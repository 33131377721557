import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useMemo } from 'react';
import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
import { getWorksite } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { useNavigate } from 'react-router-dom';
import {
  extractAllWorksiteLinkedFiles,
  extractCustomFieldFiles,
} from '@utils/functions';
import { useTranslation } from 'react-i18next';

function PartLeftDocuments() {
  const { t } = useTranslation();
  const router = useNavigate();

  const { worksiteDatas, updateWorksiteDatas } = useContext(
    WorksiteCreationContext
  );

  const updateParentDocuments = async () => {
    await getWorksite(
      t,
      worksiteDatas.id,
      {
        updateWorksiteDatas,
      },
      router
    );
  };

  /* const arrayNamesFiscal = worksiteDatas.fiscalDeclarations?.map(
                  (item) => `${item.firstname} ${item.lastname}`
                ); */

  const allLinkedFiles = useMemo(
    () => extractAllWorksiteLinkedFiles(worksiteDatas),
    [worksiteDatas]
  );

  const customFieldFiles = useMemo(
    () => extractCustomFieldFiles(worksiteDatas),
    [worksiteDatas]
  );

  return (
    <div className="w-full gap-5 flex flex-col">
      {worksiteDatas.linkedFiles !== undefined && (
        <ListDocumentsCard
          documents={allLinkedFiles}
          updateParentDocuments={updateParentDocuments}
          // worksiteFiles
          // arrayFiscalNames={arrayNamesFiscal}
          worksiteDatas={worksiteDatas}
          forceImportMode
        />
      )}

      {customFieldFiles.length >= 1 && (
        <ListDocumentsCard
          updateParentDocuments={updateParentDocuments}
          worksiteDatas={worksiteDatas}
          forceImportMode
          customFieldFiles={customFieldFiles}
        />
      )}
    </div>
  );
}

export default PartLeftDocuments;
