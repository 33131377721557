import { useTranslation } from 'react-i18next';
import { AuthLayout } from '@pages/auth/AuthLayout';

function RegistrationAlmostDone() {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <div className="flex flex-col space-y-6 lg:w-[35rem] items-center">
        <p
          className="text-[1.6875rem] font-medium leading-[2.5rem] self-start mb-2"
          data-test-id="almost_done_title"
        >
          {t('auth.registration.almost_done.title')}
        </p>
        <p className="font-normal text-black text-base self-start mb-2">
          {t('auth.registration.almost_done.message_1')}{' '}
          <a
            href="https://www.opx.co/"
            target="_blank"
            rel="noreferrer"
            className="text-blueOpx"
          >
            OPX
          </a>{' '}
          {t('auth.registration.almost_done.message_2')}
          <br />
          {t('auth.registration.almost_done.message_3')}
          <br />
          <br />
          {t('auth.registration.almost_done.message_4')}{' '}
          <a
            href="https://support.opx.co/"
            target="_blank"
            rel="noreferrer"
            className="text-blueOpx"
          >
            {t('auth.registration.almost_done.faq')}
          </a>
          .
        </p>
      </div>
    </AuthLayout>
  );
}

export { RegistrationAlmostDone };
