import { Dispatch, SetStateAction } from 'react';
import { v4 } from 'uuid';
import { black, textGrey } from '@assets/color';

interface IListAddressGoogle {
  setChoiceOk: Dispatch<React.SetStateAction<boolean>>;
  setValue: (val: string, shouldFetchData?: boolean | undefined) => void;
  setIsManual: (isManual: boolean) => void;
  activeSuggestionIndex: number;
  setActiveSuggestionIndex: Dispatch<SetStateAction<number>>;
  listAddress: { id: string; name: string }[] | undefined;
  setAddressSelected: Dispatch<SetStateAction<boolean>>;
}

function ListAddressGoogle({
  setChoiceOk,
  setValue,
  setIsManual,
  activeSuggestionIndex,
  setActiveSuggestionIndex,
  listAddress,
  setAddressSelected,
}: IListAddressGoogle) {
  return (
    <div className="border border-borderGrey border-t-transparent bg-white text-black rounded-b-default p-[.75rem] mt-[-.75rem]">
      {listAddress && listAddress.length > 0 && (
        <ul className="flex flex-col space-y-[.5rem]">
          {listAddress.map((address, index: number) => {
            return (
              <li key={v4()}>
                <div
                  style={{
                    color: address.id === 'notFound' ? textGrey : black,
                  }}
                  className={`hover:opacity-80 text-[.875rem] ${
                    index === activeSuggestionIndex
                      ? 'opacity-80 '
                      : 'opacity-100'
                  }`}
                  tabIndex={0 + 1}
                  role="button"
                  onKeyDown={undefined}
                  onClick={() => {
                    if (address.id === 'notFound') {
                      setIsManual(true);
                      if (setIsManual) {
                        setIsManual(true);
                      }
                    } else {
                      setAddressSelected(true);
                      setValue(address.name);
                    }
                    setChoiceOk(true);
                  }}
                  onMouseEnter={() => setActiveSuggestionIndex(-1)}
                  key={address.id}
                  data-test-id={
                    address.id === 'notFound'
                      ? 'address_not_found'
                      : `address_${index}`
                  }
                >
                  {address.name}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export { ListAddressGoogle };
