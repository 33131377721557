import React from 'react';

type ImageContainerProps = {
  imageUrl?: string | null;
  width?: string;
  height?: string;
  addClass?: string;
  imageText?: string;
};

function ImageContainer({
  imageUrl,
  width,
  height,
  addClass,
  imageText,
}: ImageContainerProps) {
  const hasImage = !!imageUrl;

  return (
    <div
      className={`rounded-default overflow-hidden relative ${
        hasImage ? '' : 'bg-borderGrey'
      } ${addClass}`}
      style={{ width, height, minWidth: width, minHeight: height }}
    >
      {!hasImage && imageText && imageText !== '' && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-auto h-full">
          <div className="h-full flex items-center">{imageText}</div>
        </div>
      )}
      {hasImage && (
        <img
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-auto h-full object-cover"
          src={imageUrl}
          alt=""
        />
      )}
    </div>
  );
}
export default ImageContainer;

ImageContainer.defaultProps = {
  imageUrl: undefined,
  width: '2rem',
  height: '2rem',
  addClass: '',
  imageText: '',
};
