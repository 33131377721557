import { AuthContext } from '@context/authContext';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { isInstaller } from '@utils/roles';

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PARTNERS_ROUTES } from '@utils/routesUrls';

function HeaderCardModalRequestAndChanges() {
  const { t } = useTranslation();
  const router = useNavigate();
  const {
    requestOrChangeEnumInModal,
    updateIsHistoryRequest,
    updateRequestOrChangeEnumInModal,
    isHistoryRequest,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { user } = useContext(AuthContext);

  const navigateToRequest = () => () => {
    router(PARTNERS_ROUTES.REQUESTS);
    updateRequestOrChangeEnumInModal(null);
    if (isInstaller(user)) {
      updateIsHistoryRequest(true);
      localStorage.setItem(
        'isHistoryRequest',
        JSON.stringify(isHistoryRequest)
      );
    } else {
      updateIsHistoryRequest(false);
      localStorage.removeItem('isHistoryRequest');
    }
  };

  return (
    <div className="border-b-[1px] border-b-borderGrey px-6 py-4 flex justify-between items-center mb-[1rem]">
      <p className="font-normal text-[1.5rem] leading-9">
        {requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.REQUEST
          ? t('requests_and_changes.notifications')
          : t('requests_and_changes.changes')}
      </p>
      {requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.REQUEST && (
        <button
          onClick={navigateToRequest()}
          type="button"
          className="text-[.875rem] text-textGrey"
        >
          {isInstaller(user)
            ? t('requests_and_changes.see_history')
            : t('requests_and_changes.see_more')}
        </button>
      )}
    </div>
  );
}

export { HeaderCardModalRequestAndChanges };
