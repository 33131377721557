import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { downloadWorksiteDocuments } from '@models/worksites/apiRequests/worksitesRequests';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function DownloadArchive() {
  const { worksiteId, fileName } = useParams();
  const { t } = useTranslation();
  const [pageNotFound, setPageNotFound] = useState<boolean>(false);
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
  const [descLoading, setDescLoading] = useState<boolean>(
    !!worksiteId && !!fileName
  );

  const contactSupportTeam = () => {
    return (
      <div>
        <p className="text-[1.2rem] font-medium leading-[2.5rem]">
          {t('worksites.documents.downloaded.fail.header')}{' '}
        </p>
        <ul className="flex flex-col space-y-[.5rem] disc pl-[1rem]">
          <li>{t('worksites.documents.downloaded.fail.reason_1')}</li>
          <li>{t('worksites.documents.downloaded.fail.reason_2')}</li>
          <li>{t('worksites.documents.downloaded.fail.reason_3')}</li>
          <li>{t('worksites.documents.downloaded.fail.reason_4')}</li>
        </ul>
        <br />
        <p className="text-[1.2rem] font-medium leading-[2.5rem]">
          {t('global.contact_support_team')}
        </p>
      </div>
    );
  };

  const filesDownloaded = () => {
    if (!descLoading && isDownloaded) {
      return (
        <div>
          <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
            {t('worksites.documents.downloaded.success', {
              filename: fileName,
            })}
          </p>
        </div>
      );
    }

    return (
      <div className="w-[30rem]">
        <LoaderSkeleton height="5rem" addClass="w-full" />
      </div>
    );
  };

  useEffect(() => {
    if (worksiteId && fileName) {
      downloadWorksiteDocuments(
        Number(worksiteId),
        fileName,
        setIsDownloaded,
        setDescLoading
      );
    } else setPageNotFound(true);
  }, [worksiteId, fileName]);

  return (
    <AuthLayout>
      {pageNotFound ? (
        <div>
          <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
            {t('global.page_not_found.header')}
            <br />
            {t('global.contact_support_team')}{' '}
          </p>
        </div>
      ) : (
        <div>
          {!descLoading && !isDownloaded
            ? contactSupportTeam()
            : filesDownloaded()}
        </div>
      )}
    </AuthLayout>
  );
}

export { DownloadArchive };
