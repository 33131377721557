import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { Card } from '@components/Card';
import {
  housingIcons,
  worksiteIcons,
} from '@models/beneficiaries/components/view/InfosIcons';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { v4 } from 'uuid';
import { blueOpx } from '@assets/color';
import { t } from 'i18next';
import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import { removalToDisplay } from '@models/worksiteCreation/utils/functions';

interface WorksiteInfosProps {
  worksiteDatas: IWorksiteDetails;
}

function WorksiteInfos({ worksiteDatas }: WorksiteInfosProps) {
  const { globalEnum } = useContext(GlobalContext);

  const operationIcons = worksiteIcons(worksiteDatas, globalEnum).operations;
  return (
    <div className="flex flex-col gap-5">
      {worksiteDatas.housing && (
        <Card title={t('worksites.housing.title')}>
          <div className="grid grid-cols-2">
            {housingIcons(
              worksiteDatas.housing,
              globalEnum,
              removalToDisplay(worksiteDatas.worksites_operations)
            ).map((icon) => (
              <InfosWithIcon
                key={v4()}
                loading={false}
                infos={[
                  {
                    title: icon.title,
                    subtitle: icon.subtitle,
                  },
                ]}
                icon={icon.icon || <div />}
                colorIcon={icon.icon ? blueOpx : 'transparent'}
                addClass={icon.addClass}
              />
            ))}
          </div>
        </Card>
      )}
      <Card title={t('worksites.worksite_information')}>
        <div>
          <div className="grid grid-cols-2 border border-transparent border-b-1 border-b-borderGrey">
            {worksiteIcons(worksiteDatas, globalEnum).infos.map((icon) => (
              <InfosWithIcon
                key={v4()}
                loading={false}
                infos={[
                  {
                    title: icon.title,
                    subtitle: icon.subtitle,
                  },
                ]}
                icon={icon.icon || <div />}
                colorIcon={icon.icon ? blueOpx : 'transparent'}
                addClass={icon.addClass}
              />
            ))}
          </div>
          <div>
            {operationIcons.map((op, index) => (
              <div
                key={v4()}
                className={`grid grid-cols-2 ${
                  index < operationIcons.length - 1 &&
                  'border border-transparent border-b-1 border-b-borderGrey '
                } mt-5`}
              >
                {op.map((icon) => {
                  if (
                    icon?.subtitle === '' ||
                    (icon?.subtitle && icon.subtitle !== 'null')
                  )
                    return (
                      <InfosWithIcon
                        key={v4()}
                        loading={false}
                        infos={[
                          {
                            title: icon?.title,
                            subtitle: icon.subtitle,
                          },
                        ]}
                        icon={icon.icon || <div />}
                        colorIcon={icon.icon ? blueOpx : 'transparent'}
                        addClass="mb-5"
                      />
                    );
                  return null;
                })}
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}

export { WorksiteInfos };
