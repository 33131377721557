import { Navigate, useLocation } from 'react-router-dom';
import { AUTH_ROUTES } from '@utils/routesUrls';

function ProtectedRedirect() {
  const location = useLocation();
  return (
    <Navigate to={AUTH_ROUTES.LOGIN} state={{ from: location.pathname }} />
  );
}

export default ProtectedRedirect;
