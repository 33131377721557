import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import { ColorCube } from '../ColorCube';

interface IPaginationProps {
  colorPagination: string;
  numberPage: number;
  totalNumberPage: number;
  onPrevious?: () => void;
  onNext?: () => void;
  addClass?: string;
}

function Pagination({
  colorPagination,
  numberPage,
  totalNumberPage,
  onPrevious,
  onNext,
  addClass,
}: IPaginationProps) {
  const { t } = useTranslation();
  return (
    <div
      className={[
        'flex justify-center space-x-[1.5rem] items-center',
        addClass,
      ].join(' ')}
    >
      {numberPage > 1 ? (
        <ColorCube
          color={colorPagination}
          numberOrIcon={<ChevronLeftIcon />}
          size="2rem"
          onClick={onPrevious}
          opacity
          dataTestId="pagination_previous"
        />
      ) : (
        <div className="w-8" />
      )}
      <div className="border border-borderGrey py-[.5rem] px-[1rem] rounded-default">
        {t('pagination.page')} {numberPage}
        {`${totalNumberPage > 0 ? ` / ${totalNumberPage}` : ''}`}
      </div>
      {totalNumberPage > 1 && !(totalNumberPage === numberPage) ? (
        <ColorCube
          color={colorPagination}
          numberOrIcon={<ChevronLeftIcon />}
          size="2rem"
          addClass="transform rotate-180"
          onClick={onNext}
          opacity
          dataTestId="pagination_next"
        />
      ) : (
        <div className="w-8" />
      )}
    </div>
  );
}

export { Pagination };

Pagination.defaultProps = {
  onPrevious: undefined,
  onNext: undefined,
  addClass: '',
};
