import { CheckIcon } from '@assets/images/svgComponents';
import { blueOpx, borderGrey } from '@assets/color';
import { useState } from 'react';

interface IProps {
  label: string;
  onCheck?: (checked: boolean) => void;
  colorChecked?: string;
  backgroundColorChecked?: string;
  addClass?: string;
  checked: boolean;
  width?: string;
  disabled?: boolean;
}

//  EXEMPLE D'UTILISATION DANS LE COMPOSANT PARENT:
//  1. useState du tableau qui contiendra les items checkés
//  const [checkedItems, setCheckedItems] = useState<string[]>([]);
//
//  2. fonction à passer en propriété du composant
//  const handleCheck = (isChecked: boolean, label: string) => {
//     if (isChecked) {
//       setCheckedItems([...checkedItems, label]);
//     } else {
//       setCheckedItems(checkedItems.filter((item) => item !== label));
//     }
//   };
//
//  3. Import des composants avec la fonction passée en paramètre et le label
//   <Checkbox
//     label="Option 1"
//     onCheck={(isChecked) => handleCheck(isChecked, 'Option 1')}
//     backgroundColorChecked='red'
//   />
//   <Checkbox
//     label="Option 2"
//     onCheck={(isChecked) => handleCheck(isChecked, 'Option 2')}
//     backgroundColorChecked='red'
//   />

function Checkbox({
  label,
  onCheck,
  backgroundColorChecked,
  colorChecked,
  addClass,
  checked,
  width,
  disabled,
}: IProps) {
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (onCheck) {
      onCheck(isChecked);
    }
  };

  return (
    <div className="flex items-center">
      <label
        htmlFor={label}
        className={['w-[1.25rem] h-[1.25rem] relative', addClass].join(' ')}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          style={{
            border: checked ? 'none' : `2px solid ${borderGrey}`,
            zIndex: 2,
          }}
          className={`w-[1.25rem] h-[1.25rem] rounded-[3px] relative ${
            disabled ? 'cursor-not-allowed bg-borderGrey' : 'cursor-pointer'
          }`}
          disabled={disabled}
          readOnly={disabled}
        />
        {checked && (
          <CheckIcon
            fill={colorChecked}
            style={{
              transform: 'translate(-50%, -50%)',
              backgroundColor: backgroundColorChecked,
              zIndex: 0,
            }}
            width={width || '1.25rem'}
            height={width || '1.25rem'}
            className="absolute top-[50%] left-[50%] rounded-[3px]"
          />
        )}
      </label>
    </div>
  );
}

export { Checkbox };

Checkbox.defaultProps = {
  onCheck: () => null,
  backgroundColorChecked: blueOpx,
  colorChecked: 'white',
  addClass: '',
  width: '',
  disabled: false,
};
