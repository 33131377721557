import { blueOpx } from '@assets/color';
import { BeneficiariesIcon, HomeIcon } from '@assets/images/svgComponents';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { OWNER } from '@models/worksiteCreation/utils/enums';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  labelAge,
  labelHabitationZone,
  labelIncomes,
  labelOwner,
} from '@models/worksiteCreation/utils/functions';

function ListInfoGeneral() {
  const { t } = useTranslation();
  const { simulatorData } = useContext(WorksiteCreationContext);

  const generalIncome = simulatorData['general.income.precarityType'];
  const generalAge = simulatorData['general.age'];
  const generalOwner = simulatorData['general.owner'];
  const generalHabitation = simulatorData['general.habitation'];
  const generalHabitationZone = simulatorData['general.habitationZone'];
  const generalSurface = simulatorData['general.surface'];
  const generalPersons = Number(simulatorData['general.persons']);
  const showSeparation = useMemo(
    () =>
      (!!generalPersons && generalPersons > 0) ||
      !!generalIncome ||
      !!generalOwner,
    [generalIncome, generalOwner, generalPersons]
  );

  return (
    <div className="mt-5">
      <p className="text-4 my-4">
        {t('worksite_creation.simulation.summary.info_general')}
      </p>
      <div className="border border-borderGrey p-[1.5rem] rounded-default">
        <div
          className={`${
            showSeparation ? 'border-b border-b-borderGrey pb-[1rem]' : ''
          } grid w-full grid-cols-2 gap-y-4`}
        >
          {generalHabitation && (
            <InfosWithIcon
              colorIcon={blueOpx}
              icon={<HomeIcon />}
              infos={[
                {
                  title: t('worksite_creation.simulation.habitation.title'),
                  subtitle:
                    generalHabitation === 'house'
                      ? t('worksite_creation.simulation.habitation.house') || ''
                      : t(
                          'worksite_creation.simulation.habitation.apartment'
                        ) || '',
                },
              ]}
            />
          )}
          {generalHabitationZone && (
            <InfosWithIcon
              colorIcon={blueOpx}
              icon={<HomeIcon />}
              infos={[
                {
                  title: t(
                    'worksite_creation.simulation.habitation.type_of_building'
                  ),
                  subtitle: labelHabitationZone(t, generalHabitationZone) || '',
                },
              ]}
            />
          )}

          {generalAge && (
            <InfosWithIcon
              spaceLeft
              infos={[
                {
                  title: t('worksite_creation.simulation.age.title'),
                  subtitle: labelAge(t, generalAge),
                },
              ]}
            />
          )}
          {generalSurface && (
            <InfosWithIcon
              spaceLeft
              colorIcon={blueOpx}
              infos={[
                {
                  title: t('worksite_creation.simulation.surface'),
                  subtitle: `${generalSurface} m2` || '',
                },
              ]}
            />
          )}
        </div>
        <div
          className={`${
            showSeparation ? 'pt-[1rem]' : ''
          } w-full grid grid-cols-2 gap-y-4`}
        >
          {!!generalPersons && generalPersons > 0 && (
            <InfosWithIcon
              colorIcon={blueOpx}
              icon={<BeneficiariesIcon />}
              infos={[
                {
                  title: t('worksite_creation.simulation.persons'),
                  subtitle:
                    `${generalPersons.toString()} ${t('global.people')}${
                      generalPersons > 1 ? 's' : ''
                    }` || '',
                },
              ]}
            />
          )}
          {generalIncome && (
            <InfosWithIcon
              spaceLeft
              infos={[
                {
                  title: t('worksite_creation.simulation.income'),
                  subtitle: labelIncomes(t, generalIncome),
                },
              ]}
            />
          )}
          {generalOwner && generalOwner !== OWNER.OTHER && (
            <InfosWithIcon
              spaceLeft
              infos={[
                {
                  title: t('worksite_creation.simulation.type_owner'),
                  subtitle: labelOwner(t, generalOwner),
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { ListInfoGeneral };
