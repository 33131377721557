import { ITunnelType } from '@models/contracts/utils/newContractTypes';
import { CardChoicePartnerInModal } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/CardChoicePartnerInModal';
import { useContext, useEffect, useState } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import {
  getConventionModelTunnel,
  getTunnels,
} from '@models/contracts/apiRequests/newContractRequests';
import { v4 } from 'uuid';
import { Modal } from '@components/Modal';
import {
  ContractTypes,
  CreationSteps,
} from '@models/contractCreation/utils/enums';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ContractTunnelProps {
  isNewTemplate?: boolean;
}

function ContractTunnel({ isNewTemplate }: ContractTunnelProps) {
  const { t } = useTranslation();
  const {
    updateTunnel,
    changeStep,
    updateContractType,
    tunnels,
    updateTunnels,
    updateRelatedContract,
  } = useContext(ContractCreationContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  const getData = async () => {
    if (tunnels.length < 1) {
      setIsLoading(true);
      const res = isNewTemplate
        ? await getConventionModelTunnel()
        : await getTunnels();

      if (res) {
        const formattedTunnels = res.map((tunnel: ITunnelType) => {
          let badges = [''];
          switch (tunnel.contractType) {
            case ContractTypes.CONVENTION:
              badges = [
                t('contract.tunnel_badges.convention'),
                t('contract.tunnel_badges.frame'),
              ];
              break;
            case ContractTypes.TRIPARTITE:
              badges = [
                t('contract.tunnel_badges.tripartite'),
                t('contract.tunnel_badges.punctual'),
              ];
              break;
            case ContractTypes.MANDAT:
            case ContractTypes.MANDAT_NON_DEPOSANT:
              badges = [
                t('contract.tunnel_badges.mandat'),
                t('contract.tunnel_badges.frame'),
              ];
              break;
            case ContractTypes.VENTE:
            case ContractTypes.DELEGATION:
              badges = [
                t('contract.tunnel_badges.sale'),
                t('contract.tunnel_badges.frame'),
              ];
              break;
            default:
              break;
          }
          return { ...tunnel, badges };
        });

        updateTunnels(formattedTunnels);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [isNewTemplate, location]);

  const choiceNextStep = (tunnel: ITunnelType) => {
    const choiceContractStep = [
      ContractTypes.VENTE,
      ContractTypes.DELEGATION,
    ].includes(tunnel.contractType)
      ? CreationSteps.SALE_PROFILE
      : CreationSteps.PARTNER_CHOICE;

    return isNewTemplate ? CreationSteps.GENERAL : choiceContractStep;
  };

  const renderTunnelElement = (tunnel: ITunnelType) => {
    return (
      <CardChoicePartnerInModal
        choice={tunnel.label}
        infos={tunnel.description}
        onChoice={() => {
          updateContractType(tunnel.contractType);

          if (tunnel.contractType === ContractTypes.CONVENTION) {
            updateRelatedContract(null);
          }

          const nextStep = choiceNextStep(tunnel);

          changeStep(nextStep);
          updateTunnel(tunnel);
        }}
        addClass="w-full my-[1rem]"
        badges={tunnel.badges}
        dataTestId={`tunnel_${tunnel.contractType}`}
      />
    );
  };

  return (
    <Modal
      title={
        isNewTemplate
          ? `${t('contract.begin.new_template')}`
          : `${t('contract.begin.new')}`
      }
      closeModal
      backgroundTransparent
      sidebarVisible
    >
      <div>
        {isLoading ? (
          <>
            {Array.from(Array(4).keys()).map(() => (
              <div
                key={v4()}
                className="bg-white border border-borderGrey rounded-default p-[1.5rem] h-[6.5rem] my-[1rem]"
              >
                <LoaderSkeleton height="1.5rem" addClass="mb-[1rem] w-[5rem]" />
                <LoaderSkeleton height="1.5rem" addClass="mb-[1rem] w-[70%]" />
              </div>
            ))}
          </>
        ) : (
          tunnels.map((tunnel) => (
            <div key={v4()}>{renderTunnelElement(tunnel)}</div>
          ))
        )}
      </div>
    </Modal>
  );
}

ContractTunnel.defaultProps = {
  isNewTemplate: false,
};
export { ContractTunnel };
