import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ArrowIcon, MobileIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '@utils/routesUrls';

interface IAccountToActivateProps {
  linkIdNow?: string | null;
}
function AccountToActivate({ linkIdNow }: IAccountToActivateProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!linkIdNow) {
    navigate(AUTH_ROUTES.REGISTRATION_ALMOST_DONE);
  }

  return (
    <div className="flex flex-col space-y-8 lg:w-[40rem] items-center">
      <div>
        <p
          className="text-[1.6875rem] font-medium leading-[2.5rem] self-start mb-2"
          data-test-id="account_to_activate_message"
        >
          {t('auth.registration.account_to_activate.title')}
        </p>
        <p className="font-normal text-black text-[.875rem] self-start mb-2">
          {t('auth.registration.account_to_activate.message')}
        </p>
      </div>
      <div className="mt-2 text-[1rem] p-4 border border-borderGrey rounded-default flex text-blueOpx items-center">
        <MobileIcon
          fill={blueOpx}
          width="1.5rem"
          height="1.5rem"
          className="mr-2"
        />
        {t('auth.registration.account_to_activate.mobile')}
      </div>

      {linkIdNow && (
        <>
          <p className="mb-2 text-[1.5rem] leading-[1rem]">{t('global.or')}</p>
          <div className="text-[1rem] p-4 border border-borderGrey rounded-default flex text-blueOpx items-center">
            <ArrowIcon
              fill={blueOpx}
              width="1.75rem"
              height="1.75rem"
              className="mr-2 rotate-90"
            />
            {t('auth.registration.account_to_activate.desktop')}
          </div>
          <a href={linkIdNow} target="_blank" rel="noreferrer">
            <ButtonOpx
              label={t('admin.users.identity_status')}
              type="primary"
            />
          </a>
        </>
      )}
    </div>
  );
}

export { AccountToActivate };

AccountToActivate.defaultProps = {
  linkIdNow: null,
};
