/* eslint-disable no-nested-ternary */
import { useContext, useEffect } from 'react';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { useLocation } from 'react-router-dom';
import { DIMENSIONS } from '@utils/utils';
import { WarningIcon } from '@assets/images/svgComponents';
import { GlobalContext } from '@context/globalContext';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@context/authContext';
import { HeaderCardRequestAndChanges } from './HeaderCardRequestAndChanges';
import { DetailRequest } from './requests/DetailRequest';
import { NewMessageDetailsRequestForm } from './requests/NewMessageDetailsRequestForm';
import { ListChangesCard } from './changes/ListChangesCard';
import { SubHeaderCardRequest } from './SubHeaderCardRequestAndChanges';
import { NewMessageChanges } from './changes/NewMessageChanges';
import { ListRequestsInCard } from './requests/ListRequestsInCard';

function RequestsAndChangesCard() {
  const location = useLocation();
  const { t } = useTranslation();
  const { updateDisplayModalUpgradeSubscription } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const {
    requestOrChangeEnumInCard,
    updateIsModalNewRequest,
    detailRequest,
    updateDetailRequest,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  const { header_height, requests_and_changes_width } = DIMENSIONS;

  const request =
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.REQUEST;
  const changes =
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.CHANGE;
  const message =
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.MESSAGE;

  useEffect(() => {
    updateDetailRequest(null);
  }, []);

  return (
    <div
      className={`fixed right-0 top-[${header_height}] max-h-screen bg-white border-l`}
    >
      <div
        style={{
          height: `calc(100vh - ${parseFloat(header_height)}rem)`,
          minWidth: requests_and_changes_width,
          maxWidth: requests_and_changes_width,
        }}
        className="flex flex-col bg-white"
      >
        {/* Header */}
        <HeaderCardRequestAndChanges />

        {/* Contenu scrollable */}
        <div className="flex-1 overflow-auto">
          {(request || message) && (
            <div className="w-full">
              <SubHeaderCardRequest />
              {detailRequest === null ? (
                <ListRequestsInCard isListMessages={message} />
              ) : (
                <DetailRequest />
              )}
            </div>
          )}
          {changes && (
            <div className="w-full">
              {user?.is_freemium && (
                <div className="flex justify-center mt-2">
                  <ButtonOpx
                    icon={<WarningIcon className="!w-6 !h-6" />}
                    label={t('upgrade_subscription_modal.upgrade_cta_changes')}
                    onClick={() => updateDisplayModalUpgradeSubscription(true)}
                  />
                </div>
              )}
              <ListChangesCard />
            </div>
          )}
        </div>

        <div className="pt-[1.5rem] border-t w-full">
          {detailRequest === null &&
          location.pathname.includes('worksites') &&
          message ? (
            <div
              className="mb-[1.5rem] relative flex justify-end"
              style={{ width: requests_and_changes_width }}
            >
              <ButtonOpx
                label={`${t('requests_and_changes.new_message')}`}
                addClass="mr-[1.5rem]"
                onClick={() => updateIsModalNewRequest(true)}
              />
            </div>
          ) : detailRequest !== null &&
            location.pathname.includes('worksites') &&
            !changes ? (
            <div
              className="relative flex justify-end"
              style={{ width: requests_and_changes_width }}
            >
              <NewMessageDetailsRequestForm />
            </div>
          ) : location.pathname.includes('worksites') && changes ? (
            <div
              className="relative"
              style={{ width: requests_and_changes_width }}
            >
              <NewMessageChanges />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export { RequestsAndChangesCard };
