/* eslint-disable no-nested-ternary */
import { CheckIcon, CrossIcon } from '@assets/images/svgComponents';
import { green, red } from '@assets/color';
import { convertHexToRGBA } from '@utils/functions';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { GeocodeResult, getGeocode, getLatLng } from 'use-places-autocomplete';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { t } from 'i18next';
import { IFormAddressDatas } from '@components/formAddress/FormAddress';
import { ListAddressGoogle } from './ListAddressGoogle';

interface IFormAddressProps {
  data: google.maps.places.AutocompletePrediction[];
  value: string;
  setValue: (val: string, shouldFetchData?: boolean | undefined) => void;
  addressDatas?: IBeneficiaryAddress;
  setAddressDatas?: Dispatch<SetStateAction<IBeneficiaryAddress>>;
  placeholder: string;
  validAddress?: boolean;
  setIsManual: (isManual: boolean) => void;
  onBlurInput?: (e: ChangeEvent<HTMLElement>) => void;
  textError?: string;
  required?: boolean;
  updateDatas?: (updates: Partial<IFormAddressDatas>) => void;
  disabled?: boolean;
}

function InputSearchAddressGoogle({
  data,
  value,
  setValue,
  addressDatas,
  setAddressDatas,
  placeholder,
  validAddress,
  setIsManual,
  onBlurInput,
  textError,
  required,
  updateDatas,
  disabled,
}: IFormAddressProps) {
  const [choiceOk, setChoiceOk] = useState(value !== '');

  const [errorInfo, setErrorInfo] = useState<boolean>(false);
  const [listAddress, setListAddress] =
    useState<{ id: string; name: string }[]>();
  const [activeSuggestionIndex, setActiveSuggestionIndex] =
    useState<number>(-1);

  const [addressSelected, setAddressSelected] = useState<boolean>(false);
  const [street, setStreet] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [zip, setZip] = useState<string>('');

  const parameter = {
    address: value,
  };

  const handleBlur = (e: ChangeEvent<HTMLElement>) => {
    if (onBlurInput) {
      onBlurInput(e);
    }
  };
  const geoCodeResultProcessing = (
    addressFind: google.maps.GeocoderAddressComponent[]
  ) => {
    const numberStreetObj = addressFind.find((item) =>
      item.types.includes('street_number')
    );
    const streetObj = addressFind.find(
      (item) =>
        item.types.includes('route') || item.types.includes('establishment')
    );
    const cityObj = addressFind.find((item) => item.types.includes('locality'));

    const postalCodeObj = addressFind.find((item) =>
      item.types.includes('postal_code')
    );

    const countryObj = addressFind.find((item) =>
      item.types.includes('country')
    );

    const datasToSet = {
      country: countryObj ? countryObj.long_name : '',
      street: `${streetObj ? streetObj.long_name : ''}`,
      city: cityObj ? cityObj.long_name : '',
      zipCode: postalCodeObj ? postalCodeObj.long_name : '',
      streetNumber: numberStreetObj?.long_name,
    };

    setStreet(
      datasToSet.streetNumber
        ? `${datasToSet.streetNumber} ${datasToSet.street}`
        : datasToSet.street
    );
    setCity(datasToSet.city);
    setZip(datasToSet.zipCode);

    if (updateDatas) {
      updateDatas(datasToSet);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      if (setAddressDatas && addressDatas)
        setAddressDatas({
          ...addressDatas,
          address: '',
          city: '',
          postal_code: '',
        });
    }
    setValue(e.target.value);
    setChoiceOk(false);
    setAddressSelected(false);
  };

  const onKeyDown = (e: any) => {
    if (listAddress) {
      if (e.keyCode === 13) {
        if (activeSuggestionIndex === listAddress.length - 1) {
          setIsManual(true);
          setActiveSuggestionIndex(0);
          if (setIsManual) {
            setIsManual(true);
          }
        } else {
          setValue(listAddress[activeSuggestionIndex].name, false);
          setAddressSelected(true);
          setActiveSuggestionIndex(0);
          setChoiceOk(true);
          if (setIsManual) {
            setIsManual(false);
          }
        }
      } else if (e.keyCode === 38) {
        if (activeSuggestionIndex === 0) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      } else if (e.keyCode === 40) {
        if (activeSuggestionIndex - 1 === data.length) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    }
  };

  useEffect(() => {
    if (setAddressDatas && addressDatas)
      setAddressDatas({
        ...addressDatas,
        address: street,
        city,
        postal_code: zip,
      });
  }, [street, city, zip]);

  useEffect(() => {
    if (addressSelected) {
      getGeocode(parameter)
        .then((results) => {
          if (
            results[0].types.includes('street_address') ||
            results[0].types.includes('route') ||
            results[0].types.includes('premise') ||
            results[0].types.includes('subpremise') ||
            results[0].types.includes('establishment')
          ) {
            setErrorInfo(false);
            geoCodeResultProcessing(results[0].address_components);
          } else setErrorInfo(true);
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          if (choiceOk && updateDatas) {
            updateDatas({
              latitude: latLng.lat,
              longitude: latLng.lng,
            });
          }
          if (choiceOk && addressDatas) {
            if (setAddressDatas)
              setAddressDatas({
                ...addressDatas,
                latitude: latLng.lat,
                longitude: latLng.lng,
              });
          }
        })
        .catch((error) => {
          console.log(
            'Erreur lors de la récupération des données géographiques:',
            error
          );
        });
    } else {
      getGeocode(parameter)
        .then((res) => {
          const filterAddress = (address: GeocodeResult) => {
            const { formatted_address, address_components } = address;
            return (
              formatted_address.includes('France') &&
              address_components.length >= 7
            );
          };

          const notFoundAddress = {
            id: 'notFound',
            name: `${t('forms.address.not_found')}`,
          };

          if (res.length > 0) {
            const addressList = res
              .filter((elt) => filterAddress(elt))
              .map((item) => ({
                id: item.place_id,
                name: item.formatted_address,
              }));

            setListAddress([...addressList, { ...notFoundAddress }]);
          }
        })
        .catch((error) => {
          console.log(
            'Erreur lors de la récupération des données géographiques:',
            error
          );
        });
    }
    if (value === '' && setErrorInfo) {
      setErrorInfo(false);
    }
  }, [value, addressSelected]);

  return (
    <div className="flex w-full flex-col text-textGrey text-[.625rem] relative">
      <input
        onBlur={(e) => handleBlur(e)}
        type="text"
        placeholder={`${placeholder}${required ? '*' : ''}`}
        className={`border rounded-default focus:outline-none p-[.75rem] ${
          validAddress && choiceOk
            ? 'border-green'
            : errorInfo
            ? 'border-red'
            : 'border-borderGrey'
        } text-[.875rem] text-black ${disabled ? 'bg-backgroundBody' : ''}`}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        data-test-id="input_address"
        autoComplete="off"
        disabled={disabled}
      />
      {validAddress && choiceOk && (
        <div
          className="rounded-default p-[.25rem] flex items-center justify-center absolute right-[.563rem] top-[.5rem]"
          style={{
            backgroundColor: convertHexToRGBA(green, 0.1),
          }}
        >
          <CheckIcon fill={green} width="1.5rem" height="1.5rem" />
        </div>
      )}
      {errorInfo && (
        <div
          className="rounded-default p-[.25rem] flex items-center justify-center absolute right-[.563rem] top-[.5rem]"
          style={{
            backgroundColor: convertHexToRGBA(red, 0.1),
          }}
        >
          <CrossIcon fill={red} width="1.5rem" height="1.5rem" />
        </div>
      )}
      {value !== '' && !choiceOk && (
        <ListAddressGoogle
          setChoiceOk={setChoiceOk}
          setIsManual={setIsManual}
          setValue={setValue}
          activeSuggestionIndex={activeSuggestionIndex}
          setActiveSuggestionIndex={setActiveSuggestionIndex}
          listAddress={listAddress}
          setAddressSelected={setAddressSelected}
        />
      )}
      {errorInfo && (
        <div
          className="rounded-default p-[.5rem] text-red"
          style={{
            backgroundColor: convertHexToRGBA(red, 0.1),
          }}
        >
          {textError}
        </div>
      )}
    </div>
  );
}

export { InputSearchAddressGoogle };

InputSearchAddressGoogle.defaultProps = {
  validAddress: false,
  onBlurInput: undefined,
  textError: '',
  setAddressDatas: undefined,
  addressDatas: undefined,
  required: false,
  updateDatas: undefined,
  disabled: false,
};
