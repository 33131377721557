import { BriefcaseIcon } from '@assets/images/svgComponents';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { KpiTab } from '@components/atomic/KpiTab';
import { useState } from 'react';
import { IInfoWithIcon, IKPITab } from '../../../types/globalTypes';

function KpiTabDesign() {
  const [numberOfCards, setNumberOfCards] = useState<number>(5);

  const infos: IInfoWithIcon[] = [
    { title: 'Prénom', subtitle: 'Al' },
    { title: 'Nom', subtitle: 'Kollyck' },
    { title: 'Profession', subtitle: 'Roi du dancefloor' },
  ];

  const kpiTab = (): IKPITab[] => {
    const tab = [
      {
        infos: [{ title: 'THE TITRE', subtitle: 'THE SOUS TITRE' }],
        icon: <BriefcaseIcon />,
        colorIcon: '#1730BF',
      },
    ];
    for (let i = 2; i < numberOfCards + 1; i += 1) {
      tab.push({
        infos: [{ title: `THE TITRE ${i}`, subtitle: `THE SOUS TITRE${i}` }],
        icon: <BriefcaseIcon />,
        colorIcon: '#1730BF',
      });
    }
    return tab;
  };

  return (
    <div className="w-full flex-col items-center mb-6">
      <p className="mb-4">KPI TAB AVEC INFOS CARDS</p>
      <div className="m-4">
        <InfosWithIcon
          icon={<BriefcaseIcon />}
          colorIcon="#1730BF"
          infos={infos}
        />
      </div>
      <div className="m-4">
        <InputSelect
          label={"Nombre d'éléments"}
          placeholder={"Sélectionner le nombre d'éléments"}
          defaultSelected={numberOfCards.toString()}
          dataLabelValue={[
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ]}
          onSelect={(value) => setNumberOfCards(Number(value))}
          addClass="w-1/2 mb-4"
        />
        <KpiTab infos={kpiTab()} />
      </div>
    </div>
  );
}

export { KpiTabDesign };
