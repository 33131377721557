import { textGreyInfos } from '@assets/color';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { v4 } from 'uuid';
import { shortenStringWithEllipsis } from '@utils/functions';
import { IInfoWithIcon } from '../../types/globalTypes';

type IInfosWithImageProps = {
  infos: IInfoWithIcon[];
  image: string;
  titleColor?: string;
  subtitleColor?: string;
  addClass?: string;
  loading?: boolean;
  rightInfos?: JSX.Element | string;
  dataTestId?: string;
};

function InfosWithImage({
  infos,
  image,
  titleColor,
  subtitleColor,
  addClass,
  loading,
  rightInfos,
  dataTestId,
}: IInfosWithImageProps): JSX.Element {
  return (
    <div
      className={['flex-col items-start space-y-[.5rem] w-full', addClass].join(
        ' '
      )}
    >
      {infos.map((info: IInfoWithIcon) => {
        if (info.showImage) {
          return (
            <div
              className="flex items-center space-x-[.5rem] w-full"
              key={v4()}
            >
              <div className="rounded-[.25rem] w-[2.5rem] h-[2.5rem] overflow-hidden">
                {image ? (
                  <img
                    src={image}
                    alt={info.title}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <div className="bg-borderGrey w-full h-full" />
                )}
              </div>
              <div className="flex justify-between w-[100%]">
                <div className="infos_left">
                  <p
                    style={{ color: titleColor || 'black' }}
                    className={[
                      'text-[1rem] leading-[1.25rem] pb-[.25rem] font-[500] text-left',
                      info.addClass,
                    ].join(' ')}
                    data-test-id={dataTestId}
                  >
                    {loading ? (
                      <LoaderSkeleton
                        height="0.75rem"
                        addClass="max-w-[8rem]"
                      />
                    ) : (
                      shortenStringWithEllipsis(info.title, 30)
                    )}
                  </p>
                  <p
                    style={{ color: subtitleColor || textGreyInfos }}
                    className="text-[.625rem] leading-[.75rem] text-left"
                  >
                    {loading ? (
                      <LoaderSkeleton height="1rem" addClass="max-w-[15rem]" />
                    ) : (
                      info.subtitle
                    )}
                  </p>
                </div>
                {rightInfos && (
                  <div className="infos_right flex items-center">
                    {rightInfos}
                  </div>
                )}
              </div>
            </div>
          );
        }
        return (
          <div
            className="flex-col items-center space-y-[.25rem] w-full"
            key={v4()}
          >
            <p
              style={{ color: titleColor || textGreyInfos }}
              className={[
                'text-[.625rem] leading-[.75rem] pb-[.25rem] text-left',
                info.addClass,
              ].join(' ')}
            >
              {loading ? (
                <LoaderSkeleton height="0.75rem" addClass="max-w-[8rem]" />
              ) : (
                info.title
              )}
            </p>
            <p
              style={{ color: subtitleColor || 'black' }}
              className="font-[500] text-[.875rem] leading-[1rem] text-left"
              data-test-id={dataTestId}
            >
              {loading ? (
                <LoaderSkeleton height="1rem" addClass="max-w-[15rem]" />
              ) : (
                info.subtitle
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export { InfosWithImage };

InfosWithImage.defaultProps = {
  titleColor: '',
  subtitleColor: '',
  addClass: '',
  loading: undefined,
  rightInfos: undefined,
  dataTestId: '',
};
