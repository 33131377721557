import { SidebarProgressCard } from '@models/worksiteCreation/components/sidebarProgress/SidebarProgressCard';
import { StatusStepEnum } from '@models/worksiteCreation/utils/enums';

function SidebarProgressWorksiteCreationDesign() {
  const steps = [
    { value: 1, label: 'Information bénéficiaire' },
    { value: 2, label: 'Sous-traitant' },
    { value: 3, label: 'Matériel' },
  ];

  return (
    <div className="flex-col items-center mb-6">
      <div className="mt-5 mb-2">SIDEBAR PROGRESSION CREATION DE CHANTIER</div>
      <div className="w-[20rem] flex flex-col space-y-[.875rem]">
        <SidebarProgressCard
          title="Simulation"
          status={StatusStepEnum.COMPLETED}
        />
        <SidebarProgressCard
          title="Création de chantier"
          steps={steps}
          nameStepActive="Sous-traitant"
          status={StatusStepEnum.IN_PROGRESS}
        />
        <SidebarProgressCard
          title="Document"
          status={StatusStepEnum.NOT_STARTED}
        />
      </div>
      <p className="mt-5 mb-2"> Si Document en cours d&apos; upload:</p>
      <div className="w-[20rem]">
        <SidebarProgressCard
          title="Document"
          status={StatusStepEnum.IN_PROGRESS}
          document
          documentLoaded
        />
      </div>
    </div>
  );
}

export { SidebarProgressWorksiteCreationDesign };
