import { Dispatch, SetStateAction, useContext } from 'react';
import { StepsEnumBeforeSimulation } from '@models/worksiteCreation/utils/enums';
import { Modal } from '@components/Modal';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { v4 } from 'uuid';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { getWorkflowSidebarSteps } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { OperationTypeEnum } from '@utils/enums';
import { ROLES } from '@utils/roles';
import { GlobalContext } from '@context/globalContext';
import { useTranslation } from 'react-i18next';

interface IStepChoiceOperationType {
  setStepFormBeforeSimulation: Dispatch<
    SetStateAction<StepsEnumBeforeSimulation>
  >;
}

function StepChoiceOperationType({
  setStepFormBeforeSimulation,
}: IStepChoiceOperationType) {
  const { t } = useTranslation();
  const {
    updateWorksiteOperationType,
    updateWorkflowSidebar,
    updateIsLoading,
    worksiteOperationType,
    worksiteDatas,
  } = useContext(WorksiteCreationContext);

  const { roleUser } = useContext(GlobalContext);

  const operationChoices = [
    {
      title: t(
        'worksite_creation.before_simulation.choice_operation_type.simple_operations'
      ),
      description: t(
        'worksite_creation.before_simulation.choice_operation_type.simple_operations_description'
      ),
      dataTestId: 'gesture_by_gesture_choice',
    },
    {
      title: t(
        'worksite_creation.before_simulation.choice_operation_type.major_renovation'
      ),
      operationType: OperationTypeEnum.GLOBAL_RENOVATION,
      dataTestId: 'global_renovation_choice',
      disabled: true,
    },
  ];

  const loadWorkflow = (operationType: number) => {
    getWorkflowSidebarSteps(
      null,
      updateWorkflowSidebar,
      updateIsLoading,
      operationType,
      worksiteDatas.worksites_operations
    );
  };

  return (
    <Modal
      title={t(
        'worksite_creation.before_simulation.choice_operation_type.title'
      )}
      closeModal
      withArrowBack
      onBackClick={() =>
        setStepFormBeforeSimulation((prevState) => prevState - 1)
      }
    >
      <div className="flex flex-col space-y-[1rem] mt-[1.5rem]">
        {operationChoices.map((choice) => (
          <button
            key={v4()}
            onClick={() => {
              setStepFormBeforeSimulation(
                roleUser === ROLES.GESTION
                  ? StepsEnumBeforeSimulation.CHOICE_INSTALLER
                  : (prevState) => prevState + 1
              );

              if (choice.operationType) {
                updateWorksiteOperationType(choice.operationType);
                loadWorkflow(choice.operationType);
              } else if (
                worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
              ) {
                updateWorksiteOperationType(OperationTypeEnum.B2C); /// TODO Attention! à modifier quand il y aura les chantiers Réno globale B2B la logique sera différente
                loadWorkflow(1);
              }
            }}
            type="button"
            className={`w-full bg-white border text-start border-borderGrey 
               rounded-default p-[1.5rem] flex items-start justify-between ${
                 choice.disabled
                   ? 'bg-greyOpacity20 cursor-not-allowed text-textGrey'
                   : 'hover:bg-backgroundBody hover:border-backgroundBody'
               }`}
            data-test-id={choice.dataTestId}
            disabled={choice.disabled}
          >
            <div className="w-11/12">
              <p className="text-[1rem] font-medium">{choice.title}</p>
              {choice.description && (
                <p className="text-[.875rem] mt-[.625rem]">
                  {choice.description}
                </p>
              )}
            </div>
            {choice.disabled ? (
              <p className="text-sm text-black whitespace-nowrap font-medium">
                {t(
                  'worksite_creation.before_simulation.choice_operation_type.available_soon'
                )}
              </p>
            ) : (
              <ColorCube
                opacity
                size="1.5rem"
                color={blueOpx}
                addClass="w-1/12"
                numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
              />
            )}
          </button>
        ))}
      </div>
    </Modal>
  );
}

export { StepChoiceOperationType };
