/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { LoaderWorksitesList } from '@components/loaders/worksites/LoaderWorksitesList';
import { WorksitesCard } from '@models/worksites/components/worksitesList/WorksitesCard';

import { getWorksites } from '@models/conventions/apiRequests/conventionRequests';

// import { IWorksiteOperationType } from '@models/conventions/utils/conventionTypes';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { toast } from 'react-toastify';

interface ITabWorksitesProps {
  conventionId: number;
}

function TabWorksites({ conventionId }: ITabWorksitesProps) {
  const { t } = useTranslation();

  const listInnerRef = useRef<HTMLDivElement>(null);

  const [numberPageList, setNumberPageList] = useState<number>(1);
  const [totalWorksites, setTotalWorksites] = useState<number>(0);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const numberLoaderCards = 5;
  const [endOfList, setEndOfList] = useState<boolean>(false);
  const [worksitesList, setWorksitesList] = useState<IWorksiteDetails[]>([]);

  const infiniteScroll = () => {
    if (listInnerRef.current) {
      // eslint-disable-next-line no-shadow
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight < scrollHeight + 1 &&
        scrollTop + clientHeight > scrollHeight - 1
      ) {
        if (!isLoading && totalWorksites > worksitesList.length) {
          setNumberPageList(numberPageList + 1);
          setIsLoading(true);
          getWorksites(
            conventionId,
            setWorksitesList,
            setIsLoadingList,
            numberPageList + 1,
            setTotalWorksites,
            setIsLoading,
            worksitesList
          );
        } else if (
          !endOfList &&
          totalWorksites === worksitesList.length &&
          numberPageList > 1
        ) {
          setEndOfList(true);
        }
      }
    }
  };

  useEffect(() => {
    if (Number(conventionId) > 0) {
      getWorksites(
        conventionId,
        setWorksitesList,
        setIsLoadingList,
        numberPageList,
        setTotalWorksites,
        setIsLoading,
        undefined
      );
    }
  }, [conventionId]);

  useEffect(() => {
    if (endOfList) {
      toast(t('global.end_list_reached'));
    }
  }, [endOfList]);

  return (
    <>
      {isLoadingList ? (
        [...Array(numberLoaderCards)].map(() => (
          <div key={v4()}>
            <LoaderWorksitesList />
          </div>
        ))
      ) : (
        <div data-test-id="worksites_list">
          {worksitesList.length === 0 ? (
            <div
              className="list_noElements flex items-center justify-center h-36 text-danger"
              data-test-id="no_result"
            >
              {t('list.no_result')}
            </div>
          ) : (
            <div
              onScroll={() => infiniteScroll()}
              ref={listInnerRef}
              className="w-full overflow-y-scroll flex-col flex space-y-[1.5rem] max-h-[80vh]"
            >
              {worksitesList.map((details: IWorksiteDetails) => (
                <WorksitesCard key={v4()} worksiteDetails={details} />
              ))}
              {isLoading && <LoaderWorksitesList />}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export { TabWorksites };
