import { Tag } from '@components/atomic/Tag';
import { grey, iconBlue, purple, red } from '@assets/color';
import { numberRegex } from '@utils/regex';
import React, { Dispatch, SetStateAction } from 'react';
import { ICardOperationType } from '@models/contracts/utils/contractTypes';
import { convertKiloToGiga, convertKiloToMega } from '@utils/functions';
import { useTranslation } from 'react-i18next';

interface OperationVolumesProps {
  item: ICardOperationType;
  isEditVolume: boolean;
  isDeposit: boolean;
  precaireValue: string;
  setPrecaireValue: Dispatch<SetStateAction<string>>;
  classiqueValue: string;
  setClassiqueValue: Dispatch<SetStateAction<string>>;
}

function OperationVolumes({
  item,
  isEditVolume,
  isDeposit,
  precaireValue,
  setPrecaireValue,
  classiqueValue,
  setClassiqueValue,
}: OperationVolumesProps) {
  const unit = isDeposit ? 'Gwhc' : 'MWhc';
  const convert = isDeposit ? convertKiloToGiga : convertKiloToMega;
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-1">
      {!!item.volume_classique && (
        <div className="flex items-center">
          <Tag
            color={iconBlue}
            label={t('global.classic')}
            addClass="h-fit w-[15rem] mr-2"
          />
          {isEditVolume && (
            <input
              style={{
                borderColor:
                  Number(classiqueValue.replace(',', '.')) >
                  Number(convert(item.volume_classique).replace(',', '.'))
                    ? red
                    : grey,
                borderWidth: '1px',
              }}
              className="rounded-default w-[4rem] px-[.5rem] mr-2"
              value={classiqueValue}
              onChange={(e) =>
                numberRegex.test(e.target.value) &&
                setClassiqueValue(e.target.value)
              }
            />
          )}
          <div>
            {isEditVolume ? '/' : ''} {convert(item.volume_classique)} {unit}
          </div>
        </div>
      )}
      {!!item.volume_precaire && (
        <div className="flex items-center">
          <Tag
            color={purple}
            label={t('global.precaire')}
            addClass="h-fit w-[15rem] px-[.25rem] mr-2"
          />
          {isEditVolume && (
            <input
              className="rounded-default w-[4rem] px-[.5rem] mr-2"
              value={precaireValue}
              onChange={(e) =>
                numberRegex.test(e.target.value) &&
                setPrecaireValue(e.target.value)
              }
              style={{
                borderColor:
                  Number(precaireValue.replace(',', '.')) >
                  Number(convert(item.volume_precaire).replace(',', '.'))
                    ? red
                    : grey,
                borderWidth: '1px',
              }}
            />
          )}
          <div>
            {isEditVolume ? '/' : ''} {convert(item.volume_precaire)} {unit}
          </div>
        </div>
      )}
    </div>
  );
}

export { OperationVolumes };
