/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Modal } from '@components/Modal';
import { SearchBar } from '@components/SearchBar';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { v4 } from 'uuid';
import { IAssociateType } from '@models/contracts/utils/newContractTypes';
import { getListPartners } from '@models/contracts/apiRequests/newContractRequests';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { t } from 'i18next';
import {
  ContractTypes,
  CreationSteps,
  SaleProfiles,
} from '@models/contractCreation/utils/enums';
import { getEntityTypes } from '@models/contractCreation/utils/functions';
import { ENTITY_TYPES } from '@utils/roles';
import { useLocation, useNavigate } from 'react-router-dom';
import { PARTNERS_ROUTES } from '@utils/routesUrls';

function AssociateSelection() {
  const {
    tunnel,
    changeStep,
    saleProfile,
    updateEntityTo,
    updateAmo,
    updateListAssociated,
    listAssociated,
    contractType,
  } = useContext(ContractCreationContext);

  if (!tunnel) return <div />;

  const textSingular = useMemo(() => {
    if (tunnel.contractType === ContractTypes.CONVENTION)
      return t('contract.association.installer');
    if (tunnel.contractType === ContractTypes.MANDAT)
      return t('contract.association.mandatory');
    if (tunnel.contractType === ContractTypes.TRIPARTITE) return 'AMO';

    if (saleProfile === SaleProfiles.BUYER)
      return t('contract.association.seller');
    if (saleProfile === SaleProfiles.SELLER)
      return t('contract.association.buyer');
    return t('contract.association.partner');
  }, [tunnel, saleProfile]);

  const [search, setSearch] = useState<string | null>(null);
  const [selected, setSelected] = useState<IAssociateType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = () => {
    const setEntity =
      contractType === ContractTypes.TRIPARTITE ? updateAmo : updateEntityTo;

    if (selected) {
      setEntity({
        id: selected.id,
        company_name: selected.company_name,
        siret: selected.siret,
        entityTypes: selected.entityTypes,
        address: {
          address_type: null,
          address: selected.address || '',
          city: selected.city || '',
          postal_code: selected.zipcode || '',
          country: '',
          latitude: null,
          longitude: null,
          elevation: null,
          selected: true,
          additional_address: null,
        },
        signatory_id: null,
      });
    }

    changeStep(CreationSteps.GENERAL);
  };

  const renderListElement = (
    element: IAssociateType,
    index: number,
    type: string
  ) => {
    return (
      <ButtonOpx
        type="secondary"
        label={`${element.company_name} - ${element.siret}`}
        onClick={() => {
          setSelected(
            selected?.id === Number(element.id) ? undefined : element
          );
        }}
        addClass={`w-full my-[0.5rem] ${
          selected?.id === element.id ? '!bg-blueOpx/[0.1]' : ''
        }`}
        textJustify="justify-start"
        dataTestId={`associate_${type}_${index}`}
      />
    );
  };

  const getLists = async () => {
    setIsLoading(true);
    const resAll = await getListPartners('all', tunnel.contractType, search);
    const resRecent = await getListPartners(
      'recent',
      tunnel.contractType,
      search
    );

    const entityTypeFilter = [
      ContractTypes.VENTE,
      ContractTypes.DELEGATION,
    ].includes(contractType)
      ? [ENTITY_TYPES.OBLIGE, ENTITY_TYPES.DELEGATAIRE]
      : undefined;

    setIsLoading(false);
    const recents = resRecent.data.map((elt: any) => ({
      ...elt,
      entityTypes: getEntityTypes(elt.entity_types, entityTypeFilter),
    }));

    const all = resAll.data.map((elt: any) => ({
      ...elt,
      entityTypes: getEntityTypes(elt.entity_types, entityTypeFilter),
    }));

    updateListAssociated({ recents, all });
  };

  useEffect(() => {
    getLists();
  }, [search]);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Modal
      title={`${t('contract.association.associate_to')} ${textSingular}`}
      withArrowBack
      onBackClick={() => {
        if (
          [ContractTypes.VENTE, ContractTypes.DELEGATION].includes(contractType)
        )
          changeStep(CreationSteps.SALE_PROFILE);
        else if (location.state && contractType)
          navigate(PARTNERS_ROUTES.PARTNERS);
        else changeStep(CreationSteps.TUNNEL);
      }}
      textBtnCancel={`${
        ['amo', 'installateur', 'installer'].includes(textSingular)
          ? t('contract.association.continue_with_an')
          : t('contract.association.continue_with_a')
      } ${textSingular}`}
      btnCancel
      textBtnConfirm={`${t('buttons.validate')}`}
      btnConfirmDisabled={!selected}
      onConfirmClick={onSubmit}
      onClickCancel={() => {
        setSelected(undefined);
        onSubmit();
      }}
      backgroundTransparent
      sidebarVisible
    >
      <>
        <div className="my-[1.5rem]">
          <SearchBar
            placeholder={`${t('search_bar.placeholder')}`}
            onSearch={(value: string) => setSearch(value)}
            searchOnEveryChange={false}
            width="100%"
          />
        </div>

        <p className="font-medium text-[1rem] mb-[1rem]">{`${t(
          'contract.association.recents'
        )}`}</p>

        {isLoading ? (
          Array.from(Array(3).keys()).map(() => (
            <div key={v4()} className="mb-[0.5rem]">
              <LoaderSkeleton height="3rem" addClass="w-full" />
            </div>
          ))
        ) : listAssociated.recents && listAssociated.recents.length > 0 ? (
          listAssociated.recents.map((element, index) => (
            <div key={v4()}>{renderListElement(element, index, 'recents')}</div>
          ))
        ) : (
          <p className="text-textGrey">{t('contract.association.no_items')}</p>
        )}

        <p className="font-medium text-[1rem] mt-[1.5rem] mb-[1rem]">{`${t(
          'contract.association.all_the'
        )} ${textSingular}s`}</p>

        {!listAssociated ? (
          Array.from(Array(4).keys()).map(() => (
            <div key={v4()} className="mb-[0.5rem]">
              <LoaderSkeleton height="3rem" addClass="w-full" />
            </div>
          ))
        ) : listAssociated.all && listAssociated.all.length > 0 ? (
          listAssociated.all.map((element, index) => (
            <div key={v4()}>{renderListElement(element, index, 'all')}</div>
          ))
        ) : (
          <p className="text-textGrey">{t('contract.association.no_items')}</p>
        )}
      </>
    </Modal>
  );
}

export { AssociateSelection };
