import { InputText } from '@components/atomic/inputs/InputText';
import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface IBoilersFormProps {
  graph: IGraph;
}

function BoilerForm({ graph }: IBoilersFormProps) {
  const { t } = useTranslation();
  const { updateSimulatorDataOperation, readOnly } = useContext(
    WorksiteCreationContext
  );
  // Nombre de chaudière
  const [numberUnits, setNumberUnits] = useState<number>(1);
  // Tableau avec les puissances des chaudières
  const [boilersData, setBoilersData] = useState<{ power: string }[]>([
    { power: '' },
  ]);

  // on créé autant de boilersData que de numberUnits
  useEffect(() => {
    setBoilersData((currentBoilers) => {
      const updatedBoilers: { power: string }[] = [];
      for (let i = 0; i < numberUnits; i += 1) {
        // Ajouter la chaudière existante si elle est déjà dans currentBoilers
        // Sinon, ajouter une nouvelle chaudière avec power vide
        updatedBoilers.push(currentBoilers[i] || { power: '' });
      }
      return updatedBoilers;
    });
  }, [numberUnits]);

  useEffect(() => {
    updateSimulatorDataOperation((prevState: any) => {
      const dataArray = Array.isArray(prevState) ? [...prevState] : [prevState];
      return dataArray.map((elt) => {
        if (
          elt['operation.id'] === 'bar-th-107' ||
          elt['operation.id'] === 'bar-th-107-se'
        ) {
          return { ...elt, [graph.key]: boilersData };
        }
        return elt;
      });
    });
  }, [boilersData]);

  return (
    <div className="space-y-[.5rem] flex flex-col pt-[1rem]">
      <p>{graph.name}</p>
      <InputText
        typeNumber
        placeholder={t(
          'worksite_creation.simulation.boiler_unit.number_boilers'
        )}
        onChange={(e) => {
          const lastChar = typeof e === 'string' ? e.charAt(e.length - 1) : '1';
          setNumberUnits(
            Number(lastChar) >= 1 ? Number(lastChar) : numberUnits
          );
        }}
        id="number_units"
        name="number_units"
        value={numberUnits.toString()}
        valueInitialInput={numberUnits.toString()}
        disabled={readOnly}
        addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
      />
      <div>
        {boilersData.map((boiler, index) => {
          return (
            <div
              className="flex flex-col space-y-[.5rem]"
              key={`boiler-unit-${index + 1}`}
            >
              <p className="pt-[1rem]">Chaudière numéro {index + 1}</p>
              <InputText
                typeNumber
                placeholder={`${t(
                  'worksite_creation.simulation.boiler_unit.power'
                )} (kW)`}
                onChange={(e) => {
                  const updatedBoilers = [...boilersData];
                  updatedBoilers[index] = { ...boiler, power: e.toString() };
                  setBoilersData(updatedBoilers);
                }}
                id={`boiler_unit${index + 1}`}
                name={`boiler_unit${index + 1}`}
                value={boiler.power}
                disabled={readOnly}
                addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BoilerForm;
