import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import { GlobalContext } from '@context/globalContext';

import { CardInformations } from '@components/informations/CardInformations';

import {
  IAccountUser,
  IAccountUserUpdateParams,
} from '@models/users/utils/userTypes';
import { updateUserInfos } from '@models/users/apiRequests/userRequests';

import { MailIcon, PhoneIcon } from '@assets/images/svgComponents';
import { AuthContext } from '@context/authContext';

interface ICardUserInfosProps {
  data: IAccountUser;
  loading: boolean;
  isAdmin: boolean;
  refetch: CallableFunction;
  addClass?: string;
}

function CardUserInfos({
  data,
  loading,
  isAdmin,
  refetch,
  addClass,
}: ICardUserInfosProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const { user, updateUser } = useContext(AuthContext);

  const profileTypeList = useMemo(() => {
    return globalEnum && globalEnum.profile_type
      ? Object.values(globalEnum.profile_type).map(
          (role: string) =>
            role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()
        )
      : [];
  }, [globalEnum]);

  const handleUpdateUser = async (formData: FieldValues) => {
    const resUserUpdate = await updateUserInfos(
      data.id,
      formData as IAccountUserUpdateParams
    );

    if (resUserUpdate) {
      if (data.id === user?.id) {
        updateUser((prevUser) => {
          if (!prevUser) return prevUser;
          return {
            ...prevUser,
            photo_url: resUserUpdate.photo_url,
          };
        });
      }
      refetch();
    }
  };

  const user_profile_type = useMemo(() => {
    const resProfile =
      globalEnum && globalEnum.profile_type
        ? globalEnum.profile_type[
            Number(
              Object.keys(globalEnum.profile_type).find(
                (key: string) => key === String(data.profile_type)
              ) || 1
            )
          ]
        : 1;
    return typeof resProfile === 'string'
      ? `${resProfile.charAt(0).toUpperCase()}${resProfile
          .slice(1)
          .toLowerCase()}`
      : resProfile;
  }, [globalEnum, data]);

  return (
    <CardInformations
      isAccountUser
      title={t('partners.general_infos')}
      data={{
        status: data.active,
        rows: [
          [
            {
              name: 'photo_url',
              value: data.photo_url,
              type: 'image',
              width: '6rem',
              height: '6rem',
              isEditable: true,
              addClass: '!ml-0',
              imageText: !data.photo_url ? t('global.photo') : '',
            },
            {
              name: 'firstname',
              title: t('forms.firstname.placeholder'),
              value: data.firstname,
              isEditable: isAdmin,
            },
            {
              name: 'lastname',
              title: t('forms.lastname.placeholder'),
              value: data.lastname,
              isEditable: isAdmin,
            },
            {
              name: 'identity_status',
              value: data.identity_status,
              isEditable: false,
            },
            {
              name: 'function',
              title: t('convention.tab_info_general.function'),
              value: data.function,
              isEditable: isAdmin,
            },
            {
              name: 'profile_type',
              title: t('my_account.role'),
              value: user_profile_type,
              type: 'select',
              list: profileTypeList,
              placeholder: t('my_account.role'),
            },
            {
              name: 'phone_number',
              title: t('forms.phone.placeholder'),
              value: data.phone_number,
              icon: <PhoneIcon />,
              isEditable: isAdmin,
              type: 'phone',
            },
            {
              name: 'email',
              title: t('partners.email_address'),
              value: data.email,
              icon: <MailIcon />,
              isEditable: isAdmin,
              type: 'email',
            },
          ],
        ],
      }}
      loading={loading}
      layout="infos-user"
      underlineHeader={false}
      hideTitles
      onEdit={handleUpdateUser}
      addClass={addClass}
    />
  );
}

CardUserInfos.defaultProps = { addClass: '' };

export { CardUserInfos };
