/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Card } from '@components/Card';
import { LoaderWorksitesList } from '@components/loaders/worksites/LoaderWorksitesList';
import { WorksitesCard } from '@models/worksites/components/worksitesList/WorksitesCard';
import { ListPagination } from '@components/atomic/pagination/ListPagination';

import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useContext } from 'react';
import { AuthContext } from '@context/authContext';
import { WORKSITES_ROUTES } from '@utils/routesUrls';

interface ICardWorksitesProps {
  data: any[];
  loading: boolean;
  type: 'worksite' | 'simulation';
  pagination: {
    paginationData: Record<string, string | number | null> | undefined;
    pagesDatas: { data: any; meta: any }[];
    getData: CallableFunction;
    pageAlreadyLoad: CallableFunction;
  };
}

function CardWorksites({
  data,
  loading,
  type,
  pagination,
}: ICardWorksitesProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { paginationData, pagesDatas, getData, pageAlreadyLoad } = pagination;
  const { user } = useContext(AuthContext);

  const worksitesRender = !data[0]
    ? t('partners.no_worksite')
    : data.map((details: IWorksiteDetails) => (
        <WorksitesCard key={v4()} worksiteDetails={details} isCompact />
      ));

  return (
    <Card
      title={t(
        type === 'worksite' ? 'sidebar.worksites' : 'beneficiaries.simulations'
      )}
      actionButtons={
        user?.permissions_names.includes('creer-chantier') ? (
          <ButtonOpx
            onClick={() => {
              navigate(WORKSITES_ROUTES.WORKSITES_NEW);
            }}
            label={
              type === 'worksite'
                ? t('worksite_creation.titles.new_worksite')
                : t('worksite_creation.titles.new_simulation')
            }
            type="primary"
            small
            addClass="!border-transparent"
          />
        ) : undefined
      }
    >
      <>
        {loading ? (
          [...Array(2)].map(() => (
            <div key={v4()}>
              <LoaderWorksitesList />
            </div>
          ))
        ) : data.length === 0 ? (
          <div className="list_noElements flex items-center justify-center h-36 text-danger">
            {t('list.no_result')}
          </div>
        ) : (
          <>
            <div className="flex flex-col space-y-2">{worksitesRender}</div>
            {paginationData && Number(paginationData.last_page) > 1 && (
              <ListPagination
                getData={(page) => {
                  if (page > pagesDatas.length) {
                    getData(page);
                  } else {
                    pageAlreadyLoad(page);
                  }
                }}
                paginationData={paginationData}
              />
            )}
          </>
        )}
      </>
    </Card>
  );
}

export { CardWorksites };
