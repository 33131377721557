import { Dispatch, SetStateAction } from 'react';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';

interface IUsersNewButtonProps {
  setModal: Dispatch<SetStateAction<boolean>>;
}

function UsersNewButton({ setModal }: IUsersNewButtonProps) {
  const { t } = useTranslation();

  return (
    <ButtonOpx
      onClick={() => setModal(true)}
      label={t('admin.user_new')}
      type="primary"
    />
  );
}

export { UsersNewButton };
