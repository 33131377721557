/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { useTranslation } from 'react-i18next';
import {
  backgroundGrey,
  blueOpx,
  borderGrey,
  red,
  textGrey,
  white,
} from '@assets/color';
import { useState } from 'react';
import { convertHexToRGBA } from '@utils/functions';
import { ColorCube } from '@components/atomic/ColorCube';
import { Tag } from '@components/atomic/Tag';

interface IOneOperationCardProps {
  nameOperation: string;
  code: string;
  isChecked: boolean;
  pictureOperationUrl: string;
  incompatible?: boolean;
  backgroundColorCheck?: string;
  handleCheck: (isChecked: boolean) => void;
  disabled?: boolean;
  addClass?: string;
}

function OneOperationCard({
  nameOperation,
  code,
  incompatible,
  pictureOperationUrl,
  isChecked,
  handleCheck,
  backgroundColorCheck,
  disabled,
  addClass,
}: IOneOperationCardProps) {
  const { t } = useTranslation();
  const [isOpChecked, setIsOpChecked] = useState(isChecked);
  const [isShown, setIsShown] = useState(false);
  const codeSplited = code.split('-');
  const shortLabelFromCode = codeSplited[1] ? codeSplited[1].toUpperCase() : '';

  return (
    <div
      role="button"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      tabIndex={0}
      onClick={() => {
        if (!disabled && !incompatible) {
          setIsOpChecked(!isOpChecked);
          handleCheck(!isOpChecked);
        }
      }}
      style={{
        backgroundColor: incompatible
          ? convertHexToRGBA(red, 0.1)
          : isOpChecked
          ? convertHexToRGBA(backgroundColorCheck || blueOpx, 0.1)
          : !isOpChecked && isShown
          ? backgroundGrey
          : white,
        border:
          isShown || incompatible || isOpChecked
            ? '1px solid transparent'
            : `1px solid ${borderGrey}`,
      }}
      className={[
        `${
          incompatible ? 'cursor-not-allowed' : ''
        } rounded-default p-[1rem]  flex justify-between items-center`,
        addClass,
      ].join(' ')}
      data-test-id={`operation_card_${code}`}
    >
      <div className="flex space-x-4 items-center">
        {pictureOperationUrl !== '' ? (
          <img
            className="w-[2.5rem] h-[2.5rem]"
            src={pictureOperationUrl}
            alt={nameOperation}
          />
        ) : (
          <ColorCube
            numberOrIcon={
              <div className="flex items-center justify-center">
                {shortLabelFromCode}
              </div>
            }
            size="2.5rem"
            color={blueOpx}
            opacity
          />
        )}
        <div>
          <p className="text-[1rem]">{nameOperation}</p>
          {incompatible && (
            <p className="text-[.625rem] text-red">
              {t('worksite_creation.incompatible_operation_warning')}
            </p>
          )}
          <div className="mt-1">
            <Tag color={textGrey} label={code} />
          </div>
        </div>
      </div>

      {!incompatible && !disabled && (
        <Checkbox
          label={nameOperation}
          onCheck={(isCheck) => handleCheck(isCheck)}
          backgroundColorChecked={blueOpx}
          checked={isOpChecked}
        />
      )}
    </div>
  );
}

export { OneOperationCard };

OneOperationCard.defaultProps = {
  incompatible: false,
  backgroundColorCheck: blueOpx,
  addClass: '',
  disabled: false,
};
