import { axiosClient } from '@api/apiClient';
import {
  urlCertifications,
  urlGetCertification,
  urlGetCertificationsList,
} from '@api/apiUrls';
import { displayError } from '@utils/format';

export const getCertificationsList = async (
  entityId: number,
  page: number,
  perPage: number
) => {
  try {
    const response = await axiosClient.post(
      urlGetCertificationsList(entityId),
      {
        pagination: { page, perPage },
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return null;
};

export const getCertificationById = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetCertification(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
  return null;
};

export const createAndEditCertification = async (
  certificationData: Record<string, any>
) => {
  try {
    const formData = new FormData();

    Object.keys(certificationData).forEach((key) => {
      const value = certificationData[key];
      if (key === 'uploadedFile' && value) {
        formData.append(key, value);
      } else if (key !== 'linked_file' && value) {
        formData.append(
          key,
          value instanceof Date ? value.toISOString() : value.toString()
        );
      }
    });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await axiosClient.post(
      urlCertifications,
      formData,
      config
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
  }
  return null;
};

export const deleteCertification = async (certificationId: number) => {
  try {
    const response = await axiosClient.delete(
      urlGetCertification(certificationId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return null;
};
