import { useContext } from 'react';
import { IUserAccessItem, IUserRight } from '@models/users/utils/userTypes';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { v4 } from 'uuid';
import { Toggle } from '@components/atomic/inputs/controls/Toggle';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';

interface IAccessSectionProps {
  loadingAccess: boolean;
  access?: IUserRight;
  selectedAccessList: IUserAccessItem[];
  setSelectedAccessList: React.Dispatch<
    React.SetStateAction<IUserAccessItem[]>
  >;
  dataTestId: string;
}

function AccessSection({
  loadingAccess,
  access,
  selectedAccessList,
  setSelectedAccessList,
  dataTestId,
}: IAccessSectionProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  return (
    <div className="mb-8 border border-borderGrey rounded-default p-6">
      <p className="font-[500] text-[1.250rem] leading-[2rem] pb-[.25rem]">
        <p>{t('my_account.access_management')}</p>
      </p>
      <p className="text-[.875rem] leading-[1.250rem] mb-8">
        {t('my_account.add_access_description')}
      </p>

      {loadingAccess ? (
        <div className="flex flex-wrap items-center my-3 py-3">
          <LoaderSkeleton
            height="2.5rem"
            addClass="w-full md:w-[25%] md:mr-[0.5rem]"
          />
        </div>
      ) : (
        (access ? access.access_list : []).map(
          (accessItem: IUserAccessItem, index) => {
            const isToggleOn = Boolean(
              selectedAccessList.find(
                (itemAccess: IUserAccessItem) =>
                  itemAccess.access === accessItem.access
              )?.toggle
            );

            return (
              <button
                key={v4()}
                type="button"
                data-test-id={`${dataTestId}_${index}`}
                className="w-full flex justify-between items-center border border-borderGrey rounded-default p-2 mb-2"
                onClick={() => {
                  const selectedAccessIndex = selectedAccessList.findIndex(
                    (itemAccess: IUserAccessItem) =>
                      itemAccess.access === accessItem.access
                  );
                  const tmpAccesses = selectedAccessList;
                  tmpAccesses[selectedAccessIndex] = {
                    ...tmpAccesses[selectedAccessIndex],
                    toggle: !isToggleOn,
                  };

                  setSelectedAccessList([...tmpAccesses]);
                }}
              >
                <p>{globalEnum.access_type[accessItem.access]}</p>
                <Toggle
                  label={globalEnum.access_type[accessItem.access]}
                  isToggleOn={isToggleOn}
                  onToggle={() => null}
                />
              </button>
            );
          }
        )
      )}
      {}
    </div>
  );
}

export default AccessSection;

AccessSection.defaultProps = {
  access: undefined,
};
