import { Dispatch, SetStateAction } from 'react';
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { saveAs } from 'file-saver';
import { getLinkedFileDocumentAnalysisCSV } from '@apiRequests/globalRequests';
import { IDocumentAnalysis, ILinkedFile } from '../../types/globalTypes';

interface IModalDocumentAnalysisProps {
  document: ILinkedFile;
  content: IDocumentAnalysis | null;
  setModal: Dispatch<SetStateAction<boolean>>;
}

function ModalDocumentAnalysis({
  document,
  setModal,
  content,
}: IModalDocumentAnalysisProps) {
  const { t } = useTranslation();

  const exportAnalysis = async () => {
    try {
      const response = await getLinkedFileDocumentAnalysisCSV(
        document.id as number
      );
      const csv = `${response.headers.join(';')}\n${response.rows
        .map((row: string[]) =>
          row.map((cell) => (cell.includes(';') ? `"${cell}"` : cell)).join(';')
        )
        .join('\n')}`;
      const blob = new Blob([`\uFEFF${csv}`], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(blob, `export_${document.file_name}_analysis.csv`);
    } catch (error) {
      console.error('Error downloading document analysis csv:', error);
    }
  };

  return (
    <div>
      <Modal
        title={t('global.document_analysis_report')}
        backgroundTransparent
        sidebarVisible
        setIsModal={setModal}
        btnCancel
        textBtnExport={t('deposits.btn_export_csv') || ''}
        btnExportOnClick={exportAnalysis}
      >
        <div className="w-full">
          <div className="grid grid-cols-3">
            <div className="flex-grow m-2 font-bold">
              {t('global.document_analysis_report_title_errors')}:{' '}
              {content?.non_compliance.length}
            </div>
            <div className="flex-grow m-2 font-bold">
              {t('global.document_analysis_report_title_warnings')}:{' '}
              {content?.suspected_non_compliance.length}
            </div>
            <div className="flex-grow m-2 font-bold">
              {t('global.document_analysis_report_title_data')}:
            </div>
          </div>
          <div className="h-80 overflow-y-scroll grid grid-cols-3">
            <div className="flex-grow">
              {content?.non_compliance.map((message: string) => (
                <div
                  className="text-[.875rem] rounded-default m-2 p-2 bg-red text-white"
                  key={v4()}
                >
                  {message}
                </div>
              ))}
            </div>
            <div className="flex-grow">
              {content?.suspected_non_compliance?.map((message: string) => (
                <div
                  className="text-[.875rem] rounded-default m-2 p-2 bg-orange text-white"
                  key={v4()}
                >
                  {message}
                </div>
              ))}
            </div>
            <div className="flex-grow">
              {content?.extracted_data &&
                Object.keys(content.extracted_data).map((key) => {
                  const value = content.extracted_data[key] ?? 'N/A';
                  if (Array.isArray(value)) {
                    return (
                      <div
                        className="text-[.875rem] rounded-default m-2 p-2 bg-backgroundBody"
                        key={v4()}
                      >
                        {key}: {value.join(', ')}
                      </div>
                    );
                  }
                  return (
                    <div
                      className="text-[.875rem] rounded-default m-2 p-2 bg-backgroundBody"
                      key={v4()}
                    >
                      {key}: {value.toString()}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export { ModalDocumentAnalysis };
