import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@components/atomic/Tag';
import { grey, textGrey } from '@assets/color';
import { getBeneficiaryName } from '@models/worksiteCreation/utils/functions';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { ButtonsHeaderWorksiteCreation } from './ButtonsHeaderWorksiteCreation';

function HeaderWorksiteCreation() {
  const { t } = useTranslation();
  const { beneficiary, worksiteDatas, worksiteOperationType, simulatorData } =
    useContext(WorksiteCreationContext);

  const headerRef = useRef<HTMLDivElement>(null);

  const titleLeft = t(
    beneficiary !== null
      ? 'worksite_creation.titles.new_worksite'
      : 'worksite_creation.titles.new_simulation'
  );

  const nameBeneficiary =
    beneficiary !== null
      ? `- ${getBeneficiaryName(beneficiary, worksiteOperationType)}`
      : '';

  const worksiteName =
    worksiteDatas.site_name || simulatorData['general.worksiteName'];

  const titleDisplay = `${titleLeft} ${
    worksiteName ? `- ${worksiteName}` : nameBeneficiary
  }`;

  return (
    <header
      ref={headerRef}
      className="w-full p-[2rem] md:flex justify-between items-center border-b border-b-borderGrey bg-white"
    >
      <div className="flex">
        {nameBeneficiary !== '' ? (
          <>
            <h2
              className="text-[1.688rem] font-medium"
              data-test-id="title_header_worksite_creation"
            >
              {titleDisplay}
            </h2>
            {worksiteDatas.reference !== '' && (
              <>
                &nbsp;
                <Tag
                  color={textGrey}
                  label={worksiteDatas.reference}
                  addClass="ml-4"
                  dataTestId="reference_worksite_creation"
                />
              </>
            )}
          </>
        ) : (
          <>
            <LoaderDivSkeleton
              height="2.5rem"
              width="21rem"
              backgroundColor={grey}
            />
            <LoaderDivSkeleton
              height="2.5rem"
              width="8rem"
              backgroundColor={grey}
              addClass="ml-4"
            />
          </>
        )}
      </div>
      <ButtonsHeaderWorksiteCreation />
    </header>
  );
}

export { HeaderWorksiteCreation };
