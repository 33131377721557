import { useContext } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { OneAddress } from '@models/contractCreation/components/steps/general/OneAddress';
import { useTranslation } from 'react-i18next';

function WorksiteAddresses() {
  const { t } = useTranslation();
  const { worksiteAddresses } = useContext(ContractCreationContext);

  return (
    <div>
      <p className="font-medium my-[1rem]">{t('contract.worksite_address')}</p>
      <div className="flex flex-col gap-[1rem]">
        <OneAddress action="add" index={0} />
        {worksiteAddresses.map((wa, index) => (
          <OneAddress action="delete" index={index} key={wa.address} />
        ))}
      </div>
    </div>
  );
}

export { WorksiteAddresses };
