import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { handleUploadClick } from '@utils/functions';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from '@utils/utils';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ILinkedFile } from '../../../../types/globalTypes';

interface DragAndDropZoneProps {
  onUpload: (file: File, originalLinkedFile: ILinkedFile) => void;
  document: ILinkedFile;
  children: JSX.Element;
  addClass?: string;
  disabled?: boolean;
}
function DragAndDropZone({
  onUpload,
  document,
  children,
  disabled,
  addClass,
}: DragAndDropZoneProps) {
  const [isDragging, setIsDragging] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files; // Access dropped files
    if (files[0].size > MAX_FILE_SIZE) {
      toast.error(
        t('global.file_too_large', { maxFileSize: MAX_FILE_SIZE_MB })
      );
      return;
    }
    if (!disabled) {
      await onUpload(files[0], document);
    }
  };

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      if (files[0].size > MAX_FILE_SIZE) {
        toast.error(
          t('global.file_too_large', { maxFileSize: MAX_FILE_SIZE_MB })
        );
        if (hiddenFileInput.current) {
          hiddenFileInput.current.value = ''; // réinitialiser la valeur de l'input
        }
        return;
      }
      await onUpload(files[0], document);
    }
  };

  const acceptFileType = useMemo(() => {
    if (document.file_type === 6 || document.file_type === 12) {
      return '.jpg,.jpeg,.png';
    }
    return '.pdf';
  }, [document.file_type]);

  return (
    <div
      className={`${addClass} ${
        isDragging ? 'bg-greyOpacity20' : ''
      } bg-backgroundBody`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(e) => !disabled && onUploadFile(e)}
        style={{ display: 'none' }}
        accept={acceptFileType}
        onClick={(e) => {
          e.stopPropagation();
        }}
        disabled={disabled}
      />
      <button
        type="button"
        onClick={() => handleUploadClick(hiddenFileInput)}
        className="w-full"
      >
        <div>{children}</div>
      </button>
    </div>
  );
}

DragAndDropZone.defaultProps = {
  disabled: false,
  addClass: '',
};
export { DragAndDropZone };
