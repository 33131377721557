import { useEffect, useState } from 'react';
import { ButtonOpx } from '../atomic/ButtonOpx';

interface ITabsLeftPartProps {
  titlesList: string[];
  onClick: (title: string, index: number) => void;
  backgroundColorButton?: string;
  textColorButton?: string;
  icon?: JSX.Element;
  activeButton?: string;
  smallButton?: boolean;
  addClass?: string;
}

function TabsLeftPart({
  titlesList,
  onClick,
  addClass,
  backgroundColorButton,
  textColorButton,
  icon,
  activeButton,
  smallButton,
}: ITabsLeftPartProps): JSX.Element {
  const [buttonActive, setButtonActive] = useState<string>(
    activeButton || titlesList[0]
  );

  const handleClick = (title: string, index: number) => {
    setButtonActive(title);
    onClick(title, index);
  };

  useEffect(() => {
    setButtonActive(activeButton || titlesList[0]);
  }, [titlesList, activeButton]);

  return (
    <div
      className={[
        'flex items-center flex-wrap -mx-[0.5rem] -my-[0.5rem]', // Ajoute un négatif margin-x et margin-y pour compenser la marge des enfants
        addClass,
      ].join(' ')}
    >
      {titlesList.map((title: string, index) => (
        <div key={title} className="p-[0.5rem]">
          <ButtonOpx
            label={title}
            onClick={() => handleClick(title, index)}
            type="nav"
            active={(buttonActive || activeButton || titlesList[0]) === title}
            backgroundColor={backgroundColorButton}
            color={textColorButton}
            icon={icon}
            small={smallButton}
            dataTestId="button_sub_header"
          />
        </div>
      ))}
    </div>
  );
}

export { TabsLeftPart };

TabsLeftPart.defaultProps = {
  backgroundColorButton: '',
  textColorButton: '',
  icon: null,
  activeButton: '',
  smallButton: false,
  addClass: '',
};
