import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { Modal } from '@components/Modal';
import { OperationPriceInputs } from '@models/contractCreation/components/steps/operationPrices/OperationPriceInputs';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { GlobalContext } from '@context/globalContext';
import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import { useFormContext } from 'react-hook-form';
import {
  BeneficiaryTypes,
  OperationPriceTypes,
} from '@models/contractCreation/utils/enums';
import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { convertUnitPricesToFixPrices } from '@models/contractCreation/utils/functions';

interface OperationPricesActionsProps {
  isConvention: boolean;
  selectedIds: number[];
  setSelectedIds: Dispatch<SetStateAction<number[]>>;
}
function OperationPricesActions({
  isConvention,
  setSelectedIds,
  selectedIds,
}: OperationPricesActionsProps) {
  const {
    operationPrices,
    updateOperationPrices,
    updateListOperations,
    contract,
    listOperations,
    beneficiaryType,
    incentiveType,
  } = useContext(ContractCreationContext);
  const {
    globalEnum: { operation_price_type },
  } = useContext(GlobalContext);

  const {
    formState: { errors },
  } = useFormContext();

  const emptyPriceValue = { operation_id: 0, label: '' };

  const [priceValue, setPriceValue] = useState<
    IContractOperationPrice | undefined
  >(emptyPriceValue);

  const [showModal, setShowModal] = useState(false);
  const [operationPriceType, setOperationPriceType] = useState(
    contract?.price_type || 1
  );
  const [withCdp, setWithCdp] = useState(false);
  const [fixCdp, setFixCdp] = useState(false);
  const [withMinimumAmount, setWithMinimumAmount] = useState(false);
  const [differentIncentivePrice, setDifferentIncentivePrice] = useState(false);

  const { reset, formState } = useFormContext();

  const operations =
    listOperations?.filter((lo) => selectedIds.includes(lo.id)) || [];

  const hasCdp = useMemo(() => {
    return operations.some((operation) => operation && operation.has_cdp);
  }, [operations]);

  const { t } = useTranslation();

  const chooseOperationPriceType = (label: string) => {
    const value = Object.entries(operation_price_type).find(
      (val) => val[1] === label
    );
    if (value && Number(value[0]) !== operationPriceType) {
      setOperationPriceType(Number(value[0]));
    }
  };

  const updateSelectedOperations = () => {
    if (priceValue) {
      const newOperationPrices = operationPrices.map((op) => {
        let newPriceValue: IContractOperationPrice = {
          ...priceValue,
        };

        const operation = listOperations?.find((o) => o.id === op.operation_id);

        Object.keys(newPriceValue).forEach((key) => {
          const keyValue = key as keyof IContractOperationPrice;

          if (!operation?.has_cdp) {
            if (key.includes('_cdp_')) delete newPriceValue[keyValue];
          }

          if (!operation?.code.includes('BAR-') && isConvention) {
            if (key.includes('_classique')) {
              const uniqueKey = key.replace(
                'classique',
                'unique'
              ) as keyof IContractOperationPrice;

              newPriceValue = {
                ...newPriceValue,
                [uniqueKey]: newPriceValue[keyValue],
              };
            }

            if (!key.includes('_unique')) {
              delete newPriceValue[keyValue];
            }
          }
        });

        if (selectedIds.includes(op.operation_id)) {
          return {
            ...newPriceValue,
            operation_id: op.operation_id,
            label: op.label,
          };
        }

        return op;
      });

      updateOperationPrices(newOperationPrices);
      setShowModal(false);
    }
  };

  const allOperationSelected = operationPrices.every((op) =>
    selectedIds.includes(op.operation_id)
  );

  const showMinimumAmountInputs = selectedIds.length > 0 && withMinimumAmount;

  const handleSelectAllOperation = () => {
    if (allOperationSelected) setSelectedIds([]);
    else setSelectedIds(operationPrices.map((op) => op.operation_id));
  };

  function transformOperations(op: IOperation): IOperationType {
    return {
      id: op.id,
      code: op.code,
      description: op.description,
      can_have_material: op.can_have_material,
      has_cdp: op.has_cdp ?? false, // Utilisation de la valeur par défaut false si has_cdp est undefined
      has_mpr: op.has_mpr ?? false, // Utilisation de la valeur par défaut false si has_mpr est undefined
      incompatibilities: op.incompatibilities,
    };
  }

  const handleDeleteOperation = () => {
    updateOperationPrices((prevState) =>
      prevState.filter((op) => !selectedIds.includes(op.operation_id))
    );

    const operationsPricesDeleted = operationPrices.filter((op) =>
      selectedIds.includes(op.operation_id)
    );
    const operationsDeleted: IOperationType[] = operationsPricesDeleted
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((opd) => {
        if (opd.operation) {
          return transformOperations(opd.operation);
        }
      })
      .filter((op): op is IOperationType => op !== undefined);
    if (listOperations) {
      updateListOperations([...listOperations, ...operationsDeleted]);
    }

    setSelectedIds([]);
  };

  const incentiveName =
    incentiveType === INCENTIVE_TYPE.INDIRECT ? 'indirect' : 'direct';

  const incentiveArrayData: ('direct' | 'indirect')[] =
    isConvention &&
    incentiveType === INCENTIVE_TYPE.MIXED &&
    differentIncentivePrice
      ? ['direct', 'indirect']
      : [incentiveName];

  const priceTypeValues: string[] = useMemo(() => {
    let priceTypes = Object.entries(operation_price_type).map((val) => val);

    const onlyB2b =
      operations.length > 0 &&
      !operations.some((op) => op.code.toLowerCase().includes('bar-'));

    if (beneficiaryType === BeneficiaryTypes.ENTITY) {
      priceTypes = priceTypes.filter(
        (p) => Number(p[0]) !== OperationPriceTypes.INCOMES
      );
    }

    if (isConvention) {
      if (onlyB2b) {
        setOperationPriceType(OperationPriceTypes.UNIQUE);
        priceTypes = priceTypes.filter(
          (p) => Number(p[0]) === OperationPriceTypes.UNIQUE
        );
      } else {
        setOperationPriceType(OperationPriceTypes.CLASSIQUE_PRECAIRE);
      }
    }

    return priceTypes.map((val) => val[1]);
  }, [selectedIds]);

  useEffect(() => {
    convertUnitPricesToFixPrices(priceValue, setPriceValue, fixCdp);
  }, [fixCdp]);

  useEffect(() => {
    setPriceValue(emptyPriceValue);
    setWithCdp(false);
    setWithMinimumAmount(false);
    setDifferentIncentivePrice(false);
    reset();
  }, [showModal]);

  return (
    <div className="w-full mt-[2rem] pb-[1rem] flex justify-between border-b">
      <div className="flex items-center gap-3">
        <Checkbox
          label=""
          checked={allOperationSelected}
          onCheck={handleSelectAllOperation}
        />
        <div>{t('global.select_all')}</div>
      </div>
      <div className="flex gap-3">
        <ButtonOpx
          label={t('buttons.delete')}
          type="tab"
          addClass="border-red"
          disabled={selectedIds.length < 1}
          onClick={handleDeleteOperation}
        />
        <ButtonOpx
          label={t('buttons.update')}
          disabled={selectedIds.length < 1}
          onClick={() => setShowModal(true)}
        />
      </div>
      {showModal && (
        <div>
          <Modal
            title={t('contract.update_operations')}
            backgroundTransparent
            sidebarVisible
            width="60%"
            textBtnConfirm={t('buttons.validate') || ''}
            btnCancel
            textBtnCancel={t('buttons.cancel') || ''}
            buttonsPosition="bottom"
            onConfirmClick={updateSelectedOperations}
            onClickCancel={() => setShowModal(false)}
            btnConfirmDisabled={
              !formState.isValid || Object.values(errors).length > 0
            }
            maxHeight="90%"
            isOverflowAuto
          >
            <div className="mt-[1rem]">
              {isConvention && incentiveType === INCENTIVE_TYPE.MIXED && (
                <div className="flex gap-3 items-center">
                  <Checkbox
                    label=""
                    checked={differentIncentivePrice}
                    onCheck={() =>
                      setDifferentIncentivePrice(!differentIncentivePrice)
                    }
                  />
                  <div>{t('convention.different_incentive_price')}</div>
                </div>
              )}

              {isConvention && (
                <InputSelect
                  placeholder=""
                  dataArrayString={priceTypeValues}
                  defaultSelected={operation_price_type[operationPriceType]}
                  onSelect={(value) => chooseOperationPriceType(value)}
                  addClass="my-3"
                  disabled={priceTypeValues.length < 2}
                />
              )}
              {incentiveArrayData.map((incentive) => (
                <OperationPriceInputs
                  isLoading={false}
                  operationPriceType={operationPriceType}
                  operations={operations}
                  isConvention={isConvention}
                  action="edit"
                  selectedIds={selectedIds}
                  priceValue={priceValue}
                  setPriceValue={setPriceValue}
                  incentive={incentive}
                  differentIncentivePrice={incentiveArrayData.length > 1}
                />
              ))}

              {isConvention && hasCdp && (
                <div>
                  <div className="flex gap-3 items-center my-3">
                    <Checkbox
                      label=""
                      checked={withCdp}
                      onCheck={() => setWithCdp(() => !withCdp)}
                    />
                    <div>{t('convention.with_cdp_prices')}</div>
                  </div>
                  {withCdp && (
                    <>
                      <InputSelect
                        addClass="my-3"
                        placeholder=""
                        dataArrayString={[
                          t('contract.unit_price'),
                          t('contract.fix_price'),
                        ]}
                        onSelect={(value) =>
                          setFixCdp(value === t('contract.fix_price'))
                        }
                        valueInput={
                          fixCdp
                            ? t('contract.fix_price')
                            : t('contract.unit_price')
                        }
                      />
                      {incentiveArrayData.map((incentive) => (
                        <OperationPriceInputs
                          operationPriceType={operationPriceType}
                          action="edit"
                          priceValue={priceValue}
                          setPriceValue={setPriceValue}
                          isLoading={false}
                          isConvention={isConvention}
                          isCdp
                          fixCdp={fixCdp}
                          selectedIds={selectedIds}
                          operations={operations}
                          incentive={incentive}
                          differentIncentivePrice={
                            incentiveArrayData.length > 1
                          }
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
              {isConvention && (
                <div>
                  <div className="flex gap-3 items-center my-3">
                    <Checkbox
                      label=""
                      checked={withMinimumAmount}
                      onCheck={() =>
                        setWithMinimumAmount(() => !withMinimumAmount)
                      }
                    />
                    <div>{t('convention.define_minimum_prices')}</div>
                  </div>
                  {showMinimumAmountInputs &&
                    incentiveArrayData.map((incentive) => {
                      const inputsData = [
                        { display: true, isCdp: false },
                        { display: withCdp, isCdp: true },
                      ];

                      return inputsData.map(
                        (elt) =>
                          elt.display && (
                            <OperationPriceInputs
                              operationPriceType={operationPriceType}
                              action="edit"
                              priceValue={priceValue}
                              setPriceValue={setPriceValue}
                              isLoading={false}
                              isConvention
                              selectedIds={selectedIds}
                              operations={operations}
                              isMinimumAmount
                              incentive={incentive}
                              differentIncentivePrice={
                                incentiveArrayData.length > 1
                              }
                              isCdp={elt.isCdp}
                              fixCdp={elt.isCdp && fixCdp}
                            />
                          )
                      );
                    })}
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export { OperationPricesActions };
