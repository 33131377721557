import { Card } from '@components/Card';
import { InputText } from '@components/atomic/inputs/InputText';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useState } from 'react';
import { ModalPasswordValidation } from '@models/users/components/ModalPasswordValidation';
import { sendPasswordCode } from '@models/auth/apiRequests/authRequests';
import { yupResolver } from '@hookform/resolvers/yup';
import { getChangePasswordSchema } from '@utils/validationSchemas';

function CardPassword(): JSX.Element {
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(getChangePasswordSchema(t)),
  });
  const { handleSubmit, watch } = methods;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    await sendPasswordCode(data, setShowModal);
    setIsLoading(false);
  };

  return (
    <div>
      <Card title="" hidePanel addClass="mt-6">
        <div className="flex">
          <div className="w-1/2 text-xl font-semibold">
            {t('auth.reset_pwd.update')}
          </div>
          <FormProvider {...methods}>
            <form
              id="form-change-pwd"
              className="w-1/2 flex flex-col gap-y-5"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputText
                id="old_password"
                name="old_password"
                type="password"
                placeholder={t('forms.password.placeholder')}
                required
                label={`${t('forms.password.old_pwd')}`}
              />
              <InputText
                id="new_password"
                name="new_password"
                type="password"
                placeholder={t('forms.password.placeholder')}
                required
                label={`${t('forms.password.new_pwd')}`}
              />
              <ButtonOpx
                label={t('buttons.update')}
                addClass="w-[8rem]"
                isLoading={isLoading}
                disabled={isLoading}
                isSubmitBtn
                formId="form-change-pwd"
              />
            </form>
          </FormProvider>
        </div>
      </Card>
      {showModal && (
        <ModalPasswordValidation
          newPassword={watch('new_password')}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
}

export { CardPassword };
