import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AUTH_ROUTES } from '@utils/routesUrls'; // Assurez-vous d'avoir react-router-dom installé

// Définir l'interface pour Beacon
interface Beacon {
  (command: string, ...args: any[]): void;
}

// Étendre l'interface Window pour inclure Beacon
declare global {
  interface Window {
    Beacon: Beacon;
  }
}

function ChatbotScript() {
  const location = useLocation();

  const loadChatbotScript = () => {
    // Injection de la première partie du script
    const loaderScript = document.createElement('script');
    loaderScript.innerHTML = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
    document.body.appendChild(loaderScript);

    // Injection de la seconde partie du script après un délai
    setTimeout(() => {
      const initScript = document.createElement('script');
      initScript.innerHTML = `window.Beacon('init', '03a33df1-5410-4bc2-b106-86228c9bdc98')`;
      document.body.appendChild(initScript);
    }, 1000); // Le délai de 1000 ms est arbitraire, ajustez selon les besoins
  };

  const hideChatbot = () => {
    // Utiliser 'window.Beacon('toggle', false)' pour masquer le chatbot
    if (window.Beacon && typeof window.Beacon === 'function') {
      window.Beacon('destroy', true);
    }
  };

  useEffect(() => {
    const chatbotPaths = [AUTH_ROUTES.REGISTER];

    if (chatbotPaths.includes(location.pathname)) {
      loadChatbotScript();
    } else {
      hideChatbot();
    }
  }, [location]);

  return null;
}

export default ChatbotScript;
