import QuotaCeeList from '@components/requestsAndChanges/card/requests/QuotaCeeList';
import { useTranslation } from 'react-i18next';

function DashboardQuotaCeeList() {
  const { t } = useTranslation();

  const handleViewAllClick = () => {
    console.log('View all clicked');
  };

  return (
    <div className="flex flex-col w-full bg-white border border-borderGrey rounded-default">
      <div className="flex items-center justify-between py-6 px-4 border-b border-borderGrey">
        <div className="flex items-center justify-between flex-grow">
          <div className="text-neutral-900 text-xl font-medium">
            {t('dashboard.quota_cee_per_applicant')}
          </div>
          <button
            type="button"
            className="text-neutral-400 text-sm font-normal leading-tight"
            onClick={handleViewAllClick}
          >
            {t('dashboard.views_all')}
          </button>
        </div>
      </div>
      <QuotaCeeList />
    </div>
  );
}

export default DashboardQuotaCeeList;
