/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState, useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { format, parse } from 'date-fns';

import { ListTable, IColumn, IRow } from '@components/ListTable';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getPartnerConventions } from '@models/partners/apiRequests/partnersFormRequest';

import { IPartnerConvention } from '@models/partners/utils/types/partnersType';
import { dateToDDMMYYY } from '@utils/format';
import { IPartnerWorksite } from '@models/partners/utils/types/partnerWorksiteType';
import { Tag } from '@components/atomic/Tag';
import { lightBlue, red } from '@assets/color';
import { GlobalContext } from '@context/globalContext';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { CONTRACTS_ROUTES_WITH_ID } from '@utils/routesUrls';

function TabConventions() {
  const { globalEnum } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<IPartnerConvention[]>([]);
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const getSumWorksiteOperations = (worksites: IPartnerWorksite[]) => {
    return worksites
      .map(
        (worksiteFiltered: IPartnerWorksite) =>
          worksiteFiltered.worksitesOperations
      )
      .flat()
      .reduce(
        (sum, cur) =>
          sum +
          Number(cur?.kwhc_classique || 0) +
          Number(cur?.kwhc_precaire || 0),
        0
      );
  };

  const getData = async (pageParam: number) => {
    setLoading(true);
    const resList = await getPartnerConventions({
      page: pageParam,
      perPage: 5,
      entityId: Number(id),
      operationType: 1,
    });
    if (resList) {
      const formattedData = resList.data.map(
        (convention: IPartnerConvention) => {
          const precaires = convention.worksites.filter(
            (worksite: IPartnerWorksite) =>
              worksite.precarity_type !== null && worksite.precarity_type < 4
          );

          const classiques = convention.worksites.filter(
            (worksite: IPartnerWorksite) =>
              worksite.precarity_type !== null && worksite.precarity_type > 3
          );

          return {
            ...convention,
            sum_volume_ops_classique_kwhc: getSumWorksiteOperations(classiques),
            sum_volume_ops_precaire_kwhc: getSumWorksiteOperations(precaires),
          };
        }
      );

      setData(formattedData);
      setPaginationData(resList.meta);

      if (resList?.current_page > pagesDatas.length) {
        setPagesDatas([...pagesDatas, { data: resList.data, meta: resList }]);
      }
    }
    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const columnHelper = createColumnHelper<IPartnerConvention>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('reference', {
        header: () => t('contract.reference_opx'),
      }),
      columnHelper.accessor('contract_type', {
        header: () => t('contract.contract_type'),
        cell: (info) => {
          return (
            <p>{globalEnum.contract_type[info.row.original.contract_type]}</p>
          );
        },
      }),
      columnHelper.accessor('start_date', {
        header: () => t('contract.contract_start_date'),
        cell: (info) => dateToDDMMYYY(info.getValue()),
      }),
      columnHelper.accessor('end_date', {
        header: () => t('contract.contract_end_date'),
        cell: (info) => dateToDDMMYYY(info.getValue()),
      }),
      columnHelper.accessor('active', {
        header: () => t('contract.status'),
        cell: (info) => {
          const isActive = info.getValue();
          return (
            <Tag
              color={isActive ? lightBlue : red}
              label={
                isActive ? t('convention.active') : t('convention.expired')
              }
            />
          );
        },
      }),
      columnHelper.accessor('created_at', {
        header: () => t('global.creation'),
        cell: (info) => {
          const dateString = info.getValue();
          let formattedDate = null;

          if (dateString) {
            const dateParts = dateString.split(' ')[0];
            formattedDate = parse(dateParts, 'yyyy-MM-dd', new Date());
          }

          return (
            <span>
              {formattedDate ? format(formattedDate, 'dd/MM/yyyy') : '-'}
            </span>
          );
        },
      }),
    ],
    [data]
  );

  useEffect(() => {
    getData(1);
  }, [id]);

  return (
    <div className="w-full items-center justify-center py-5">
      <ListTable
        loading={loading}
        columns={columns as IColumn[]}
        data={data}
        callBackOnRowClick={(e: IRow) => {
          const rowData = e.original as IPartnerConvention;
          navigate(
            rowData.contract_type === ContractTypes.CONVENTION
              ? CONTRACTS_ROUTES_WITH_ID(rowData.id).CONVENTION_INSTALLER_VIEW
              : CONTRACTS_ROUTES_WITH_ID(rowData.id).CONVENTION_BENEFICIARY_VIEW
          );
        }}
      />

      {paginationData && Number(paginationData.last_page) > 1 && (
        <ListPagination
          getData={(page) => {
            if (page > pagesDatas.length) {
              getData(page);
            } else {
              pageAlreadyLoad(page);
            }
          }}
          paginationData={paginationData}
        />
      )}
    </div>
  );
}

export { TabConventions };
