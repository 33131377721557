/* eslint-disable no-nested-ternary */
import {
  backgroundGrey,
  blueOpx,
  green,
  lightGreen,
  lightRed,
  red,
  white,
} from '@assets/color';
import { ChevronLeftIcon, CrossIcon } from '@assets/images/svgComponents';
import {
  useEffect,
  useState,
  useMemo,
  useRef,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';
import { ColorCube } from '@components/atomic/ColorCube';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { v4 } from 'uuid';
import { Loader } from '@components/atomic/Loader';
import { convertHexToRGBA } from '@utils/functions';
import { useTranslation } from 'react-i18next';

interface IInputSelectProps {
  placeholder: string;
  // Si les datas sont un tableau de string
  dataArrayString?: string[];
  // Si les datas sont un tableau d'objet de type label et value
  dataLabelValue?: { label: string; value: string }[];
  onSelect?: (value: string) => void;
  onSelectLabelValue?: (value: { label: string; value: string }) => void;
  defaultSelected?: string;
  isMultipleSelect?: boolean;
  label?: string;
  addClass?: string;
  valueInput?: string | null;
  // Ajoute le map dans le dropdown si autre qu'un tableau de string
  children?: JSX.Element;
  error?: boolean;
  textError?: string;
  valid?: boolean;
  showClearButton?: boolean;
  isAutoComplete?: boolean;
  onDropDown?: (value: boolean) => void;
  callbackOnSearch?: (value: string) => void;
  color?: 'danger' | 'success';
  borderRadius?: number;
  disabled?: boolean;
  required?: boolean;
  hideScrollBar?: boolean;
  onScrollToBottom?: (page: number) => void;
  closeDropdown?: boolean;
  setCloseDropdown?: Dispatch<SetStateAction<boolean>>; // ferme le dropdown depuis une action du composant parent
  defaultChecked?: string[];
  onChangeValue?: (value: string | string[]) => void;
  resetValue?: boolean;
  isLoading?: boolean;
  dataTestIdOptions?: string;
  dataTestIdSelect?: string;
  dataTestIdSearch?: string;
  onClear?: () => void;
}

function InputSelect({
  dataArrayString,
  placeholder,
  defaultSelected,
  onSelect,
  isMultipleSelect,
  label,
  valueInput,
  addClass,
  children,
  dataLabelValue,
  onSelectLabelValue,
  error,
  textError,
  valid,
  showClearButton,
  isAutoComplete,
  onDropDown,
  callbackOnSearch,
  color,
  borderRadius,
  disabled,
  required,
  hideScrollBar,
  onScrollToBottom,
  closeDropdown,
  setCloseDropdown,
  defaultChecked,
  onChangeValue,
  resetValue,
  isLoading,
  dataTestIdOptions,
  dataTestIdSelect,
  dataTestIdSearch,
  onClear,
}: IInputSelectProps): JSX.Element {
  const initialValueUsed = defaultChecked || defaultSelected || placeholder;
  const [value, setValue] = useState(initialValueUsed);
  const { t } = useTranslation();
  const [isDropDown, setIsDropdown] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [list, setList] = useState<string[]>(dataArrayString || []);
  const [page, setPage] = useState<number>(1);

  const listInnerRef = useRef<HTMLDivElement>(null);
  const childrenInnerRef = useRef<HTMLDivElement>(null);

  const infiniteScroll = () => {
    if (listInnerRef.current) {
      // eslint-disable-next-line no-shadow
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight < scrollHeight + 0.1 &&
        scrollTop + clientHeight > scrollHeight - 0.1
      ) {
        setPage((prevState) => prevState + 1);
        if (onScrollToBottom) onScrollToBottom(page + 1);
      }
    }
  };

  const handleCheck = (isChecked: boolean, labelCheck: string) => {
    if (isMultipleSelect) {
      setValue((oldValue) => {
        const valueTyped = oldValue as string[];
        if (!isChecked && !valueTyped.includes(labelCheck)) {
          return [...valueTyped, labelCheck].filter((el) => el !== placeholder);
        }
        return valueTyped.filter((el) => el !== labelCheck);
      });
    }
  };

  const onClickOnOption = (item: any) => {
    if (typeof item === 'string') {
      if (isMultipleSelect) {
        if (value.includes(item)) {
          setValue((oldValue) => {
            const valueTyped = oldValue as string[];
            return [...valueTyped, item];
          });
        }
      } else {
        setValue(item);
        setIsDropdown(false);
        if (setCloseDropdown) setCloseDropdown(true);
      }
      if (onSelect) {
        onSelect(item);
      }
    } else {
      if (isMultipleSelect) {
        if (value.includes(item.label)) {
          setValue((oldValue) => {
            const valueTyped = oldValue as string[];
            return [...valueTyped, item.label];
          });
        }
      } else {
        setValue(item?.selectedItem ? item?.selectedItem : item);
        setIsDropdown(false);
        if (setCloseDropdown) setCloseDropdown(true);
      }
      if (onSelectLabelValue) onSelectLabelValue(item);
    }
  };

  const handleSearch = (keyword: string) => {
    setSearch(keyword);
    if (callbackOnSearch) {
      callbackOnSearch(keyword);
    } else {
      setList(
        keyword !== ''
          ? list.filter((item: string) => item.includes(keyword))
          : dataArrayString || []
      );
    }
  };

  const textColor = useCallback(
    (inline = false) => {
      if (color === 'danger') {
        return inline ? red : 'text-red';
      }
      if (color === 'success') {
        return inline ? green : 'text-success';
      }
      if (
        (isMultipleSelect &&
          (value[0] === placeholder || value[0] === undefined)) ||
        value === placeholder
      ) {
        if (valueInput && valueInput !== '' && valueInput !== placeholder) {
          return 'text-black';
        }
        return 'text-textGrey';
      }
      return 'text-black';
    },
    [color, isMultipleSelect, value, valueInput]
  );

  const renderInputText = () => {
    if (isAutoComplete) {
      let val =
        valueInput && valueInput !== ''
          ? (valueInput as any)?.selectedItem
            ? (valueInput as any)?.selectedItem
            : valueInput
          : !isMultipleSelect
          ? value
          : '';
      val = val === placeholder ? '' : val;

      return (
        <input
          type="text"
          value={val !== '' ? val : search}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!disabled) {
              setIsDropdown(true);
              if (setCloseDropdown) setCloseDropdown(false);
            }
          }}
          placeholder={placeholder}
          disabled={disabled}
          className={isLoading || disabled ? 'bg-backgroundBody' : 'w-[95%]'}
          data-test-id={dataTestIdSearch || 'input_search'}
        />
      );
    }

    if (valueInput && valueInput !== '') {
      return (valueInput as any)?.selectedItem
        ? (valueInput as any)?.selectedItem
        : valueInput;
    }

    if (!isMultipleSelect) {
      return (value as any)?.selectedItem
        ? (value as any)?.selectedItem
        : value;
    }

    const arrayValue = value as string[];
    if (arrayValue.length === 0 || typeof arrayValue === 'string') {
      return placeholder;
    }

    return arrayValue.map((el, index) => (
      <p key={v4()}>
        {el}
        {value.length > 0 && index < value.length - 1 && ','}&nbsp;
      </p>
    ));
  };

  const borderStyle = () => {
    if (error) {
      return 'border-red';
    }
    if (valid) {
      return 'border-green';
    }
    return 'border-borderGrey';
  };

  const bgColor = (): string => {
    switch (color) {
      case 'danger':
        return lightRed;
      case 'success':
        return lightGreen;
      default:
        return '';
    }
  };

  useMemo(() => {
    setList(dataArrayString || []);
  }, [dataArrayString]);

  // Permet de fermer le dropdown lorsque l'on clique en dehors de celui ci
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        childrenInnerRef.current &&
        !childrenInnerRef.current.contains(event.target as Node)
      ) {
        setIsDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (onDropDown) onDropDown(isDropDown);
  }, [isDropDown]);

  /* useEffect(() => {
    if (defaultSelected) setValue(defaultSelected);
  }, [defaultSelected]); */

  useEffect(() => {
    if (closeDropdown) setIsDropdown(false);
  }, [closeDropdown]);

  useEffect(() => {
    if (onChangeValue) onChangeValue(value);
  }, [value]);

  useEffect(() => {
    if (valueInput) {
      if (valueInput !== '') {
        if (setCloseDropdown) setCloseDropdown(false);
        else setIsDropdown(false);
      }
    } else {
      setValue('');
    }
  }, [valueInput]);

  useEffect(() => {
    if (defaultSelected) {
      setValue(defaultSelected);
    } else if (defaultChecked) {
      setValue(defaultChecked);
    }
  }, []);

  useEffect(() => {
    if (resetValue) {
      setValue('');
      setSearch('');
    }
  }, [resetValue]);

  return (
    <div
      className={`relative ${addClass}`}
      style={{ backgroundColor: bgColor(), borderRadius }}
    >
      {label && (
        <p className="text-textGrey text-[.75rem] leading-3 mb-2">
          {label}{' '}
          <span>{`${required ? '' : `(${t('global.optional')})`}`}</span>
        </p>
      )}
      <button
        style={{ backgroundColor: disabled ? backgroundGrey : 'transparent' }}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            setIsDropdown(!isDropDown);
            if (setCloseDropdown) setCloseDropdown(isDropDown);
          }
        }}
        type="button"
        className={`flex items-center justify-between w-full p-[.5rem] py-[.6rem] text-[.875rem] ${
          isDropDown ? 'rounded-t-default' : 'rounded-default'
        } border ${borderStyle()} relative`}
        data-test-id={dataTestIdSelect ?? 'input_select'}
      >
        <div
          className={`ml-[.25rem] flex items-center whitespace-nowrap ${textColor()} ${
            valueInput === placeholder ? '' : 'font-normal'
          } w-[85%] overflow-hidden`}
        >
          {renderInputText()}
        </div>
        {showClearButton && !disabled && (
          <ColorCube
            numberOrIcon={<CrossIcon />}
            size="1.5rem"
            color={red}
            opacity
            onClick={() => {
              setIsDropdown(false);
              if (setCloseDropdown) setCloseDropdown(true);
              setValue(initialValueUsed);

              handleSearch('');

              if (onSelect) onSelect(initialValueUsed as string);
              if (onClear) onClear();
            }}
            addClass="mr-2"
            dataTestId={`${dataTestIdSelect || 'input_select'}_close`}
          />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <ColorCube
            numberOrIcon={
              <ChevronLeftIcon
                className={`${
                  isDropDown ? 'rotate-[90deg]' : 'rotate-[-90deg]'
                } transform duration-200 m-auto`}
              />
            }
            backgroundColor={!color ? '' : white}
            size="1.5rem"
            color={!color ? blueOpx : textColor(true)}
            opacity
            onClick={() => {
              if (!disabled) setIsDropdown(!isDropDown);
              if (setCloseDropdown) setCloseDropdown(isDropDown);
            }}
            addClass={` ${disabled && 'cursor-not-allowed'}`}
            dataTestId="close_dropdown"
          />
        )}
      </button>

      <div className="absolute max-h-[15rem] w-full bg-white z-10">
        <div
          className={`flex flex-col text-[.875rem] overflow-y-${
            hideScrollBar ? 'invisible' : 'auto'
          } border-r border-r-borderGrey border-l border-l-borderGrey rounded-b-default w-full max-h-[15rem]`}
          id="dropdown"
          ref={listInnerRef}
          onScroll={infiniteScroll}
        >
          {isDropDown &&
            !children &&
            dataLabelValue &&
            dataLabelValue
              ?.filter((el) => el.label !== renderInputText())
              .map((item, index) => {
                return (
                  <button
                    type="button"
                    onClick={() => {
                      onClickOnOption(item);
                      handleCheck(
                        (value as any).label === item.label,
                        item.label
                      );
                    }}
                    className={`flex items-center text-start px-[1rem] py-[.75rem] border-b border-b-borderGrey ${
                      index ===
                      dataLabelValue.filter(
                        (el) => el.label !== renderInputText()
                      ).length -
                        1
                        ? 'rounded-b-default'
                        : ''
                    }`}
                    key={item.label}
                    value={item.label}
                    data-test-id={dataTestIdOptions}
                  >
                    {isMultipleSelect && (
                      <Checkbox
                        checked={value.includes(item.label)}
                        colorChecked={white}
                        label={item.label}
                        backgroundColorChecked={blueOpx}
                        addClass="mr-[.5rem]"
                      />
                    )}
                    <p>{item.label}</p>
                  </button>
                );
              })}
          {isDropDown &&
            !children &&
            !dataLabelValue &&
            dataArrayString
              ?.filter((el) => el !== renderInputText())
              .map((item, index) => {
                return (
                  <button
                    key={v4()}
                    type="button"
                    onClick={() => {
                      onClickOnOption(item);
                      handleCheck(value.includes(item), item);
                    }}
                    className={`flex items-center text-start px-[1rem] py-[.75rem] border-b border-b-borderGrey ${
                      index ===
                      dataArrayString.filter((el) => el !== renderInputText())
                        .length -
                        1
                        ? 'rounded-b-default'
                        : ''
                    } ${
                      value.includes(item) ? 'bg-blueOpx bg-opacity-10' : ''
                    }`}
                    value={
                      (item as any)?.selectedItem
                        ? (item as any)?.selectedItem
                        : item
                    }
                    style={{ backgroundColor: 'white' }}
                  >
                    {isMultipleSelect && (
                      <Checkbox
                        checked={value.includes(item)}
                        colorChecked={white}
                        label={item}
                        /* onCheck={(isChecked: boolean) =>
                          handleCheck(isChecked, item)
                        } */
                        backgroundColorChecked={blueOpx}
                        addClass="mr-[.5rem]"
                      />
                    )}
                    <p data-test-id={dataTestIdOptions}>
                      {(item as any)?.selectedItem
                        ? (item as any)?.selectedItem
                        : item}
                    </p>
                  </button>
                );
              })}
          {isDropDown && children && (
            <div ref={childrenInnerRef}>{children}</div>
          )}
        </div>
      </div>
      {error && textError && (
        <div
          className="rounded-default p-[.5rem] text-red text-sm mt-[.5rem]"
          style={{
            backgroundColor: convertHexToRGBA(red, 0.1),
          }}
        >
          {textError}
        </div>
      )}
    </div>
  );
}

export { InputSelect };

InputSelect.defaultProps = {
  defaultSelected: undefined,
  isMultipleSelect: false,
  label: '',
  addClass: '',
  children: undefined,
  dataArrayString: [''],
  onSelect: undefined,
  valueInput: null,
  dataLabelValue: undefined,
  onSelectLabelValue: undefined,
  error: false,
  textError: undefined,
  valid: false,
  showClearButton: false,
  isAutoComplete: false,
  callbackOnSearch: undefined,
  onDropDown: undefined,
  color: undefined,
  borderRadius: 0,
  disabled: false,
  required: false,
  hideScrollBar: false,
  onScrollToBottom: undefined,
  closeDropdown: false,
  setCloseDropdown: undefined,
  defaultChecked: undefined,
  onChangeValue: undefined,
  resetValue: false,
  isLoading: false,
  dataTestIdOptions: undefined,
  dataTestIdSelect: undefined,
  dataTestIdSearch: undefined,
  onClear: undefined,
};
