/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, getYear, parse } from 'date-fns';
import { v4 } from 'uuid';

import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Tag } from '@components/atomic/Tag';
import { ColorCube } from '@components/atomic/ColorCube';
import { Modal } from '@components/Modal';

import { blueOpx, red, textGrey } from '@assets/color';
import { EditIcon, TrashIcon } from '@assets/images/svgComponents';
import { convertKiloToMega } from '@utils/functions';
import { DEPOSITS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { ITransferType, IVolumeDetailType } from '../utils/contractTypes';
import { deleteTransfer } from '../apiRequests/contractRequests';
import { LinePlot } from './LinePlot';

interface ITabVolumeDetailProps {
  contractId: number;
  data: IVolumeDetailType | undefined;
  refresh: () => void;
}

function TabVolumeDetail(props: ITabVolumeDetailProps) {
  const { contractId, data, refresh } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const thisYear = getYear(new Date());
  const lastYear = thisYear - 1;

  const [activeYear, setActiveYear] = useState(thisYear);
  const [deleteId, setDeleteId] = useState<number>();

  const transfersDone: ITransferType[] = useMemo(() => {
    return data ? data.transfersDone : [];
  }, [data, contractId]);

  const transfersInProgress: ITransferType[] = useMemo(() => {
    return data ? data.transfersInProgress : [];
  }, [data, contractId]);

  const handleDeleteTransfer = async () => {
    if (deleteId) {
      setDeleteId(undefined);
      await deleteTransfer(Number(deleteId));
      refresh();
    }
  };

  const renderActionButton = (type: string, transfer: ITransferType) => {
    const editUrl = DEPOSITS_ROUTES_WITH_ID(transfer.id).TRANSFER_VIEW;
    if (type === 'inProgress') {
      return (
        <div className="flex flex-nowrap">
          <ColorCube
            color={blueOpx}
            numberOrIcon={<EditIcon />}
            onClick={() => navigate(editUrl)}
            size="2rem"
            opacity
            addClass="mr-[.5rem] rounded"
          />
          <ColorCube
            color={red}
            numberOrIcon={<TrashIcon />}
            onClick={() => setDeleteId(transfer.id)}
            size="2rem"
            opacity
            addClass="rounded"
          />
        </div>
      );
    }

    return (
      <ButtonOpx
        type="secondary"
        label={t('contract.tab_volume_detail.consult')}
        onClick={() => {
          navigate(editUrl);
        }}
      />
    );
  };

  const renderTransferElement = (
    index: number,
    transfer: ITransferType,
    type: string
  ) => {
    const nb = index + 1;
    const nbStr = nb < 10 ? `00${nb}` : nb < 100 ? `0${nb}` : nb;
    const titleText =
      type === 'completed'
        ? `N° ODT ${transfer.odt_number}`
        : `${t('contract.tab_volume_detail.transfer')} n° ${nbStr}`;

    let dateLabel = 'global.created_at';
    let dateValue = transfer.created_at;

    if (transfer.validation_date) {
      dateLabel = 'contract.tab_volume_detail.validation_date';
      dateValue = transfer.validation_date;
    } else if (transfer.created_at === transfer.last_update) {
      dateLabel = 'contract.tab_volume_detail.last_update';
    }
    return (
      <div className="p-6 border border-transparent border-b-borderGrey">
        <div className="flex flex-wrap justify-between items-center mb-6">
          <div className="flex flex-nowrap items-center">
            <div className="mr-[1rem] text-[1.25rem] leading-8">
              {titleText}
            </div>
            <Tag
              color={textGrey}
              label={`${transfer.count} ${t(
                'contract.tab_volume_detail.operation'
              )}${transfer.count > 1 ? 's' : ''}`}
              addClass="h-fit"
            />
          </div>
          {renderActionButton(type, transfer)}
        </div>
        <div className="flex flex-wrap items-center space-x-4 text-[.875rem]">
          <div>{`${t(
            'contract.tab_volume_detail.volume_classic'
          )} : ${convertKiloToMega(transfer.gisement.classique)} MWhc`}</div>
          <div>{`${t(
            'contract.tab_volume_detail.volume_precaire'
          )} : ${convertKiloToMega(transfer.gisement.precaire)} MWhc`}</div>
          <div>{`${t(dateLabel)} : ${format(
            parse(dateValue, 'yyyy-MM-dd HH:mm:ss', new Date()),
            'dd/MM/yyyy'
          )}`}</div>
        </div>
      </div>
    );
  };

  const renderListTransfers = (
    title: string,
    list: ITransferType[],
    type: string
  ) => {
    return (
      <div className="border border-borderGrey rounded-default2 bg-white">
        <p className="border border-solid border-transparent border-b-borderGrey p-6 font-medium text-[1.25rem]">
          {title}
        </p>
        {list && list.length > 0 ? (
          list.map((transfer, idx) => (
            <div key={v4()}>{renderTransferElement(idx, transfer, type)}</div>
          ))
        ) : (
          <p className="p-6">{t('contract.tab_volume_detail.no_items')}</p>
        )}
      </div>
    );
  };

  return (
    <div>
      <Card
        title={`${t('contract.tab_volume_detail.volume_delivered')}`}
        actionButtons={
          <>
            {[lastYear, thisYear].map((year) => (
              <ButtonOpx
                key={v4()}
                type="secondary"
                label={`${year}`}
                onClick={() => setActiveYear(year)}
                active={year === activeYear}
              />
            ))}
          </>
        }
        addClass="mb-6"
        dataTestId="volume_detail_card"
      >
        <LinePlot
          data={transfersDone}
          activeYear={activeYear}
          xAxisReference="gisement"
        />
      </Card>
      {renderListTransfers(
        t('contract.tab_volume_detail.transfers_in_progress'),
        transfersInProgress,
        'inProgress'
      )}
      <div className="my-[1.5rem]" />
      {renderListTransfers(
        t('contract.tab_volume_detail.transfers_completed'),
        transfersDone,
        'completed'
      )}
      {deleteId && (
        <Modal
          title={t('contract.tab_volume_detail.delete_transfer')}
          textBtnConfirm={`${t('buttons.confirm')}`}
          onConfirmClick={handleDeleteTransfer}
          btnCancel
          setIsModal={() => setDeleteId(undefined)}
          backgroundTransparent
        >
          <p className="mt-4">
            {t('contract.tab_volume_detail.confirm_delete')}
          </p>
        </Modal>
      )}
    </div>
  );
}

export { TabVolumeDetail };
