/* eslint-disable react/no-unstable-nested-components */
import { Card } from '@components/Card';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import {
  ContactPageIcon,
  EuroIcon,
  InfoCircleIcon,
} from '@assets/images/svgComponents';
import {
  IBuyingPriceType,
  IContactType,
  IEntityType,
} from '@models/conventions/utils/conventionTypes';
import React, { useEffect, useMemo, useState } from 'react';
import { IColumn, ListTable } from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
import { Tag } from '@components/atomic/Tag';
import { blueSecondary, orange } from '@assets/color';
import { numberWithSeparator } from '@utils/format';

interface TabIntermediaryBusinessProps {
  id: number;
  fetchData: (id: number, isConvention: boolean) => Promise<any>;
  isConvention: boolean;
  commission: number | undefined;
  intermediary: IEntityType | undefined;
  contact: IContactType | undefined;
}
function TabIntermediaryBusiness({
  id,
  fetchData,
  isConvention,
  commission,
  intermediary,
  contact,
}: TabIntermediaryBusinessProps) {
  const [data, setData] = useState<IBuyingPriceType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getData = async () => {
    setIsLoading(true);
    const response = await fetchData(id, isConvention);
    setIsLoading(false);
    setData(response);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const columnHelper = createColumnHelper<IBuyingPriceType>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('operation.code', {
        header: () => t('contract.business.table.operation'),
        cell: (info) => {
          const op = info.row.original.operation;
          return <div>{op.description} </div>;
        },
      }),
      columnHelper.accessor('operation.id', {
        header: () => t('contract.business.table.gisement'),
        cell: (info) => {
          return (
            <div className="flex flex-col gap-2">
              <Tag color={blueSecondary} label={t('global.classic')} />
              {Number(info.row.original.prix_unitaire_precaire) > 0 && (
                <Tag color={orange} label={t('global.precaire')} />
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('prix_unitaire_precaire', {
        header: () => t('contract.business.table.price'),
        cell: (info) => {
          return (
            <div className="flex flex-col gap-2">
              <div>
                {`${numberWithSeparator(
                  String(info.row.original.prix_unitaire_classique || 0)
                )} €/MWhc`}
              </div>
              {Number(info.row.original.prix_unitaire_precaire) > 0 && (
                <div>
                  {`${numberWithSeparator(
                    String(info.row.original.prix_unitaire_precaire || 0)
                  )} €/MWhc`}
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('prix_unitaire_precaire', {
        header: () => 'Commission',
        cell: () => `${numberWithSeparator(String(commission || ''))} €/MWhc`,
      }),
      columnHelper.accessor('prix_unitaire_precaire', {
        header: () => 'Total',
        cell: (info) => {
          const comPrice = commission ?? 0;
          const totalPuc =
            (info.row.original.prix_unitaire_classique || 0) + comPrice;

          const totalPuP =
            (info.row.original.prix_unitaire_precaire || 0) + comPrice;
          return (
            <div className="flex flex-col gap-2">
              <div>{`${numberWithSeparator(String(totalPuc))} €/MWhc`}</div>
              {Number(info.row.original.prix_unitaire_precaire) > 0 && (
                <div>{`${numberWithSeparator(String(totalPuP))} €/MWhc`}</div>
              )}
            </div>
          );
        },
      }),
    ];
  }, [data]);

  return (
    <div className="flex gap-[1rem] items-baseline ">
      <Card title={t('contract.business.tab_title')} addClass="w-1/2">
        <div>
          <div className="border-0 border-b border-borderGrey pb-[1rem]">
            <div className="flex pb-[1rem]">
              <InfosWithIcon
                infos={[
                  {
                    title: t('partners.company_name'),
                    subtitle: intermediary?.company_name || '',
                  },
                ]}
                icon={<InfoCircleIcon />}
                addClass="w-1/2"
              />
              <InfosWithIcon
                infos={[
                  {
                    title: t('partners.siret'),
                    subtitle: intermediary?.siret || '',
                  },
                ]}
              />
            </div>
            <InfosWithIcon
              infos={[
                {
                  title: t('partners.head_office_address'),
                  subtitle: `${intermediary?.address}, ${intermediary?.zipcode} ${intermediary?.city}`,
                },
              ]}
              icon={<div />}
              colorIcon="transparent"
            />
          </div>
          <div className="pt-[1rem]">
            <div className="flex pb-[1rem]">
              <InfosWithIcon
                infos={[
                  {
                    title: 'Contact',
                    subtitle: `${contact?.firstname || ''} ${
                      contact?.lastname || ''
                    }`,
                  },
                ]}
                icon={<ContactPageIcon />}
                addClass="w-1/2"
              />
              <InfosWithIcon
                infos={[
                  {
                    title: t('worksites.partner.function'),
                    subtitle: contact?.function || '',
                  },
                ]}
              />
            </div>
            <div className="flex">
              <InfosWithIcon
                infos={[
                  { title: t('infos.email'), subtitle: contact?.email || '' },
                ]}
                icon={<div />}
                colorIcon="transparent"
                addClass="w-1/2"
              />
              <InfosWithIcon
                infos={[
                  {
                    title: t('infos.phone'),
                    subtitle: contact?.phone_number || '',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card title="Commission" addClass="w-1/2">
        <div>
          <InfosWithIcon
            infos={[
              {
                title: t('contract.business.table.commission'),
                subtitle: `${numberWithSeparator(
                  String(commission || '')
                )}€/MWhc`,
              },
            ]}
            icon={<EuroIcon />}
          />
          <ListTable
            data={data}
            columns={columns as IColumn[]}
            type="secondary"
            addClass="border border-borderGrey mt-[1.5rem]"
            hideArrow
            loading={isLoading}
          />
        </div>
      </Card>
    </div>
  );
}

export { TabIntermediaryBusiness };
