import { useEffect, useState, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';

import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { CardInformations } from '@components/informations/CardInformations';
import { CardContacts } from '@models/partners/components/informations/cards/CardContacts';
import { CardSirets } from '@models/partners/components/informations/cards/CardSirets';

import { getPartnerGeneralInfos } from '@models/partners/apiRequests/partnersFormRequest';
import { getContacts } from '@models/users/apiRequests/userRequests';

import { IPartnerInfos } from '@models/partners/utils/types/partnersType';
import { DEFAULT_GENERAL_INFOS } from '@models/partners/utils/partnersConstants';
import { ROLES } from '@utils/roles';

import {
  ContactPageIcon,
  InfoCircleIcon,
  PositionIcon,
} from '@assets/images/svgComponents';
import { IUserType } from '@models/auth/utils/types';
import { IContactReferentType } from '@models/partners/utils/types/partnerContactType';

function TabInformations({ entityType }: { entityType: number | null }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { roleUser, partnersMenu } = useContext(GlobalContext);
  const { updateTitleHeader } = useContext(HeaderContext);

  const [data, setData] = useState<IPartnerInfos>(DEFAULT_GENERAL_INFOS);
  const [contactList, setContactList] = useState<IUserType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [referent, setReferent] = useState<IContactReferentType | null>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);

    const resGeneralInfos = await getPartnerGeneralInfos({
      entityId: Number(id),
      entityType: entityType || undefined,
    });

    if (resGeneralInfos) {
      setData(resGeneralInfos);
      setReferent(resGeneralInfos.referent);
      setCanEdit(resGeneralInfos.canEdit);
    }

    const resContacts = await getContacts();

    if (resContacts) {
      setContactList(resContacts);
    }

    setLoading(false);
  };

  const showSiretCard = useMemo(() => roleUser === ROLES.GESTION, [roleUser]);

  useEffect(() => {
    if (partnersMenu.length > 0) {
      getData();
    }
  }, [partnersMenu, id]);

  useEffect(() => {
    updateTitleHeader(data.info_generales.company_name || 'loading');
  }, [data]);

  return (
    <div className="grid gap-x-0 md:gap-x-[1.5%] gap-y-4 grid-cols-1 md:grid-cols-[68.5%_30%] mt-6">
      {/* GENERAL INFOS */}
      {data.info_generales && (
        <CardInformations
          title={t('partners.general_infos')}
          data={{
            status: data.info_generales.active,
            rows: [
              [
                {
                  name: 'company_name',
                  title: t('partners.company_name'),
                  value: data.info_generales.company_name,
                  icon: <InfoCircleIcon />,
                },
                {
                  name: 'logo',
                  value: data.info_generales.logo,
                  type: 'image',
                },
                {
                  name: 'siret',
                  title: t('partners.siret'),
                  value: data.info_generales.siret,
                },
                {
                  name: 'siren',
                  title: t('partners.siren'),
                  value: data.info_generales.siren,
                },
                {
                  name: 'iban_payment',
                  title: t('partners.iban'),
                  value: data.info_generales.iban_payment,
                },
              ],
              [
                {
                  name: 'address',
                  title: t('partners.postal_address'),
                  value:
                    typeof data.info_generales.address === 'string'
                      ? `${data.info_generales.address || ''} ${
                          data.info_generales.zipcode || ''
                        } ${data.info_generales.city || ''} ${
                          data.info_generales.country || ''
                        }`.trim()
                      : data.info_generales.address,
                  icon: <PositionIcon />,
                },
                {
                  name: 'additional_address',
                  title: t('partners.additional_address'),
                  value: data.info_generales.additional_address,
                },
              ],
              [
                {
                  name: 'phone_number',
                  title: t('forms.phone.placeholder'),
                  value: data.info_generales.phone_number,
                  icon: <ContactPageIcon />,
                },
                {
                  name: 'contact_email',
                  title: t('partners.email_address'),
                  value: data.info_generales.contact_email,
                },
              ],
            ],
          }}
          loading={loading}
          dataTestId="informations_card"
        />
      )}

      {/* CONTACT */}
      <CardContacts
        title={t('partners.contact')}
        contactList={contactList}
        loading={loading}
        referent={referent}
        setReferent={setReferent}
        canEdit={canEdit}
      />

      {/* SIRETS */}
      {!loading ? (
        data.siret &&
        showSiretCard && <CardSirets data={data.siret} loading={loading} />
      ) : (
        <LoaderSkeleton height="21.25rem" />
      )}
    </div>
  );
}

export { TabInformations };
