import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { UploadXLSX } from '@components/atomic/UploadXLSX';
import { urlDownloadModelInvitationInstallerTemplate } from '@api/apiUrls';
import { setValidatedFile } from '@models/partners/apiRequests/partnersRequest';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { processInvitationFromModel } from '@models/conventions/apiRequests/conventionRequests';
import { GlobalContext } from '@context/globalContext';

function StepInvitation() {
  const { t } = useTranslation();
  const { relatedContract, invitationProcess } = useContext(
    ContractCreationContext
  );
  const { userView } = useContext(GlobalContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<File>();
  const [fileValid, setFileValid] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [countInvited, setCountInvited] = useState<number>(0);
  const [countRejected, setCountRejected] = useState<number>(0);
  const [countProcessedLines, setCountProcessedLines] = useState<number>(0);
  const [rejectedEmailDescription, setRejectedEmailDescription] = useState<
    string[]
  >([]);
  const [rejectedSiretDescription, setRejectedSiretDescription] = useState<
    string[]
  >([]);
  const [disbarredSiretDescription, setDisbarredSiretDescription] = useState<
    string[]
  >([]);

  const onUpload = (file: File) => {
    setFileValid(false);
    setUploadFile(file);
  };

  const loadValidButton = () => {
    setFileValid(false);
    setIsProcess(false);
  };

  const processInvitation = async () => {
    if (userView) {
      if (uploadFile) {
        setIsProcess(false);
        setLoading(true);
        const resValidatedFile = await setValidatedFile(
          Number(relatedContract?.id),
          uploadFile
        );

        if (resValidatedFile && resValidatedFile.proceed) {
          const modelId = Number(relatedContract?.id);
          const res = await processInvitationFromModel(
            modelId,
            resValidatedFile.valid_row_datas || []
          );

          if (res) {
            setCountInvited(Number(resValidatedFile.count_invited));
            setCountRejected(Number(resValidatedFile.count_rejected));
            setRejectedEmailDescription(
              resValidatedFile.invalid_email_descr || []
            );
            setRejectedSiretDescription(
              resValidatedFile.invalid_siret_descr || []
            );
            setDisbarredSiretDescription(
              resValidatedFile.disbarred_siret_descr || []
            );
            setCountProcessedLines(
              Number(resValidatedFile.total_lines_process)
            );
          }
          setIsProcess(true);
          invitationProcess(true); // used to change text of button from Enregistrer sans inviter into Enregistrer et quitter
        }
        setLoading(false);
      }
    }

    setLoading(false);
  };

  return (
    <Card
      title={t('partners.installer_invitation.modal.header_title_upload')}
      actionButtons={
        <div>
          <ButtonOpx
            label={`${t('buttons.send')}`}
            type="primary"
            onClick={processInvitation}
            addClass="!px-[3.5rem]"
            isLoading={loading}
            disabled={!uploadFile || fileValid || isProcess}
          />
        </div>
      }
    >
      <div>
        <div className="mt-2 flex flex-col gap-3">
          <p className="mt-0 mb-3">
            {t('partners.installer_invitation.modal.header_text_upload')}
          </p>
          <a
            href={`${process.env.PUBLIC_URL}${urlDownloadModelInvitationInstallerTemplate}`}
            target="_blank"
            rel="noreferrer"
            download
            className="mb-3 font-[600] cursor-pointer"
            style={{ color: 'blue' }}
          >
            {t('partners.installer_invitation.modal.link_download_template')}
          </a>
          <span className="font-300">
            {t('partners.installer_invitation.modal.input_file_title')}
          </span>

          {/* Input d'upload du document */}
          <UploadXLSX
            onUpload={onUpload}
            isUploadFile={!uploadFile}
            fileName={
              uploadFile
                ? uploadFile.name
                : t(
                    'partners.installer_invitation.modal.input_file_placeholder'
                  )
            }
            loadValidButton={loadValidButton}
          />

          {isProcess && (
            <>
              {countProcessedLines === 0 && (
                <div className="mt-2 text-[1rem] p-4 border border-borderGrey rounded-default flex text-red items-center">
                  {t('convention.step_invitation.info.none')}
                </div>
              )}
              {countInvited > 0 && (
                <div className="mt-2 text-[1rem] p-4 border border-borderGrey rounded-default flex text-blueOpx items-center">
                  {countInvited} {t('global.out_of')} {countProcessedLines}{' '}
                  {t('convention.step_invitation.info.invited')}
                </div>
              )}
              {countRejected > 0 && (
                <div className="mt-1 text-[1rem] grid grid-cols-1 gap-y-6 p-4 border border-borderGrey rounded-default items-center">
                  <div className="flex w-full text-red">
                    {countRejected} {t('global.out_of')} {countProcessedLines}{' '}
                    {t('convention.step_invitation.info.rejected')}
                  </div>

                  {rejectedSiretDescription.length > 0 && (
                    <div className="w-full flex-wrap grid grid-cols-1 px-[.5rem] md:grid-cols-[14%_2%_84%]">
                      <div className="text-red w-[8rem]">
                        {t(
                          'convention.model.installer_invitation.incorrect_siret'
                        )}
                      </div>
                      <div>{' : '}</div>
                      <div className="float-left">
                        {rejectedSiretDescription.map((info) => (
                          <p className="text-black" key={info}>
                            {info}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                  {disbarredSiretDescription.length > 0 && (
                    <div className="w-full flex-wrap grid grid-cols-1 px-[.5rem] md:grid-cols-[14%_2%_84%]">
                      <div className="text-red">
                        {t(
                          'convention.model.installer_invitation.disbarred_siret'
                        )}
                      </div>
                      <div>{' : '}</div>
                      <div className="float-left">
                        {disbarredSiretDescription.map((info) => (
                          <p className="text-black" key={info}>
                            {info}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                  {rejectedEmailDescription.length > 0 && (
                    <div className="w-full flex-wrap grid grid-cols-1 px-[.5rem] md:grid-cols-[14%_2%_84%]">
                      <div className="text-red">
                        {t(
                          'convention.model.installer_invitation.incorrect_email'
                        )}
                      </div>
                      <div>{' : '}</div>
                      <div className="float-left">
                        {rejectedEmailDescription.map((info) => (
                          <p className="text-black" key={info}>
                            {info}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
}

export { StepInvitation };
