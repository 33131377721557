import { IOneRequestInList } from '@models/requests/types/requestTypes';
import { OneRequest } from '../OneRequest';

interface IListRequestsKanbanProps {
  requests: IOneRequestInList[] | never[];
}

function ListRequestsKanban({ requests }: IListRequestsKanbanProps) {
  return (
    <div className="mt-[1.5rem] flex flex-col space-y-[.5rem] max-h-[80vh] overflow-y-scroll">
      {requests.map((request) => {
        return <OneRequest request={request} isKanban borderBottom />;
      })}
    </div>
  );
}

export { ListRequestsKanban };
