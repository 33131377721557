import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { InfosWithImage } from '@components/atomic/InfosWithImage';
import { SearchBar } from '@components/SearchBar';
import { ListPagination } from '@components/atomic/pagination/ListPagination';

import { IAccountInfos, IAccountUser } from '@models/users/utils/userTypes';
import { getLinkedUsersList } from '@models/users/apiRequests/userRequests';

import { green, red, textGrey } from '@assets/color';
import { LoaderUsersInList } from '@models/users/components/LoaderUsersInList';
import { AuthContext } from '@context/authContext';
import { Tag } from '@components/atomic/Tag';
import { CardUserDetails } from './CardUserDetails';

interface ITabAccountUsersProps {
  infos: IAccountInfos;
  loadingInfos: boolean;
}

function TabAccountUsers({ loadingInfos, infos }: ITabAccountUsersProps) {
  const { t } = useTranslation();
  const { globalEnum, updateDisplayModalUpgradeSubscription } =
    useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAccountUser[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [paginationData, setPaginationData] = useState<
    Record<string, any> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const getData = async (pageParam: number, index?: number) => {
    setLoading(true);
    setSelectedIndex(undefined);

    const resList = await getLinkedUsersList(pageParam, 10);

    if (resList) {
      const sortedData = resList.data.sort((a: IAccountUser, b: IAccountUser) =>
        a.id < b.id ? -1 : 1
      );
      setData(sortedData);
      setFilteredData(sortedData);
      setPaginationData(resList);
      setSelectedIndex(index);
      if (resList?.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          { data: sortedData, meta: resList.meta },
        ]);
      }
    }
    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const searchUser = (searchValue: string) => {
    const resSearch = data
      .map((userItem: IAccountUser) => {
        const indexSearchItem = [
          userItem.firstname,
          userItem.lastname,
          userItem.email,
          userItem.role_name?.includes('admin')
            ? t('my_account.admin') || ''
            : t('my_account.user') || '',
          userItem.function,
        ].findIndex((userInfo) =>
          String(userInfo || '')
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );

        if (indexSearchItem !== -1) return userItem;
        return null;
      })
      .filter((resUser) => resUser);

    setFilteredData(resSearch || []);
  };

  const user_profile_type = (userItem: IAccountUser) => {
    const resProfile =
      globalEnum && globalEnum.profile_type
        ? globalEnum.profile_type[
            Number(
              Object.keys(globalEnum.profile_type).find(
                (key: string) => key === String(userItem.profile_type)
              ) || 1
            )
          ]
        : '';
    return `${resProfile.charAt(0).toUpperCase()}${resProfile
      .slice(1)
      .toLowerCase()}`;
  };

  useEffect(() => {
    if (!loadingInfos) getData(1);
  }, [loadingInfos]);

  useEffect(() => {
    if (user?.is_freemium) {
      updateDisplayModalUpgradeSubscription(true);
    }
  }, []);

  return (
    <div className="grid gap-x-0 md:gap-x-[1.5%] gap-y-4 grid-cols-1 md:grid-cols-[40%_58.5%] mt-6">
      <div className="border border-borderGrey rounded-default bg-white p-6">
        <p className="font-medium text-[1.25rem] leading-[2.25rem] mb-4">
          {t('my_account.user_management')}
        </p>

        <SearchBar
          placeholder={t('my_account.search_user')}
          onSearch={(value: string) => searchUser(value)}
          searchOnEveryChange
          width="100%"
        />

        <div className="mt-6 -mx-6">
          {loading ? (
            <LoaderUsersInList />
          ) : (
            filteredData.map((userItem: IAccountUser, iUser: number) => (
              <button
                key={v4()}
                type="button"
                className={`hover:cursor-pointer hover:bg-backgroundBody px-6 py-4 w-full ${
                  iUser === selectedIndex ? 'bg-backgroundBody' : ''
                }`}
                onClick={() => setSelectedIndex(userItem.id)}
              >
                <InfosWithImage
                  loading={loading}
                  infos={[
                    {
                      title:
                        userItem.firstname && userItem.firstname
                          ? `${userItem.firstname} ${userItem.lastname}`
                          : `${userItem.email}`,
                      subtitle: userItem.function || '-',
                      showImage: true,
                    },
                  ]}
                  image={userItem.photo_url || ''}
                  subtitleColor={textGrey}
                  rightInfos={
                    <div className="flex flex-col items-end">
                      {user_profile_type(userItem)}
                      <Tag
                        color={userItem.active ? green : red}
                        label={
                          userItem.active
                            ? t('settings.active')
                            : t('settings.inactive')
                        }
                      />
                    </div>
                  }
                />
              </button>
            ))
          )}
        </div>

        {paginationData && Number(paginationData.last_page) > 1 && (
          <ListPagination
            getData={(page) => {
              if (page > pagesDatas.length) {
                getData(page);
              } else {
                pageAlreadyLoad(page);
              }
            }}
            paginationData={paginationData}
          />
        )}
      </div>
      <CardUserDetails
        data={
          selectedIndex !== undefined
            ? data.find((item) => item.id === selectedIndex)
            : undefined
        }
        refetch={getData}
        loading={loading}
        selectedIndex={selectedIndex}
        currentPage={paginationData?.meta?.current_page || 1}
        infosConnectedUser={infos}
      />
    </div>
  );
}

export { TabAccountUsers };
