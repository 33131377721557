import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { SETTINGS_TABS } from '@models/settings/utils/utils';

interface ISettingsTabsNav {
  tabActive: string;
  setTabActive: Dispatch<SetStateAction<string>>;
}
function SettingsTabsNav({ tabActive, setTabActive }: ISettingsTabsNav) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col min-w-[24.125rem] space-y-2">
      <button
        type="button"
        className={`text-left p-4 border border-borderGrey rounded-[.5rem] text-[1rem] leading-5 ${
          tabActive === SETTINGS_TABS.CUSTOM_FIELDS
            ? 'bg-blueOpxOpacity10 text-blueOpx'
            : ''
        }`}
        onClick={() => setTabActive(SETTINGS_TABS.CUSTOM_FIELDS)}
      >
        {t('settings.custom_fields')}
      </button>
      <button
        type="button"
        className={`text-left p-4 border border-borderGrey rounded-[.5rem] text-[1rem] leading-5 ${
          tabActive === SETTINGS_TABS.BRANDS
            ? 'bg-blueOpxOpacity10 text-blueOpx'
            : ''
        }`}
        onClick={() => setTabActive(SETTINGS_TABS.BRANDS)}
      >
        {t('settings.brands')}
      </button>
      <button
        type="button"
        className={`text-left p-4 border border-borderGrey rounded-[.5rem] text-[1rem] leading-5 ${
          tabActive === SETTINGS_TABS.SUBSCRIPTION_AND_PAYMENT
            ? 'bg-blueOpxOpacity10 text-blueOpx'
            : ''
        }`}
        onClick={() => setTabActive(SETTINGS_TABS.SUBSCRIPTION_AND_PAYMENT)}
      >
        {t('settings.subscriptions_and_payments')}
      </button>
    </div>
  );
}

export default SettingsTabsNav;
