import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { GlobalContext } from '@context/globalContext';
import { BeneficiaryTypes } from '@models/contractCreation/utils/enums';
import { OneBeneficiary } from '@models/contractCreation/components/steps/beneficiary/OneBeneficiary';
import { useTranslation } from 'react-i18next';
import { emailValidationRegex, phoneNumberRegex } from '@utils/regex';

function StepBeneficiaryPerson() {
  const { t } = useTranslation();
  const { updateBeneficiary, beneficiary, changeStep, beneficiaryType } =
    useContext(ContractCreationContext);
  const { globalEnum } = useContext(GlobalContext);

  const [isSameContact, setIsSameContact] = useState<boolean>(true);

  const isNotEmpty = (val: string | undefined | null) => val && val !== '';

  const methods = useForm();

  const onClickNext = useCallback(async () => {
    const { contact, ...beneficiaryContact } = beneficiary;

    if (isSameContact) {
      updateBeneficiary((prevState) => ({
        ...prevState,
        contact: beneficiaryContact,
      }));
    } else {
      updateBeneficiary((prevState) => ({
        ...prevState,
        contact: beneficiary.contact,
      }));
    }

    changeStep('next');
  }, [beneficiary, isSameContact]);

  const isValid = useMemo(() => {
    const beneficiaryOk =
      beneficiary.civility &&
      beneficiary.firstname &&
      beneficiary.lastname &&
      beneficiary.phone_number &&
      !!phoneNumberRegex.test(beneficiary?.phone_number || '0102030405') &&
      beneficiary.email &&
      !!emailValidationRegex.test(beneficiary?.email || 'email@drapo.com') &&
      beneficiary.address.address !== '' &&
      beneficiary.address.postal_code !== '' &&
      beneficiary.address.city !== '';

    if (isSameContact) return beneficiaryOk;

    return (
      beneficiaryOk &&
      beneficiary.contact?.civility &&
      beneficiary.contact?.firstname &&
      beneficiary.contact?.lastname &&
      beneficiary.contact?.phone_number &&
      !!phoneNumberRegex.test(
        beneficiary?.contact?.phone_number || '0102030405'
      ) &&
      beneficiary.contact?.email &&
      !!emailValidationRegex.test(
        beneficiary.contact?.email || 'email@drapo.com'
      ) &&
      beneficiary.contact?.function
    );
  }, [beneficiary]);

  const onClickBack = () => {
    changeStep('back');
  };

  useEffect(() => {
    const { contact } = beneficiary;
    if (!contact) {
      setIsSameContact(true);
    }
    if (
      isNotEmpty(contact?.firstname) ||
      isNotEmpty(contact?.lastname) ||
      isNotEmpty(contact?.phone_number) ||
      isNotEmpty(contact?.email)
    ) {
      setIsSameContact(false);
    }
  }, [beneficiary]);

  return (
    <Card
      title={t('contract.partner_infos')}
      subtitle={
        beneficiaryType === BeneficiaryTypes.MIXED
          ? globalEnum.beneficiaries_type[2]
          : undefined
      }
      actionButtons={
        <div className="flex gap-3">
          <ButtonOpx
            type="secondary"
            label={`${t('global.back')}`}
            onClick={onClickBack}
          />
          <ButtonOpx
            type="primary"
            label={`${t('buttons.next')}`}
            onClick={onClickNext}
            disabled={Boolean(!isValid)}
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <form className="flex flex-col gap-[1.5rem]">
          <OneBeneficiary
            contact={beneficiary}
            isBeneficiary
            setIsSameContact={setIsSameContact}
            isSameContact={isSameContact}
          />
          <OneBeneficiary
            contact={beneficiary.contact}
            isBeneficiary={false}
            setIsSameContact={setIsSameContact}
            isSameContact={isSameContact}
          />
        </form>
      </FormProvider>
    </Card>
  );
}

export { StepBeneficiaryPerson };
