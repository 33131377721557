/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { blueOpx, green } from '@assets/color';
import { CheckIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';

interface OneStepProps {
  nameStep: string;
  numberStep: number;
  stepIsActive?: boolean;
  stepIsDone?: boolean;
  onClick?: () => void;
  lastStep?: boolean;
}

function OneStep({
  nameStep,
  numberStep,
  stepIsActive,
  stepIsDone,
  onClick,
  lastStep,
}: OneStepProps) {
  let isDone = stepIsDone;
  if (lastStep) isDone = true;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      style={{
        backgroundColor: stepIsActive ? convertHexToRGBA(blueOpx, 0.1) : '',
      }}
      className={`flex space-x-[1rem] items-center w-full p-[.5rem] rounded-default ${
        !onClick ? 'cursor-default' : ''
      }`}
    >
      <ColorCube
        numberOrIcon={!isDone ? numberStep : <CheckIcon />}
        size="1.5rem"
        color={!isDone || stepIsActive ? blueOpx : green}
        opacity={!stepIsActive}
      />
      <p
        className={
          stepIsActive
            ? 'text-blueOpx'
            : stepIsDone
            ? 'text-black'
            : 'text-textGrey'
        }
      >
        {nameStep}
      </p>
    </div>
  );
}

export { OneStep };

OneStep.defaultProps = {
  stepIsActive: false,
  stepIsDone: false,
  onClick: undefined,
  lastStep: false,
};
