import { createContext, Dispatch, SetStateAction } from 'react';

interface IRequests {
  elementActiveAllRequestWorksiteOrPartner: string;
  updateElementActiveAllRequestWorksiteOrPartner: Dispatch<
    SetStateAction<string>
  >;
  isLoadingList: boolean;
  updateIsLoadingList: Dispatch<SetStateAction<boolean>>;
}

export const RequestsContext = createContext({} as IRequests);
