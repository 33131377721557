import { green, iconBlue, red, textGrey } from '@assets/color';

export const getRelatedModelBadge = (
  firstNumber: number,
  secondNumber: number,
  relatedModel: string
) => {
  let text = '';
  let color = iconBlue;
  const value = firstNumber > 0 ? String(firstNumber) : String(secondNumber);

  if (firstNumber === 1) {
    text = `one_active_${relatedModel}`;
  } else if (firstNumber > 1) {
    text = `multiple_active_${relatedModel}s`;
  } else if (secondNumber === 1) {
    text = `one_inactive_${relatedModel}`;
    color = relatedModel !== 'worksites' ? textGrey : green;
  } else {
    text =
      relatedModel !== 'worksites'
        ? `multiple_inactive_${relatedModel}s`
        : 'multiple_done_worksites';
    color = relatedModel !== 'worksites' ? textGrey : green;
  }

  text = `partners.${text}`;

  if (firstNumber < 1 && secondNumber < 1 && relatedModel === 'worksite') {
    return { text: 'partners.no_worksite', value: '', color: red };
  }

  return { text, value, color };
};
