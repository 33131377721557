import { grey } from '@assets/color';

function LoaderKpiTab() {
  return (
    <div
      className="bg-white w-full h-auto rounded-[.25rem] px-[2rem] py-[2.125rem] flex-grow"
      style={{ borderColor: grey, borderWidth: '1px' }}
    >
      <div className="flex items-start space-x-[1rem]">
        <div className="skeleton-loader-anim-darker h-[2rem] w-[2rem]" />
        <div className="flex flex-col space-y-[.25rem]">
          <div className="skeleton-loader-anim-darker h-[.75rem] w-[10rem]" />
          <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
        </div>
      </div>
    </div>
  );
}
export { LoaderKpiTab };
