import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { OperationTypeEnum } from '@utils/enums';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface IUndividedHousingProps {
  undividedHousing: boolean;
  setUndividedHousing: Dispatch<SetStateAction<boolean>>;
  methods: UseFormReturn;
  worksiteOperationType?: number;
}

function UndividedHousing({
  undividedHousing,
  setUndividedHousing,
  methods,
  worksiteOperationType,
}: IUndividedHousingProps) {
  const { t } = useTranslation();
  const { readOnly } = useContext(WorksiteCreationContext);

  const yes = t('global.yes');
  const no = t('global.no');
  return (
    <FormProvider {...methods}>
      <div className="pt-[1.5rem] w-full">
        <p
          className={` ${
            worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
              ? 'text-[1.25rem] pt-[1.5rem]'
              : ''
          }  font-medium`}
        >
          {t('worksite_creation.create_worksite.undivided_housing')}
        </p>
        <div className="flex items-center space-x-[.5rem] w-full mt-[1.5rem]">
          <TextWithRadio
            label={yes}
            selectValue={yes}
            value={yes}
            setSelectedValue={(e) =>
              e === yes ? setUndividedHousing(true) : setUndividedHousing(false)
            }
            isChecked={undividedHousing}
            addClass="w-full"
            disabled={readOnly}
          />
          <TextWithRadio
            label={no}
            selectValue={no}
            value={no}
            setSelectedValue={(e) =>
              e === no ? setUndividedHousing(false) : setUndividedHousing(true)
            }
            isChecked={!undividedHousing}
            addClass="w-full"
            disabled={readOnly}
          />
        </div>
      </div>
    </FormProvider>
  );
}

export { UndividedHousing };

UndividedHousing.defaultProps = {
  worksiteOperationType: undefined,
};
