import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { login } from '@models/auth/apiRequests/authRequests';
import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ILogin } from '@models/auth/utils/types';
import { toast } from 'react-toastify';
import { AUTH_ROUTES } from '@utils/routesUrls';

interface IAuthInfoProps {
  username: string;
  loginInfo: ILogin | undefined;
}

function AccountCompleted({ username, loginInfo }: IAuthInfoProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { updateToken } = useContext(AuthContext);
  const { updateLayoutIsLoading } = useContext(GlobalContext);

  const authenticate = async () => {
    setLoading(true);

    if (loginInfo) {
      const isLogged = await login(
        {
          email: loginInfo.email,
          password: loginInfo.password,
        },
        updateToken
      );
      setLoading(false);

      if (isLogged) {
        updateLayoutIsLoading(true);
        navigate('/', { replace: true });
      }
    } else {
      toast.info(() => (
        <div>
          {t('auth.registration.account_completed.welcome')} {username} !
          <br />
          {t('auth.registration.account_completed.lost_session')}
        </div>
      ));
      navigate(AUTH_ROUTES.LOGIN);
    }
  };

  return (
    <div className="lg:w-[35rem]">
      <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
        {t('auth.registration.account_completed.welcome')} !
        <br />
        {t('auth.registration.account_completed.message')}{' '}
      </p>
      <ButtonOpx
        addClass="w-full my-6"
        label={t('auth.registration.account_completed.button')}
        type="primary"
        onClick={() => authenticate()}
        isLoading={loading}
        disabled={loading}
      />
    </div>
  );
}

export { AccountCompleted };
AccountCompleted.defaultProps = {
  // loginInfo: undefined,
};
