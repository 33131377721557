import { useTranslation } from 'react-i18next';

import { Tag } from '@components/atomic/Tag';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';

import { IPartnerDeposit } from '@models/partners/utils/types/partnersType';
import { dateToDDMMYYY } from '@utils/format';

import { iconBlue, lightBlue, textGrey } from '@assets/color';

interface ICardDepositsProps {
  data: IPartnerDeposit;
}

function CardDeposits({ data }: ICardDepositsProps) {
  const { t } = useTranslation();

  return (
    <article className="border border-borderGrey rounded-default2 bg-white mb-6">
      <div className="grid grid-cols-1 md:grid-cols-[40%_60%]">
        <div className="p-6 border border-solid border-transparent border-r-borderGrey">
          <p className="flex">
            <span className="font-medium text-[1.25rem] mr-4">
              {t('partners.deposits.internal_reference')}
            </span>
            <Tag label={data.name || '-'} color={textGrey} />
          </p>
          <p className="block xm:flex justify-between mt-5">
            <span className="text-[.625rem] text-textGrey">
              {`${t('partners.deposits.dd_classic')} : ${
                data.num_dd_classique || '-'
              }`}
            </span>
            <span className="text-[.625rem] text-textGrey">
              {`${t('partners.deposits.dd_precarity')} : ${
                data.num_dd_precaire || '-'
              }`}
            </span>
          </p>
        </div>
        <div className="p-6">
          <p className="text-right mb-4">
            {`RAI : ${data.rai} `}
            <span className="font-medium ml-8">{`${data.nb_operations || '-'} ${
              Number(data.nb_operations) > 1
                ? t('partners.deposits.operations')
                : t('partners.deposits.operation')
            }`}</span>
          </p>

          <MultipleProgressBar
            showLegend
            data={[
              {
                ratio: Number(data.ratio_precaire),
                color: '#916BE2',
                label: `${t('partners.deposits.volume_precarity')} : ${
                  data.volume_precaire || '-'
                } GWhc`,
              },
              {
                ratio: Number(data.ratio_classique),
                color: lightBlue,
                label: `${t('partners.deposits.volume_classic')} : ${
                  data.volume_classique || '-'
                } GWhc`,
              },
            ]}
          />
        </div>
      </div>
      <div className="p-6 border border-solid border-transparent border-t-borderGrey block md:flex justify-between">
        <div className="block xm:flex items-center">
          <Tag
            label={String(data.status || '-')}
            color={!data.status ? textGrey : iconBlue}
          />
          <p className="m-0 ml-0 xm:ml-4">
            {`${t('partners.deposits.last_update')} `}
            <span className="font-medium">
              {data.updated_at ? dateToDDMMYYY(data.updated_at) : '-'}
            </span>
          </p>
          <p className="m-0 ml-0 xm:ml-4">
            {`${t('partners.deposits.creation_date')} `}
            <span className="font-medium">
              {data.depot_date ? dateToDDMMYYY(data.depot_date) : '-'}
            </span>
          </p>
        </div>
        <div className="block xm:flex items-center mt-4 md:mt-0">
          <p className="flex m-0">
            <span>{t('partners.deposits.total_prime')}</span>
            <span className="font-medium ml-2 mr-8">{`${
              data.prime_total || '-'
            } €`}</span>
          </p>

          <p className="flex m-0">
            <span>{t('partners.deposits.total_volume')}</span>
            <span className="font-medium ml-2">{data.volume_total || '-'}</span>
          </p>
        </div>
      </div>
    </article>
  );
}

export { CardDeposits };
