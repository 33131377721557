import { IQuotaCeeListDatas } from '@models/dashboard/utils/dashboardTypes';
import OneLineQuotaList from './OneLineQuotaList';

const fakeDatas: IQuotaCeeListDatas[] = [
  {
    label: 'Convention_A',
    numberWorksite: 114,
    expirationDate: '25/12/24',
    values: {
      classic: {
        value: 5.4,
        max: 250,
      },
      precarious: { value: 247.6, max: 250 },
    },
  },
  {
    label: 'Convention_B',
    numberWorksite: 56,
    expirationDate: '15/08/25',
    values: {
      classic: {
        value: 120.87,
        max: 300,
      },
      precarious: { value: 180, max: 300 },
    },
  },
  {
    label: 'Convention_C',
    numberWorksite: 32,
    expirationDate: '11/11/24',
    values: {
      classic: {
        value: 50,
        max: 200,
      },
      precarious: { value: 150, max: 200 },
    },
  },
  {
    label: 'Convention_D',
    numberWorksite: 78,
    expirationDate: '30/01/26',
    values: {
      classic: {
        value: 180,
        max: 400,
      },
      precarious: { value: 220, max: 400 },
    },
  },
  {
    label: 'Convention_E',
    numberWorksite: 100,
    expirationDate: '05/06/25',
    values: {
      classic: {
        value: 75,
        max: 150,
      },
      precarious: { value: 75, max: 150 },
    },
  },
];

function QuotaCeeList() {
  return (
    <div className="flex flex-col bg-white max-h-[50rem] overflow-y-auto scroll-smooth">
      {fakeDatas.map((data, index) => (
        <OneLineQuotaList
          key={`One-Line-Quota-List-${data.label}`}
          label={data.label}
          numberWorksite={data.numberWorksite}
          expirationDate={data.expirationDate}
          values={data.values}
          animationDelay={index * 400}
          isFirst={index === 0}
        />
      ))}
    </div>
  );
}

export default QuotaCeeList;
