import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { ITaxHouselod } from '@models/worksiteCreation/utils/types/worksitesType';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, FormProvider } from 'react-hook-form';
import { OperationTypeEnum } from '@utils/enums';
import { OneTaxHousehold } from './OneTaxHousehold';
import { WorksiteCreationContext } from '../../../../utils/worksiteCreationContext';

interface ITaxHouseholdProps {
  taxHouseholdDatas: ITaxHouselod[];
  setTaxHouseholdDatas: Dispatch<SetStateAction<ITaxHouselod[]>>;
  methods: UseFormReturn;
}

function TaxHousehold({
  taxHouseholdDatas,
  setTaxHouseholdDatas,
  methods,
}: ITaxHouseholdProps) {
  const { t } = useTranslation();

  const { worksiteOperationType, readOnly } = useContext(
    WorksiteCreationContext
  );

  const onSelectNumber = (value: number) => {
    if (value < taxHouseholdDatas.length) {
      setTaxHouseholdDatas((prevState) => {
        return prevState.filter((_, i) => i < value);
      });
    } else if (value > taxHouseholdDatas.length) {
      const diff = value - taxHouseholdDatas.length;
      const emptyData = {
        firstname: '',
        lastname: '',
        fiscal_number: '',
        fiscal_reference: '',
      };

      const emptyTaxHouseholds: ITaxHouselod[] = [];

      for (let i = 0; i < diff; i += 1) {
        emptyTaxHouseholds.push(emptyData);
      }

      setTaxHouseholdDatas((prevState) => [
        ...prevState,
        ...emptyTaxHouseholds,
      ]);
    }
  };

  return (
    <FormProvider {...methods}>
      <div
        className={
          worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
            ? 'w-full pt-[1.5rem]'
            : ''
        }
      >
        <p
          className={
            worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
              ? 'font-medium text-[1.25rem] leading-[2.25rem]'
              : 'font-medium'
          }
        >
          {t('worksite_creation.create_worksite.tax_household.title')}
        </p>
        <InputSelect
          placeholder={t(
            'worksite_creation.create_worksite.tax_household.tax_household_number'
          )}
          dataArrayString={['1', '2', '3', '4', '5']}
          addClass="mt-[1.5rem]"
          valueInput={taxHouseholdDatas.length.toString()}
          onSelect={(value) => onSelectNumber(Number(value))}
          disabled={readOnly}
        />
        {taxHouseholdDatas.map((household, index) => (
          <OneTaxHousehold
            key={`tax-${index + 1}`}
            household={household}
            index={index}
            setTaxHouseholdDatas={setTaxHouseholdDatas}
            taxHouseholdDatas={taxHouseholdDatas}
          />
        ))}
      </div>
    </FormProvider>
  );
}

export { TaxHousehold };
