import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { numberWithSeparator } from '@utils/format';
import { updateWorksitePrimes } from '@models/worksites/apiRequests/worksitesRequests';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { getAhGeneralData } from '@models/worksites/utils/utils';

interface IUpdateWorksitePrimesModalProps {
  onClickCancel: () => void;
  onError: () => void;
  setNewPayload: Dispatch<
    SetStateAction<{ [x: string]: string | number } | undefined>
  >;
  newPayload: { [x: string]: string | number };
  beneficiaryData?: { [x: string]: string | number } | null;
  onClickConfirm?: () => void;
}
function UpdateWorksitePrimesModal({
  onClickCancel,
  setNewPayload,
  newPayload,
  onError,
  beneficiaryData,
  onClickConfirm,
}: IUpdateWorksitePrimesModalProps) {
  const { t } = useTranslation();
  const { updateWorksiteDetails, updateAhGeneralData, worksiteDetails } =
    useContext(WorksitesContext);

  const newAmount = newPayload.totalPrimesEuros;
  const amountWithSeparator = numberWithSeparator(String(newAmount));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConfirmClick = async () => {
    setIsLoading(true);

    if (!onClickConfirm) {
      const response = await updateWorksitePrimes(
        worksiteDetails.id.toString(),
        newPayload,
        beneficiaryData
      );

      if (response.data) {
        updateWorksiteDetails(response.data);
        setNewPayload(undefined);
      } else {
        onError();
      }
    } else {
      onClickConfirm();
    }

    await getAhGeneralData(worksiteDetails.id, updateAhGeneralData);

    setIsLoading(false);
  };

  return (
    <Modal
      title={t('worksites.update_amounts.modal_title')}
      backgroundTransparent
      sidebarVisible
      btnCancel
      onClickCancel={onClickCancel}
      textBtnConfirm={newAmount > 0 ? `${t('buttons.confirm')}` : undefined}
      onConfirmClick={onConfirmClick}
      width="50rem"
      isLoading={isLoading}
    >
      {newAmount <= 0 ? (
        <div className="mt-3">{t('worksite_creation.simulation.no_prime')}</div>
      ) : (
        <div className="mt-3">
          {t('worksites.update_amounts.new_amount_message', {
            newAmount: amountWithSeparator,
          })}{' '}
          <br /> {t('global.continue_question')}
        </div>
      )}
    </Modal>
  );
}
export { UpdateWorksitePrimesModal };

UpdateWorksitePrimesModal.defaultProps = {
  beneficiaryData: undefined,
  onClickConfirm: undefined,
};
