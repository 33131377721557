import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HeaderContext } from '@context/headerContext';
import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';

import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { ButtonOpx } from '@components/atomic/ButtonOpx';

import { ROLES } from '@utils/roles';

import { getDepotsList } from '@models/deposits/apiRequests/depositRequests';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { v4 } from 'uuid';
import { DepositCard } from '@models/deposits/components/DepositCard';
import { DepositCardSkeleton } from '@models/deposits/components/DepositCardSkeleton';
import { ListDeliveryOps } from '@models/deposits/components/ListDeliveryOps';
import { ModalNewDeposit } from '@models/deposits/components/ModalNewDeposit';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  resetPagination,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { DEPOSITS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { blueOpx } from '@assets/color';

function Deposits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { roleUser, globalEnum } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);

  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();

  const [deposits, setDeposits] = useState([]);

  const [modalNew, setModalNew] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getDepots = async (page: number) => {
    setIsLoading(true);

    const chosenTabDetails = globalEnum.deposit_status
      ? Object.entries(globalEnum.deposit_status).filter(
          (item) => item[1] === selectedMenu
        )
      : [];

    const status =
      chosenTabDetails.length > 0 ? Number(chosenTabDetails[0][0]) : null;

    try {
      const resDeposits = await getDepotsList(
        page,
        status,
        sortAndFilterData,
        resultsPerPage ?? 5
      );

      if (resDeposits && resDeposits.data) {
        setDeposits(resDeposits.data);
        setPaginationData(resDeposits.meta);

        if (resDeposits.meta?.current_page > pagesDatas.length) {
          setPagesDatas([
            ...pagesDatas,
            { data: resDeposits.data, meta: resDeposits.meta },
          ]);
        }
      } else {
        setDeposits([]);
        setPaginationData(undefined);
      }
    } catch (error) {
      console.error('Failed to fetch deposits', error);
      setDeposits([]);
      setPaginationData(undefined);
    }

    setIsLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setDeposits(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const tabs = useMemo(() => {
    const tabsFromEnum =
      globalEnum && globalEnum.deposit_status
        ? Object.entries(globalEnum.deposit_status).map((item) => item[1])
        : [];

    tabsFromEnum.unshift('Tous');

    if (roleUser === ROLES.GESTION) {
      return [...tabsFromEnum, t('contractMandant.delivery_operations')];
    }

    return tabsFromEnum;
  }, [roleUser, globalEnum]);

  const isTabDeliveryOps = useMemo(() => {
    return selectedMenu === t('contractMandant.delivery_operations');
  }, [selectedMenu]);

  const canViewPage = useMemo(() => {
    // This section is commented for fix in issue opx-959 to allow access to depot, even if role_user = ""
    // const hasRole =
    //   user &&
    //   ['oblige-admin', 'delegataire-admin', 'mandataire-admin'].includes(
    //     user.role_name
    //   );
    return user && user.permissions_names.includes('gerer-depot');
  }, [user]);

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.deposits')}`);
    refreshHeader();
  }, []);

  useEffect(() => {
    if (!isTabDeliveryOps) setCurrentPage(1);
    getDepots(1);
  }, [selectedMenu, globalEnum]);

  useEffect(() => {
    if (sortAndFilterData || resultsPerPage) {
      resetPagination(setPaginationData, setPagesDatas);
      setCurrentPage(1);
      getDepots(1);
    }
  }, [sortAndFilterData, resultsPerPage]);

  if (!canViewPage) return <p className="my-4">{t('global.no_permission')}</p>;

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          <TabsLeftPart
            titlesList={tabs}
            onClick={(title) => {
              setSelectedMenu(title);
              setPagesDatas([]);
              setCurrentPage(1);
              getDepots(1);
            }}
          />
        }
        rightPart={
          <>
            <SortAndFilter
              page="LISTE_DEPOTS"
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterData)
              }
              onFilter={(filters) => filterList(filters, setSortAndFilterData)}
            />
            <ButtonOpx
              onClick={() => setModalNew(true)}
              type="primary"
              label={t('deposits.btn_new')}
            />
          </>
        }
      />
      <div className="flex justify-between">
        <div className="mt-4">
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <ResultsPerPageButton
          resultsPerPage={resultsPerPage || 5}
          onChange={(value) => {
            setResultsPerPage(value);
            setPagesDatas([]);
            setCurrentPage(1);
            getDepots(1);
          }}
          options={[5, 10, 20, 50, 100, 250]}
          colorPagination={blueOpx}
        />
      </div>
      {!isTabDeliveryOps && isLoading && <DepositCardSkeleton />}
      {!isTabDeliveryOps &&
        !isLoading &&
        deposits.length > 0 &&
        deposits.map((item: any) => (
          <div key={v4()}>
            <DepositCard
              data={item}
              clickable
              handleClick={() =>
                navigate(DEPOSITS_ROUTES_WITH_ID(item.id).DEPOSIT_VIEW)
              }
              showDeposant
              showRai={roleUser === ROLES.GESTION}
              showNumDd={roleUser === ROLES.GESTION}
              showEmmyRef={roleUser === ROLES.GESTION}
              showExternal
            />
          </div>
        ))}
      {!isTabDeliveryOps && !isLoading && deposits.length === 0 && (
        <p className="text-textGrey">{t('contract.association.no_items')}</p>
      )}
      {isTabDeliveryOps && <ListDeliveryOps order={sortAndFilterData} />}
      {!isTabDeliveryOps && !isLoading && paginationData && (
        <ListPagination
          getData={(page) => {
            setCurrentPage(page);
            if (page > pagesDatas.length) {
              getDepots(page);
            } else {
              pageAlreadyLoad(page);
            }
          }}
          paginationData={paginationData}
          resultsPerPage={resultsPerPage || 5}
          currentPage={currentPage}
        />
      )}
      {modalNew && (
        <ModalNewDeposit
          callbackClose={() => setModalNew(false)}
          callbackConfirm={(res: any) => {
            setModalNew(false);
            navigate(DEPOSITS_ROUTES_WITH_ID(res.id).DEPOSIT_VIEW);
          }}
        />
      )}
    </div>
  );
}

export { Deposits };
