import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/Modal';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { IMaterial } from '@models/materials/utils/materialTypes';

interface IDeleteModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  materialData: IMaterial | null;
  deleteMaterial: () => void;
  isLoading: boolean;
}
export function DeleteMaterialModal({
  setShowModal,
  materialData,
  deleteMaterial,
  isLoading,
}: IDeleteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('brand.products.delete_modal_title')}
      setIsModal={setShowModal}
      backgroundTransparent
      lightHeader
      isLoading={isLoading}
    >
      <>
        <p>
          {t('brand.products.delete_modal_text', {
            reference: materialData?.name || '',
          })}
        </p>
        <div className="flex items-center justify-end mt-6">
          <ButtonOpx
            onClick={() => setShowModal(false)}
            label={t('buttons.cancel')}
            type="secondary"
            addClass="!border-transparent"
          />
          <ButtonOpx
            onClick={deleteMaterial}
            label={t('buttons.confirm')}
            type="primary"
            addClass="!border-transparent"
            dataTestId="confirm-delete-certification"
          />
        </div>
      </>
    </Modal>
  );
}
