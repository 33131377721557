import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import StatusDatasLine from '@models/dashboard/components/StatusDatasLine';
import WorksiteQuantityLine from '@models/dashboard/components/WorksiteQuantityLine';
import RequestsDashboard from '@models/dashboard/components/RequestsDashboard';
import DonutChart from '@models/dashboard/components/DonutChart';
import DashboardQuotaCeeList from '@models/dashboard/components/DashboardQuotaCeeList';
import LineChart from '@models/dashboard/components/LineChart';

function DashboardDev() {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.dashboard')}`);
    refreshHeader();
  }, []);

  return (
    <div className="py-6 flex flex-col space-y-6">
      <StatusDatasLine realData />
      <div className="h-full">
        <div className="flex h-full space-x-6">
          <div className="w-[75.4375rem] flex flex-col space-y-4">
            <div className="w-[49.75rem] flex flex-col space-y-4">
              <WorksiteQuantityLine />
            </div>
            <DonutChart />
          </div>
          <div className="flex w-[30rem]">
            <RequestsDashboard realData />
          </div>
        </div>
      </div>
      <div className="flex w-1/2">
        <DashboardQuotaCeeList />
      </div>
      <div className="flex w-full">
        <LineChart realData />
      </div>
    </div>
  );
}

export { DashboardDev };
