import { FieldValues } from 'react-hook-form';

import { CardInformations } from '@components/informations/CardInformations';

import { InfoCircleIcon } from '@assets/images/svgComponents';

function CardInfosDesign() {
  const onEdit = (formData: FieldValues) => {
    console.log('submitting Infos values ==> ', formData);
  };
  return (
    <div className="w-1/2 mb-6">
      <div className="my-8">UTILISATION INFOS GENERALES</div>

      <CardInformations
        title="Exemples design"
        data={{
          status: true,
          rows: [
            [
              {
                name: 'fullname',
                title: 'Nom complet',
                value: 'Paul Smith',
                icon: <InfoCircleIcon />,
              },
              {
                name: 'email',
                title: 'Email',
                value: 'email@gmail.ce',
                type: 'email',
                isEditable: true,
              },
            ],
            [
              {
                name: 'address',
                title: 'Adresse',
                value: {
                  address: '13 Premiere rue',
                  postal_code: '7000',
                  city: 'ma ville',
                  country: null,
                },
                isEditable: true,
                type: 'address',
              },
              {
                name: 'address_supp',
                title: 'Adresse supp',
                value: '45 Rue Deuxieme rue',
                isEditable: true,
                type: 'address',
              },
            ],
            [
              {
                name: 'tel',
                title: 'Tel',
                value: '012345789',
                isEditable: true,
                type: 'phone',
              },
              {
                name: 'another',
                title: 'Champ simple avec icone',
                value: 'un texte',
                isEditable: true,
                icon: <InfoCircleIcon />,
              },
            ],
            [
              {
                name: 'contact',
                title: 'Contact',
                value: '',
                list: ['Anne Walker', 'Paul Smith', 'John Doe'],
                isEditable: true,
                type: 'select',
              },
            ],
          ],
        }}
        loading={false}
        onEdit={onEdit}
      />
    </div>
  );
}

export { CardInfosDesign };
