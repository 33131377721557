import { blueOpx, red, white } from '@assets/color';
import { ChangeIcon, NotificationsIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';
import React, { useContext } from 'react';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';

function ButtonsRequestsAndChanges() {
  const {
    updateRequestOrChangeEnumInModal,
    requestOrChangeEnumInModal,
    listRequestsGroupAll,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  const totalRequest =
    (listRequestsGroupAll.todo?.length || 0) +
    (listRequestsGroupAll.waiting?.length || 0);
  return (
    <div className="flex items-center space-x-[1rem]">
      <ColorCube
        numberOrIcon={totalRequest || <NotificationsIcon />}
        color={
          listRequestsGroupAll.todo && listRequestsGroupAll.todo.length > 0
            ? red
            : blueOpx
        }
        opacity={
          requestOrChangeEnumInModal !== RequestOrMessageOrChangeEnum.REQUEST
        }
        size="2.5rem"
        onClick={() =>
          updateRequestOrChangeEnumInModal(
            requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.REQUEST
              ? null
              : RequestOrMessageOrChangeEnum.REQUEST
          )
        }
      />
      <ColorCube
        numberOrIcon={<ChangeIcon />}
        color={
          requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.CHANGE
            ? white
            : blueOpx
        }
        backgroundColor={
          requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.CHANGE
            ? blueOpx
            : convertHexToRGBA(blueOpx, 0.1)
        }
        size="2.5rem"
        onClick={() =>
          updateRequestOrChangeEnumInModal(
            requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.CHANGE
              ? null
              : RequestOrMessageOrChangeEnum.CHANGE
          )
        }
      />
    </div>
  );
}

export { ButtonsRequestsAndChanges };
