import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { sendSubcontractorObjectionNotification } from '@models/worksites/apiRequests/worksitesRequests';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function SubcontractorObjection() {
  const query = new URLSearchParams(useLocation().search);
  const worksiteId = query.get('worksite-id');
  const subcontractorId = query.get('subcontractor-id');
  const { t } = useTranslation();
  const [pageNotFound, setPageNotFound] = useState<boolean>(false);
  const [notificationSent, setNotificationSent] = useState<boolean>(false);
  const [descLoading, setDescLoading] = useState<boolean>(
    !!worksiteId && !!subcontractorId
  );

  const sentObjectionNotification = () => {
    if (!descLoading && notificationSent) {
      return (
        <div>
          <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
            {t('subcontractor_objection.email_sent')}{' '}
          </p>
        </div>
      );
    }

    return (
      <div className="w-[30rem]">
        <LoaderSkeleton height="5rem" addClass="w-full" />
      </div>
    );
  };

  const contactSupportTeam = () => {
    return (
      <div>
        <p className="text-[1.2rem] font-medium leading-[2.5rem]">
          {t('subcontractor_objection.email_not_sent.header')}{' '}
        </p>
        <ul className="flex flex-col space-y-[.5rem] disc pl-[1rem]">
          <li>{t('subcontractor_objection.email_not_sent.reason_1')}</li>
          <li>{t('subcontractor_objection.email_not_sent.reason_2')}</li>
          <li>{t('subcontractor_objection.email_not_sent.reason_3')}</li>
          <li>{t('subcontractor_objection.email_not_sent.reason_4')}</li>
        </ul>
        <br />
        <p className="text-[1.2rem] font-medium leading-[2.5rem]">
          {t('global.contact_support_team')}
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (worksiteId && subcontractorId) {
      const validateNotification = async () => {
        const sent = await sendSubcontractorObjectionNotification(
          Number(worksiteId),
          Number(subcontractorId)
        );

        // send email to support.opx.co if ok
        if (sent.status === 'success') {
          setNotificationSent(true);
        }

        setDescLoading(false);
      };

      validateNotification();
    } else setPageNotFound(true);
  }, [subcontractorId, worksiteId]);

  return (
    <AuthLayout>
      {pageNotFound ? (
        <div className="w-full lg:max-w-[35rem]">
          <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
            {t('global.page_not_found.header')}
            <br />
            {t('global.contact_support_team')}{' '}
          </p>
        </div>
      ) : (
        <div className="w-full lg:max-w-[35rem]">
          {!descLoading && !notificationSent
            ? contactSupportTeam()
            : sentObjectionNotification()}
        </div>
      )}
    </AuthLayout>
  );
}

export { SubcontractorObjection };
