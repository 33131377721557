/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';

import { Card } from '@components/Card';
import { UploadButton } from '@components/documents/UploadButton';
import { DocumentCardHeader } from '@components/documents/DocumentCardHeader';

import { IUserDocsType } from '@models/users/utils/userTypes';
import { ILinkedFile } from 'types/globalTypes';

import { ROLES } from '@utils/roles';

import { LoaderListSimpleDocuments } from '@components/loaders/document/LoaderListSimpleDocuments';
import { sendLinkedFile, deleteLinkedFile } from '@apiRequests/globalRequests';
import { FileTypeEnum } from '@models/worksiteCreation/utils/enums';
import { useLocation } from 'react-router-dom';

interface ICardUserDetailsProps {
  data: IUserDocsType[];
  refetch: CallableFunction;
  isAdmin: boolean;
  loading: boolean;
  uploadDisabled?: boolean;
}

function CardUserDocs({
  data,
  refetch,
  isAdmin,
  loading,
  uploadDisabled,
}: ICardUserDetailsProps) {
  const { t } = useTranslation();
  const location = useLocation();

  const { user } = useContext(AuthContext);

  const { documentActive, updateDocumentActive, roleUser, globalEnum } =
    useContext(GlobalContext);
  const [fileInUpload, setFileInUpload] = useState<ILinkedFile | undefined>(
    undefined
  );

  const updateParentDocuments = async () => {
    refetch();
  };

  const handleUpload = async (file: File) => {
    const currentUrl = location.pathname;
    const fromAdminPage = currentUrl.match(/^\/admin\/users\/(\d+)/);
    let relationId: number;
    if (fromAdminPage) {
      relationId = parseInt(fromAdminPage[1], 10);
    } else {
      relationId = user?.current_entity_type || 0;
    }
    await sendLinkedFile(
      file,
      [relationId],
      'entity_types',
      16,
      2,
      '',
      relationId,
      false,
      undefined,
      updateParentDocuments
    );
  };

  const actionButtons = useMemo(() => {
    if (isAdmin) {
      const disabled = roleUser !== ROLES.ADMIN && uploadDisabled;
      return (
        <UploadButton
          name={t('my_account.documents.add')}
          onUpload={handleUpload}
          fileType={16}
          disabled={disabled}
        />
      );
    }

    return (
      <p>
        {`${data.length} ${
          data.length < 2
            ? t('my_account.documents.doc')
            : t('my_account.documents.docs')
        }`}
      </p>
    );
  }, [isAdmin, data]);

  return (
    <Card
      title={t('my_account.documents.title')}
      actionButtons={actionButtons}
      underlineHeader={false}
      addClass="h-min"
      addClassBody="max-h-[44.875rem] overflow-y-auto"
    >
      <>
        {loading ? (
          <LoaderListSimpleDocuments />
        ) : (
          data.length > 0 &&
          data.map((operation) =>
            operation.documents.map((document: ILinkedFile) => {
              const pos = document.file_url?.lastIndexOf('/') || 0;
              const title =
                document.file_name ||
                document.file_url?.substring(pos + 1) ||
                'Document';

              const isActive =
                documentActive.document.id === document.id &&
                documentActive.list === title;

              const status =
                globalEnum && globalEnum.linked_file_status
                  ? globalEnum.linked_file_status[document.status]
                  : undefined;

              const handleDeleteFile = (doc: ILinkedFile) => {
                setFileInUpload(document);
                deleteLinkedFile(doc.id || 0, updateDocumentActive)
                  .then(() => updateParentDocuments())
                  .finally(() => {
                    setFileInUpload(undefined);
                    refetch();
                  });
              };

              return (
                <div key={v4()} className="border rounded-default p-2 mb-2">
                  <div className="p-2 border border-solid border-transparent border-b-borderGrey">
                    <p className="font-medium text-[1rem]">
                      {document.file_type === FileTypeEnum.CONTRAT
                        ? t('my_account.documents.principal_contract')
                        : globalEnum.linked_file_type[document.file_type]}
                    </p>
                  </div>
                  <DocumentCardHeader
                    title={title}
                    document={document}
                    status={status}
                    isActive={isActive}
                    headerButtonsList={['delete', 'download']}
                    isUploadCard
                    onDelete={
                      roleUser === ROLES.ADMIN ||
                      (document.is_deletable && isAdmin)
                        ? handleDeleteFile
                        : undefined
                    }
                    isLoading={fileInUpload === document}
                    showSubtitle
                  />
                </div>
              );
            })
          )
        )}
      </>
    </Card>
  );
}

export { CardUserDocs };

CardUserDocs.defaultProps = {
  uploadDisabled: false,
};
