import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import {
  sendInfoBeneficiary,
  sendInfoLogement,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TaxHousehold } from '@models/worksiteCreation/components/createWorksite/infoBeneficiary/taxHousehold/TaxHousehold';
import { ITaxHouselod } from '@models/worksiteCreation/utils/types/worksitesType';
import { TextError } from '@components/TextError';
import {
  checkGeneralFields,
  arraysAreSame,
  getFilteredAndSortedGraphs,
  taxHouseHoldToSimulator,
  simulatorToTaxHouseHold,
  fetchIncomes,
} from '@models/worksiteCreation/utils/functions';
import { OperationTypeEnum } from '@utils/enums';
import { IDefaultObject } from '../../../../../types/globalTypes';
import { CardForm } from '../../CardForm';
import { RenderFormGraphGeneral } from '../stepHabitationSimulation/RenderFormGraphGeneral';
import BeneficiarySimulationForm from './BeneficiarySimulationForm';

function StepBeneficiarySimulation() {
  const [isLoadingIncomes, setIsLoadingIncomes] = useState<boolean>(false);
  const methods = useForm();
  const { watch } = methods;
  const {
    graphGeneral,
    incomesOptionsArray,
    updateIncomesOptionsArray,
    simulatorData,
    updateStepActiveWorksiteCreation,
    worksiteOperationType,
    beneficiary,
    worksiteDatas,
    updateWorksiteDatas,
    informationBeneficiaryDataPost,
    informationLogementDataPost,
    updateIsLoading,
    updateInformationBeneficiaryDataPost,
    updateDisabledNextButton,
    updateLastStep,
    lastStep,
    updateSimulatorData,
    updateSimulatorDataOperation,
    simulatorDataOperations,
    worksiteAddress,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const initialTaxData = {
    firstname: beneficiary?.firstname || '',
    lastname: beneficiary?.lastname || '',
    fiscal_number: '',
    fiscal_reference: '',
  };

  const [taxHouseholdDatas, setTaxHouseholdDatas] = useState<ITaxHouselod[]>([
    initialTaxData,
  ]);
  const [errorBeneficiary, setErrorBeneficiary] = useState<string>('');

  const { t } = useTranslation();

  const filteredAndSortedGraphs = useMemo(
    () => getFilteredAndSortedGraphs(graphGeneral),
    [graphGeneral, incomesOptionsArray]
  );

  const noChange = useMemo(() => {
    const { fiscalDeclarations } = informationBeneficiaryDataPost;

    if (fiscalDeclarations.length < 1) return false;

    const initial = worksiteDatas.fiscalDeclarations as IDefaultObject[];
    const current = fiscalDeclarations as IDefaultObject[];

    const valuesToCheck = [
      'firstname',
      'lastname',
      'id',
      'fiscal_number',
      'fiscal_declaration',
    ];

    return arraysAreSame(initial, current, 'fiscal_number', valuesToCheck);
  }, [informationBeneficiaryDataPost.fiscalDeclarations, worksiteDatas]);

  const onSubmit = async () => {
    let goToNextStep = true;
    if (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION) {
      if (!noChange) {
        updateIsLoading(true);
        const resBeneficiary = await sendInfoBeneficiary(
          worksiteOperationType,
          worksiteDatas.id,
          informationBeneficiaryDataPost,
          updateIsLoading,
          updateWorksiteDatas,
          setErrorBeneficiary,
          simulatorData
        );
        const resLogement = await sendInfoLogement(
          worksiteDatas.id,
          informationLogementDataPost,
          updateIsLoading,
          updateWorksiteDatas,
          setErrorBeneficiary
        );

        goToNextStep = resBeneficiary && resLogement;

        if (resBeneficiary && resLogement) {
          updateSimulatorData((prevState) => ({
            ...prevState,
            fiscalData: [],
          }));
          if (lastStep < StepsWorksiteCreationEnum.GLOBAL_PRIMES) {
            updateLastStep(StepsWorksiteCreationEnum.GLOBAL_PRIMES);
          }
        }
      }

      const newData = {
        ...simulatorDataOperations[0],
        'operation.cef_initial': '',
      };

      if (goToNextStep) {
        updateSimulatorDataOperation([...simulatorDataOperations, newData]);
        updateStepActiveWorksiteCreation(
          StepsWorksiteCreationEnum.GLOBAL_PRIMES
        );
      }

      updateIsLoading(false);
    } else {
      updateStepActiveWorksiteCreation(
        StepsWorksiteCreationEnum.SIMULATION_OPERATIONDETAILS
      );
    }
  };

  useEffect(() => {
    simulatorToTaxHouseHold(simulatorData, setTaxHouseholdDatas);
  }, []);

  useEffect(() => {
    const dontFetch = readOnly && incomesOptionsArray !== null;
    if (
      !dontFetch &&
      !isLoadingIncomes &&
      OperationTypeEnum.B2C === worksiteOperationType
    ) {
      fetchIncomes(
        String(watch('general.persons')),
        worksiteAddress,
        simulatorData,
        updateIncomesOptionsArray,
        t,
        setIsLoadingIncomes
      );
    }
  }, [watch('general.persons')]);

  useEffect(() => {
    if (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION) {
      updateInformationBeneficiaryDataPost((prevState) => ({
        ...prevState,
        fiscalDeclarations: taxHouseholdDatas,
      }));
      taxHouseHoldToSimulator(updateSimulatorData, taxHouseholdDatas);
    }
  }, [taxHouseholdDatas, worksiteOperationType]);

  useEffect(() => {
    let btnDisabled = !checkGeneralFields(
      ['owner', 'persons', 'income.precarityType'],
      simulatorData
    );
    const { fiscalDeclarations } = informationBeneficiaryDataPost;

    const fiscalDeclarationError = fiscalDeclarations.some(
      (element) =>
        element.firstname === '' ||
        element.lastname === '' ||
        element.fiscal_number === '' ||
        element.fiscal_reference === ''
    );

    if (
      worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION &&
      fiscalDeclarationError
    ) {
      btnDisabled = simulatorData['general.income.precarityType'] !== 4;
    }

    updateDisabledNextButton(btnDisabled);
  }, [simulatorData, worksiteOperationType, informationBeneficiaryDataPost]);

  return (
    <div className="flex flex-wrap ">
      {filteredAndSortedGraphs.map((graph) => {
        if (
          graph.key === 'general.income' &&
          !incomesOptionsArray &&
          !isLoadingIncomes
        ) {
          return null;
        }
        return (
          <div key={graph.name} className="w-full">
            {graph.key === 'general.income' && isLoadingIncomes ? (
              <div className="flex flex-col space-y-[1rem] pt-[3rem]">
                <div className="w-[15rem]">
                  <LoaderSkeleton height="1rem" />
                </div>
                <div className="w-[20rem]">
                  <LoaderSkeleton height="1rem" />
                </div>
                <div>
                  <LoaderSkeleton height="2rem" />
                </div>
                <div>
                  <LoaderSkeleton height="2rem" />
                </div>
                <div>
                  <LoaderSkeleton height="2rem" />
                </div>
                <div>
                  <LoaderSkeleton height="2rem" />
                </div>
              </div>
            ) : (
              <CardForm
                title={
                  graph.key === 'general.income'
                    ? graph.name
                    : t('worksite_creation.simulation.infos_household')
                }
                subtitle={
                  graph.key === 'general.income' ? graph.description : ''
                }
                idForm="sendBeneficiarySimulationInfos"
                onSubmit={onSubmit}
                methods={methods}
              >
                <div className="mt-[1.5rem]">
                  {graph.childrens ? (
                    <BeneficiarySimulationForm infosGraph={graph.childrens} />
                  ) : (
                    <RenderFormGraphGeneral graph={graph} />
                  )}
                </div>
              </CardForm>
            )}
          </div>
        );
      })}
      {simulatorData['general.income.precarityType'] &&
        simulatorData['general.income.precarityType'] !== 4 &&
        worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION && (
          <TaxHousehold
            taxHouseholdDatas={taxHouseholdDatas}
            setTaxHouseholdDatas={setTaxHouseholdDatas}
            methods={methods}
          />
        )}
      <TextError errorMessage={errorBeneficiary} />
    </div>
  );
}

export { StepBeneficiarySimulation };
