/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import { CardInformations } from '@components/informations/CardInformations';
import { CardLocalisation } from '@components/informations/CardLocalisation';
import { CardWorksites } from '@models/partners/components/informations/cards/CardWorksites';

import {
  updateSubcontractorInfos,
  getSubcontractorWorksites,
} from '@models/partners/apiRequests/subcontractorsRequest';

import { IPartnerInfosGenerales } from '@models/partners/utils/types/partnersType';

import {
  ContactPageIcon,
  InfoCircleIcon,
  PositionIcon,
} from '@assets/images/svgComponents';
import { ISubcontractorUserType } from '@models/auth/utils/types';

interface ITabSubcontractorInfosProps {
  canEdit: boolean;
  subcontractor: IPartnerInfosGenerales;
  userDetails: ISubcontractorUserType;
  loading: boolean;
  refetch: CallableFunction;
}
function TabSubcontractorInfos({
  canEdit,
  subcontractor,
  userDetails,
  loading,
  refetch,
}: ITabSubcontractorInfosProps) {
  const { t } = useTranslation();
  const { id } = useParams();

  const [worksites, setWorksites] = useState<any[]>([]);
  const [loadingWorksites, setLoadingWorksites] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const getWorksites = async (pageParam: number) => {
    setLoadingWorksites(true);

    const resWorksites = await getSubcontractorWorksites(
      Number(id),
      pageParam,
      5
    );

    if (resWorksites) {
      setWorksites(resWorksites.data);

      setPaginationData(resWorksites.meta);

      if (resWorksites?.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          { data: resWorksites.data, meta: resWorksites },
        ]);
      }
    }
    setLoadingWorksites(false);
  };

  const onEdit = async (formData: FieldValues) => {
    const resSubcontractors = await updateSubcontractorInfos(Number(id), {
      address: formData.address.address,
      zipcode: formData.address.postal_code,
      city: formData.address.city,
      country: formData.address.country,
      additional_address:
        typeof formData.additional_address === 'string'
          ? formData.additional_address
          : `${formData.additional_address.address || ''} ${
              formData.additional_address.postal_code || ''
            } ${formData.additional_address.city || ''} ${
              formData.additional_address.country || ''
            }`.trim(),
      contact_email: formData.contact_email,
      phone_number: formData.phone_number,
    });

    if (resSubcontractors) {
      refetch();
    }
  };

  const pageAlreadyLoad = (page: number) => {
    setWorksites(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  useEffect(() => {
    getWorksites(1);
  }, []);

  return (
    <div className="mt-6 grid gap-x-0 md:gap-x-[1.5%] gap-y-4 grid-cols-1 md:grid-cols-[32.5%_66%]">
      {/* GENERAL INFOS */}
      <div>
        <CardInformations
          title={t('partners.general_infos')}
          data={{
            status: subcontractor?.active,
            rows: [
              [
                {
                  name: 'company_name',
                  title: t('partners.company_name'),
                  value: subcontractor?.company_name,
                  icon: <InfoCircleIcon />,
                },
                {
                  name: 'logo',
                  value: subcontractor?.logo,
                  type: 'image',
                },
                {
                  name: 'siret',
                  title: t('partners.siret'),
                  value: subcontractor?.siret,
                },
                {
                  name: 'siren',
                  title: t('partners.siren'),
                  value: subcontractor?.siren,
                },
              ],
              [
                {
                  name: 'address',
                  title: t('partners.postal_address'),
                  value: {
                    address: subcontractor?.address,
                    postal_code: subcontractor?.zipcode,
                    city: subcontractor?.city,
                    country: subcontractor?.country,
                  },
                  icon: <PositionIcon />,
                  isEditable: canEdit,
                  type: 'address',
                },
                {
                  name: 'additional_address',
                  title: t('partners.additional_address'),
                  value: subcontractor?.additional_address,
                  isEditable: canEdit,
                },
              ],
              [
                {
                  name: 'firstname',
                  title: t('partners.subcontractors.firstname_lastname'),
                  value: userDetails?.firstname
                    ? `${userDetails?.firstname} ${userDetails?.lastname}`
                    : '-',
                  icon: <ContactPageIcon />,
                },
                {
                  name: 'contact_email',
                  title: t('partners.email_address'),
                  value: userDetails?.email,
                  type: 'email',
                },
                {
                  name: 'phone_number',
                  title: t('forms.phone.placeholder'),
                  value: userDetails?.phone_number,
                  type: 'phone',
                },
              ],
            ],
          }}
          loading={loading}
          onEdit={onEdit}
          dataTestId="informations_card"
        />

        <CardLocalisation
          address={subcontractor?.address || ''}
          loading={loading}
        />
      </div>

      <CardWorksites
        data={worksites}
        loading={loadingWorksites}
        type="worksite"
        pagination={{
          paginationData,
          pagesDatas,
          getData: getWorksites,
          pageAlreadyLoad,
        }}
      />
    </div>
  );
}

export { TabSubcontractorInfos };
