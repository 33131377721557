/* eslint-disable no-shadow */
export enum UserFilterCriteria {
  STATUS = 19,
}

export enum UserStatus {
  ACTIVE = 2,
  INACTIVE = 1,
  PENDING = 3,
}

export enum IdentityStatus {
  NOT_CREATED = 1,
  CREATED = 2,
  CLICKED = 3,
  CAPTURE_ONGOING = 4,
  SUCCESS = 5,
  EXPIRED = 6,
  ERROR = 7,
}
