import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';

import { Tag } from '@components/atomic/Tag';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';

import { grey, textGrey } from '@assets/color';
import { convertKiloToMega } from '@utils/functions';
import { numberWithSeparator } from '@utils/format';
import { getVolumesRender } from '@models/contracts/utils/contractHelper';

interface IControlItemProps {
  item: any;
  removeList: any[];
  setRemoveList: (v: any) => void;
  enableComments: boolean;
  identifier: string;
}

function ControlItem(props: IControlItemProps) {
  const { item, removeList, setRemoveList, enableComments, identifier } = props;
  const { t } = useTranslation();

  const { globalEnum } = useContext(GlobalContext);

  const bene = item.worksite_operation.worksite.beneficiary;
  const beneficiary = bene.siret
    ? `${bene?.company_name || ''}`
    : `${bene?.firstname || ''} ${bene?.lastname || ''}`;

  const inst = item.worksite_operation.worksite.installer;
  const installer = inst ? inst.company_name : '';

  const volumePrecaire = !item.worksite_operation.kwhc_precaire
    ? null
    : convertKiloToMega(item.worksite_operation.kwhc_precaire);
  const volumeClassique = !item.worksite_operation.kwhc_classique
    ? null
    : convertKiloToMega(item.worksite_operation.kwhc_classique);

  const opCode = item.worksite_operation.operation.code;
  const opDesc = item.worksite_operation.operation.description;

  const prime = numberWithSeparator(
    String(
      (item.worksite_operation.cdp_amount > 0
        ? item.worksite_operation.cdp_amount
        : item.worksite_operation.cee_amount) || ''
    )
  );

  const { cee_precaire, cee_classique } = item.worksite_operation;

  const indexInRemoveList = removeList.findIndex(
    (el: any) => el.id === item[identifier]
  );

  const itemCommentary =
    indexInRemoveList > -1 ? removeList[indexInRemoveList].commentary : '';

  return (
    <div className="flex flex-wrap items-center">
      <div className="w-[5%]">
        <Checkbox
          label=""
          onCheck={() => {
            const tmp = [...removeList];

            if (indexInRemoveList === -1) {
              tmp.push({
                id: item[identifier],
                commentary: '',
                mwhc:
                  Number(volumeClassique?.replace(',', '.') || '0') +
                  Number(volumePrecaire?.replace(',', '.') || '0'),
              });
            } else {
              // remove it from the list
              tmp.splice(indexInRemoveList, 1);
            }

            setRemoveList(tmp);
          }}
          checked={indexInRemoveList > -1}
        />
      </div>
      <div className="w-[95%]">
        <div className="flex justify-between flex-wrap items-center">
          <p className="text-[1rem] font-medium">
            {`${beneficiary} / ${installer}`}
          </p>
          {getVolumesRender(item.worksite_operation, globalEnum)}
        </div>

        <div className="flex justify-between flex-wrap items-center">
          <div className="flex flex-wrap items-center">
            <Tag
              color={textGrey}
              label={opCode}
              addClass="h-fit mr-[1rem] min-w-[6rem] text-center"
            />
            <p className="text-[0.875rem] my-[0.656rem]">{opDesc}</p>
          </div>

          {!cee_classique || !cee_precaire ? (
            <p className="text-textGrey text-[0.875rem]">{`${t(
              'contractMandant.amount_prime'
            )}: ${prime} €`}</p>
          ) : (
            <div className="text-textGrey text-[0.875rem] mt-1">
              <p>
                {t('contract.prime_precaire')}:{' '}
                {numberWithSeparator(String(cee_precaire))} €{' '}
              </p>
              <p>
                {t('contract.prime_classic')}: :{' '}
                {numberWithSeparator(String(cee_classique))} €
              </p>
            </div>
          )}
        </div>
      </div>
      {enableComments && indexInRemoveList > -1 && (
        <div className="w-full my-3">
          {/* Here, we are explicitely using a custom input field instead of component InputText because of state re-rendering */}
          <input
            type="text"
            id={`commentary-${item[identifier]}`}
            name={`commentary-${item[identifier]}`}
            placeholder={`${
              itemCommentary || t('contractMandant.commentary_placeholder')
            }`}
            required
            className="rounded-default p-[.5rem] w-full"
            style={{
              borderColor: grey,
              borderWidth: '1px',
            }}
            onBlur={(e) => {
              const tmp = [...removeList];
              tmp[indexInRemoveList].commentary = e.target.value;
              setRemoveList(tmp);
            }}
          />
        </div>
      )}
    </div>
  );
}

export { ControlItem };
