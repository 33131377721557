import { AuthLayout } from '@pages/auth/AuthLayout';
import { useContext, useState } from 'react';
import { GlobalContext } from '@context/globalContext';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import { ENTITY_TYPES } from '@utils/roles';
import OneSubscription from '@models/auth/components/registrationSteps/OneSubscription';
import { useNavigate } from 'react-router-dom';
import { postSubscriptionUpgrade } from '@models/users/apiRequests/userRequests';
import { AuthContext } from '@context/authContext';
import { SETTINGS_TABS } from '@models/settings/utils/utils';
import { SETTINGS_ROUTES } from '@utils/routesUrls';

function UpgradeSubscriptionModal() {
  const { t } = useTranslation();
  const { updateDisplayModalUpgradeSubscription, globalEnum } =
    useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const entityTypeLabel =
    globalEnum?.entity_type[user?.entity_type as number] || '';
  const subscriptionType = t(
    'auth.registration.subscription_overview.subscription',
    {
      entityTypeLabel,
    }
  );
  const onSubmitUpgrade = async () => {
    setIsLoading(true);
    if (user?.current_entity_type) {
      await postSubscriptionUpgrade(user.current_entity_type);
    }
    navigate(
      `${SETTINGS_ROUTES.SETTINGS}#${SETTINGS_TABS.SUBSCRIPTION_AND_PAYMENT}`
    );
    updateDisplayModalUpgradeSubscription(false);
    setIsLoading(false);
  };

  return (
    <AuthLayout isModal>
      <div>
        <div className="flex flex-col space-y-1">
          <div className="text-[1.6875rem] font-medium leading-10">
            {t('upgrade_subscription_modal.title')}
          </div>
          <div className="text-neutral-900 text-sm font-light leading-tight">
            {`${t(
              'upgrade_subscription_modal.description_1'
            )} ${subscriptionType}.`}
          </div>
        </div>
        <div className="flex mt-4 space-x-2">
          <div>
            <div className="text-lg font-medium leading-7 mb-4">
              {t('upgrade_subscription_modal.header_1')}
            </div>
            <OneSubscription
              profilsGroup={[user?.entity_type || ENTITY_TYPES.INSTALLATEUR]}
              options={{ discover: true, fromModal: true }}
            />
          </div>
          <div>
            <div className="text-lg font-medium leading-7 mb-4">
              {`${t(
                'upgrade_subscription_modal.header_2'
              )} ${subscriptionType}`}
            </div>
            <OneSubscription
              profilsGroup={[user?.entity_type || ENTITY_TYPES.INSTALLATEUR]}
              options={{ forceSelected: true, fromModal: true }}
            />
          </div>
        </div>

        {/* Bloc Buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <ButtonOpx
            label={t('auth.registration.subscription_overview.return_button')}
            type="secondary"
            onClick={() => updateDisplayModalUpgradeSubscription(false)}
          />
          <ButtonOpx
            label={t(
              `auth.registration.subscription_overview.choose_subscription_button`
            )}
            onClick={() => onSubmitUpgrade()}
            isLoading={isLoading}
          />
        </div>
      </div>
    </AuthLayout>
  );
}

export default UpgradeSubscriptionModal;
