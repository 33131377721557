function LoaderWorksiteDetailsOperationCard() {
  return (
    <div className="border-grey border-[1px] w-full h-full pr-[1.5rem] rounded-[.25em] border-[1px] flex bg-white">
      <div className="px-[2rem] pb-[.875rem]">
        <div className="h-full flex-col items-center flex w-[.25rem]">
          <div className="w-full h-[90%] skeleton-loader-anim" />
          <div className="skeleton-loader-anim h-[1.5rem] w-[1.5rem]" />
        </div>
      </div>
      <div className="w-full flex space-x-[1.5rem] items-baseline">
        <div className="w-full">
          <div className="w-full py-[1.5rem]">
            <div className="flex items-center justify-between pb-[.875rem]">
              <div className="flex items-center space-x-[1rem]">
                <div className="skeleton-loader-anim h-[2rem] w-[10rem]" />
                <div className="skeleton-loader-anim h-[1.25rem] w-[10rem]" />
              </div>
              <div className="flex items-center space-x-[1rem]">
                <div className="skeleton-loader-anim h-[1.25rem] w-[10rem]" />
                <div className="skeleton-loader-anim h-[1.25rem] w-[10rem]" />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between py-[.875rem] border-grey border-t-[1px]">
            <div className="skeleton-loader-anim h-[1.25rem] w-[12rem]" />
            <div className="text-right">
              <div className="skeleton-loader-anim h-[1.25rem] w-[15rem]" />
            </div>
          </div>
        </div>
        <div className="skeleton-loader-anim h-[1.5rem] w-[1.5rem]" />
      </div>
    </div>
  );
}
export { LoaderWorksiteDetailsOperationCard };
