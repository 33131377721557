import { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { textGreyInfos } from '@assets/color';
import { DEFAULT_BENEFICIARY } from '@models/beneficiaries/utils/beneficiariesConstants';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import {
  FormAddress,
  IFormAddressDatas,
} from '@components/formAddress/FormAddress';

interface IAddressEditProps {
  name: string;
  label: string;
  value: FieldValues;
  setValue: UseFormSetValue<FieldValues>;
  addClass?: string;
}

function AddressEdit({
  name,
  label,
  value: formValue,
  setValue: setAddress,
  addClass,
}: IAddressEditProps) {
  const [isManualAddress, setIsManualAddress] = useState(false);

  const [addressDatas, setAddressDatas] = useState<IFormAddressDatas>({
    city: formValue?.city || '',
    country: formValue?.country || '',
    street: formValue?.address || '',
    streetNumber: '',
    zipCode: formValue?.postal_code || '',
  });

  const { city, country, street, streetNumber, zipCode } = addressDatas;

  const [valueAddress, setValueAddress] = useState<IBeneficiaryAddress>(
    typeof formValue === 'string'
      ? { ...DEFAULT_BENEFICIARY.address, address: formValue }
      : { ...DEFAULT_BENEFICIARY.address, ...formValue }
  );

  const updateAddressFromKeyValue = (updates: Partial<IFormAddressDatas>) => {
    setAddressDatas((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    setValueAddress(
      typeof formValue === 'string'
        ? { ...DEFAULT_BENEFICIARY.address, address: formValue }
        : { ...DEFAULT_BENEFICIARY.address, ...formValue }
    );
  }, [formValue]);

  useEffect(() => {
    if (typeof formValue === 'string') {
      setAddress(
        name,
        `${valueAddress.address || ''} ${valueAddress.postal_code || ''} ${
          valueAddress.city || ''
        } ${valueAddress.country || ''}`.trim()
      );
    } else {
      setAddress(name, valueAddress);
    }
  }, [valueAddress]);

  useEffect(() => {
    setValueAddress((prev) => ({
      ...prev,
      city,
      country,
      address: street,
      postal_code: zipCode,
    }));
  }, [city, country, street, zipCode]);

  useEffect(() => {
    updateAddressFromKeyValue({ street: `${streetNumber} ${street}`.trim() });
  }, [streetNumber]);

  return (
    <div>
      <p
        className={['text-[.625rem] leading-[.75rem] mb-1', addClass].join(' ')}
        style={{ color: textGreyInfos }}
      >
        {label}
      </p>
      <FormAddress
        addressDatas={valueAddress}
        setAddressDatas={setValueAddress}
        setIsManual={setIsManualAddress}
        isManual={isManualAddress}
        datas={addressDatas}
        updateDatas={updateAddressFromKeyValue}
        updateDatasDirectForm
      />
    </div>
  );
}

AddressEdit.defaultProps = {
  addClass: undefined,
};

export { AddressEdit };
