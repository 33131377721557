import { useRef, useState, useEffect } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonOpx } from '@components/atomic/ButtonOpx';

import { IInfosLine } from 'types/globalTypes';
import ImageContainer from '@components/atomic/ImageContainer';

interface IInputImageProps {
  data: IInfosLine;
  setValue: UseFormSetValue<FieldValues>;
  imageText?: string;
}

function InputImage({ data, setValue, imageText }: IInputImageProps) {
  const { name, value, width, height } = data;
  const { t } = useTranslation();
  const inputFile = useRef(null);

  const [file, setFile] = useState<File | null | string>(value || null);
  const [preview, setPreview] = useState('');

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!file) {
      setPreview('');
      return;
    }

    if (typeof file !== 'string') {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    } else {
      setPreview(file);
    }

    // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleChange = (e: any) => {
    setFile(e.target.files[0]);
    setValue(name, e.target.files[0]);
  };

  return (
    <div className="block">
      <ImageContainer
        imageUrl={preview}
        width={width}
        height={height}
        addClass="mb-4 mr-4"
        imageText={imageText}
      />
      <div className="relative flex  flex-col w-[max-content]">
        <input
          className="absolute opacity-0"
          onChange={handleChange}
          type="file"
          ref={inputFile}
          style={{ width: 1, height: 1 }}
        />
        <ButtonOpx
          onClick={() =>
            inputFile.current !== null
              ? (inputFile.current as any).click()
              : null
          }
          type="primary"
          label={t('my_account.update_image')}
          addClass="btn p-5 mb-3"
          small
        />
        <ButtonOpx
          onClick={() => {
            setFile(null);
            setValue(name, null);
          }}
          type="secondary"
          label={t('my_account.delete_image')}
          small
          addClass="btn border 1 p-5"
          color="red"
        />
      </div>
    </div>
  );
}

InputImage.defaultProps = { imageText: '' };

export { InputImage };
