import { IBeneficiary } from '@models/beneficiaries/utils/beneficiariesType';

export const DEFAULT_BENEFICIARY: IBeneficiary = {
  id: null,
  civility: null,
  firstname: null,
  lastname: null,
  address: {
    address_type: null,
    address: '',
    postal_code: '',
    city: '',
    country: '',
    elevation: null,
    latitude: null,
    longitude: null,
    additional_address: null,
    selected: false,
  },
  fixe_phone: null,
  mobile_phone: null,
  email: null,
  worksites: [],
};
