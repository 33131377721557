import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BENEFICIARIES_ROUTES_WITH_ID } from '@utils/routesUrls';

interface ICardOneBeneficiaryProps {
  leftPart: string;
  rightPart?: IBeneficiaryAddress | string;
  picture?: string;
  onClick?: () => void;
  addClass?: string;
  id?: string;
}

function CardOneBeneficiaryOrPartner({
  leftPart,
  rightPart,
  picture,
  onClick,
  addClass,
  id,
}: ICardOneBeneficiaryProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={onClick}
      className={[
        'flex space-x-[1rem] w-full items-center text-[.875rem] p-[.5rem] bg-white rounded-default',
        addClass,
        onClick
          ? 'cursor-pointer hover:bg-backgroundBody hover:border-transparent'
          : 'cursor-default',
      ].join(' ')}
      data-test-id="beneficiary_or_partner_card"
    >
      {picture && (
        <div
          className={`h-[2rem] w-[2rem] overflow-hidden rounded-default ${
            picture ? '' : 'bg-[#C4C4C4]'
          }`}
        >
          {picture && (
            <img src={picture} className="object-cover" alt="beneficiary-pic" />
          )}
        </div>
      )}
      <div className="flex space-x-[1rem] w-full items-center">
        <p className="font-medium">{leftPart}</p>
        {rightPart && (
          <p>
            {typeof rightPart === 'string'
              ? rightPart
              : `${rightPart.address} ${
                  rightPart.postal_code
                } ${rightPart.city.toUpperCase()}`}
          </p>
        )}
      </div>
      {id && (
        <ButtonOpx
          onClick={() =>
            navigate(BENEFICIARIES_ROUTES_WITH_ID(id).BENEFICIARY_VIEW)
          }
          label={t('buttons.update')}
          type="tierciary"
          addClass="!border-transparent"
          small
        />
      )}
    </button>
  );
}

export { CardOneBeneficiaryOrPartner };

CardOneBeneficiaryOrPartner.defaultProps = {
  rightPart: undefined,
  picture: undefined,
  addClass: '',
  onClick: undefined,
  id: undefined,
};
