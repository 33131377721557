import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';

import SubscriptionAndPaymentTab from '@models/settings/components/SubscriptionAndPaymentTab';
import SettingsTabsNav from '@models/settings/components/SettingsTabsNav';
import { SETTINGS_TABS } from '@models/settings/utils/utils';
import CustomFieldsTab from '@models/settings/components/CustomFieldsTab';
import { useLocation } from 'react-router-dom';
import { Brands } from '@pages/Brands';

function Settings() {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const location = useLocation();
  const hash = location.hash.substring(1);
  const defaultTab = () => {
    if (Object.values(SETTINGS_TABS).includes(hash)) {
      return hash;
    }
    return SETTINGS_TABS.CUSTOM_FIELDS;
  };
  const [tabActive, setTabActive] = useState<string>(defaultTab());

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.settings')}`);
    refreshHeader();
  }, []);

  const renderTab = () => {
    switch (tabActive) {
      case SETTINGS_TABS.SUBSCRIPTION_AND_PAYMENT:
        return <SubscriptionAndPaymentTab />;
      case SETTINGS_TABS.CUSTOM_FIELDS:
        return <CustomFieldsTab />;
      case SETTINGS_TABS.BRANDS:
        return <Brands />;
      default:
        return <SubscriptionAndPaymentTab />;
    }
  };

  return (
    <div className="w-full flex space-x-6 pt-6">
      <SettingsTabsNav tabActive={tabActive} setTabActive={setTabActive} />
      {renderTab()}
    </div>
  );
}

export { Settings };
