import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { lightBlue, purple } from '@assets/color';

interface ILinePlotProps {
  data: any[];
  activeYear: number;
  xAxisReference: string; // for each item in data, precaire and classique values are in { xAxisReference: { classique: 12, precaire: 13 } }
  dateReference?: string;
}

function LinePlot(props: ILinePlotProps) {
  const { data, activeYear, xAxisReference, dateReference } = props;
  const { t } = useTranslation();

  const months = [
    t('months.january'),
    t('months.february'),
    t('months.march'),
    t('months.april'),
    t('months.may'),
    t('months.june'),
    t('months.july'),
    t('months.august'),
    t('months.september'),
    t('months.october'),
    t('months.november'),
    t('months.december'),
  ];

  const getVolumeData = (type: string) => {
    const result: (number | null)[] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ];

    if (data.length > 0) {
      data.map((item: any) => {
        const xAxisDate = new Date(item[dateReference || 'created_at']);

        const dateYear = format(xAxisDate, 'yyyy');

        const isThisYear = String(activeYear) === dateYear;

        if (isThisYear) {
          const monthIdx = Number(format(xAxisDate, 'MM')) - 1; // using -1 because january is at index 0
          const value = item[xAxisReference][type];

          result[monthIdx] = Number(result[monthIdx]) + Number(value);
        }

        return result.map((elt) =>
          elt ? Math.round((elt / 1000000) * 100) / 100 : elt
        );
      });
    }

    return result.map((elt) =>
      elt ? Math.round((elt / 1000000) * 100) / 100 : elt
    );
  };

  const options = {
    chart: {
      type: 'spline',
    },
    title: { text: '' },
    xAxis: {
      categories: months,
    },
    yAxis: {
      title: {
        text: 'Gwhc',
      },
    },
    series: [
      {
        name: `${t('transfer.volume_precaire')} (Gwhc)`,
        data: getVolumeData('precaire'),
        color: purple,
      },
      {
        name: `${t('transfer.volume_classic')} (Gwhc)`,
        data: getVolumeData('classique'),
        color: lightBlue,
      },
    ],
  };

  return (
    <div>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
}

LinePlot.defaultProps = {
  dateReference: undefined,
};

export { LinePlot };
