import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';

function DatePickerDesign() {
  const handleSelection = (value: any) => {
    return value;
  };

  return (
    <div className="w-1/6 flex-col items-center mb-6">
      <p className="mb-4">DATE PICKER</p>
      <DatePicker
        label="Saisir une date"
        required
        onChangeDate={(value: string) => handleSelection(value)}
      />
      <p>Erreur sur date:</p>
      <DatePicker
        label="Saisir une date"
        required
        onChangeDate={(value: string) => handleSelection(value)}
        error
        textError="La date indiquée n’est pas valide."
      />
    </div>
  );
}

export { DatePickerDesign };
