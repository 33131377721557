import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { useNavigate } from 'react-router-dom';
import PartLeftDocuments from './PartLeftDocuments';
import { PartRightDocuments } from './PartRightDocuments';
import { GlobalContext } from '../../../../../context/globalContext';
import { getWorksite } from '../../../apiRequests/worksiteCreationRequests';
import { initDocumentActive } from '../../../../../utils/initialState';

function StepDocuments() {
  const { t } = useTranslation();
  const router = useNavigate();
  const { worksiteDatas, updateWorksiteDatas } = useContext(
    WorksiteCreationContext
  );
  const { updateDocumentActive } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      worksiteDatas.linkedFiles &&
      worksiteDatas.linkedFiles[0]?.file_url !== undefined &&
      worksiteDatas.linkedFiles[0]?.file_url !== null
    ) {
      updateDocumentActive({
        list: t('worksite_creation.create_worksite.document.global_worksite'),
        document: worksiteDatas.linkedFiles[0],
      });
    } else {
      updateDocumentActive(initDocumentActive);
    }
    getWorksite(
      t,
      worksiteDatas.id,
      {
        updateWorksiteDatas,
        setIsLoading,
      },
      router
    );
  }, []);

  return (
    <div className="relative flex flex-grow space-x-[1.5rem] w-full overflow-y-scroll">
      {!isLoading ? (
        <>
          <PartLeftDocuments />
          <PartRightDocuments />
        </>
      ) : (
        <>
          <LoaderSkeleton height="100%" />
          <LoaderSkeleton height="100%" />
        </>
      )}
    </div>
  );
}

export { StepDocuments };
