/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import {
  urlContracts,
  urlGetAndDeleteTransfer,
  urlGetContractInfos,
  urlGetContractsBuyingPrices,
  urlGetContractTypesSale,
  urlGetContractTypesMandant,
  urlGetConventionBuyingPrices,
  urlGetTransferLinkedOperations,
  urlGetTransferOperationsToLink,
  urlGetVolumeDetail,
  urlPostTransferLinkOperation,
  urlPostTransferUnlinkOperation,
  urlPostUpdateVolume,
  urlPostUpsertTransfer,
  urlUpdateContact,
  urlAdminContracts,
} from '@api/apiUrls';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { Dispatch, SetStateAction } from 'react';

export const getContractList = async (
  page: number,
  perPage: number,
  contractStatusUI: number,
  sortAndFilter?: ISortAndFilterType,
  adminMode?: boolean
) => {
  const configs = {
    pagination: {
      page,
      perPage,
    },
    contractStatusUI,
    sort_and_filter: sortAndFilter,
  };

  try {
    const response = await axiosClient.get(
      adminMode ? urlAdminContracts : urlContracts,
      {
        params: configs,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getContractTypes = async (isSale: boolean) => {
  try {
    const response = await axiosClient.get(
      isSale ? urlGetContractTypesSale : urlGetContractTypesMandant
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getContractInfos = async (contractId: number) => {
  try {
    const response = await axiosClient.get(urlGetContractInfos(contractId));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateContact = async (
  contactId: number,
  data: Record<string, string | number>
) => {
  try {
    const response = await axiosClient.post(urlUpdateContact(contactId), data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getBuyingPrices = async (id: number, isConvention: boolean) => {
  const url = isConvention
    ? urlGetConventionBuyingPrices(id)
    : urlGetContractsBuyingPrices(id);

  try {
    const response = await axiosClient.get(url);
    const { contractsOperations, conventionsOperations } = response.data.data;
    return isConvention ? conventionsOperations : contractsOperations;
  } catch (error) {
    displayError(error.response);
  }
};

export const getVolumeDetail = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetVolumeDetail(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const upsertTransfer = async (
  data: Record<string, number | string | null>
) => {
  try {
    const response = await axiosClient.post(urlPostUpsertTransfer, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getTransfer = async (transferId: number) => {
  try {
    const response = await axiosClient.get(urlGetAndDeleteTransfer(transferId));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const deleteTransfer = async (transferId: number) => {
  try {
    const response = await axiosClient.delete(
      urlGetAndDeleteTransfer(transferId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getLinkedOperations = async (
  transferId: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  const data = {
    keyword: search !== '' ? search : null,
    sort_and_filter: sortAndFilter,
  };
  try {
    const response = await axiosClient.post(
      urlGetTransferLinkedOperations(transferId),
      data
    );
    setIsLoading((prev) => ({ ...prev, linked: false }));
    return response.data;
  } catch (error) {
    setIsLoading((prev) => ({ ...prev, linked: false }));
    displayError(error.response);
  }
};

export const getOperationsToLink = async (
  transferId: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  const data = {
    keyword: search !== '' ? search : null,
    sort_and_filter: sortAndFilter,
  };
  try {
    const response = await axiosClient.post(
      urlGetTransferOperationsToLink(transferId),
      data
    );
    setIsLoading((prev) => ({ ...prev, toLink: false }));
    return response.data;
  } catch (error) {
    setIsLoading((prev) => ({ ...prev, toLink: false }));
    displayError(error.response);
  }
};

export const transferLinkOperation = async (
  contractId: number,
  contractTransferId: number,
  operationId: number,
  kwhcClassique: number,
  kwhcPrecaire: number
) => {
  try {
    const response = await axiosClient.post(urlPostTransferLinkOperation, {
      contract_id: contractId,
      contract_transfer_id: contractTransferId,
      worksite_operation_id: operationId,
      kwhc_classique: kwhcClassique,
      kwhc_precaire: kwhcPrecaire,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const transferUnLinkOperation = async (
  contractTransferId: number,
  operationId: number
) => {
  try {
    const response = await axiosClient.post(urlPostTransferUnlinkOperation, {
      contract_transfer_id: contractTransferId,
      worksite_operation_id: operationId,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateVolume = async (
  operationId: number,
  kwhcClassique: number,
  kwhcPrecaire: number
) => {
  try {
    const response = await axiosClient.post(urlPostUpdateVolume, {
      id: operationId,
      kwhc_precaire: kwhcPrecaire,
      kwhc_classique: kwhcClassique,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};
