import { CardForm } from '@models/worksiteCreation/components/CardForm';
import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

function CardFormWorksiteCreationDesign() {
  const methods = useForm();
  // TEST TextWithCheckboxOrToggle toggle
  const [isToggle, setIsToggle] = useState(false);
  const onSubmit = async (formData: FieldValues) => {
    console.log(formData);
  };
  return (
    <div className="w-2/3 flex-col items-center mb-6">
      <div className="mt-5">CARD BENEFICIAIRE CREATION DE CHANTIER</div>
      <CardForm
        title="Informations générales du logement"
        subtitle="Les aides diffèrent selons les zones climatiques du logement."
        idForm="testIdForm"
        onSubmit={onSubmit}
        methods={methods}
      >
        <TextWithCheckboxOrToggle
          label="Toggle 2"
          onCheck={() => setIsToggle(!isToggle)}
          checked={isToggle}
          type="toggle"
          addClass="mt-5"
        />
      </CardForm>
    </div>
  );
}

export { CardFormWorksiteCreationDesign };
