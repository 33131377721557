export const requestInitial = {
  id: 0,
  relation_type: '',
  relation_id: 0,
  type: 0,
  subject: '',
  message: null,
  tags: 0,
  nb: 0,
  status: 0,
  created_at: '',
  installer: {
    active: true,
    address: '',
    city: '',
    company_name: '',
    contact_email: null,
    country: null,
    employees_number: null,
    id: 2,
    logo: '',
    phone_number: null,
    revenue: null,
    siret: '',
    type: null,
    website: null,
    zipcode: '',
  },
  address: '',
  all_external_messages: [],
};

export const listRequestsGroupInitial = { waiting: [], todo: [] };
