import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardFormWorksiteCreationDesign } from '@models/design/components/CardFormWorksiteCreationDesign';
import { ControlsDesign } from '@models/design/components/ControlsDesign';
import { DatePickerDesign } from '@models/design/components/DatePickerDesign';
import { ModalDesign } from '@models/design/components/ModalDesign';
import { OneOperationCardsDesign } from '@models/design/components/OneOperationCardsDesign';
import { KpiTabDesign } from '@models/design/components/KpiTabDesign';
import { SidebarProgressWorksiteCreationDesign } from '@models/design/components/SidebarProgressWorksiteCreationDesign';
import { CardChoicePartnerInModalWorksiteCreationDesign } from '@models/design/components/CardChoicePartnerInModalWorksiteCreationDesign';
import { HeaderContext } from '@context/headerContext';
import { Tag } from '@components/atomic/Tag';
import { green } from '@assets/color';
import { SearchBarDesign } from '@models/design/components/SearchBarDesign';
import { CardOneBeneficiaryWorksiteCreationDesign } from '@models/design/components/CardOneBeneficiaryWorksiteCreationDesign';
import { CardDesign } from '@models/design/components/CardDesign';
import { CardInfosDesign } from '@models/design/components/CardInfosDesign';

function Design() {
  const { t } = useTranslation();

  const { updateTitleHeader, updateSubtitleHeader, updateTagHeader } =
    useContext(HeaderContext);

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.design')}`);
    updateSubtitleHeader('Espace de review & QA design');
    updateTagHeader(<Tag color={green} label="Ready" />);
  }, []);

  return (
    <>
      <CardInfosDesign />
      <SearchBarDesign />
      <DatePickerDesign />
      <ControlsDesign />
      <ModalDesign />
      <KpiTabDesign />
      <OneOperationCardsDesign />
      <CardFormWorksiteCreationDesign />
      <CardChoicePartnerInModalWorksiteCreationDesign />
      <SidebarProgressWorksiteCreationDesign />
      <CardOneBeneficiaryWorksiteCreationDesign />
      <CardDesign />
    </>
  );
}

export { Design };
