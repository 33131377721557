import { useEffect, useMemo, useState } from 'react';
import { Pagination } from '@components/atomic/pagination/Pagination';
import { blueOpx } from '@assets/color';

interface IListPaginationProps {
  getData: (page: number) => void;
  paginationData: Record<string, string | number | null> | undefined;
  resultsPerPage?: number;
  currentPage?: number;
}

function ListPagination({
  getData,
  paginationData,
  resultsPerPage,
  currentPage,
}: IListPaginationProps) {
  const [pageNb, setPageNb] = useState<number>(
    paginationData ? Number(paginationData.current_page) : 1
  );

  const onNextPage = async () => {
    setPageNb(pageNb + 1);
    await getData(pageNb + 1);
  };

  const onPreviousPage = async () => {
    const newPageNb = pageNb !== 1 ? pageNb - 1 : pageNb;
    setPageNb(newPageNb);
    await getData(newPageNb);
  };

  const totalNbPage = useMemo(() => {
    return paginationData ? Number(paginationData.last_page) : 0;
  }, [paginationData, resultsPerPage]);

  useEffect(() => {
    setPageNb(currentPage || 1);
  }, [currentPage]);

  return (
    <div className="relative mb-4 flex items-center justify-center">
      <Pagination
        numberPage={pageNb}
        totalNumberPage={totalNbPage}
        colorPagination={blueOpx}
        addClass="mt-[1rem]"
        onNext={onNextPage}
        onPrevious={onPreviousPage}
      />
    </div>
  );
}

export { ListPagination };

ListPagination.defaultProps = {
  resultsPerPage: undefined,
  currentPage: undefined,
};
