import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
  blueOpx,
  darkBlue,
  green,
  orange,
  purple,
  red,
  textGreyInfos,
} from '@assets/color';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getWorksitePerMonthDatas } from '@models/dashboard/apiRequests/dashboardRequests';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@context/authContext';
import { isInstaller } from '@utils/roles';
import {
  fakeChartDatas,
  initialEmptyChartDatas,
} from '@models/dashboard/utils/fakeData';
import { ILineChartDatas } from '../utils/dashboardTypes';

interface LineChartProps {
  realData?: boolean;
}

function LineChart({ realData }: LineChartProps) {
  const { t } = useTranslation();
  const [lineChartDatas, setLineChartDatas] = useState<ILineChartDatas>();
  const [months, setMonths] = useState<string[]>([]);

  const { user } = useContext(AuthContext);

  const getData = async () => {
    const resDatas = await getWorksitePerMonthDatas();
    if (resDatas) {
      setLineChartDatas(resDatas);

      // Crée un tableau des mois pour l'axe X
      const currentDate = new Date();
      const monthNames = [
        t('dashboard.linechart.january'),
        t('dashboard.linechart.february'),
        t('dashboard.linechart.march'),
        t('dashboard.linechart.april'),
        t('dashboard.linechart.may'),
        t('dashboard.linechart.june'),
        t('dashboard.linechart.july'),
        t('dashboard.linechart.august'),
        t('dashboard.linechart.september'),
        t('dashboard.linechart.october'),
        t('dashboard.linechart.november'),
        t('dashboard.linechart.december'),
      ];

      const lastSixMonths = []; // Crée un tableau vide pour stocker les noms des mois

      // Boucle pour les 6 derniers mois
      for (let i = 0; i < 6; i += 1) {
        // Obtient le nom du mois actuel à partir du tableau monthNames
        const currentMonthName = monthNames[currentDate.getMonth()];

        // Obtient l'année actuelle à partir de la date en cours
        const currentYear = currentDate.getFullYear();

        // Crée une chaîne de mois et d'année au format "Nom du mois Année"
        const formattedMonth = `${currentMonthName} ${currentYear}`;

        // Ajoute la chaîne formatée au tableau lastSixMonths
        lastSixMonths.push(formattedMonth);

        // Déplace la date vers le mois précédent pour la prochaine itération
        currentDate.setMonth(currentDate.getMonth() - 1);
      }

      // Inverse l'ordre des mois pour les afficher du plus récent au plus ancien
      setMonths(lastSixMonths.reverse());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const seriesToDisplay = useMemo(() => {
    const series = [
      {
        name: t('dashboard.series.number_of_worksite_created_per_month'),
        data: !realData
          ? fakeChartDatas[0]
          : lineChartDatas?.worksite_created_per_month.reverse() ||
            initialEmptyChartDatas,
        type: 'line',
        color: blueOpx,
      },
    ];

    if (!isInstaller(user)) {
      series.push(
        {
          name: t('dashboard.series.number_of_compliant_worksite_per_month'),
          data: !realData
            ? fakeChartDatas[1]
            : lineChartDatas?.worksite_compliant_per_month.reverse() ||
              initialEmptyChartDatas,
          type: 'line',
          color: green,
        },
        {
          name: t('dashboard.series.number_of_worksite_submitted_per_month'),
          data: !realData
            ? fakeChartDatas[2]
            : lineChartDatas?.worksite_submitted_per_month.reverse() ||
              initialEmptyChartDatas,
          type: 'line',
          color: orange,
        },
        {
          name: t('dashboard.series.number_of_validated_worksite_per_month'),
          data: !realData
            ? fakeChartDatas[3]
            : lineChartDatas?.worksite_validated_per_month.reverse() ||
              initialEmptyChartDatas,
          type: 'line',
          color: darkBlue,
        }
      );
    } else {
      series.push(
        {
          name: t('dashboard.series.simulation_created_per_month'),
          data: !realData
            ? fakeChartDatas[1]
            : lineChartDatas?.simulations_created_per_month.reverse() ||
              initialEmptyChartDatas,
          type: 'line',
          color: purple,
        },
        {
          name: t('dashboard.series.worksite_status_above_5_per_month'),
          data: !realData
            ? fakeChartDatas[2]
            : lineChartDatas?.worksite_status_above_5_per_month.reverse() ||
              initialEmptyChartDatas,
          type: 'line',
          color: red,
        }
      );
    }

    return series;
  }, [user, lineChartDatas]);

  const options = {
    chart: {
      type: 'line',
      backgroundColor: '#FFFFFF',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: months,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter() {
          const value = (this as any).value;
          if (value >= 1000) {
            return `${value / 1000}k`;
          }
          return value.toString();
        },
      },
    },
    series: seriesToDisplay,
    plotOptions: {
      line: {
        lineWidth: 3,
        marker: {
          enabled: false,
        },
        colors: [blueOpx, green, orange, darkBlue, purple, red],
      },
    },
    legend: {
      enabled: true,
      floating: false,
      useHTML: false,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemWidth: 300,
      itemMarginBottom: 5,
      itemStyle: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        color: textGreyInfos,
      },
      symbolHeight: 3,
      symbolWidth: 15,
      symbolRadius: 3,
      squareSymbol: false,
      symbolPadding: 25,
      y: 18,
    },
  };

  return (
    <div className="flex h-full items-center bg-white w-full border border-borderGrey rounded-default">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ className: 'h-full w-full' }}
      />
    </div>
  );
}

LineChart.defaultProps = { realData: false };

export default LineChart;
