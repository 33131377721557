import { blueOpx, borderGrey } from '@assets/color';
import { v4 } from 'uuid';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';
import { ColorCube } from '@components/atomic/ColorCube';
import { EditIcon } from '@assets/images/svgComponents';
import { useMemo } from 'react';
import { IKPITab, IPersonKpi } from '../../types/globalTypes';
import { InfosWithIcon } from './InfosWithIcon';
import PersonKpi from './PersonKpi';

type IKpiTabProps = {
  infos: IKPITab[];
  backgroundColor?: string;
  borderColor?: string;
  personInfos?: IPersonKpi;
  addClass?: string;
};

function KpiTab({
  infos,
  backgroundColor,
  borderColor,
  personInfos,
  addClass,
}: IKpiTabProps): JSX.Element {
  const kpiNumber =
    infos.filter((info) => info.infos.length > 0).length +
    (personInfos ? 1 : 0);
  const gridColsClass = useMemo(() => {
    if (kpiNumber === 5) return 'grid-cols-5';
    return 'grid-cols-3';
  }, [infos, kpiNumber, addClass, personInfos]);

  return (
    <div
      className={`grid ${
        kpiNumber > 4 ? gridColsClass : 'grid-flow-col'
      } gap-[1rem] ${addClass}`}
    >
      {personInfos && (
        <PersonKpi
          infos={personInfos}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          addClass={addClass}
        />
      )}
      {infos.map((el: IKPITab) => (
        <div
          key={v4()}
          style={{
            backgroundColor: backgroundColor || 'white',
            borderColor: borderColor || borderGrey,
          }}
          className="relative border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem] w-full"
        >
          {el.onEdit && (
            <div className="absolute top-1 right-1 cursor-pointer">
              <ColorCube
                size="2rem"
                numberOrIcon={<EditIcon />}
                color={blueOpx}
                backgroundColor="white"
                opacity
                borderRadius="4px"
                onClick={el.onEdit}
              />
            </div>
          )}
          <InfosWithIcon
            infos={el.infos}
            colorIcon={el.colorIcon}
            icon={el.icon}
            borderRadius
            dataTestId={el.dataTestId}
            noWrap
          />
          {el.progressBar && Number(el.ratios) >= 0 && (
            <div className="mt-[0.45rem]">
              <MultipleProgressBar
                addClass="!h-[0.313rem]"
                showLegend={false}
                data={[
                  {
                    ratio: Number(el.ratios),
                    color: `${el.colorIcon}`,
                    label: ``,
                  },
                ]}
                loading={false}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export { KpiTab };

KpiTab.defaultProps = {
  backgroundColor: '',
  borderColor: '',
  personInfos: undefined,
  addClass: '',
  // progressBar: false,
};
