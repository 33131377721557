import { Loader } from '@components/atomic/Loader';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { getMessagesRequest } from '@models/requests/apiRequest/requestRequests';
import { useContext, useEffect } from 'react';
import { AuthContext } from '@context/authContext';
import { OneMessageDetailsRequest } from './OneMessageDetailsRequest';

function DetailRequest() {
  const {
    updateMessagesRequest,
    detailRequest,
    messagesRequest,
    isLoadingMessagesRequest,
    updateIsLoadingMessagesRequest,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    updateIsLoadingMessagesRequest(true);
    getMessagesRequest(
      updateMessagesRequest,
      updateIsLoadingMessagesRequest,
      detailRequest?.id || 0
    );
  }, []);

  return (
    <div className="p-[1rem] flex flex-col space-y-[.5rem]">
      {!isLoadingMessagesRequest ? (
        messagesRequest?.map((item) => {
          const isUserMessage = item.from_user.id === user?.id;
          return (
            <div
              key={item.id}
              style={{
                paddingRight: !isUserMessage ? '2rem' : '',
                paddingLeft: isUserMessage ? '2rem' : '',
              }}
            >
              <OneMessageDetailsRequest
                background={isUserMessage}
                border={!isUserMessage}
                message={item.message}
                date={item.created_at}
                senderName={`${item.from_user.firstname} ${item.from_user.lastname}`}
                senderImage={item.from_user.photo_url || null}
              />
            </div>
          );
        })
      ) : (
        <Loader />
      )}
    </div>
  );
}

export { DetailRequest };
