import { toast } from 'react-toastify';
import { t } from 'i18next';
import { displayError } from '@utils/format';
import {
  urlGetInvitationInfo,
  urlPostRegisteredEmail,
  urlPostSiretInfo,
  urlSetPartnership,
  urlValidateInvitation,
  urlPostRegistrationConfirm,
  urlPostCompleteCollabDetails,
  urlPutRegistrationCreate,
  urlPostInvitationAccountCreated,
  urlPostRepresentativesInfo,
} from '@api/apiUrls';
import { FieldValues } from 'react-hook-form';
import { IRepresentativesType } from '@models/auth/utils/types';
import { axiosClient } from '@api/apiClient';

export const getSiretInfo = async (data: FieldValues) => {
  try {
    const response = await axiosClient.post(urlPostSiretInfo, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 500) toast.error(t('global.error'));
    else displayError(error.response);
    return false;
  }
};

export const getRepresentativesInfo = async (data: FieldValues) => {
  try {
    return await axiosClient.post(urlPostRepresentativesInfo, data);
  } catch (error) {
    return false;
  }
};

export const checkEmail = async (data: { email: string | null }) => {
  try {
    const response = await axiosClient.post(urlPostRegisteredEmail, data);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const createAccount = async (payload: {
  entity_data: any;
  lastname: string | null;
  firstname: string | null;
  email: string | null;
  phone_number: string | null;
  password: string | null;
  function: string | null;
  profils: number[];
  user_status?: number;
  subscription_payment_frequency?: string;
  representatives: IRepresentativesType;
}) => {
  try {
    const response = await axiosClient.put(urlPutRegistrationCreate, payload);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const invitationInfo = async (id: string) => {
  try {
    const response = await axiosClient.get(urlGetInvitationInfo(id));
    return response.data;
  } catch (error) {
    // displayError(error.response);
    return false;
  }
};

export const setPartnership = async (id: string) => {
  try {
    const response = await axiosClient.put(urlSetPartnership(id));
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const validateInvitation = async (id: string) => {
  try {
    const response = await axiosClient.put(urlValidateInvitation(id));
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const invitationAccountCreated = async (payload: {
  id: number | null;
}) => {
  try {
    const response = await axiosClient.put(
      urlPostInvitationAccountCreated,
      payload
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const registrationConfirm = async (payload: { id: number | null }) => {
  try {
    const response = await axiosClient.post(
      urlPostRegistrationConfirm,
      payload
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const completeCollabDetails = async (payload: {
  uuid: string | null;
  lastname: string | null;
  firstname: string | null;
  phone_number: string | null;
  password: string | null;
  function: string | null;
}) => {
  try {
    const response = await axiosClient.post(
      urlPostCompleteCollabDetails,
      payload
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};
