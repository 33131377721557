import { Dispatch, SetStateAction } from 'react';
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { CrossIcon } from '@assets/images/svgComponents';
import { red } from '@assets/color';

interface IModalDocumentAnalysisProps {
  onConfirmClick: () => void;
  setModal: Dispatch<SetStateAction<boolean>>;
}

function ModalDocumentFailSignature({
  onConfirmClick,
  setModal,
}: IModalDocumentAnalysisProps) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('worksites.documents.failed_signature.modal_title')}
      backgroundTransparent
      sidebarVisible
      setIsModal={setModal}
    >
      <div className="mt-2 flex flex-col gap-3">
        <div className="mt-2" style={{ display: 'inline-flex' }}>
          <CrossIcon fill={red} width="2.5rem" height="2.5rem" />
          <p className="mt-2">
            {t('worksites.documents.failed_signature.modal_text')}
          </p>
        </div>
        <div className="pt-[0.75rem] flex items-center justify-end space-x-[1rem]">
          <div>
            <ButtonOpx
              label={`${t('buttons.cancel')}`}
              type="secondary"
              onClick={() => setModal(false)}
              addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
            />
          </div>
          <div>
            <ButtonOpx
              label={`${t('global.document_confirm')}`}
              type="primary"
              onClick={onConfirmClick}
              addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export { ModalDocumentFailSignature };
