/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '@context/globalContext';

import { Tag } from '@components/atomic/Tag';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';

import { blueSecondary, orange, purple, textGrey } from '@assets/color';
import { convertKiloToGiga } from '@utils/functions';
import {
  dateToDDMMYYY,
  formatToSingular,
  numberWithSeparator,
} from '@utils/format';

interface IDepositsCardProps {
  data: any;
  clickable?: boolean;
  handleClick?: () => void;
  showExternal?: boolean;
  showRai?: boolean;
  showDeposant?: boolean;
  showNumDd?: boolean;
  showEmmyRef?: boolean;
}

function DepositCard(props: IDepositsCardProps) {
  const {
    data,
    showRai,
    showDeposant,
    showNumDd,
    showEmmyRef,
    showExternal,
    clickable,
    handleClick,
  } = props;
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);

  const vClassique = convertKiloToGiga(data.volume.classique);
  const vPrecaire = convertKiloToGiga(data.volume.precaire);

  const vTotal = convertKiloToGiga(data.volume.total);

  const ratioClassic =
    (Number(data.volume.classique) * 100) / Number(data.volume.total);
  const ratioPrecaire =
    (Number(data.volume.precaire) * 100) / Number(data.volume.total);

  const textRai = `RAI: ${data.rai.company_name}`;
  const textDeposant = data.creator
    ? `${t('deposits.deposant')}: ${data.creator.company_name}`
    : '';

  return (
    <div
      className={`w-full bg-white border border-borderGrey rounded-default flex items-center justify-center flex-wrap my-4 ${
        clickable ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        if (handleClick) handleClick();
        return false;
      }}
    >
      <div className="w-full md:w-[30%] md:border-r md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex flex-wrap items-center justify-start mb-[0.875rem]">
          {showExternal && data.is_external && (
            <Tag
              label={t('deposits.external')}
              color={orange}
              addClass="border border-borderOrange mr-3"
            />
          )}
          <span className="text-[1.25rem] font-medium mr-3">
            {t('deposits.internal_reference')}
          </span>
          <Tag
            label={data.reference}
            color={textGrey}
            addClass="border border-borderGrey"
          />
        </div>
        {showNumDd && (
          <div className="flex flex-wrap items-center justify-between">
            <p className="w-full md:w-[50%] text-textGrey text-[0.625rem]">{`${t(
              'contractMandant.num_dd_classique'
            )}: ${data.num_dd_classique || '-'}`}</p>
            <p className="w-full md:w-[50%] text-textGrey text-[0.625rem] md:text-right">{`${t(
              'contractMandant.num_dd_precaire'
            )}: ${data.num_dd_precaire || '-'}`}</p>
          </div>
        )}
        {showEmmyRef && (
          <div className="flex flex-wrap items-center justify-between">
            <p className="w-full md:w-[50%] text-textGrey text-[0.625rem]">{`${t(
              'deposits.emmy_ref'
            )}: ${data.emmy_reference || '-'}`}</p>
          </div>
        )}
      </div>
      <div className="w-full md:w-[70%] border-t md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex justify-end items-center">
          {showDeposant && (
            <span className="mr-3 text-[0.75rem]">{textDeposant}</span>
          )}
          {showRai && <span className="mr-3 text-[0.75rem]">{textRai}</span>}
          <span className="text-[1rem] font-medium">{`${
            data.count_operations
          } ${t(
            data.count_operations > 1
              ? 'partners.deposits.operations'
              : 'partners.deposits.operation'
          )}`}</span>
        </div>
        <div className="my-[1rem]">
          <MultipleProgressBar
            addClass="!h-[0.313rem]"
            showLegend
            data={[
              {
                ratio: ratioClassic / 100,
                color: blueSecondary,
                label: `${t(
                  'partners.deposits.volume_classic'
                )} ${vClassique} GWhc`,
              },
              {
                ratio: ratioPrecaire / 100,
                color: purple,
                label: `${t(
                  'partners.deposits.volume_precarity'
                )} ${vPrecaire} GWhc`,
              },
            ]}
          />
        </div>
      </div>
      <div className="w-full p-[1rem] border-t border-borderGrey flex flex-justify-between flex-wrap">
        <div className="w-full md:w-[70%]">
          <div className="flex items-center">
            <Tag
              label={formatToSingular(
                (globalEnum.deposit_status as any)[String(data.status)]
              )}
              color={blueSecondary}
              addClass="mr-3"
            />
            <span className="mr-3 text-[0.875rem]">{`${t(
              'partners.deposits.last_update'
            )}: ${dateToDDMMYYY(data.updated_at.split(' ')[0])}`}</span>
            <span className="mr-3 text-[0.875rem]">{`${t(
              'partners.deposits.creation_date'
            )}: ${dateToDDMMYYY(data.created_at.split(' ')[0])}`}</span>
          </div>
        </div>
        <div className="w-full md:w-[30%]">
          <div className="flex justify-start md:justify-end">
            <span className="mr-3 text-[0.875rem]">{`${t(
              'partners.deposits.total_prime'
            )}: ${numberWithSeparator(String(data.prime_total))} €`}</span>

            <span className="mr-3 text-[0.875rem]">{`${t(
              'partners.deposits.total_volume'
            )}: ${vTotal} Gwhc`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

DepositCard.defaultProps = {
  clickable: undefined,
  handleClick: undefined,
  showExternal: undefined,
  showRai: true,
  showDeposant: undefined,
  showNumDd: true,
  showEmmyRef: undefined,
};

export { DepositCard };
