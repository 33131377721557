import { useEffect, useState, useMemo, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import { HeaderContext } from '@context/headerContext';

import { ListTable, IColumn, IRow } from '@components/ListTable';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getPartnerSubcontractors } from '@models/partners/apiRequests/partnersFormRequest';

import { IPartnerSubcontractor } from '@models/partners/utils/types/partnerSubcontractorType';
import { SUBCONTRACTOR_ROUTES_WITH_PARAMS } from '@utils/routesUrls';

function SubcontractorList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { updateDisplayBackButtonHeader, refreshHeader } =
    useContext(HeaderContext);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const getData = async (pageParam: number) => {
    setLoading(true);
    const resList = await getPartnerSubcontractors({
      page: pageParam,
      perPage: 5,
      entityId: Number(id),
    });
    if (resList) {
      setData(resList.data || []);

      setPaginationData(resList.meta);

      if (resList.meta.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          { data: resList.data, meta: resList.meta },
        ]);
      }
    }
    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const columnHelper = createColumnHelper<IPartnerSubcontractor>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('company_name', {
        header: () => t('partners.company_name'),
      }),
      columnHelper.accessor('siret', {
        header: () => t('partners.siret'),
      }),
      columnHelper.accessor('siren', {
        header: () => t('partners.siren'),
      }),
    ],
    [data]
  );

  useEffect(() => {
    refreshHeader();
    getData(1);
  }, []);

  return (
    <div className="w-full items-center justify-center py-5">
      <ListTable
        loading={loading}
        columns={columns as IColumn[]}
        data={data}
        hideArrow
        callBackOnRowClick={(e: IRow) => {
          const rowData = e.original as IPartnerSubcontractor;
          navigate(
            `${
              SUBCONTRACTOR_ROUTES_WITH_PARAMS(Number(id), rowData.id)
                .INSTALLER_SUBCONTRACTOR_VIEW
            }#subcontractors`
          );
          updateDisplayBackButtonHeader(true);
        }}
      />
      {paginationData && Number(paginationData.last_page) > 1 && (
        <ListPagination
          getData={(page) => {
            if (page > pagesDatas.length) {
              getData(page);
            } else {
              pageAlreadyLoad(page);
            }
          }}
          paginationData={paginationData}
        />
      )}
    </div>
  );
}

export { SubcontractorList };
