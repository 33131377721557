export const VISUALISER = {
  CONVENTION: 'visualiser-convention',
  CONTRACT_MANDANT: 'visualiser-contrat-mandant',
  CONTRACT_VENTE: 'visualiser-contrat-vente',
};

export const GERER = {
  CONTRACT_VENTE: 'gerer-contrat-vente',
  CONVENTION: 'gerer-convention',
  CONTRACT: 'gerer-contrat',
  CONTRACT_MANDANT: 'gerer-contrat-mandant',
  GERER_SOUSTRAITANT: 'gerer-soustraitant',
};

export const LISTER = {
  CONVENTION: 'lister-convention',
  CONTRACT: 'lister-contrat',
};

export const TAB_TYPES = {
  CONVENTIONS: 'Conventions',
  MANDANT: 'Mandant',
  MANDANT_NON_DEPOSANT: 'Mandant non-déposant',
  DELEGATION: 'Vente',
  SALE: 'Délégation',
};

export const URL_TYPES = {
  CONVENTION: 'convention/installateur',
  TRIPARTITE: 'convention/beneficiaire',
  MANDANT: 'mandant-deposant',
  MANDANT_NON_DEPOSANT: 'mandant-non-deposant',
  DELEGATION: 'delegation',
  SALE: 'vente',
};
