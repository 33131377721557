import { blueOpx, blueSecondary, purple } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { Tag } from '@components/atomic/Tag';
import { IQuotaPrecarityValues } from '@models/dashboard/utils/dashboardTypes';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IBarItem } from '@models/partners/utils/types/partnersType';

interface OneLineQuotaListProps {
  label: string;
  numberWorksite: number;
  expirationDate: string;
  values: IQuotaPrecarityValues;
  animationDelay: number;
  isFirst: boolean;
}
function OneLineQuotaList({
  label,
  numberWorksite,
  expirationDate,
  values,
  animationDelay,
  isFirst,
}: OneLineQuotaListProps) {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const slideInStyle = isVisible
    ? {
        transform: 'translateY(0)',
        opacity: 1,
        transition: 'transform 0.75s ease-in-out, opacity 0.75s ease-in-out',
      }
    : { transform: 'translateY(100%)', opacity: 0 };

  const totalGWhc = `${(
    values.classic.value + values.precarious.value
  ).toLocaleString()} GWhc`;

  const numberWorksiteText = `${numberWorksite} ${t('dashboard.worksite')}${
    numberWorksite > 1 ? 's' : ''
  }`;

  const handleContractClick = () => {
    console.log(`Contract ${label} clicked`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, animationDelay);
    return () => clearTimeout(timer);
  }, [animationDelay]);

  return (
    <div
      style={slideInStyle}
      className={`grid grid-cols-3 items-center gap-4 p-4 ${
        isFirst ? '' : 'border-t'
      } border-borderGrey`}
    >
      {/* Colonne 1: Label et Informations */}
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium">{label}</div>
        <div className="text-xs text-textGrey">{numberWorksiteText}</div>
        <div className="text-xs text-textGrey">
          ({t('dashboard.expiration')} {expirationDate})
        </div>
      </div>

      {/* Colonne 2: Tags et Barres de Progression */}
      <div className="flex flex-col justify-around space-y-4">
        {Object.keys(values).map((valueKey: any) => {
          const isClassic = valueKey === 'classic';
          const colorToUse = isClassic ? blueSecondary : purple;
          const key = `${label}_${valueKey}`;
          const precarityValues = values[valueKey];
          const tabData: IBarItem[] = [
            {
              color: colorToUse,
              ratio: precarityValues.value / precarityValues.max,
            },
          ];
          return (
            <div
              key={key}
              className="flex flex-col items-center justify-center gap-2"
            >
              <Tag
                color={colorToUse}
                label={`${
                  isClassic ? 'C' : 'P'
                } ${precarityValues.value.toLocaleString()} GWhc`}
              />
              <MultipleProgressBar data={tabData} showLegend={false} />
            </div>
          );
        })}
      </div>

      {/* Colonne 3: Total GWhc et Icône */}
      <div className="flex justify-end items-center gap-4">
        <div>{totalGWhc}</div>
        <div className="rotate-180">
          <ColorCube
            size="1.5rem"
            numberOrIcon={<ChevronLeftIcon />}
            color={blueOpx}
            opacity
            onClick={handleContractClick}
          />
        </div>
      </div>
    </div>
  );
}

export default OneLineQuotaList;
