/* eslint-disable no-nested-ternary */
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { SummaryPartRight } from './containerRight/SummaryPartRight';
import { SizingNoteViewer } from './containerRight/SizingNoteViewer';

function ContainerRightScreen() {
  const { stepActiveWorksiteCreation, payloadSizingNote, sizingNote } =
    useContext(WorksiteCreationContext);

  const stepSizingNoteGenerate =
    stepActiveWorksiteCreation ===
      StepsWorksiteCreationEnum.WORKSITE_CREATION_ND &&
    payloadSizingNote.generate === true;

  return (
    <div>
      {!stepSizingNoteGenerate ||
      stepActiveWorksiteCreation !==
        StepsWorksiteCreationEnum.WORKSITE_CREATION_ND ||
      stepActiveWorksiteCreation ? (
        <div
          className={`pb-5 max-h-[calc(100vh_-_9.625rem)] h-full border border-borderGrey bg-white ${
            stepActiveWorksiteCreation !== StepsWorksiteCreationEnum.DOCUMENTS
              ? 'w-[38rem]'
              : 'w-[28rem]'
          } rounded-default bg-white block overflow-hidden`}
        >
          <SummaryPartRight />
        </div>
      ) : (
        <div
          style={{
            minWidth: sizingNote !== '' ? '38rem' : '0rem',
            width: sizingNote !== '' ? '38rem' : '0rem',
            transition: 'all 0.5s ease',
          }}
          className={`pb-5 overflow-hidden h-full ${
            sizingNote !== '' ? 'border border-borderGrey' : ''
          } bg-white min-w-[38rem] rounded-default bg-white block`}
        >
          <SizingNoteViewer />
        </div>
      )}
    </div>
  );
}

export { ContainerRightScreen };
