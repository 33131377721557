/* eslint-disable no-nested-ternary */
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { RequestsContext } from '@models/requests/utils/requestsContext';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IListGroupRequests } from '@models/requests/types/requestTypes';
import { filterRequestsByRelationType } from '@models/requests/utils/functions';
import { HeaderKanban } from './HeaderKanban';
import { ListRequestsKanban } from './ListRequestsKanban';

function KanbanRequests() {
  const { t } = useTranslation();
  const { listRequestsGroupAll, refreshListGroupRequests, filterDataRequests } =
    useContext(RequestAndActivityIntoModalOrCardContext);
  const { elementActiveAllRequestWorksiteOrPartner } =
    useContext(RequestsContext);

  const listRequestsGroupAllWorkiste: IListGroupRequests = {
    todo: listRequestsGroupAll.todo
      ? filterRequestsByRelationType(listRequestsGroupAll.todo, 'worksite')
      : undefined,
    waiting: listRequestsGroupAll.waiting
      ? filterRequestsByRelationType(listRequestsGroupAll.waiting, 'worksite')
      : undefined,
  };

  const listRequestsGroupAllNote: IListGroupRequests = {
    todo: listRequestsGroupAll.todo
      ? filterRequestsByRelationType(listRequestsGroupAll.todo, 'note')
      : undefined,
  };
  const listRequestsGroupAllPartners: IListGroupRequests = {
    todo: listRequestsGroupAll.todo
      ? filterRequestsByRelationType(listRequestsGroupAll.todo, 'partner')
      : undefined,
    waiting: listRequestsGroupAll.waiting
      ? filterRequestsByRelationType(listRequestsGroupAll.waiting, 'partner')
      : undefined,
  };

  const listRequestsFiltered = useMemo(() => {
    if (
      elementActiveAllRequestWorksiteOrPartner ===
      t('requests_and_changes.all_requests')
    )
      return listRequestsGroupAll;
    if (
      elementActiveAllRequestWorksiteOrPartner ===
      t('requests_and_changes.worksites')
    )
      return listRequestsGroupAllWorkiste;
    if (elementActiveAllRequestWorksiteOrPartner === 'note')
      return listRequestsGroupAllNote;

    return listRequestsGroupAllPartners;
  }, [listRequestsGroupAll, elementActiveAllRequestWorksiteOrPartner]);

  useEffect(() => {
    refreshListGroupRequests();
  }, [filterDataRequests]);

  return (
    <div className="flex w-full justify-between space-x-[1.5rem]">
      <div className="w-full">
        <HeaderKanban
          numberRequests={
            listRequestsFiltered.todo !== undefined
              ? listRequestsFiltered.todo.length
              : 0
          }
          titleKanban={t('requests_and_changes.treat')}
        />
        {listRequestsFiltered.todo !== undefined && (
          <ListRequestsKanban requests={listRequestsFiltered.todo} />
        )}
      </div>
      <div className="w-full">
        <HeaderKanban
          numberRequests={
            listRequestsFiltered.waiting !== undefined
              ? listRequestsFiltered.waiting.length
              : 0
          }
          titleKanban={t('requests_and_changes.on_hold')}
        />
        {listRequestsFiltered.waiting !== undefined && (
          <ListRequestsKanban requests={listRequestsFiltered.waiting} />
        )}
      </div>
    </div>
  );
}

export { KanbanRequests };
