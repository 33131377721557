/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';

import { CardUserInfos } from '@models/users/components/CardUserInfos';

import { IAccountInfos, IAccountUser } from '@models/users/utils/userTypes';
import { CardUserRights } from './CardUserRights';

interface ICardUserDetailsProps {
  data: IAccountUser | undefined;
  refetch: CallableFunction;
  loading: boolean;
  selectedIndex: number | undefined;
  currentPage: number;
  infosConnectedUser: IAccountInfos;
}

function CardUserDetails({
  data,
  refetch,
  loading,
  selectedIndex,
  currentPage,
  infosConnectedUser,
}: ICardUserDetailsProps) {
  const isAdmin = useMemo(() => {
    return infosConnectedUser.informations_utilisateur.profile_type === 2;
  }, [infosConnectedUser]);

  const reloadData = () => {
    refetch(currentPage, selectedIndex);
  };

  return data ? (
    <div>
      <CardUserInfos
        data={data}
        loading={loading}
        isAdmin={isAdmin}
        refetch={reloadData}
      />
      <CardUserRights data={data} isAdmin={isAdmin} refetch={reloadData} />
    </div>
  ) : null;
}

export { CardUserDetails };
