import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';
import { v4 } from 'uuid';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';

import { getDeposits } from '@models/lots/apiRequests/lotRequests';
import { DepositCard } from '@models/deposits/components/DepositCard';

import { DepositCardSkeleton } from '@models/deposits/components/DepositCardSkeleton';
import { LinePlot } from './LinePlot';

interface ITabDepositsProps {
  contractId: number;
}

function TabDeposits(props: ITabDepositsProps) {
  const { contractId } = props;
  const { t } = useTranslation();

  const thisYear = getYear(new Date());
  const lastYear = thisYear - 1;

  const [activeYear, setActiveYear] = useState(thisYear);
  const [items, setItems] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const getData = async () => {
    setIsloading(true);
    const res = await getDeposits(contractId);
    setItems(res);
    setIsloading(false);
  };

  useMemo(() => {
    if (contractId) getData();
  }, [contractId]);

  return (
    <div>
      <Card
        title={`${t('contractMandant.volume_deposited')}`}
        actionButtons={
          <>
            {[lastYear, thisYear].map((year) => (
              <ButtonOpx
                key={v4()}
                type="secondary"
                label={`${year}`}
                onClick={() => setActiveYear(year)}
                active={year === activeYear}
              />
            ))}
          </>
        }
        addClass="mb-6"
        dataTestId="deposit_card"
      >
        <LinePlot
          data={items}
          activeYear={activeYear}
          xAxisReference="volume"
        />
      </Card>

      {isLoading && <DepositCardSkeleton />}
      {!isLoading &&
        items &&
        items.length > 0 &&
        items.map((item) => (
          <div key={v4()}>
            <DepositCard data={item} />
          </div>
        ))}
      {!isLoading && items.length === 0 && (
        <p className="text-textGrey">{t('contract.association.no_items')}</p>
      )}
    </div>
  );
}

export { TabDeposits };
