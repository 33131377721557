import { CardChoicePartnerInModal } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/CardChoicePartnerInModal';

function CardChoicePartnerInModalWorksiteCreationDesign() {
  return (
    <div className="w-11/12 flex flex-col space-y-[.5rem] mb-6">
      <div className="mb-4">CARD CHOIX PARTENAIRE CREATION DE CHANTIER</div>
      <CardChoicePartnerInModal
        infos="Lors de la création ou de la simulation d’un nouveau chantier, vous pouvez l’associer directement à un bénéficiaire afin d’enregistrer et de retrouver votre chantier dans la page bénéficiaire. Lors de la création ou de la simulation d’un nouveau chantier, vous pouvez l’associer directement à un bénéficiaire afin d’enregistrer et de retrouver votre chantier dans la page bénéficiaire."
        choice="Associer à un bénéficiaire existants"
      />
      <CardChoicePartnerInModal
        infos="Lors de la création ou de la simulation d’un nouveau chantier, vous pouvez l’associer directement à un bénéficiaire afin d’enregistrer et de retrouver votre chantier dans la page bénéficiaire."
        choice="Créer un bénéficiaire"
      />
      <CardChoicePartnerInModal
        infos="Vous pouvez également faire une simulation sans bénéficiaire en revanche il ne vous sera pas possible de la conserver une fois la simulation terminée"
        choice="Simulation sans bénéficiaire"
      />
    </div>
  );
}

export { CardChoicePartnerInModalWorksiteCreationDesign };
