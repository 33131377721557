import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Card } from '@components/Card';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { storeCustomFields } from '@models/worksites/apiRequests/worksitesRequests';
import ActionButtons from '@models/worksites/components/worksiteDetails/worksiteInformations/ActionButtons';
import { fileTypeEnum } from '@utils/enums';
import { IInputType } from '../../../../../types/globalTypes';

interface ICardInformationProps {
  refresh: () => Promise<void>;
  customFields: ICustomField[] | undefined;
  worksiteId: number;
}

interface ICustomField {
  id: number;
  internal_name: string;
  public_name: string;
  type: number;
  value: string;
  created_at: string;
  default_value: string;
  deleted_at: string;
  entity_id: number;
  is_active: boolean;
  is_public: boolean;
  is_required: boolean;
  pivot: any;
  placeholder: string;
  updated_at: string;
}

function CardWorksiteCustomFields({
  refresh,
  customFields,
  worksiteId,
}: ICardInformationProps) {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const methods = useForm();
  const { handleSubmit, getValues } = methods;

  const onSubmit = async () => {
    setIsLoading(true);
    const values = getValues();
    const customFieldData = Object.entries(values).map(([id, value]) => ({
      id: parseInt(id, 10),
      value,
    }));
    await storeCustomFields(worksiteId, { customFields: customFieldData });
    setIsLoading(false);
    refresh();
    setIsEditMode(false);
  };

  return (
    <Card
      title={t('worksites.custom_fields.title')}
      addClass="h-[max-content] w-full"
      actionButtons={
        <ActionButtons
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          onSubmit={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      }
      dataTestId="card_worksite_custom_fields"
    >
      <FormProvider {...methods}>
        <form id="form-card-custom-fields" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-2 grid-cols-2">
            {customFields?.map((customField, index) => {
              if (
                customField &&
                customField.type !== fileTypeEnum.CUSTOM_FIELD
              ) {
                const typedTypeName = customField.type as IInputType;
                return (
                  <div
                    key={`cf_${customField.id}_${customField.internal_name}`}
                  >
                    <DynamicInputEdit
                      key={`${customField.id}_${customField.type}`}
                      inputType={typedTypeName}
                      name={`${customField.id}`}
                      label={
                        customField.public_name || customField.internal_name
                      }
                      initialValue={
                        customField.value || customField.default_value || ''
                      }
                      isEditMode={isEditMode}
                      addClass={`ml-12 ${index > 1 ? 'mt-4' : ''}`}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </form>
      </FormProvider>
    </Card>
  );
}

export { CardWorksiteCustomFields };
