import { useContext } from 'react';
import { TextError } from '@components/TextError';
import { OperationTypeEnum } from '@utils/enums';
import { HouseholdSummary } from '@models/worksiteCreation/components/simulation/summary/HouseholdSummary';
import { InfoGeneralSummary } from './InfoGeneralSummary';
import { LocationWorksiteSummary } from './LocationWorksiteSummary';
import { OperationSummary } from './OperationSummary';
import { PrimesSummary } from './PrimesSummary';
import { WorksiteCreationContext } from '../../../utils/worksiteCreationContext';
import { BeneficiaryInfoSummary } from './BeneficiaryInfoSummary';

function Summary() {
  const { beneficiary, worksiteOperationType, errorValidateSimulation } =
    useContext(WorksiteCreationContext);

  return (
    <>
      <div className="flex space-x-[1.5rem] w-full">
        <div className="w-full">
          <InfoGeneralSummary />
          <OperationSummary />
          {worksiteOperationType === OperationTypeEnum.B2C && (
            <HouseholdSummary />
          )}
          {beneficiary !== null && <BeneficiaryInfoSummary />}
        </div>
        <div className="w-full">
          <PrimesSummary />
          <div className="w-full flex space-x-[1.5rem]">
            <LocationWorksiteSummary />
          </div>
        </div>
      </div>
      <TextError errorMessage={errorValidateSimulation} />
    </>
  );
}

export { Summary };
