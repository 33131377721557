import { v4 } from 'uuid';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';

function LoaderTabsLeftPart(): JSX.Element {
  return (
    <div className="flex items-center space-x-[1rem]">
      {[...Array(3)].map(() => (
        <div key={v4()} className="w-full">
          <LoaderDivSkeleton height="2.75rem" width="8.75rem" />
        </div>
      ))}
    </div>
  );
}

export { LoaderTabsLeftPart };
