import { useContext } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { Modal } from '@components/Modal';
import { CardChoicePartnerInModal } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/CardChoicePartnerInModal';
import { t } from 'i18next';
import {
  ContractTypes,
  CreationSteps,
  SaleProfiles,
} from '@models/contractCreation/utils/enums';
import { useLocation, useNavigate } from 'react-router-dom';
import { PARTNERS_ROUTES } from '@utils/routesUrls';

function SaleProfileModal() {
  const { tunnel, updateSaleProfile, changeStep, updateContractType } =
    useContext(ContractCreationContext);
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Modal
      title={tunnel?.label || ''}
      backgroundTransparent
      sidebarVisible
      withArrowBack
      onBackClick={() => {
        if (location.state) navigate(PARTNERS_ROUTES.PARTNERS);
        else changeStep(CreationSteps.TUNNEL);
      }}
    >
      <div>
        <CardChoicePartnerInModal
          choice={t('contract.sale_buyer')}
          infos=""
          addClass="w-full my-[1rem]"
          onChoice={() => {
            updateSaleProfile(SaleProfiles.BUYER);
            updateContractType(ContractTypes.DELEGATION);
            changeStep(CreationSteps.PARTNER_CHOICE);
          }}
          dataTestId="buyer"
        />
        <CardChoicePartnerInModal
          choice={t('contract.sale_seller')}
          infos=""
          addClass="w-full my-[1rem]"
          onChoice={() => {
            updateSaleProfile(SaleProfiles.SELLER);
            updateContractType(ContractTypes.VENTE);
            changeStep(CreationSteps.PARTNER_CHOICE);
          }}
          dataTestId="seller"
        />
      </div>
    </Modal>
  );
}

export { SaleProfileModal };
