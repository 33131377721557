const initialGraph = {
  name: '',
  description: '',
  type: '',
  input: '',
  key: '',
  value: '',
};

const initialSimulatorDataOperation = {
  'operation.id': null,
  'operation.totalCost': null,
};

const initialSimulatorData = {};

export { initialGraph, initialSimulatorData, initialSimulatorDataOperation };
