import { SearchBar } from '@components/SearchBar';
import { useTranslation } from 'react-i18next';

function SearchBarDesign() {
  const { t } = useTranslation();
  const onSearch = (value: string) => {
    console.log('value', value);
  };

  return (
    <div className="w-11/12 flex flex-col mb-6">
      <div className="mb-4">BARRE DE RECHERCHE</div>
      <div className="rounded-default bg-white p-3">
        <SearchBar
          placeholder={t('search_bar.search_something')}
          onSearch={(value: string) => onSearch(value)}
          searchOnEveryChange={false}
          width="40rem"
        />
      </div>
    </div>
  );
}

export { SearchBarDesign };
