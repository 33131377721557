import { blueOpx } from '@assets/color';
import { ChevronDownIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import {
  getMessagesRequest,
  getOneRequest,
} from '@models/requests/apiRequest/requestRequests';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import moment from 'moment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IInternalMessage } from 'types/changesTypes';
import { convertHexToRGBA } from '../../../../utils/functions';
import { AnswerMessageForm } from './AnswerMessageForm';

interface IOneChangeCardProps {
  icon: JSX.Element;
  color: string;
  dateChange: string;
  isDocument: boolean;
  isAccessToRequest: boolean;
  lastChange?: boolean;
  isMessage: boolean;
  internalMessages: IInternalMessage[];
  idRequest: number;
  idChange: number;
}

function OneChangeCard({
  icon,
  color,
  dateChange,
  isDocument,
  lastChange,
  isMessage,
  internalMessages,
  isAccessToRequest,
  idRequest,
  idChange,
}: IOneChangeCardProps) {
  const { t } = useTranslation();

  const {
    updateRequestOrChangeEnumInCard,
    updateMessagesRequest,
    updateIsLoadingMessagesRequest,
    updateDetailRequest,
    updateIsLoadingListRequestOrChange,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  const date = moment(dateChange, 'YYYY-MM-DD HH:mm:ss');
  const now = moment();
  const dateEvent = now.diff(date, 'days');

  const accessToRequest = async (id: number) => {
    updateIsLoadingListRequestOrChange(true);
    await getMessagesRequest(
      updateMessagesRequest,
      updateIsLoadingMessagesRequest,
      id
    );
    await getOneRequest(
      updateDetailRequest,
      updateIsLoadingListRequestOrChange,
      id
    );
    await updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.REQUEST);
  };

  return (
    <div className="w-full relative">
      <div className="flex justify-between ">
        {!lastChange && (
          <div className="absolute bg-borderGrey w-[.5px] h-[calc(100%_-_.5rem)] ml-[.8rem] mt-[1.5rem]" />
        )}
        <div style={{ zIndex: 1 }} className="flex space-x-[1rem] w-full">
          <ColorCube
            numberOrIcon={icon}
            color={color}
            backgroundColor={convertHexToRGBA(color, 0.1)}
            size="1.5rem"
            opacity
          />
          {isMessage && (
            <div className="bg-backgroundBody p-[.5rem] rounded-[4px] w-full">
              <div className="flex flex-col space-y-4">
                {internalMessages.map((message) => {
                  const dateMessage = moment(
                    message.created_at,
                    'YYYY-MM-DD HH:mm:ss'
                  );
                  moment.locale('fr');
                  return (
                    <div
                      key={message.id}
                      className="flex justify-between w-full"
                    >
                      <div className="flex space-x-[.5rem] w-full">
                        <img
                          className="h-[1.5rem] min-h-[1.5rem] w-[1.5rem] min-w-[1.5rem] bg-white rounded-[4px]"
                          src={message.from_user.photo_url}
                          alt=""
                        />
                        <div>
                          <p className="font-medium text-[.875rem]">
                            {`${message.from_user.firstname} ${message.from_user.lastname}`}
                          </p>
                          <p className="text-[.75rem] mt-[.75rem]">
                            {message.message}
                          </p>
                        </div>
                      </div>
                      <p className="text-textGrey text-[.75rem] whitespace-nowrap">
                        {dateMessage.format('DD MMM')}
                      </p>
                    </div>
                  );
                })}
                <AnswerMessageForm idChange={idChange} />
              </div>
            </div>
          )}
          <div>
            {!isMessage && (
              <p className="text-[.875rem]">{internalMessages[0].message}</p>
            )}
            {!isMessage && internalMessages[0].description !== null && (
              <p className="text-textGrey text-[.75rem]">
                {internalMessages[0].description}
              </p>
            )}

            {isDocument && (
              <button
                type="button"
                onClick={() => console.log('ouvrir le doc')}
                className="py-[1px] px-[1rem] border border-borderGrey text-textGrey text-[.875rem] mt-[.5rem]"
              >
                {t('requests_and_changes.see_document')}
              </button>
            )}
          </div>
        </div>
        {!isMessage && (
          <div className="text-textGrey text-[.75rem] pr-[1rem] whitespace-nowrap">
            {dateEvent === 0
              ? t('requests_and_changes.today')
              : `${dateEvent} ${t('requests_and_changes.day')}${
                  dateEvent === 1 ? '' : 's'
                }`}
          </div>
        )}
      </div>
      {isAccessToRequest && (
        <button
          type="button"
          onClick={() => accessToRequest(idRequest)}
          className="py-[1px] pl-[1rem] pr-[.25rem] bg-backgroundBody rounded-[4px] flex justify-between m-auto ml-[2.5rem] items-center w-[85%] text-[.875rem] mt-[.5rem] font-medium "
        >
          <p>{t('requests_and_changes.access_to_request')}</p>
          <ColorCube
            size="1rem"
            color={blueOpx}
            opacity
            backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
            numberOrIcon={<ChevronDownIcon className="rotate-[-90deg]" />}
          />
        </button>
      )}
    </div>
  );
}

export { OneChangeCard };

OneChangeCard.defaultProps = {
  lastChange: undefined,
};
