import { useTranslation } from 'react-i18next';
import { textGrey, red } from '@assets/color';
import {
  IWorksiteDetailsHeader,
  SetOperationAccessibleType,
} from '@models/worksites/utils/worksitesTypes';
import { Tag } from '@components/atomic/Tag';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { numFormatSpace } from '@utils/functions';
import { GlobalContext } from '@context/globalContext';
import { getTagValue } from '@models/deposits/utils/depositHelper';
import { getCofracTag } from '@models/cofrac/utils/utils';
import { COFRAC_STATUS } from '@models/cofrac/utils/cofracConstants';
import { dateToDDMMYYY, formatWord } from '@utils/format';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import {
  WORKSITE_OPERATION_STATUS,
  WORKSITE_STATUS,
} from '@models/worksites/utils/enums';

type IWorksiteDetailsOperationCardHeaderProps = {
  infos: IWorksiteDetailsHeader;
  detailsIsActive: boolean;
  lastUpdateDate: string;
  stepActive: number;
  setHeaderHeight: Dispatch<SetStateAction<number>>;
  setDetailsIsActive: Dispatch<SetStateAction<boolean>>;
  operationInvalidFixed: SetOperationAccessibleType | null;
  handleChangeProjectedEnd: (id: number, date: string) => void;
};

function WorksiteDetailsOperationCardHeader({
  infos,
  stepActive,
  detailsIsActive,
  lastUpdateDate,
  setHeaderHeight,
  setDetailsIsActive,
  operationInvalidFixed,
  handleChangeProjectedEnd,
}: IWorksiteDetailsOperationCardHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const cardHeader = useRef<HTMLDivElement>(null);
  const { globalEnum } = useContext(GlobalContext);
  const { worksiteDetails } = useContext(WorksitesContext);

  const resize = () => {
    if (cardHeader && cardHeader.current) {
      setHeaderHeight(cardHeader.current.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
  }, [detailsIsActive]);

  const operationStatus = useMemo(() => {
    let key = 'contract_lot_status';

    if (operationInvalidFixed) {
      if (operationInvalidFixed['cofrac_lots_operations.id'])
        key = 'cofrac_lots_operations_status';
      if (operationInvalidFixed['deposits_operations.id'])
        key = 'deposits_operations_status';
    }

    return globalEnum[key][operationInvalidFixed?.status || 0];
  }, [operationInvalidFixed]);

  const operationTag = useMemo(
    () => getTagValue(operationStatus, t, true, true),
    [operationStatus]
  );

  const cofracTag = useMemo(() => {
    let status = 0;
    if (
      operationInvalidFixed &&
      operationInvalidFixed['cofrac_lots_operations.id']
    ) {
      status = Number(operationInvalidFixed?.cofrac_lot?.status || 0);
    }
    return getCofracTag(status, true);
  }, [operationInvalidFixed]);

  const shouldShowDatePicker =
    stepActive < WORKSITE_OPERATION_STATUS.PostChecks;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="w-full py-[1.5rem]"
      ref={cardHeader}
      onClick={() => {
        if (detailsIsActive) {
          setDetailsIsActive(false);
        }
      }}
    >
      <div className="flex items-start justify-between pb-[.875rem]">
        <div className="w-[85%] flex flex-col">
          <div className="flex items-start space-x-[1rem] w-full">
            <p
              className="text-[1.25rem] leading-[2rem] font-[500] text-left"
              data-test-id="op_title"
            >
              {infos.title}
            </p>
            <div className="flex items-center space-x-[1rem] mt-[.375rem]">
              <Tag color={textGrey} label={infos.slug} dataTestId="op_slug" />
              {infos.da !== 0 && (
                <Tag
                  color={red}
                  label={`${infos.da} ${
                    infos.da && infos.da > 1
                      ? t('worksites.das')
                      : t('worksites.da')
                  }`}
                />
              )}
              {Number(operationInvalidFixed?.cofrac_lot?.status) ===
                COFRAC_STATUS.INSATISFAISANT && (
                <Tag color={cofracTag.color} label={cofracTag.text} />
              )}
              {formatWord(operationStatus).includes('insatisfaisant') && (
                <Tag
                  color={operationTag.tagColor}
                  label={operationTag.tagText}
                />
              )}
            </div>
          </div>
          {worksiteDetails.status >
            WORKSITE_STATUS.PRE_WORKSITE_VERIFICATION && (
            <div className="flex w-full items-center space-x-[1rem] mt-4">
              <p className="text-[.875rem] whitespace-nowrap">
                {t('worksites.projected_end_date')} :
              </p>
              {infos.projectedEnd ? (
                <p className="text-[.875rem] whitespace-nowrap">
                  {dateToDDMMYYY(infos.projectedEnd)}
                </p>
              ) : (
                shouldShowDatePicker && (
                  <DatePicker
                    required
                    onChangeDate={(e) => handleChangeProjectedEnd(infos.id, e)}
                    noDefaultDate
                    addClass="w-[20rem]"
                  />
                )
              )}
            </div>
          )}
        </div>
        <div className="flex items-center space-x-[1rem] mt-[.125rem]">
          {infos.haveCdp && (
            <p
              className="text-[.875rem] whitespace-nowrap"
              data-test-id="bonus_type"
            >
              Coup de pouce
            </p>
          )}
          <p
            className="text-[.875rem] whitespace-nowrap"
            data-test-id="op_kwhc"
          >
            {numFormatSpace(Number(infos.kwhc) / 1000)} MWhc
          </p>
          <p
            className="text-[1rem] font-[500] whitespace-nowrap"
            data-test-id="op_total_bonus"
          >
            {infos.totalAmount} €
          </p>
        </div>
      </div>

      {detailsIsActive && (
        <div
          className="text-right text-[.875rem] h-5"
          style={{ color: textGrey }}
          data-test-id="op_last_update_header"
        >
          {lastUpdateDate}
        </div>
      )}
      {formatWord(operationStatus).includes('insatisfaisant') && (
        <div className="text-red text-start">
          {operationInvalidFixed?.cofrac_lot
            ? t('worksites.operation.ko_message_cofrac')
            : t('worksites.operation.ko_message')}
        </div>
      )}
    </div>
  );
}

export { WorksiteDetailsOperationCardHeader };
