import { grey } from '@assets/color';

function LoaderWorksitesList() {
  return (
    <div
      className="bg-white w-full h-auto rounded-[.25rem]"
      style={{ borderColor: grey, borderWidth: '1px' }}
    >
      <div className="flex items-center">
        <div
          className="flex flex-col space-y-[1rem] w-1/3 p-[1rem]"
          style={{ borderRightColor: grey, borderRightWidth: '1px' }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-[1rem]">
              <div className="skeleton-loader-anim-darker h-[2rem] w-[10rem]" />
              <div className="skeleton-loader-anim-darker h-[1rem] w-[6rem]" />
            </div>
            <div className="skeleton-loader-anim-darker h-[1.25rem] w-[12rem]" />
          </div>
          <div className="flex items-center justify-between">
            <div className="skeleton-loader-anim-darker h-[1.25rem] w-[12rem]" />
            <div className="skeleton-loader-anim-darker h-[1.25rem] w-[5rem]" />
          </div>
        </div>
        <div className="flex flex-col space-y-[1rem] w-2/3 p-[1rem]">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-[1rem]">
              <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
              <div className="skeleton-loader-anim-darker h-[1rem] w-[6rem]" />
            </div>
            <div className="flex items-center space-x-[1.5rem]">
              <div className="skeleton-loader-anim-darker h-[.875rem] w-[4rem]" />
              <div className="skeleton-loader-anim-darker h-[1rem] w-[6rem]" />
            </div>
          </div>

          <div className="flex items-center space-x-[1rem]">
            <div className="skeleton-loader-anim-darker h-[1rem] w-[1rem]" />
            <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
            <div className="skeleton-loader-anim-darker h-[.25rem] w-full" />
          </div>
        </div>
      </div>
      <div
        style={{ borderTopColor: grey, borderTopWidth: '1px' }}
        className="p-[1rem] flex items-center justify-between"
      >
        <div className="flex items-center space-x-[1rem]">
          <div className="skeleton-loader-anim-darker h-[1rem] w-[6rem]" />
          <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
        </div>
        <div className="flex items-center space-x-[1rem]">
          <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
          <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
          <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
        </div>
      </div>
    </div>
  );
}
export { LoaderWorksitesList };
