import { TFunction } from 'i18next';
import { format, parse } from 'date-fns';
import { createColumnHelper } from '@tanstack/react-table';
import { ICertificationType } from '@models/certifications/utils/certificationsTypes';
import { InfoCircleIcon } from '@assets/images/svgComponents';

const columnHelperCertification = createColumnHelper<ICertificationType>();

export const getCertificationListColumns = (t: TFunction, globalEnum: any) => {
  return [
    columnHelperCertification.accessor('reference', {
      header: () => t('certifications.reference'),
    }),
    columnHelperCertification.accessor('type', {
      header: () => t('certifications.qualification'),
      cell: (info) => {
        return globalEnum.certification_type[info.getValue()] || '';
      },
    }),
    columnHelperCertification.accessor('name', {
      header: () => t('certifications.name'),
    }),
    columnHelperCertification.accessor('start_date', {
      header: () => t('certifications.start_date'),
      cell: (info) => {
        const dateString = parse(info.getValue(), 'yyyy-MM-dd', new Date());
        return info.getValue() ? format(dateString, 'dd/MM/yyyy') : '-';
      },
    }),
    columnHelperCertification.accessor('end_date', {
      header: () => t('certifications.end_date'),
      cell: (info) => {
        const dateString = parse(info.getValue(), 'yyyy-MM-dd', new Date());
        return info.getValue() ? format(dateString, 'dd/MM/yyyy') : '-';
      },
    }),
  ];
};

export const getCardInfosGeneralCertification = (
  t: TFunction,
  certification: ICertificationType,
  globalEnum: any
) => {
  const startDate = new Date(certification.start_date).toLocaleDateString(
    'fr-FR'
  );
  const endDate = new Date(certification.end_date).toLocaleDateString('fr-FR');

  return [
    [
      {
        name: 'reference',
        title: t('certifications.reference'),
        value: certification.reference,
        icon: <InfoCircleIcon />,
        colorIcon: '#1730BF',
      },
      {
        name: 'type',
        title: t('certifications.type'),
        value: globalEnum.certification_type[certification.type],
      },
      {
        name: 'start_date',
        title: t('certifications.start_date'),
        value: startDate,
      },
      {
        name: 'end_date',
        title: t('certifications.end_date'),
        value: endDate,
      },
    ],
  ];
};
