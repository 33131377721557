import { InputText } from '@components/atomic/inputs/InputText';
import { numberWithSeparator } from '@utils/format';
import { useContext } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { numberRegex } from '@utils/regex';
import { useTranslation } from 'react-i18next';

interface IVolumeRowProps {
  type: string;
  required: boolean;
}
function VolumeRow({ type, required }: IVolumeRowProps) {
  const { t } = useTranslation();
  const { volumeMax, volumeMin, updateVolumeMin, updateVolumeMax } = useContext(
    ContractCreationContext
  );

  // eslint-disable-next-line react/destructuring-assignment
  const [part1, part2] = type.split('_');

  const label =
    part2 === 'precaire'
      ? `${t('contract.volume_precaire_kwhc')} (GWhc)`
      : `${t('contract.volume_classique_kwhc')} (GWhc)`;
  const volume = part1 === 'min' ? volumeMin : volumeMax;
  const updateVolume = part1 === 'min' ? updateVolumeMin : updateVolumeMax;
  const key = part2 === 'precaire' ? 'precaire' : 'classic';

  return (
    <div className="flex gap-3">
      <InputText
        id={`volume_${type}`}
        name={`volume_${type}`}
        placeholder=""
        value={volume[key]}
        typeNumber
        onChange={(e) => {
          updateVolume((prevState) => ({
            ...prevState,
            [key]: String(e),
          }));
        }}
        label={label}
        valid={Number(volume[key].replace(',', '.')) > 0}
        error={!numberRegex.test(volume[key].replace(',', '.'))}
        required={required}
      />
      <InputText
        id={`volume_${type}_mwhc`}
        name={`volume_${type}_mwhc`}
        placeholder=""
        disabled
        value={
          Number(volume[key].replace(',', '.')) > 0
            ? numberWithSeparator(
                String(Number(volume[key].replace(',', '.')) * 1000)
              )
            : ''
        }
        label={`${t('contract.conversion')} MWhc`}
        readOnly
      />
      <InputText
        id={`volume_${type}_percent`}
        name={`volume_${type}_percent`}
        placeholder=""
        disabled
        value={
          Number(volume[key].replace(',', '.')) > 0
            ? `${numberWithSeparator(
                String(
                  (Number(volume[key].replace(',', '.')) /
                    (Number(volume.classic.replace(',', '.')) +
                      Number(volume.precaire.replace(',', '.')))) *
                    100
                )
              )} %`
            : ''
        }
        label={
          t('convention.tab_info_general.percent_gisement') ||
          'Pourcentage gisement'
        }
        readOnly
      />
    </div>
  );
}

export { VolumeRow };
