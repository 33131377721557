import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import { AuthContext } from '@context/authContext';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { GlobalContext } from '@context/globalContext';
import StatusDatasLine from '@models/dashboard/components/StatusDatasLine';
import LineChart from '@models/dashboard/components/LineChart';
import RequestsDashboard from '@models/dashboard/components/RequestsDashboard';

function Dashboard() {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { user } = useContext(AuthContext);

  const { updateDisplayModalUpgradeSubscription } = useContext(GlobalContext);

  const isFreemium = user?.is_freemium;

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.dashboard')}`);
    refreshHeader();
  }, []);

  const realData = useMemo(() => Boolean(user && !user.is_freemium), [user]);

  return (
    <div>
      <div
        className={`w-full flex-col items-center relative py-6 ${
          isFreemium ? 'blur-sm' : ''
        }`}
      >
        <StatusDatasLine realData={realData} />
        <div className="flex gap-6 py-6 w-full h-full">
          <div className="w-8/12">
            <LineChart realData={realData} />
          </div>
          <div className="w-4/12">
            <RequestsDashboard realData={realData} />
          </div>
        </div>
      </div>
      {isFreemium && (
        <div className="absolute inset-0 flex items-center justify-center z-20 blur-0">
          <div className="bg-white p-6 rounded border border-[#90909080] rounded-default flex flex-col items-center">
            <p className="mb-4 text-lg font-medium">
              {t('upgrade_subscription_modal.dashboard_not_accessible')}
            </p>
            <ButtonOpx
              label={t('upgrade_subscription_modal.upgrade_cta')}
              onClick={() => updateDisplayModalUpgradeSubscription(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export { Dashboard };
