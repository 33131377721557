import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Card } from '@components/Card';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';

import { IPartnerSiret } from '@models/partners/utils/types/partnersType';
import { blueOpx } from '@assets/color';

interface ICardSiretsProps {
  data: IPartnerSiret[];
  loading: boolean;
}

function CardSirets({ data, loading }: ICardSiretsProps) {
  const { t } = useTranslation();
  return (
    <Card title={t('partners.sirets')}>
      <>
        {data.map((siretItem: IPartnerSiret, iSiret: number) => (
          <div
            key={v4()}
            className={`grid grid-cols-2 border border-solid border-transparent ${
              iSiret < data.length - 1
                ? `border-b-borderGrey ${iSiret === 0 ? 'pb-4' : 'py-4'}`
                : 'pt-4'
            }`}
          >
            <InfosWithIcon
              loading={loading}
              infos={[
                {
                  title: t('partners.company_name'),
                  subtitle: siretItem.company_name || '-',
                },
                {
                  title: t('partners.siret'),
                  subtitle: siretItem.siret || '-',
                },
              ]}
              colorIcon={blueOpx}
              icon={iSiret + 1}
            />
            <div>
              <div className="w-[100px] h-[40px]" />
              <InfosWithIcon
                loading={loading}
                infos={[
                  {
                    title: t('partners.siren'),
                    subtitle: siretItem.siren || '-',
                  },
                ]}
              />
            </div>
          </div>
        ))}
      </>
    </Card>
  );
}

export { CardSirets };
