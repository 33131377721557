import { IPartnerView } from '@models/partners/utils/types/partnersType';
import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import { Tag } from '@components/atomic/Tag';
import { blueOpx, orange, purple } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { ChevronLeftIcon } from '@assets/images/svgComponents';

interface IOneEntityLineProps {
  entityView: IPartnerView;
  handleSwitchEntity: (entityId: number) => void;
}

function OneEntityLine({
  entityView,
  handleSwitchEntity,
}: IOneEntityLineProps) {
  const { t } = useTranslation();
  const { userView, globalEnum } = useContext(GlobalContext);

  const isCurrentEntity =
    userView?.vue === entityView.vue &&
    userView?.entity_type_id === entityView.entity_type_id;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={
        !isCurrentEntity
          ? () => handleSwitchEntity(entityView.entity_type_id || 0)
          : undefined
      }
      className={`text-black flex justify-between items-center border border-borderGrey p-4 w-full rounded-default ${
        !isCurrentEntity ? 'cursor-pointer' : ''
      }`}
    >
      <div className="flex space-x-2 items-center">
        <p className="font-medium">{userView?.company_name}</p>
        <Tag
          color={orange}
          label={globalEnum?.entity_type[entityView.entity_type || 0]}
        />
        {isCurrentEntity ? (
          <Tag color={purple} label={t('roles.actual_profile')} />
        ) : null}
      </div>
      {!isCurrentEntity ? (
        <ColorCube
          size="1.5rem"
          numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
          color={blueOpx}
          opacity
        />
      ) : null}
    </div>
  );
}

export default OneEntityLine;
