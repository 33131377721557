import { InputText } from '@components/atomic/inputs/InputText';
import { GlobalContext } from '@context/globalContext';
import {
  IEntityInfoType,
  IRepresentativesType,
} from '@models/auth/utils/types';
import { dateToDDMMYYY } from '@utils/format';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { actionsBottom } from '@models/auth/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getRecognisedSiretSchema } from '@utils/validationSchemas';
import { PersonalInfo } from './PersonalInfo';

interface IRecognisedSiretProps {
  infos?: IEntityInfoType;
  asNew?: boolean;
  setAsNew: Dispatch<SetStateAction<boolean>>;
  setRecognised: Dispatch<SetStateAction<boolean>>;
  setRepresentativesTimeOut: Dispatch<SetStateAction<boolean>>;
  representatives: IRepresentativesType;
  setRepresentatives: Dispatch<SetStateAction<IRepresentativesType>>;
}

function RecognisedSiret({
  infos,
  asNew,
  setAsNew,
  setRecognised,
  setRepresentativesTimeOut,
  representatives,
  setRepresentatives,
}: IRecognisedSiretProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const [showPersonalInfo, setShowPersonalInfo] = useState<boolean>(false);
  const [globalInfos, setGlobalInfos] = useState<IEntityInfoType>();

  const methods = useForm({
    resolver: yupResolver(getRecognisedSiretSchema(t)),
  });

  const { handleSubmit, setValue, watch } = methods;

  const backToRelatedContractsOrSiret = () => {
    if (
      infos &&
      infos.entity_data.intended_contracts &&
      infos.entity_data.intended_contracts?.length > 0
    ) {
      if (setAsNew) setAsNew(false);
    } else {
      setRecognised(false);
    }
  };

  const infosGeneral = () => {
    if (setAsNew) setAsNew(true);
  };

  const infosPerso = () => {
    setShowPersonalInfo(true);
    if (infos) {
      const infosToPush = {
        ...infos,
        entity_data: {
          ...infos.entity_data,
          company_name: watch('company_name'),
          siren: watch('siren'),
        },
      };
      setGlobalInfos(infosToPush);
    }
  };

  const partnerCard = (contractType: string, endDate: string) => (
    <div
      key={v4()}
      className="flex justify-between items-center border border-borderGrey mb-2 p-6 overflow-auto text-textGrey rounded-default"
    >
      <p className="text-[.875rem] font-normal">{contractType}</p>
      <p className="text-[.75rem] font-normal">{endDate}</p>
    </div>
  );

  useEffect(() => {
    if (infos && asNew) {
      setValue('siret', infos.entity_data.siret || '');
      setValue('siren', infos.entity_data.siren || '');
      setValue('company_name', infos.entity_data.company_name || '');
      setValue(
        'address',
        `${infos.entity_data.address}, ${infos.entity_data.zipcode} ${infos.entity_data.city}` ||
          ''
      );
    }
  }, [infos, setValue, asNew]);

  if (showPersonalInfo) {
    return (
      <PersonalInfo
        setShowPersonalInfo={setShowPersonalInfo}
        setAsNew={setAsNew}
        globalInfos={globalInfos}
        setRepresentativesTimeOut={setRepresentativesTimeOut}
        representatives={representatives}
        setRepresentatives={setRepresentatives}
      />
    );
  }
  if (asNew && infos) {
    const siren = watch('siren');
    const siret = watch('siret');
    const company_name = watch('company_name');
    const address = watch('address');
    const continueButtonDisabled =
      !siren || !siret || !company_name || !address;

    return (
      <FormProvider {...methods}>
        <form id="form-siret" onSubmit={handleSubmit(infosPerso)}>
          <div className="flex flex-col lg:w-[35rem]">
            <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
              {t('auth.registration.global_info.header')}
            </p>
            <p className="font-normal text-black text-[.875rem] mb-6">
              {t('auth.registration.create_account_message')}
            </p>
            <div className="company_name w-full mb-3">
              <div className="relative mb-2">
                <InputText
                  id="company_name"
                  name="company_name"
                  label={t('auth.registration.forms.company_name') || ''}
                  placeholder=""
                  required
                  data-test-id="company_name"
                />
              </div>
            </div>

            <div className="w-full" style={{ display: 'inline-flex' }}>
              <div className="w-full md:mr-[0.5rem] mb-3">
                <div className="relative mb-2">
                  <InputText
                    id="siret"
                    name="siret"
                    label={t('auth.registration.forms.siret') || ''}
                    placeholder=""
                    required
                    readOnly
                    defaultValue={infos.entity_data.siret || ''}
                    data-test-id="siret"
                    addClassToInput="text-textGrey"
                  />
                </div>
              </div>
              <div className="w-full mb-3">
                <div className="relative mb-2">
                  <InputText
                    id="siren"
                    name="siren"
                    typeNumber
                    maxLength={9}
                    label={t('auth.registration.forms.siren') || ''}
                    placeholder=""
                    required
                    data-test-id="siren"
                  />
                </div>
              </div>
            </div>

            <div className="w-full mb-3">
              <div className="relative mb-2">
                <InputText
                  id="address"
                  name="address"
                  label={t('auth.registration.forms.address') || ''}
                  placeholder=""
                  required
                  data-test-id="address"
                />
              </div>
            </div>
            {actionsBottom(
              t,
              () => backToRelatedContractsOrSiret(),
              undefined,
              'form-siret',
              undefined,
              continueButtonDisabled
            )}
          </div>
        </form>
      </FormProvider>
    );
  }
  return (
    <div>
      <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
        {t('auth.registration.recognised.header')}
      </p>
      <div className="overflow-y-auto scroll-invisible max-h-[20rem]">
        {infos &&
          infos.entity_data.intended_contracts?.map(
            (intended_contracts) =>
              intended_contracts &&
              partnerCard(
                globalEnum.contract_type[
                  Number(intended_contracts.contract_type)
                ],
                dateToDDMMYYY(intended_contracts.end_date)
              )
          )}
      </div>
      {actionsBottom(
        t,
        () => setRecognised(false),
        () => infosGeneral()
      )}
    </div>
  );
}

export { RecognisedSiret };
RecognisedSiret.defaultProps = {
  infos: undefined,
  asNew: false,
};
