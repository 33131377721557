import { IColumn, ListTable } from '@components/ListTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getUserActionsHistory } from '@models/admin/users/apiRequests/usersRequests';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IUserRevision } from '@models/admin/users/utils/usersType';
import { ListPagination } from '@components/atomic/pagination/ListPagination';

interface IHistoryUsersProps {
  id: number;
}

const PER_PAGE = 10; // Constante pour la pagination
function HistoryUsers({ id }: IHistoryUsersProps) {
  const { t } = useTranslation();

  const [data, setData] = useState<IUserRevision[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<{
    data: any;
    meta: any;
  }>();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const columnHelper = createColumnHelper<IUserRevision>();

  // Séparation de la logique de pagination dans une fonction useCallback pour éviter les recalculs inutiles
  const fetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const resList = await getUserActionsHistory(id, {
          page,
          perPage: PER_PAGE,
        });
        if (resList) {
          setData(resList.data);
          setPaginationData(resList);
          if (resList.meta.current_page > pagesDatas.length) {
            setPagesDatas((prev) => [
              ...prev,
              { data: resList.data, meta: resList.meta },
            ]);
          }
        }
      } catch (error) {
        console.error('Error fetching user actions history:', error);
      }
      setLoading(false);
    },
    [id]
  );

  // Fonction pour charger une page déjà récupérée
  const loadPage = useCallback(
    (page: number) => {
      if (paginationData) {
        const pageData = pagesDatas.find(
          (p: any) => p.meta.current_page === page
        );
        if (pageData) {
          setData(pageData.data);
          setPaginationData(pageData);
        } else {
          fetchData(page);
        }
      }
    },
    [paginationData, fetchData]
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('date', {
        header: () => t('admin.history.date'),
      }),
      columnHelper.accessor('model', {
        header: () => t('admin.history.model'),
      }),
      columnHelper.accessor('concerned', {
        header: () => t('admin.history.concerned'),
      }),
      columnHelper.accessor('field', {
        header: () => t('admin.history.field'),
      }),
      columnHelper.accessor('oldValue', {
        header: () => t('admin.history.old_value'),
      }),
      columnHelper.accessor('newValue', {
        header: () => t('admin.history.new_value'),
      }),
    ];
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="pb-6">
      <ListTable
        data={data}
        columns={columns as IColumn[]}
        loading={loading}
        hideArrow
        rowsPerPage={PER_PAGE}
      />
      {paginationData && Number(paginationData.meta.last_page) > 1 && (
        <ListPagination
          getData={loadPage}
          paginationData={paginationData.meta}
        />
      )}
    </div>
  );
}

export { HistoryUsers };
