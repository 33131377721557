import { ChevronDownIcon } from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ColorCube } from '../ColorCube';

interface IResultsPerPageButtonProps {
  resultsPerPage: number;
  options: number[];
  colorPagination: string;
  onChange: (value: number) => void;
}

function ResultsPerPageButton({
  colorPagination,
  resultsPerPage,
  options,
  onChange,
}: IResultsPerPageButtonProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: number) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="flex justify-center items-center space-x-2">
      <p>{t('pagination.results_per_page')}</p>
      <div className="w-[6.5rem] relative border border-borderGrey p-[.5rem] pl-0 rounded-default flex items-center justify-center">
        <div className="flex justify-between items-center w-full">
          <p className="w-full text-center">{resultsPerPage}</p>
          <ColorCube
            color={colorPagination}
            numberOrIcon={<ChevronDownIcon />}
            size="2rem"
            onClick={() => setIsOpen(!isOpen)}
            opacity
            dataTestId="results_per_page_toggle"
          />
        </div>
        {isOpen && (
          <div className="w-[6.5rem] absolute top-full mt-2 border border-borderGrey rounded-default z-10">
            {options.map((option) => (
              <button
                type="button"
                key={option}
                className={`w-full cursor-pointer px-4 py-2 hover:bg-gray-200 ${
                  resultsPerPage === option
                    ? 'bg-blueOpx text-white'
                    : 'bg-white text-black'
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export { ResultsPerPageButton };
