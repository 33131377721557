import {
  IEntityInfoType,
  IPersonalInfoType,
  IAccountType,
  ISubscriptionsStored,
  IRepresentativesType,
} from '@models/auth/utils/types';

import { ENTITY_TYPES } from '@utils/roles';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertHexToRGBA } from '@utils/functions';
import { red } from '@assets/color';
import { actionsBottom } from '@models/auth/utils/utils';
import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import { createAccount } from '@models/auth/apiRequests/registrationRequests';
import { UserStatus } from '@models/admin/users/utils/enums';
import { RequiredDocuments } from './RequiredDocuments';
import { AccountToActivate } from './AccountToActivate';
import { SubscriptionOverview } from './SubscriptionOverview';
import MultipleEntityProfilsSelected from '../MultipleEntityProfilsSelected';

interface IEntityProfilProps {
  globalInfos?: IEntityInfoType;
  personalInfos?: IPersonalInfoType;
  setShowEntityProfil: Dispatch<SetStateAction<boolean>>;
  isCompanyManager: boolean;
  setShowCompanyManagerInfo: Dispatch<SetStateAction<boolean>>;
  representatives: IRepresentativesType;
}

function EntityProfil({
  globalInfos,
  personalInfos,
  setShowEntityProfil,
  isCompanyManager,
  setShowCompanyManagerInfo,
  representatives,
}: IEntityProfilProps) {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [userInfo, setUserInfo] = useState<IAccountType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionsStored, setSubscriptionsStored] = useState<
    ISubscriptionsStored | undefined
  >(undefined);
  const [showRequiredDocuments, setShowRequiredDocuments] =
    useState<boolean>(false);
  const [showSubscriptionOverview, setShowSubscriptionOverview] =
    useState<boolean>(false);
  const [showAccountToActivate, setShowAccountToActivate] =
    useState<boolean>(false);
  const [
    showMultipleEntityProfilsSelected,
    setShowMultipleEntityProfilsSelected,
  ] = useState<boolean>(false);

  const profilTypesList = [
    {
      id: ENTITY_TYPES.INSTALLATEUR,
      label: t('sidebar.installer'),
    },
    {
      id: ENTITY_TYPES.AMO,
      label: t('sidebar.amo'),
    },
    {
      id: ENTITY_TYPES.MANDATAIRE,
      label: t('sidebar.representative'),
    },
    {
      id: ENTITY_TYPES.DELEGATAIRE,
      label: t('sidebar.delegate'),
    },
    {
      id: ENTITY_TYPES.OBLIGE,
      label: t('sidebar.single_obliged'),
    },
  ];

  const backToPersonalInfo = () => {
    if (!isCompanyManager) {
      setShowCompanyManagerInfo(true);
    } else {
      setShowEntityProfil(false);
    }
  };

  const handleCheck = (isChecked: boolean, id: number) => {
    if (isChecked) {
      if (!checkedItems.includes(id)) {
        setCheckedItems([...checkedItems, id]);
      }
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };

  /* const hasProfilWithRequiredDocs = () => {
    // un obligé et un délégataire n'a pas de doc à uploader
    const profilWithoutDocToUpload = [2, 6];
    return checkedItems.some(
      (item) => !profilWithoutDocToUpload.includes(item)
    );
  }; */

  const onSubmitEntityType = () => {
    if (checkedItems.length === 1) {
      setShowSubscriptionOverview(true);
    } else {
      setShowMultipleEntityProfilsSelected(true);
    }
  };

  const onSubmitSubscriptions = async (isSkipped = false) => {
    setLoading(true);

    if (globalInfos && personalInfos && checkedItems.length > 0) {
      const account = await createAccount({
        entity_data: {
          id: globalInfos.entity_data.id,
          siren: globalInfos.entity_data.siren,
          siret: globalInfos.entity_data.siret,
          legal_category: globalInfos.entity_data.legal_category,
          company_name: globalInfos.entity_data.company_name,
          zipcode: globalInfos.entity_data.zipcode,
          city: globalInfos.entity_data.city,
          address: globalInfos.entity_data.address,
        },
        lastname: personalInfos.lastname,
        firstname: personalInfos.firstname,
        email: personalInfos.email,
        phone_number: personalInfos.phone_number,
        password: personalInfos.password,
        function: personalInfos.function,
        profils: checkedItems,
        user_status: UserStatus.PENDING, // status "pending"
        subscription_payment_frequency: isSkipped
          ? 'free'
          : subscriptionsStored?.paymentFrequency,
        representatives,
      });

      if (account) {
        setUserInfo({
          id: account.info.user_id,
          entity_id: account.info.entity_id,
          email: personalInfos.email,
          entity_types: account.info.entity_types,
        });
        // si profil obligé ou délégataire, on confirme la création du compte
        /* if (!hasProfilWithRequiredDocs()) {
          setLoading(true);
          try {
            const sent = await registrationConfirm({
              id: account.info.user_id,
            });
            if (sent) setShowAccountToActivate(true);
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        } */
        setShowSubscriptionOverview(false);
        setShowRequiredDocuments(true);
        setShowAccountToActivate(false);
      }
    }
    setLoading(false);
    return false;
  };

  const renderCheckCard = (label: string, id: number, key: number) => (
    <TextWithCheckboxOrToggle
      key={key}
      label={label}
      onCheck={(isChecked) => handleCheck(isChecked, id)}
      checked={checkedItems.includes(id)}
      type="checkbox"
      dataTestId={`entity_profil_checkbox_${id}`}
    />
  );

  if (showMultipleEntityProfilsSelected) {
    return <MultipleEntityProfilsSelected />;
  }

  if (showSubscriptionOverview) {
    return (
      <SubscriptionOverview
        profilTypes={checkedItems}
        setShowSubscriptionOverview={setShowSubscriptionOverview}
        setSubscriptionsStored={setSubscriptionsStored}
        onSubmitSubscriptions={onSubmitSubscriptions}
        loading={loading}
      />
    );
  }

  if (showRequiredDocuments && userInfo) {
    return (
      <RequiredDocuments
        userInfo={userInfo}
        isCompanyManager={isCompanyManager}
      />
    );
  }

  if (showAccountToActivate) {
    return <AccountToActivate />;
  }

  return (
    <div className="flex flex-col lg:w-[35rem]">
      <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
        {t('auth.registration.entity_profil.header')}
      </p>
      <div className="w-full space-y-4">
        {profilTypesList.map((profil) =>
          renderCheckCard(profil.label, profil.id, profil.id)
        )}
      </div>
      <div className="w-full">
        {checkedItems && checkedItems.length > 1 && (
          <div className="min-h-[2px] mt-4">
            <p
              className="text-danger p-[.5rem] "
              style={{ backgroundColor: convertHexToRGBA(red, 0.1) }}
            >
              {t('auth.registration.entity_profil.multiple_profils_before')}{' '}
              <a
                href="https://support.opx.co"
                target="_blank"
                rel="noreferrer"
                className="font-medium"
              >
                {t('auth.registration.entity_profil.multiple_profils_after')}
              </a>
              .
            </p>
          </div>
        )}
      </div>
      {actionsBottom(
        t,
        () => backToPersonalInfo(),
        () => onSubmitEntityType(),
        undefined,
        loading,
        checkedItems.length !== 1
      )}
    </div>
  );
}

export { EntityProfil };
EntityProfil.defaultProps = {
  globalInfos: undefined,
  personalInfos: undefined,
};
