import { borderGrey } from '@assets/color';

interface ILoaderDivSkeletonProps {
  height: string;
  width?: string;
  addClass?: string;
  backgroundColor?: string;
}
function LoaderDivSkeleton({
  height,
  width,
  addClass,
  backgroundColor,
}: ILoaderDivSkeletonProps) {
  return (
    <div
      style={{
        height,
        width,
        backgroundColor: backgroundColor || borderGrey,
        opacity: backgroundColor ? 0.8 : 0.5,
      }}
      className={`skeleton-loader-anim ${addClass}`}
    />
  );
}

LoaderDivSkeleton.defaultProps = {
  width: '100%',
  addClass: '',
  backgroundColor: '',
};
export { LoaderDivSkeleton };
