import { useEffect, useMemo, useState } from 'react';
import { getCofracEligibleOperations } from '@models/cofrac/apiRequests/cofracRequests';
import { useParams } from 'react-router-dom';
import { CofracList } from '@models/cofrac/components/CofracList';
import { CofracSheet } from '@models/cofrac/components/CofracSheet';
import { IOperationType } from '@models/conventions/utils/conventionTypes';

function Cofrac(): JSX.Element {
  const [eligibleOperations, setEligibleOperations] = useState<
    IOperationType[]
  >([]);
  const { id } = useParams();
  const isCofracList = useMemo(() => !id, [id]);

  const getData = async () => {
    await getCofracEligibleOperations(setEligibleOperations);
  };

  useEffect(() => {
    if (isCofracList) getData();
  }, []);

  return isCofracList ? (
    <CofracList operations={eligibleOperations} />
  ) : (
    <CofracSheet />
  );
}

export { Cofrac };
