/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import { TFunction } from 'i18next';
import {
  urlDeleteInvitedInstaller,
  urlDuplicateConvention,
  urlGetAndPutConventionPaymentConditions,
  urlGetConventionBuyingPrices,
  urlGetConventionGeneralInfos,
  urlGetConventionModelGeneralInfo,
  urlGetInvitedInstallerList,
  urlPostContractReferents,
  urlPostConventionContact,
  urlPostConventionReferents,
  urlPostConventionWorksites,
  urlPostInvitationFromModel,
} from '@api/apiUrls';
import { Dispatch, SetStateAction } from 'react';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { IConventionType } from '@models/conventions/utils/conventionTypes';
import { IUserType } from '@models/auth/utils/types';

export const getTabs = async (t: TFunction, convention: IConventionType) => {
  try {
    const tabs = [
      {
        label: t('convention.tabs.information'),
        tab_id: 1,
      },
      {
        label: t('convention.tabs.payment_conditions'),
        tab_id: 2,
      },
      {
        label: t('convention.tabs.operation_prices'),
        tab_id: 3,
      },
      {
        label: t('convention.tabs.worksite'),
        tab_id: 5,
      },
    ];

    if (convention.intermediary_business)
      tabs.push({
        label: "Apport d'affaire",
        tab_id: 4,
      });

    return tabs.sort((tabA, tabB) => tabA.tab_id - tabB.tab_id);
  } catch (error) {
    displayError(error.response);
  }
};

export const getInfosGeneral = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetConventionGeneralInfos(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateContact = async (
  conventionId: number,
  contactId: number,
  data: any
) => {
  try {
    const response = await axiosClient.post(
      urlPostConventionContact(conventionId, contactId),
      data
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateReferents = async (
  contractType: number,
  contractId: number,
  referent1: IUserType,
  referent2: IUserType | null,
  isConvention: boolean,
  isCreator: boolean
) => {
  const data = {
    contract_type: contractType,
    contact_1: {
      id: referent1.id,
      contact_user_id: referent1.contact_user_id,
      entity_type: referent1.entity_type,
      contact_type: isCreator ? 3 : 1,
    },
    contact_2: !referent2
      ? null
      : {
          id: referent2.id,
          contact_user_id: referent2.contact_user_id,
          entity_type: referent2.entity_type,
          contact_type: isCreator ? 3 : 1,
        },
  };

  const url = isConvention
    ? urlPostConventionReferents(contractId)
    : urlPostContractReferents(contractId);

  try {
    const response = await axiosClient.post(url, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getPaymentConditions = async (id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetAndPutConventionPaymentConditions(id)
    );
    return response.data.data.paymentConditions;
  } catch (error) {
    displayError(error.response);
  }
};

export const getBuyingPrices = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetConventionBuyingPrices(id));
    return response.data.data.conventionsOperations;
  } catch (error) {
    displayError(error.response);
  }
};

export const getWorksites = async (
  conventionId: number,
  setWorksitesList: Dispatch<SetStateAction<IWorksiteDetails[]>>,
  setIsLoadingList: Dispatch<SetStateAction<boolean>>,
  numberPageList: number,
  setTotalWorksites: Dispatch<SetStateAction<number>>,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  worksitesList?: IWorksiteDetails[]
) => {
  const config = {
    pagination: {
      page: numberPageList,
      perPage: 5,
    },
  };
  try {
    const response = await axiosClient.post(
      urlPostConventionWorksites(conventionId),
      config
    );
    setTotalWorksites(response.data.meta.total);
    if (numberPageList === 1) {
      setWorksitesList(response.data.data);
      setIsLoadingList(false);
    } else {
      const newData = response.data.data;
      const listWorksitesInArray: IWorksiteDetails[] =
        worksitesList !== undefined ? worksitesList : [];
      const newArray = listWorksitesInArray.concat(newData);
      setWorksitesList(newArray);
    }
    if (setIsLoading) {
      setIsLoading(false);
    }
  } catch (error) {
    displayError(error.response);
  }
};

export const duplicateConvention = async (conventionId: number) => {
  try {
    const response = await axiosClient.post(
      urlDuplicateConvention(conventionId)
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const processInvitationFromModel = async (
  modelId: number | null,
  validRowDatas: any
) => {
  try {
    const response = await axiosClient.post(
      urlPostInvitationFromModel(Number(modelId)),
      { valid_row_datas: validRowDatas }
    );

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getConventionModelGeneralInfo = async (id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetConventionModelGeneralInfo(id)
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getConventionModelBuyingPrice = async (id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetConventionModelGeneralInfo(id)
    );
    return response.data.data.convention_operations;
  } catch (error) {
    displayError(error.response);
  }
};

export const getConventionModelTabs = async (t: TFunction) => {
  try {
    const tabs = [
      {
        label: t('convention.tabs.information'),
        tab_id: 1,
      },
      {
        label: t('convention.tabs.operation_prices'),
        tab_id: 2,
      },
    ];

    return tabs.sort((tabA, tabB) => tabA.tab_id - tabB.tab_id);
  } catch (error) {
    displayError(error.response);
  }
};

export const getInvitedInstallersList = async (
  id: number,
  status: number | null
) => {
  try {
    const response = await axiosClient.post(
      urlGetInvitedInstallerList(Number(id)),
      { status }
    );

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const unlinkInvitedInstaller = async (
  modelId: number,
  entityId: number | undefined,
  invitationId: number | undefined
) => {
  try {
    const response = await axiosClient.post(
      urlDeleteInvitedInstaller(Number(modelId)),
      { entity_id: entityId, invitation_id: invitationId }
    );

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};
