import { blueOpx, orange, purple } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { Tag } from '@components/atomic/Tag';

interface ICardChoicePartnerInModalProps {
  choice: string;
  infos?: string;
  addClass?: string;
  onChoice?: () => void;
  dataTestId?: string;
  badges?: string[];
}

function CardChoicePartnerInModal({
  choice,
  infos,
  onChoice,
  addClass,
  dataTestId,
  badges,
}: ICardChoicePartnerInModalProps) {
  return (
    <button
      type="button"
      onClick={onChoice}
      className={[
        'bg-white border text-start border-borderGrey hover:border-backgroundBody hover:bg-backgroundBody rounded-default p-[1.5rem] flex items-start justify-between',
        addClass,
      ].join(' ')}
      data-test-id={dataTestId}
    >
      <div className="w-11/12">
        <div className="text-[1rem] font-medium flex gap-3">
          {' '}
          {choice}
          {badges && badges[0] && <Tag color={orange} label={badges[0]} />}
          {badges && badges[1] && <Tag color={purple} label={badges[1]} />}
        </div>
        {infos && <p className="text-[.875rem] mt-[.625rem]">{infos}</p>}
      </div>
      <ColorCube
        opacity
        size="1.5rem"
        color={blueOpx}
        addClass="w-1/12"
        numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
      />
    </button>
  );
}

export { CardChoicePartnerInModal };

CardChoicePartnerInModal.defaultProps = {
  onChoice: undefined,
  addClass: '',
  dataTestId: '',
  infos: '',
  badges: undefined,
};
