import { TextError } from '@components/TextError';
import { sendInfoBeneficiary } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { ITaxHouselod } from '@models/worksiteCreation/utils/types/worksitesType';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import {
  arraysAreSame,
  simulatorToTaxHouseHold,
  taxHouseHoldToSimulator,
} from '@models/worksiteCreation/utils/functions';
import { taxNoticeReferenceRegex, taxNumberRegex } from '@utils/regex';
import { IDefaultObject } from '../../../../../types/globalTypes';
import { TaxHousehold } from './taxHousehold/TaxHousehold';

function InfoBeneficiary() {
  const methods = useForm();

  const { handleSubmit } = methods;
  const {
    updateInformationBeneficiaryDataPost,
    worksiteDatas,
    informationBeneficiaryDataPost,
    updateIsLoading,
    updateWorksiteDatas,
    beneficiary,
    updateDisabledNextButton,
    nextStepWorksiteCreation,
    worksiteOperationType,
    updateSimulatorData,
    simulatorData,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const [errorBeneficiary, setErrorBeneficiary] = useState<string>('');
  const [taxHouseholdDatas, setTaxHouseholdDatas] = useState<ITaxHouselod[]>([
    {
      firstname: beneficiary?.firstname || '',
      lastname: beneficiary?.lastname || '',
      fiscal_number: '',
      fiscal_reference: '',
    },
  ]);

  const initialFiscalDeclarations = useMemo(() => {
    simulatorToTaxHouseHold(simulatorData, setTaxHouseholdDatas);
    return worksiteDatas.fiscalDeclarations;
  }, []);

  const noChange = useMemo(() => {
    const { fiscalDeclarations } = informationBeneficiaryDataPost;

    const initial = initialFiscalDeclarations as IDefaultObject[];
    const current = fiscalDeclarations as IDefaultObject[];

    return arraysAreSame(initial, current, 'fiscal_number');
  }, [informationBeneficiaryDataPost.fiscalDeclarations, worksiteDatas]);

  const onSubmit = async () => {
    if (readOnly) nextStepWorksiteCreation();
    else if (worksiteDatas.need_fiscal_datas !== undefined) {
      const sortedTaxes = taxHouseholdDatas.sort((a, b) => {
        if (a.id === undefined) return 1;
        if (b.id === undefined) return -1;
        return a.id - b.id;
      });

      const beneficiaryData = {
        ...informationBeneficiaryDataPost,
        fiscalDeclarations: sortedTaxes,
      };

      let goToNextStep = true;

      if (!noChange && worksiteDatas.need_fiscal_datas) {
        goToNextStep = await sendInfoBeneficiary(
          worksiteOperationType,
          worksiteDatas.id,
          beneficiaryData,
          updateIsLoading,
          updateWorksiteDatas,
          setErrorBeneficiary,
          null
        );
      }

      if (goToNextStep) nextStepWorksiteCreation();
    }
  };

  useEffect(() => {
    updateInformationBeneficiaryDataPost({
      fiscalDeclarations: taxHouseholdDatas,
    });

    taxHouseHoldToSimulator(updateSimulatorData, taxHouseholdDatas);
  }, [taxHouseholdDatas]);

  useEffect(() => {
    const { fiscalDeclarations } = informationBeneficiaryDataPost;
    const { need_fiscal_datas } = worksiteDatas;

    let isNextButtonDisabled = false;

    if (need_fiscal_datas) {
      const allValid = fiscalDeclarations.every((data) => {
        const fiscalInfoProvided =
          data.fiscal_number !== '' || data.fiscal_reference !== '';
        const validFiscalNumber =
          !data.fiscal_number || taxNumberRegex.test(data.fiscal_number);
        const validFiscalReference =
          !data.fiscal_reference ||
          taxNoticeReferenceRegex.test(data.fiscal_reference);
        const hasName = !!data.firstname && !!data.lastname;

        return (
          hasName &&
          (!fiscalInfoProvided || (validFiscalNumber && validFiscalReference))
        );
      });

      isNextButtonDisabled = !allValid;
    }

    updateDisabledNextButton(isNextButtonDisabled);
  }, [informationBeneficiaryDataPost, worksiteDatas]);

  return (
    <FormProvider {...methods}>
      <form
        id="sendInfoBeneficiary"
        className="pt-[1.5rem]"
        onSubmit={
          handleSubmit
            ? handleSubmit(onSubmit as (data: FieldValues) => void)
            : undefined
        }
      >
        {!!worksiteDatas.need_fiscal_datas && (
          <TaxHousehold
            methods={methods}
            taxHouseholdDatas={taxHouseholdDatas}
            setTaxHouseholdDatas={setTaxHouseholdDatas}
          />
        )}

        <TextError errorMessage={errorBeneficiary} />
      </form>
    </FormProvider>
  );
}

export { InfoBeneficiary };
