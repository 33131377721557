/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import {
  urlConventions,
  urlDeleteConventionBuyingPrices,
  urlEditBuyingPriceConvention,
  urlGetAndPutConventionPaymentConditions,
  urlGetBuyingPriceValidationContract,
  urlGetBuyingPriceValidationConvention,
  urlGetConventionsRaiList,
  urlPutConventionBuyingPrices,
  urlPutConventionInfos,
} from '@api/apiUrls';
import { toast } from 'react-toastify';

export const getRaiAndContractsConvention = async () => {
  try {
    const response = await axiosClient.get(urlGetConventionsRaiList);

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const saveGeneralInfosConvention = async (data: any) => {
  try {
    const response = await axiosClient.put(urlConventions, data);

    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const saveInfosConvention = async (conventionId: number, data: any) => {
  try {
    const response = await axiosClient.put(
      urlPutConventionInfos(conventionId),
      {
        ...data,
        copyId: conventionId,
      }
    );

    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const storeBuyingPriceConvention = async (
  conventionId: number,
  data: any
) => {
  const tmpData = { ...data };
  if (data.pu_precaire === null) {
    delete tmpData.pu_precaire;
  }
  try {
    const response = await axiosClient.put(
      urlPutConventionBuyingPrices(conventionId),
      tmpData
    );

    return response.data.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

export const editBuyingPriceConvention = async (
  operationId: number,
  data: any
) => {
  try {
    const response = await axiosClient.post(
      urlEditBuyingPriceConvention(operationId),
      data
    );

    return response.data.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

export const deleteBuyingPriceConvention = async (operationId: number) => {
  try {
    const response = await axiosClient.delete(
      urlDeleteConventionBuyingPrices(operationId)
    );

    return response.data.data;
  } catch (error) {
    displayError({
      status: error.response.status,
      statusText: error.response.statusText,
      data: {
        errors: [error.response.data.errors],
      },
    });
  }
};

export const checkBuyingpriceValidity = async (
  contractId: number,
  type: string
) => {
  try {
    const response = await axiosClient.get(
      type === 'convention'
        ? urlGetBuyingPriceValidationConvention(contractId)
        : urlGetBuyingPriceValidationContract(contractId)
    );

    return response.data;
  } catch (error) {
    displayError(error.data ? error : error.response);
  }
};

export const updatePaymentConditions = async (
  conventionId: number,
  data: any
) => {
  try {
    const response = await axiosClient.put(
      urlGetAndPutConventionPaymentConditions(conventionId),
      data
    );

    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

// export const noChangeBuyingPrices = (prices: )
