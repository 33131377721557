import { SearchBar } from '@components/SearchBar';
import {
  backgroundBody,
  iconBlue,
  iconBlueOpacity,
  textGrey,
} from '@assets/color';
import { v4 } from 'uuid';
import { getSearchElement } from '@utils/functions';
import { SearchIcon } from '@components/sortAndFilter/SearchAndFilterIcon';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '@context/authContext';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { t } from 'i18next';
import { globalSearch } from '@apiRequests/globalRequests';
import { Tag } from '@components/atomic/Tag';

function GlobalSearch(): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<{ [x: number]: [] }>();
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [itemInListHovered, setItemInListHovered] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);

  const { user } = useContext(AuthContext);
  const { updateIsOpaque } = useContext(GlobalContext);

  const availableTags = user?.tags || [];
  const suggestionTags = suggestions && Object.entries(suggestions);
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const focusOrHover = isFocus || isHover;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsFocus(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onSearch = () => {
    setSearchPerformed(true);
    setIsLoading(true);
    globalSearch(searchValue, selectedTags, setSuggestions, setIsLoading);
  };

  const handleTags = (value: number) => {
    if (selectedTags.length === availableTags.length) {
      setSelectedTags([value]);
    } else if (selectedTags.length === 1 && selectedTags.includes(value)) {
      setSelectedTags(availableTags.map((tag) => tag.value));
    } else if (selectedTags.includes(value)) {
      const newState = selectedTags.filter((tag) => tag !== value);
      setSelectedTags(newState);
    } else {
      setSelectedTags([...selectedTags, value]);
    }
  };

  const emptySuggestions =
    !suggestionTags ||
    suggestionTags?.filter((s) => s[1].length > 0).length < 1;

  useEffect(() => {
    if (searchValue.length > 2) {
      onSearch();
    } else {
      setSuggestions(undefined);
      setSearchPerformed(false);
    }
  }, [selectedTags, searchValue]);

  useEffect(() => {
    if (user) {
      const allTags = availableTags.map((tag) => tag.value);
      setSelectedTags(allTags);
    }
  }, [user]);

  useEffect(() => {
    updateIsOpaque(focusOrHover);
  }, [isFocus, isHover]);

  return (
    <div
      ref={containerRef}
      className="flex flex-col gap-3 top-[.9rem] relative z-[52]"
      onMouseLeave={() => setIsHover(false)}
    >
      <SearchBar
        addClass="mx-5"
        placeholder={t('search_bar.search_something')}
        onSearch={(value: string) => setSearchValue(value)}
        setFocus={setIsFocus}
        setHover={setIsHover}
        width={focusOrHover ? '40rem' : '19rem'}
        isLoading={isLoading}
      />
      <div className="w-full h-[1rem] bg-transparent mx-0" />
      {focusOrHover && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className="bg-white p-3 border border-1 border-borderGrey rounded-default mx-5 absolute top-[3.5rem] text-[.75rem]"
          style={{
            width: focusOrHover ? '40rem' : '19rem',
            transition: '0.4s',
          }}
          onClick={() => setIsFocus(true)}
        >
          <div className="flex gap-2">
            {availableTags.map((tag) => {
              const isSelected = selectedTags.includes(tag.value);
              const allSelected =
                selectedTags && selectedTags.length === availableTags.length;
              return (
                <div
                  aria-hidden
                  onClick={() => {
                    handleTags(tag.value);
                  }}
                  className="py-1 px-2 rounded-[3px] cursor-pointer whitespace-nowrap"
                  style={{
                    color: allSelected || isSelected ? iconBlue : textGrey,
                    backgroundColor:
                      allSelected || isSelected
                        ? iconBlueOpacity
                        : backgroundBody,
                  }}
                  key={v4()}
                >
                  {tag.label}
                </div>
              );
            })}
          </div>
          {emptySuggestions &&
            searchValue.length > 2 &&
            searchPerformed &&
            !isLoading && <div className="mt-3">{t('list.no_result')}</div>}
          {!emptySuggestions && (
            <div
              className="mt-3 h-screen overflow-y-auto"
              style={{ maxHeight: window.innerHeight * 0.7 }}
            >
              {suggestionTags
                .filter((elt) => elt[1].length > 0)
                .map((tag, i) => {
                  const index = Number(tag[0]);
                  const item = availableTags.find((elt) => elt.value === index);
                  const list = suggestions[index] as {
                    [x: string]: string | number;
                  }[];
                  const label = item?.label || '';
                  return (
                    <div
                      key={v4()}
                      className="border border-solid border-transparent border-1 border-t-borderGrey p-2 "
                      style={{ borderWidth: i === 0 ? 0 : '' }}
                    >
                      <div className="text-textGrey mb-2">{label}</div>
                      {list && (
                        <div>
                          {list.map((element, it) => {
                            const { text, link } = getSearchElement(
                              index,
                              element,
                              user?.entity_type
                            );
                            return (
                              <div
                                onMouseOver={() =>
                                  setItemInListHovered(`${index}${it}`)
                                }
                                onFocus={() =>
                                  setItemInListHovered(`${index}${it}`)
                                }
                                key={v4()}
                                aria-hidden
                                onClick={() => {
                                  navigate(link);
                                }}
                                className="flex items-center gap-2 p-1 rounded-default cursor-pointer"
                                style={{
                                  backgroundColor:
                                    `${index}${it}` === itemInListHovered
                                      ? iconBlueOpacity
                                      : 'transparent',
                                }}
                              >
                                <SearchIcon index={index} />
                                <div className="flex gap-3">
                                  {text}{' '}
                                  {element.tag && (
                                    <Tag
                                      label={String(element.tag || '')}
                                      color={textGrey}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { GlobalSearch };
