/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import {
  urlDepositDateDeposit,
  urlDepositLinkOperation,
  urlDepositOperationsToControl,
  urlDepositUnLinkOperation,
  urlDeposits,
  urlGetDepositInfos,
  urlGetDepositLinkedOperations,
  urlGetDepositOperationsToLink,
  urlGetDepositRaiList,
  urlPostDepositsDeliveries,
  urlPutCreateDeposit,
  urlDepositsExportCsv,
  urlDownloadDepositDocument,
  urlDeleteDeposit,
} from '@api/apiUrls';
import { displayError } from '@utils/format';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { Dispatch, SetStateAction } from 'react';
import { saveAs } from 'file-saver';

export const getDepotsList = async (
  page: number,
  status: number | null,
  sortAndFilter?: ISortAndFilterType,
  resultsPerPage = 5
) => {
  const configs = {
    pagination: {
      page,
      perPage: resultsPerPage,
    },
    status,
    sort_and_filter: sortAndFilter,
  };

  try {
    const response = await axiosClient.post(urlDeposits, configs);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getDeliveries = async (
  page: number,
  sortAndFilter: ISortAndFilterType
) => {
  const configs = {
    pagination: {
      page,
      perPage: 15,
    },
    status: null,
    sort_and_filter: sortAndFilter,
  };

  try {
    const response = await axiosClient.post(urlPostDepositsDeliveries, configs);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getDepositRaiList = async () => {
  try {
    const response = await axiosClient.get(urlGetDepositRaiList);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const createDeposit = async (data: any) => {
  try {
    const response = await axiosClient.put(urlPutCreateDeposit, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getDepot = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetDepositInfos(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getLinkedOperations = async (
  id: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  const data = {
    keyword: search !== '' ? search : null,
    sort_and_filter: sortAndFilter,
  };
  try {
    const response = await axiosClient.post(
      urlGetDepositLinkedOperations(id),
      data
    );
    setIsLoading((prev) => ({ ...prev, linked: false }));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getOperationsToLink = async (
  raiId: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  const data = {
    rai: raiId,
    keyword: search !== '' ? search : null,
    sort_and_filter: sortAndFilter,
  };
  try {
    const response = await axiosClient.post(
      urlGetDepositOperationsToLink,
      data
    );

    setIsLoading((prev) => ({ ...prev, toLink: false }));
    return response.data;
  } catch (error) {
    setIsLoading((prev) => ({ ...prev, toLink: false }));
    displayError(error.response);
  }
};

export const depositLinkOperation = async (data: any) => {
  try {
    const response = await axiosClient.post(urlDepositLinkOperation, data);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const depositUnLinkOperation = async (lineId: number) => {
  try {
    const response = await axiosClient.delete(
      urlDepositUnLinkOperation(lineId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const saveDateDeposit = async (data: any) => {
  try {
    const response = await axiosClient.put(urlDepositDateDeposit, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const listOperationsToControl = async (
  depositId: any,
  keyword: string,
  sortAndFilter?: ISortAndFilterType
) => {
  try {
    const response = await axiosClient.post(
      urlDepositOperationsToControl(depositId),
      { keyword, sort_and_filter: sortAndFilter }
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const exportCsv = async (id: number) => {
  try {
    const response = await axiosClient.get(urlDepositsExportCsv(id));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const downloadDocuments = async (
  id: number,
  reference: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.get(urlDownloadDepositDocument(id), {
      responseType: 'arraybuffer',
    });

    if (response.data !== undefined) {
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });

      setIsLoading(false);

      saveAs(blob, reference);
    }
  } catch (error) {
    setIsLoading(false);
    displayError(error.response);
  }
};

export const deleteDeposit = async (depositId: number) => {
  try {
    const response = await axiosClient.delete(urlDeleteDeposit(depositId));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};
