/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import { IColumn, ListTable } from '@components/ListTable';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { PriceCell } from '@models/conventions/components/PriceCell';
import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { blueOpx } from '@assets/color';
import { IBuyingPriceType } from '../utils/conventionTypes';

interface ITabPricePerOperationProps {
  id: number;
  fetchData: (id: number, isConvention: boolean) => Promise<any>;
  isConvention: boolean;
  incentiveType?: number;
}

function TabPricePerOperation(props: ITabPricePerOperationProps) {
  const { id, fetchData, isConvention, incentiveType } = props;
  const { t } = useTranslation();

  const [data, setData] = useState<IBuyingPriceType[]>([]);
  const [dataPaginated, setDataPaginated] = useState<IBuyingPriceType[]>([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [resultsPerPage, setResultsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const columnHelper = createColumnHelper<IBuyingPriceType>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('id', {
        header: () => 'id',
      }),
      columnHelper.accessor('operation.code', {
        header: () => t('convention.tab_operation_prices.operation_code'),
        cell: (info) => {
          return <span>{info.getValue() ? `${info.getValue()}` : '-'}</span>;
        },
        size: 10,
      }),
      columnHelper.accessor('operation.description', {
        header: () => t('convention.tab_operation_prices.operation_type'),
        cell: (info) => {
          return <span>{info.getValue() ? `${info.getValue()}` : '-'}</span>;
        },
      }),
      columnHelper.accessor(
        isConvention ? 'indirect_pu_classique' : 'prix_unitaire_classique',
        {
          header: () => `${t('convention.tab_operation_prices.price_classic')}`,
          cell: (info) => (
            <PriceCell
              isConvention={isConvention}
              info={info}
              incentiveType={incentiveType}
              addClass={
                incentiveType === INCENTIVE_TYPE.MIXED ? 'border-l' : ''
              }
            />
          ),
        }
      ),
      columnHelper.accessor('indirect_pu_intermediaire', {
        header: () =>
          `${t('convention.tab_operation_prices.price_intermediaire')}`,
        cell: (info) => (
          <PriceCell
            isConvention={isConvention}
            info={info}
            incentiveType={incentiveType}
          />
        ),
      }),
      columnHelper.accessor('indirect_pu_modeste', {
        header: () => `${t('convention.tab_operation_prices.price_modeste')}`,
        cell: (info) => (
          <PriceCell
            isConvention={isConvention}
            info={info}
            incentiveType={incentiveType}
          />
        ),
      }),
      columnHelper.accessor(
        isConvention ? 'indirect_pu_precaire' : 'prix_unitaire_precaire',
        {
          header: () =>
            `${t('convention.tab_operation_prices.price_precaire')}`,
          cell: (info) => (
            <PriceCell
              isConvention={isConvention}
              info={info}
              incentiveType={incentiveType}
            />
          ),
        }
      ),
      columnHelper.accessor('incentive_type', {
        header: () => `${t('convention.tab_operation_prices.incentive')}`,
        cell: () => (
          <div className="flex flex-col h-full">
            <div className="border-b flex items-center h-full">
              {t('convention.tab_operation_prices.indirect')}
            </div>
            <div className="flex h-full items-center">
              {t('convention.tab_operation_prices.direct')}
            </div>
          </div>
        ),
      }),
    ];
  }, [isConvention]);

  const getData = async () => {
    const response = await fetchData(id, isConvention);
    if (response) {
      setData(response);
      setPaginationData({
        current_page: 1,
        last_page: Math.ceil(response.length / resultsPerPage),
        per_page: resultsPerPage,
        total: response.length,
      });
    }
  };

  const filterByResultsPerPage = () => {
    const startIndex =
      ((paginationData?.current_page as number) - 1 || 0) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    const paginatedData =
      data && data.length > 0 && data.slice(startIndex, endIndex);
    if (paginatedData) {
      setDataPaginated(paginatedData);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    filterByResultsPerPage();
  }, [resultsPerPage, data, paginationData]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="mt-4">
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <div className="mb-4">
          <ResultsPerPageButton
            resultsPerPage={resultsPerPage}
            onChange={(value) => {
              setResultsPerPage(value);
              setCurrentPage(1);
              setPaginationData((oldPaginationData) => ({
                ...oldPaginationData,
                current_page: 1,
                per_page: value,
                last_page: Math.ceil(
                  (oldPaginationData?.total as number) / value
                ),
              }));
            }}
            options={[5, 10, 20, 50, 100, 250]}
            colorPagination={blueOpx}
          />
        </div>
      </div>
      <ListTable
        columns={columns as IColumn[]}
        data={dataPaginated}
        hiddenColumns={
          isConvention
            ? [
                'id',
                incentiveType === INCENTIVE_TYPE.MIXED ? '' : 'incentive_type',
              ]
            : [
                'id',
                'indirect_pu_unique',
                'indirect_pu_intermediaire',
                'indirect_pu_modeste',
                'incentive_type',
              ]
        }
        hideArrow
        addStyleRow={
          incentiveType === INCENTIVE_TYPE.MIXED
            ? { gap: 0, paddingBottom: 0, paddingTop: 0, paddingRight: 0 }
            : undefined
        }
        addStyleHeader={{ paddingRight: 0 }}
        rowsPerPage={resultsPerPage}
      />
      <ListPagination
        getData={(page) => {
          setCurrentPage(page);
          setPaginationData((oldPaginationData) => ({
            ...oldPaginationData,
            current_page: page,
          }));
        }}
        paginationData={paginationData}
        resultsPerPage={resultsPerPage || 5}
        currentPage={currentPage}
      />
    </div>
  );
}

export { TabPricePerOperation };

TabPricePerOperation.defaultProps = { incentiveType: undefined };
