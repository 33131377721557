import { useId } from 'react';

function LoaderUsersInList() {
  return (
    <div className="flex flex-col border-t border-t-borderGrey">
      {[...Array(5)].map(() => (
        <div
          key={useId()}
          className="p-[1rem] border-b border-b-borderGrey flex items-center justify-between"
        >
          <div className="flex items-center space-x-[.5rem]">
            <div className="skeleton-loader-anim-darker h-[2.5rem] w-[2.5rem]" />
            <div>
              <div className="skeleton-loader-anim-darker h-[1.25rem] w-[15rem] mb-[.25rem]" />
              <div className="skeleton-loader-anim-darker h-[.75rem] w-[7rem]" />
            </div>
          </div>
          <div className="skeleton-loader-anim-darker h-[2.5rem] w-[10rem]" />
        </div>
      ))}
    </div>
  );
}
export { LoaderUsersInList };
