import { toast } from 'react-toastify';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { ILogin, IUserType } from '@models/auth/utils/types';
import { displayError } from '@utils/format';
import {
  urlGetUser,
  urlLogout,
  urlPostActivateResetPwd,
  urlPostAuthentication,
  urlPostPasswordForgotten,
  urlPostPasswordReset,
  urlPostPwdSendCode,
} from '@api/apiUrls';
import { FieldValues } from 'react-hook-form';
import Cookies from 'js-cookie';
import { axiosClient } from '../../../api/apiClient';

export const login = async (
  data: ILogin,
  updateToken: Dispatch<SetStateAction<string | undefined>>
) => {
  try {
    const response = await axiosClient.post(urlPostAuthentication, data);
    // Si la réponse contient un token, on le stocke dans le local storage
    if (response.data.success) {
      updateToken('token');
      Cookies.set('token', 'token', {
        expires: 7,
        secure: true,
      });
      return true;
    }
    // Si la réponse contient une erreur indiquant que les identifiants sont invalides, on affiche un message d'erreur
    if (response.data.error === 'invalid_grant') {
      toast.error(t('auth.errors.credentials'));
      // Si la réponse contient une erreur indiquant que l'utilisateur est inactif, on affiche un message d'erreur
    } else if (response.data.error === 'user_not_actif') {
      toast.error(response.data.message);
    }
  } catch (error) {
    // Si la requête échoue, on affiche un message d'erreur
    toast.error(error.response.data.errors || t('auth.errors.server_error'));
  }
  // indique que la connexion a échouée
  return false;
};

export const getUserByToken = async (
  updateUser: Dispatch<SetStateAction<IUserType | null>>
) => {
  try {
    const response = await axiosClient.get(urlGetUser);
    updateUser(response.data);
  } catch (error) {
    // vide car erreur gérée avec les intercepteurs
  }
};

export const sendEmailPasswordForgotten = async (email: string) => {
  try {
    await axiosClient.post(urlPostPasswordForgotten, { email });
    toast.success(t('auth.forget_pwd.success'));
  } catch (error) {
    if (
      error.response.data &&
      error.response.data.errors === 'Utilisateur inexistant'
    ) {
      toast.error(t('auth.forget_pwd.error'));
    } else {
      displayError(error.response);
    }
  }
};

export const resetPassword = async (data: {
  email: string | null;
  token: string | null;
  password: string;
  password_confirmation: string;
}) => {
  try {
    await axiosClient.post(urlPostPasswordReset, data);
    toast.success(t('auth.reset_pwd.success'));
  } catch (error) {
    displayError(error.response);
  }
};

export const sendPasswordCode = async (
  data: FieldValues,
  setShowModal: Dispatch<SetStateAction<boolean>>
) => {
  try {
    await axiosClient.post(urlPostPwdSendCode, data);
    setShowModal(true);
    return true;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const activatePasswordReset = async (
  data: FieldValues,
  setShowModal: Dispatch<SetStateAction<boolean>>
) => {
  try {
    await axiosClient.post(urlPostActivateResetPwd, data);
    setShowModal(false);
    toast.success(t('auth.reset_pwd.success_logged'));
    return true;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const logout = async (
  updateToken: Dispatch<SetStateAction<string | undefined>>
) => {
  try {
    await axiosClient.get(urlLogout);
    Cookies.remove('token');
    updateToken('');
    return true;
  } catch (error) {
    return false;
  }
};
