import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '@utils/routesUrls';

function PartnershipConfirmation() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
        {t('auth.registration.account_confirmation.congratulations')}
        <br />
        {t('auth.registration.partnership_confirmation.created_message')}{' '}
      </p>
      <ButtonOpx
        addClass="w-full my-6"
        label={t('auth.login')}
        type="primary"
        onClick={() => navigate(AUTH_ROUTES.LOGIN)}
      />
    </div>
  );
}

export { PartnershipConfirmation };
