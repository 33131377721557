import { createContext, Dispatch, SetStateAction } from 'react';

interface IProps {
  titleHeader: string;
  updateTitleHeader: Dispatch<SetStateAction<string>>;
  subtitleHeader: string | undefined;
  updateSubtitleHeader: Dispatch<SetStateAction<string | undefined>>;
  tagHeader: JSX.Element | undefined;
  updateTagHeader: Dispatch<SetStateAction<JSX.Element | undefined>>;
  displayBackButtonHeader: boolean;
  updateDisplayBackButtonHeader: Dispatch<SetStateAction<boolean>>;
  additionnalInfosHeader: string[] | undefined;
  updateAdditionnalInfosHeader: Dispatch<SetStateAction<string[] | undefined>>;
  refreshHeader: (displayBackButton?: boolean) => Promise<void>;
  routeFrom: string;
  updateRouteFrom: Dispatch<SetStateAction<string>>;
}

export const HeaderContext = createContext({} as IProps);
