/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IUserType } from '@models/auth/utils/types';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getUsersList } from '@models/admin/users/apiRequests/usersRequests';
import { HeaderContext } from '@context/headerContext';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { resetPagination } from '@components/sortAndFilter/utils/functions';
import { Tag } from '@components/atomic/Tag';
import { green, orange, red } from '@assets/color';
import { EntityStatusEnum } from '@models/worksiteCreation/utils/enums';
import { ADMIN_ROUTES } from '@utils/routesUrls';

interface UsersListProps {
  sortAndFilterData: ISortAndFilterType;
  searchKeyword?: string;
}

const PER_PAGE = 15;
function UsersList({
  sortAndFilterData,
  searchKeyword,
}: UsersListProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateTitleHeader, refreshHeader, updateDisplayBackButtonHeader } =
    useContext(HeaderContext);

  const { globalEnum } = useContext(GlobalContext);

  const [data, setData] = useState<IUserType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<{
    data: any;
    meta: any;
  }>();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const columnHelper = createColumnHelper<IUserType>();

  const fetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const resList = await getUsersList({
          page,
          perPage: PER_PAGE,
          sortAndFilterData,
          searchKeyword,
        });
        if (resList) {
          setData(resList.data);
          setPaginationData(resList);
          if (resList.meta.current_page > pagesDatas.length) {
            setPagesDatas((prev) => [
              ...prev,
              { data: resList.data, meta: resList.meta },
            ]);
          }
        }
      } catch (error) {
        console.error('Error fetching user actions history:', error);
      }
      setLoading(false);
    },
    [sortAndFilterData, searchKeyword]
  );

  const loadPage = useCallback(
    (page: number) => {
      if (paginationData) {
        const pageData = pagesDatas.find(
          (p: any) => p.meta.current_page === page
        );
        if (pageData) {
          setData(pageData.data);
          setPaginationData(pageData);
        } else {
          fetchData(page);
        }
      }
    },
    [paginationData, fetchData]
  );

  const colorTag = (status: number) => {
    switch (status) {
      case EntityStatusEnum.GHOST:
        return orange;
      case EntityStatusEnum.ACTIVE:
        return green;
      default:
        return red;
    }
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('firstname', {
        header: () => t('forms.firstname.placeholder'),
      }),
      columnHelper.accessor('lastname', {
        header: () => t('forms.lastname.placeholder'),
      }),
      columnHelper.accessor('email', {
        header: () => t('forms.email.placeholder'),
      }),
      columnHelper.accessor('identity_status', {
        header: () => t('admin.users.identity_status'),
        cell: (info) => {
          if (info.getValue())
            return (
              <span>{globalEnum.identity_status[Number(info.getValue())]}</span>
            );
          return <span />;
        },
      }),
      columnHelper.accessor('entity_name', {
        header: () => t('admin.users.entity_name'),
      }),
      columnHelper.accessor('entity_type', {
        header: () => t('admin.users.entity_type'),
        cell: (info) => {
          if (info.getValue())
            return (
              <span>{globalEnum.entity_type[Number(info.getValue())]}</span>
            );
          return <span />;
        },
      }),
      columnHelper.accessor('entity_status', {
        header: () => t('admin.users.entity_status'),
        cell: (info) => {
          if (info.getValue())
            return (
              <div className="flex items-center space-x-[1rem] mt-[.375rem]">
                <Tag
                  color={colorTag(Number(info.getValue()))}
                  label={globalEnum.entity_status[Number(info.getValue())]}
                  addClass={globalEnum.entity_status[Number(info.getValue())]}
                />
              </div>
            );
          return <span />;
        },
      }),
    ];
  }, [data]);

  useEffect(() => {
    if (sortAndFilterData || searchKeyword) {
      resetPagination(setPaginationData, setPagesDatas);
      fetchData(1);
    }
  }, [sortAndFilterData, searchKeyword]);

  useEffect(() => {
    refreshHeader();
    updateTitleHeader(`${t('sidebar.users')}`);
    fetchData();
  }, []);

  return (
    <div>
      <ListTable
        data={data}
        columns={columns as IColumn[]}
        loading={loading}
        callBackOnRowClick={(e: IRow) => {
          const link = ADMIN_ROUTES.ADMIN_USERS;

          const rowData = e.original as IUserType;
          navigate(`${link}/${rowData.id}`);
          updateDisplayBackButtonHeader(true);
        }}
        rowsPerPage={PER_PAGE}
      />
      {paginationData && Number(paginationData.meta.last_page) > 1 && (
        <ListPagination
          getData={loadPage}
          paginationData={paginationData.meta}
        />
      )}
    </div>
  );
}

export { UsersList };

UsersList.defaultProps = {
  searchKeyword: null,
};
