import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { red, blueOpx, purple } from '@assets/color';
import { EuroIcon, RobotIcon, WarningIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { WORKSITE_TYPES_DASHBOARD } from '../utils/enums';
import { IWorksiteCardDatas } from '../utils/dashboardTypes';
import { WORKSITE_STATUS_DASHBOARD_LABELS } from '../utils/utils';

function WorksiteQuantityCard({
  worksiteType,
  numberWorksite,
}: IWorksiteCardDatas) {
  const { t } = useTranslation();
  const iconToUse = useMemo(() => {
    let icon;
    let color;

    switch (worksiteType) {
      case WORKSITE_TYPES_DASHBOARD.WORKSITE_SIMULATION:
        icon = <WarningIcon />;
        color = red;
        break;
      case WORKSITE_TYPES_DASHBOARD.WORKSITE_CREATED:
        icon = <RobotIcon />;
        color = blueOpx;
        break;
      case WORKSITE_TYPES_DASHBOARD.WORKSITE_COMPLIANT:
      default:
        icon = <EuroIcon />;
        color = purple;
        break;
    }
    return <ColorCube numberOrIcon={icon} color={color} size="2rem" opacity />;
  }, [worksiteType]);

  return (
    <div className="flex flex-1 p-4 justify-between items-start self-stretch border-borderGrey border bg-white rounded-default">
      <div className="flex flex-col items-start gap-2 ">
        {iconToUse}

        <div className="text-[#909090] text-xs font-normal leading-3">
          {`${t('dashboard.number_of')} ${
            WORKSITE_STATUS_DASHBOARD_LABELS[worksiteType]
          }`}
        </div>
      </div>
      <div className="text-right text-2xl leading-10">{`${numberWorksite}`}</div>
    </div>
  );
}

export default WorksiteQuantityCard;
