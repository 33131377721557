import { DocumentCardActions } from '@components/documents/DocumentCardActions';
import { ILinkedFile } from '../../types/globalTypes';

interface IDocumentCardListProps {
  document: ILinkedFile;
  isActive: boolean;
  setDocumentActive: (document: ILinkedFile) => void;
  status: string;
  onValidate?: (() => void) | undefined;
  onReject?: ((rejectionComment: string) => void) | undefined;
  onUpload?: (file: File, originalLinkedFile: ILinkedFile) => void;
}

function DocumentCardList({
  document,
  status,
  isActive,
  onUpload,
  setDocumentActive,
  onValidate,
  onReject,
}: IDocumentCardListProps) {
  return (
    <div className="w-full border-t p-4">
      <DocumentCardActions
        document={document}
        status={status as string}
        isActive={isActive}
        setDocumentActive={setDocumentActive}
        onUpload={onUpload}
        onValidate={onValidate}
        onReject={
          onReject
            ? (rejectionComment: string) => onReject(rejectionComment)
            : undefined
        }
      />
    </div>
  );
}

export { DocumentCardList };

DocumentCardList.defaultProps = {
  onUpload: undefined,
  onReject: undefined,
  onValidate: undefined,
};
