import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';

import { BeneficiariesList } from '@models/beneficiaries/components/BeneficiariesList';
import { TabBeneficiaryInfos } from '@models/beneficiaries/components/TabBeneficiaryInfos';

function Beneficiaries() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.beneficiaries')}`);
    refreshHeader();
  }, []);

  return (
    <div className="w-full items-center justify-center">
      {id ? <TabBeneficiaryInfos /> : <BeneficiariesList />}
    </div>
  );
}

export { Beneficiaries };
