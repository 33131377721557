import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import {
  IRequestTypes,
  IPayloadDataPostListRequests,
} from '@models/requests/types/requestTypes';
import { useContext, useRef, useState } from 'react';
import { getListRequests } from '@models/requests/apiRequest/requestRequests';
import { blueOpx } from '@assets/color';
import { useNavigate } from 'react-router-dom';
import { fakeRequestDatas } from '@models/dashboard/utils/fakeData';
import { WORKSITES_ROUTES_WITH_ID } from '@utils/routesUrls';
import { OneCardRequestInCard } from './OneCardRequestInCard';
import { GlobalContext } from '../../../../context/globalContext';

interface ListRequestsInCardProps {
  isInTabFixed?: boolean;
  isListMessages?: boolean;
  realData?: boolean;
}
function ListRequestsInCard({
  isInTabFixed,
  isListMessages,
  realData,
}: ListRequestsInCardProps) {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const {
    listRequests,
    updateDetailRequest,
    isLoadingListRequestOrChange,
    totalRequestsList,
    updateNumberPageRequestsList,
    numberPageRequestsList,
    requestOrChangeEnumInModal,
    updateListRequests,
    updateTotalRequestsList,
    listMessagesRequests,
    updateListMessagesRequests,
    updateTotalRequestsMessagesList,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { route } = useContext(GlobalContext);
  const navigate = useNavigate();

  let listToUse: IRequestTypes[] = [];

  if (!realData) {
    listToUse = fakeRequestDatas;
  } else {
    listToUse = isListMessages ? listMessagesRequests : listRequests;
  }

  const handleRequestClick = (request: IRequestTypes) => {
    if (
      !isInTabFixed &&
      request.relation_type === 'worksite' &&
      request.relation_id
    ) {
      navigate(WORKSITES_ROUTES_WITH_ID(request.relation_id).WORKSITES_VIEW);
    } else {
      updateDetailRequest(request);
    }
  };
  const infiniteScroll = () => {
    if (listInnerRef.current) {
      // eslint-disable-next-line no-shadow
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight < scrollHeight + 1 &&
        scrollTop + clientHeight > scrollHeight - 1 &&
        !isLoadingList &&
        totalRequestsList > listToUse.length
      ) {
        setIsLoadingList(true);
        const nextNumberPageRequestsList = numberPageRequestsList + 1;
        const dataPost: IPayloadDataPostListRequests = {
          pagination: {
            page: nextNumberPageRequestsList,
            perPage: 10,
          },
          status: 1,
        };
        updateNumberPageRequestsList(nextNumberPageRequestsList);
        if (route.to.includes('worksites')) {
          if (requestOrChangeEnumInModal === null) {
            if (/\d/.test(route.to)) {
              dataPost.relation_type = 'worksite';
              dataPost.relation_id = parseInt(route.to.split('/')[2], 10);
            } else {
              dataPost.relation_type = 'worksite';
              delete dataPost.relation_id;
            }
          } else {
            delete dataPost.relation_type;
            delete dataPost.relation_id;
          }
        }
        getListRequests(
          isListMessages ? updateListMessagesRequests : updateListRequests,
          isListMessages
            ? updateTotalRequestsMessagesList
            : updateTotalRequestsList,
          isListMessages ? listMessagesRequests : listRequests,
          dataPost,
          setIsLoadingList,
          isListMessages ? 2 : 1
        );
        listInnerRef.current.scrollTop = scrollTop - 2.5;
      }
    }
  };

  return (
    <div
      className={`flex flex-col ${isInTabFixed ? 'max-h-[60vh]' : 'h-full'}`}
    >
      {(isLoadingList || isLoadingListRequestOrChange) && (
        <div className="flex flex-col space-y-[.5rem]">
          <LoaderSkeleton backgroundColor={blueOpx} height=".2rem" />
        </div>
      )}
      <div
        onScroll={() => infiniteScroll()}
        ref={listInnerRef}
        className={`flex flex-col overflow-y-scroll overflow-x-hidden relative ${
          isInTabFixed ? 'max-h-[60vh]' : 'h-full'
        }`}
      >
        {!(
          (isLoadingList || isLoadingListRequestOrChange) &&
          numberPageRequestsList === 1
        ) &&
          listToUse.length > 0 &&
          listToUse[0].id !== 0 &&
          listToUse.map((request, index) => (
            <OneCardRequestInCard
              key={`one_card_request_in_card_${request.id}`}
              request={request}
              borderBottom
              animationDelay={index * 100}
              onClick={() => handleRequestClick(request)}
              displayAddress={!isInTabFixed}
            />
          ))}
      </div>
    </div>
  );
}

export { ListRequestsInCard };

ListRequestsInCard.defaultProps = {
  isInTabFixed: true,
  isListMessages: false,
  realData: true,
};
