import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { SubHeader } from '@components/subHeader/SubHeader';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import { AddCofrac } from '@models/cofrac/components/AddCofrac';
import { useContext, useEffect, useState } from 'react';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { HeaderContext } from '@context/headerContext';
import { CofracCard } from '@models/cofrac/components/CofracCard';
import { v4 } from 'uuid';
import { CofracCardSkeleton } from '@models/cofrac/components/CofracCardSkeleton';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getCofracList } from '@models/cofrac/apiRequests/cofracRequests';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { blueOpx } from '@assets/color';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';

interface CofracListProps {
  operations: IOperationType[];
}

function CofracList({ operations }: CofracListProps): JSX.Element {
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<any>();
  const [lotsCofrac, setLotsCofrac] = useState<ICofracType[]>([]);
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();
  const [resultsPerPage, setResultsPerPage] = useState<number>();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { t } = useTranslation();
  const { updateTitleHeader, updateTagHeader } = useContext(HeaderContext);

  const getData = async (
    page: number,
    perPage?: number,
    sortAndFilter?: ISortAndFilterType
  ) => {
    setLoading(true);
    await getCofracList(page, perPage, sortAndFilter).then((res) => {
      if (res.data) {
        setLotsCofrac(res.data);
        setPaginationData(res.meta);

        if (page > pagesDatas.length) {
          setPagesDatas([...pagesDatas, { data: res.data, meta: res.meta }]);
        }
      }
      setLoading(false);
    });
  };

  const pageAlreadyLoad = (page: number) => {
    setLotsCofrac(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  useEffect(() => {
    updateTitleHeader('COFRAC');
    updateTagHeader(undefined);
    getData(1, resultsPerPage);
  }, [sortAndFilterData, resultsPerPage]);

  const list =
    lotsCofrac.length > 0 ? (
      lotsCofrac.map((cofrac) => (
        <div key={v4()}>
          <CofracCard cofrac={cofrac} />
        </div>
      ))
    ) : (
      <div>{t('cofrac.no_items')}</div>
    );

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={<div />}
        rightPart={
          <>
            <SortAndFilter
              page="LISTE_COFRAC"
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterData)
              }
              onFilter={(filters) => filterList(filters, setSortAndFilterData)}
            />
            <ButtonOpx
              onClick={() => setModal(true)}
              type="primary"
              label={t('cofrac.new_cofrac')}
            />
          </>
        }
      />
      <div className="flex justify-between">
        <div className="mt-4">
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <ResultsPerPageButton
          resultsPerPage={resultsPerPage || 5}
          onChange={(value) => {
            setResultsPerPage(value);
            setPagesDatas([]);
            setCurrentPage(1);
            getData(1, value);
          }}
          options={[5, 10, 20, 50, 100, 250]}
          colorPagination={blueOpx}
        />
      </div>
      {!loading
        ? list
        : [...Array(paginationData?.per_page || 5)].map(() => (
            <div key={v4()}>
              <CofracCardSkeleton />
            </div>
          ))}
      {modal && (
        <AddCofrac eligibleOperations={operations} setModal={setModal} />
      )}

      <ListPagination
        getData={(page) => {
          if (page > pagesDatas.length) {
            getData(page, resultsPerPage);
          } else {
            pageAlreadyLoad(page);
          }
        }}
        paginationData={paginationData}
        resultsPerPage={resultsPerPage || 5}
        currentPage={currentPage}
      />
    </div>
  );
}

export { CofracList };
