/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';

import marker from '@assets/images/position_plain.svg';

import { Card } from '@components/Card';

import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ILatitudeLongitude } from '@models/partners/utils/types/subcontractorsType';

interface ICardLocalisationProps {
  address: string;
  loading: boolean;
}

function CardLocalisation({ address, loading }: ICardLocalisationProps) {
  const { t } = useTranslation();

  const [coordinates, setCoordinates] = useState<ILatitudeLongitude>({
    lat: 47.8588897,
    lng: 1.320041,
  });

  const mapStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit.station',
      stylers: [{ visibility: 'on' }],
    },
  ];

  const getMapOptions = {
    streetViewControl: false,
    scaleControl: true,
    zoomControl: true,
    fullscreenControl: true,
    dragginCursor: 'pointer',
    styles: mapStyles,
    disableDoubleClickZoom: true,
    minZoom: 5,
    maxZoom: 25,
    mapTypeControl: false,
    clickableIcons: false,
  };

  useEffect(() => {
    if (address.length > 5) {
      getGeocode({ address })
        .then((results) => {
          return getLatLng(results[0]);
        })
        .then((latLng) => setCoordinates(latLng))
        .catch(() => false);
    }
  }, [address]);

  return (
    <Card title={t('partners.localisation')} addClass="mt-6">
      <>
        {loading ? (
          <LoaderSkeleton height="100%" addClass="w-full aspect-[43/22]" />
        ) : (
          <GoogleMap
            options={getMapOptions}
            zoom={12}
            center={coordinates}
            mapContainerClassName="map-container"
            mapContainerStyle={{
              aspectRatio: '43/22',
              width: '100%',
              height: '35Opx',
            }}
          >
            <Marker position={coordinates} icon={marker} />
          </GoogleMap>
        )}
      </>
    </Card>
  );
}

export { CardLocalisation };
