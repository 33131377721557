import { useContext, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { parse, isBefore } from 'date-fns';

import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';
import { GlobalContext } from '@context/globalContext';

import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Modal } from '@components/Modal';
import {
  noPermissions,
  verifyPermission,
} from '@models/contracts/utils/contractHelper';
import { IMenuType } from '@models/contracts/utils/contractTypes';
import { GERER, VISUALISER } from '@models/contracts/utils/contractConstants';
import {
  getTabs,
  getInfosGeneral,
} from '@models/conventions/apiRequests/conventionRequests';
import { getTabContent } from '@models/conventions/utils/conventionHelper';
import { IConventionType } from '@models/conventions/utils/conventionTypes';
import { isInstaller } from '@utils/roles';
import { initDocumentActive } from '@utils/initialState';
import { lightBlue } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { CONTRACTS_ROUTES, CONTRACTS_ROUTES_WITH_ID } from '@utils/routesUrls';

function ConventionSheet() {
  const { t } = useTranslation();
  const { id: conventionId } = useParams();
  const navigate = useNavigate();

  const { updateTitleHeader, updateDisplayBackButtonHeader, updateTagHeader } =
    useContext(HeaderContext);
  const { user } = useContext(AuthContext);
  const { route, updateRoute, updateDocumentActive, globalEnum } =
    useContext(GlobalContext);

  const [convention, setConvention] = useState<IConventionType>();
  const [tabs, setTabs] = useState<string[]>([]);
  const [menus, setMenus] = useState<IMenuType[]>();
  const [selectedMenu, setSelectedMenu] = useState<IMenuType>();
  const [showModalUpdate, setShowModalUpdate] = useState<boolean>(false);

  const getData = async () => {
    const conventionInfos = (await getInfosGeneral(
      Number(conventionId)
    )) as IConventionType;
    setConvention(conventionInfos);

    const tabsList = await getTabs(t, conventionInfos);

    const tabLabels = tabsList?.map((tab) => tab.label);
    setTabs(tabLabels || []);
    setMenus(tabsList);
    setSelectedMenu(tabsList ? tabsList[0] : undefined);
  };

  const handleDuplicate = () => {
    setShowModalUpdate(false);
    navigate(CONTRACTS_ROUTES_WITH_ID(Number(conventionId)).CONTRACTS_EDIT, {
      state: { copy: true },
    });
  };

  useEffect(() => {
    getData();
  }, [conventionId]);

  useEffect(() => {
    if (route.to === route.from) {
      updateRoute({ to: route.to, from: CONTRACTS_ROUTES.CONTRACTS });
    }
    updateDocumentActive(initDocumentActive);
  }, []);

  const contractLabel = useMemo(() => {
    const isTripartite = !!convention?.beneficiary;
    return globalEnum.contract_type[isTripartite ? 5 : -1];
  }, [convention]);

  useEffect(() => {
    if (convention) {
      const partenaire = isInstaller(user)
        ? convention.from
        : convention.installer;

      updateTitleHeader(`${partenaire.company_name} - ${contractLabel}`);
      updateTagHeader(
        <Tag
          color={lightBlue}
          label={
            globalEnum.beneficiaries_type_tags[
              Number(convention.beneficiaries_type)
            ]
          }
        />
      );
      updateDisplayBackButtonHeader(true);
    }
  }, [convention, isInstaller, user]);

  const isExpired = useMemo(() => {
    if (!convention) return true;

    const end_date = parse(convention.end_date, 'yyyy-MM-dd', new Date());
    return isBefore(end_date, new Date());
  }, [convention]);

  if (user && !verifyPermission(user, [VISUALISER.CONVENTION])) {
    return noPermissions(t);
  }

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          <TabsLeftPart
            titlesList={tabs}
            onClick={(title) => {
              setSelectedMenu(menus?.filter((menu) => menu.label === title)[0]);
            }}
          />
        }
        rightPart={
          user && verifyPermission(user, [GERER.CONVENTION]) && !isExpired ? (
            <ButtonOpx
              label={t('convention.update.button')}
              onClick={() => setShowModalUpdate(true)}
              dataTestId="button_sub_header"
            />
          ) : undefined
        }
      />
      {selectedMenu && Number(selectedMenu.tab_id) > 0 && convention && (
        <div>
          {getTabContent(Number(selectedMenu?.tab_id), convention, getData)}
        </div>
      )}

      {showModalUpdate && (
        <Modal
          title={`${t('convention.update.title')} ${
            contractLabel.charAt(0).toLowerCase() + contractLabel.slice(1)
          }`}
          btnCancel
          onClickCancel={() => setShowModalUpdate(false)}
          textBtnConfirm={t('convention.update.confirm') || ''}
          onConfirmClick={handleDuplicate}
          backgroundTransparent
          sidebarVisible
          buttonsPosition="bottom"
        >
          <div className="pt-[1.5rem]">
            <p>{t('convention.update.modal_text')}</p>
            {convention?.model && ( //
              <p className="mt-3">
                {t('convention.update.convention_model_text', {
                  refModel: convention.model.reference,
                })}
              </p>
            )}
            <p className="mt-5">
              {`${`${t('convention.update.modal_question')} ${
                contractLabel.charAt(0).toLowerCase() + contractLabel.slice(1)
              }`}?`}
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export { ConventionSheet };
