import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { AUTH_ROUTES } from '@utils/routesUrls';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BACK_OPX_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Liste des endpoints pour lesquels les doublons sont autorisés
const ALLOWED_DUPLICATE_ENDPOINTS = ['/demands/list'];

// Ajout pour la gestion des requêtes doubles
const pendingRequests = new Map();

axiosClient.interceptors.request.use((config) => {
  let isAllowedDuplicateEndpoint = false;
  if (config && config.url) {
    isAllowedDuplicateEndpoint = ALLOWED_DUPLICATE_ENDPOINTS.some((endpoint) =>
      config?.url?.includes(endpoint)
    );
  }

  const token = axios.CancelToken.source();
  const modifiedConfig = { ...config, cancelToken: token.token };

  const key = [modifiedConfig.method, modifiedConfig.url].join('&');
  if (!isAllowedDuplicateEndpoint && pendingRequests.has(key)) {
    const cancelTokenSource = pendingRequests.get(key);
    cancelTokenSource.cancel('Duplicate request detected.');
  }
  pendingRequests.set(key, token);

  return modifiedConfig;
});

axiosClient.interceptors.response.use(
  (response) => {
    const key = [response.config.method, response.config.url].join('&');
    pendingRequests.delete(key);

    /* if (response.data.exception) {
      toast.error(`${response.data.exception} \n\n${response.data.message}`);
    } */
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) {
      if (error.response) {
        const { data, status } = error.response;
        if (
          data.errors !== undefined &&
          data.errors.includes('Unauthenticated.')
        ) {
          Cookies.remove('token');
          window.location.href = AUTH_ROUTES.LOGIN;
          toast.error(`Vous n'êtes pas autorisé à accéder à la plateforme.`);
        }
        console.log('Request made but the server responded with an error :', {
          data,
          status,
        });
      } else if (error.request) {
        console.log(
          'Request made but no response is received from the server :',
          error.request
        );
      } else {
        console.log(
          'Error occured while setting up the request :',
          error.message
        );
      }
    }

    const config = error.config;
    if (config) {
      const key = [config.method, config.url].join('&');
      pendingRequests.delete(key);
    }

    return Promise.reject(error);
  }
);

export { axiosClient };
