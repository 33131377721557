import { v4 } from 'uuid';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { LoaderKpiTab } from '@components/loaders/LoaderKpiTab';

function LoaderDeposit(): JSX.Element {
  return (
    <div className="flex w-full flex-col space-y-4">
      <div className="w-full flex items-center space-x-[1.5rem]">
        {[...Array(4)].map(() => (
          <div key={v4()} className="w-full">
            <LoaderKpiTab />
          </div>
        ))}
      </div>
      {[...Array(4)].map(() => (
        <div key={v4()} className="w-full">
          <LoaderDivSkeleton height="7.25rem" />
        </div>
      ))}
    </div>
  );
}

export { LoaderDeposit };
