import { Modal } from '@components/Modal';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@context/authContext';
import { InputText } from '@components/atomic/inputs/InputText';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { activatePasswordReset } from '@models/auth/apiRequests/authRequests';
import { getPasswordValidationSchema } from '@utils/validationSchemas';
import { yupResolver } from '@hookform/resolvers/yup';

interface ModalPasswordValidationProps {
  newPassword: string | undefined;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

function ModalPasswordValidation({
  newPassword,
  setShowModal,
}: ModalPasswordValidationProps): JSX.Element {
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(getPasswordValidationSchema(t)),
  });
  const { handleSubmit } = methods;

  const sendValidationCode = (data: FieldValues) => {
    const sendData = { ...data, new_password: newPassword };
    activatePasswordReset(sendData, setShowModal);
  };
  return (
    <div>
      <Modal
        title={`${t('auth.reset_pwd.validation_title')}`}
        backgroundTransparent
        sidebarVisible
        btnCancel
        onConfirmClick={handleSubmit(sendValidationCode)}
        textBtnConfirm={`${t('buttons.validate')}`}
        buttonsPosition="bottom"
        onClickCancel={() => setShowModal(false)}
      >
        <div className="mt-3">
          <p className="mb-3">
            {t('auth.reset_pwd.validation_message')}{' '}
            <span className="font-semibold">{user?.email || ''}</span>
          </p>
          <FormProvider {...methods}>
            <form>
              <InputText
                id="code"
                name="code"
                placeholder={`${t('auth.reset_pwd.validation_placeholder')}`}
                required
              />
            </form>
          </FormProvider>
        </div>
      </Modal>
    </div>
  );
}

export { ModalPasswordValidation };
