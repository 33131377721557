import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { beneficiaryIcons } from '@models/beneficiaries/components/view/InfosIcons';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { v4 } from 'uuid';
import { blueOpx } from '@assets/color';
import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import { GoogleMap, Marker } from '@react-google-maps/api';

interface BeneficiaryInfosProps {
  worksiteDatas: IWorksiteDetails;
}

function BeneficiaryInfos({ worksiteDatas }: BeneficiaryInfosProps) {
  const { beneficiary } = worksiteDatas;
  const { globalEnum, isMobile } = useContext(GlobalContext);

  // Initialisation de la position de la carte
  const mapPosition = {
    lat: Number(worksiteDatas.address?.latitude) || 46.227638,
    lng: Number(worksiteDatas.address?.longitude) || 2.213749,
  };

  // Création d'une fonction pour générer les icônes de bénéficiaire
  const generateBeneficiaryIcons = (icons: any[]) => {
    return icons.map((icon) => {
      if (!icon) return null;
      return (
        <InfosWithIcon
          key={v4()}
          loading={false}
          infos={[
            {
              title: icon.title,
              subtitle: icon.subtitle,
            },
          ]}
          icon={icon.icon || <div />}
          colorIcon={icon.icon ? blueOpx : 'transparent'}
          addClass={icon.addClass}
        />
      );
    });
  };

  const beneficiaryLabel = beneficiary.firstname
    ? `${beneficiary.firstname} ${beneficiary.lastname}`
    : beneficiary.company_name;

  return (
    <div
      className={
        isMobile
          ? 'w-full bg-white p-5 rounded-default border border-borderGrey'
          : 'min-h-screen'
      }
    >
      <div className="font-medium text-[1.5rem] leading-[2.25rem] px-5 py-10 border border-transparent border-b-borderGrey">
        {beneficiaryLabel}
      </div>
      <div>
        <div
          className={`border border-transparent border-b-borderGrey p-5 ${
            isMobile ? 'grid grid-cols-2' : ''
          }`}
        >
          {generateBeneficiaryIcons(
            beneficiaryIcons(worksiteDatas, globalEnum).top
          )}
        </div>
        <div className={`p-5 ${isMobile ? 'grid grid-cols-2' : ''}`}>
          {generateBeneficiaryIcons(
            beneficiaryIcons(worksiteDatas, globalEnum).bottom
          )}
        </div>
        {worksiteDatas.address?.latitude && (
          <div
            className={`${
              !isMobile ? 'absolute w-3/12' : 'relative w-full'
            } px-5  h-[15rem]`}
          >
            <GoogleMap id="map" center={mapPosition} zoom={15}>
              <Marker position={mapPosition} />
            </GoogleMap>
          </div>
        )}
      </div>
    </div>
  );
}

export { BeneficiaryInfos };
