import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';

function CardDesign() {
  return (
    <Card
      title="This is my card title"
      subtitle="Subtitle"
      actionButtons={
        <>
          <ButtonOpx label="Action 1" addClass="mr-[1rem]" />
          <ButtonOpx label="Action 2" />
        </>
      }
    >
      <p>This is the content of the card</p>
    </Card>
  );
}

export { CardDesign };
