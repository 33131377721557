import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/Modal';
import { ButtonOpx } from '@components/atomic/ButtonOpx';

interface BrandData {
  id: number;
  name: string;
  entity_id: number;
  deleted_at: string | null;
  materials: any[];
}

interface IDeleteModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  brandData: BrandData;
  deleteBrand: () => void;
  isLoading: boolean;
}
export function DeleteBrandModal({
  setShowModal,
  brandData,
  deleteBrand,
  isLoading,
}: IDeleteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('brand.delete_modal_title')}
      setIsModal={setShowModal}
      backgroundTransparent
      lightHeader
      isLoading={isLoading}
    >
      <>
        <p>
          {t('brand.delete_modal_text', {
            reference: brandData?.name || '',
          })}
        </p>
        <div className="flex items-center justify-end mt-6">
          <ButtonOpx
            onClick={() => setShowModal(false)}
            label={t('buttons.cancel')}
            type="secondary"
            addClass="!border-transparent"
          />
          <ButtonOpx
            onClick={deleteBrand}
            label={t('buttons.confirm')}
            type="primary"
            addClass="!border-transparent"
            dataTestId="confirm-delete-certification"
          />
        </div>
      </>
    </Modal>
  );
}
