import { initialSubcontractor } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import {
  ISubcontractors,
  IWorksiteOperation,
  IWorksiteSuboperation,
} from '@models/worksiteCreation/utils/types/worksitesType';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { convertHexToRGBA } from '@utils/functions';
import { red } from '@assets/color';
import { getSubcontractorsList } from '@models/partners/apiRequests/partnersRequest';
import {
  StepsWorksiteCreationEnum,
  WORKSITE_CREATION_STATUS,
} from '@models/worksiteCreation/utils/enums';
import {
  arraysAreSame,
  getNextStep,
} from '@models/worksiteCreation/utils/functions';
import { OperationTypeEnum } from '@utils/enums';
import { useTranslation } from 'react-i18next';
import { OneOperationSubcontractor } from './OneOperationSubcontractor';
import { sendSubcontractorToWorksiteOperations } from '../../../apiRequests/worksiteCreationRequests';

function StepSubcontractor() {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { t } = useTranslation();

  const {
    worksiteDatas,
    arraySubcontractorDataPost,
    updateWorksiteDatas,
    updateIsLoading,
    updateStepActiveWorksiteCreation,
    worksiteOperationType,
    updateDisabledNextButton,
    workflowSidebar,
    stepActiveWorksiteCreation,
    readOnly,
    nextStepWorksiteCreation,
  } = useContext(WorksiteCreationContext);

  const { worksites_operations: worksitesOperations } = worksiteDatas;

  const [error, setError] = useState<string>('');
  const [listSubcontractors, setListSubcontractors] = useState<
    ISubcontractors[]
  >([initialSubcontractor]);

  const getList = async (pageParam?: number) => {
    const page = pageParam || 1;

    const resList = await getSubcontractorsList({
      page,
      perPage: 100,
      worksiteId: worksiteDatas.id,
    });

    if (resList) {
      setListSubcontractors(resList.data);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const initialData = useMemo(() => {
    return arraySubcontractorDataPost || [];
  }, []);

  const noChange = useMemo(() => {
    if (workflowSidebar?.creation.find((step) => step.value === 6)) {
      return false;
    }

    return arraysAreSame(initialData, arraySubcontractorDataPost || [], 'id');
  }, [arraySubcontractorDataPost, workflowSidebar]);

  const operations: IWorksiteOperation[] | IWorksiteSuboperation[] =
    useMemo(() => {
      if (
        worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION &&
        worksitesOperations[0] &&
        worksitesOperations[0].suboperations
      ) {
        return worksitesOperations[0].suboperations;
      }
      return worksitesOperations;
    }, [worksiteDatas, worksiteOperationType]);

  const onSubmit = async () => {
    if (readOnly) nextStepWorksiteCreation();
    else {
      const payload =
        arraySubcontractorDataPost && arraySubcontractorDataPost.length > 0
          ? arraySubcontractorDataPost
          : [];

      let canChangeStep = true;

      if (
        !noChange ||
        worksiteDatas.creation_status ===
          WORKSITE_CREATION_STATUS.CREATION_SUBCONSTRACTOR
      ) {
        const res = await sendSubcontractorToWorksiteOperations(
          worksiteDatas.id,
          updateIsLoading,
          setError,
          payload,
          updateWorksiteDatas
        );

        if (!res) canChangeStep = false;
      }

      if (canChangeStep) {
        const nextStep = getNextStep(
          workflowSidebar,
          stepActiveWorksiteCreation
        );

        updateStepActiveWorksiteCreation(
          nextStep ? nextStep.value : StepsWorksiteCreationEnum.DOCUMENTS
        );
      }
    }
  };

  useEffect(() => {
    const btnIsDisabled = arraySubcontractorDataPost?.some(
      (element) =>
        !element.id || !element.toId || element.toId === 0 || element.id === 0
    );

    updateDisabledNextButton(!!btnIsDisabled);
  }, [arraySubcontractorDataPost]);

  return (
    <FormProvider {...methods}>
      <form
        id="sendSubcontractorToWorksite"
        className="pt-[1.5rem]"
        onSubmit={
          handleSubmit
            ? handleSubmit(onSubmit as (data: FieldValues) => void)
            : undefined
        }
        action="sendSubcontractorToWorksite"
      >
        <p className="font-medium">
          {t(
            'worksite_creation.create_worksite.subcontractor.is_subcontractor_exist'
          )}
        </p>
        {operations.map((operation) => {
          return (
            <OneOperationSubcontractor
              key={operation.id}
              operation={operation}
              listSubcontractors={listSubcontractors}
              getList={() => getList()}
            />
          );
        })}
        {error !== '' && (
          <p
            className="text-red p-[.5rem] rounded-default mt-[1rem]"
            style={{ backgroundColor: convertHexToRGBA(red, 0.1) }}
          >
            {error}
          </p>
        )}
      </form>
    </FormProvider>
  );
}

export { StepSubcontractor };
