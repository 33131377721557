import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import {
  IOperationMaterial,
  IMaterial,
  IPayloadMaterials,
} from '@models/worksiteCreation/utils/types/worksitesType';
import {
  IBrandMaterials,
  IScopUnit,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  initialMark,
  initialMaterial,
  materialInitialState,
} from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import BrandSelector from './BrandSelector';
import ProductSelector from './ProductSelector';

interface INewOneOperationMaterialProps {
  operation: IOperationMaterial;
  unitIndex?: number;
  setUnitsData?: React.Dispatch<React.SetStateAction<IScopUnit[]>>;
  listBrands: { name: string; id: number }[];
  isLoadingBrands: boolean;
  initialValues: { mark: string; reference: string };
  manualScop?: boolean;
}

function OneOperationMaterial({
  operation,
  unitIndex,
  setUnitsData,
  listBrands,
  isLoadingBrands,
  initialValues,
  manualScop,
}: INewOneOperationMaterialProps) {
  const { arrayMaterialsDataPost, updateArrayMaterialsDataPost } = useContext(
    WorksiteCreationContext
  );
  const [listMaterials, setListMaterials] = useState<IMaterial[]>([
    initialMaterial,
  ]);
  const [materialActive, setMaterialActive] =
    useState<IMaterial>(initialMaterial);
  const [newMaterial, setNewMaterial] = useState<{
    mark: string;
    reference: string;
  }>(materialInitialState.newMaterial);
  const [brandActive, setBrandActive] = useState<IBrandMaterials>({
    name: '',
    id: 0,
  });
  const [resetSignal, setResetSignal] = useState<number>(0);

  const methods = useForm();
  const { setValue } = methods;

  const handleBrandSelected = (brand: IBrandMaterials) => {
    setBrandActive(brand);
  };

  const handleProductSelected = (material: IMaterial) => {
    setMaterialActive(material);
    if (setUnitsData && unitIndex !== undefined) {
      setUnitsData((prevUnitsData) => {
        const updatedUnits = [...prevUnitsData];
        updatedUnits[unitIndex] = {
          ...updatedUnits[unitIndex],
          details: material as any,
          scop:
            material.caracteristics.scop !== ''
              ? material.caracteristics.scop
              : '10',
          power:
            material.caracteristics.puissance_nominale_calorifique !== ''
              ? material.caracteristics.puissance_nominale_calorifique
              : '10',
          markMaterial: `${material.name} - ${material.caracteristics.mark.name}`,
        };
        return updatedUnits;
      });
    }
  };

  const resetBrands = () => {
    setResetSignal((prev) => prev + 1);
    setBrandActive(initialMark);
    setListMaterials([initialMaterial]);
  };

  useEffect(() => {
    if (manualScop) {
      setValue('mark', initialValues.mark);
      setValue('reference', initialValues.reference);
      setNewMaterial({
        mark: initialValues.mark,
        reference: initialValues.reference,
      });
    }
  }, [manualScop]);

  useEffect(() => {
    if (operation.material) {
      const active = listMaterials.find(
        (elt) => elt.material_id === operation.material?.material_id
      );
      if (materialActive.id === 0) {
        setMaterialActive(active || initialMaterial);
      }
    }
  }, [operation, listMaterials, materialActive]);

  useEffect(() => {
    if (
      setUnitsData &&
      newMaterial.mark !== '' &&
      newMaterial.reference !== ''
    ) {
      setUnitsData((prevState) =>
        prevState.map((unit, i) => {
          if (i === unitIndex) {
            return {
              ...unit,
              markMaterial: `${newMaterial.reference} - ${newMaterial.mark}`,
              manual: true,
            };
          }

          return unit;
        })
      );
    }
  }, [newMaterial]);

  useEffect(() => {
    let materialSameOperation = arrayMaterialsDataPost?.find(
      (material) => material.worksite_operation_id === operation.id
    );

    if (materialSameOperation) {
      materialSameOperation = {
        ...materialSameOperation,
        ...materialActive,
        caracteristics: JSON.stringify({
          ...materialActive.caracteristics,
          reference: {
            id: materialActive.id,
            name: materialActive.name,
          },
        }),
      };
      if (arrayMaterialsDataPost) {
        const newArrayMaterialsDataPost = arrayMaterialsDataPost.map(
          (material) => {
            if (material.worksite_operation_id === operation.id) {
              return materialSameOperation as IPayloadMaterials;
            }
            return material;
          }
        );
        updateArrayMaterialsDataPost(newArrayMaterialsDataPost);
      }
    }
  }, [materialActive]);

  return (
    <FormProvider {...methods}>
      <div className={operation.description ? 'mt-[1.5rem]' : ''}>
        {operation.description && (
          <p className="font-medium">{operation.description}</p>
        )}
        <div
          className={`${operation.description ? 'mt-[1.5rem]' : ''} flex gap-5`}
        >
          <BrandSelector
            listBrands={listBrands}
            isLoadingBrands={isLoadingBrands}
            resetBrands={resetBrands}
            onBrandSelected={handleBrandSelected}
          />
          <ProductSelector
            brandActive={brandActive}
            operationCode={operation.code}
            setListMaterials={setListMaterials}
            onProductSelected={handleProductSelected}
            listMaterials={listMaterials}
            setMaterialActive={setMaterialActive}
            searchInternal={false}
            setNewMaterial={setNewMaterial}
            resetSignal={resetSignal}
          />
        </div>
      </div>
    </FormProvider>
  );
}

export { OneOperationMaterial };

OneOperationMaterial.defaultProps = {
  unitIndex: undefined,
  setUnitsData: undefined,
  manualScop: false,
};
