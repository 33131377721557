import { InputTypes } from '@utils/utils';
import { InputText } from '@components/atomic/inputs/InputText';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { dateToDDMMYYY, toAPIDateStr } from '@utils/format';
import { useFormContext } from 'react-hook-form';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { useTranslation } from 'react-i18next';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { useEffect, useMemo } from 'react';
import { IInputType } from '../../../types/globalTypes';

interface DynamicInputEditProps {
  isEditMode: boolean;
  inputType: IInputType;
  name: string;
  label: string;
  initialValue?: any;
  placeholder?: string;
  additionalOnChange?: (e: any) => void;
  required?: boolean;
  options?: string[];
  dataTestId?: string;
  addClass?: string;
  icon?: JSX.Element;
  maxLength?: number;
}

function DynamicInputEdit({
  isEditMode,
  inputType,
  name,
  label,
  initialValue,
  placeholder,
  additionalOnChange,
  required,
  options,
  dataTestId,
  addClass,
  icon,
  maxLength,
}: DynamicInputEditProps) {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const {
    setValue,
    watch,
    formState: { errors },
    register,
    unregister,
    setError,
    clearErrors,
  } = formContext;
  const formValues = watch();

  const isEfficiencyInput = name.includes('efficiency');
  const isCoveragaRateInput = name.includes('coverageRate');
  const idOperation = name.split('_')[0];

  const readOnlyValue = useMemo(() => {
    if (inputType === InputTypes.DATE) {
      return dateToDDMMYYY(initialValue);
    }
    if (inputType === InputTypes.BOOL) {
      if (initialValue === 'false') {
        return t('settings.no');
      }
      if (initialValue === 'true') {
        return t('settings.yes');
      }
      if (initialValue === null) {
        return '';
      }
    }
    return initialValue;
  }, [initialValue]);

  const defaultMultipleCheckedValues = useMemo(() => {
    if (!initialValue && typeof initialValue !== 'object') {
      return undefined;
    }
    return initialValue;
  }, [initialValue]);

  const defaultSelectedValue = useMemo(() => {
    if (!initialValue || typeof initialValue !== 'string') {
      return undefined;
    }
    return initialValue;
  }, [initialValue]);

  const defaultBoolValue = useMemo(() => {
    if (initialValue === undefined) {
      return undefined;
    }
    if (initialValue === 'false') {
      return t('settings.no');
    }
    if (initialValue === 'true') {
      return t('settings.yes');
    }
    if (initialValue === null) {
      return '';
    }
    return initialValue;
  }, [initialValue]);

  const validDefaultDate = useMemo(() => {
    // Vérifie si initialValue correspond au format aaaa-mm-dd
    const isIsoFormat =
      initialValue && /^\d{4}-\d{2}-\d{2}$/.test(initialValue);
    if (isIsoFormat) {
      return dateToDDMMYYY(initialValue);
    }

    // Vérifie si initialValue correspond au format dd/mm/aaaa
    const isValidDate =
      initialValue && /^\d{2}\/\d{2}\/\d{4}$/.test(initialValue);
    if (isValidDate) {
      return initialValue;
    }
    return undefined;
  }, [initialValue]);

  const validDefaultNumber = useMemo(() => {
    const numberValue = Number(initialValue); // Convertit initialValue en nombre (ou NaN si la conversion échoue)
    if (!Number.isNaN(numberValue) && Number.isFinite(numberValue)) {
      return String(numberValue);
    }
    return undefined;
  }, [initialValue]);

  const defaultTextValue = useMemo(() => {
    if (initialValue === undefined) {
      return undefined;
    }
    return initialValue;
  }, [initialValue]);

  // Fonction utilitaire pour accéder à une propriété imbriquée via un chemin
  const getValueByPath = () => {
    return name.split('.').reduce((acc, part) => acc && acc[part], formValues);
  };

  useEffect(() => {
    // Utilise la fonction utilitaire pour obtenir la valeur actuelle
    const currentValue = getValueByPath();

    if (!isEditMode && currentValue === undefined) {
      switch (inputType) {
        case InputTypes.MULTIPLE_SELECT:
          if (initialValue && typeof initialValue === 'object') {
            setValue(name, initialValue);
          }
          break;
        case InputTypes.NUMBER:
          if (
            !Number.isNaN(Number(initialValue)) &&
            Number.isFinite(Number(initialValue))
          ) {
            setValue(name, String(Number(initialValue)));
          }
          break;
        case InputTypes.BOOL:
        case InputTypes.SELECT:
        case InputTypes.TEXT:
        default:
          if (initialValue && typeof initialValue === 'string') {
            setValue(name, initialValue);
          }
          break;
      }
    }
  }, [inputType, initialValue, name, setValue, formValues]);

  useEffect(() => {
    // Enregistre le champ avec la règle 'required' si spécifiée
    register(name, {
      required: required ? t('forms.required_error_message') || '' : false,
      min: isCoveragaRateInput
        ? {
            value: 70,
            message: t('worksites.editable_fields.error_coverage_rate'),
          }
        : undefined,
    });

    // Retourne une fonction de nettoyage pour désenregistrer le champ lors du démontage du composant
    return () => {
      unregister(name);
    };
  }, [name, required, register, unregister]);

  useEffect(() => {
    if ((!isEfficiencyInput && !isCoveragaRateInput) || !isEditMode) return;

    if (isEfficiencyInput) {
      const temperaturePacKey = `${idOperation}_temperaturePac`;
      const temperaturePac = formValues[temperaturePacKey];
      const efficiency = formValues[name];

      if (temperaturePac && efficiency) {
        let error = null;
        if (
          temperaturePac === 'moyenne ou haute température' &&
          Number(efficiency) < 111
        ) {
          error = t('worksites.editable_fields.error_efficiency_111');
        } else if (
          temperaturePac === 'basse température' &&
          Number(efficiency) < 126
        ) {
          error = t('worksites.editable_fields.error_efficiency_126');
        }

        if (error && (!errors[name] || errors[name]?.message !== error)) {
          setError(name, { type: 'manual', message: error });
        } else if (errors[name] && errors[name]?.message !== error) {
          clearErrors(name);
        }
      }
    }
  }, [
    isEfficiencyInput,
    formValues[`${idOperation}_temperaturePac`],
    formValues[name],
    idOperation,
    name,
    isEditMode,
  ]);

  if (isEditMode) {
    switch (inputType) {
      case InputTypes.MULTIPLE_SELECT:
        return (
          <InputSelect
            label={label}
            dataArrayString={options || []}
            isMultipleSelect
            onSelect={(value) => {
              if (formValues[name]?.includes(value)) {
                setValue(
                  name,
                  formValues[name].filter((item: string) => item !== value)
                );
              } else {
                setValue(name, [...formValues[name], value]);
              }
            }}
            placeholder={placeholder || ''}
            defaultChecked={defaultMultipleCheckedValues}
            required={required || false}
            error={!!errors[name]}
            textError={(errors[name]?.message as string) || undefined}
            dataTestIdSelect={dataTestId || 'input_select_dynamic'}
            addClass={addClass}
          />
        );
      case InputTypes.SELECT:
        return (
          <InputSelect
            label={label}
            dataArrayString={options || []}
            onSelect={(value) =>
              setValue(name, value, { shouldValidate: true })
            }
            placeholder={placeholder || ''}
            defaultSelected={defaultSelectedValue}
            required={required || false}
            error={!!errors[name]}
            textError={(errors[name]?.message as string) || undefined}
            dataTestIdSelect={dataTestId || 'input_select_dynamic'}
            addClass={addClass}
          />
        );
      case InputTypes.BOOL:
        return (
          <InputSelect
            label={label}
            dataArrayString={[t('settings.yes'), t('settings.no'), '']}
            onSelect={(value) =>
              setValue(name, value === t('settings.yes') ? 'true' : 'false')
            }
            placeholder={placeholder || ''}
            defaultSelected={defaultBoolValue}
            required={required || false}
            dataTestIdSelect={dataTestId || 'input_select_dynamic'}
            error={!!errors[name]}
            textError={(errors[name]?.message as string) || undefined}
            addClass={addClass}
          />
        );
      case InputTypes.DATE:
        return (
          <DatePicker
            label={label}
            required={required || false}
            onChangeDate={(value: string) =>
              setValue(name, toAPIDateStr(value), { shouldValidate: true })
            }
            noDefaultDate={!validDefaultDate}
            defaultDate={validDefaultDate}
            dataTestId={dataTestId || 'input_date_dynamic'}
            error={!!errors[name]}
            textError={(errors[name]?.message as string) || undefined}
            addClass={addClass}
          />
        );
      case InputTypes.NUMBER:
        return (
          <InputText
            type="text"
            id={name}
            name={name}
            label={label}
            defaultValue={validDefaultNumber}
            placeholder={placeholder || ''}
            required={required}
            typeNumber
            onChange={(e) => {
              if (additionalOnChange) {
                additionalOnChange(e);
              }
            }}
            addClass={addClass}
            dataTestId={dataTestId || 'input_number_dynamic'}
            maxLength={maxLength}
          />
        );
      case InputTypes.TEXT:
      default:
        return (
          <InputText
            type="text"
            id={name}
            name={name}
            label={label}
            defaultValue={defaultTextValue}
            placeholder={placeholder || ''}
            required={required}
            onChange={(e) => {
              if (additionalOnChange) {
                additionalOnChange(e);
              }
            }}
            addClass={addClass}
            dataTestId={dataTestId || 'input_text_dynamic'}
            maxLength={maxLength}
          />
        );
    }
  }
  return (
    <InfosWithIcon
      spaceLeft
      infos={[
        {
          title: label,
          subtitle: readOnlyValue || '-',
        },
      ]}
      addClass={addClass}
      icon={icon}
    />
  );
}

export default DynamicInputEdit;

DynamicInputEdit.defaultProps = {
  initialValue: undefined,
  placeholder: '',
  additionalOnChange: undefined,
  required: false,
  options: undefined,
  dataTestId: undefined,
  addClass: undefined,
  icon: undefined,
  maxLength: undefined,
};
