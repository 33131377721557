/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { Tag } from '@components/atomic/Tag';
import { ListPagination } from '@components/atomic/pagination/ListPagination';

import { blueSecondary, green, red, textGrey } from '@assets/color';
import { dateToDDMMYYY } from '@utils/format';
import { convertKiloToMega } from '@utils/functions';

import { useNavigate } from 'react-router-dom';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { DEPOSITS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { DeliveryOpSekeleton } from './DeliveryOpSekeleton';
import { getDeliveries } from '../apiRequests/depositRequests';

interface IListDeliveryOpsProps {
  order: ISortAndFilterType;
}

function ListDeliveryOps(props: IListDeliveryOpsProps) {
  const { order } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { globalEnum } = useContext(GlobalContext);

  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([]);

  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const getData = async (page: number) => {
    setIsloading(true);

    const res = await getDeliveries(page, order);

    setData(res.data);
    setPaginationData(res.meta);

    if (res.meta?.current_page > pagesDatas.length) {
      setPagesDatas([...pagesDatas, { data: res.data, meta: res.meta }]);
    }
    setIsloading(false);
  };

  const renderElement = (item: any) => {
    const title = `Livraison ${item.reference}`;
    const textReference = `Ref ${item.id}`;

    const textMandatory = `${t('deposits.representative')}: ${
      item.created_by.company_name
    }`;
    const nbOperations = item.count_operations;
    const textOperations = `${nbOperations} ${t(
      'partners.deposits.operation'
    )}${nbOperations > 1 ? 's' : ''}`;

    const statusText = (globalEnum.contract_lot_status as any)[
      String(item.status)
    ];
    const statusColor = item
      ? [1, 2, 3].includes(item.status)
        ? blueSecondary
        : item.status === 4
        ? red
        : green
      : textGrey;

    const nbOperationRemoved = item.invalid_operations;
    const textOperationRemoved = `${nbOperationRemoved} ${t(
      'partners.deposits.operation_maj'
    )}${nbOperationRemoved > 1 ? 's' : ''} ${t('contractMandant.removed')}${
      nbOperationRemoved > 1 ? 's' : ''
    }`;

    const dateValue = dateToDDMMYYY(item.updated_at.split(' ')[0]);

    const volumeC = `${convertKiloToMega(item.volume.classique)} MWhc`;
    const volumeP = `${convertKiloToMega(item.volume.precaire)} MWhc`;
    const volumeTotal = `${convertKiloToMega(item.volume.total)} MWhc`;

    return (
      <div
        onClick={() => navigate(DEPOSITS_ROUTES_WITH_ID(item.id).LOT_VIEW)}
        className="cursor-pointer w-full bg-white border border-borderGrey rounded-default flex items-center justify-center flex-wrap my-4"
      >
        <div
          className="w-full md:w-[30%] md:border-r md:border-t-0 border-borderGrey p-[1rem]"
          onClick={() =>
            console.log(
              'Affiche l’écran Livraison pour contrôle (vue Pilotage)'
            )
          }
        >
          <div className="flex items-center">
            <div className="mr-[1rem] text-[1.25rem] leading-8 text-darkBlue font-medium">
              {title}
            </div>
            <Tag
              color={textGrey}
              label={textReference}
              addClass="h-fit mr-[1rem]"
            />
          </div>
        </div>
        <div className="w-full md:w-[70%] border-t md:border-t-0 border-borderGrey p-[1rem]">
          <div className="flex items-center justify-end">
            <span className="mr-7 text-[1rem]">{textMandatory}</span>
            <span className="font-medium text-[1rem]">{textOperations}</span>
          </div>
        </div>
        <div className="w-full p-[1rem] border-t border-borderGrey flex flex-justify-between flex-wrap">
          <div className="w-full md:w-[50%]">
            <div className="flex items-center">
              <Tag label={statusText} color={statusColor} addClass="mr-3" />
              {nbOperationRemoved > 0 && (
                <Tag label={textOperationRemoved} color={red} addClass="mr-3" />
              )}
              <span className="mr-3 text-[0.875rem]">{`${t(
                'partners.deposits.last_update'
              )}: ${dateValue}`}</span>
            </div>
          </div>
          <div className="w-full md:w-[50%] flex md:justify-end">
            <span className="mr-3 text-[0.875rem]">
              {`${t('partners.deposits.volume_precarity')}:`}
              <span className="ml-1 font-medium text-purple">{volumeP}</span>
            </span>
            <span className="mr-3 text-[0.875rem]">
              {`${t('partners.deposits.volume_classic')}:`}
              <span className="ml-1 font-medium text-blueOpx">{volumeC}</span>
            </span>
            <span className="text-[0.875rem]">
              {`${t('partners.deposits.total_volume')}:`}
              <span className="ml-1 font-medium">{volumeTotal}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <div>
      {isLoading && <DeliveryOpSekeleton />}
      {!isLoading &&
        data.length > 0 &&
        data.map((item) => <div key={v4()}>{renderElement(item)}</div>)}
      {!isLoading && data.length === 0 && (
        <p className="text-textGrey">{t('contract.association.no_items')}</p>
      )}
      {!isLoading &&
        data.length > 0 &&
        paginationData &&
        Number(paginationData.last_page) > 1 && (
          <ListPagination
            getData={(page) => {
              if (page > pagesDatas.length) {
                getData(page);
              } else {
                pageAlreadyLoad(page);
              }
            }}
            paginationData={paginationData}
          />
        )}
    </div>
  );
}

export { ListDeliveryOps };
