import { borderGrey } from '@assets/color';

interface ILoaderSkeletonProps {
  height: string;
  addClass?: string;
  backgroundColor?: string;
}
function LoaderSkeleton({
  height,
  addClass,
  backgroundColor,
}: ILoaderSkeletonProps) {
  return (
    <div
      className={[
        'bg-white flex flex-col w-full space-y-[1rem] h-auto rounded-md rounded-default ',
        addClass,
      ].join(' ')}
    >
      <div
        style={{
          height,
          backgroundColor: backgroundColor || borderGrey,
          opacity: backgroundColor ? 0.8 : 0.5,
        }}
        className="skeleton-loader-anim w-full"
      />
    </div>
  );
}

LoaderSkeleton.defaultProps = {
  addClass: '',
  backgroundColor: '',
};
export { LoaderSkeleton };
