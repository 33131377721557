import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

function DepositCardSkeleton() {
  return (
    <div className="w-full bg-white border border-borderGrey rounded-default flex items-center justify-center flex-wrap my-4">
      <div className="w-full md:w-[30%] md:border-r md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex flex-wrap items-center justify-start mb-[0.875rem]">
          <LoaderSkeleton height="1.5rem" addClass="w-[10rem] md:mr-[0.5rem]" />
          <LoaderSkeleton height="1.5rem" addClass="w-[3rem]" />
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <LoaderSkeleton height="1.5rem" addClass="w-[35%] md:mr-[0.5rem]" />
          <LoaderSkeleton height="1.5rem" addClass="w-[35%] md:mr-[0.5rem]" />
        </div>
      </div>
      <div className="w-full md:w-[70%] border-t md:border-l md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex justify-end items-center">
          <LoaderSkeleton height="1.5rem" addClass="w-[5rem] md:mr-[0.5rem]" />
          <LoaderSkeleton height="1.5rem" addClass="w-[3rem]" />
        </div>
        <div className="my-[1rem]">
          <LoaderSkeleton height="2.5rem" addClass="w-full" />
        </div>
      </div>
      <div className="w-full p-[1rem] border-t border-borderGrey flex flex-justify-between flex-wrap">
        <div className="w-full md:w-[70%]">
          <div className="flex items-center">
            <LoaderSkeleton
              height="1.5rem"
              addClass="w-[10rem] md:mr-[0.5rem]"
            />
            <LoaderSkeleton
              height="1.5rem"
              addClass="w-[10rem] md:mr-[0.5rem]"
            />
            <LoaderSkeleton height="1.5rem" addClass="w-[10rem]" />
          </div>
        </div>
        <div className="w-full md:w-[30%]">
          <div className="flex justify-start md:justify-end">
            <LoaderSkeleton
              height="1.5rem"
              addClass="w-[10rem] md:mr-[0.5rem]"
            />
            <LoaderSkeleton height="1.5rem" addClass="w-[10rem]" />
          </div>
        </div>
      </div>
    </div>
  );
}

export { DepositCardSkeleton };
