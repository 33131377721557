/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Tag } from '@components/atomic/Tag';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import { getAllUserBrand } from '@models/materials/apiRequests/materialsRequests';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import { iconBlue } from '@assets/color';
import { useNavigate } from 'react-router-dom';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AddFatIcon } from '@assets/images/svgComponents';
import { ModalAddBrand } from '@models/materials/components/ModalAddBrand';
import { IBrandDetails } from '@models/materials/utils/materialTypes';
import { SETTINGS_ROUTES_WITH_ID } from '@utils/routesUrls';

function Brands() {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const [brands, setBrands] = useState<IBrandDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showNewBrandModal, setShowNewBrandModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const refetchBrands = async () => {
    updateTitleHeader(`${t('sidebar.materials')}`);
    getAllUserBrand().then((r) => setBrands(r));
    refreshHeader(false);
    setLoading(false);
  };

  useEffect(() => {
    refetchBrands();
  }, []);

  const columnHelper = createColumnHelper<IBrandDetails>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => 'id',
      }),
      columnHelper.accessor('name', {
        header: () => t('brand.own'),
      }),
      columnHelper.accessor('materials', {
        header: () => '',
        cell: (info) => {
          let label = `${info.getValue().length} ${String(
            t('brand.materials')
          ).toLowerCase()}`;

          if (info.getValue().length <= 1) {
            label = `${info.getValue().length} ${String(
              t('brand.material')
            ).toLowerCase()}`;
          }
          return <Tag color={iconBlue} label={label} />;
        },
      }),
    ],
    [brands]
  );

  if (brands.length === 0) {
    return (
      <div className="w-full flex flex-col pt-6">
        <div className="flex flex-row-reverse w-full">
          <div className="flex flex-col lg:flex-row gap-2">
            <ButtonOpx
              label={t('brand.new')}
              icon={<AddFatIcon />}
              type="primary"
              onClick={() => setShowNewBrandModal(true)}
            />
          </div>
        </div>
        <div className="list_noElements flex items-center justify-center h-36 text-danger">
          {t('list.no_result')}
        </div>
        {showNewBrandModal && (
          <ModalAddBrand
            setShowModal={setShowNewBrandModal}
            refetch={refetchBrands}
          />
        )}
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-row-reverse py-[1.5rem] gap-5">
        <ButtonOpx
          label={t('brand.new')}
          icon={<AddFatIcon />}
          type="primary"
          onClick={() => setShowNewBrandModal(true)}
        />
      </div>
      <div className="w-full flex text-neutral-400 justify-center p-2">
        {brands.length > 0 && (
          <ListTable
            loading={loading}
            columns={columns as IColumn[]}
            data={brands}
            callBackOnRowClick={(e: IRow) => {
              const rowData = e.original as IBrandDetails;
              navigate(
                SETTINGS_ROUTES_WITH_ID(rowData.id).SETTINGS_BRANDS_VIEW
              );
            }}
            addClass="w-full"
            hiddenColumns={['id']}
          />
        )}
      </div>
      {showNewBrandModal && (
        <ModalAddBrand
          setShowModal={setShowNewBrandModal}
          refetch={refetchBrands}
        />
      )}
    </div>
  );
}

export { Brands };
