/* eslint-disable no-nested-ternary */
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { Loader } from '@components/atomic/Loader';
import { CardDeposits } from '@models/partners/components/deposits/CardDeposits';

import { getPartnerDeposits } from '@models/partners/apiRequests/partnersFormRequest';
import { IPartnerDeposit } from '@models/partners/utils/types/partnersType';

function TabDeposits() {
  const { entite, id } = useParams();
  const { t } = useTranslation();
  const { partnersMenu } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<IPartnerDeposit[]>([]);

  const getData = async () => {
    setLoading(true);
    const iEntityType = partnersMenu.findIndex((menu) =>
      menu.link?.includes(String(entite || ''))
    );

    const resDeposits = await getPartnerDeposits({
      entityId: Number(id),
      entityType:
        iEntityType !== -1 ? Number(partnersMenu[iEntityType].entityType) : 0,
    });
    if (resDeposits) {
      setData(resDeposits);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-full items-center justify-center p-5">
      {loading ? (
        <Loader />
      ) : data.length > 0 ? (
        data.map((depotItem: IPartnerDeposit) => (
          <CardDeposits key={v4()} data={depotItem} />
        ))
      ) : (
        <div className="list_noElements flex items-center justify-center h-36 text-danger">
          {t('list.no_result')}
        </div>
      )}
    </div>
  );
}

export { TabDeposits };
