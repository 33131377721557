/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICustomField } from '@models/settings/utils/settingsTypes';
import { red } from '@assets/color';
import ModalAddOrEditCustomField from '@models/settings/components/ModalAddOrEditCustomField';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { renderCustomFieldTypeLabel } from '@models/settings/utils/utils';
import { deleteCustomField } from '@models/settings/apiRequests/settingsRequests';
import { IContractType } from '@models/contracts/utils/contractTypes';

interface OneCustomFieldProps {
  customFieldDetail: ICustomField;
  setCustomFieldDetail: Dispatch<SetStateAction<ICustomField | undefined>>;
  refreshlist?: () => void;
}

function OneCustomField({
  customFieldDetail,
  setCustomFieldDetail,
  refreshlist,
}: OneCustomFieldProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const handleDeleteCustomField = async () => {
    setIsLoading(true);
    const customFieldDeleted = await deleteCustomField(
      customFieldDetail.id,
      setIsLoading
    );
    if (customFieldDeleted) {
      if (refreshlist) {
        refreshlist();
      }
      setCustomFieldDetail(undefined);
    }
  };

  const contractsListString = () => {
    const contracts = customFieldDetail.contracts;
    const conventions = customFieldDetail.conventions;
    const contractsAndConventionList = [
      ...contracts.map((contract: IContractType) => contract.reference),
      ...conventions.map((convention: IContractType) => convention.reference),
    ];

    return contractsAndConventionList.join(', ');
  };

  return (
    <>
      <Card
        title={`${t('settings.custom_field')} : ${
          customFieldDetail.internal_name
        }`}
        arrowBack
        onArrowBackClick={() => setCustomFieldDetail(undefined)}
        actionButtons={
          <div className="flex space-x-2">
            <ButtonOpx
              onClick={() => setModalIsOpen(true)}
              label={t('settings.edit')}
              small
            />
            <ButtonOpx
              onClick={() => handleDeleteCustomField()}
              label={t('settings.delete')}
              color={red}
              type="secondary"
              small
            />
          </div>
        }
        addClass="w-full"
        isLoading={isLoading}
      >
        <div className="flex w-full">
          <InfosWithIcon
            spaceY="space-y-4"
            addClass="flex-1"
            infos={[
              {
                title: `${t('settings.field_name')} (${t(
                  'settings.internal'
                )})`,
                subtitle: customFieldDetail.internal_name,
              },
              {
                title: `${t('settings.field_name')} (${t('settings.public')})`,
                subtitle:
                  customFieldDetail.public_name ||
                  t('settings.not_defined') ||
                  '',
              },
              {
                title: t('settings.associated_contracts') || '',
                subtitle:
                  customFieldDetail.contracts || customFieldDetail.conventions
                    ? contractsListString()
                    : '',
              },
            ]}
          />
          <InfosWithIcon
            spaceY="space-y-4"
            addClass="flex-1"
            infos={[
              {
                title: t('settings.visibility') || '',
                subtitle: customFieldDetail.is_public
                  ? t('settings.all') || ''
                  : t('settings.internal') || '',
              },
              {
                title: t('settings.is_required') || '',
                subtitle: customFieldDetail.is_required
                  ? t('settings.yes') || ''
                  : t('settings.no') || '',
              },
              {
                title: t('settings.placeholder') || '',
                subtitle:
                  customFieldDetail.placeholder ||
                  t('settings.not_defined') ||
                  '',
              },
            ]}
          />
          <InfosWithIcon
            spaceY="space-y-4"
            addClass="flex-1"
            infos={[
              {
                title: t('settings.field_type') || '',
                subtitle: renderCustomFieldTypeLabel(customFieldDetail.type, t),
              },
              {
                title: t('settings.default_value') || '',
                subtitle:
                  customFieldDetail.default_value ||
                  t('settings.not_defined_fem') ||
                  '',
              },
              {
                title: t('settings.status') || '',
                subtitle: customFieldDetail.is_active
                  ? t('settings.active') || ''
                  : t('settings.inactive') || '',
              },
            ]}
          />
        </div>
      </Card>
      {modalIsOpen && (
        <ModalAddOrEditCustomField
          setIsOpen={setModalIsOpen}
          setCustomFieldDetail={setCustomFieldDetail}
          customFieldDetails={customFieldDetail}
        />
      )}
    </>
  );
}

export default OneCustomField;

OneCustomField.defaultProps = {
  refreshlist: undefined,
};
