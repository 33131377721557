import { borderGrey, textGreyInfos } from '@assets/color';
import { IPersonKpi } from '../../types/globalTypes';

interface PersonKpiProps {
  infos: IPersonKpi;
  backgroundColor?: string;
  borderColor?: string;
  addClass?: string;
}

function PersonKpi({
  infos,
  backgroundColor,
  borderColor,
  addClass,
}: PersonKpiProps) {
  return (
    <div
      style={{
        backgroundColor: backgroundColor || 'white',
        borderColor: borderColor || borderGrey,
      }}
      className={[
        'border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem] w-full flex space-x-2',
        addClass,
      ].join(' ')}
    >
      <div
        className={`min-w-[2.125rem] min-h-[2.125rem] h-[2.125rem] w-[2.125rem] mr-2 rounded-default overflow-hidden ${
          infos.photoUrl ? '' : 'bg-borderGrey'
        }`}
      >
        {infos.photoUrl && (
          <img
            className="w-full h-full object-cover"
            src={infos.photoUrl || ''}
            alt="user"
          />
        )}
      </div>
      <div className="flex-col items-center space-y-[.5rem] w-full">
        <div className="flex-col items-center space-y-[.375rem] w-full">
          <p
            style={{ color: textGreyInfos }}
            className="text-[.75rem] leading-[.75rem]"
          >
            {infos.label}
          </p>
          <div
            style={{ color: 'black' }}
            className="font-[500] text-[.875rem] leading-[1rem]"
          >
            {infos.name}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonKpi;

PersonKpi.defaultProps = {
  backgroundColor: '',
  borderColor: '',
  addClass: '',
};
