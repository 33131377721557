import { GlobalContext } from '@context/globalContext';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  extractAllWorksiteLinkedFiles,
  transformObjectList,
} from '@utils/functions';
import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { ILabelValue } from '../../types/globalTypes';

interface IAddDocumentModalProps {
  updateParentDocuments: () => Promise<void>;
}

function AddDocumentModal({
  updateParentDocuments,
}: IAddDocumentModalProps): JSX.Element {
  const { t } = useTranslation();
  const { updateAddDocumentModalIsActive, globalEnum } =
    useContext(GlobalContext);
  const { worksiteDetails } = useContext(WorksitesContext);
  const methods = useForm();

  const [documentTypeListSelected, setDocumentTypeListSelected] = useState<
    ILabelValue[]
  >([]);
  const [typesAreSelected, setTypesAreSelected] = useState<boolean>(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);

  const typesList = useMemo(() => {
    // Récupération de tous les linkedFiles de l'objet principal
    const mainFileTypes = worksiteDetails.linkedFiles.map(
      (file) => file.file_type
    );

    // Récupération de tous les linkedFiles de chaque worksitesOperations
    const worksitesOperationsFileTypes =
      worksiteDetails.worksites_operations.reduce(
        (acc: Array<number>, curr) => {
          const currFileTypes = curr.linkedFiles.map((file) => file.file_type);
          return acc.concat(currFileTypes);
        },
        []
      );

    // Fusion de tous les types de fichiers et suppression des doublons
    const allFileTypes = [...mainFileTypes, ...worksitesOperationsFileTypes];
    const uniqueFileTypes = Array.from(new Set(allFileTypes));

    const uniqueFileTypesObj = uniqueFileTypes.map((file_type) => ({
      name: globalEnum.linked_file_type[file_type],
      file_type,
    }));

    uniqueFileTypesObj.push({
      name: globalEnum.linked_file_type[16] || 'Autre',
      file_type: 16,
    });

    // Mise en correspondance des types de fichiers avec leurs noms en chaîne de caractères et combinaison en objets
    return uniqueFileTypesObj;
  }, [worksiteDetails]);

  const updateDocumentTypeList = (data: ILabelValue) => {
    if (
      documentTypeListSelected.findIndex(
        (item) => item.value === data.value
      ) !== -1
    ) {
      setDocumentTypeListSelected(
        documentTypeListSelected.filter((item) => item.value !== data.value)
      );
    } else {
      setDocumentTypeListSelected([...documentTypeListSelected, data]);
    }
  };

  const allLinkedFiles = useMemo(
    () => extractAllWorksiteLinkedFiles(worksiteDetails),
    [worksiteDetails]
  );

  const renderTypeSelection = () => {
    return (
      <div>
        <div>
          <p className="text-[1.25rem] font-[500] leading-[2rem]">
            {t('global.import_a_document')}
          </p>
          <p className="text-[.875rem] leading-[1.25rem]">
            {t('global.document_import_message')}
          </p>
        </div>
        <div className="pt-[1rem] flex flex-col space-y-[.5rem]">
          <div className="flex items-start space-x-[.5rem] w-full">
            <div className="w-full">
              {/* Selection du type de document (Avis d'imposition, Devis, Autre, etc...) */}
              <InputSelect
                dataLabelValue={transformObjectList(
                  typesList,
                  'name',
                  'file_type'
                )}
                onSelectLabelValue={(data) => updateDocumentTypeList(data)}
                placeholder={t('global.document_type')}
                isMultipleSelect
              />
            </div>
          </div>
        </div>
        <div className="pt-[1.5rem] flex items-center justify-end space-x-[1rem]">
          <div>
            <ButtonOpx
              label={t('buttons.cancel')}
              type="secondary"
              onClick={() => updateAddDocumentModalIsActive(false)}
            />
          </div>
          <div>
            <ButtonOpx
              label={t('buttons.next')}
              type="primary"
              disabled={documentTypeListSelected.length === 0}
              onClick={() => setTypesAreSelected(true)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDocumentsEdition = () => {
    return (
      <div>
        <div className="flex gap-6 self-stretch">
          <div className="flex flex-col gap-4 flex-1 self-stretch">
            <div className="flex flex-col gap-2 self-stretch">
              <p className="text-[1.25rem] font-[500] leading-[2rem] font-inter">
                {t('global.import_a_document')}
              </p>
              <p className="text-[.875rem] leading-[1.25rem]">
                {t('global.document_import_message')}
              </p>
            </div>
            <div className="flex h-[37.625rem] overflow-y-auto scroll-invisible w-full">
              <ListDocumentsCard
                documents={allLinkedFiles}
                updateParentDocuments={updateParentDocuments}
                typesFilter={documentTypeListSelected.map((item) =>
                  Number(item.value)
                )}
                worksiteDatas={worksiteDetails}
              />
            </div>
          </div>
          <div className="w-[35rem] h-[45rem] max-h-[45rem]">
            <DocumentViewer
              isLoading={isLoadingDocument}
              setIsLoading={setIsLoadingDocument}
              addClassHeight="max-h-[28.875rem]"
            />
          </div>
        </div>
        <div className="pt-[1.5rem] flex items-center justify-end space-x-[1rem]">
          <div>
            <ButtonOpx
              label={t('global.finish')}
              type="primary"
              onClick={() => updateAddDocumentModalIsActive(false)}
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    // Cette fonction de nettoyage sera exécutée lorsque le composant sera démonté ou lorsque la prop isOpen changera
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div
      style={{ zIndex: 61 }}
      className="w-screen bg-[#EFEFEF] fixed inset-0 z-50 h-full flex items-center justify-center bg-opacity-30"
    >
      <FormProvider {...methods}>
        <div
          className={`bg-white shadow-2xl overflow-visible border-[1px] border-borderGrey rounded-default p-[1.5rem] absolute min-h-[5rem] ${
            !typesAreSelected ? 'w-1/3' : 'w-[72rem]'
          }`}
        >
          {!typesAreSelected ? renderTypeSelection() : renderDocumentsEdition()}
        </div>
      </FormProvider>
    </div>
  );
}

export default AddDocumentModal;
