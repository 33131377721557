import { useTranslation } from 'react-i18next';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';

import { textGreyInfos } from '@assets/color';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { IUserType } from '@models/auth/utils/types';

interface IOneContactProps {
  contact: IUserType | undefined;
  loading: boolean;
}

function OneContact({ contact, loading }: IOneContactProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center mb-4">
        {loading ? (
          <LoaderSkeleton
            height="2.5rem"
            addClass="w-[2.5rem] min-w-[2.5rem] min-h-[2.5rem] mr-2 rounded-default"
          />
        ) : (
          <div
            className={`min-w-[2.5rem] min-h-[2.5rem] h-[2.5rem] w-[2.5rem] mr-2 rounded-default overflow-hidden ${
              contact?.photo_url ? '' : 'bg-borderGrey'
            }`}
          >
            {contact?.photo_url && (
              <img
                className="w-full h-full object-cover"
                src={contact?.photo_url || ''}
                alt="user"
              />
            )}
          </div>
        )}

        <div className="w-full">
          {loading ? (
            <>
              <LoaderSkeleton height="1.25rem" addClass="w-[6rem] mb-1" />
              <LoaderSkeleton height=".75rem" addClass="w-[8rem]" />
            </>
          ) : (
            <>
              <p className="font-[500] text-[1rem] leading-[1.25rem] mb-1">
                {`${contact?.firstname || ''} ${contact?.lastname || ''}`}
              </p>
              <p
                className="text-[.625rem] leading-[.75rem]"
                style={{ color: textGreyInfos }}
              >
                {contact?.function || '-'}
              </p>
            </>
          )}
        </div>
      </div>
      <InfosWithIcon
        loading={loading}
        infos={[
          {
            title: t('forms.phone.placeholder'),
            subtitle: contact?.phone_number || '-',
          },
          {
            title: t('forms.email.placeholder'),
            subtitle: contact?.email || '-',
          },
        ]}
      />
    </>
  );
}

export { OneContact };
