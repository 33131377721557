import { OneOperationCard } from '@models/worksiteCreation/components/simulation/stepChoiceOperation/OneOperationCard';
import { v4 } from 'uuid';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { SearchBar } from '@components/SearchBar';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { formatWord } from '@utils/format';
import { t } from 'i18next';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import {
  arraysAreSame,
  incompatibleCategoryOperations,
  onCheckOperation,
} from '@models/worksiteCreation/utils/functions';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { createWorksite } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { convertHexToRGBA } from '@utils/functions';
import { red } from '@assets/color';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

interface IGlobalRenovationChoiceOperations {
  scenarios: { value: string; label: string }[];
  isLoadingList: boolean;
}

function GlobalRenovationChoiceOperations({
  scenarios,
  isLoadingList,
}: IGlobalRenovationChoiceOperations) {
  const {
    listOperations,
    listOperationSelected,
    updateListOperationSelected,
    updateScenario,
    updateStepActiveWorksiteCreation,
    worksiteOperationType,
    updateIsLoading,
    conventionActive,
    beneficiary,
    updateWorksiteDatas,
    updateDisabledNextButton,
    scenario,
    simulatorData,
    updateSimulatorData,
  } = useContext(WorksiteCreationContext);

  const [searchValue, setSearchValue] = useState<string>('');
  const [errorGraph, setErrorGraph] = useState<string>('');

  const isChecked = (operation: IOperation) => {
    const operationChecked =
      listOperationSelected &&
      listOperationSelected.find((el) => el.code === operation.code);

    return !!operationChecked;
  };

  const listIncludes = (operation: IOperation) => {
    const code = formatWord(operation.code);
    const description = formatWord(operation.description);

    return code.includes(searchValue) || description.includes(searchValue);
  };

  const onSelectScenario = (value: string) => {
    const selectedScenario = scenarios.find((s) => s.label.includes(value));
    updateSimulatorData((prevState) => ({
      ...prevState,
      'worksite.scenario': selectedScenario?.value,
    }));
    if (selectedScenario) updateScenario(selectedScenario);
  };

  useEffect(() => {
    const scenarioId = simulatorData['worksite.scenario'] || '';
    const selectedScenario = scenarios.find((s) => s.value === scenarioId);
    if (selectedScenario) updateScenario(selectedScenario);
  }, [simulatorData]);

  const initialData = useMemo(() => {
    return {
      operations: listOperationSelected?.map((elt) => ({
        id: elt.id,
        code: elt.code,
      })),
      scenario: simulatorData['worksite.scenario'],
    };
  }, []);

  const noChange = useMemo(() => {
    const currentOperations = listOperationSelected?.map((elt) => ({
      id: elt.id,
      code: elt.code,
    }));

    return arraysAreSame(
      initialData.operations || [],
      currentOperations || [],
      'code'
    );
  }, [simulatorData, listOperationSelected]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateIsLoading(true);
    setErrorGraph('');

    if (!noChange) {
      await createWorksite(
        conventionActive.id,
        Number(beneficiary?.id),
        worksiteOperationType,
        updateWorksiteDatas
      );
    }

    updateIsLoading(false);
    updateStepActiveWorksiteCreation(
      StepsWorksiteCreationEnum.SIMULATION_LOGEMENT
    );
  };

  useEffect(() => {
    const btnDisabled =
      !scenario || !listOperationSelected || listOperationSelected.length < 1;
    updateDisabledNextButton(btnDisabled);
  }, [listOperationSelected, scenario]);

  return (
    <form id="globalRenovationChoiceOperations" onSubmit={(e) => onSubmit(e)}>
      <div>
        <p className="pt-[1.5rem] font-medium text-[1.25rem]">
          {t('worksite_creation.global_renovation.scenarios')}
        </p>
        <InputSelect
          placeholder={`${t(
            'worksite_creation.global_renovation.choose_scenario'
          )}`}
          dataArrayString={scenarios.map((elt) => elt.label)}
          addClass="mt-[1rem]"
          onSelect={(value) => onSelectScenario(value)}
          defaultSelected={
            simulatorData['worksite.scenario']
              ? scenarios.find((s) =>
                  s.label.includes(simulatorData['worksite.scenario'])
                )?.label
              : undefined
          }
          dataTestIdSelect="select_scenario"
          dataTestIdOptions="scenario"
        />
      </div>
      <div>
        <p className="pt-[1.5rem] font-medium text-[1.25rem]">
          {t('global.operations')}
        </p>
        <SearchBar
          placeholder={t('search_bar.search_operation')}
          onSearch={(keyWord) => setSearchValue(formatWord(keyWord))}
          addClass="mt-[1rem]"
          dataTestId="search_operation"
        />
        <div className="w-full space-y-[1rem] overflow-y-scroll max-h-[60vh] mt-[1rem]">
          {isLoadingList ? (
            <div className="flex flex-col space-y-[1rem]">
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
            </div>
          ) : (
            listOperations
              .filter((elt) => listIncludes(elt))
              .map((operation) => {
                return (
                  <OneOperationCard
                    key={v4()}
                    nameOperation={operation.description}
                    code={operation.code}
                    isChecked={isChecked(operation)}
                    pictureOperationUrl=""
                    disabled={false}
                    handleCheck={() =>
                      onCheckOperation(
                        operation,
                        listOperationSelected,
                        listOperations,
                        updateListOperationSelected
                      )
                    }
                    incompatible={
                      incompatibleCategoryOperations(
                        operation,
                        listOperationSelected
                      ) ||
                      (listOperations
                        .filter((el) => el.code === operation.code)[0]
                        .incompatibilities?.includes(operation.code) &&
                        listOperationSelected !== undefined &&
                        listOperationSelected !== null &&
                        listOperationSelected.length > 0 &&
                        !listOperationSelected?.includes(operation) &&
                        !operation)
                    }
                  />
                );
              })
          )}
        </div>
      </div>
      {errorGraph !== '' && (
        <p
          className="text-red p-[.5rem] rounded-default mt-[1rem]"
          style={{ backgroundColor: convertHexToRGBA(red, 0.1) }}
        >
          {errorGraph}
        </p>
      )}
    </form>
  );
}

export { GlobalRenovationChoiceOperations };
