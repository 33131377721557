import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

function CofracCardSkeleton(): JSX.Element {
  return (
    <div
      className="w-full bg-white border border-borderGrey rounded-default flex items-center justify-center flex-wrap my-4
        cursor-pointer"
    >
      <div className="w-full md:w-[30%] md:border-r md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex flex-wrap items-center justify-start mb-[0.875rem]">
          <LoaderSkeleton height="1rem" addClass="w-[10rem] pb-[10px]" />
        </div>
      </div>
      <div className="w-full md:w-[70%] border-t md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex justify-between items-center">
          <LoaderSkeleton
            height="0.7rem"
            addClass="w-[12rem] relative bottom-2"
          />
          <LoaderSkeleton height="0.7rem" addClass="w-[7rem]" />
        </div>
      </div>
      <div className="w-full p-[1rem] my-[0.4rem] border-t border-borderGrey flex flex-justify-between">
        <div className="w-1/2">
          <LoaderSkeleton height="0.7rem" addClass="w-[18rem]" />
        </div>
        <div className="w-1/2 flex justify-end">
          <LoaderSkeleton height="0.7rem" addClass="w-[25rem]" />
        </div>
      </div>
    </div>
  );
}

export { CofracCardSkeleton };
