import {
  getMaterialBrandsList,
  sendMaterialToWorksiteOperation,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import {
  IPayloadMaterials,
  IWorksiteOperation,
} from '@models/worksiteCreation/utils/types/worksitesType';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { TextError } from '@components/TextError';
import {
  arraysAreSame,
  getNextStep,
} from '@models/worksiteCreation/utils/functions';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { materialInitialValues } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { OneOperationMaterial } from '@models/worksiteCreation/components/createWorksite/material/OneOperationMaterial';
import { WorksiteCreationContext } from '../../../utils/worksiteCreationContext';

function StepMaterial() {
  const methods = useForm();
  const { handleSubmit } = methods;
  const {
    worksiteDatas,
    arrayMaterialsDataPost,
    updateArrayMaterialsDataPost,
    updateIsLoading,
    updateWorksiteDatas,
    updateDisabledNextButton,
    updateStepActiveWorksiteCreation,
    workflowSidebar,
    stepActiveWorksiteCreation,
    readOnly,
  } = useContext(WorksiteCreationContext);
  const [errorMaterial, setErrorMaterial] = useState<string>('');
  const [listMark, setListMark] = useState<{ name: string; id: number }[]>([]);
  const [isLoadingBrands, setIsLoadingBrands] = useState<boolean>(false);

  const worksitesOperationsWithMaterial = useMemo(
    () =>
      worksiteDatas.worksites_operations.filter(
        (wo) =>
          wo.operation.can_have_material && wo.operation.code !== 'BAR-TH-129'
      ),
    [worksiteDatas]
  );

  const initialData = useMemo(() => {
    return worksitesOperationsWithMaterial.map((elt) => {
      return {
        material_id: elt.material?.material_id || null,
        operation_id: elt.id || null,
        id: elt.material?.id || null,
      };
    });
  }, [worksiteDatas.worksites_operations]);

  const noChange = useMemo(() => {
    const current = arrayMaterialsDataPost?.map((elt) => ({
      material_id: elt.material_id,
      operation_id: elt.worksite_operation_id,
      id: elt.id, //
    }));

    if (!arrayMaterialsDataPost) return false;

    if (arrayMaterialsDataPost.some((m) => m.internal === true)) return false;

    return arraysAreSame(initialData, current || [], 'operation_id');
  }, [arrayMaterialsDataPost]);

  const nextStep = useMemo(() => {
    return getNextStep(workflowSidebar, stepActiveWorksiteCreation);
  }, [stepActiveWorksiteCreation]);

  const onSubmit = async () => {
    if (arrayMaterialsDataPost && arrayMaterialsDataPost.length > 0) {
      let canGoToNextStep = true;
      if (!noChange) {
        canGoToNextStep = await sendMaterialToWorksiteOperation(
          worksiteDatas.id,
          updateIsLoading,
          arrayMaterialsDataPost,
          updateWorksiteDatas,
          setErrorMaterial
        );
      }
      if (canGoToNextStep) {
        updateStepActiveWorksiteCreation(
          nextStep ? nextStep.value : StepsWorksiteCreationEnum.DOCUMENTS
        );
      }
    }
  };

  useEffect(() => {
    if (!readOnly) {
      getMaterialBrandsList(null, setListMark, setIsLoadingBrands);
    }

    const payloadMaterials: any = [];
    worksiteDatas.worksites_operations
      .filter((operation) => operation.operation.can_have_material)
      .forEach((operation) => {
        payloadMaterials.push({
          id: null,
          material_id: null,
          name: null,
          caracteristics: {
            mark: {
              id: null,
              name: null,
            },
            scop: null,
            puissance_nominale_calorifique: null,
          },
          picture_url: null,
          technical_document_url: null,
          created_by: null,
          created_at: null,
          updated_at: null,
          worksite_operation_id: operation.id,
        });
      });
    if (payloadMaterials.length > 0) {
      updateArrayMaterialsDataPost(payloadMaterials);
    } else {
      sendMaterialToWorksiteOperation(
        worksiteDatas.id,
        updateIsLoading,
        [],
        updateWorksiteDatas,
        setErrorMaterial
      );
    }
  }, []);

  useEffect(() => {
    if (readOnly) {
      updateDisabledNextButton(false);
    } else {
      const btnIsDisabled = arrayMaterialsDataPost?.some((obj) => !obj.id);

      updateDisabledNextButton(!!btnIsDisabled);
    }
  }, [arrayMaterialsDataPost, readOnly]);

  useEffect(() => {
    const newMaterialArray: IPayloadMaterials[] =
      worksiteDatas.worksites_operations
        .filter((wo) => wo.operation.can_have_material)
        .map((wo) => {
          const caracteristics = wo.material?.caracteristics
            ? JSON.parse(wo.material?.caracteristics)
            : { mark: { name: '' }, reference: { name: '' } };

          return {
            id: wo.material?.id || null,
            material_id: wo.material?.material_id || null,
            name: `${caracteristics?.reference?.name || ''} - ${
              caracteristics?.mark?.name || ''
            }`,
            caracteristics: wo.material?.caracteristics || '',
            picture_url: wo.material?.picture_url || null,
            technical_document_url: wo.material?.technical_document_url || null,
            created_by: wo.material?.created_by || null,
            created_at: wo.material?.created_at || null,
            updated_at: wo.material?.updated_at || null,
            worksite_operation_id: wo.id,
            internal: wo.material?.internal || false,
          };
        });

    updateArrayMaterialsDataPost(newMaterialArray);
  }, [worksiteDatas.worksites_operations]);

  return (
    <FormProvider {...methods}>
      <form
        action="sendMaterialToWorksite"
        id="sendMaterialToWorksite"
        onSubmit={
          handleSubmit
            ? handleSubmit(onSubmit as (data: FieldValues) => void)
            : undefined
        }
      >
        {worksitesOperationsWithMaterial.map(
          (operation: IWorksiteOperation) => {
            return (
              <OneOperationMaterial
                key={`key_material_${operation.id}`}
                listBrands={listMark}
                isLoadingBrands={isLoadingBrands}
                operation={{
                  id: operation.id,
                  code: operation.operation.code,
                  description: operation.operation.description,
                  material: operation.material,
                }}
                initialValues={materialInitialValues(
                  operation,
                  arrayMaterialsDataPost
                )}
              />
            );
          }
        )}
        <TextError errorMessage={errorMaterial} />
      </form>
    </FormProvider>
  );
}

export { StepMaterial };
