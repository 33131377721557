import { createContext, Dispatch, SetStateAction } from 'react';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { IAhExtraDataItem } from '../../../types/globalTypes';

interface IProps {
  worksitesListDatas: IWorksiteDetails[];
  updateWorksitesListDatas: Dispatch<SetStateAction<IWorksiteDetails[]>>;
  worksiteId: string;
  updateWorksiteId: Dispatch<SetStateAction<string>>;
  worksiteDetails: IWorksiteDetails;
  updateWorksiteDetails: Dispatch<SetStateAction<IWorksiteDetails>>;
  leftPartSubHeader: JSX.Element;
  updateLeftPartSubHeader: Dispatch<SetStateAction<JSX.Element>>;
  rightPartSubHeader: JSX.Element;
  tabActive: string;
  updateTabActive: Dispatch<SetStateAction<string>>;
  ahGeneralData: IAhExtraDataItem[] | undefined;
  updateAhGeneralData: Dispatch<SetStateAction<IAhExtraDataItem[] | undefined>>;
}

export const WorksitesContext = createContext({} as IProps);
