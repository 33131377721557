import { blueOpx, white } from '@assets/color';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { Toggle } from '@components/atomic/inputs/controls/Toggle';
import { useEffect, useState } from 'react';
// import { useState, useEffect } from 'react';

interface ITextWhithCheckboxOrToggleProps {
  label: string;
  onCheck: (check: boolean) => void;
  // sert à définir si le check doit être de style toggle ou checkbox
  type: 'checkbox' | 'toggle';
  checkOrTogglePosition?: 'left' | 'right';
  colorChecked?: string;
  backgroundColorCheckedOrToggle?: string;
  checked: boolean;
  addClass?: string;
  dataTestId?: string;
  disabled?: boolean;
}

// EXEMPLE D'UTILISATION DANS LE COMPOSANT PARENT
//  const [checkedItems, setCheckedItems] = useState<string[]>([]);
//
// const handleCheck = (isChecked: boolean, label: string) => {
//   if (isChecked) {
//     setCheckedItems([...checkedItems, label]);
//   } else {
//     setCheckedItems(checkedItems.filter((item) => item !== label));
//   }
// };
// const onSubmit = () => {
//   functionOnSubmit(checkedItems);
// };
//
//   return (
//     <form
//       onSubmit={handleSubmit(onSubmit)}
//       action="test/inputs"
//       className="px-5"
//     >
//     <TextWhithCheckboxOrToggle
//       label="Option 1"
//       onCheck={(isChecked) => handleCheck(isChecked, 'Option 1')}
//       checked={checkedItems.includes('Option 1')}
//     />
//     <TextWhithCheckboxOrToggle
//       label="Option 2"
//       onCheck={(isChecked) => handleCheck(isChecked, 'Option 2')}
//       checked={checkedItems.includes('Option 2')}
//     />
//     <TextWhithCheckboxOrToggle
//       label="Option 3"
//       onCheck={(isChecked) => handleCheck(isChecked, 'Option 3')}
//       checked={checkedItems.includes('Option 3')}
//     />
//       <button
//         type="submit"
//         className="ml-5 mt-5 bg-green rounded-default text-white px-5 py-2"
//       >
//         Test envoi
//       </button>
//     </form>
//   );
// }
function TextWithCheckboxOrToggle({
  onCheck,
  label,
  type,
  addClass,
  checked,
  checkOrTogglePosition,
  backgroundColorCheckedOrToggle,
  colorChecked,
  dataTestId,
  disabled,
}: ITextWhithCheckboxOrToggleProps): JSX.Element {
  const [isChecked, setIsChecked] = useState(checked || false);

  const checkOrToggle = () => {
    if (type === 'toggle') {
      return (
        <Toggle
          label={label}
          colorToggle={backgroundColorCheckedOrToggle}
          isToggleOn={isChecked}
          onToggle={(isOn) => {
            setIsChecked(isOn);
          }}
          disabled={disabled}
        />
      );
    }
    return (
      <Checkbox
        colorChecked={colorChecked}
        label={label}
        onCheck={(isCheck) => onCheck(isCheck)}
        backgroundColorChecked={backgroundColorCheckedOrToggle}
        checked={checked}
        disabled={disabled}
      />
    );
  };

  useEffect(() => {
    onCheck(isChecked);
  }, [isChecked]);

  return (
    <div
      className={[
        'border border-borderGrey rounded-default text-[.875rem] ',
        addClass,
      ].join(' ')}
    >
      <button
        type="button"
        onClick={() => {
          setIsChecked(!isChecked);
        }}
        className="flex items-center justify-between w-full px-[.5rem] py-[.75rem] space-x-2"
        data-test-id={dataTestId}
      >
        {checkOrTogglePosition === 'left' && checkOrToggle()}
        <p>{label}</p>
        {checkOrTogglePosition === 'right' && checkOrToggle()}
      </button>
    </div>
  );
}

export { TextWithCheckboxOrToggle };

TextWithCheckboxOrToggle.defaultProps = {
  addClass: '',
  colorChecked: white,
  backgroundColorCheckedOrToggle: blueOpx,
  dataTestId: 'text_with_checkbox_or_toggle',
  checkOrTogglePosition: 'right',
  disabled: false,
};
