import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface IChoiceGenerateSizingNote {
  setChoice: Dispatch<SetStateAction<string>>;
  choice: string;
}

function ChoiceGenerateSizingNote({
  setChoice,
  choice,
}: IChoiceGenerateSizingNote) {
  const { t } = useTranslation();
  const { readOnly } = useContext(WorksiteCreationContext);

  return (
    <div className="flex flex-col space-y-[.5rem] w-full mt-[2rem]">
      <TextWithRadio
        label={t(
          'worksite_creation.create_worksite.sizing_note.choice_i_have_sizing_note'
        )}
        value="2"
        setSelectedValue={setChoice}
        isChecked={choice === '2'}
        selectValue="2"
        subLabel={`${t(
          'worksite_creation.create_worksite.sizing_note.certify_i_have_sizing_note'
        )}`}
        addClass="w-full"
        labelInBold
        dataTestId="radio_certify_i_have_sizing_note"
        disabled={readOnly}
      />
      <TextWithRadio
        label={t(
          'worksite_creation.create_worksite.sizing_note.choice_opx_generate_document'
        )}
        value="1"
        setSelectedValue={setChoice}
        isChecked={choice === '1'}
        selectValue="1"
        addClass="w-full"
        labelInBold
        dataTestId="radio_choice_opx_generate_document"
        disabled={readOnly}
      />
    </div>
  );
}

export { ChoiceGenerateSizingNote };
