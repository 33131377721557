/* eslint-disable no-nested-ternary */
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format, getYear, parse } from 'date-fns';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { Tag } from '@components/atomic/Tag';
import { Modal } from '@components/Modal';

import { blueOpx, green, red, textGrey } from '@assets/color';

import { EditIcon, TrashIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { getDeliveries, deleteLot } from '@models/lots/apiRequests/lotRequests';
import { LinePlot } from '@models/contracts/components/LinePlot';
import { convertKiloToMega } from '@utils/functions';
import { DEPOSITS_ROUTES_WITH_ID } from '@utils/routesUrls';

interface ITabDeliveryOperationsProps {
  contractId: number;
}

function TabDeliveryOperations(props: ITabDeliveryOperationsProps) {
  const { contractId } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { globalEnum } = useContext(GlobalContext);

  const thisYear = getYear(new Date());
  const lastYear = thisYear - 1;

  const [activeYear, setActiveYear] = useState(thisYear);

  const [listInProgress, setListInProgress] = useState<any>();
  const [listDone, setListDone] = useState<any>();

  const [deleteId, setDeleteId] = useState<number>();

  const getData = async () => {
    const res = await getDeliveries(contractId);

    setListInProgress(res.lots_in_progress);
    setListDone(res.lots_done);
  };

  useMemo(() => {
    if (contractId) getData();
  }, [contractId]);

  const renderElement = (index: number, item: any, type: string) => {
    const nb = index + 1;
    const nbStr = nb < 10 ? `00${nb}` : nb < 100 ? `0${nb}` : nb;
    const titleText = `Livraison ${item.reference ? item.reference : nbStr}`;
    const nbOperations = item.count_operations;
    const nbOperationsRemoved = item.invalid_operations;

    const volumeClassique = convertKiloToMega(item.volume.classique || '');
    const volumePrecaire = convertKiloToMega(item.volume.precaire || '');
    const volumeTotal = convertKiloToMega(item.volume.total || '');

    const dateLabel =
      type === 'inProgress'
        ? t('contract.tab_volume_detail.last_update')
        : t('contract.tab_volume_detail.validation_date');
    const dateToDisplay =
      type === 'inProgress' ? item.updated_at : item.delivery_date;
    const dateValue = dateToDisplay
      ? format(
          parse(dateToDisplay, 'yyyy-MM-dd HH:mm:ss', new Date()),
          'dd/MM/yyyy'
        )
      : '-';

    const tagOperationText =
      type === 'inProgress'
        ? `${nbOperations} ${t('contract.tab_volume_detail.operation')}${
            nbOperations > 1 ? 's' : ''
          }`
        : nbOperationsRemoved
        ? `${nbOperationsRemoved} ${t('contract.tab_volume_detail.operation')}${
            nbOperationsRemoved > 1 ? 's' : ''
          } ${t('contractMandant.removed')}${
            nbOperationsRemoved > 1 ? 's' : ''
          }`
        : '';

    const tagOperationColor = type === 'inProgress' ? textGrey : red;

    const status = item.status || 5;
    const tagStatusText = (globalEnum.contract_lot_status as any)[
      String(status)
    ];
    const tagStatusColor = [1, 2, 3].includes(status)
      ? blueOpx
      : status === 4
      ? red
      : green;

    const btnEdit = (
      <ColorCube
        color={blueOpx}
        numberOrIcon={<EditIcon />}
        onClick={() =>
          navigate(
            `${DEPOSITS_ROUTES_WITH_ID(item.id).LOT_VIEW}?lot_number=${
              item.reference ? item.reference : nbStr
            }`
          )
        }
        size="2rem"
        opacity
        addClass="mr-[.5rem] rounded"
      />
    );
    const btnDelete = (
      <ColorCube
        color={red}
        numberOrIcon={<TrashIcon />}
        onClick={() => setDeleteId(item.id)}
        size="2rem"
        opacity
        addClass="rounded"
      />
    );
    const btnConsult = (
      <ButtonOpx
        type="secondary"
        label={t('contract.tab_volume_detail.consult')}
        onClick={() => navigate(DEPOSITS_ROUTES_WITH_ID(item.id).LOT_VIEW)}
      />
    );

    return (
      <div className="p-6 border border-transparent border-b-borderGrey">
        <div className="flex flex-wrap justify-between items-center mb-6">
          <div className="flex flex-nowrap items-center">
            <div className="mr-[1rem] text-[1.25rem] leading-8">
              {titleText}
            </div>
            {type === 'done' && (
              <Tag
                color={tagStatusColor}
                label={tagStatusText}
                addClass="h-fit mr-3"
              />
            )}
            {tagOperationText !== '' && (
              <Tag
                color={tagOperationColor}
                label={tagOperationText}
                addClass="h-fit"
              />
            )}
          </div>
          {type === 'inProgress' ? (
            <div className="flex flex-nowrap">
              {item.is_lot_creator ? (
                <>
                  {btnEdit} {btnDelete}
                </>
              ) : (
                btnConsult
              )}
            </div>
          ) : (
            btnConsult
          )}
        </div>
        <div className="flex flex-wrap items-center space-x-4 text-[.875rem]">
          <div>{`${t('contract.tab_volume_detail.volume_classic')} : ${
            volumeClassique || '-'
          } MWhc`}</div>
          <div>{`${t('contract.tab_volume_detail.volume_precaire')} : ${
            volumePrecaire || '-'
          } MWhc`}</div>
          <div>{`${t(
            'contract.tab_volume_detail.volume_total'
          )} : ${volumeTotal} MWhc`}</div>
          <div>{`${dateLabel} : ${dateValue}`}</div>
        </div>
      </div>
    );
  };

  const renderListProduction = (title: string, list: any[], type: string) => {
    return (
      <div className="border border-borderGrey rounded-default2 bg-white">
        <p className="border border-solid border-transparent border-b-borderGrey p-6 font-medium text-[1.25rem]">
          {title}
        </p>
        {list && list.length > 0 ? (
          list.map((item, idx) => (
            <div key={v4()}>{renderElement(idx, item, type)}</div>
          ))
        ) : (
          <p className="p-6">{t('contract.association.no_items')}</p>
        )}
      </div>
    );
  };

  const renderListPilotage = () => {
    return (
      <div>
        {listDone && listDone.length > 0 ? (
          listDone.map((item: any, idx: number) => (
            <div
              className="border border-borderGrey rounded-default2 bg-white my-3"
              key={v4()}
            >
              {renderElement(idx, item, 'done')}
            </div>
          ))
        ) : (
          <p className="p-6">{t('contract.association.no_items')}</p>
        )}
      </div>
    );
  };

  const handleDeleteDelivery = async () => {
    if (deleteId) {
      setDeleteId(undefined);
      await deleteLot(Number(deleteId));
      getData();
    }
  };

  const graphData = useMemo(() => {
    let allData: any[] = [];
    if (listDone) allData = [...listDone];
    if (listDone && listInProgress) allData = [...listDone, ...listInProgress];

    return allData;
  }, [listInProgress, listDone, activeYear]);

  return (
    <div>
      <Card
        title={`${t('contractMandant.volume_delivered')}`}
        actionButtons={
          <>
            {[lastYear, thisYear].map((year) => (
              <ButtonOpx
                key={v4()}
                type="secondary"
                label={`${year}`}
                onClick={() => setActiveYear(year)}
                active={year === activeYear}
              />
            ))}
          </>
        }
        addClass="mb-6"
        dataTestId="delivery_operation_card"
      >
        <LinePlot
          data={graphData}
          activeYear={activeYear}
          xAxisReference="volume"
          dateReference="delivery_date"
        />
      </Card>

      <div className="my-[1.5rem]" />

      {listInProgress && listDone && (
        <>
          {renderListProduction(
            t('contractMandant.delivery_in_progress'),
            listInProgress,
            'inProgress'
          )}

          <div className="my-[1.5rem]" />

          {renderListProduction(
            t('contractMandant.delivery_done'),
            listDone,
            'done'
          )}
        </>
      )}

      {listInProgress === undefined && listDone && renderListPilotage()}

      {deleteId && (
        <Modal
          title={t('contractMandant.delete_lot_title')}
          textBtnConfirm={`${t('buttons.confirm')}`}
          onConfirmClick={handleDeleteDelivery}
          btnCancel
          setIsModal={() => setDeleteId(undefined)}
          backgroundTransparent
        >
          <p className="mt-4">
            {t('contract.tab_volume_detail.confirm_delete')}
          </p>
        </Modal>
      )}
    </div>
  );
}

export { TabDeliveryOperations };
