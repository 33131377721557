import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import OneArrayKey from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/OneArrayKey';
import { useEffect, useState } from 'react';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

interface IMultipleArrayFormProps {
  graph: IGraph;
  operationIndex: number;
}

function MultipleArrayForm({ graph, operationIndex }: IMultipleArrayFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);
  return (
    <div className="flex flex-col space-y-2 pt-2">
      <p>{graph.description}</p>
      {isLoading ? (
        <LoaderSkeleton height="5rem" />
      ) : (
        graph.multipleArrayKeys &&
        graph.multipleArrayKeys.map((multipleElement) => (
          <OneArrayKey
            key={`${operationIndex}-${graph.key}-${multipleElement.key}`}
            graph={graph}
            oneArrayKeyDatas={multipleElement}
            operationIndex={operationIndex}
          />
        ))
      )}
    </div>
  );
}

export default MultipleArrayForm;
