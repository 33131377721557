import { useId } from 'react';

function LoaderListSimpleDocuments() {
  return (
    <div className="flex flex-col space-y-[1rem]">
      {[...Array(5)].map(() => (
        <div
          key={useId()}
          className="p-[1rem] border border-borderGrey rounded-default flex items-center justify-between bg-borderGrey"
        >
          <div className="flex items-center space-x-[.5rem]">
            <div>
              <div className="skeleton-loader-anim h-[1rem] w-[15rem] mb-[.25rem]" />
              <div className="skeleton-loader-anim h-[.75rem] w-[7rem]" />
            </div>
          </div>
          <div className="skeleton-loader-anim h-[1.5rem] w-[1.5rem]" />
        </div>
      ))}
    </div>
  );
}
export { LoaderListSimpleDocuments };
