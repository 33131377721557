import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

function StatusCardLoader() {
  return (
    <div className="flex flex-1 border-r last:border-r-0 border-borderGrey">
      {Array.from(Array(8).keys()).map((value) => (
        <LoaderSkeleton key={`loader_${value}`} height="13rem" />
      ))}
    </div>
  );
}

export default StatusCardLoader;
