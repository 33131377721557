/* eslint-disable no-useless-concat */
/* eslint-disable no-nested-ternary */
import { ColorCube } from '@components/atomic/ColorCube';
import { getListRequests } from '@models/requests/apiRequest/requestRequests';
import {
  IPayloadDataPostListRequests,
  IRequestTypes,
} from '@models/requests/types/requestTypes';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { ChevronDownIcon } from '@assets/images/svgComponents';
import { textGrey } from '@assets/color';
import { Link } from 'react-router-dom';
import { WORKSITES_ROUTES_WITH_ID } from '@utils/routesUrls';
import { RequestAndActivityIntoModalOrCardContext } from '../../../../context/requestAndChangeContext';
import { RequestsContext } from '../../utils/requestsContext';
import { convertHexToRGBA } from '../../../../utils/functions';

function HistoryRequests() {
  const { t } = useTranslation();
  const {
    listRequests,
    updateListRequests,
    updateTotalRequestsList,
    numberPageRequestsList,
    filterDataRequests,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { elementActiveAllRequestWorksiteOrPartner, updateIsLoadingList } =
    useContext(RequestsContext);

  const columns = [
    t('requests_and_changes.name_of_request'),
    t('requests_and_changes.category'),
    t('requests_and_changes.precision_of_request'),
    t('requests_and_changes.installer'),
    t('requests_and_changes.task'),
    t('requests_and_changes.type_of_request'),
    '',
  ];

  const rowsToDisplay = (row: IRequestTypes) => {
    const displayTags = (tags: number) => {
      if (row.relation_type === 'worksite') {
        switch (tags) {
          case null:
            return '-';
          case 1:
            return `${tags} document`;
          default:
            return `${tags} documents`;
        }
      } else {
        switch (tags) {
          case null:
            return '-';
          case 1:
            return `${tags} signature`;
          default:
            return `${tags} signatures`;
        }
      }
    };

    return columns.map((column) => {
      const iconLink = {
        '': (
          <ColorCube
            numberOrIcon={<ChevronDownIcon className="rotate-[-90deg]" />}
            color={textGrey}
            backgroundColor={convertHexToRGBA(textGrey, 0.1)}
            size="1.5rem"
          />
        ),
      }[column];
      const rowValue = {
        'Nom de la demande': row.subject,
        'Name of the request': row.subject,
        Catégorie:
          row.relation_type === 'worksite'
            ? t('requests_and_changes.worksite')
            : t('requests_and_changes.partner'),
        Category:
          row.relation_type === 'worksite'
            ? t('requests_and_changes.worksite')
            : t('requests_and_changes.partner'),
        'Précision sur la demande': row.address,
        'Precision of the request': row.address,
        Installateur: row.installer.company_name,
        Installer: row.installer.company_name,
        Tâche: displayTags(row.tags),
        Task: displayTags(row.tags),
        Type:
          row.type === 1
            ? t('requests_and_changes.automatic')
            : t('requests_and_changes.personalize'),
      }[column];
      return { row: rowValue, iconLink };
    });
  };

  useEffect(() => {
    const dataPostRequest: IPayloadDataPostListRequests = {
      pagination: {
        page: numberPageRequestsList,
        perPage: 10,
      },
      status: 1,
      sort_and_filter: filterDataRequests,
    };

    if (
      elementActiveAllRequestWorksiteOrPartner ===
      t('requests_and_changes.worksites')
    ) {
      dataPostRequest.relation_type = 'worksite';
    } else {
      delete dataPostRequest.relation_type;
    }
    getListRequests(
      updateListRequests,
      updateTotalRequestsList,
      listRequests,
      dataPostRequest,
      updateIsLoadingList
    );
  }, [elementActiveAllRequestWorksiteOrPartner, filterDataRequests]);

  return (
    <table className="w-full">
      <thead>
        <tr className="text-left text-textGrey font-normal text-[.875rem]">
          {columns.map((column) => (
            <th className="font-normal p-[1.5rem]" key={column}>
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {listRequests.map((row) => {
          return (
            <tr key={v4()} className="sb-text-rem-caption1">
              {rowsToDisplay(row).map((rowCustom: any) => {
                return (
                  <td
                    className="bg-white  p-[1.5rem] border-b border-b-borderGrey"
                    key={v4()}
                  >
                    <Link
                      to={
                        WORKSITES_ROUTES_WITH_ID(row.relation_id).WORKSITES_VIEW
                      }
                    >
                      {rowCustom.row} {rowCustom.iconLink}
                    </Link>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export { HistoryRequests };
