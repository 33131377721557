// eslint-disable-next-line no-shadow
export enum WORKSITE_STATUS {
  WORKSITE_CREATION = 1, // Étape 1 : Création du chantier
  PRE_WORKSITE_VERIFICATION = 2, // Étape 2 : Vérifications avant chantier
  WORKSITE_IN_PROGRESS = 3, // Étape 3 : Chantier en cours
  POST_WORKSITE_VERIFICATION = 4, // Étape 4 : Vérifications après chantier
  WORKSITE_CONFORME = 5, // Étape 5 : Chantier conforme
  FINISHED = 6, // Terminé
  DELETED = -1, // Supprimé
}

export const WORKSITE_OPERATION_STATUS = {
  Creation: 1,
  PreChecks: 2,
  InProgress: 3,
  PostChecks: 4,
  COFRAC: 5,
  PreSubmission: 6,
  Submitted: 7,
  SubmissionValidated: 8,
};

export const INCENTIVE_TYPE_ENUM = {
  DIRECT: 1,
  INDIRECT: 2,
  MIXED: 3,
};
