/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { DIMENSIONS } from '@utils/utils';
import { InvitedInstallersStatusEnum } from '@utils/enums';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import {
  BeneficiariesIcon,
  ChangeIcon,
  CheckCircleIcon,
  TrashIcon,
} from '@assets/images/svgComponents';
import { convertHexToRGBA, shortenStringWithEllipsis } from '@utils/functions';
import { blueOpx, green, orange, red, textGrey, white } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { ColorCube } from '@components/atomic/ColorCube';
import { Modal } from '@components/Modal';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';
import {
  getInvitedInstallersList,
  unlinkInvitedInstaller,
} from '../apiRequests/conventionRequests';
import {
  IInstallerToDelete,
  IInvitedInstallerStatus,
} from '../utils/conventionTypes';

interface InstallerFollowUpCardProps {
  listUpdated: boolean;
}

function InstallerFollowUpCard({
  listUpdated,
}: InstallerFollowUpCardProps): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();

  const [clickedInstallerStatus, setClickedInstallerStatus] = useState<
    IInvitedInstallerStatus | undefined
  >(undefined);
  const [installerToDelete, setInstallerToDelete] =
    useState<IInstallerToDelete>();
  const [installers, setInstallers] = useState([]);
  const { header_height, requests_and_changes_width } = DIMENSIONS;
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getInvitedInstallers = async (status: number | null) => {
    setLoading(true);
    setInstallers([]);
    const res = await getInvitedInstallersList(Number(id), status);
    if (res && res.length > 0) {
      setInstallers(res);
    }
    setLoading(false);
  };

  const deleteInvitedInstaller = async () => {
    setLoading(true);
    setModal(true);

    // delete link as installer then reset list of installers as per status clicked
    await unlinkInvitedInstaller(
      Number(id),
      installerToDelete?.entity_id,
      installerToDelete?.invitation_id
    );
    setInstallers([]);
    const res = await getInvitedInstallersList(
      Number(id),
      clickedInstallerStatus?.pending
        ? InvitedInstallersStatusEnum.PENDING
        : null
    );

    if (res && res.length > 0) {
      setInstallers(res);
    }
    setLoading(false);
    setModal(false);
  };

  // Example function to fetch the list
  const fetchList = () => {
    // Fetch the list and update state
    setClickedInstallerStatus({
      active: false,
      pending: false,
      all: true,
    });
    getInvitedInstallers(null);
  };

  // Update the list when listUpdated changes
  useEffect(() => {
    // Fetch or update the list here when listUpdated changes
    fetchList(); // function to fetch the list
  }, [listUpdated]);

  return (
    <div
      className={`fixed right-0 top-[${header_height}] max-h-screen bg-white border-l`}
    >
      <div
        style={{
          height: `calc(100vh - ${parseFloat(header_height)}rem)`,
          minWidth: requests_and_changes_width,
          maxWidth: requests_and_changes_width,
        }}
        className="flex flex-col bg-white"
      >
        {/* Header status */}
        <div className="w-full p-6 flex items-center justify-between border-b border-b-borderGrey">
          <div className="flex">
            <ButtonOpx
              icon={<CheckCircleIcon />}
              color={clickedInstallerStatus?.active ? green : textGrey}
              backgroundColor={
                clickedInstallerStatus?.active
                  ? convertHexToRGBA(blueOpx, 0.1)
                  : white
              }
              label={`${t(
                'convention.model.header_card_invited_installers.active'
              )}`}
              addClass="!border-none"
              onClick={() => {
                getInvitedInstallers(InvitedInstallersStatusEnum.ACTIVE);
                setClickedInstallerStatus({
                  active: true,
                  pending: false,
                  all: false,
                });
              }}
            />
            <ButtonOpx
              icon={<ChangeIcon />}
              color={clickedInstallerStatus?.pending ? orange : textGrey}
              backgroundColor={
                clickedInstallerStatus?.pending
                  ? convertHexToRGBA(blueOpx, 0.1)
                  : white
              }
              label={`${t(
                'convention.model.header_card_invited_installers.pending'
              )}`}
              onClick={() => {
                getInvitedInstallers(InvitedInstallersStatusEnum.PENDING);
                setClickedInstallerStatus({
                  active: false,
                  pending: true,
                  all: false,
                });
              }}
              addClass="!border-none"
            />
            <ButtonOpx
              icon={<BeneficiariesIcon />}
              color={clickedInstallerStatus?.all ? blueOpx : textGrey}
              backgroundColor={
                clickedInstallerStatus?.all
                  ? convertHexToRGBA(blueOpx, 0.1)
                  : white
              }
              label={t('convention.model.header_card_invited_installers.all')}
              onClick={() => {
                getInvitedInstallers(null);
                setClickedInstallerStatus({
                  active: false,
                  pending: false,
                  all: true,
                });
              }}
              addClass="!border-none"
            />
          </div>
        </div>

        {/* display list of installers as per status */}
        <div className="flex-1 overflow-auto">
          <div className="w-full">
            {!loading ? (
              installers.length > 0 &&
              installers.map((row: any) => (
                <div
                  className="flex px-4 mt-2 border-b border-b-borderGrey py-3"
                  key={v4()}
                >
                  <div className="w-full flex-wrap grid grid-cols-1 px-3 md:grid-cols-[95%_5%]">
                    <div className="flex items-center">
                      {clickedInstallerStatus?.all && (
                        <p className="pr-2">
                          <ColorCube
                            backgroundColor={white}
                            color={
                              row?.installer_status !==
                              InvitedInstallersStatusEnum.ACTIVE
                                ? orange
                                : green
                            }
                            borderRadius=".125rem"
                            numberOrIcon={
                              row?.installer_status ===
                              InvitedInstallersStatusEnum.PENDING ? (
                                <ChangeIcon />
                              ) : (
                                <CheckCircleIcon />
                              )
                            }
                            size="1.5rem"
                            opacity
                          />
                        </p>
                      )}
                      <Link
                        to={PARTNERS_ROUTES_WITH_ID(row.id).INSTALLER_VIEW}
                        className="text-grey"
                        title={row.company_name}
                      >
                        {shortenStringWithEllipsis(row?.company_name, 30)}
                      </Link>
                    </div>
                    {row?.installer_status !==
                      InvitedInstallersStatusEnum.ACTIVE && (
                      <div className="float-right">
                        <ColorCube
                          color={red}
                          numberOrIcon={<TrashIcon />}
                          onClick={() => {
                            setModal(true);
                            setInstallerToDelete({
                              entity_id: row.id,
                              invitation_id: row.invitation_id,
                            });
                          }}
                          size="1.5rem"
                          opacity
                          addClass="rounded"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <LoaderSkeleton height="5rem" />
            )}
            {modal && (
              <Modal
                title={t('convention.model.unlink_installer.header')}
                backgroundTransparent
                sidebarVisible
                setIsModal={setModal}
              >
                <div className="mt-2 flex flex-col gap-3">
                  <p className="mt-0 mb-3">
                    {t('convention.model.unlink_installer.body')}
                  </p>
                  <div className="pt-6 flex items-center justify-end space-x-4">
                    <div>
                      <ButtonOpx
                        label={`${t('buttons.cancel')}`}
                        type="secondary"
                        onClick={() => setModal(false)}
                        addClass="px-4 py-[0.625rem] !px-[3.5rem]"
                      />
                    </div>
                    <div>
                      <ButtonOpx
                        label={`${t('buttons.confirm')}`}
                        type="primary"
                        onClick={() => deleteInvitedInstaller()}
                        isLoading={loading}
                        addClass="px-4 py-[0.625rem] !px-[3.5rem]"
                      />
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { InstallerFollowUpCard };
