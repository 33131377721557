import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { resetPassword } from '@models/auth/apiRequests/authRequests';
import { InputText } from '@components/atomic/inputs/InputText';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import { getResetPasswordInfoSchema } from '@utils/validationSchemas';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AUTH_ROUTES } from '@utils/routesUrls';

function ResetPassword() {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm({
    resolver: yupResolver(getResetPasswordInfoSchema(t)),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formData: FieldValues) => {
    setLoading(true);
    const { password, confirmPassword } = formData;
    await resetPassword({
      email,
      token,
      password,
      password_confirmation: confirmPassword,
    });
    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className="w-full">
        <p className="mb-2 text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('auth.reset_pwd.title')}
        </p>
        <p className="mb-6 text-sm font-normal leading-[1.25rem]">
          {t('auth.reset_pwd.details')}
        </p>
        <FormProvider {...methods}>
          <form id="form-reset-pwd" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full">
              <div className="space-y-6">
                <div className="space-y-4">
                  <p className="text-base font-medium leading-5">
                    {t('forms.password.placeholder')}
                  </p>
                  <InputText
                    id="password"
                    name="password"
                    placeholder={t('forms.password.new_pwd')}
                    required
                    type="password"
                  />
                </div>
                <div className="space-y-4">
                  <p className="text-base font-medium leading-5">
                    {t('forms.password.confirm_pwd')}
                  </p>
                  <InputText
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder={t('forms.password.confirm_pwd')}
                    required
                    type="password"
                  />
                </div>
                <Link
                  to={AUTH_ROUTES.LOGIN}
                  className="text-blueOpx block w-full text-sm text-right"
                >
                  {t('auth.back_to_login')}
                </Link>
                <ButtonOpx
                  label={t('auth.reset_pwd.submit')}
                  formId="form-reset-pwd"
                  isSubmitBtn
                  disabled={loading}
                  dataTestId="reset_pwd_button"
                  addClass="w-full"
                  isLoading={loading}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </AuthLayout>
  );
}

export { ResetPassword };
