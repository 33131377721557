import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { black, blueOpx, textGreyInfos } from '@assets/color';
import { IDonutChartCategory, IDonutChartDatas } from '../utils/dashboardTypes';

const fakeDatas: IDonutChartDatas = {
  labelCenter: 'Volume engagé',
  valueCenter: 31.7,
  unit: 'GWhc',
  categories: [
    {
      label: 'Volume engagé classique',
      value: 13.5,
    },
    {
      label: 'Volume engagé précaire',
      value: 34.5,
    },
    {
      label: 'Volume restant',
      value: 8.6,
    },
  ],
};

const colors = [blueOpx, '#405AF3', '#4F95FF1A'];

const options = {
  chart: {
    type: 'pie',
    height: '237px',
    backgroundColor: '#FFFFFF',
    width: 460,
  },
  title: {
    verticalAlign: 'middle',
    align: 'center',
    text: `${fakeDatas.valueCenter.toLocaleString('fr-FR')} ${fakeDatas.unit}`,
    style: {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'medium',
      fontWeight: '500',
      lineHeight: '32px',
      color: black,
    },
    y: 4,
    x: -107,
  },
  subtitle: {
    text: fakeDatas.labelCenter,
    verticalAlign: 'middle',
    align: 'center',
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '12px',
      color: textGreyInfos,
    },
    y: 23,
    x: -107,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      startAngle: 0,
      endAngle: 0,
      verticalAlign: 'middle',
      align: 'center',
      showInLegend: true,
    },
  },
  series: [
    {
      innerSize: '86%',
      enableMouseTracking: false,
      data: fakeDatas.categories.map(
        (data: IDonutChartCategory, i: number) => ({
          name: data.label,
          y: data.value,
          color: colors[i],
        })
      ),
    },
  ],
  legend: {
    enabled: true,
    floating: false,
    useHTML: true,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    itemMarginBottom: 8,
    itemStyle: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      color: textGreyInfos,
    },
    symbolHeight: 10,
    symbolWidth: 16,
    symbolRadius: 2,
    squareSymbol: false,
    symbolPadding: 25,
    y: 18,
    labelFormatter() {
      const value = this.y; // ou une autre logique pour trouver la valeur correspondante
      return `
        <div style='margin-top: 4px'>
          ${(this as any).name}<br>
          <div style='font-size: 14px; font-weight: 500; color: ${black}'>
            ${value.toLocaleString('fr-FR')} ${fakeDatas.unit}
          </div>
        </div>
      `;
    },
  },
};

function DonutChart() {
  return (
    <div className="flex items-center bg-white w-[500px] border border-borderGrey rounded-default">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default DonutChart;
