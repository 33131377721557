import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { isBeforeDate } from '@utils/functions';
import { storeCofracDate } from '@models/cofrac/apiRequests/cofracRequests';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { toAPIDateStr } from '@utils/format';

interface CofracDateProps {
  setModal: Dispatch<SetStateAction<string>>;
  cofrac: ICofracType | undefined;
  setCofrac: Dispatch<SetStateAction<ICofracType | undefined>>;
}

function CofracDate({
  setModal,
  cofrac,
  setCofrac,
}: CofracDateProps): JSX.Element {
  const [date, setDate] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  const onSubmit = () => {
    if (cofrac && !error) {
      storeCofracDate(cofrac.id, date, 'cofrac_date').then((res) => {
        if (res.data) {
          setCofrac(res.data);
          setModal('');
        }
      });
    }
  };

  return (
    <div>
      <Modal
        title={t('cofrac.btn_date')}
        buttonsPosition="bottom"
        backgroundTransparent
        sidebarVisible
        textBtnConfirm={`${t('buttons.validate')}`}
        onConfirmClick={onSubmit}
        btnCancel
        onClickCancel={() => setModal('')}
      >
        <div>
          <p>{t('cofrac.form.date_text')}</p>
          <DatePicker
            addClass="mt-5"
            label={`${t('cofrac.form.date_placeholder')}`}
            required
            onChangeDate={(e) => {
              const currentDate = new Date().toJSON().slice(0, 10);
              if (isBeforeDate(e, currentDate)) setError(true);
              else {
                setDate(toAPIDateStr(e));
                setError(false);
              }
            }}
            error={error}
            textError={`${t('deposits.errors.start_date')}`}
          />
        </div>
      </Modal>
    </div>
  );
}

export { CofracDate };
