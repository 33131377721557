export const intialConvention = {
  id: 0,
  reference: '',
  start_date: '',
  end_date: '',
  volume_classique: 0,
  volume_precaire: 0,
  status: 0,
  signature_status: 0,
  incentive_type: 0,
  installer: {
    id: 0,
    type: 0,
    company_name: '',
    zipcode: '',
    city: '',
    address: '',
    subcontractors: [],
  },
  from: {
    id: 0,
    type: 0,
    company_name: '',
    zipcode: '',
    city: '',
    address: '',
  },
  rai: {
    id: 0,
    type: 0,
    company_name: '',
    zipcode: '',
    city: '',
    address: '',
  },
  contract: null,
  created_at: '',
  offer_period_invalid: false,
  minimum_bonus_allocation: 0,
};
