/* eslint-disable no-nested-ternary */
import { red } from '@assets/color';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import { convertHexToRGBA } from '@utils/functions';
import {
  alphabeticRegex,
  alphanumericRegex,
  emailValidationRegex,
  nameRegex,
  numberRegex,
  phoneNumberRegex,
} from '@utils/regex';
import React, { Dispatch, SetStateAction } from 'react';
import { IContractPartnerInfo } from '@models/contractCreation/utils/contractCreationTypes';
import {
  ISelectFieldParamType,
  ITextFieldParamType,
} from '@models/contracts/utils/newContractTypes';

export const renderTextField = (params: ITextFieldParamType) => {
  return (
    <div className={params.className}>
      <InputText
        onChange={params.onChange}
        label={params.label !== undefined ? params.label : params.placeholder}
        id={params.name}
        name={params.name}
        placeholder={params.placeholder || ''}
        required={params.required || false}
        disabled={params.disabled}
        addClass="mb-2"
        error={!params.isValid}
        value={params.valuesForm[params.name] || ''}
        typeNumber={params.typeNumber}
        textError={params.textError}
      />
    </div>
  );
};

export const renderSelectField = (params: ISelectFieldParamType) => {
  return (
    <div className={`${params.outerClassName}`}>
      <InputSelect
        label={params.label || params.placeholder}
        dataArrayString={params.data}
        onSelect={(value) => params.onSelect(value)}
        defaultSelected={params.defaultSelected}
        placeholder={params.placeholder || ''}
        valueInput={params.valueInput}
        error={params.error}
        addClass={params.className}
        isAutoComplete={params.isAutoComplete}
        callbackOnSearch={params.callbackOnsearch}
        showClearButton={params.showClearButton}
        required={params.required}
      />
      {params.error && params.textError && (
        <div
          style={{ backgroundColor: convertHexToRGBA(red, 0.1) }}
          className="text-red p-[.5rem] rounded-b-default text-[.625rem] mt-[.5rem]"
        >
          {params.textError}
        </div>
      )}
    </div>
  );
};

export const isValidField = (
  value: string | null,
  type?: 'email' | 'phone' | 'alpha' | 'num' | 'siret' | 'siren' | 'name',
  nullable = false
) => {
  if (!value || value === '') return nullable;

  let regex = alphanumericRegex;
  if (type === 'email') regex = emailValidationRegex;
  if (type === 'phone') regex = phoneNumberRegex;
  if (type === 'alpha') regex = alphabeticRegex;
  if (type === 'num') regex = numberRegex;
  if (type === 'name') regex = nameRegex;

  if (type === 'siret' && value.length !== 14) return false;
  if (type === 'siren' && value.length !== 9) return false;

  return regex.test(value);
};

export const setContactInfo = (
  key: string,
  value: string | number,
  updateInfosPartner: Dispatch<SetStateAction<IContractPartnerInfo>>,
  contact?: 'signataire' | 'contact'
) => {
  updateInfosPartner((prevInfosPartner) => {
    if (contact) {
      const updatedContact = {
        ...prevInfosPartner[contact],
        [key]: value,
      };

      return { ...prevInfosPartner, [contact]: updatedContact };
    }
    return { ...prevInfosPartner, [key]: value };
  });
};
