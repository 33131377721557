/* eslint-disable no-nested-ternary */
import { blueOpx } from '@assets/color';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon } from '@assets/images/svgComponents';
import { OperationTypeEnum } from '@utils/enums';
import { WORKSITE_AGE } from '@models/worksiteCreation/utils/enums';

function InfoGeneralSummary() {
  const { t } = useTranslation();
  const { simulatorData, replacedEnergy, worksiteOperationType } = useContext(
    WorksiteCreationContext
  );

  const dataInfosGeneral = {
    type_habitation: {
      title: t('worksite_creation.simulation.habitation.title'),
      subtitle:
        simulatorData['general.habitation'] === 'house'
          ? t('worksite_creation.simulation.habitation.house')
          : t('worksite_creation.simulation.habitation.apartment'),
    },
    age: {
      title: t('worksite_creation.simulation.age.title'),
      subtitle:
        simulatorData['general.age'] === WORKSITE_AGE.YOUNG
          ? t('worksite_creation.simulation.age.young')
          : simulatorData['general.age'] === WORKSITE_AGE.BETWEEN
          ? t('worksite_creation.simulation.age.between')
          : t('worksite_creation.simulation.age.old'),
    },
    surface: {
      title: t('worksite_creation.simulation.surface'),
      subtitle: `${simulatorData['general.surface']} m2` || '',
    },
    replacedEnergy: {
      title: t('worksites.housing.removal'),
      subtitle: replacedEnergy?.label,
    },
  };

  return worksiteOperationType === OperationTypeEnum.B2B ? null : (
    <div className="mb-[1rem] border border-borderGrey rounded-default">
      <div className="border-b border-b-borderGrey">
        <p className="p-[1.5rem] text-[1.25rem] font-medium">
          {worksiteOperationType === OperationTypeEnum.B2B // b2b
            ? t('worksites.housing.title_b2b')
            : t('worksites.housing.title')}
        </p>
      </div>
      <div className="p-[1.5rem] grid grid-cols-2 gap-4">
        <InfosWithIcon
          colorIcon={blueOpx}
          icon={<HomeIcon />}
          infos={[
            {
              title: dataInfosGeneral.type_habitation.title,
              subtitle: dataInfosGeneral.type_habitation.subtitle,
            },
          ]}
        />

        <InfosWithIcon
          spaceLeft
          infos={[
            {
              title: dataInfosGeneral.age.title,
              subtitle: dataInfosGeneral.age.subtitle,
            },
          ]}
        />
        {simulatorData['general.surface'] && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: dataInfosGeneral.surface.title,
                subtitle: dataInfosGeneral.surface.subtitle.toString(),
              },
            ]}
          />
        )}
        {replacedEnergy && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: dataInfosGeneral.replacedEnergy.title,
                subtitle: dataInfosGeneral.replacedEnergy.subtitle || '',
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}

export { InfoGeneralSummary };
