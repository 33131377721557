import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { WorksitesList } from '@models/worksites/components/WorksitesList';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { initialStateWorksiteDetails } from '@utils/initialState';
import { GlobalContext } from '@context/globalContext';
import { WorksiteDetails } from '@models/worksites/components/WorksiteDetails';
import { SubHeader } from '@components/subHeader/SubHeader';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ROLES } from '@utils/roles';
import { WORK_SITE_DETAILS_TABS } from '@models/worksites/utils/tab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '@context/authContext';
import { toast } from 'react-toastify';
import { HeaderContext } from '@context/headerContext';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import {
  AddFatIcon,
  ContactPageIcon,
  DownloadIcon,
} from '@assets/images/svgComponents';
import {
  FilterRequestData,
  ISortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { RequestsAndChangesCard } from '@components/requestsAndChanges/card/RequestsAndChangesCard';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { DIMENSIONS } from '@utils/utils';
import { exportVue } from '@models/worksites/apiRequests/worksitesRequests';
import { getKeyByValue } from '@models/worksites/utils/utils';
import { Loader } from '@components/atomic/Loader';
import {
  BENEFICIARIES_ROUTES_WITH_ID,
  WORKSITES_ROUTES,
  WORKSITES_ROUTES_WITH_ID,
} from '@utils/routesUrls';
import { FilterCriteriaEnum } from '@utils/enums';
import { IAhExtraDataItem } from '../types/globalTypes';

interface IWorksitesProps {
  adminMode?: boolean;
}

function Worksites({ adminMode }: IWorksitesProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const {
    globalEnum,
    roleUser,
    updateAddDocumentModalIsActive,
    userView,
    updateDisplayModalUpgradeSubscription,
  } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const { refreshHeader } = useContext(HeaderContext);

  const [worksitesListDatas, setWorksitesListDatas] = useState<
    IWorksiteDetails[]
  >([]);
  const [worksiteId, setWorksiteId] = useState<string>(id || '');
  const [worksiteDetails, setWorksiteDetails] = useState<IWorksiteDetails>(
    initialStateWorksiteDetails
  );

  const [tabActive, setTabActive] = useState<string>('');

  const preFilter = useMemo((): FilterRequestData[] => {
    const filterData: FilterRequestData[] = [];
    const company = query.get('company');
    if (company) {
      filterData.push({
        criteria: FilterCriteriaEnum.ENTITY,
        ids: [parseInt(company as string, 10)],
      });
    }
    return filterData;
  }, [query]);

  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>({ filters: preFilter });

  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const [ahGeneralData, setAhGeneralData] = useState<IAhExtraDataItem[]>();

  const exportListVue = () => {
    const status = getKeyByValue(globalEnum.worksite_ui_status, tabActive, t);
    exportVue(
      setExportLoading,
      String(userView?.company_name),
      tabActive,
      status,
      sortAndFilterData
    );
  };

  const handleNavigationButtonClick = (label: string) => {
    switch (label) {
      case t('worksites.new_work_site'):
        navigate(WORKSITES_ROUTES.WORKSITES_NEW);
        break;
      case t('global.document_import'):
        updateAddDocumentModalIsActive(true);
        break;
      case t('worksites.exportvue'):
        exportListVue();
        break;
      default:
        break;
    }
  };

  const [leftPartSubHeader, setLeftPartSubHeader] = useState<JSX.Element>(
    <div />
  );

  const isWorksitesList = useMemo(() => !id, [id]);

  const rightPartSubHeader: JSX.Element = (
    <>
      {isWorksitesList && (
        <div className="flex gap-3">
          <ButtonOpx
            label={t('worksites.exportvue')}
            icon={exportLoading ? <Loader /> : <DownloadIcon />}
            type="secondary"
            onClick={() => {
              if (user?.is_freemium) {
                updateDisplayModalUpgradeSubscription(true);
              } else {
                handleNavigationButtonClick(t('worksites.exportvue'));
              }
            }}
          />
          <SortAndFilter
            page={adminMode ? 'ADMIN_CHANTIERS' : 'CHANTIERS'}
            onSort={(column, direction) =>
              sortList(column, direction, setSortAndFilterData)
            }
            onFilter={(filters) => filterList(filters, setSortAndFilterData)}
            preFilter={preFilter}
          />
        </div>
      )}
      {isWorksitesList &&
        [ROLES.PRODUCTION, ROLES.GESTION].includes(roleUser) &&
        user?.permissions_names.includes('creer-chantier') && (
          <ButtonOpx
            label={t('worksites.new_work_site')}
            icon={<AddFatIcon />}
            type="primary"
            onClick={() =>
              handleNavigationButtonClick(t('worksites.new_work_site'))
            }
            dataTestId="new_worksite"
          />
        )}
      {!isWorksitesList && (
        <div className="flex gap-3 items-center">
          {roleUser === ROLES.GESTION &&
            tabActive === WORK_SITE_DETAILS_TABS(t).DOCS && (
              <ButtonOpx
                label={t('global.document_import')}
                type="secondary"
                onClick={() =>
                  handleNavigationButtonClick(t('global.document_import'))
                }
              />
            )}
          {worksiteDetails.status > 1 && worksiteDetails.uuid && (
            <ButtonOpx
              icon={<ContactPageIcon />}
              iconSize="1rem"
              label={t('buttons.beneficiary_view')}
              onClick={() => {
                const baseUrl = window.location.origin;
                const link = `${baseUrl}${
                  BENEFICIARIES_ROUTES_WITH_ID(worksiteDetails.uuid as string)
                    .BENEFICIARY_WORKSITE_VIEW
                }`;
                window.open(link, '_blank');
              }}
              type="secondary"
            />
          )}
        </div>
      )}
    </>
  );

  const worksitesContextValue = useMemo(
    () => ({
      worksitesListDatas,
      updateWorksitesListDatas: setWorksitesListDatas,
      worksiteId,
      updateWorksiteId: setWorksiteId,
      worksiteDetails,
      updateWorksiteDetails: setWorksiteDetails,
      leftPartSubHeader,
      updateLeftPartSubHeader: setLeftPartSubHeader,
      rightPartSubHeader,
      tabActive,
      updateTabActive: setTabActive,
      ahGeneralData,
      updateAhGeneralData: setAhGeneralData,
    }),
    [
      worksitesListDatas,
      worksiteId,
      globalEnum,
      worksiteDetails,
      rightPartSubHeader,
      leftPartSubHeader,
      tabActive,
      ahGeneralData,
    ]
  );

  const cantAccessWorksites = useMemo(
    () => !user?.permissions_names.includes('gerer-chantier'),
    [user]
  );

  useEffect(() => {
    if (!isWorksitesList) {
      // redirect to nouveau-chantier:id if ever status is still in creation
      if (worksiteDetails.status === WORKSITE_STATUS.WORKSITE_CREATION)
        navigate(WORKSITES_ROUTES_WITH_ID(id as string).WORKSITES_NEW_ID, {
          replace: true,
        });

      setWorksiteId(id as string);

      if (tabActive === '') {
        setTabActive(WORK_SITE_DETAILS_TABS(t).OP);
      }
    }
  }, [isWorksitesList, id, worksiteDetails]);

  useEffect(() => {
    if (user && cantAccessWorksites) {
      toast(t('global.no_permission'), {
        type: 'error',
      });
      navigate('/', { replace: true });
    }
    refreshHeader();

    // (window as any).Beacon('close');
  }, []);

  return (
    <WorksitesContext.Provider value={worksitesContextValue}>
      <div className="flex">
        <div
          className="w-full"
          style={{
            paddingRight: isWorksitesList
              ? 0
              : DIMENSIONS.requests_and_changes_width,
          }}
          data-test-id="worksites"
        >
          <SubHeader
            leftPart={leftPartSubHeader}
            rightPart={rightPartSubHeader}
          />
          {!cantAccessWorksites &&
            (isWorksitesList ? (
              <WorksitesList
                sortAndFilterData={sortAndFilterData}
                adminMode={adminMode}
              />
            ) : (
              <WorksiteDetails adminMode={adminMode} />
            ))}
        </div>

        {!isWorksitesList && <RequestsAndChangesCard />}
      </div>
    </WorksitesContext.Provider>
  );
}

Worksites.defaultProps = {
  adminMode: false,
};

export { Worksites };
