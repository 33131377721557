/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { black } from '@assets/color';
import { convertHexToRGBA } from '@utils/functions';
import { useContext, useEffect } from 'react';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { DIMENSIONS } from '@utils/utils';
import { HeaderCardModalRequestAndChanges } from './HeaderCardModalRequestAndChanges';
import { ListRequestsInModal } from './ListRequestInModal';

function RequestsAndChangesCardModal() {
  const { requestOrChangeEnumInModal, updateRequestOrChangeEnumInModal } =
    useContext(RequestAndActivityIntoModalOrCardContext);
  const modalIsOpen = requestOrChangeEnumInModal !== null;
  const request =
    requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.REQUEST;

  const handleParentClick = () => {
    updateRequestOrChangeEnumInModal(null);
  };

  const handleChildClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (modalIsOpen) {
      // Désactiver le défilement lorsque le modal est ouvert
      document.body.style.overflow = 'hidden';
    } else {
      // Réactiver le défilement lorsque le modal est fermé
      document.body.style.overflow = '';
    }

    // Nettoyage : réactiver le défilement lorsque le composant est démonté
    return () => {
      document.body.style.overflow = '';
    };
  }, [modalIsOpen]);

  return (
    <div
      style={{
        height: modalIsOpen
          ? `calc(100vh - ${parseFloat(DIMENSIONS.header_height)}rem)`
          : '',
        zIndex: modalIsOpen ? 100 : -1,
        position: modalIsOpen ? 'absolute' : 'relative',
        backgroundColor: modalIsOpen
          ? convertHexToRGBA(black, 0.2)
          : 'transparent',
        top: DIMENSIONS.header_height,
      }}
      className="w-full right-0 absolute"
      onClick={handleParentClick}
    >
      <div
        style={{
          minWidth: modalIsOpen
            ? DIMENSIONS.requests_and_changes_width
            : '0rem',
          width: modalIsOpen ? DIMENSIONS.requests_and_changes_width : '0rem',
          transition: 'all 0.5s',
          height: `calc(100vh - ${parseFloat(DIMENSIONS.header_height)}rem)`,
        }}
        className="bg-white w-auto absolute right-0 overflow-hidden"
        onClick={(e) => handleChildClick(e)}
      >
        <HeaderCardModalRequestAndChanges />
        {request && <ListRequestsInModal />}
      </div>
    </div>
  );
}

export { RequestsAndChangesCardModal };
