/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import { urlGetSignatories, urlUpdateEntity } from '@api/apiUrls';
import { FieldValues } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { IPartnerView } from '@models/partners/utils/types/partnersType';

export const updateEntityInfos = async (
  id: number,
  data: FieldValues,
  updateUserView?: Dispatch<SetStateAction<IPartnerView | null>>
) => {
  try {
    const formData = new FormData();

    if (data.logo === null) {
      formData.append('logo', '');
    } else {
      formData.append('file', data.logo || '');
    }

    if (data.iban) {
      formData.append('iban_payment', data.iban);
    }

    const res = await axiosClient.post(urlUpdateEntity(id), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (updateUserView) {
      const entityData = res.data.data;

      updateUserView((prevState) =>
        prevState
          ? {
              ...prevState,
              logo: entityData.logo || '',
              company_name: entityData.company_name || '',
            }
          : null
      );
    }

    return res.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getSignatories = async (id: number, email?: string | null) => {
  try {
    const response = await axiosClient.post(urlGetSignatories(id), { email });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};
