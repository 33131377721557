const backgroundBody = '#F4F4F4';
const white = '#FFFFFF';
const black = '#121212';

const greyOpacity20 = '#90909033';
const borderGrey = '#EEEEEE';

// Primary color
const blueOpx = '#1730BF';
// const blueAuthBg = '#2A30BF';
const blueSecondary = '#4F95FF';
const darkBlue = '#0D164F';
const blueOpacity = '#E0E3F6';

const backgroundGrey = '#F7F7FB';
const grey = '#EEEEEE';
const lightGrey = '#D0D0D0';
const purple = '#916BE2';

// Text color
const textGrey = '#909090';
const textGreyInfos = '#A5A5A5';

// Icones color
const iconBlue = '#4F95FF';
const iconBlueOpacity = '#4F95FF10';
const lightBlue = '#46BDE2';

// Secondary color
const green = '#50BA87';
const darkGreen = '#285D43';
const lightGreen = '#C7E9D8';
const red = '#E43E3E';
const lightRed = '#F8D7DA';
const orange = '#FF8717';

export {
  orange,
  white,
  black,
  blueOpx,
  darkBlue,
  blueOpacity,
  backgroundBody,
  textGrey,
  iconBlue,
  iconBlueOpacity,
  greyOpacity20,
  grey,
  lightGrey,
  backgroundGrey,
  borderGrey,
  green,
  red,
  textGreyInfos,
  blueSecondary,
  purple,
  lightRed,
  lightGreen,
  lightBlue,
  darkGreen,
};
