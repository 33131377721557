/* eslint-disable no-shadow */
// ENUM DEMANDES OU ACTIVITES
enum RequestOrMessageOrChangeEnum {
  CHANGE,
  REQUEST,
  MESSAGE,
}

enum AddressType {
  CORRESPONDANCE,
  CHANTIER,
  BAILLEUR,
}

enum OperationTypeEnum {
  B2C = 1,
  B2B = 2,
  GLOBAL_RENOVATION = 3,
}

enum InvitedInstallersStatusEnum {
  PENDING = 1,
  ACTIVE = 2,
  ALL = 3,
}

export enum fileTypeEnum {
  AH = 1,
  ATTESTATION = 2,
  CADRE = 3,
  DEVIS = 4,
  FACTURE = 5,
  PHOTOSAV = 6,
  AH_INDIVISION = 7,
  AVIS_IMPOSITION = 8,
  JUSTIFICATIF_PROPRIETE = 9,
  AH_BAILLEUR = 10,
  NOTIFICATION_OCTROI = 11,
  PHOTO_AVANT_CHANTIER = 12,
  FICHE_TECHNIQUE = 13,
  RAPPORT_AUDIT_ENERGETIQUE = 14,
  NOTE_DE_DIMENSIONNEMENT = 15,
  AUTRE = 16,
  CONTRAT = 17,
  CONTRAT_MANDANT = 1701,
  CONVENTION = 18,
  COFRAC_REPORT_FILE = 19,
  COFRAC_SUMMARY_REPORT_FILE = 20,
  PHOTO_APRES_CHANTIER = 21,
  DEVIS_SIGNE = 22,
  PHOTO_CALORIFUGEAGE = 23,
  PHOTO_VANNE_EQUILIBRAGE = 24,
  SUBCONTRACTOR_FILE = 25,
  RGE = 26,
  AUDIT_SYNTHESIS = 27,
  AUDIT_REPORT = 28,
  AUDIT_COFRAC_REPORT = 29,
  EXTERNAL_CONVENTION = 30,
  ANNEXE = 31,
  CONTRAT_PARTENARIAT = 32,
  PHOTO_HORODATEE = 33,
  KBIS = 101,
  JUSTIFICATIF_IDENTITE = 102,
  RC_PRO = 103,
  ATTESTATION_REGULARITE_SOCIALE = 104,
  ASSURANCE_DECENNALE = 105,
  CUSTOM_FIELD = 9999,
}

enum CompanyActivityStatusEnum {
  INACTIVE = 1,
  ACTIVE = 2,
  GHOST = 3,
  BLOCKED = 4,
  BANKRUPTED = 5,
}

enum FilterCriteriaEnum {
  COMPANY_NAME = 15,
  ENTITY = 21,
}

export {
  AddressType,
  OperationTypeEnum,
  CompanyActivityStatusEnum,
  RequestOrMessageOrChangeEnum,
  FilterCriteriaEnum,
  InvitedInstallersStatusEnum,
};
