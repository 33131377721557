import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

function DeliveryOpSekeleton() {
  return (
    <div className="w-full bg-white border border-borderGrey rounded-default flex items-center justify-center flex-wrap my-4">
      <div className="w-full md:w-[30%] md:border-r md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex items-center">
          <LoaderSkeleton height="1.5rem" addClass="w-[10rem] md:mr-[0.5rem]" />
          <LoaderSkeleton height="1.5rem" addClass="w-[3rem]" />
        </div>
      </div>
      <div className="w-full md:w-[70%] border-t md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex items-center justify-end">
          <LoaderSkeleton height="1.5rem" addClass="w-[5rem] md:mr-[0.5rem]" />
          <LoaderSkeleton height="1.5rem" addClass="w-[3rem]" />
        </div>
      </div>
      <div className="w-full p-[1rem] border-t border-borderGrey flex flex-justify-between flex-wrap">
        <div className="w-full md:w-[50%]">
          <div className="flex items-center">
            <LoaderSkeleton
              height="1.5rem"
              addClass="w-[10rem] md:mr-[0.5rem]"
            />
            <LoaderSkeleton
              height="1.5rem"
              addClass="w-[10rem] md:mr-[0.5rem]"
            />
            <LoaderSkeleton height="1.5rem" addClass="w-[10rem]" />
          </div>
        </div>
        <div className="w-full md:w-[50%] flex md:justify-end">
          <LoaderSkeleton height="1.5rem" addClass="w-[10rem] md:mr-[0.5rem]" />
          <LoaderSkeleton height="1.5rem" addClass="w-[10rem] md:mr-[0.5rem]" />
          <LoaderSkeleton height="1.5rem" addClass="w-[10rem]" />
        </div>
      </div>
    </div>
  );
}

export { DeliveryOpSekeleton };
