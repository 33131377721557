export const DATE_MODAL = {
  DATE: 'date',
  OK: 'ok',
  KO: 'ko',
  OK_OPERATIONS: 'ok_operations',
  KO_OPERATIONS: 'ko_operations',
};

export const COFRAC_STATUS = {
  EN_CREATION: 1,
  EN_COURS: 2,
  SATISFAISANT: 3,
  INSATISFAISANT: 4,
};

export const COFRAC_OPERATION_STATUS = {
  SATISFAISANTE: 1,
  INSATISFAISANTE: 2,
  NON_CONTROLEE: 3,
  NON_VERIFIABLE: 4,
  CORRIGEE: 5,
};
