import { Layout } from '@pages/Layout';
import Router from './Router';

function App(): JSX.Element {
  return (
    <Layout>
      <Router />
    </Layout>
  );
}

export default App;
