import { useContext, useEffect } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import {
  BeneficiaryTypes,
  CreationSteps,
} from '@models/contractCreation/utils/enums';
import { StepGeneral } from '@models/contractCreation/components/steps/general/StepGeneral';
import { StepVolumesCee } from '@models/contractCreation/components/steps/volumes/StepVolumesCee';
import { StepPartner } from '@models/contractCreation/components/steps/StepPartner';
import { StepReferents } from '@models/contractCreation/components/steps/StepReferents';
import { StepPaymentConditions } from '@models/contractCreation/components/steps/StepPaymentConditions';
import { StepOperationPrices } from '@models/contractCreation/components/steps/operationPrices/StepOperationPrices';
import { StepIntermediaryBusiness } from '@models/contractCreation/components/steps/StepIntermediaryBusiness';
import { StepDocuments } from '@models/contractCreation/components/steps/StepDocuments';
import { initialContractContact } from '@utils/initialState';
import { initialWorksite } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { StepBeneficiaryPerson } from '@models/contractCreation/components/steps/beneficiary/StepBeneficiaryPerson';
import { StepInvitation } from './steps/StepInvitation';

function ContractStepsContainer() {
  const { activeStep, updateEntityTo, beneficiaryType, updateBeneficiary } =
    useContext(ContractCreationContext);

  useEffect(() => {
    if (activeStep === CreationSteps.BENEFICIARY) {
      if (beneficiaryType === BeneficiaryTypes.ENTITY) {
        updateBeneficiary(initialWorksite.beneficiary);
      } else if (beneficiaryType === BeneficiaryTypes.PERSON) {
        updateEntityTo(initialContractContact);
      }
    }
  }, [activeStep, beneficiaryType]);

  switch (activeStep) {
    case CreationSteps.GENERAL:
      return <StepGeneral />;
    case CreationSteps.VOLUMES_CEE:
      return <StepVolumesCee />;
    case CreationSteps.PARTNER:
      return <StepPartner />;
    case CreationSteps.BENEFICIARY:
      return beneficiaryType === BeneficiaryTypes.PERSON ? (
        <StepBeneficiaryPerson />
      ) : (
        <StepPartner />
      );
    case CreationSteps.AMO:
      return <StepPartner />;
    case CreationSteps.REFERENTS:
      return <StepReferents />;
    case CreationSteps.OPERATIONS:
      return <StepOperationPrices />;
    case CreationSteps.BUSINESS:
      return <StepIntermediaryBusiness />;
    case CreationSteps.PAYMENT:
      return <StepPaymentConditions />;
    case CreationSteps.DOCUMENTS:
      return <StepDocuments />;
    case CreationSteps.INVITATION:
      return <StepInvitation />;
    default:
      return <div />;
  }
}

export { ContractStepsContainer };
