/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DepositPageHeader } from '@models/deposits/components/DepositPageHeader';
import { OperationList } from '@models/contracts/components/OperationList';
import { ModalDateDeposit } from '@models/deposits/components/ModalDateDeposit';

import {
  depositLinkOperation,
  depositUnLinkOperation,
  getLinkedOperations,
  getOperationsToLink,
} from '@models/deposits/apiRequests/depositRequests';

import { ICardOperationType } from '@models/contracts/utils/contractTypes';
import { ConsultList } from '@models/lots/components/ConsultList';
import { IDepositType } from '@models/deposits/utils/depositsTypes';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';

interface ITabDepositFollowUpProps {
  deposit: IDepositType;
  setDeposit: Dispatch<SetStateAction<IDepositType | undefined>>;
  modalDateDepot: boolean;
  setModalDateDepot: (val: boolean) => void;
  setSelectedTab: (val: string) => void;
  setEmmyButtonEnabled: (val: boolean) => void;
  getDataDeposit: () => void;
}

function TabDepositFollowUp(props: ITabDepositFollowUpProps) {
  const {
    deposit,
    modalDateDepot,
    setModalDateDepot,
    setSelectedTab,
    setDeposit,
    setEmmyButtonEnabled,
    getDataDeposit,
  } = props;
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState({
    linked: false,
    toLink: false,
  });

  const [dataLinked, setDataLinked] = useState<ICardOperationType[]>([]);
  const [dataToLink, setDataToLink] = useState<ICardOperationType[]>([]);

  const [searchLinked, setSearchLinked] = useState<string | null>(null);
  const [searchToLink, setSearchToLink] = useState<string | null>(null);
  const [sortAndFilterLinked, setSortAndFilterLinked] =
    useState<ISortAndFilterType>();
  const [sortAndFilterToLink, setSortAndFilterToLink] =
    useState<ISortAndFilterType>();

  const getData = async (
    sLinked?: string,
    sToLink?: string,
    refreshLinked?: boolean,
    refreshToLink?: boolean,
    refreshDeposit?: boolean
  ) => {
    setIsLoading({
      linked: refreshLinked || false,
      toLink: refreshToLink || false,
    });

    if (deposit.status !== 1) {
      setIsLoading({
        linked: false,
        toLink: false,
      });
      return false;
    }

    const fetchBoth = sLinked === undefined && sToLink === undefined;
    const fetchLinked = fetchBoth || sLinked !== undefined;
    const fetchToLink = fetchBoth || sToLink !== undefined;

    if (refreshLinked || sLinked) {
      const resLinked = fetchLinked
        ? await getLinkedOperations(
            deposit.id,
            sLinked !== undefined ? sLinked : searchLinked,
            setIsLoading,
            sortAndFilterLinked
          )
        : await new Promise((myResolve) => {
            myResolve(dataLinked);
          });

      const res1 = !fetchLinked
        ? resLinked
        : resLinked
        ? resLinked.data.map((item: any) => {
            return {
              id: item.worksite_operation_id,
              code: item.worksite_operation.operation.code,
              description: item.worksite_operation.operation.description,
              precarity: item.worksite_operation.worksite.precarity,
              volume_classique: item.worksite_operation.kwhc_classique,
              volume_precaire: item.worksite_operation.kwhc_precaire,
              price: item.worksite_operation.cee_amount,
              price_precaire: item.worksite_operation.cee_precaire,
              price_classique: item.worksite_operation.cee_classique,
              beneficiary: item.worksite_operation.worksite.beneficiary,
              installer: item.worksite_operation.worksite.installer,
              line_id: item.id,
            };
          })
        : [];
      setDataLinked(res1);
      setEmmyButtonEnabled(res1.length > 0);
    }

    if (refreshToLink || sToLink) {
      const resToLink = fetchToLink
        ? await getOperationsToLink(
            deposit.rai.id,
            sToLink !== undefined ? sToLink : searchToLink,
            setIsLoading,
            sortAndFilterToLink
          )
        : await new Promise((myResolve) => {
            myResolve(dataToLink);
          });

      const res2 = !fetchToLink
        ? resToLink
        : resToLink
        ? resToLink.data.map((item: any) => {
            return {
              id: item.id,
              code: item.operation.code,
              description: item.operation.description,
              precarity: item.worksite.precarity,
              volume_classique: item.volume_classique_restant,
              volume_precaire: item.volume_precaire_restant,
              price: item.cee_amount,
              price_precaire: item.cee_precaire,
              price_classique: item.cee_classique,
              beneficiary: item.worksite.beneficiary,
              installer: item.worksite.installer,
            };
          })
        : [];
      setDataToLink(res2);
    }

    if (refreshDeposit) getDataDeposit();

    return true;
  };

  useEffect(() => {
    getData(undefined, undefined, true, true);
  }, []);

  useEffect(() => {
    if (sortAndFilterLinked) getData(undefined, undefined, true, undefined);
  }, [sortAndFilterLinked]);

  useEffect(() => {
    if (sortAndFilterToLink) getData(undefined, undefined, undefined, true);
  }, [sortAndFilterToLink]);

  if (!deposit || !deposit.id) return <div />;

  setDeposit(deposit);
  return (
    <>
      <DepositPageHeader data={deposit} page="Suivi" />

      {deposit.status === 1 ? (
        <div className="w-full flex flex-wrap">
          {!deposit.is_external && (
            <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
              <OperationList
                model="deposit"
                cardTitle={t('deposits.section_ready_for_depot')}
                buttonList={['add']}
                onAdd={async (operationId) => {
                  await depositLinkOperation({
                    deposit_id: deposit.id,
                    worksite_operation_id: operationId,
                  });
                }}
                data={dataToLink}
                setSearch={(val: string) => {
                  setSearchToLink(val);
                  getData(undefined, val);
                }}
                refresh={() => getData(undefined, undefined, true, true, true)}
                isEdit={!deposit.is_external}
                priceLabel={`${t('contractMandant.amount_prime')}`}
                isLoading={isLoading.toLink}
                onSort={(column, direction) =>
                  sortList(column, direction, setSortAndFilterToLink)
                }
                onFilter={(filters) =>
                  filterList(filters, setSortAndFilterToLink)
                }
              />
            </div>
          )}
          <div
            className={`w-full md:w-[${
              deposit.is_external ? '100%]' : '50%]'
            } mb-[1.5rem]`}
          >
            <OperationList
              model="deposit"
              cardTitle={t('deposits.section_my_depot')}
              buttonList={['delete']}
              onDelete={async (operationId, lineId) => {
                await depositUnLinkOperation(lineId);
              }}
              data={dataLinked}
              setSearch={(val: string) => {
                setSearchLinked(val);
                getData(val, undefined);
              }}
              refresh={() => getData(undefined, undefined, true, true, true)}
              isEdit={!deposit.is_external}
              priceLabel={`${t('contractMandant.amount_prime')}`}
              placeholderEmpty={`${
                !deposit.is_external
                  ? t('deposits.to_begin_deposit')
                  : t('deposits.external_list_operations_deposit')
              }`}
              isLoading={isLoading.linked}
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterLinked)
              }
              onFilter={(filters) =>
                filterList(filters, setSortAndFilterLinked)
              }
            />
          </div>
        </div>
      ) : (
        <ConsultList
          data={deposit.deposits_operations}
          model="deposit"
          sortList={(column, direction) =>
            sortList(column, direction, setSortAndFilterLinked)
          }
          onFilter={(filters) => filterList(filters, setSortAndFilterLinked)}
          loading={isLoading.linked}
        />
      )}

      {modalDateDepot && (
        <ModalDateDeposit
          deposit={deposit}
          type="deposit_date"
          callbackConfirm={(res) => {
            if (res) {
              setDeposit(res);
              setSelectedTab(`${t('deposits.tab_detail')}`);
              setModalDateDepot(false);
            }
          }}
          callbackClose={() => {
            setModalDateDepot(false);
          }}
          setDeposit={setDeposit}
        />
      )}
    </>
  );
}

export { TabDepositFollowUp };
