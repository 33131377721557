import { red } from '@assets/color';
import { convertHexToRGBA } from '@utils/functions';

interface ITextErrorProps {
  errorMessage: string;
}
function TextError({ errorMessage }: ITextErrorProps) {
  return (
    <div>
      {errorMessage !== '' && (
        <p
          className="text-red p-[.5rem] rounded-default mt-[1rem]"
          style={{ backgroundColor: convertHexToRGBA(red, 0.1) }}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
}

export { TextError };
