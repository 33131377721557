import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { Map } from '../../Map';

function LocationWorksiteSummary() {
  const { t } = useTranslation();
  const { worksiteAddress } = useContext(WorksiteCreationContext);

  return (
    <div className="border border-borderGrey rounded-default w-full min-h-[25rem]">
      <div className="border-b border-b-borderGrey">
        <p className="p-[1.5rem] font-medium text-[1.25rem]">
          {t('worksite_creation.simulation.summary.localisation_worksite')}
        </p>
      </div>
      <div className="p-[1.5rem] pb-[2rem] pt-0 h-[72%]">
        <div className="relative h-full mt-[1.5rem]">
          <Map />
        </div>
        <div className="my-2 text-sm font-medium">
          <span className="font-normal">
            {t('worksite_creation.worksite_address')}
          </span>
          {' : '}
          {worksiteAddress.numberStreet} {worksiteAddress.streetName}
          {', '}
          {worksiteAddress.zipCode} {worksiteAddress.city}
        </div>
      </div>
    </div>
  );
}

export { LocationWorksiteSummary };
