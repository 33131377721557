import { blueOpx } from '@assets/color';
import { PersonIcon, PositionIcon } from '@assets/images/svgComponents';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function BeneficiaryInfoSummary() {
  const { t } = useTranslation();
  const { beneficiary } = useContext(WorksiteCreationContext);

  const isB2B = beneficiary?.company_name !== null;

  const dataBeneficiary = [
    {
      label: `${isB2B ? t('infos.company_name') : t('infos.name_firstname')}`,
      value:
        (isB2B
          ? beneficiary?.company_name
          : `${beneficiary?.firstname} ${beneficiary?.lastname}`) || '',
      icon: <PersonIcon />,
      color: blueOpx,
    },
    {
      label: '',
      value: '',
      icon: <div />,
      color: 'transparent',
    },
    {
      label: t('infos.email'),
      value: beneficiary?.email || '',
      icon: <div />,
      color: 'transparent',
    },
    {
      label: t('infos.phone'),
      value: beneficiary?.mobile_phone || '',
      icon: <div />,
      color: 'transparent',
    },
    {
      label: t('infos.address'),
      value: beneficiary?.address.address || '',
      icon: <PositionIcon />,
    },
    {
      label: t('infos.zipcode_city'),
      value:
        `${beneficiary?.address.postal_code} ${beneficiary?.address.city}` ||
        '',
      icon: <div />,
      color: 'transparent',
    },
  ];

  return (
    <div className="border border-borderGrey rounded-default w-full">
      <div className="border-b border-b-borderGrey">
        <p className="p-[1.5rem] text-[1.25rem] font-medium">
          {t('global.beneficiary')}
        </p>
      </div>
      <div className="p-[1.5rem] grid grid-cols-2 gap-4">
        {dataBeneficiary.map((item) => (
          <InfosWithIcon
            key={item.value}
            colorIcon={item.color}
            icon={item.icon}
            infos={[
              {
                title: item.label,
                subtitle: item.value,
              },
            ]}
          />
        ))}
      </div>
    </div>
  );
}

export { BeneficiaryInfoSummary };
