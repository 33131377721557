/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import { INavLinkSidebar } from 'types/globalTypes';
import {
  IPartnerListRequest,
  IPartnerView,
  ISubcontractorListRequest,
  // IPartnersInvitedEmail,
} from '@models/partners/utils/types/partnersType';
import {
  urlGetPartnerTabs,
  urlPartners,
  urlPostSubcontractorsList,
  urlPostValidateInvitationFile,
  urlPostValidateModelInvitationFile,
} from '@api/apiUrls';
import { toast } from 'react-toastify';
import { ENTITY_TYPES } from '@utils/roles';
import { PARTNERS_MENU_MAPPING } from '../utils/partnersConstants';

export const getPartnersMenu = async (
  updatePartnersMenu: Dispatch<SetStateAction<INavLinkSidebar[]>>,
  updateListUserView: Dispatch<SetStateAction<IPartnerView[]>>,
  updateUserView: Dispatch<SetStateAction<IPartnerView | null>>
) => {
  try {
    const response = await axiosClient.get(urlGetPartnerTabs);
    const current_view = response.data.current_view;

    let menus = response.data.menu || response.data;

    // NOTE: hide sous-traitants menu when mandataire, oblige, delegataire
    if ([2, 3, 6].includes(current_view.entity_type)) {
      menus = menus.filter(
        (menu: Record<string, string | number>) =>
          menu.entityType !== ENTITY_TYPES.SOUS_TRAITANT
      );
    }

    // NOTE: hide mandataire menu when mandataire
    if (current_view.entity_type === ENTITY_TYPES.MANDATAIRE) {
      menus = menus.filter(
        (menu: Record<string, string | number>) =>
          menu.entityType !== ENTITY_TYPES.MANDATAIRE
      );
    }

    updatePartnersMenu(
      menus.map((menu: Record<string, string | number>) => {
        return {
          ...menu,
          name: PARTNERS_MENU_MAPPING[menu.entityType]?.title || '',
          link: PARTNERS_MENU_MAPPING[menu.entityType]?.link || '',
        };
      })
    );

    // SETTING user View
    updateListUserView(response.data.vues);
    updateUserView(response.data.vues[0]);
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnersList = async ({
  page,
  perPage,
  entityType,
  sortAndFilterData,
}: IPartnerListRequest) => {
  try {
    const response = await axiosClient.post(urlPartners, {
      pagination: { page, perPage },
      entity_type: entityType,
      sort_and_filter: sortAndFilterData,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getSubcontractorsList = async ({
  page,
  perPage,
  worksiteId,
  sortAndFilterData,
}: ISubcontractorListRequest) => {
  try {
    const response = await axiosClient.post(
      urlPostSubcontractorsList(worksiteId),
      {
        pagination: { page, perPage },
        sort_and_filter: sortAndFilterData,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const sendValidatedFile = async (uploadFile: File) => {
  const formData = new FormData();
  formData.append('file', uploadFile);
  try {
    const response = await axiosClient.post(
      urlPostValidateInvitationFile,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
  return false;
};

export const setValidatedFile = async (modelId: number, uploadFile: File) => {
  const formData = new FormData();
  formData.append('file', uploadFile);
  try {
    const response = await axiosClient.post(
      urlPostValidateModelInvitationFile(modelId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
  return false;
};
