import { blueOpx, green, iconBlue, red, textGrey } from '@assets/color';
import {
  BriefcaseIcon,
  CheckIcon,
  ChevronDownIcon,
  CrossIcon,
  HouseIcon,
  PersonsIcon,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { GlobalContext } from '@context/globalContext';
import {
  IAdvantagesRows,
  IPricingRow,
  ISubscriptionType,
} from '@models/auth/utils/types';
import {
  ENTITY_TYPES,
  groupDelegataireOblige,
  groupInstallateurAMO,
  groupMandataire,
} from '@utils/roles';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@components/atomic/Tag';

interface IOneSubscriptionProps {
  profilsGroup: number[];
  allSelectedProfils?: number[];
  selectedSubscriptions?: ISubscriptionType[];
  setSelectedSubscriptions?: Dispatch<SetStateAction<ISubscriptionType[]>>;
  options?: {
    discover?: boolean;
    multipleProfilsGroup?: boolean;
    isFirstDisplay?: boolean;
    forceSelected?: boolean;
    fromModal?: boolean;
  };
}

interface ISubscriptionDatas {
  type: ISubscriptionType;
  label: string;
  amount: string;
  beforeAccess: string | JSX.Element;
}

function OneSubscription({
  allSelectedProfils,
  profilsGroup,
  selectedSubscriptions,
  setSelectedSubscriptions,
  options,
}: IOneSubscriptionProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const entityTypeLabel = globalEnum?.entity_type[profilsGroup[0]] || '';

  const isOffered =
    groupMandataire.includes(profilsGroup[0]) &&
    allSelectedProfils &&
    allSelectedProfils.filter((type) => groupDelegataireOblige.includes(type))
      .length > 0;

  const isDiscover = options?.discover;

  const isInstallerOrAMO = groupInstallateurAMO.includes(profilsGroup[0]);

  // eslint-disable-next-line no-nested-ternary
  const discoverLabel = isInstallerOrAMO
    ? profilsGroup[0] === ENTITY_TYPES.AMO
      ? 'AMO'
      : 'Pro'
    : entityTypeLabel;

  let subscriptionDatas: ISubscriptionDatas;
  const commonDescription = (
    <>
      {t('auth.registration.subscription_overview.pro_max_description_1')}{' '}
      <span className="text-blueOpx font-medium">
        <a
          href="https://www.opx.co/simulation"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          {t('auth.registration.subscription_overview.pro_max_description_2')}
        </a>
      </span>{' '}
      {t('auth.registration.subscription_overview.pro_max_description_3')}
    </>
  );
  if (isDiscover) {
    subscriptionDatas = {
      type: 'discover',
      label: discoverLabel,
      amount: '0€',
      beforeAccess:
        t('auth.registration.subscription_overview.discover_description') || '',
    };
  } else if (groupDelegataireOblige.includes(profilsGroup[0])) {
    subscriptionDatas = {
      type: 'pilot',
      label: `${entityTypeLabel} Max`,
      amount: '699,99€',
      beforeAccess: commonDescription || '',
    };
  } else if (groupMandataire.includes(profilsGroup[0])) {
    subscriptionDatas = {
      type: 'manager',
      label: `${entityTypeLabel} Max`,
      amount: '299,99€',
      beforeAccess: commonDescription || '',
    };
  } else {
    subscriptionDatas = {
      type: 'pro',
      /* label: t('auth.registration.subscription_overview.subscription', {
        entityTypeLabel,
      }), */
      label: `${profilsGroup[0] === ENTITY_TYPES.AMO ? 'AMO' : 'Pro'} Max`,
      amount: '29,99€',
      beforeAccess:
        (options?.fromModal
          ? t('upgrade_subscription_modal.full_access')
          : commonDescription) || '',
    };
  }

  const isSelected =
    selectedSubscriptions?.includes(subscriptionDatas.type) ||
    options?.forceSelected;

  const pricingRows: IPricingRow[] = [
    {
      icon: <PersonsIcon />,
      label: isInstallerOrAMO
        ? t('auth.registration.subscription_overview.worksite_single_gear')
        : t('auth.registration.subscription_overview.operation_single_gear'),
      price: '5',
    },
    {
      icon: <BriefcaseIcon />,
      label: isInstallerOrAMO
        ? t('auth.registration.subscription_overview.worksite_BtoB')
        : t('auth.registration.subscription_overview.operation_BtoB'),
      price: '15',
    },
    {
      icon: <HouseIcon />,
      label: isInstallerOrAMO
        ? t(
            'auth.registration.subscription_overview.worksite_global_renovation'
          )
        : t(
            'auth.registration.subscription_overview.operation_global_renovation'
          ),
      price: '15',
    },
  ];

  const advantagesRowsPro: IAdvantagesRows = {
    left: [
      t('auth.registration.subscription_overview.advantage_row_pro_one_user'),
      t(
        'auth.registration.subscription_overview.advantage_row_pro_standard_support'
      ),
    ],
    right: [
      t(
        'auth.registration.subscription_overview.advantage_row_pro_free_for_life'
      ),
    ],
  };

  const advantagesRowsProMax: IAdvantagesRows = {
    left: [
      t(
        'auth.registration.subscription_overview.advantage_row_pro_max_no_commitment'
      ),
      t(
        'auth.registration.subscription_overview.advantage_row_pro_max_unlimited_users'
      ),
    ],
    right: [
      t(
        'auth.registration.subscription_overview.advantage_row_pro_max_premium_support'
      ),
      t(
        'auth.registration.subscription_overview.advantage_row_pro_max_dashboard'
      ),
    ],
  };

  const advantagesRows: IAdvantagesRows = isDiscover
    ? advantagesRowsPro
    : advantagesRowsProMax;

  const modalDiscoverRows = [
    t('upgrade_subscription_modal.cross_multi_user'),
    t('upgrade_subscription_modal.cross_no_dashboard'),
    t('upgrade_subscription_modal.cross_activity_history'),
    t('upgrade_subscription_modal.cross_no_support'),
  ];

  const modalProRows = [
    t('upgrade_subscription_modal.check_multi_user'),
    t('upgrade_subscription_modal.check_dashboard'),
    t('upgrade_subscription_modal.check_activity_history'),
    t('upgrade_subscription_modal.check_suppport'),
    t('upgrade_subscription_modal.check_without_obligation'),
  ];

  const toggleAccordion = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsAccordionOpen(!isAccordionOpen);
  };

  const renderModalRows = (rows: string[]) => {
    const totalItems = rows.length;
    const itemsPerColumn = Math.ceil(totalItems / 2); // Si total est impair, cela mettra l'item supplémentaire dans la colonne de gauche

    const leftColumnItems = rows.slice(0, itemsPerColumn);
    const rightColumnItems = rows.slice(itemsPerColumn);

    const renderOneItem = (row: string) => (
      <div key={row} className="flex items-center space-x-2">
        <ColorCube
          opacity
          size="1.5rem"
          color={isDiscover ? red : green}
          numberOrIcon={isDiscover ? <CrossIcon /> : <CheckIcon />}
        />
        <div className="text-xs leading-3 whitespace-nowrap">{row}</div>
      </div>
    );

    return (
      <div className="flex w-full">
        <div className="flex flex-col items-start space-y-2 w-1/2">
          {leftColumnItems.map((row) => renderOneItem(row))}
        </div>
        <div className="flex flex-col items-start space-y-2 w-1/2">
          {rightColumnItems.map((row) => renderOneItem(row))}
        </div>
      </div>
    );
  };

  const pricingRow = (row: IPricingRow) => {
    const priceToDisplay = `+${isDiscover ? '0' : row.price}€${
      isDiscover ? '' : t('auth.registration.subscription_overview.vat')
    }/${t(
      `auth.registration.subscription_overview.${
        isInstallerOrAMO ? 'worksite' : 'operation'
      }`
    )}`;
    return (
      <div
        key={`${row.label}-${row.price}`}
        className="flex items-center justify-between"
      >
        <div className="flex space-x-4 items-center">
          <ColorCube
            opacity
            size="1.5rem"
            color={blueOpx}
            numberOrIcon={row.icon}
          />
          <div className="font-normal text-xs leading-3">{row.label}</div>
        </div>
        <div className="font-medium text-sm leading-tight">
          {priceToDisplay}
        </div>
      </div>
    );
  };

  const discoverRow = (row: string, includes: boolean) => (
    <div key={`discover${row}`} className="flex items-center space-x-2">
      <ColorCube
        opacity
        size="1.5rem"
        color={includes ? blueOpx : textGrey}
        numberOrIcon={includes ? <CheckIcon /> : <CrossIcon />}
      />
      <div className="text-xs leading-3 whitespace-nowrap">{row}</div>
    </div>
  );

  const deselectOnSelectOne = (
    toReplace: ISubscriptionType,
    toAdd: ISubscriptionType
  ) => {
    if (selectedSubscriptions && setSelectedSubscriptions) {
      setSelectedSubscriptions([
        ...selectedSubscriptions.filter(
          (subscription) => subscription !== toReplace
        ),
        toAdd,
      ]);
    }
  };

  /* const handleSubscriptionClick = (subscriptionType: ISubscriptionType) => {
    if (isSelected) {
      // Si on clique sur l'abonnement Gestionnaire alors que l'abonnement Pilote est sélectionné rien ne se passe
      // Sinon on retire l'abonnement cliqué de la liste
      if (
        !(
          subscriptionType === 'manager' &&
          selectedSubscriptions.includes('pilot')
        )
      ) {
        setSelectedSubscriptions(
          selectedSubscriptions.filter(
            (subscription) => subscription !== subscriptionType
          )
        );
      }
      // Ajoute l'abonnement Découverte et retire l'abonnement Pro de la liste
    } else if (subscriptionType === 'discover') {
      deselectOnSelectOne('pro', subscriptionType);
      // Ajoute l'abonnement Pro et retire l'abonnement Découverte de la liste
    } else if (subscriptionType === 'pro') {
      deselectOnSelectOne('discover', subscriptionType);
      // Si plusieurs types de profils sont sélectionnés
    } else if (options?.multipleProfilsGroup) {
      // Si on clique sur l'abonnement Pilote et que l'abonnement Gestionnaire n'est pas sélectionné on le sélectionne aussi
      if (
        subscriptionType === 'pilot' &&
        !selectedSubscriptions.includes('manager') &&
        allSelectedProfils.includes(ENTITY_TYPES.MANDATAIRE)
      ) {
        setSelectedSubscriptions([
          ...selectedSubscriptions,
          subscriptionType,
          'manager',
        ]);
      }
      // Sinon, on ajoute simplement l'abonnement cliqué à la liste
      else {
        setSelectedSubscriptions([...selectedSubscriptions, subscriptionType]);
      }
    } else {
      // Si un seul type de profil est sélectionné, l'abonnement cliqué devient le seul abonnement sélectionné
      setSelectedSubscriptions([subscriptionType]);
    }
  }; */

  const handleSubscriptionClick = (subscriptionType: ISubscriptionType) => {
    if (
      selectedSubscriptions &&
      setSelectedSubscriptions &&
      allSelectedProfils
    ) {
      if (!isSelected) {
        // Ajoute l'abonnement Découverte et retire l'abonnement Pro de la liste
        if (subscriptionType === 'discover') {
          setSelectedSubscriptions(['discover']);
          // Ajoute l'abonnement Pro et retire l'abonnement Découverte de la liste
        } else {
          deselectOnSelectOne('discover', subscriptionType);
        }
      }
    }
  };

  return (
    <div className="w-[30rem]">
      {options?.multipleProfilsGroup && (
        <div
          className={`text-neutral-900 text-sm font-light leading-tight mb-4 ${
            options.isFirstDisplay ? '' : 'mt-4'
          }`}
        >
          {t(
            `auth.registration.subscription_overview.before_subscription${
              subscriptionDatas.type === 'pro' ? 's' : ''
            }_statut${profilsGroup.length > 1 ? 's' : ''}`
          )}{' '}
          {profilsGroup
            .map((profilKey) => globalEnum.entity_type[profilKey])
            .join(` ${t('global.and')} `)}
          .
          {isOffered && (
            <div className="text-xs mt-2">
              {t(
                'auth.registration.subscription_overview.offered_if_subscription_pilot'
              )}
            </div>
          )}
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={`p-6 rounded-default border cursor-pointer ${
          isSelected ? 'border-blueOpx' : 'border-borderGrey'
        }`}
        onClick={() => {
          handleSubscriptionClick(subscriptionDatas.type);
        }}
      >
        <div className="flex justify-between">
          <div className="flex flex-col space-y-1">
            <div className="flex space-x-2">
              <p className="text-xl text-lg font-normal leading-7 text-black">
                {subscriptionDatas.label}
              </p>
              {subscriptionDatas.type === 'discover' && !options?.fromModal && (
                <Tag
                  addClass="mb-1"
                  color={iconBlue}
                  label={t(
                    'auth.registration.subscription_overview.recommended'
                  )}
                />
              )}
            </div>
            <div className="h-[1.5rem] text-textGrey text-xs leading-3 font-light">
              {subscriptionDatas.beforeAccess}{' '}
            </div>
          </div>
          <div
            className={`flex ${
              isOffered ? 'flex-col items-end' : 'space-x-1 items-center'
            }`}
          >
            <div className="text-xl leading-loose">
              {isOffered
                ? t('auth.registration.subscription_overview.price_offered')
                : subscriptionDatas.amount}
            </div>

            <div
              className={`text-xs leading-3 ${
                isOffered ? 'text-neutral-400 line-through' : ''
              }`}
            >
              {isOffered && `${subscriptionDatas.amount} `}
              {isDiscover
                ? t('auth.registration.subscription_overview./month')
                : t('auth.registration.subscription_overview.vat/month')}
            </div>
          </div>
        </div>

        {!isOffered && (
          <>
            <div className="h-[.0625rem] bg-borderGrey opacity-40 my-4" />

            {!options?.fromModal && (
              <>
                <div className="flex w-full">
                  <div className="flex flex-col items-start space-y-2 w-1/2">
                    {advantagesRows.left.map((row) => discoverRow(row, true))}
                  </div>
                  <div className="flex flex-col items-start space-y-2 w-1/2">
                    {advantagesRows.right.map((row) => discoverRow(row, true))}
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className={`flex items-center justify-between cursor-pointer mt-6 pb-2 ${
                    isAccordionOpen ? 'border-b' : ''
                  }`}
                  onClick={toggleAccordion}
                >
                  <span className="text-sm font-medium">
                    {t('auth.registration.subscription_overview.usage_fees')}
                  </span>
                  <ChevronDownIcon
                    width="1.25rem"
                    height="1.25rem"
                    className={`transform transition-transform ${
                      isAccordionOpen ? 'rotate-180' : ''
                    }`}
                  />
                </div>

                {isAccordionOpen && (
                  <div className="flex flex-col space-y-4 p-4">
                    {pricingRows.map((row) => pricingRow(row))}
                  </div>
                )}
              </>
            )}
            {options?.fromModal &&
              renderModalRows(isDiscover ? modalDiscoverRows : modalProRows)}
          </>
        )}
      </div>
    </div>
  );
}

export default OneSubscription;

OneSubscription.defaultProps = {
  allSelectedProfils: undefined,
  selectedSubscriptions: undefined,
  setSelectedSubscriptions: undefined,
  options: {
    discover: false,
    multipleProfilsGroup: false,
    isFirstDisplay: false,
    forceSelected: false,
    fromModal: false,
  },
};
