/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from '@components/Modal';

import {
  renderSelectField,
  renderTextField,
} from '@models/contractCreation/utils/newContractHelper';
import {
  createDeposit,
  getDepositRaiList,
} from '../apiRequests/depositRequests';

interface IModalNewDeposiProps {
  callbackConfirm: (res: any) => void;
  callbackClose: () => void;
}

function ModalNewDeposit(props: IModalNewDeposiProps) {
  const { callbackConfirm, callbackClose } = props;
  const { t } = useTranslation();

  const methods = useForm();
  const { watch, setValue } = methods;
  const valuesForm = watch();

  const [listRai, setListRai] = useState([]);
  const [hasChange, setHasChange] = useState<boolean>(false);

  const getData = async () => {
    const res = await getDepositRaiList();

    setListRai(res || []);
  };

  const handleConfirm = async () => {
    if (
      !valuesForm.reference ||
      valuesForm.reference === '' ||
      !valuesForm.entity_to_id ||
      !valuesForm.entity_to_type
    ) {
      return false;
    }
    const res: any = await createDeposit({
      reference: valuesForm.reference,
      entity_to_id: valuesForm.entity_to_id,
      entity_to_type: valuesForm.entity_to_type,
    });

    if (res && res.id) callbackConfirm(res);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      title={t('deposits.btn_new')}
      textBtnConfirm={`${t('deposits.begin')}`}
      onConfirmClick={handleConfirm}
      btnCancel
      setIsModal={callbackClose}
      backgroundTransparent
      buttonsPosition="bottom"
    >
      <FormProvider {...methods}>
        <form>
          <div className="mt-4">
            <div className="flex flex-wrap">
              {renderTextField({
                valuesForm,
                label: '',
                placeholder:
                  t('forms.internal_reference_deposit.placeholder') || '',
                name: 'reference',
                className: 'w-full mb-3',
                required: true,
                isValid: hasChange
                  ? valuesForm.reference && valuesForm.reference !== ''
                  : true,
                onChange: () => setHasChange(true),
              })}

              <p className="font-medium w-full my-3">
                {t('deposits.choose_deposit_rai')}
              </p>

              {renderSelectField({
                data:
                  listRai && listRai.length > 0
                    ? listRai.map((rai) => (rai as any).company_name)
                    : [],
                onSelect: (value) => {
                  setHasChange(true);

                  setValue('rai', value);
                  const filteredList: any = listRai.filter(
                    (rai) => (rai as any).company_name === value
                  );

                  if (filteredList.length > 0) {
                    const selectedRai = filteredList[0];
                    setValue('entity_to_id', selectedRai.entity_to_id);
                    setValue('entity_to_type', selectedRai.entity_to_type);
                  } else {
                    setValue('entity_to_id', null);
                    setValue('entity_to_type', null);
                  }
                },
                label: '',
                placeholder: t('global.choose') || '',
                valueInput: valuesForm.rai,
                outerClassName: 'w-full mb-3',
                className: 'mb-2',
                defaultSelected: '',
                error: hasChange
                  ? !valuesForm.rai || valuesForm.rai === ''
                  : false,
                textError: t('forms.rai.error') || '',
                showClearButton: true,
              })}
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export { ModalNewDeposit };
