import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { transformDate } from '@utils/functions';
import {
  IRevisionStatus,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import { v4 } from 'uuid';
import { textGrey } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { formatWord } from '@utils/format';

type IWorksiteDetailsOperationCardStepsProps = {
  detailsIsActive: boolean;
  stepsList: string[];
  stepActive: number;
  setStepHeight: Dispatch<SetStateAction<number>>;
  revisionStatus: IRevisionStatus[];
  lastUpdateDate: string;
  operation: IWorksiteOperation;
};

function WorksiteDetailsOperationCardSteps({
  detailsIsActive,
  stepsList,
  stepActive,
  setStepHeight,
  revisionStatus,
  lastUpdateDate,
  operation,
}: IWorksiteDetailsOperationCardStepsProps): JSX.Element {
  const { t } = useTranslation();
  const cardStep = useRef<HTMLDivElement>(null);

  const [stepName, setStepName] = useState<string>('');

  const revisionStatusToDisplay = (index: number) => {
    let result;
    const element = revisionStatus.find((el) => el.status === index.toString());
    if (element) {
      result = {
        date: transformDate(element.created_at),
        days: element.days,
      };
    } else {
      const value = revisionStatus[revisionStatus.length - 1];
      result = { date: transformDate(value.created_at), days: value.days };
    }
    return result;
  };

  const resize = () => {
    if (cardStep && cardStep.current) {
      setStepHeight(cardStep.current.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
  }, []);

  useEffect(() => {
    resize();
  }, [detailsIsActive]);

  useEffect(() => {
    const name = stepsList[stepActive - 1]
      ? stepsList[stepActive - 1].split(':')
      : ['', ''];
    setStepName(name[1]);
  }, [stepsList, stepActive]);

  return (
    <div>
      {!detailsIsActive ? (
        <div className="flex items-center justify-between py-[.875rem] text-[.875rem] border-t-[1px] border-grey">
          <div>{stepName}</div>
          <div
            className="text-[.875rem]"
            style={{ color: textGrey }}
            data-test-id="op_last_update_step"
          >
            {lastUpdateDate}
          </div>
        </div>
      ) : (
        <div ref={cardStep} className="cursor-default">
          {stepsList.map((step: string, index: number) => {
            const name = step.split(':');
            const revision = revisionStatusToDisplay(index + 1);

            const hideCofrac =
              formatWord(name[1]).includes('cofrac') &&
              !operation.operation.have_cofrac;

            let textColor = '';

            if (index + 1 < stepActive) textColor = 'text-textGrey';

            if (hideCofrac) textColor = 'text-gray-200';

            return (
              <div
                className={`flex items-center justify-between py-[.875rem] text-[.875rem] border-t-[1px] ${
                  index + 1 > stepActive ? 'border-transparent' : 'border-grey'
                } ${textColor}`}
                key={v4()}
                data-test-id="worksite_op_step"
              >
                {name[1]}

                {!hideCofrac && index + 1 <= stepActive && (
                  <div data-test-id="worksite_op_last_update">
                    {`${revision.date} (${revision.days} ${t(
                      'requests_and_changes.day'
                    )}${revision.days > 1 ? 's' : ''})`}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export { WorksiteDetailsOperationCardSteps };
