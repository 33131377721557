/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-spread */
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Pagination } from '@components/atomic/pagination/Pagination';
import { blueOpx } from '@assets/color';
import { LoaderCarouselDocument } from '@components/loaders/document/LoaderCarouselDocument';
import { LoaderDocument } from '@components/loaders/document/LoaderDocument';
import { LoaderPagination } from '@components/loaders/LoaderPagination';
import { LoaderHeaderDocument } from '@components/loaders/document/LoaderHeaderDocument';
import { GlobalContext } from '@context/globalContext';
import { v4 } from 'uuid';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { dateToDDMMYYY, formatWord } from '@utils/format';
import {
  getLinkedFileArchives,
  updateContractLinkedFile,
} from '@apiRequests/globalRequests';
import { AuthContext } from '@context/authContext';
import { ILinkedFile } from '../../types/globalTypes';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IDocumentViewerProps {
  isLoading: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  addClassHeight?: string;
  isSizingNote?: string;
  reference?: string;
  canEdit?: boolean;
  withoutHeader?: boolean;
  showArchives?: boolean;
}

function DocumentViewer({
  isLoading,
  setIsLoading,
  addClassHeight,
  isSizingNote,
  reference,
  canEdit,
  withoutHeader,
  showArchives,
}: IDocumentViewerProps) {
  const { globalEnum, documentActive, updateDocumentActive } =
    useContext(GlobalContext);
  const { sizingNote } = useContext(WorksiteCreationContext);
  const { user } = useContext(AuthContext);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isRotate, setIsRotate] = useState<boolean>(false);

  const [pdfWidth, setPdfWidth] = useState<number>(0);

  const [widthToDisplay, setWidthToDisplay] = useState<number>(0);

  const [containerWidth, setContainerWidth] = useState<number>(0);

  const [selectedStatus, setSelectedStatus] = useState<string>(
    documentActive
      ? globalEnum.linked_file_status[documentActive.document.status]
      : ''
  );

  const container = useRef<HTMLDivElement>(null);

  const [urlDocumentActive, setUrlDocumentActive] = useState<string | null>(
    null
  );

  const [dateDocumentActive, setDateDocumentActive] = useState<string | null>(
    null
  );

  const [archiveData, setArchiveData] = useState<ILinkedFile[]>([]);

  const typeIsImage = useMemo(() => {
    const isBlob = urlDocumentActive?.includes('blob:');
    const parts = urlDocumentActive?.split('?')[0].split('.');
    if (!parts) return false;
    const extension = parts[parts.length - 1];

    return (
      !isBlob &&
      ((extension && extension.toLowerCase() !== 'pdf') ||
        [6, 12].includes(documentActive.document.file_type))
    );
  }, [documentActive]);

  const resizePdf = () => {
    if (container && container.current) {
      const divWidth = container.current.clientWidth;
      setContainerWidth(divWidth);
    }
  };

  const onDocumentLoadSuccess = (pageNum: number) => {
    setNumPages(pageNum);
    setPageNumber(1);
    if (setIsLoading) {
      setIsLoading(false);
    }
    if (isRotate) {
      setIsRotate(false);
    }
  };

  const handleLoadSuccess = (page: PDFPageProxy) => {
    if (page.width > page.height || page.width >= 842) {
      setIsRotate(true);
      setPdfWidth(page.width);
    }
  };

  const getSize = () => {
    if (urlDocumentActive) {
      const img = new Image();
      img.src = urlDocumentActive;
      img.onload = () => {
        if (img.width > img.height) {
          setIsRotate(true);
        } else {
          setIsRotate(false);
        }
      };
    }
  };

  const getWidth = () => {
    const width = containerWidth;
    setWidthToDisplay(width);
  };

  const getArchives = async (id: number) => {
    const response = await getLinkedFileArchives(id);
    if (response.data) setArchiveData(response.data);
  };

  const docStatus = useMemo(() => {
    const label = globalEnum.linked_file_status[documentActive.document.status];
    setSelectedStatus(label);
    return label;
  }, [documentActive]);

  const changeStatus = (label: string) => {
    setSelectedStatus(label);
    const element = Object.values(globalEnum.linked_file_status).indexOf(label);
    const actualStatus =
      globalEnum.linked_file_status[documentActive.document.status];
    const status = Object.entries(globalEnum.linked_file_status)[element][0];
    if (status && documentActive.document.id && actualStatus !== label) {
      updateContractLinkedFile(documentActive.document.id, Number(status)).then(
        (res) => {
          if (res.data) updateDocumentActive({ list: '', document: res.data });
        }
      );
    }
  };

  const inputColor = useMemo(() => {
    if (formatWord(selectedStatus).includes('signe')) return 'success';
    return undefined;
  }, [selectedStatus]);

  const archives = useMemo(() => {
    if (!archiveData) return [];

    const dataArchive = archiveData
      .filter((archive) => archive.file_url !== null)
      .map((archive) => ({
        value: String(archive.file_url || ''),
        label: dateToDDMMYYY(archive.uploaded_at || '', true),
      }));

    return [
      {
        value: String(documentActive.document.file_url || ''),
        label:
          documentActive.document.uploaded_at &&
          !documentActive.document.uploaded_at.includes('/')
            ? dateToDDMMYYY(documentActive.document.uploaded_at || '', true)
            : documentActive.document.uploaded_at || '',
      },
      ...dataArchive,
    ];
  }, [documentActive, archiveData]);

  useEffect(() => {
    window.addEventListener('resize', resizePdf);

    resizePdf();
  }, []);

  useEffect(() => {
    resizePdf();
  }, [urlDocumentActive]);

  useEffect(() => {
    setTimeout(() => {
      resizePdf();
    }, 1000);
  }, [sizingNote]);

  useEffect(() => {
    setTimeout(() => {
      resizePdf();
    }, 1000);
  }, [sizingNote]);

  useEffect(() => {
    getWidth();
  }, [pdfWidth, containerWidth, sizingNote]);

  useEffect(() => {
    setUrlDocumentActive(documentActive.document.file_url);

    let dateDoc = documentActive.document.uploaded_at || '';

    if (dateDoc !== '' && !dateDoc.includes('/')) {
      dateDoc = dateToDDMMYYY(dateDoc, true);
    }
    setDateDocumentActive(dateDoc);

    if (documentActive.document.id && showArchives && user) {
      getArchives(documentActive.document.id);
    }
  }, [documentActive]);

  return (
    <div
      className={`w-full bg-white border-[1px] border-borderGrey rounded-default overflow-hidden ${
        !isSizingNote ? 'py-[1.5rem] px-[2.75rem]' : ''
      }  flex flex-col space-y-[1.5rem]`}
      data-test-id="document_viewer"
    >
      {!urlDocumentActive && !isLoading && !isSizingNote ? (
        <>
          <div className="flex flex-col space-y-[.25rem]">
            <p className="text-[1rem] leading-[1.25rem]">
              {documentActive.list}
            </p>
          </div>
          <div
            className={`w-full ${
              addClassHeight ? 'h-[60vh]' : 'h-[80vh]'
            } rounded-default bg-backgroundBody`}
          />
        </>
      ) : (
        <>
          {isLoading && !isSizingNote ? (
            <LoaderHeaderDocument />
          ) : !isSizingNote && !isLoading && !withoutHeader ? (
            <div className="flex justify-between relative">
              <div className="flex flex-col space-y-[.25rem] w-full">
                <div className="flex w-full justify-between">
                  <div>
                    <p
                      className="text-[1rem] leading-[1.25rem]"
                      data-test-id="document_title"
                    >
                      {documentActive.document.custom_title
                        ? documentActive.document.custom_title
                        : reference || documentActive.listAsTitle
                        ? documentActive.list
                        : globalEnum.linked_file_type[
                            documentActive.document.file_type
                          ]}
                    </p>
                    <p
                      className="text-[.75rem] leading-[.75rem] text-textGrey"
                      data-test-id="document_date"
                    >
                      {urlDocumentActive !== documentActive.document.file_url
                        ? `archive (${dateDocumentActive})`
                        : dateDocumentActive}
                    </p>
                  </div>
                  <div className="w-[15rem]">
                    {archiveData && archiveData.length > 0 && (
                      <InputSelect
                        placeholder=""
                        dataLabelValue={archives}
                        valueInput="Versions"
                        onSelectLabelValue={(item) => {
                          setUrlDocumentActive(item.value);
                          setDateDocumentActive(item.label);
                        }}
                        hideScrollBar
                      />
                    )}
                  </div>
                </div>
              </div>
              {canEdit && (
                <InputSelect
                  placeholder=""
                  defaultSelected={docStatus}
                  dataArrayString={Object.values(
                    globalEnum.linked_file_status
                  ).filter(
                    (elt) =>
                      elt.includes('Signé') ||
                      elt.includes('En attente de signature')
                  )}
                  addClass="w-[13.5rem] absolute right-0 top-[-1rem] z-20"
                  onSelect={(value) => changeStatus(String(value))}
                  color={inputColor}
                  borderRadius={6}
                />
              )}
            </div>
          ) : null}
          {typeIsImage ? (
            <div className="overflow-auto" style={{ width: '100%' }}>
              <img
                src={urlDocumentActive || ''}
                alt="document active"
                className="rounded-default max-w-none"
                onLoad={getSize}
              />
            </div>
          ) : (
            <>
              <div className="w-full" ref={container} data-test-id="document">
                {isLoading ? (
                  <LoaderDocument addClassHeight={addClassHeight} />
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href={
                      isSizingNote !== undefined
                        ? ''
                        : String(urlDocumentActive)
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Document
                      file={
                        isSizingNote !== undefined
                          ? isSizingNote
                          : urlDocumentActive
                      }
                      options={{
                        disableFontFace: true,
                        disableRange: true,
                        disableStream: true,
                        disableAutoFetch: true,
                        disableCreateObjectURL: true,
                        cMapUrl: 'cmaps/',
                        cMapPacked: true,
                      }}
                      onLoadSuccess={(pdf) => {
                        onDocumentLoadSuccess(pdf.numPages);
                      }}
                      onLoadError={() => {
                        setNumPages(0);
                        setPageNumber(1);
                      }}
                      error={
                        <div className="w-full h-full justify-center flex items-center">
                          <div className="text-[.75rem] font-light">
                            Impossible d&apos;afficher le fichier.
                          </div>
                        </div>
                      }
                      loading={
                        <LoaderDocument addClassHeight={addClassHeight} />
                      }
                      className="w-full"
                    >
                      {isRotate ? (
                        <Page
                          pageNumber={pageNumber}
                          onRenderSuccess={(page) => handleLoadSuccess(page)}
                          rotate={pdfWidth < 842 ? 90 : 180}
                          className={`overflow-auto scroll-invisible h-[65vh] ${
                            addClassHeight || 'max-h-[65vh]'
                          } ${
                            !isSizingNote
                              ? 'rounded-default border-borderGrey border-[1px]'
                              : ''
                          } `}
                          width={widthToDisplay}
                          renderAnnotationLayer={false}
                        />
                      ) : (
                        <Page
                          pageNumber={pageNumber}
                          onRenderSuccess={(page) => handleLoadSuccess(page)}
                          width={widthToDisplay}
                          className={`overflow-auto scroll-invisible h-[65vh] ${
                            addClassHeight || 'max-h-[65vh]'
                          } ${
                            !isSizingNote
                              ? 'rounded-default border-borderGrey border-[1px]'
                              : ''
                          }`}
                          renderAnnotationLayer={false}
                        />
                      )}
                    </Document>
                  </a>
                )}
              </div>
              <div className="w-full flex items-center max-w-full ">
                {isLoading ? (
                  <LoaderCarouselDocument />
                ) : (
                  <Document
                    file={
                      isSizingNote !== undefined
                        ? isSizingNote
                        : urlDocumentActive
                    }
                    options={{
                      disableFontFace: true,
                      disableRange: true,
                      disableStream: true,
                      disableAutoFetch: true,
                      disableCreateObjectURL: true,
                      cMapUrl: 'cmaps/',
                      cMapPacked: true,
                    }}
                    onLoadSuccess={(pdf) => {
                      onDocumentLoadSuccess(pdf.numPages);
                    }}
                    className="mx-auto flex items-center space-x-[1rem]"
                  >
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page) => (
                        <button
                          key={v4()}
                          type="button"
                          onClick={() => setPageNumber(page)}
                          className={`rounded-default border-[1px] h-[3.5rem] max-h-[3.5rem] w-[3.5rem] max-w-[3.5rem] overflow-hidden ${
                            page === pageNumber
                              ? 'border-blueOpx'
                              : 'border-borderGrey'
                          }`}
                        >
                          <Page
                            pageNumber={page}
                            width={100}
                            height={100}
                            renderAnnotationLayer={false}
                          />
                        </button>
                      ))}
                  </Document>
                )}
              </div>
              {isLoading ? (
                <LoaderPagination />
              ) : (
                <div>
                  {numPages > 1 && (
                    <Pagination
                      colorPagination={blueOpx}
                      numberPage={pageNumber}
                      totalNumberPage={0}
                      onPrevious={() => {
                        if (pageNumber > 1) {
                          setPageNumber((prevPageNumber) => prevPageNumber - 1);
                        }
                      }}
                      onNext={() => {
                        if (pageNumber < numPages) {
                          setPageNumber((prevPageNumber) => prevPageNumber + 1);
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export { DocumentViewer };

DocumentViewer.defaultProps = {
  addClassHeight: undefined,
  isSizingNote: undefined,
  reference: '',
  canEdit: false,
  withoutHeader: false,
  showArchives: false,
  setIsLoading: undefined,
};
