import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputText } from '@components/atomic/inputs/InputText';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { postNewMessageChange } from '@apiRequests/changesRequest';
import { AuthContext } from '@context/authContext';
import ImageContainer from '@components/atomic/ImageContainer';
import { IAccountUser } from '@models/users/utils/userTypes';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { RequestAndActivityIntoModalOrCardContext } from '../../../../context/requestAndChangeContext';

function NewMessageChanges() {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      new_message: '',
    },
  });
  const { handleSubmit, watch } = methods;
  const { worksiteDetails } = useContext(WorksitesContext);
  const { user } = useContext(AuthContext);
  const {
    updateListChanges,
    updateTotalChangesList,
    listChanges,
    totalChangesList,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const [isLoading, setIsLoading] = useState(false);
  const [usersMentioned, setUsersMentioned] = useState<IAccountUser[]>([]);
  const [resetSignal, setResetSignal] = useState(0);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await postNewMessageChange({
      setIsLoading,
      relation_type: 'worksite',
      relation_id: worksiteDetails.id.toString(),
      message: data.new_message,
      setListChanges: updateListChanges,
      listChanges,
      updateTotalChangesList,
      usersMentionedIds: usersMentioned.map(
        (userMentioned) => userMentioned.id
      ),
      totalChangesList,
    });
    setUsersMentioned([]);
    setResetSignal((prev) => prev + 1);
  };

  return (
    <div className="px-[1.5rem] w-full">
      <div className="flex items-end space-x-[.5rem] w-full">
        <ImageContainer
          imageUrl={user?.photo_url}
          width="2.875rem"
          height="2.875rem"
          addClass="min-w-[2.875rem] min-h-[2.875rem]"
        />
        <FormProvider {...methods}>
          <form
            id="send_message"
            onSubmit={handleSubmit(onSubmit)}
            className="w-full"
          >
            <InputText
              id="new_message"
              name="new_message"
              label={t('requests_and_changes.new_message_change') || ''}
              placeholder={t('requests_and_changes.@_to_mention') || ''}
              dataTestId="input_text"
              userMention
              setUsersMentioned={setUsersMentioned}
              valueInitialInput=""
              resetSignal={resetSignal}
            />
          </form>
        </FormProvider>
      </div>
      <div className="flex space-x-[.5rem] items-center float-right pb-[1.5rem] pt-[.5rem]">
        <ButtonOpx
          small
          type="primary"
          label={t('buttons.send')}
          isSubmitBtn
          formId="send_message"
          isLoading={isLoading}
          disabled={isLoading || watch().new_message === ''}
        />
      </div>
    </div>
  );
}

export { NewMessageChanges };
