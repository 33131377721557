/* eslint-disable no-nested-ternary */
import { Modal } from '@components/Modal';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ISimulatorData } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { OperationTypeEnum } from '@utils/enums';
import { WORKSITES_ROUTES } from '@utils/routesUrls';
import { saveSimulation } from '../apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '../utils/worksiteCreationContext';

function SaveAndQuit() {
  const { t } = useTranslation();
  const router = useNavigate();
  const {
    beneficiary,
    updateIsModals,
    isModals,
    simulatorData,
    updateSimulatorData,
    conventionActive,
    worksiteDatas,
    listOperationSelected,
    worksiteOperationType,
    simulatorDataOperations,
    informationLogementDataPost,
  } = useContext(WorksiteCreationContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(true);

  const onQuitSimulation = () => {
    updateIsModals({ ...isModals, saveAndQuit: false, deleteWorksite: false });
    router(WORKSITES_ROUTES.WORKSITES);
  };

  const onSaveSimulation = () => {
    Object.keys(simulatorData).forEach((key) => {
      if (simulatorData[key] === null) {
        delete simulatorData[key];
      }
    });

    let newSimulationData: ISimulatorData = { ...simulatorData };

    const { cadastral_parcel } = informationLogementDataPost;

    if (cadastral_parcel && cadastral_parcel !== '') {
      newSimulationData = { ...newSimulationData, cadastral_parcel };
    }

    if (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION) {
      const operations = [
        {
          ...simulatorDataOperations[0],
          'operation.id': 'bar-th-164',
          'operation.suboperations': listOperationSelected,
        },
      ];
      //

      newSimulationData = { ...newSimulationData, operations };
    } else if (simulatorDataOperations.length > 0) {
      newSimulationData = {
        ...newSimulationData,
        operations: simulatorDataOperations,
      };
    } else if (listOperationSelected) {
      newSimulationData = listOperationSelected.map((item) => {
        return {
          'operation.id': item.code.toLowerCase(),
        };
      });
    }
    saveSimulation(
      router,
      newSimulationData,
      setIsLoading,
      updateIsModals,
      worksiteOperationType,
      worksiteDatas.incentive_type,
      worksiteDatas.id !== 0 ? worksiteDatas.id : undefined
    );
  };

  useEffect(() => {
    updateSimulatorData({
      ...simulatorData,
      'convention.id': conventionActive.id,
      'worksite.beneficiary_id': beneficiary?.id,
      'worksite.incentive_type': worksiteDatas.incentive_type,
    });
  }, []);

  useEffect(() => {
    if (!isModal) {
      updateIsModals({ ...isModals, saveAndQuit: false });
    }
  }, [isModal]);

  return (
    <Modal
      backgroundTransparent
      title={t(
        beneficiary !== null && worksiteDatas.id === 0
          ? 'worksite_creation.titles.save_and_quit'
          : beneficiary && worksiteDatas.id !== 0
          ? 'buttons.quit'
          : 'worksite_creation.titles.quit_and_delete'
      )}
      btnCancel
      setIsModal={setIsModal}
      textBtnCancel={`${t('buttons.back_to_simulation')}`}
      textBtnConfirm={`${t(
        beneficiary !== null && worksiteDatas.id === 0
          ? 'buttons.quit_and_save_simulation'
          : (beneficiary && worksiteDatas.id !== 0) ||
            (beneficiary &&
              worksiteDatas.status !== WORKSITE_STATUS.WORKSITE_CREATION)
          ? 'buttons.quit'
          : 'buttons.quit_simulation'
      )}`}
      onConfirmClick={
        (beneficiary !== null && worksiteDatas.id === 0) ||
        (worksiteDatas.status === WORKSITE_STATUS.WORKSITE_CREATION &&
          beneficiary !== null)
          ? onSaveSimulation
          : onQuitSimulation
      }
      isLoading={isLoading}
    >
      <p className="mb-[1.5rem] mt-[1rem]">
        {t(
          beneficiary !== null && worksiteDatas.id === 0
            ? 'worksite_creation.subtitles.save_and_quit'
            : beneficiary !== null && worksiteDatas.id !== 0
            ? 'worksite_creation.subtitles.save_and_quit_create_worksite'
            : 'worksite_creation.subtitles.quit_and_delete'
        )}
      </p>
    </Modal>
  );
}

export { SaveAndQuit };
