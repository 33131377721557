/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState, useContext, useEffect } from 'react';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { TabInformations } from '@models/partners/components/informations/TabInformations';
import { TabDeposits } from '@models/partners/components/deposits/TabDeposits';
import { SubcontractorList } from '@models/partners/components/subcontractors/SubcontractorList';
import { TabConventions } from '@models/partners/components/conventions/TabConventions';
import { TabContracts } from '@models/partners/components/contracts/TabContracts';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

import { IPartnerSubmenu } from '@models/partners/utils/types/partnersType';
import { ROLES } from '@utils/roles';
import { TabCertifications } from '@models/certifications/components/TabCertifications';
import { useParams } from 'react-router-dom';

interface ISheetPartnerOrInstallerProps {
  isInstaller?: boolean;
  isSheetPartner?: boolean;
  loadingTabs: boolean;
  partnersSubMenu: IPartnerSubmenu[];
  entityType: number | null;
}

// NOTE: Fiche partenaire
function SheetPartnerOrInstaller({
  isInstaller,
  isSheetPartner,
  loadingTabs,
  partnersSubMenu,
  entityType,
}: ISheetPartnerOrInstallerProps): JSX.Element {
  const { id } = useParams();
  const { roleUser } = useContext(GlobalContext);

  const [sheetContent, setSheetContent] = useState<string>('informations');
  const [viewTabs, setViewTabs] = useState<IPartnerSubmenu[]>([]);

  const renderTab = useMemo(() => {
    // if (isInstaller) return <TabInformations />;

    switch (sheetContent) {
      case 'informations':
        return <TabInformations entityType={entityType} />;
      case 'contracts':
        return <TabContracts />;
      case 'deposits':
        return <TabDeposits />;
      case 'conventions':
        return <TabConventions />;
      case 'subcontractors':
        return <SubcontractorList />;
      case 'certifications':
        return <TabCertifications entityIdForRequests={Number(id)} />;
      case 'business':
      default:
        return <p>{sheetContent}</p>;
    }
  }, [sheetContent, isInstaller]);

  // NOTE: filtering tabs according to selected view
  useEffect(() => {
    // default and Pilot tabs show only Infos tab
    let viewTabsToSet = partnersSubMenu.filter((tab: IPartnerSubmenu) =>
      ['informations', 'conventions'].includes(tab.content || '')
    );

    if (!isSheetPartner) {
      switch (roleUser) {
        case ROLES.PRODUCTION:
          viewTabsToSet = partnersSubMenu.filter((tab: IPartnerSubmenu) =>
            ['informations', 'conventions'].includes(tab.content || '')
          );
          break;
        case ROLES.GESTION:
          viewTabsToSet = [...partnersSubMenu];
          break;
        default:
          break;
      }
    } else if (!isInstaller) {
      viewTabsToSet = [...partnersSubMenu];
    }
    console.log(viewTabsToSet);
    setViewTabs(viewTabsToSet);
  }, [roleUser, partnersSubMenu]);

  return (
    <>
      <div className="tabs_wrapper mt-6 flex" data-test-id="sub_header">
        {!loadingTabs
          ? viewTabs
              .filter(
                (tabItem: IPartnerSubmenu) =>
                  !['deposits', 'worksites'].includes(tabItem?.content || '')
              )
              .map((tab: IPartnerSubmenu) => (
                <ButtonOpx
                  key={v4()}
                  onClick={() => setSheetContent(tab.content || '')}
                  label={tab.submenuLabel}
                  type="tab"
                  addClass="mr-3"
                  active={sheetContent === tab.content}
                  dataTestId="button_sub_header"
                />
              ))
          : [...Array(3)].map(() => (
              <LoaderSkeleton
                key={v4()}
                height="2.75rem"
                addClass="mr-3 w-[7.5rem]"
              />
            ))}
      </div>
      <div className="tabs_content">{renderTab}</div>
    </>
  );
}

SheetPartnerOrInstaller.defaultProps = {
  isInstaller: false,
  isSheetPartner: true,
};

export { SheetPartnerOrInstaller };
