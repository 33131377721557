function LoaderPagination() {
  return (
    <div className="w-full flex items-center">
      <div className="flex space-x-[1.5rem] items-center mx-auto">
        <div className="skeleton-loader-anim h-[1.5rem] w-[1.5rem]" />
        <div className="skeleton-loader-anim h-[2rem] w-[5rem]" />
        <div className="skeleton-loader-anim h-[1.5rem] w-[1.5rem]" />
      </div>
    </div>
  );
}
export { LoaderPagination };
