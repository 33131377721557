import { green, grey, red, textGrey } from '@assets/color';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { GlobalContext } from '@context/globalContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ROLES } from '@utils/roles';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_STATE, BUTTONS_DOCUMENT_STATE_TABS } from '@utils/utils';
import { ColorCube } from '@components/atomic/ColorCube';
import { CheckIcon, CrossIcon } from '@assets/images/svgComponents';
import { ILinkedFile } from '../../types/globalTypes';
import { UploadButton } from './UploadButton';

interface IDocumentCardActionsProps {
  document: ILinkedFile;
  isActive: boolean;
  setDocumentActive: (document: ILinkedFile) => void;
  status: string;
  onValidate?: (() => void) | undefined;
  onReject?: ((rejectionComment: string) => void) | undefined;
  onUpload?: (file: File, originalLinkedFile: ILinkedFile) => void;
}

function DocumentCardActions({
  document,
  status,
  isActive,
  onUpload,
  setDocumentActive,
  onValidate,
  onReject,
}: IDocumentCardActionsProps) {
  const { t } = useTranslation();
  const { roleUser } = useContext(GlobalContext);
  const BUTTONS_STATE = BUTTONS_DOCUMENT_STATE_TABS(t);

  const showButtons =
    (roleUser === ROLES.GESTION && status === DOCUMENT_STATE.PENDING) ||
    roleUser === ROLES.PRODUCTION;
  const [isRefused, setIsRefused] = useState<boolean>(false);
  const [buttonsNames, setButtonsNames] = useState<string[]>([]);

  const [changedFile, setChangedFile] = useState<File>();
  const [changedDocument, setChangedDocument] = useState<ILinkedFile>();
  const [rejectionComment, setRejectionComment] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<
    'validate' | 'refuse' | null
  >(null);
  const onClickConfirmDocument = () => {
    if (changedFile && onUpload) {
      onUpload(changedFile, document);
    }
  };
  const onClick = (name: string) => {
    switch (name) {
      case BUTTONS_STATE.ACCEPTED:
        if (onValidate) {
          onValidate();
        }
        break;
      case BUTTONS_STATE.REFUSED:
        setIsRefused(true);
        setRejectionComment('');
        setButtonsNames([
          BUTTONS_STATE.CANCELED,
          BUTTONS_STATE.VALIDATE_REJECTION,
        ]);
        break;
      case BUTTONS_STATE.CANCELED:
        setIsRefused(false);
        setRejectionComment('');
        setButtonsNames([BUTTONS_STATE.REFUSED, BUTTONS_STATE.ACCEPTED]);
        break;
      case BUTTONS_STATE.VALIDATE_REJECTION:
        if (onReject) {
          onReject(rejectionComment);
        }
        break;
      case BUTTONS_STATE.REQUEST:
        console.log('ouvrir DA');
        break;
      case BUTTONS_STATE.CONFIRM_DOCUMENT:
        onClickConfirmDocument();
        break;
      default:
        break;
    }
  };

  const uploadBeforeConfirm = async (fileUploaded: File) => {
    const newDocument = {
      ...document,
      file: fileUploaded.name,
      file_url: URL.createObjectURL(fileUploaded),
      status: 2,
    };
    setDocumentActive(newDocument);
    setChangedDocument(newDocument);
    setChangedFile(fileUploaded);
    setButtonsNames([BUTTONS_STATE.CHANGE, BUTTONS_STATE.CONFIRM_DOCUMENT]);
  };

  const renderButton = (name: string, index: number) => {
    switch (name) {
      case BUTTONS_STATE.CHANGE:
        if (!onUpload) {
          break;
        }
        return (
          <UploadButton
            name={name}
            onUpload={uploadBeforeConfirm}
            document={document}
            color="grey"
            fileType={document.file_type}
          />
        );
      case BUTTONS_STATE.REFUSED:
        return (
          <ColorCube
            size="1.5rem"
            numberOrIcon={<CrossIcon />}
            color={selectedAction === 'validate' ? textGrey : red}
            opacity
            onClick={() => {
              if (selectedAction === 'refuse') {
                setSelectedAction(null);
              } else {
                setSelectedAction('refuse');
              }
            }}
          />
        );
      case BUTTONS_STATE.ACCEPTED:
        return (
          <ColorCube
            size="1.5rem"
            numberOrIcon={<CheckIcon />}
            color={selectedAction === 'refuse' ? textGrey : green}
            opacity
            onClick={() => {
              if (selectedAction === 'validate') {
                setSelectedAction(null);
              } else {
                setSelectedAction('validate');
              }
            }}
          />
        );
      default:
        return (
          <ButtonOpx
            label={name}
            disabled={
              name === BUTTONS_STATE.VALIDATE_REJECTION &&
              rejectionComment === ''
            }
            onClick={(e) => {
              e.stopPropagation();
              onClick(name);
            }}
            type={index === 0 ? 'secondary' : 'primary'}
            small
          />
        );
    }
    return null;
  };

  const buttons = useMemo(() => {
    return (
      <div className="flex items-center space-x-[.5rem] justify-end">
        {changedDocument &&
          buttonsNames.includes(BUTTONS_STATE.CONFIRM_DOCUMENT) && (
            <ButtonOpx
              label={t('global.see')}
              onClick={(e) => {
                e.stopPropagation();
                setDocumentActive(changedDocument);
              }}
              small
            />
          )}
        {buttonsNames.map((name: string, index: number) => (
          <div key={`${name}_${index + 1}`}>{renderButton(name, index)}</div>
        ))}
      </div>
    );
  }, [buttonsNames, rejectionComment, onUpload, selectedAction]);

  useEffect(() => {
    let buttonsNamesToSet: string[] = [];
    switch (roleUser) {
      case ROLES.GESTION:
        if (status === DOCUMENT_STATE.PENDING) {
          buttonsNamesToSet = [BUTTONS_STATE.REFUSED, BUTTONS_STATE.ACCEPTED];
        }
        break;
      case ROLES.PRODUCTION:
        if (status === DOCUMENT_STATE.REFUSE) {
          buttonsNamesToSet = [BUTTONS_STATE.REQUEST, BUTTONS_STATE.CHANGE];
        }
        break;
      default:
        break;
    }
    setButtonsNames(buttonsNamesToSet);
  }, [roleUser, status]);

  return (
    <div className="w-full border rounded-default p-2">
      {roleUser === ROLES.GESTION && (
        <>
          {isRefused && (
            <div className="pt-[1rem]">
              <input
                style={{ borderColor: grey, borderWidth: '1px' }}
                className="rounded-default w-full p-[.75rem]"
                placeholder={t('global.document_input_rejection') || ''}
                onChange={(e) => {
                  setRejectionComment(e.target.value);
                }}
                readOnly={status === DOCUMENT_STATE.REFUSE}
                onClick={(e) => e.stopPropagation()}
                value={rejectionComment}
                onBlur={(e) => e.stopPropagation()}
              />
            </div>
          )}
          {showButtons && buttons}
        </>
      )}
      {status === DOCUMENT_STATE.REFUSE && (
        <div
          className={`w-full p-[1rem] flex flex-col space-y-[1rem] rounded-default mb-[1rem] ${
            isActive ? 'bg-white' : 'bg-backgroundBody'
          }`}
        >
          <p className="text-left">{document.commentary}</p>
        </div>
      )}
      {roleUser === ROLES.PRODUCTION && showButtons && buttons}
    </div>
  );
}

export { DocumentCardActions };

DocumentCardActions.defaultProps = {
  onUpload: undefined,
  onReject: undefined,
  onValidate: undefined,
};
