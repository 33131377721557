import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { ChevronDownIcon } from '@assets/images/svgComponents';
import { convertHexToRGBA } from '@utils/functions';
import { blueOpx } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { Loader } from '@components/atomic/Loader';
import { postAnswerMessageChange } from '@apiRequests/changesRequest';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ImageContainer from '@components/atomic/ImageContainer';
import { AuthContext } from '@context/authContext';
import { IAccountUser } from '@models/users/utils/userTypes';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';

interface IAnswerMessageFormProps {
  idChange: number;
}

function AnswerMessageForm({ idChange }: IAnswerMessageFormProps) {
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        answer_message: yup
          .string()
          .required(t('forms.required_error_message') || ''),
      })
    ),
  });
  const { handleSubmit } = methods;
  const { worksiteDetails } = useContext(WorksitesContext);

  const { updateListChanges } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );
  const { user } = useContext(AuthContext);

  const [isLoadingAnswerMessage, setIsLoadingAnswerMessage] = useState(false);
  const [usersMentioned, setUsersMentioned] = useState<IAccountUser[]>([]);
  const [resetSignal, setResetSignal] = useState(0);

  const onSubmit = async (data: any) => {
    setIsLoadingAnswerMessage(true);
    await postAnswerMessageChange({
      idChange,
      setIsLoading: setIsLoadingAnswerMessage,
      relation_type: 'worksite',
      relation_id: worksiteDetails.id.toString(),
      message: data.answer_message,
      usersMentionedIds: usersMentioned.map(
        (userMentioned) => userMentioned.id
      ),
      updateListChanges,
    });
    setUsersMentioned([]);
    setResetSignal((prev) => prev + 1);
  };

  return (
    <div className="flex space-x-[.5rem] items-end w-full pt-[1rem]">
      <ImageContainer
        imageUrl={user?.photo_url}
        height="1.5rem"
        width="1.5rem"
        addClass="min-w-[1.5rem] min-h-[1.5rem] mb-[.375rem]"
      />
      <FormProvider {...methods}>
        <form
          id="send_answer_message"
          className="w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputText
            addClassToInput="!py-[.3rem]"
            id="answer_message"
            name="answer_message"
            label={t('requests_and_changes.answer') || ''}
            placeholder={t('requests_and_changes.write_new_message') || ''}
            dataTestId="input_text"
            userMention
            setUsersMentioned={setUsersMentioned}
            resetSignal={resetSignal}
            icon={
              isLoadingAnswerMessage ? (
                <Loader />
              ) : (
                <button type="submit">
                  <ColorCube
                    size="1rem"
                    color={blueOpx}
                    opacity
                    backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
                    numberOrIcon={
                      <ChevronDownIcon className="rotate-[-90deg]" />
                    }
                  />
                </button>
              )
            }
          />
        </form>
      </FormProvider>
    </div>
  );
}

export { AnswerMessageForm };
