import { OneOperationCard } from '@models/worksiteCreation/components/simulation/stepChoiceOperation/OneOperationCard';
import { useState } from 'react';

function OneOperationCardsDesign() {
  // POUR TESTER LE CHECK SUR LA CARD OPERATION
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const handleCheck = (isChecked: boolean, label: string) => {
    if (isChecked) {
      setCheckedItems([...checkedItems, label]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== label));
    }
  };

  return (
    <div className="w-1/3 flex-col items-center mb-6">
      <p className="mb-4">ONE OPERATION CARDS - CREATION DE CHANTIER</p>
      <div className="flex flex-col space-y-[1rem] bg-white p-2">
        <OneOperationCard
          nameOperation="Pompe à chaleur"
          code="BAR-TH"
          pictureOperationUrl="http://pro.front.staging.drapo.com/static/media/barth106.aa0ebcb2d47b38e584f4.png"
          isChecked={checkedItems.includes('Pompe à chaleur')}
          handleCheck={(isChecked) => handleCheck(isChecked, 'Pompe à chaleur')}
        />
        <OneOperationCard
          nameOperation="Chaudière biomasse"
          code="BAR-TH"
          pictureOperationUrl="http://pro.front.staging.drapo.com/static/media/barth106.aa0ebcb2d47b38e584f4.png"
          isChecked={checkedItems.includes('Chaudière biomasse')}
          handleCheck={(isChecked) =>
            handleCheck(isChecked, 'Chaudière biomasse')
          }
        />
        <OneOperationCard
          nameOperation="Chaudière biomasse"
          code="BAR-TH"
          incompatible
          pictureOperationUrl="http://pro.front.staging.drapo.com/static/media/barth106.aa0ebcb2d47b38e584f4.png"
          isChecked={checkedItems.includes('Chaudière biomasse')}
          handleCheck={(isChecked) =>
            handleCheck(isChecked, 'Chaudière biomasse')
          }
        />
      </div>
    </div>
  );
}

export { OneOperationCardsDesign };
