/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import {
  InfoCircleIcon,
  CalendarIcon,
  VolumeGraphIcon,
  ContactPageIcon,
  SignataryIcon,
} from '@assets/images/svgComponents';
import { TFunction } from 'i18next';
import { format, parse } from 'date-fns';
import { convertKiloToGiga } from '@utils/functions';
import { TabIntermediaryBusiness } from '@models/contracts/components/TabIntermediaryBusiness';
import { numberWithSeparator } from '@utils/format';

import { TabInfosConventions } from '@models/conventions/components/TabInfosConventions';
import { ColorCube } from '@components/atomic/ColorCube';
import { sendLinkedFile } from '@apiRequests/globalRequests';
import { ILinkedFile } from 'types/globalTypes';
import { TabPaymentModality } from '../components/TabPaymentModality';
import { TabPricePerOperation } from '../components/TabPricePerOperation';
import { TabWorksites } from '../components/TabWorksites';
import {
  IConventionType,
  IContactType,
  IConventionModelType,
} from './conventionTypes';
import {
  getBuyingPrices,
  getConventionModelBuyingPrice,
} from '../apiRequests/conventionRequests';
import { TabInfosConventionModel } from '../components/TabInfosConventionModel';

export const getTabContent = (
  tabId: number,
  convention: IConventionType,
  refresh: () => void
) => {
  switch (tabId) {
    case 1:
      return <TabInfosConventions convention={convention} refresh={refresh} />;
    case 2:
      return <TabPaymentModality conventionId={convention.id} />;
    case 3:
      return (
        <TabPricePerOperation
          id={convention.id}
          fetchData={getBuyingPrices}
          isConvention
          incentiveType={convention.incentive_type}
        />
      );
    case 4:
      return (
        <TabIntermediaryBusiness
          id={convention.id}
          fetchData={getBuyingPrices}
          isConvention
          commission={convention.minimum_bonus_allocation}
          intermediary={convention.intermediary_business}
          contact={
            convention.contactIntermediaryBusiness
              ? convention.contactIntermediaryBusiness[0]
              : undefined
          }
        />
      );
    case 5:
      return <TabWorksites conventionId={convention.id} />;
    default:
      return <TabInfosConventions convention={convention} refresh={refresh} />;
  }
};

export const getCardInfosGeneralConvention = (
  t: TFunction,
  convention: IConventionType,
  globalEnum: any
) => {
  const startDate = parse(convention.start_date, 'yyyy-MM-dd', new Date());
  const endDate = parse(convention.end_date, 'yyyy-MM-dd', new Date());

  const infosWithoutBonusAllocation = [
    {
      name: 'reference',
      title: t('contract.reference_opx'),
      value: convention.reference,
      icon: <InfoCircleIcon />,
      colorIcon: '#1730BF',
    },
    {
      name: 'internal_reference',
      title: t('contract.internal_reference'),
      value: convention.internal_reference,
    },
    {
      name: 'rai',
      title: t('partners.obliged.name'),
      value: convention.rai.company_name,
    },
    {
      name: 'contract_associated',
      title: t('worksites.contract'),
      value: convention.contract ? convention.contract.reference : '',
    },
    {
      name: 'beneficiaries_type',
      title: t('convention.tab_info_general.beneficiaries_type'),
      value:
        globalEnum.beneficiaries_type[Number(convention.beneficiaries_type)],
    },
    {
      name: 'incentive_type',
      title: t(
        'worksite_creation.form_modal_before_simulation.title_incentive_type'
      ),
      value: globalEnum.incentive_type[Number(convention.incentive_type)],
    },
  ];

  const basicInfos = [...infosWithoutBonusAllocation];

  const getPercent = (price: number | null, type: 'max' | 'min') => {
    if (!price) return '-';

    const volP =
      type === 'max'
        ? convention.volume_precaire_kwhc
        : convention.minimum_volume_precaire_kwhc;
    const volC =
      type === 'max'
        ? convention.volume_classique_kwhc
        : convention.minimum_volume_classique_kwhc;

    const total = (volP || 0) + (volC || 0);

    return `${numberWithSeparator(String((price * 100) / total))}%`;
  };

  const worksiteAddresses = convention.beneficiary?.addresses?.data.filter(
    (a) => a.address_type === 2
  );

  return [
    [...basicInfos],
    [
      {
        name: 'start_date',
        title: t('convention.tab_info_general.start_date'),
        value: format(startDate, 'dd/MM/yyyy'),
        icon: <CalendarIcon />,
        colorIcon: '#1730BF',
      },
      {
        name: 'end_date',
        title: t('convention.tab_info_general.end_date'),
        value: format(endDate, 'dd/MM/yyyy'),
      },
      !convention.beneficiary
        ? {
            name: 'offer_validity_delay',
            title: t('convention.tab_info_general.offer_validity_delay'),
            value: convention.offer_validity_delay ?? '-',
          }
        : { empty: true },
    ],
    worksiteAddresses
      ? worksiteAddresses.map((address, i) => ({
          name: `address_${i}`,
          title: `${t('contract.worksite_address')}`,
          value: `${address.address}, ${address.postal_code} ${address.city}`,
          icon: (
            <ColorCube
              size="2rem"
              numberOrIcon={i + 1}
              color="#1730BF"
              opacity
            />
          ),
          colorIcon: '#1730BF',
        }))
      : [
          {
            name: 'volume_classique_kwhc_max',
            title: `${t(
              'convention.tab_info_general.volume_classique_max'
            )} (GWhc)`,
            value: `${convertKiloToGiga(
              convention.volume_classique_kwhc
            )} GWhc`,
            icon: <VolumeGraphIcon />,
            colorIcon: '#1730BF',
          },
          {
            name: 'percent_classique_max',
            title: t('convention.tab_info_general.percent_gisement'),
            value: getPercent(convention.volume_classique_kwhc, 'max'),
            colorIcon: '#1730BF',
          },
          {
            name: 'volume_precaire_kwhc_max',
            title: `${t(
              'convention.tab_info_general.volume_precaire_max'
            )} (GWhc)`,
            value: `${convertKiloToGiga(convention.volume_precaire_kwhc)} GWhc`,
          },
          {
            name: 'percent_precaire_max',
            title: t('convention.tab_info_general.percent_gisement'),
            value: getPercent(convention.volume_precaire_kwhc, 'max'),
            colorIcon: '#1730BF',
          },
          {
            name: 'volume_classique_kwhc_min',
            title: `${t(
              'convention.tab_info_general.volume_classique_min'
            )} (GWhc)`,
            value: `${convertKiloToGiga(
              convention.minimum_volume_classique_kwhc
            )} GWhc`,
            icon: <VolumeGraphIcon />,
            colorIcon: '#1730BF',
          },
          {
            name: 'percent_classique_min',
            title: t('convention.tab_info_general.percent_gisement'),
            value: getPercent(convention.minimum_volume_classique_kwhc, 'min'),
            colorIcon: '#1730BF',
          },
          {
            name: 'volume_precaire_kwhc_min',
            title: `${t(
              'convention.tab_info_general.volume_precaire_min'
            )} (GWhc)`,
            value: `${convertKiloToGiga(
              convention.minimum_volume_precaire_kwhc
            )} GWhc`,
          },
          {
            name: 'percent_precaire_max',
            title: t('convention.tab_info_general.percent_gisement'),
            value: getPercent(convention.minimum_volume_precaire_kwhc, 'min'),
            colorIcon: '#1730BF',
          },
        ],
  ];
};

const getContactElements = (
  contact: IContactType | null,
  idx: number,
  t: TFunction,
  listContacts?: string[],
  showFields?: boolean
) => {
  let firstname = contact && contact.firstname ? contact.firstname : '';
  let lastname = contact && contact.lastname ? contact.lastname : '';
  let func = contact && contact.function ? contact.function : '';
  let phone = contact && contact.phone_number ? contact.phone_number : '';
  let email = contact && contact.email ? contact.email : '';

  const c: any = contact?.contactUser;

  firstname =
    firstname === '' ? (c && c.firstname ? c.firstname : '') : firstname;
  lastname = lastname === '' ? (c && c.lastname ? c.lastname : '') : lastname;
  func = func === '' ? (c && c.function ? c.function : '') : func;
  phone = phone === '' ? (c && c.phone_number ? c.phone_number : '') : phone;
  email = email === '' ? (c && c.email ? c.email : '') : email;

  const contactFullName = {
    name: `contact-fullname-${idx + 1}`,
    title: t('convention.tab_info_general.referent_name'),
    placeholder: t('convention.tab_info_general.referent_name'),
    value: `${firstname} ${lastname}`,
    icon: <ContactPageIcon />,
    type: 'text',
    fullItem: contact,
  };

  const contactName = {
    name: `contact-${idx + 1}`,
    title: `Contact ${idx + 1}`,
    placeholder: `${firstname} ${lastname}`.trim(),
    icon: <ContactPageIcon />,
    colorIcon: '#1730BF',
    type: 'select',
    fullItem: contact,
    list: listContacts,
  };

  const contactFunction = {
    name: `contact-function-${idx + 1}`,
    title: t('convention.tab_info_general.referent_function'),
    value: func,
    type: showFields ? 'text' : 'contact',
    fullItem: showFields ? contact : undefined,
    hideOnEdit: !showFields,
  };

  const contactPhone = {
    name: `contact-phone-${idx + 1}`,
    title: t('convention.tab_info_general.referent_phone'),
    value: phone,
    type: showFields ? 'text' : 'contact',
    fullItem: showFields ? contact : undefined,
    hideOnEdit: !showFields,
  };

  const contactEmail = {
    name: `contact-email-${idx + 1}`,
    title: t('convention.tab_info_general.referent_email'),
    value: email,
    type: showFields ? 'email' : 'contact',
    fullItem: showFields ? contact : undefined,
    hideOnEdit: !showFields,
  };

  const contactNameInfo = showFields ? contactFullName : contactName;

  return func && func !== ''
    ? [contactNameInfo, contactEmail, contactPhone, contactFunction]
    : [contactNameInfo, contactEmail, contactPhone];
};

export const getInfos = (
  t: TFunction,
  data: any,
  contactArray: IContactType[] | undefined,
  contactTypes: any,
  listContacts?: string[],
  showFields?: boolean,
  isTripartite?: boolean,
  isPerson?: boolean
) => {
  const tripartiteAddress = data?.address?.address
    ? `${data?.address.address}, ${data?.address.postal_code} ${data?.address.city}`
    : data?.address;

  const address = !isTripartite
    ? `${data?.address}, ${data?.zipcode} ${data?.city}`
    : tripartiteAddress;

  const name = isPerson
    ? `${data?.firstname} ${data?.lastname}`
    : data?.company_name;

  const basicInfos = [
    {
      name: 'name',
      title: isPerson
        ? t('beneficiaries.fullname')
        : t('partners.company_name'),
      value: name,
      icon: <InfoCircleIcon />,
      colorIcon: '#1730BF',
    },

    {
      name: 'address',
      title: t('partners.head_office_address'),
      value: address,
    },
  ];

  if (!isPerson)
    basicInfos.push({
      name: 'siret_siren',
      title: isTripartite ? t('partners.siren') : t('partners.siret'),
      value: isTripartite ? data?.siren : data?.siret,
    });

  const contact1 =
    (contactArray &&
      contactArray.length > 0 &&
      contactArray.find(
        (element) => element.contact_type === contactTypes.typeContact1
      )) ||
    null;

  const signataire =
    (contactArray &&
      contactArray.length > 0 &&
      contactArray.find(
        (element) => element.contact_type === contactTypes.typeContactSign
      )) ||
    null;

  const contactInfos = getContactElements(
    contact1,
    0,
    t,
    listContacts,
    showFields
  );

  const signataireInfos = [
    {
      name: 'signataire_name',
      title: t('convention.tab_info_general.signataire_name'),
      value: `${
        signataire && signataire.firstname ? signataire.firstname : ''
      }${signataire && signataire.lastname ? ` ${signataire.lastname}` : ''}`,
      icon: <SignataryIcon />,
      colorIcon: '#1730BF',
    },
    {
      name: 'signataire_email',
      title: t('convention.tab_info_general.signataire_email'),
      value: signataire && signataire.email ? signataire.email : '',
    },
    {
      name: 'signataire_phone',
      title: t('convention.tab_info_general.signataire_phone'),
      value:
        signataire && signataire.phone_number ? signataire.phone_number : '',
    },
  ];

  if (signataire && signataire.function)
    signataireInfos.push({
      name: 'signataire_function',
      title: t('convention.tab_info_general.signataire_function'),
      value: signataire && signataire.function ? signataire.function : '',
    });

  return [[...basicInfos], [...signataireInfos], [...contactInfos]];
};

export const getModelTabContent = (
  tabId: number,
  model: IConventionModelType
) => {
  switch (tabId) {
    case 1:
      return <TabInfosConventionModel model={model} />;
    case 2:
      return (
        <TabPricePerOperation
          id={model.id}
          fetchData={getConventionModelBuyingPrice}
          isConvention
        />
      );
    default:
      return <TabInfosConventionModel model={model} />;
  }
};

export const getConventionModelGeneralInfoCard = (
  t: TFunction,
  model: IConventionModelType,
  globalEnum: any
) => {
  const startDate = parse(model.start_date, 'yyyy-MM-dd', new Date());
  const endDate = parse(model.end_date, 'yyyy-MM-dd', new Date());

  const infosWithoutBonusAllocation = [
    {
      name: 'reference',
      title: t('contract.reference_opx'),
      value: model.reference,
      icon: <InfoCircleIcon />,
      colorIcon: '#1730BF',
    },
    {
      name: 'internal_reference',
      title: t('contract.internal_reference'),
      value: model.internal_reference,
    },
    {
      name: 'rai',
      title: t('partners.obliged.name'),
      value: model.rai,
    },
    {
      name: 'contract_associated',
      title: t('worksites.contract'),
      value: model.contract ? model.contract : '',
    },
    {
      name: 'beneficiaries_type',
      title: t('convention.tab_info_general.beneficiaries_type'),
      value: globalEnum.beneficiaries_type[Number(model.beneficiaries_type)],
    },
  ];

  const basicInfos = [...infosWithoutBonusAllocation];

  const getPercent = (price: number | null, type: 'max' | 'min') => {
    if (!price) return '-';

    const volP =
      type === 'max'
        ? model.volume_precaire_kwhc
        : model.minimum_volume_precaire_kwhc;
    const volC =
      type === 'max'
        ? model.volume_classique_kwhc
        : model.minimum_volume_classique_kwhc;

    const total = (volP || 0) + (volC || 0);

    return `${numberWithSeparator(String((price * 100) / total))}%`;
  };

  return [
    [...basicInfos],
    [
      {
        name: 'start_date',
        title: t('convention.tab_info_general.start_date'),
        value: format(startDate, 'dd/MM/yyyy'),
        icon: <CalendarIcon />,
        colorIcon: '#1730BF',
      },
      {
        name: 'end_date',
        title: t('convention.tab_info_general.end_date'),
        value: format(endDate, 'dd/MM/yyyy'),
      },
      {
        name: 'offer_validity_delay',
        title: t('convention.tab_info_general.offer_validity_delay'),
        value: model.offer_validity_delay ?? '-',
      },
    ],
    [
      {
        name: 'volume_classique_kwhc_max',
        title: `${t(
          'convention.tab_info_general.volume_classique_max'
        )} (GWhc)`,
        value: `${convertKiloToGiga(model.volume_classique_kwhc)} GWhc`,
        icon: <VolumeGraphIcon />,
        colorIcon: '#1730BF',
      },
      {
        name: 'percent_classique_max',
        title: t('convention.tab_info_general.percent_gisement'),
        value: getPercent(model.volume_classique_kwhc, 'max'),
        colorIcon: '#1730BF',
      },
      {
        name: 'volume_precaire_kwhc_max',
        title: `${t('convention.tab_info_general.volume_precaire_max')} (GWhc)`,
        value: `${convertKiloToGiga(model.volume_precaire_kwhc)} GWhc`,
      },
      {
        name: 'percent_precaire_max',
        title: t('convention.tab_info_general.percent_gisement'),
        value: getPercent(model.volume_precaire_kwhc, 'max'),
        colorIcon: '#1730BF',
      },
      {
        name: 'volume_classique_kwhc_min',
        title: `${t(
          'convention.tab_info_general.volume_classique_min'
        )} (GWhc)`,
        value: `${convertKiloToGiga(model.minimum_volume_classique_kwhc)} GWhc`,
        icon: <VolumeGraphIcon />,
        colorIcon: '#1730BF',
      },
      {
        name: 'percent_classique_min',
        title: t('convention.tab_info_general.percent_gisement'),
        value: getPercent(model.minimum_volume_classique_kwhc, 'min'),
        colorIcon: '#1730BF',
      },
      {
        name: 'volume_precaire_kwhc_min',
        title: `${t('convention.tab_info_general.volume_precaire_min')} (GWhc)`,
        value: `${convertKiloToGiga(model.minimum_volume_precaire_kwhc)} GWhc`,
      },
      {
        name: 'percent_precaire_max',
        title: t('convention.tab_info_general.percent_gisement'),
        value: getPercent(model.minimum_volume_precaire_kwhc, 'min'),
        colorIcon: '#1730BF',
      },
    ],
  ];
};

export const handleUpload = async (
  file: File,
  document: ILinkedFile,
  refresh: any
) => {
  const relation_ids = document.relation_ids || [0];
  const relation_type = document.relation_type || '';
  const file_type = document.file_type;
  const status = document.status;
  const commentary = document.commentary || '';
  const linked_entity_id = document.linked_entity_id;
  const mandatory = document.mandatory || false;
  const documentId = document.id || undefined;

  await sendLinkedFile(
    file,
    relation_ids,
    relation_type,
    file_type,
    status,
    commentary,
    linked_entity_id,
    mandatory,
    documentId
  );

  refresh();
};
