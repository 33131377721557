import { red, blueOpx, purple, blueSecondary } from '@assets/color';
import {
  // NotificationsIcon,
  // RobotIcon,
  // SupportContactIcon,
  ChevronLeftIcon,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { useContext } from 'react';
import { Tag } from '@components/atomic/Tag';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';
// import { CardSubHeaderRequestAndChanges } from './CardSubHeaderRequestAndChanges';
import { convertHexToRGBA } from '../../../utils/functions';

function SubHeaderCardRequest() {
  const { t } = useTranslation();
  const { detailRequest, updateDetailRequest } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );
  const { globalEnum } = useContext(GlobalContext);

  const tagEnum =
    globalEnum.demand_tag !== undefined && detailRequest !== null
      ? globalEnum.demand_tag[
          `${detailRequest.tags !== null ? detailRequest.tags : 0}`
        ]
      : null;

  // const requestPersonalize = listRequests.filter(
  //   (request) => request.type === 2
  // );
  // const requestAutomatic = listRequests.filter((request) => request.type === 1);

  // const datasCards = [
  //   {
  //     id: 1,
  //     color: red,
  //     icon: <NotificationsIcon />,
  //     numberRequests: listRequests.length,
  //     nameRequest: 'A réaliser',
  //   },
  //   {
  //     id: 2,
  //     color: blueOpx,
  //     icon: <RobotIcon />,
  //     numberRequests: requestAutomatic.length,
  //     nameRequest: 'Automatiques',
  //   },
  //   {
  //     id: 3,
  //     color: purple,
  //     icon: <SupportContactIcon />,
  //     numberRequests: requestPersonalize.length,
  //     nameRequest: 'Personnalisées',
  //   },
  // ];
  const date =
    detailRequest !== null ? new Date(detailRequest?.created_at) : '';

  return (
    <div
      className={`w-full border-b border-b-borderGrey ${
        detailRequest !== null ? 'pb-[1rem]' : ''
      }`}
    >
      {detailRequest === null ? (
        <div />
      ) : (
        // <div className="flex items-center w-full">
        //   {datasCards.map((data) => (
        //     <CardSubHeaderRequestAndChanges
        //       key={data.id}
        //       icon={data.icon}
        //       colorIcon={data.color}
        //       nameRequest={data.nameRequest}
        //       numberRequests={data.numberRequests.toString()}
        //     />
        //   ))}
        // </div>
        <>
          <div className="w-full p-[1.5rem]">
            <div className="flex items-center gap-3">
              <ColorCube
                color={blueOpx}
                opacity
                size="1.5rem"
                backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
                onClick={() => updateDetailRequest(null)}
                numberOrIcon={<ChevronLeftIcon />}
              />
              <p className="font-medium">{detailRequest.subject}</p>
            </div>

            {detailRequest.type !== 1 && (
              <p className="text-textGrey text-[.875rem] mt-[.5rem] text-left">
                {detailRequest.message}
              </p>
            )}
          </div>
          <div className="flex items-center w-full px-[1.5rem] justify-between">
            <div className="flex items-center  space-x-[1rem]">
              <Tag
                color={detailRequest.type === 1 ? blueSecondary : purple}
                label={
                  detailRequest.type === 1
                    ? t('requests_and_changes.automatic')
                    : t('requests_and_changes.personalize')
                }
              />
              {tagEnum !== null && detailRequest.tags !== null && (
                <Tag color={red} label={tagEnum} />
              )}
            </div>
            <p className="text-[.75rem]">
              {date.toLocaleString().substring(0, 10)}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export { SubHeaderCardRequest };
