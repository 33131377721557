import { Modal } from '@components/Modal';
import { useState } from 'react';

function ModalDesign() {
  const [isModal, setIsModal] = useState(false);
  const [isModalWithBack, setIsModalWithBack] = useState(false);

  return (
    <div className="w-2/3 flex-col items-center mb-6">
      <div className="mt-5">MODAL</div>
      {isModal && (
        <Modal
          title="Je suis la modal"
          btnCancel
          textBtnConfirm="Confirmer"
          setIsModal={setIsModal}
          onConfirmClick={() => setIsModal(false)}
        >
          <div className="mt-5">
            {' '}
            Modal content à ajouter au composant lors de l&apos;import
          </div>
        </Modal>
      )}
      {isModalWithBack && (
        <Modal
          title="Je suis la modal"
          withArrowBack
          textBtnConfirm="Confirmer"
          setIsModal={setIsModalWithBack}
          onBackClick={() => setIsModalWithBack(false)}
          onConfirmClick={() => setIsModalWithBack(false)}
        >
          <div className="mt-5">
            Modal content à ajouter au composant lors de l&apos;import
          </div>
        </Modal>
      )}
      <div className="flex space-x-[2rem]">
        <button
          onClick={() => setIsModal(true)}
          className="bg-blueOpx text-white rounded-default p-2 mt-2"
          type="button"
        >
          Cliquer pour activer la modal sans retour en arrière et avec bouton
        </button>
        <button
          onClick={() => setIsModalWithBack(true)}
          className="bg-blueOpx text-white rounded-default p-2 mt-2"
          type="button"
        >
          Cliquer pour activer la modal avec retour en arrière et sans bouton
          annuler
        </button>
      </div>
    </div>
  );
}

export { ModalDesign };
