import { Modal } from '@components/Modal';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { OperationTypeEnum } from '@utils/enums';

interface INoAidProps {
  description: string;
}

function NoAid({ description }: INoAidProps) {
  const { t } = useTranslation();
  const {
    updateStepActiveWorksiteCreation,
    updateSimulatorData,
    updateListOperationSelected,
    simulatorData,
    updateLastStep,
    worksiteOperationType,
  } = useContext(WorksiteCreationContext);

  // Supprimer les clés qui commencent par "general." sauf "general.address" et "general.zipcode"
  const updatedState = Object.entries(simulatorData).reduce(
    (acc, [key, value]) => {
      if (
        (key.startsWith('general.') &&
          key !== 'general.address' &&
          key !== 'general.zipcode') ||
        key === 'operations'
      ) {
        return acc;
      }
      return { ...acc, [key]: value };
    },
    {}
  );
  const onNewSimulation = () => {
    const initialStep =
      worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
        ? StepsWorksiteCreationEnum.GLOBAL_CHOICEOPERATIONS
        : StepsWorksiteCreationEnum.SIMULATION_CHOICEOPERATIONS;

    updateStepActiveWorksiteCreation(initialStep);
    updateLastStep(initialStep);
    updateSimulatorData(updatedState);
    updateListOperationSelected([]);
  };

  return (
    <Modal
      title={t('worksite_creation.simulation.noaid')}
      textBtnConfirm={`${t('buttons.new_simulation')}`}
      onConfirmClick={onNewSimulation}
    >
      <div className="mt-[1rem]">{description}</div>
    </Modal>
  );
}

export default NoAid;
