import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import React, { useContext, useMemo } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { numberRegex } from '@utils/regex';
import { GlobalContext } from '@context/globalContext';
import { IContractPaymentCondition } from '@models/contractCreation/utils/contractCreationTypes';
import {
  storeContract,
  upsertConventionModel,
} from '@models/contractCreation/apiRequests/contractCreationRequests';
import {
  getModelPayload,
  getPayload,
} from '@models/contractCreation/utils/payloads';
import { useParams } from 'react-router-dom';
import { ConventionModelStatus } from '@models/contractCreation/utils/enums';

function StepPaymentConditions() {
  const { t } = useTranslation();
  const { id } = useParams();
  const contractContext = useContext(ContractCreationContext);
  const { userView, globalEnum } = useContext(GlobalContext);

  const {
    paymentConditions,
    updatePaymentConditions,
    updateRelatedContract,
    changeStep,
    conventionModel,
  } = contractContext;

  const methods = useForm();

  const triggers = useMemo(() => {
    const triggerList = Object.values(globalEnum.trigger_type).map(
      (trigger, idx) => ({
        id: Number(Object.keys(globalEnum.trigger_type)[idx]),
        value: String(trigger),
      })
    );

    return [{ id: null, value: t('global.choose') }, ...triggerList];
  }, [globalEnum]);

  const setConditions = (
    index: number,
    key: 'trigger_percent' | 'delay_days' | 'trigger_type',
    value: number | null
  ) => {
    if (!Number.isNaN(value) || value === null) {
      updatePaymentConditions((prevState) =>
        prevState.map((p, i) => (i === index ? { ...p, [key]: value } : p))
      );
    }
  };

  const onSubmit = async () => {
    if (userView) {
      const copyId = id ? Number(id) : null;

      const payload = conventionModel
        ? getModelPayload(contractContext, userView, copyId)
        : getPayload(contractContext, userView, copyId);

      const res = conventionModel
        ? await upsertConventionModel(ConventionModelStatus.CREATED, payload)
        : await storeContract(payload);

      if (res) {
        updateRelatedContract({
          id: Number(res.id),
          label: String(res.reference),
          contract_type: Number(res.contract_type),
          incentive_type: Number(res.incentive_type),
        });
        changeStep('end');
      }
    }
  };

  const validCondition = (condition: IContractPaymentCondition) => {
    return (
      (condition.delay_days &&
        condition.trigger_type &&
        condition.trigger_type) ||
      (!condition.delay_days &&
        !condition.trigger_type &&
        !condition.trigger_percent)
    );
  };

  const renderFormFields = (index: number) => {
    const condition = paymentConditions[index];

    return (
      <div className="flex gap-3 items-end w-full">
        <ColorCube
          size="2.5rem"
          numberOrIcon={index + 1}
          color={blueOpx}
          opacity
          addClass="mb-[3px]"
        />
        <InputText
          id={`percent${index}`}
          name={`percent${index}`}
          placeholder={`${t('contract.payment_percent')}`}
          label={`${t('contract.payment_percent')}`}
          addClass="w-full"
          value={condition.trigger_percent || ''}
          onChange={(e) => setConditions(index, 'trigger_percent', Number(e))}
          error={!validCondition(condition)}
        />
        <InputText
          id={`delay${index}`}
          name={`delay${index}`}
          placeholder={`${t('contract.payment_delay')}`}
          label={`${t('contract.payment_delay')}`}
          addClass="w-full"
          value={condition.delay_days || ''}
          onChange={(e) =>
            numberRegex.test(String(e)) &&
            setConditions(index, 'delay_days', Number(e))
          }
          error={!validCondition(condition)}
        />
        <InputSelect
          placeholder={t('global.choose')}
          label={`${t('contract.payment_trigger')}`}
          addClass="w-full"
          dataArrayString={triggers.map((elt) => elt.value)}
          valueInput={
            condition &&
            triggers.find(
              (elt) => paymentConditions[index].trigger_type === elt.id
            )?.value
          }
          onSelect={(e) => {
            const selectedTrigger = triggers.find((elt) => elt.value === e);

            if (selectedTrigger)
              setConditions(index, 'trigger_type', selectedTrigger.id);
          }}
          error={!validCondition(condition)}
          dataTestIdOptions={`trigger${index}`}
          dataTestIdSelect={`select_trigger${index}`}
        />
      </div>
    );
  };

  return (
    <Card
      title={t('contract.payment_terms')}
      actionButtons={
        <div className="flex gap-3">
          <ButtonOpx
            type="secondary"
            label={`${t('global.back')}`}
            onClick={() => changeStep('back')}
          />
          <ButtonOpx
            type="primary"
            label={`${t('buttons.validate')}`}
            onClick={onSubmit}
            disabled={
              !paymentConditions.some(
                (condition) =>
                  condition.trigger_type &&
                  condition.delay_days &&
                  condition.trigger_percent
              )
            }
            dataTestId="button_validate"
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <form className="flex flex-col gap-3">
          <div>{renderFormFields(0)}</div>
          <div>{renderFormFields(1)}</div>
        </form>
      </FormProvider>
    </Card>
  );
}

export { StepPaymentConditions };
