import { blueOpx, red } from '@assets/color';
import { CalendarIcon } from '@assets/images/svgComponents';
import { ChangeEvent, KeyboardEvent, useMemo } from 'react';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';

interface IInputDatePickerProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  onClickCalendar?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  showCalendar: boolean;
  error?: boolean;
  textError?: string;
  valid?: boolean;
  dataTestId?: string;
}

function InputDatePicker({
  onKeyDown,
  id,
  value,
  onChange,
  disabled = false,
  onClickCalendar,
  required,
  showCalendar,
  error,
  textError,
  valid,
  dataTestId,
}: IInputDatePickerProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const borderColor = useMemo(() => {
    if (error) return 'border-red';
    if (valid) return 'border-green';
    return 'border-borderGrey';
  }, [valid, error]);

  return (
    <div>
      <div className="relative">
        <input
          onChange={(e) => {
            e.stopPropagation();
            handleChange(e);
          }}
          required={required}
          value={value}
          defaultValue={value}
          type="text"
          id={id}
          className={`relative border ${
            showCalendar ? 'rounded-t-default' : 'rounded-default'
          } ${borderColor} p-[.5rem] w-full text-[.875rem] h-[2.5rem] `}
          onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : null)}
          disabled={disabled}
          data-test-id={dataTestId}
          onClick={onClickCalendar}
        />
        <ColorCube
          numberOrIcon={<CalendarIcon />}
          size="1.5rem"
          color={error ? red : blueOpx}
          onClick={onClickCalendar}
          opacity
          addClass="!absolute right-2 top-1/2 transform -translate-y-1/2"
        />
      </div>
      {error && textError && !showCalendar && (
        <div
          style={{ backgroundColor: convertHexToRGBA(red, 0.1) }}
          className="text-red p-[.5rem] rounded-b-default text-sm mt-[.5rem]"
        >
          {textError}
        </div>
      )}
    </div>
  );
}

export { InputDatePicker };

InputDatePicker.defaultProps = {
  id: undefined,
  disabled: false,
  onKeyDown: undefined,
  onClickCalendar: undefined,
  required: false,
  error: false,
  textError: '',
  valid: false,
  dataTestId: '',
};
