function LoaderDocument({ addClassHeight }: { addClassHeight?: string }) {
  return (
    <div
      className={`skeleton-loader-anim w-full border-[1px] border-borderGrey ${
        addClassHeight || ' h-[65vh]'
      }`}
    />
  );
}

LoaderDocument.defaultProps = {
  addClassHeight: undefined,
};

export { LoaderDocument };
