import { cloneElement, useContext, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { textGrey, white } from '@assets/color';
import { HeaderContext } from '@context/headerContext';
import {
  CONTRACTS_ROUTES,
  DASHBOARD_ROUTES,
  SETTINGS_ROUTES,
} from '@utils/routesUrls';
import { INavLinkSidebar } from '../../../types/globalTypes';

interface ILinkButtonProps {
  navLink: INavLinkSidebar;
}

function LinkButton({ navLink }: ILinkButtonProps): JSX.Element {
  const location = useLocation();
  const { sidebarMenuIsOpen } = useContext(GlobalContext);
  const { refreshHeader } = useContext(HeaderContext);

  const [isShown, setIsShown] = useState(false);

  const activateMenu = useMemo(() => {
    const strNavLink = navLink.link || '';
    if (strNavLink !== CONTRACTS_ROUTES.CONTRACTS) {
      const singularNavLink = strNavLink.substring(0, strNavLink.length - 1);
      return location.pathname.indexOf(singularNavLink) !== -1;
    }
    return [CONTRACTS_ROUTES.CONTRACTS, CONTRACTS_ROUTES.CONVENTION].some(
      (substring) => location.pathname.includes(substring)
    );
  }, [navLink, location]);

  const pageActive =
    navLink.link !== undefined &&
    (location.pathname === navLink.link ||
      (navLink.link !== DASHBOARD_ROUTES.DASHBOARD && activateMenu));

  const inOneOfBottomMenu =
    navLink.link?.includes('support') ||
    navLink.link === SETTINGS_ROUTES.SETTINGS;

  const linkActive = pageActive || inOneOfBottomMenu;

  const iconWithProps = cloneElement(navLink.icon as JSX.Element, {
    fill: linkActive ? white : textGrey,
    width: '1.5rem',
    height: '1.5rem',
  });

  const onClickOnLink = () => {
    if (!navLink.link?.includes('support')) {
      refreshHeader();
    }
  };

  const buttonComponent = (
    <button
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      type="button"
      className={`overflow-hidden ${
        pageActive || isShown ? 'bg-greyOpacity20' : ''
      } ${
        !sidebarMenuIsOpen ? 'justify-center w-min px-[.5rem]' : 'px-[1rem]'
      } w-full rounded-lg flex navLinks-center py-[.5rem] rounded-default items-center`}
      data-test-id={navLink.dataTestId}
    >
      {iconWithProps}
      {sidebarMenuIsOpen && (
        <p
          className={`${linkActive ? 'text-white' : 'text-textGrey'} ml-[1rem]`}
        >
          {navLink.name}
        </p>
      )}
    </button>
  );

  return (
    <div>
      {navLink.link?.includes('support') ? (
        <a
          href={navLink.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClickOnLink}
          className={!sidebarMenuIsOpen ? 'flex justify-center' : ''}
        >
          {buttonComponent}
        </a>
      ) : (
        <Link
          to={navLink.link ? navLink.link : '/'}
          onClick={onClickOnLink}
          className={!sidebarMenuIsOpen ? 'flex justify-center' : ''}
        >
          {buttonComponent}
        </Link>
      )}
    </div>
  );
}

export { LinkButton };
