import { blueOpx } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';

interface HeaderKanbanProps {
  numberRequests: number;
  titleKanban: string;
}
function HeaderKanban({ numberRequests, titleKanban }: HeaderKanbanProps) {
  return (
    <div className="flex items-center justify-between w-full">
      <p className="text-[1.125rem] font-medium">{titleKanban}</p>
      <ColorCube
        backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
        color={blueOpx}
        numberOrIcon={numberRequests}
        opacity
        size="1.5rem"
      />
    </div>
  );
}

export { HeaderKanban };
