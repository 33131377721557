import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { CardOneBeneficiaryOrPartner } from '@models/worksiteCreation/components/modalFormBeforeSimulation/CardOneBeneficiaryOrPartner';

function CardOneBeneficiaryWorksiteCreationDesign() {
  const beneficiaryAddress: IBeneficiaryAddress = {
    address_type: 1,
    address: '27, rue chanzy',
    city: 'PARIS',
    postal_code: '75012',
    country: 'FRANCE',
    elevation: null,
    latitude: null,
    longitude: null,
    additional_address: null,
    selected: false,
  };
  return (
    <div className="w-2/3 flex-col space-y-[.5rem] items-center mb-6">
      <div className="mt-5">CARDFORM CREATION DE CHANTIER</div>
      <CardOneBeneficiaryOrPartner
        leftPart="Léon Brossard"
        rightPart={beneficiaryAddress}
      />
      <CardOneBeneficiaryOrPartner
        leftPart="Léon Brossard"
        rightPart={beneficiaryAddress}
        picture="https://picsum.photos/200/300"
      />
      <CardOneBeneficiaryOrPartner leftPart="Raison sociale du partenaire" />
    </div>
  );
}

export { CardOneBeneficiaryWorksiteCreationDesign };
