interface InformationWithLabelProps {
  label: string;
  information: string;
  withMarginBottom?: boolean;
}

function InformationWithLabel({
  label,
  information,
  withMarginBottom,
}: InformationWithLabelProps) {
  return (
    <div className={`w-full ${withMarginBottom ? 'mb-2' : ''}`}>
      <p className="text-textGrey text-[.75rem] leading-3 mb-2">{label}</p>
      <div className="flex items-center justify-between w-full p-[.5rem] py-[.6rem] text-[.875rem] rounded-default border border-borderGrey bg-borderGrey">
        {information}
      </div>
    </div>
  );
}

export default InformationWithLabel;

InformationWithLabel.defaultProps = {
  withMarginBottom: false,
};
