import React, { useContext, useMemo } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useTranslation } from 'react-i18next';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { v4 } from 'uuid';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { useLocation } from 'react-router-dom';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { blueOpx } from '@assets/color';
import { BriefcaseIcon } from '@assets/images/svgComponents';
import { OperationTypeEnum } from '@utils/enums';
import { OneOperationInfos } from './OneOperationInfos';

function ListOperationInfos() {
  const { t } = useTranslation();
  const {
    stepWithTotalCost,
    stepActiveWorksiteCreation,
    worksiteDatas,
    arrayOperations,
    worksiteOperationType,
    graphOperation,
  } = useContext(WorksiteCreationContext);

  const { pathname } = useLocation();

  const operations: IOperation[] = useMemo(() => {
    const arrayOps = arrayOperations as any[];
    //
    if (
      worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION &&
      arrayOps &&
      arrayOps[0] &&
      arrayOps[0]['operation.suboperations']
    ) {
      const newOperations = arrayOps[0]['operation.suboperations'].map(
        (op: { [x: string]: string | number | null }, id: number) => {
          return {
            code: op.code || String(op['operation.id']).toUpperCase(),
            id,
            description: op.description || op['operation.name'],
            can_have_material: false,
            totalCost: op['operation.totalCost'] || '',
          };
        }
      );

      return newOperations as IOperation[];
    }

    const { worksites_operations: worksitesOperations } = worksiteDatas;

    if (pathname.includes('new') && arrayOperations) {
      if (arrayOps[0] && arrayOps[0]['operation.totalCost']) {
        return arrayOps.map((op, id) => ({
          code: op['operation.id'].toUpperCase(),
          id,
          description: op['operation.name'],
          can_have_material: false,
          totalCost: op['operation.totalCost'],
          primes: op.primes,
        }));
      }
      return arrayOperations as IOperation[];
    }

    return worksitesOperations.map((elt) => ({
      ...elt.operation,
      totalCost: elt.total_cost,
    }));
  }, [arrayOperations, worksiteDatas, pathname]);

  return (
    <div
      className={`${
        stepActiveWorksiteCreation !==
        StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF
          ? 'border border-borderGrey'
          : ''
      } rounded-default p-[1.5rem]"`}
    >
      {worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION && (
        <div className="border-b border-b-borderGrey p-[1rem]">
          <div className="w-full">
            <InfosWithIcon
              icon={<BriefcaseIcon />}
              colorIcon={blueOpx}
              infos={[
                {
                  title: t('worksites.operation.title'),
                  subtitle: graphOperation[0].name,
                },
              ]}
            />
          </div>
        </div>
      )}
      {operations.map((operation, index: number) => {
        return (
          <OneOperationInfos
            title={`${
              worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
                ? t('worksites.operation.sub')
                : t('worksites.operation.title')
            } ${index + 1}`}
            subtitle={`${operation.code} : ${operation.description}`}
            border={index !== operations.length - 1}
            isTotalCost={stepWithTotalCost}
            operation={operation}
            iconOrNumber={index + 1}
            key={v4()}
          />
        );
      })}
    </div>
  );
}

export { ListOperationInfos };

ListOperationInfos.defaultProps = {
  border: false,
  totalCost: false,
};
