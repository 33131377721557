import { axiosClient } from '@api/apiClient';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { urlGetEtablissementData } from '@api/apiUrls';

export const getEtablissementData = async (
  siret: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setValue?: UseFormSetValue<FieldValues>
) => {
  try {
    const response = await axiosClient.post(urlGetEtablissementData, { siret });
    const { data } = response.data;
    setLoading(false);

    if (setValue) {
      setValue('id', data.id);
      setValue('company_name', data.company_name);
      setValue('siren', data.siren);
      setValue('siret', data.siret);
      setValue('legal_category', data.legal_category);
      setValue('zipcode', data.zipcode);
      setValue('city', data.city);
      setValue('address', data.address);
      setValue('entity_to_id', data.id);
      setValue('status', data.status);
      setValue('cessation_date', data.cessation_date);
    }

    if (!data.id) {
      const newEntity = {
        siret: data.siret,
        siren: data.siren,
        legal_category: data.legal_category,
        company_name: data.company_name,
        zipcode: data.zipcode,
        city: data.city,
        address: data.address,
      };
      if (setValue) setValue('new_entity', newEntity);
    }

    return data;
  } catch (error) {
    setLoading(false);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      if (error.response?.data?.errors?.length > 0) {
        const firstError = error.response.data.errors[0];
        if (firstError) {
          toast.error(firstError);
          if (firstError.siret && firstError.siret.length > 0) {
            toast.error(firstError.siret[0]);
          }
        }
      } else {
        toast.error(error.response.data.errors);
      }
    }

    if (setValue) {
      setValue('company_name', '');
      setValue('address', '');
      setValue('id', '');
      setValue('siren', '');
      setValue('legal_category', '');
      setValue('zipcode', '');
      setValue('city', '');
      setValue('entity_to_id', '');
      setLoading(false);
    }

    return false;
  }
};
