import { blueOpx } from '@assets/color';

interface IProps {
  name?: string;
  value: string;
  onSelect: (value: string) => void;
  colorText?: string;
  colorRadio?:
    | 'blueOpx'
    | 'red'
    | 'green'
    | 'blue'
    | 'purple'
    | 'textGrey'
    | 'orange';
  label?: string;
  addClass?: string;
  isChecked?: boolean;
  disabled?: boolean;
  id?: string;
  dataTestId?: string;
}

// /!\ pour la props colorRadio, n'utiliser qu'une de ses valeurs:
// blueOpx, red, green, blue, purple, textGrey, orange

//  EXEMPLE D'UTILISATION DANS LE COMPOSANT PARENT:
//  1. useState de la value séléctionné
//   const [selectedValue, setSelectedValue] = useState('');
//
//  2. Fonction à passer en propriété du composant
//   const handleSelection = (value: any) => {
//     setSelectedValue(value);
//   };
//
//  3. Import des composants avec les props à passer
//     Pour que 2 radios soient liés, il faut que les 2 aient le même name
//
//   <Radio
//     name="options"
//     value="option1"
//     onSelect={(value: string) => handleSelection(value)}
//   />
//   <Radio
//     name="options"
//     value="option2"
//     onSelect={(value: string) => handleSelection(value)}
//   />

function Radio({
  name,
  value,
  onSelect,
  colorRadio,
  colorText,
  label,
  addClass,
  isChecked,
  disabled,
  id,
  dataTestId,
}: IProps) {
  const handleChange = () => {
    onSelect(value);
  };

  return (
    <label
      htmlFor={name}
      className={[
        `relative flex items-center space-x-[1rem] ${colorRadio}`,
        addClass,
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      ].join(' ')}
    >
      <input
        id={id || name}
        disabled={disabled || false}
        type="radio"
        name={name}
        onChange={handleChange}
        className={`w-[1.25rem] h-[1.25rem] ${
          disabled ? 'cursor-not-allowed disabled' : 'cursor-pointer'
        }`}
        checked={isChecked}
        data-test-id={dataTestId || id || name}
      />
      {label && <p style={{ color: colorText }}>{label}</p>}
    </label>
  );
}

export { Radio };

Radio.defaultProps = {
  name: undefined,
  colorText: blueOpx,
  colorRadio: 'blueOpx',
  label: '',
  addClass: '',
  isChecked: false,
  disabled: false,
  id: '',
  dataTestId: '',
};
