import { toast } from 'react-toastify';
import { TFunction } from 'i18next';

import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import { PARTNERS_SUBMENU_MAPPING } from '@models/partners/utils/partnersConstants';

import {
  IPartnerRequest,
  IPartnerSubmenu,
} from '@models/partners/utils/types/partnersType';
import {
  IContactReferentType,
  IPartnerContactReferentUpdate,
} from '@models/partners/utils/types/partnerContactType';
import { Dispatch, SetStateAction } from 'react';
import {
  urlGetPartnerActiveTabs,
  urlGetPartnerContracts,
  urlGetPartnerDeposits,
  urlGetPartnerGeneralInfos,
  urlGetPartnerSubcontractors,
  urlGetPartnerWorksites,
  urlPostPartnerConventions,
  urlUpdateContactReferent,
} from '@api/apiUrls';

export const getPartnerActiveTabs = async (
  id: number,
  entityType: number | null
) => {
  try {
    const response = await axiosClient.post(urlGetPartnerActiveTabs, {
      entity_type: entityType,
      entity_id: Number(id),
    });
    return response.data
      .sort((subMenu1: IPartnerSubmenu, subMenu2: IPartnerSubmenu) =>
        subMenu1.submenu < subMenu2.submenu ? -1 : 1
      )
      .map((subMenuItem: IPartnerSubmenu) => {
        return {
          ...subMenuItem,
          content:
            PARTNERS_SUBMENU_MAPPING[subMenuItem.submenuLabel].content || '',
        };
      });
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnerGeneralInfos = async ({
  entityId,
  entityType,
}: IPartnerRequest) => {
  try {
    const response = await axiosClient.post(
      urlGetPartnerGeneralInfos(entityId),
      { entity_type: entityType || null }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnerSubcontractors = async ({
  page,
  perPage,
  entityId,
}: IPartnerRequest) => {
  try {
    const response = await axiosClient.post(
      urlGetPartnerSubcontractors(entityId),
      { pagination: { page, perPage } }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnerConventions = async ({
  page,
  perPage,
  entityId,
  operationType,
}: IPartnerRequest) => {
  try {
    const response = await axiosClient.post(
      urlPostPartnerConventions(entityId),
      { pagination: { page, perPage }, operationType }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnerDeposits = async ({
  entityId,
  entityType,
}: IPartnerRequest) => {
  try {
    const response = await axiosClient.get(urlGetPartnerDeposits(entityId), {
      params: { entityType },
    });
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnerContracts = async ({
  page,
  perPage,
  entityId,
  entityType,
}: IPartnerRequest) => {
  try {
    const response = await axiosClient.post(urlGetPartnerContracts(entityId), {
      pagination: { page, perPage },
      entity_type: entityType,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const updatePartnerContactReferent = async (
  worksiteId: number,
  data: IPartnerContactReferentUpdate,
  t: TFunction,
  setShowContactForm: Dispatch<SetStateAction<boolean>>,
  setReferent: Dispatch<SetStateAction<IContactReferentType | null>>
) => {
  try {
    const response = await axiosClient.post(
      urlUpdateContactReferent(worksiteId),
      data
    );
    toast.success(t('partners.contact_update_success'));
    setShowContactForm(false);
    setReferent(response.data.data);
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnerWorksites = async ({
  entityId,
  entityType,
}: IPartnerRequest) => {
  try {
    const response = await axiosClient.get(urlGetPartnerWorksites(entityId), {
      params: { entityType },
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};
