import { t } from 'i18next';
import {
  IWorksiteDetails,
  IWorksiteHousing,
  IWorksiteInstaller,
} from '@models/worksites/utils/worksitesTypes';
import {
  BriefcaseIcon,
  CalendarIcon,
  FileIcon,
  MailIcon,
  PersonIcon,
  PhoneIcon,
  PositionIcon,
} from '@assets/images/svgComponents';
import { dateToDDMMYYY } from '@utils/format';
import { OperationTypeEnum } from '@utils/enums';
import { IGlobalEnumType } from '../../../../types/globalTypes';

const installerIcons = (installer: IWorksiteInstaller) => {
  return [
    {
      title: t('partners.company_name'),
      subtitle: installer.company_name,
      icon: <BriefcaseIcon />,
    },
    {
      title: t('partners.address'),
      subtitle: installer.address,
      icon: <PositionIcon />,
    },
    {
      title: t('partners.email_address'),
      subtitle: installer.contact_email,
      icon: <MailIcon />,
    },
    {
      title: t('partners.phone'),
      subtitle: installer.phone_number,
      icon: <PhoneIcon />,
    },
  ];
};

const worksiteIcons = (
  worksite: IWorksiteDetails,
  globalEnum: IGlobalEnumType
) => {
  const precarity = globalEnum.precarity_type[worksite.precarity_type];
  return {
    infos: [
      {
        title: t('worksite_creation.steps.worksite_creation'),
        subtitle: worksite.dates?.creation
          ? dateToDDMMYYY(worksite.dates?.creation)
          : '-',
        icon: <CalendarIcon />,
        addClass: 'mb-5',
      },
      {
        title: t('worksites.projected_end_date'),
        subtitle: worksite.dates?.projected_end
          ? dateToDDMMYYY(worksite.dates?.projected_end)
          : '-',
        addClass: 'mb-5',
      },
    ],
    operations: worksite.worksites_operations.map((op, i) => [
      {
        title: `Opération ${i + 1}`,
        subtitle: op.operation.description,
        icon: i + 1,
      },
      {
        title: t('worksites.operation.bonus_cee'),
        subtitle: `${op.cdp_amount || op.cee_amount} €`,
        icon: <>€</>,
      },
      {
        title: t('worksites.housing.precarity'),
        subtitle: precarity,
      },
      {
        title: t('worksites.operation.total_cost'),
        subtitle: `${op.total_cost} €`,
      },
      !op.subcontractor
        ? null
        : {
            title: t('partners.subcontractors.name'),
            subtitle: op.subcontractor.company_name || '',
            icon: <PersonIcon />,
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.siret'),
            subtitle: op.subcontractor.siret || '',
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.address'),
            subtitle: `${op.subcontractor.address}`,
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.phone'),
            subtitle: `${op.subcontractor.phone_number}`,
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.email_address'),
            subtitle: `${op.subcontractor.contact_email}`,
          },
    ]),
  };
};

const housingIcons = (
  housing: IWorksiteHousing,
  globalEnum: IGlobalEnumType,
  removal: string
) => {
  return [
    {
      title: t('worksite_creation.simulation.habitation.title'),
      subtitle: housing ? globalEnum.housing_type[housing.housing_type] : '',
      icon: <BriefcaseIcon />,
      addClass: 'mb-5',
    },
    {
      title: t('worksites.housing.housing_age'),
      subtitle: housing ? globalEnum.age_type[housing.age_type] : '',
      addClass: 'mb-5',
    },
    {
      title: t('worksites.housing.surface_area'),
      subtitle: `${housing ? housing.surface : ''} m2`,
    },
    {
      title: t('worksites.housing.removal'),
      subtitle: removal,
    },
  ];
};

const beneficiaryIcons = (
  worksite: IWorksiteDetails,
  globalEnum: IGlobalEnumType
) => {
  const { beneficiary, fiscalDeclarations } = worksite;
  const fiscalDeclaration = fiscalDeclarations ? fiscalDeclarations[0] : null;
  const personNumber = worksite.housing
    ? `${worksite.housing.persons} ${t('global.people')}${
        worksite.housing.persons > 1 ? 's' : ''
      }`
    : '-';

  const occupationType = worksite.housing
    ? globalEnum.occupation_type[worksite.housing.occupation_type]
    : '';
  const worksiteAddress = worksite.address;

  const isB2b = worksite.operation_type === OperationTypeEnum.B2B;
  return {
    top: [
      isB2b
        ? {
            title: t('worksite_creation.partners.siret'),
            subtitle: beneficiary.siret,
            addClass: 'mb-5',
            icon: <BriefcaseIcon />,
          }
        : undefined,
      {
        title: t('partners.email_address'),
        subtitle: beneficiary.email,
        icon: <MailIcon />,
        addClass: 'mb-5',
      },
      {
        title: t('partners.address'),
        subtitle: beneficiary.address.address,
        icon: <PositionIcon />,
        addClass: 'mb-5',
      },
      {
        title: t('infos.zipcode_city'),
        subtitle: `${beneficiary.address.postal_code} ${beneficiary.address.city}`,
        addClass: 'mb-5',
      },
      !fiscalDeclaration
        ? undefined
        : {
            title: t('worksites.beneficiary.fiscality_number'),
            subtitle: String(fiscalDeclaration.fiscal_number),
            addClass: 'mb-5',
            icon: <FileIcon />,
          },
    ],
    bottom: [
      isB2b
        ? undefined
        : {
            title: t('worksites.housing.household_incomes'),
            subtitle: globalEnum.precarity_type[worksite.precarity_type],
            addClass: 'mb-5',
            icon: <BriefcaseIcon />,
          },
      isB2b
        ? undefined
        : {
            title: t('worksites.housing.household_people'),
            subtitle: personNumber,
            addClass: 'mb-5',
            icon: <BriefcaseIcon />,
          },
      {
        title: t('worksites.housing.client_type'),
        subtitle: occupationType,
        addClass: 'mb-5',
        icon: <BriefcaseIcon />,
      },
      {
        title: t('worksites.address'),
        subtitle: worksiteAddress ? worksiteAddress.address : '',
        addClass: 'mb-5',
        icon: <PositionIcon />,
      },
      {
        title: t('infos.zipcode_city'),
        subtitle: worksiteAddress
          ? `${worksiteAddress.postal_code} ${worksiteAddress.city}`
          : '',
        addClass: 'mb-5',
      },
    ],
  };
};

export { installerIcons, housingIcons, worksiteIcons, beneficiaryIcons };
