// import { getLinkedFile } from '@apiRequests/globalRequests';
// import { GlobalContext } from '@context/globalContext';
import { WORK_SITE_DETAILS_TABS } from '@models/worksites/utils/tab';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import moment from 'moment';
import 'moment/locale/fr';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface OneMessageDetailsRequestProps {
  background: boolean;
  border: boolean;
  message: string;
  date: string;
  senderName: string;
  senderImage: string | null;
}

function OneMessageDetailsRequest({
  background,
  border,
  message,
  date,
  senderName,
  senderImage,
}: OneMessageDetailsRequestProps) {
  const { t } = useTranslation();
  // const { updateDocumentActive } = useContext(GlobalContext);
  const { updateTabActive } = useContext(WorksitesContext);

  const dateMessage = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM');
  const openDocsTab = async () => {
    updateTabActive(WORK_SITE_DETAILS_TABS(t).DOCS);

    // TODO: to complete in OPX-2465
    // getLinkedFile(fileId).then((res) => {
    //   if (res.data) updateDocumentActive({ list: '', document: res.data });
    // });
  };

  return (
    <button
      type="button"
      onClick={openDocsTab}
      className="bg-white rounded-default w-full pt-0"
    >
      <div
        className={`p-[1rem] w-full ${background ? 'bg-backgroundBody' : ''}  ${
          border ? 'border border-borderGrey' : ''
        } rounded-[4px]`}
      >
        <div className="flex space-x-[.5rem]  w-full">
          {senderImage === null ? (
            <div className="h-[1.5rem] min-h-[1.5rem] w-[1.5rem]  min-w-[1.5rem] bg-borderGrey rounded-[4px]" />
          ) : (
            <img
              className="h-[1.5rem] min-h-[1.5rem] w-[1.5rem]  min-w-[1.5rem] rounded-[4px]"
              src={senderImage}
              alt="user"
            />
          )}
          <div className="w-full">
            <div className="flex justify-between w-full  items-center">
              <p className="text-[.813rem] font-medium">{senderName}</p>
              <p className="text-[.75rem] text-textGrey">{dateMessage}</p>
            </div>
            <div className="text-[.75rem] text-justify pt-[1.25rem]">
              {message}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

export { OneMessageDetailsRequest };
