import React, { useMemo } from 'react';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { Tag } from '@components/atomic/Tag';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';

import { lightBlue, purple, textGrey } from '@assets/color';
import { InputText } from '@components/atomic/inputs/InputText';
import { alphanumericRegex } from '@utils/regex';
import { convertKiloToMega } from '@utils/functions';
import { ITransferType } from '../utils/contractTypes';

interface ITransferReferenceProps {
  data: ITransferType | undefined;
  setOdtNumber: (value: string) => void;
  isEdit: boolean;
}

function TransferReference(props: ITransferReferenceProps) {
  const { data, setOdtNumber, isEdit } = props;
  const { t } = useTranslation();

  const methods = useForm();
  const { watch } = methods;
  const valuesForm = watch();

  const deliveryDate = useMemo(() => {
    if (!data || !data.validation_date) return '-';

    const text = parse(
      data.validation_date || '',
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );

    return format(text, 'dd/MM/yyyy');
  }, [data]);

  const ratioPrecaire = useMemo(() => {
    return data && Number(data.gisement.totalKwhc) > 0
      ? Number(data.gisement.precaire) / Number(data.gisement.totalKwhc)
      : 0;
  }, [data]);

  const ratioClassic = useMemo(() => {
    return data && Number(data.gisement.totalKwhc) > 0
      ? Number(data.gisement.classique) / Number(data.gisement.totalKwhc)
      : 0;
  }, [data]);

  if (!data) return null;

  return (
    <div className="border border-borderGrey rounded-default2 bg-white my-[1.5rem] p-6">
      <div>
        <div className="flex flex-wrap justify-between items-center">
          <div>
            {isEdit ? (
              <FormProvider {...methods}>
                <div className="flex items-center mb-[1rem]">
                  <span className="mr-[1rem] text-[1.25rem] font-medium min-w-max">
                    N° ODT
                  </span>
                  <InputText
                    onChange={(value) => {
                      setOdtNumber(value as string);
                    }}
                    onPaste={(e: any) => {
                      const val = e.clipboardData.getData('Text');
                      if (val !== '' && alphanumericRegex.test(val)) {
                        setOdtNumber(val.trim());
                      }
                    }}
                    id="odt"
                    name="odt"
                    placeholder=""
                    valid={
                      valuesForm.odt !== '' &&
                      alphanumericRegex.test(valuesForm.odt)
                    }
                    error={
                      !valuesForm.odt ||
                      valuesForm.odt === '' ||
                      !alphanumericRegex.test(valuesForm.odt)
                    }
                  />
                </div>
              </FormProvider>
            ) : (
              <p className="mr-[.5rem] text-[1.25rem] font-medium">{`N° ODT ${
                data.odt_number || '-'
              }`}</p>
            )}

            <p className="text-[.875rem] text-red">{`${t(
              'contract.tab_info_general.validation_date'
            )}: ${deliveryDate}`}</p>
          </div>
          <Tag
            color={textGrey}
            label={`${data.count} ${t('contract.tab_volume_detail.operation')}${
              data.count > 1 ? 's' : ''
            }`}
            addClass="h-fit"
          />
        </div>

        <div className="my-[1.5rem]">
          <MultipleProgressBar
            addClass="!h-[0.5rem]"
            showLegend={false}
            data={[
              {
                ratio: ratioClassic,
                color: lightBlue,
                label: 'blue',
              },
              {
                ratio: ratioPrecaire,
                color: purple,
                label: 'purple',
              },
            ]}
          />
        </div>

        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center">
            <div className="w-[.438rem] h-[.438rem] bg-purple overflow-hidden rounded-[100%] mr-[.5rem]" />
            <span className="mr-[1rem] text-[.625rem]">{`${t(
              'contract.tab_volume_detail.volume_precaire'
            )} : ${convertKiloToMega(data.gisement.precaire)} MWhc `}</span>
            <div className="w-[.438rem] h-[.438rem] bg-iconBlue overflow-hidden rounded-[100%] mr-[.5rem]" />
            <span className="mr-[1rem] text-[.625rem]">{`${t(
              'contract.tab_volume_detail.volume_classic'
            )} : ${convertKiloToMega(data.gisement.classique)} MWhc`}</span>
          </div>
          <span className="text-[1rem]">{`${t(
            'contract.tab_volume_detail.volume_total'
          )} : ${convertKiloToMega(data.gisement.totalKwhc)} MWhc`}</span>
        </div>
      </div>
    </div>
  );
}

export { TransferReference };
