import {
  IPayloadInfosBeneficiary,
  IPayloadInfosLogement,
  IWorksiteAddress,
} from '@models/worksiteCreation/utils/types/worksitesType';

const informationBeneficiaryDataPostInitial: IPayloadInfosBeneficiary = {
  fiscalDeclarations: [
    { firstname: '', lastname: '', fiscal_number: '', fiscal_reference: '' },
  ],
};

const informationLogementDataPostInitial: IPayloadInfosLogement = {
  indivision: false,
  cadastral_parcel: '',
};

const worksiteAddressInitial: IWorksiteAddress = {
  zipCode: '',
  streetName: '',
  city: '',
  numberStreet: '',
  country: '',
  latitude: 0,
  longitude: 0,
  parcelSection: '',
  parcelNumber: '',
  parcelPrefix: '',
};

export {
  informationBeneficiaryDataPostInitial,
  informationLogementDataPostInitial,
  worksiteAddressInitial,
};
