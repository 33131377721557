import {
  IProjectedEnd,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '@context/globalContext';
import { KpiTab } from '@components/atomic/KpiTab';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { LoaderWorksiteDetailsOperations } from '@components/loaders/worksites/LoaderWorksiteDetailsOperations';
import {
  storeProjectedEnd,
  updateBeneficiaryAmount,
} from '@models/worksites/apiRequests/worksitesRequests';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { dateRegex } from '@utils/regex';
import { toAPIDateStr } from '@utils/format';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { getWorksiteKpiData } from '@models/worksites/utils/worksiteHelper';
import ModalPrimesDistribution from '@models/worksiteCreation/components/simulation/summary/ModalPrimesDistribution';
import { WorksiteDetailsOperationCard } from './operations/WorksiteDetailsOperationCard';
import { IKPITab } from '../../../../types/globalTypes';

type IWorksiteDetailsOperationProps = {
  isLoading: boolean;
  refresh: () => Promise<void>;
};

function WorksiteDetailsOperations({
  isLoading,
  refresh,
}: IWorksiteDetailsOperationProps): JSX.Element {
  const { t } = useTranslation();
  const { globalEnum, roleUser } = useContext(GlobalContext);
  const { worksiteDetails, updateWorksiteDetails } =
    useContext(WorksitesContext);

  const [storeProjectEndsLoading, setStoreProjectEndsLoading] = useState(false);

  const [projectedEnds, setProjectedEnds] = useState<IProjectedEnd[]>([]);
  const [modalPrimesDistribution, setModalPrimesDistribution] = useState(false);
  const [beneficiaryAmount, setBeneficiaryAmount] = useState(
    worksiteDetails.beneficiary_amount || 0
  );

  const [updateLoading, setUpdateLoading] = useState(false);

  const handleChangeProjectedEnd = (id: number, date: string) => {
    setProjectedEnds((prevProjectedEnds) =>
      prevProjectedEnds.map((item) =>
        item.id === id
          ? {
              ...item,
              projected_end:
                date.length === 10 && dateRegex.test(date)
                  ? toAPIDateStr(date)
                  : '',
            }
          : item
      )
    );
  };

  const totalMpr = useMemo(() => {
    return worksiteDetails.worksites_operations.reduce(
      (sum, obj) => sum + Number(obj.mpr_amount || 0),
      0
    );
  }, [worksiteDetails]);

  const totalCee = useMemo(() => {
    return worksiteDetails.worksites_operations.reduce((sum, obj) => {
      const cdpAmount = obj.cdp_amount || 0;
      const ceeAmount = obj.cee_amount || 0;

      const ceeToAdd = cdpAmount > 0 ? cdpAmount : ceeAmount;
      return sum + ceeToAdd;
    }, 0);
  }, [worksiteDetails]);

  const totalCeePrecarious = useMemo(() => {
    return worksiteDetails.worksites_operations.reduce(
      (sum, obj) => sum + Number(obj.cee_precaire || 0),
      0
    );
  }, [worksiteDetails]);

  const totalCeeClassic = useMemo(() => {
    return worksiteDetails.worksites_operations.reduce(
      (sum, obj) => sum + Number(obj.cee_classique || 0),
      0
    );
  }, [worksiteDetails]);

  const totalVolumePrecarious = useMemo(() => {
    return worksiteDetails.worksites_operations.reduce(
      (sum, obj) => sum + Number(obj.kwhc_precaire || 0),
      0
    );
  }, [worksiteDetails]);

  const totalVolumeClassic = useMemo(() => {
    return worksiteDetails.worksites_operations.reduce(
      (sum, obj) => sum + Number(obj.kwhc_classique || 0),
      0
    );
  }, [worksiteDetails]);

  const onSubmitProjectedEnds = async () => {
    if (projectedEnds) {
      setStoreProjectEndsLoading(true);
      await storeProjectedEnd(worksiteDetails.id, projectedEnds);
      await refresh();

      setStoreProjectEndsLoading(false);
    }
  };

  const kpiTabWorksiteDetailsOperations: IKPITab[] = getWorksiteKpiData(
    roleUser,
    globalEnum,
    worksiteDetails,
    totalMpr,
    totalCeePrecarious,
    totalCeeClassic,
    totalCee,
    totalVolumeClassic,
    totalVolumePrecarious,
    () => setModalPrimesDistribution(true)
  );

  useEffect(() => {
    if (
      worksiteDetails.status === WORKSITE_STATUS.WORKSITE_IN_PROGRESS &&
      projectedEnds.length === 0
    ) {
      setProjectedEnds(
        worksiteDetails.worksites_operations
          .filter((op) => !op.projected_end)
          .map((operation) => {
            return {
              id: operation.id,
              projected_end: '',
            };
          })
      );
    }
    if (worksiteDetails.beneficiary_amount) {
      setBeneficiaryAmount(worksiteDetails.beneficiary_amount);
    }
  }, [worksiteDetails]);

  const updateWorksiteBeneficiaryAmount = async () => {
    if (!updateLoading) {
      setUpdateLoading(true);
      const res = await updateBeneficiaryAmount(
        worksiteDetails.id,
        beneficiaryAmount
      );

      if (res.data.beneficiary_amount) {
        setBeneficiaryAmount(res.data.beneficiary_amount);
        updateWorksiteDetails({
          ...worksiteDetails,
          beneficiary_amount: res.data.beneficiary_amount,
        });
        setModalPrimesDistribution(false);
      } else {
        setBeneficiaryAmount(worksiteDetails.beneficiary_amount || 0);
      }

      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (
      worksiteDetails.beneficiary_amount &&
      beneficiaryAmount !== worksiteDetails.beneficiary_amount
    ) {
      updateWorksiteBeneficiaryAmount();
    }
  }, [beneficiaryAmount]);

  return (
    <div className="w-full" data-test-id="worksite_detail_operations">
      {isLoading ? (
        <LoaderWorksiteDetailsOperations />
      ) : (
        <div className="w-full flex flex-col space-y-[1.5rem]">
          <KpiTab
            infos={kpiTabWorksiteDetailsOperations}
            personInfos={
              worksiteDetails.referent.contact
                ? {
                    label: t('contract.contact_referent'),
                    name: `${worksiteDetails.referent.contact.firstname} ${worksiteDetails.referent.contact.lastname}`,
                    photoUrl: worksiteDetails.referent.contact.photo_url,
                  }
                : undefined
            }
          />
          {worksiteDetails.status === WORKSITE_STATUS.WORKSITE_IN_PROGRESS &&
            worksiteDetails.worksites_operations.some(
              (operation) => !operation.projected_end
            ) && (
              <div>
                <ButtonOpx
                  label={t('worksites.validate_projected_end_dates')}
                  onClick={onSubmitProjectedEnds}
                  disabled={
                    projectedEnds.length < 1 ||
                    projectedEnds.some(
                      (projectedEnd) => projectedEnd.projected_end === ''
                    )
                  }
                  isLoading={storeProjectEndsLoading}
                />
              </div>
            )}
          {worksiteDetails.worksites_operations.map(
            (operation: IWorksiteOperation) => (
              <div key={`${operation.id}-${operation.operation.code}`}>
                <WorksiteDetailsOperationCard
                  operation={operation}
                  handleChangeProjectedEnd={handleChangeProjectedEnd}
                />
              </div>
            )
          )}
        </div>
      )}
      {modalPrimesDistribution && (
        <ModalPrimesDistribution
          setOpen={setModalPrimesDistribution}
          totalCeeBonus={totalCee}
          beneficiaryAmount={beneficiaryAmount}
          setBeneficiaryAmount={setBeneficiaryAmount}
          installerAmount={totalCee - beneficiaryAmount}
          beneficiaryAmountMinimum={
            worksiteDetails.beneficiary_minimum_amount || 0
          }
          isLoading={updateLoading}
          isAsync
        />
      )}
    </div>
  );
}

export { WorksiteDetailsOperations };
