import { DocumentViewer } from '@components/documents/DocumentViewer';
import { useState } from 'react';

function PartRightDocuments() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <div className="min-w-[50%] max-w-[50%]">
      <DocumentViewer isLoading={isLoading} setIsLoading={setIsLoading} />
    </div>
  );
}

export { PartRightDocuments };
