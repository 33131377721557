import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { ISimulatorData } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { isValueValid } from '@utils/functions';
import { OperationTypeEnum } from '@utils/enums';
import { Map } from '../../Map';
import { ListInfoGeneral } from '../../simulation/summary/summaryPartRight/infosGeneral/ListInfoGeneral';
import { ListOperationInfos } from '../../simulation/summary/operations/ListOperationInfos';

function SummaryPartRight() {
  const { t } = useTranslation();
  const {
    simulatorData,
    stepActiveWorksiteCreation,
    arrayOperations,
    worksiteOperationType,
  } = useContext(WorksiteCreationContext);

  // Liste des propriétés à vérifier
  const propertiesToCheck = (): string[] => {
    const properties = ['general.age'];

    if (worksiteOperationType === OperationTypeEnum.B2B) {
      properties.push('general.habitationZone');
    }

    if (worksiteOperationType === OperationTypeEnum.B2C) {
      properties.push('general.surface', 'general.habitation');
      if (
        stepActiveWorksiteCreation >
        StepsWorksiteCreationEnum.SIMULATION_INFO_BENEFICIARY
      ) {
        properties.push('general.income', 'general.persons', 'general.owner');
      }
    }

    return properties;
  };

  // Vérifie si toutes les propriétés spécifiées dans l'objet simulatorData sont valides
  const arePropertiesValid = (
    object: ISimulatorData,
    properties: string[]
  ): boolean => {
    return properties.every((prop) => isValueValid(object[prop]));
  };

  const conditionDisplayListGeneral = arePropertiesValid(
    simulatorData,
    propertiesToCheck()
  );

  const showInfosGeneral = useMemo(() => {
    if (
      stepActiveWorksiteCreation <=
      StepsWorksiteCreationEnum.SIMULATION_INFO_BENEFICIARY
    ) {
      return propertiesToCheck().some((prop) =>
        isValueValid(simulatorData[prop])
      );
    }
    return conditionDisplayListGeneral;
  }, [simulatorData]);

  return (
    <div className="p-[1.5rem] h-full flex flex-col">
      <p className="text-[1.5rem] font-medium">
        {t('worksite_creation.simulation.summary.title')}
      </p>
      <div
        className={`relative ${
          stepActiveWorksiteCreation !== StepsWorksiteCreationEnum.DOCUMENTS
            ? 'min-h-[30vh] h-[30vh]'
            : 'min-h-[20vh] h-[20vh]'
        } mt-[1.5rem]`}
      >
        <Map />
      </div>
      <div className="flex-grow mt-[1rem] overflow-y-scroll">
        {showInfosGeneral && <ListInfoGeneral />}

        {stepActiveWorksiteCreation !==
          StepsWorksiteCreationEnum.GLOBAL_CHOICEOPERATIONS &&
          arrayOperations &&
          arrayOperations.length > 0 && (
            <div className="mt-5">
              <p className="text-4 my-4">
                {`${t('worksites.operation.title')}${
                  arrayOperations?.length > 1 ? 's' : ''
                }`}
              </p>

              <ListOperationInfos border />
            </div>
          )}
      </div>
    </div>
  );
}

export { SummaryPartRight };
