import { InputText } from '@components/atomic/inputs/InputText';
import { IArrayInput } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { useContext } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface IArrayInputProps {
  data: IArrayInput;
  onChange: (value: string | number | boolean, key: string) => void;
  inputValue: string;
  index: number;
  boilerPowerError?: boolean;
  boilerPowerTextError?: string;
}

function ArrayInput({
  data,
  onChange,
  inputValue,
  index,
  boilerPowerError,
  boilerPowerTextError,
}: IArrayInputProps) {
  const { readOnly } = useContext(WorksiteCreationContext);

  const options = !data.options
    ? []
    : data.options.map((option) => option.label);

  const onSelect = (value: string) => {
    const option = data.options?.find((elt) => elt.label === value);

    if (option) onChange(option.value, data.key);
  };

  switch (data.type) {
    case 'card':
      return (
        <InputSelect
          required
          label={index ? `${data.label} ${index}` : data.label}
          placeholder={data.label}
          dataArrayString={options}
          onSelect={(value) => onSelect(value)}
          valueInput={
            data.options?.find((op) => op.value === inputValue)?.label || ''
          }
          addClass="my-3"
          disabled={readOnly}
        />
      );
    default:
      return (
        <InputText
          id={`array-input-${index}`}
          name={`array-input-${index}`}
          required
          label={`${data?.label} ${index}`}
          placeholder=""
          onChange={(value) => onChange(String(value), data.key)}
          typeNumber={data.type === 'input-number'}
          value={inputValue}
          addClass="my-3"
          disabled={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
          error={boilerPowerError}
          textError={boilerPowerTextError}
        />
      );
  }
}

export default ArrayInput;

ArrayInput.defaultProps = {
  boilerPowerError: undefined,
  boilerPowerTextError: false,
};
