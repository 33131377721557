import {
  LogoOpxIconBlue,
  LogoOpxIconBlueFill,
} from '@assets/images/svgComponents';
import { useContext, useEffect, useState } from 'react';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { WORK_SITE_DETAILS_TABS } from '@models/worksites/utils/tab';
import { BeneficiaryInfos } from '@models/beneficiaries/components/view/BeneficiaryInfos';
import { GlobalContext } from '@context/globalContext';
import { checkEntity } from '@models/beneficiaries/apiRequests/beneficiariesRequests';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { useTranslation } from 'react-i18next';
import { SecurityPage } from '@models/beneficiaries/components/view/SecurityPage';
import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
import { extractAllWorksiteLinkedFiles } from '@utils/functions';
import { ILinkedFile } from 'types/globalTypes';
import { LoaderListDocumentCard } from '@components/loaders/document/LoaderListDocumentCard';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { TabDetails } from '@models/beneficiaries/components/view/TabDetails';
import { INCENTIVE_TYPE_ENUM } from '@models/worksites/utils/enums';

function BeneficiaryView() {
  const { t } = useTranslation();
  const { isMobile } = useContext(GlobalContext);
  const [worksiteDatas, setWorksiteDatas] = useState<
    IWorksiteDetails | undefined
  >();
  const [activeTab, setActiveTab] = useState<string>(
    WORK_SITE_DETAILS_TABS(t).DETAILS
  );
  const [canAccess, setCanAccess] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allLinkedFiles, setAllLinkedFiles] = useState<ILinkedFile[]>([]);
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);
  const { uuid } = useParams();
  const navigate = useNavigate();

  const checkUser = async () => {
    const result = await checkEntity(String(uuid), setWorksiteDatas, navigate);
    if (result) {
      setLoggedIn(true);
      setCanAccess(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkUser();
  }, []);

  const handleUpdateFiles = async () => {
    if (!worksiteDatas) return;
    await checkUser();
  };

  useEffect(() => {
    if (worksiteDatas) {
      setAllLinkedFiles(extractAllWorksiteLinkedFiles(worksiteDatas));
    }
  }, [worksiteDatas]);

  if (isLoading) {
    return (
      <AuthLayout>
        <div className="w-full flex flex-col lg:max-w-[35rem] gap-5">
          <LogoOpxIconBlueFill width="5rem" height="5rem" className="mx-auto" />
          <p className="text-center text-xl">{t('global.isLoading')}</p>
        </div>
      </AuthLayout>
    );
  }

  if (!canAccess) {
    return (
      <SecurityPage
        setWorksiteDatas={setWorksiteDatas}
        setCanAccess={setCanAccess}
        setIsLoading={setIsLoading}
      />
    );
  }

  return (
    <div className="w-screen mix-h-screen absolute left-0 top-0 z-50 bg-backgroundBody">
      <div className={`flex h-full ${isMobile ? 'flex-col' : 'flex-row'} `}>
        <div className={`px-5 py-3 ${isMobile ? 'w-full' : 'w-9/12'}`}>
          <div className="flex h-[5rem] items-center gap-10">
            <LogoOpxIconBlue />
            <div>
              <TabsLeftPart
                titlesList={[
                  WORK_SITE_DETAILS_TABS(t).DETAILS,
                  WORK_SITE_DETAILS_TABS(t).DOCS,
                ]}
                onClick={(tab) => setActiveTab(tab)}
                activeButton={activeTab}
              />
            </div>
          </div>
          {worksiteDatas && (
            <div>
              {activeTab === WORK_SITE_DETAILS_TABS(t).DOCS && (
                <div className="flex space-x-[1.5rem]">
                  <div className="w-1/2 flex flex-col space-y-[.5rem]">
                    {isLoading ? (
                      <LoaderListDocumentCard />
                    ) : (
                      allLinkedFiles !== undefined && (
                        <ListDocumentsCard
                          documents={allLinkedFiles}
                          worksiteDatas={worksiteDatas}
                          updateParentDocuments={handleUpdateFiles}
                          readOnly={
                            worksiteDatas.incentive_type ===
                              INCENTIVE_TYPE_ENUM.INDIRECT && !loggedIn
                          }
                        />
                      )
                    )}
                  </div>
                  <div className="w-1/2">
                    <DocumentViewer
                      isLoading={isLoadingDocument}
                      setIsLoading={setIsLoadingDocument}
                      showArchives
                    />
                  </div>
                </div>
              )}
              {activeTab === WORK_SITE_DETAILS_TABS(t).DETAILS && (
                <TabDetails worksiteDatas={worksiteDatas} loggedIn={loggedIn} />
              )}
            </div>
          )}
        </div>
        {worksiteDatas && !isMobile && (
          <div className="w-3/12 bg-white h-full">
            <BeneficiaryInfos worksiteDatas={worksiteDatas} />
          </div>
        )}
      </div>
    </div>
  );
}

export { BeneficiaryView };
