import {
  IImpersonateUserRequest,
  IUserComplianceRequest,
  IUsersListRequest,
} from '@models/admin/users/utils/usersType';
import { axiosClient } from '@api/apiClient';
import {
  urlAdminGetUserActionsHistory,
  urlAdminGetUsersList,
  urlAdminImpersonateUser,
  urlAdminLeaveImpersonation,
  urlAdminMarkAsNonCompliant,
  urlAdminRenewIdentityCheckDemand,
  urlAdminSetUserAccountCompliance,
} from '@api/apiUrls';
import { displayError } from '@utils/format';
import { Dispatch, SetStateAction } from 'react';
import Cookies from 'js-cookie';

export const getUsersList = async ({
  page,
  perPage,
  sortAndFilterData,
  searchKeyword,
}: IUsersListRequest) => {
  try {
    const response = await axiosClient.post(urlAdminGetUsersList, {
      pagination: { page, perPage },
      sort_and_filter: sortAndFilterData,
      search_keyword: searchKeyword,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const setUserAccountCompliance = async ({
  user_id,
  account_compliant,
}: IUserComplianceRequest) => {
  try {
    const response = await axiosClient.post(
      urlAdminSetUserAccountCompliance(user_id),
      {
        account_compliant,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const impersonateUser = async (
  user_id: number,
  updateImpersonateToken: Dispatch<SetStateAction<string | undefined>>
) => {
  try {
    const response = await axiosClient.post(urlAdminImpersonateUser(user_id));
    if (response.data.success) {
      updateImpersonateToken('impersonate_token');
      Cookies.set('impersonate_token', 'impersonate_token', {
        expires: 1,
        secure: true,
      });
    }
    return true;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const leaveImpersonation = async (
  updateImpersonateToken: Dispatch<SetStateAction<string | undefined>>
) => {
  try {
    await axiosClient.post(urlAdminLeaveImpersonation);
    Cookies.remove('impersonate_token');
    updateImpersonateToken('');
    return true;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const markAsNonCompliant = async ({
  user_id,
}: IImpersonateUserRequest) => {
  try {
    const response = await axiosClient.post(
      urlAdminMarkAsNonCompliant(user_id)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getUserActionsHistory = async (
  user_id: number,
  { page, perPage }: { page: number; perPage: number }
) => {
  try {
    const response = await axiosClient.post(
      urlAdminGetUserActionsHistory(user_id),
      {
        page,
        perPage,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const renewIdentityCheckDemand = async (user_id: number) => {
  try {
    const response = await axiosClient.post(
      urlAdminRenewIdentityCheckDemand(user_id)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};
