import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { handleUploadClick } from '@utils/functions';
import { toast } from 'react-toastify';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { ILinkedFile } from '../../types/globalTypes';

interface IUploadButtonProps {
  name: string;
  onUpload: (file: File, originalLinkedFile: ILinkedFile) => void;
  fileType: number;
  document?: ILinkedFile;
  color?: 'blue' | 'grey';
  icon?: JSX.Element;
  addClass?: string;
  disabled?: boolean;
  dataTestId?: string;
}

function UploadButton({
  name,
  color,
  onUpload,
  fileType,
  document,
  icon,
  addClass,
  disabled,
  dataTestId,
}: IUploadButtonProps) {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultFile: ILinkedFile = {
    id: null,
    relation_ids: [1],
    relation_type: 'worksite_operation',
    file_type: 4,
    status: 1,
    isolate_file: false,
    file_url: null,
    file_name: null,
    linked_entity_id: null,
    commentary: null,
    file: null,
    created_at: null,
    uploaded_at: null,
    mandatory: true,
    is_deletable: null,
    file_hash: null,
  };

  const acceptFileType = useMemo(() => {
    if (fileType === 6 || fileType === 12) {
      return '.jpg,.jpeg,.png';
    }
    return '.pdf';
  }, [fileType]);

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      if (files[0].size > MAX_FILE_SIZE) {
        toast.error(
          t('global.file_too_large', { maxFileSize: MAX_FILE_SIZE_MB })
        );
        if (hiddenFileInput.current) {
          hiddenFileInput.current.value = ''; // réinitialiser la valeur de l'input
        }
        return;
      }
      setIsLoading(true);
      await onUpload(files[0], document || defaultFile);
      setIsLoading(false);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onUploadFile}
        style={{ display: 'none' }}
        accept={acceptFileType}
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-test-id={dataTestId}
      />
      {icon ? (
        <button
          type="button"
          onClick={() => !disabled && handleUploadClick(hiddenFileInput)}
          className="w-full"
        >
          {icon}
        </button>
      ) : (
        <ButtonOpx
          label={name}
          onClick={(e) => {
            e.stopPropagation();
            if (!disabled) handleUploadClick(hiddenFileInput);
          }}
          type={color === 'grey' ? 'secondary' : 'primary'}
          small
          addClass={addClass}
          isLoading={isLoading}
          disabled={disabled}
        />
      )}
    </>
  );
}

export { UploadButton };

UploadButton.defaultProps = {
  document: undefined,
  color: 'blue',
  icon: undefined,
  addClass: '',
  disabled: false,
  dataTestId: '',
};
