import { v4 } from 'uuid';

import { IBarItem } from '@models/partners/utils/types/partnersType';
import { useMemo } from 'react';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

interface IMultipleProgressBarProps {
  data: IBarItem[];
  showLegend: boolean;
  addClass?: string;
  loading?: boolean;
}

function MultipleProgressBar({
  data,
  showLegend,
  addClass,
  loading,
}: IMultipleProgressBarProps) {
  const dotStyle = (color: string) => {
    return {
      backgroundColor: color,
      borderRadius: '50%',
      width: 7,
      height: 7,
    };
  };

  const sortedData = useMemo(
    () => data.sort((a: IBarItem, b: IBarItem) => b.ratio - a.ratio),
    [data]
  ); // descending order

  return (
    <>
      <div
        className={['relative w-full h-1 ', addClass].join(' ')}
        style={{ backgroundColor: '#F4F4F4', borderRadius: 21 }}
      >
        {!loading ? (
          sortedData.map((barItem: IBarItem, iBarItem: number) => {
            const previousItems = sortedData.filter((_, i) => i < iBarItem);
            const position = // start position of current item
              previousItems.reduce((acc, item) => acc + item.ratio, 0) * 100;

            return (
              <div
                key={v4()}
                className="absolute h-full"
                style={{
                  width: `${Number(barItem.ratio || 0) * 100}%`,
                  backgroundColor: barItem.color,
                  left: `${position}%`,
                  borderTopLeftRadius: iBarItem === 0 ? 21 : 0, // first item
                  borderBottomLeftRadius: iBarItem === 0 ? 21 : 0, // first item
                  borderTopRightRadius: !data[iBarItem + 1] ? 21 : 0, // last item
                  borderBottomRightRadius: !data[iBarItem + 1] ? 21 : 0, // last item
                }}
              />
            );
          })
        ) : (
          <LoaderSkeleton height="0.5rem" />
        )}
      </div>

      {showLegend && (
        <div className="block xm:flex items-center mt-2">
          {!loading ? (
            data.map((barItem: IBarItem, iBarItem: number) => (
              <div key={v4()} className="flex items-center">
                <span
                  className={`mr-4 ${iBarItem > 0 ? 'ml-0 xm:ml-8' : ''}`}
                  style={dotStyle(barItem.color)}
                />
                <p>{barItem.label}</p>
              </div>
            ))
          ) : (
            <LoaderSkeleton height="0.75rem" addClass="w-[20rem] mt-2" />
          )}
        </div>
      )}
    </>
  );
}

MultipleProgressBar.defaultProps = {
  addClass: undefined,
  loading: false,
};

export { MultipleProgressBar };
