import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

function OperationListSkeleton() {
  return (
    <div className="rounded-default p-[1rem] mb-[0.5rem] border borderGrey">
      <div className="flex flex-nowrap">
        <div className="flex flex-wrap w-full">
          <div className="w-full md:w-[70%]">
            <div className="flex mb-3">
              <LoaderSkeleton
                height="1.5rem"
                addClass="w-[5rem] md:mr-[0.5rem]"
              />
              <LoaderSkeleton height="1.5rem" addClass="w-[5rem]" />
            </div>
            <LoaderSkeleton height="1.5rem" addClass="w-[20rem] mb-3" />
            <div className="flex text-[0.875rem] text-textGrey">
              <LoaderSkeleton height="1.5rem" addClass="w-[10rem]" />
            </div>
          </div>
          <div className="w-full md:w-[30%]">
            <div className="flex flex-col justify-center items-end">
              <LoaderSkeleton height="1.5rem" addClass="w-[5rem] mb-3" />
              <LoaderSkeleton height="1.5rem" addClass="w-[10rem]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { OperationListSkeleton };
