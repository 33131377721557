import { Modal } from '@components/Modal';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { SiretSection } from '@models/auth/components/registrationSteps/SiretSection';

interface UsersCreationProps {
  setModal: Dispatch<SetStateAction<boolean>>;
}

export function ModalUsersCreation({ setModal }: UsersCreationProps) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('admin.user_new')}
      backgroundTransparent
      sidebarVisible
      setIsModal={setModal}
    >
      <div className="mt-2 flex flex-col gap-3">
        <SiretSection formLayout />
        <div className="pt-[1.5rem] flex items-center justify-end space-x-[1rem]">
          <div>
            <ButtonOpx
              label={`${t('buttons.cancel')}`}
              type="secondary"
              onClick={() => setModal(false)}
              addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
