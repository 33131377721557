import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@components/Modal';
import { ButtonOpx } from '@components/atomic/ButtonOpx';

import { deleteCertification } from '@models/certifications/apiRequests/certificationsRequests';
import { useNavigate } from 'react-router-dom';
import { ICertificationType } from '@models/certifications/utils/certificationsTypes';
import { USERS_ROUTES } from '@utils/routesUrls';

interface IDeleteCertificationModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  certificationData: ICertificationType | undefined;
}
function DeleteCertificationModal({
  setShowModal,
  certificationData,
}: IDeleteCertificationModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDeleteCertification = async () => {
    setIsLoading(true);
    const resDelete = await deleteCertification(certificationData?.id || 0);
    setIsLoading(false);
    if (resDelete) {
      setShowModal(false);
      navigate(USERS_ROUTES.PROFILE);
    }
  };

  return (
    <Modal
      title={t('certifications.delete_modal_title')}
      setIsModal={setShowModal}
      backgroundTransparent
      lightHeader
      isLoading={isLoading}
    >
      <>
        <p>
          {t('certifications.delete_modal_text', {
            reference: certificationData?.reference || '',
          })}
        </p>
        <div className="flex items-center justify-end mt-6">
          <ButtonOpx
            onClick={() => {
              setShowModal(false);
            }}
            label={t('buttons.cancel')}
            type="secondary"
            addClass="!border-transparent"
          />
          <ButtonOpx
            onClick={handleDeleteCertification}
            label={t('buttons.confirm')}
            type="primary"
            addClass="!border-transparent"
            dataTestId="confirm-delete-certification"
          />
        </div>
      </>
    </Modal>
  );
}

export { DeleteCertificationModal };
