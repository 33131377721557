import { ENTITY_TYPES } from '@utils/roles';
import { PARTNERS_ROUTES } from '@utils/routesUrls';
import { IPartnerInfos } from './types/partnersType';
import { IPartnerContact } from './types/partnerContactType';

export const PARTNERS_MENU_MAPPING: Record<
  string,
  Record<string, string | number>
> = {
  [ENTITY_TYPES.INSTALLATEUR]: {
    title: 'sidebar.installers',
    link: PARTNERS_ROUTES.PARTNERS_INSTALLERS,
  },
  [ENTITY_TYPES.DELEGATAIRE]: {
    title: 'sidebar.delegates',
    link: PARTNERS_ROUTES.PARTNERS_DELEGATES,
    hash: 'delegates',
  },
  [ENTITY_TYPES.MANDATAIRE]: {
    title: 'sidebar.representatives',
    link: PARTNERS_ROUTES.PARTNERS_REPRESENTATIVES,
    hash: 'representatives',
  },
  [ENTITY_TYPES.OBLIGE]: {
    title: 'sidebar.obliged',
    link: PARTNERS_ROUTES.PARTNERS_OBLIGED,
    hash: 'obliged',
  },
  [ENTITY_TYPES.SOUS_TRAITANT]: {
    title: 'sidebar.subcontractors',
    link: PARTNERS_ROUTES.PARTNERS_SUBCONTRACTORS,
    hash: 'subcontractors',
  },
  [ENTITY_TYPES.AMO]: {
    title: 'sidebar.amo',
    link: PARTNERS_ROUTES.PARTNERS_AMO,
    hash: 'amo',
  },
};

export const PARTNERS_SUBMENU_MAPPING: Record<
  string,
  Record<string, string>
> = {
  Informations: {
    content: 'informations',
  },
  Conventions: {
    content: 'conventions',
  },
  Chantiers: {
    content: 'worksites',
  },
  Worksites: {
    content: 'worksites',
  },
  'Sous-traitants': {
    content: 'subcontractors',
  },
  Subcontractors: {
    content: 'subcontractors',
  },
  Contrats: {
    content: 'contracts',
  },
  Contracts: {
    content: 'contracts',
  },
  Dépôts: {
    content: 'deposits',
  },
  Deposits: {
    content: 'deposits',
  },
  Documents: {
    content: 'documents',
  },
  Certifications: {
    content: 'certifications',
  },
};

export const DEFAULT_GENERAL_INFOS: IPartnerInfos = {
  info_generales: {
    active: undefined,
    company_name: '',
    siret: '',
    siren: '',
    address: '',
    additional_address: null,
    phone_number: null,
    contact_email: null,
    logo: null,
  },
  contact: null,
  siret: [],
};

export const DEFAULT_CONTACT: IPartnerContact = {
  id: 0,
  from_entity_type: null,
  reference: null,
  start_date: null,
  end_date: null,
  volume_classique: null,
  volume_precaire: null,
  convention_status: null,
  contacts: [
    {
      id: 0,
      convention_id: null,
      contract_id: null,
      firstname: '',
      lastname: '',
      function: '',
      phone_number: '',
      entity_type: null,
      contactType: null,
      contact_user: null,
      signature_status: null,
    },
  ],
  created_at: null,
};

export const initialSubcontractorUserInfo = {
  id: 0,
  firstname: '',
  lastname: '',
  email: '',
  phone_number: '',
};
