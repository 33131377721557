import { Modal } from '@components/Modal';
import { DATE_MODAL } from '@models/cofrac/utils/cofracConstants';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { isBeforeDate } from '@utils/functions';
import { dateToDDMMYYY, toAPIDateStr } from '@utils/format';
import { AddIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { UploadButton } from '@components/documents/UploadButton';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { ILinkedFilesType } from '@models/conventions/utils/conventionTypes';
import { getCofracInfos } from '@models/cofrac/apiRequests/cofracRequests';
import { sendLinkedFile, updateLinkedFile } from '@apiRequests/globalRequests';
import { Loader } from '@components/atomic/Loader';

interface CofracSatisfactionProps {
  data: ICofracType | undefined;
  setData: Dispatch<SetStateAction<ICofracType | undefined>>;
  modal: string;
  setModal: Dispatch<SetStateAction<string>>;
  setDateOk: Dispatch<SetStateAction<{ ok: string; ko: string }>>;
}

function CofracSatisfaction({
  data,
  setData,
  modal,
  setModal,
  setDateOk,
}: CofracSatisfactionProps): JSX.Element {
  const [errors, setErrors] = useState<{
    date: boolean;
    report: boolean;
    summary: boolean;
  }>({
    date: true,
    report: true,
    summary: true,
  });
  const [files, setFiles] = useState<{
    report: ILinkedFilesType | null;
    summary: ILinkedFilesType | null;
  }>({ report: null, summary: null });

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<'summary' | 'report' | null>(null);

  const { t } = useTranslation();

  const setError = (field: 'date' | 'report' | 'summary', val: boolean) => {
    setErrors((prevState) => ({ ...prevState, [field]: val }));
  };

  const setFileData = (value: 'report' | 'summary') => {
    setFiles((prevState) => ({
      ...prevState,
      [value]: data?.linked_files ? data.linked_files?.[value] : null,
    }));
    setError(value, false);
  };

  useEffect(() => {
    if (data && data.linked_files) {
      if (data.linked_files.report) setFileData('report');
      if (data.linked_files.summary) setFileData('summary');
    }
  }, [data]);

  const onUpload = async (file: File, fileType: number) => {
    const relationType =
      fileType === 20 ? 'cofrac_summary_report_file' : 'cofrac_report_file';

    setIsLoading(fileType === 20 ? 'summary' : 'report');

    const existingFile = fileType === 20 ? files.summary : files.report;

    //
    if (existingFile) {
      await updateLinkedFile(Number(existingFile.id), file);
      setIsLoading(null);
    } else {
      const response = await sendLinkedFile(
        file,
        [Number(data?.id)],
        relationType,
        fileType,
        2,
        null,
        null,
        true
      );

      setIsLoading(null);

      if (response.data) {
        const fileValue = fileType === 20 ? 'summary' : 'report';
        setFiles((prevState) => ({
          ...prevState,
          [fileValue]: response.data,
        }));
        getCofracInfos(Number(data?.id), setData);
      }

      setError(fileType === 20 ? 'summary' : 'report', false);
    }
  };

  const onClickNext = () => {
    setIsSubmitted(true);
    if (!errors.date && !errors.summary && !errors.report) {
      setModal(`${modal}_operations`);
    }
  };

  const docUpload = (fileType: number, fileIsLoading: boolean) => {
    const isSummary = fileType === 20;

    const placeholder = !isSummary
      ? t('cofrac.form.report_placeholder')
      : t('cofrac.form.summary_placeholder');

    const relationType = fileType === 19 ? 'report' : 'summary';

    const file = files[relationType];

    const fileName = file ? file.file_name : null;

    const error = isSummary ? errors.summary : errors.report;
    const borderColor =
      isSubmitted && error ? 'border-red' : 'border-borderGrey';

    return (
      <div
        className={`flex justify-between flex-wrap items-center p-2 cursor-pointer rounded-default
          hover:bg-greyOpacity20 w-full border ${borderColor} bg-white right-0 rounded-default p-2`}
      >
        <div className="text-textGrey">{fileName || placeholder}</div>
        <div className="flex items-center gap-2">
          <div>{t('global.mandatory')}</div>
          <ColorCube
            color={blueOpx}
            numberOrIcon={fileIsLoading ? <Loader /> : <AddIcon />}
            size="2rem"
            opacity
            addClass="rounded"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={
        modal === DATE_MODAL.OK
          ? t('cofrac.modal.title_ok')
          : t('cofrac.modal.title_ko')
      }
      backgroundTransparent
      sidebarVisible
      buttonsPosition="bottom"
      onConfirmClick={onClickNext}
      btnCancel
      onClickCancel={() => setModal('')}
      textBtnConfirm={`${t('buttons.next')}`}
    >
      <>
        <DatePicker
          defaultDate={dateToDDMMYYY(data?.cofrac_date.slice(0, 10))}
          addClass="my-5 w-1/2"
          label={`${t('cofrac.form.date_placeholder')}`}
          required
          onChangeDate={(e) => {
            if (isBeforeDate(e, data ? data?.cofrac_date.slice(0, 10) : '')) {
              setError('date', true);
            } else {
              setDateOk((prev) => ({ ...prev, [modal]: toAPIDateStr(e) }));
              setError('date', false);
            }
          }}
          error={errors.date}
          textError={`${t('cofrac.form.date_ok_error')}`}
        />
        <p>{t('cofrac.form.docs')}</p>
        <div className="flex flex-col gap-3">
          <UploadButton
            name="cofrac_report"
            onUpload={(file) => onUpload(file, 19)}
            fileType={19}
            icon={docUpload(19, isLoading === 'report')}
            disabled={isLoading === 'report'}
          />
          <UploadButton
            name="cofrac_summary"
            onUpload={(file) => onUpload(file, 20)}
            fileType={20}
            icon={docUpload(20, isLoading === 'summary')}
            disabled={isLoading === 'summary'}
          />
        </div>
      </>
    </Modal>
  );
}

export { CofracSatisfaction };
