/** Exemple d'utilisation :
<SubHeader
  leftPart={
    <TitleLeftPart
      title="Liste des contrats"
      description="Gestion et organisation sont les maîtres mots de ce magnifique CRM."
    />
  }
  btnGroup={
    <>
      <ButtonOpx
        onClick={() => functionOnClick()}
        label="Trier"
        type="secondary"
        icon={<SupportContactIcon />}
      />
      <ButtonOpx
        onClick={() => functionOnClick()}
        label="Filtrer"
        type="secondary"
        icon={<SupportContactIcon />}
      />
    </>
  }
/>
* */

interface ISubHeaderProps {
  leftPart: JSX.Element;
  rightPart?: JSX.Element;
}

function SubHeader({ leftPart, rightPart }: ISubHeaderProps): JSX.Element {
  return (
    <div className="flex items-center py-[1.5rem]" data-test-id="sub_header">
      <div className="flex flex-wrap flex-grow min-w-0">{leftPart}</div>
      {rightPart && (
        <div className="flex items-center space-x-[1rem]">{rightPart}</div>
      )}
    </div>
  );
}

SubHeader.defaultProps = {
  rightPart: null,
};

export { SubHeader };
