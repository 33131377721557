/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';

import { Tag } from '@components/atomic/Tag';
import { SearchBar } from '@components/SearchBar';
import { ColorCube } from '@components/atomic/ColorCube';

import { blueOpx, red, textGrey, green } from '@assets/color';
import {
  EditIcon,
  TrashIcon,
  AddIcon,
  CrossIcon,
  CheckIcon,
} from '@assets/images/svgComponents';

import { numberWithSeparator } from '@utils/format';
import { getTagValue } from '@models/deposits/utils/depositHelper';
import { convertKiloToMega } from '@utils/functions';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { FilterRequestData } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { toast } from 'react-toastify';
import { OperationVolumes } from '@models/contracts/components/OperationVolumes';
import { IConsultListPage, IPageInList } from '../../../types/globalTypes';
import { ICardOperationType } from '../utils/contractTypes';
import { OperationListSkeleton } from './OperationListSkeleton';

interface IOperationListProps {
  model: 'cofrac' | 'deposit' | 'lot' | 'contract';
  cardTitle: string;
  buttonList: string[];
  data: ICardOperationType[];
  setSearch: (value: string) => void;
  refresh: () => void;
  isLoading: boolean;
  onValidate?: (
    operationId: number,
    volumeClassique: number,
    volumePrecaire: number
  ) => Promise<void>;
  onAdd?: (
    operationId: number,
    volumeClassique: number,
    volumePrecaire: number
  ) => Promise<void>;
  onDelete?: (operationId: number, lineId: number) => Promise<void>;
  isEdit?: boolean;
  priceLabel?: string;
  placeholderEmpty?: string;
  onSort?: (column: string, direction: string) => void;
  onFilter?: (filters: FilterRequestData[]) => void;
  isInit?: boolean;
}

function OperationList(props: IOperationListProps) {
  const {
    model,
    cardTitle,
    buttonList,
    data,
    setSearch,
    refresh,
    isLoading,
    onValidate,
    onAdd,
    onDelete,
    isEdit,
    priceLabel,
    placeholderEmpty,
    onSort,
    onFilter,
    isInit,
  } = props;
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);

  const [opToEdit, setOpToEdit] = useState<ICardOperationType>();
  const [precaireValue, setPrecaireValue] = useState<string>('');
  const [classiqueValue, setClassiqueValue] = useState<string>('');

  const conversionCoeff = model === 'deposit' ? 1000000 : 1000;

  const volumeClassique =
    Number(classiqueValue.replace(',', '.')) * conversionCoeff;

  const volumePrecaire =
    Number(precaireValue.replace(',', '.')) * conversionCoeff;

  const page = useMemo(() => {
    switch (model) {
      case 'cofrac':
        return 'COFRAC';
      case 'deposit':
        return 'DEPOTS';
      default:
        return 'CONTRATS';
    }
  }, [model]);

  const handleVolumeError = () => {
    const initialVolumePrecaire = opToEdit?.volume_precaire || 0;
    const initialVolumeClassique = opToEdit?.volume_classique || 0;

    const showError = (type: string, volume: string) => {
      toast.error(
        `Volume ${type} superieur au volume restant : ${volume} MWhc`
      );
    };

    if (
      volumePrecaire <= initialVolumePrecaire &&
      volumeClassique <= initialVolumeClassique
    ) {
      return true;
    }

    if (volumeClassique > initialVolumeClassique)
      showError('classique', convertKiloToMega(initialVolumeClassique));
    if (volumePrecaire > initialVolumeClassique)
      showError('précaire', convertKiloToMega(initialVolumePrecaire));

    return false;
  };

  useEffect(() => {
    if (!opToEdit) {
      setClassiqueValue('');
      setPrecaireValue('');
    }
  }, [opToEdit]);

  const handleEdit = async () => {
    if (opToEdit && onValidate) {
      handleVolumeError();

      if (opToEdit && handleVolumeError()) {
        onValidate(opToEdit.line_id, volumeClassique, volumePrecaire).then(() =>
          refresh()
        );
      }
    }

    setOpToEdit(undefined);
    return true;
  };

  const handleAdd = async (item: ICardOperationType) => {
    if (buttonList.includes('edit')) {
      if (!handleVolumeError()) return true;
      setOpToEdit(undefined);
    }

    if (onAdd) {
      onAdd(item.id, volumeClassique, volumePrecaire).then(() => refresh());
    }

    setOpToEdit(undefined);
    return true;
  };

  const renderActionButtons = (item: ICardOperationType) => {
    const addButton = (
      <ColorCube
        color={blueOpx}
        numberOrIcon={<AddIcon />}
        onClick={() => {
          handleAdd(item);
        }}
        size="2rem"
        opacity
        addClass="rounded"
      />
    );

    const deleteButton = (
      <ColorCube
        color={red}
        numberOrIcon={<TrashIcon />}
        onClick={() =>
          onDelete && onDelete(item.id, item.line_id).then(() => refresh())
        }
        size="2rem"
        opacity
        addClass="rounded"
      />
    );

    const cancelButton = (
      <ColorCube
        color={red}
        numberOrIcon={<CrossIcon />}
        onClick={async () => {
          setOpToEdit(undefined);
        }}
        size="2rem"
        opacity
        addClass="mb-[.5rem] rounded"
      />
    );

    const editButton = (
      <ColorCube
        color={blueOpx}
        numberOrIcon={<EditIcon />}
        onClick={() => setOpToEdit(item)}
        size="2rem"
        opacity
        addClass="mb-[.5rem] rounded"
      />
    );

    const validateButton = (
      <ColorCube
        color={green}
        numberOrIcon={<CheckIcon />}
        onClick={handleEdit}
        size="2rem"
        opacity
        addClass="mb-[.5rem] rounded"
      />
    );

    return (
      <div className="flex flex-nowrap flex-col justify-between">
        {buttonList.includes('edit') ? (
          opToEdit && opToEdit === item ? (
            <>
              {buttonList.includes('validate') && validateButton}
              {cancelButton}
            </>
          ) : (
            editButton
          )
        ) : (
          <div />
        )}
        {buttonList.includes('delete') && deleteButton}
        {buttonList.includes('add') && addButton}
      </div>
    );
  };

  const renderOperation = (item: ICardOperationType) => {
    const beneficiaryCompanyName =
      item.beneficiary && item.beneficiary.company_name
        ? item.beneficiary.company_name
        : '';

    const beneficiaryFullName = item.beneficiary
      ? `${item.beneficiary.firstname} ${item.beneficiary.lastname}`
      : '';

    const beneficiary =
      beneficiaryCompanyName !== ''
        ? beneficiaryCompanyName
        : beneficiaryFullName;

    const installer = item.installer ? item.installer.company_name : '';

    const prevStatus =
      globalEnum.cofrac_lots_operations_status[item.previous_used_status || 0];

    return (
      <div className="rounded-default p-[1rem] mb-[0.5rem] border borderGrey">
        <div className="flex flex-nowrap">
          <div
            className={`${
              !isEdit ? 'w-full' : 'w-[95%]'
            } flex flex-wrap pr-[1rem]`}
          >
            <div className="w-full md:w-[70%]">
              <div className="flex">
                <Tag
                  color={textGrey}
                  label={item.code}
                  addClass="h-fit mr-[1rem] min-w-[6rem] text-center"
                />
                {prevStatus && (
                  <Tag
                    color={getTagValue(prevStatus, t, true).tagColor}
                    label={getTagValue(prevStatus, t, true).tagText}
                    addClass="h-fit mr-[1rem]"
                  />
                )}
              </div>
              <p className="text-[0.875rem] font-medium my-[0.656rem]">
                {item.description}
              </p>
              <p className="text-[0.875rem] text-textGrey">
                {`${beneficiary} / ${installer}`}
              </p>
            </div>
            <div className="w-full md:w-[30%]">
              <div className="flex md:justify-end items-center h-[2.4rem]">
                <div className="text-[0.875rem] font-medium min-w-max">
                  <OperationVolumes
                    item={item}
                    isEditVolume={Boolean(opToEdit && opToEdit === item)}
                    isDeposit={model === 'deposit'}
                    precaireValue={precaireValue}
                    setPrecaireValue={setPrecaireValue}
                    classiqueValue={classiqueValue}
                    setClassiqueValue={setClassiqueValue}
                  />
                </div>
              </div>
              {!item.price_classique || !item.price_precaire ? (
                <p className="text-[0.875rem] text-textGrey my-[0.656rem] flex flex-wrap justify-end">
                  <span>
                    {`${priceLabel || t('contract.buying_price')}:`}&nbsp;
                  </span>
                  <span>{`${
                    item.price ? numberWithSeparator(String(item.price)) : '-'
                  } €`}</span>
                </p>
              ) : (
                <div>
                  <p className="text-[0.875rem] text-textGrey my-[0.656rem] flex flex-wrap justify-end">
                    <div>{t('contract.prime_classic')}: &nbsp;</div>
                    <div>{`${numberWithSeparator(
                      String(item.price_classique)
                    )} €`}</div>
                  </p>
                  <p className="text-[0.875rem] text-textGrey my-[0.656rem] flex flex-wrap justify-end">
                    <span>{t('contract.prime_precaire')}: &nbsp;</span>
                    <span>{`${numberWithSeparator(
                      String(item.price_precaire)
                    )} €`}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          {isEdit && (
            <div className="w-[5%] flex items-center justify-end">
              {renderActionButtons(item)}
            </div>
          )}
        </div>
      </div>
    );
  };

  const pageForSortAndFilter: IConsultListPage =
    page !== 'CONTRATS' ? `${page}_OPERATIONS` : page;

  return (
    <div>
      <div className="border border-borderGrey rounded-[.25rem] bg-white p-6">
        <div>
          <div className="flex flex-wrap justify-between">
            <p className="font-medium text-[1rem]">{cardTitle}</p>
            <p className="text-textGrey text-[0.875rem]">{`${
              data && data.length
            } ${t('contract.tab_volume_detail.operation')}${
              data && data.length > 1 ? 's' : ''
            }`}</p>
          </div>
          <div className="flex flex-wrap justify-between items-center my-[1.5rem]">
            <div className="rounded-default w-full md:w-[60%] mb-[1.5rem] md:mb-0">
              <SearchBar
                placeholder={`${t('transfer.search_placeholder')}`}
                onSearch={(value: string) => setSearch(value)}
                searchOnEveryChange
                width="100%"
              />
            </div>
            <div className="w-full md:w-[40%] flex md:justify-end">
              {isInit === false && ( //
                <SortAndFilter
                  onSort={onSort}
                  onFilter={onFilter}
                  page={
                    buttonList.includes('delete')
                      ? pageForSortAndFilter
                      : (`CREATION_${page}` as IPageInList)
                  }
                />
              )}
            </div>
          </div>
          <div className="md:h-[25rem] md:overflow-y-scroll">
            {isLoading ? (
              <OperationListSkeleton />
            ) : data && data.length > 0 ? (
              data?.map((operation: ICardOperationType) => (
                <div key={operation.id}>{renderOperation(operation)}</div>
              ))
            ) : (
              <p className="text-textGrey text-[0.875rem]">
                {placeholderEmpty || t('transfer.no_operations')}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

OperationList.defaultProps = {
  onValidate: undefined,
  onAdd: undefined,
  onDelete: undefined,
  isEdit: false,
  priceLabel: '',
  placeholderEmpty: undefined,
  onSort: undefined,
  onFilter: undefined,
  isInit: false,
};

export { OperationList };
