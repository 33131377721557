import { createContext, Dispatch, SetStateAction } from 'react';
import { IUserType } from '@models/auth/utils/types';

export interface AuthContextState {
  token: string | undefined;
  updateToken: Dispatch<SetStateAction<string | undefined>>;
  user: IUserType | null;
  updateUser: Dispatch<SetStateAction<IUserType | null>>;
  getUserDatas: () => void;
  impersonateToken: string | undefined;
  updateImpersonateToken: Dispatch<SetStateAction<string | undefined>>;
}

export const AuthContext = createContext({} as AuthContextState);
