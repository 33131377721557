import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { Dispatch, SetStateAction } from 'react';
import { storeCofrac } from '@models/cofrac/apiRequests/cofracRequests';
import { useNavigate } from 'react-router-dom';
import { convertHexToRGBA } from '@utils/functions';
import { red } from '@assets/color';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAddCofracSchema } from '@utils/validationSchemas';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';

interface AddCofracProps {
  eligibleOperations: IOperationType[];
  setModal: Dispatch<SetStateAction<boolean>>;
}

function AddCofrac({
  eligibleOperations,
  setModal,
}: AddCofracProps): JSX.Element {
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(getAddCofracSchema(t)),
  });
  const { setValue, handleSubmit, formState, clearErrors } = methods;
  const { errors, isSubmitted } = formState;
  const navigate = useNavigate();

  const onSubmit = (fieldValues: FieldValues) => {
    storeCofrac(fieldValues).then((res) => {
      if (res.data) navigate(PARTNERS_ROUTES_WITH_ID(res.data.id).COFRAC_VIEW);
    });
  };

  return (
    <div>
      <Modal
        title={t('cofrac.form.title')}
        backgroundTransparent
        sidebarVisible
        textBtnConfirm={String(t('buttons.start'))}
        btnCancel
        buttonsPosition="bottom"
        onConfirmClick={handleSubmit(onSubmit)}
        setIsModal={setModal}
      >
        <FormProvider {...methods}>
          <form
            className="mt-5 flex flex-col gap-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputText
              id="name"
              name="name"
              placeholder={`${t('cofrac.form.internal_reference')}`}
              required
            />
            <InputSelect
              placeholder={`${t('cofrac.form.operation_type')}`}
              dataArrayString={eligibleOperations.map(
                (operation) => `${operation.code} - ${operation.description}`
              )}
              onSelect={(e) => {
                clearErrors('operation_id');
                const selectedOp = eligibleOperations.find(
                  (elt) => elt.code === e.split(' - ')[0]
                );
                setValue('operation_id', selectedOp?.id || 0);
              }}
              error={Boolean(isSubmitted && errors.operation_id)}
            />
            {isSubmitted && errors.operation_id && (
              <div
                className="rounded-default p-[.5rem] text-red"
                style={{
                  backgroundColor: convertHexToRGBA(red, 0.1),
                }}
              >
                {t('forms.required_error_message')}
              </div>
            )}
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
}

export { AddCofrac };
