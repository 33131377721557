import {
  getConventionModelGeneralInfo,
  getConventionModelTabs,
} from '@models/conventions/apiRequests/conventionRequests';
import { GlobalContext } from '@context/globalContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IConventionModelType } from '@models/conventions/utils/conventionTypes';
import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';
import { lightBlue } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { useTranslation } from 'react-i18next';
import { IMenuType } from '@models/contracts/utils/contractTypes';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { SupportContactIcon } from '@assets/images/svgComponents';
import { InstallerFollowUpCard } from '@models/conventions/components/InstallerFollowUpCard';
import { getModelTabContent } from '@models/conventions/utils/conventionHelper';
import { DIMENSIONS } from '@utils/utils';
import { noPermissions } from '@models/contracts/utils/contractHelper';
import { ModalConventionModelInstallerInvitation } from '@models/conventions/components/ModalConventionModelInstallerInvitation';
import { isBefore, parse } from 'date-fns';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { CONTRACTS_ROUTES_WITH_ID } from '@utils/routesUrls';

function ConventionModelSheet() {
  const { t } = useTranslation();
  const { id: modelId } = useParams();

  const { updateTitleHeader, updateDisplayBackButtonHeader, updateTagHeader } =
    useContext(HeaderContext);
  const { user } = useContext(AuthContext);
  const { globalEnum, userView } = useContext(GlobalContext);

  const [model, setModel] = useState<IConventionModelType>();
  const [tabs, setTabs] = useState<string[]>([]);
  const [menus, setMenus] = useState<IMenuType[]>();
  const [selectedMenu, setSelectedMenu] = useState<IMenuType>();
  const [modal, setModal] = useState<boolean>(false);
  const [listUpdated, setListUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false); // Set initial value based on default permission

  const navigate = useNavigate();
  const handleListUpdate = () => {
    setListUpdated((prevState) => !prevState);
  };

  const getData = async () => {
    const conventionModelInfo = (await getConventionModelGeneralInfo(
      Number(modelId)
    )) as IConventionModelType;

    if (conventionModelInfo) {
      setModel(conventionModelInfo);
    }
    const tabsList = await getConventionModelTabs(t);

    if (tabsList) {
      const tabLabels = tabsList?.map((tab) => tab.label);
      setTabs(tabLabels || []);
      setMenus(tabsList);
      setSelectedMenu(tabsList ? tabsList[0] : undefined);
    }
  };

  const isExpired = useMemo(() => {
    if (!model) return true;

    const end_date = parse(model.end_date, 'yyyy-MM-dd', new Date());
    return isBefore(end_date, new Date());
  }, [model]);

  const contractLabel = useMemo(() => {
    return globalEnum.contract_type[-1];
  }, [model]);

  useEffect(() => {
    setIsLoading(true); // Set loading state when fetching data
    getData().then(() => setIsLoading(false)); // Set loading state to false when data is fetched
  }, [modelId]);

  useEffect(() => {
    if (model) {
      updateTitleHeader(`${model.reference} - ${contractLabel}`);
      updateTagHeader(
        <Tag
          color={lightBlue}
          label={
            globalEnum.beneficiaries_type_tags[Number(model.beneficiaries_type)]
          }
        />
      );
      updateDisplayBackButtonHeader(true);
    }
  }, [model, user, userView]);

  useEffect(() => {
    if (!isLoading && model && model.from_entity) {
      if (user && userView?.entity_id === model.from_entity.id) {
        setHasPermission(true);
      }
    }
  }, [isLoading, model, user, userView]);

  if (isLoading) {
    // Render loading indicator or placeholder while data is being fetched
    return <LoaderSkeleton height="5rem" />;
  }

  if (!hasPermission) {
    return noPermissions(t);
  }

  return (
    <div className="flex">
      <div
        className="w-full"
        style={{
          paddingRight: DIMENSIONS.requests_and_changes_width,
        }}
        data-test-id="conventionmodel"
      >
        <SubHeader
          leftPart={
            <TabsLeftPart
              titlesList={tabs}
              onClick={(title) => {
                setSelectedMenu(
                  menus?.filter((menu) => menu.label === title)[0]
                );
              }}
            />
          }
          rightPart={
            <div className="flex items-center">
              <ButtonOpx
                onClick={() => setModal(true)}
                label={t(`convention.model.installer_invitation.button`)}
                type="primary"
                small
                addClass="mr-2 px-[1rem] py-[0.625rem]"
                icon={<SupportContactIcon />}
                disabled={isExpired}
              />
              <ButtonOpx
                onClick={() =>
                  modelId &&
                  navigate(
                    CONTRACTS_ROUTES_WITH_ID(modelId).CONVENTION_MODEL_EDIT
                  )
                }
                label={t('convention.update.button')}
                type="primary"
                small
                addClass="mr-1 px-[1rem] py-[0.625rem]"
                disabled={!model?.id}
              />
            </div>
          }
        />
        {selectedMenu && Number(selectedMenu.tab_id) > 0 && model && (
          <div className="w-full flex pr-1 gap-y-4">
            {getModelTabContent(Number(selectedMenu?.tab_id), model)}
          </div>
        )}
        {modal && (
          <ModalConventionModelInstallerInvitation
            setModal={setModal}
            onUpdateList={handleListUpdate}
          />
        )}
      </div>

      <InstallerFollowUpCard listUpdated={listUpdated} />
    </div>
  );
}

export { ConventionModelSheet };
