import { grey } from '@assets/color';
import { v4 } from 'uuid';

function LoaderListDocumentCard() {
  return (
    <div className="flex flex-col space-y-[1rem]">
      {[...Array(3)].map(() => (
        <div
          key={v4()}
          className="bg-white w-full h-auto rounded-default"
          style={{ borderColor: grey, borderWidth: '1px' }}
        >
          <div className="p-[1.5rem] border-b border-borderGrey">
            <div className="skeleton-loader-anim-darker h-[2rem] w-[15rem]" />
          </div>
          <div className="p-[1.5rem] flex flex-col space-y-[1rem]">
            <div className="p-[1rem] border border-borderGrey rounded-default flex items-center justify-between">
              <div className="flex items-center space-x-[.5rem]">
                <div className="skeleton-loader-anim-darker h-[1.5rem] w-[1.5rem]" />
                <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
              </div>
              <div className="flex items-center space-x-[.5rem]">
                <div className="skeleton-loader-anim-darker h-[1.5rem] w-[1.5rem]" />
                <div className="skeleton-loader-anim-darker h-[1.5rem] w-[1.5rem]" />
              </div>
            </div>
            <div className="p-[1rem] border border-borderGrey rounded-default flex items-center justify-between">
              <div className="flex items-center space-x-[.5rem]">
                <div className="skeleton-loader-anim-darker h-[1.5rem] w-[1.5rem]" />
                <div className="skeleton-loader-anim-darker h-[1.25rem] w-[10rem]" />
              </div>
              <div className="flex items-center space-x-[.5rem]">
                <div className="skeleton-loader-anim-darker h-[1.5rem] w-[1.5rem]" />
                <div className="skeleton-loader-anim-darker h-[1.5rem] w-[1.5rem]" />
              </div>
            </div>
            <div className="p-[1rem] border border-borderGrey rounded-default flex items-center justify-between">
              <div className="skeleton-loader-anim-darker h-[1.25rem] w-[15rem]" />
              <div className="flex items-center space-x-[.5rem]">
                <div className="skeleton-loader-anim-darker h-[.75rem] w-[5rem]" />
                <div className="skeleton-loader-anim-darker h-[1.5rem] w-[1.5rem]" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export { LoaderListDocumentCard };
