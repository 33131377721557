import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@components/Modal';
import { ButtonOpx } from '@components/atomic/ButtonOpx';

import { deleteUser } from '@models/users/apiRequests/userRequests';
import { IAccountUser } from '@models/users/utils/userTypes';

interface IModalDeleteUserProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedUser: IAccountUser;
  refetch: CallableFunction;
}
function ModalDeleteUser({
  setShowModal,
  selectedUser,
  refetch,
}: IModalDeleteUserProps) {
  const { t } = useTranslation();

  const handleDeleteUser = async () => {
    const resDelete = await deleteUser(selectedUser.id);
    if (resDelete) {
      refetch();
      setShowModal(false);
    }
  };

  return (
    <Modal
      title={t('my_account.delete_modal_title')}
      setIsModal={setShowModal}
      backgroundTransparent
      lightHeader
    >
      <>
        <p>
          {String(t('my_account.delete_modal_text')).replace(
            '_',
            `${selectedUser.firstname || ''} ${
              selectedUser.lastname || ''
            }`.trim()
          )}
        </p>
        <div className="flex items-center justify-end mt-6">
          <ButtonOpx
            onClick={() => {
              setShowModal(false);
            }}
            label={t('buttons.cancel')}
            type="secondary"
            addClass="!border-transparent"
          />
          <ButtonOpx
            onClick={handleDeleteUser}
            label={t('buttons.save')}
            type="primary"
            addClass="!border-transparent"
          />
        </div>
      </>
    </Modal>
  );
}

export { ModalDeleteUser };
