import { IStatusCardDatas } from '@models/dashboard/utils/dashboardTypes';
import { IRequestTypes } from '@models/requests/types/requestTypes';

export const fakeStatusDatas: IStatusCardDatas[] = [
  {
    step: 1,
    total_operations: 120,
    total_prime_amount: 50000,
    total_mwhc_gisement: 1500,
  },
  {
    step: 2,
    total_operations: 110,
    total_prime_amount: 48000,
    total_mwhc_gisement: 1400,
  },
  {
    step: 3,
    total_operations: 100,
    total_prime_amount: 45000,
    total_mwhc_gisement: 1300,
  },
  {
    step: 4,
    total_operations: 90,
    total_prime_amount: 43000,
    total_mwhc_gisement: 1200,
  },
  {
    step: 5,
    total_operations: 80,
    total_prime_amount: 40000,
    total_mwhc_gisement: 1100,
  },
  {
    step: 6,
    total_operations: 70,
    total_prime_amount: 38000,
    total_mwhc_gisement: 1000,
  },
  {
    step: 7,
    total_operations: 60,
    total_prime_amount: 35000,
    total_mwhc_gisement: 900,
  },
  {
    step: 8,
    total_operations: 50,
    total_prime_amount: 32000,
    total_mwhc_gisement: 800,
  },
];

export const initialEmptyChartDatas = [0, 0, 0, 0, 0, 0];

export const fakeChartDatas = [
  [3, 8, 6, 8, 9, 9],
  [3, 6, 6, 4, 9, 2],
  [2, 2, 2, 3, 3, 3],
  [0, 1, 2, 4, 5, 6],
];

const fakeRequest: IRequestTypes = {
  id: 1,
  relation_type: 'worksite',
  relation_id: 1,
  type: 1,
  subject: 'Facture',
  message: null,
  nb: 1,
  tags: 3,
  status: 1,
  created_at: '2024-02-06 18:04:15',
  address: '30 rue de la Paix, Paris 75000 France',
  installer: {
    active: true,
    id: 5,
    type: null,
    siret: '12312312312312',
    company_name: 'Installateur Fictif',
    zipcode: '96000',
    city: 'La Ville',
    country: null,
    address: '1, rue du chemin',
    contact_email: null,
    phone_number: null,
    logo: '',
    website: null,
    revenue: null,
    employees_number: null,
  },
  all_external_messages: [],
};

export const fakeRequestDatas = [...Array(5)].map(() => fakeRequest);
