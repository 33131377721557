/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useMemo, Dispatch, SetStateAction } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Card } from '@components/Card';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { ColorCube } from '@components/atomic/ColorCube';
import { DepositPageHeader } from '@models/deposits/components/DepositPageHeader';

import {
  AddIcon,
  CalendarIcon,
  InfoCircleIcon,
} from '@assets/images/svgComponents';

import { blueOpx, textGrey } from '@assets/color';

import { dateToDDMMYYY } from '@utils/format';
import {
  DepositDateModalType,
  IDepositType,
} from '@models/deposits/utils/depositsTypes';
import { ModalDateDeposit } from './ModalDateDeposit';
import { ModalControl } from './ModalControl';

interface ITabDetailDepositProps {
  deposit: IDepositType;
  setDeposit: Dispatch<SetStateAction<IDepositType | undefined>>;
}

function TabDetailDeposit(props: ITabDetailDepositProps) {
  const { deposit, setDeposit } = props;
  const { t } = useTranslation();

  const [modal, setModal] = useState<DepositDateModalType>('');

  const deliveryInfos: {
    title: string;
    value: string;
    className: string;
    icon?: JSX.Element;
    colorIcon?: string;
    onClick?: () => void;
  }[] = useMemo(() => {
    const dp = deposit.deposit_date;
    const theorical = deposit.theorical_validation_date;

    const unlocked = deposit.unlocked_date;
    const relocked = deposit.relocked_date;

    const complements = deposit.complements_sending_date;

    const validation = deposit.validation_date;

    const unreceivability = deposit.unreceivability_date;

    const returnDtString = (dt: string) => (dt ? dateToDDMMYYY(dt) : '-');

    const depot_dates = [
      {
        title: t('deposits.date_deposit'),
        value: returnDtString(dp || ''),
        icon: <CalendarIcon />,
        colorIcon: '#1730BF',
        className: 'w-[49%]',
      },
      {
        title: t('deposits.deposit_date_theorical'),
        value: returnDtString(theorical || ''),
        className: 'w-[49%]',
      },
    ];

    const otherDates = [];

    if (validation || unlocked) {
      otherDates.push({
        title: t('deposits.date_unlocked'),
        value: returnDtString(unlocked || ''),
        icon: <CalendarIcon />,
        colorIcon: '#1730BF',
        className: 'w-[49%] pt-6',
      });
    }

    if (validation || relocked || unlocked) {
      otherDates.push({
        title: t('deposits.date_relocked'),
        value: returnDtString(relocked || ''),
        className: 'w-[49%] pt-6',
      });
    }

    if (validation || unreceivability) {
      otherDates.push({
        title: t('deposits.date_validation'),
        value: returnDtString(validation || ''),
        icon: <CalendarIcon />,
        colorIcon: '#1730BF',
        className: 'w-full pt-6',
      });
    }

    if (unreceivability && !validation) {
      otherDates.push({
        title: t('deposits.date_unreceivability'),
        value: returnDtString(unreceivability || ''),
        icon: <CalendarIcon />,
        colorIcon: '#1730BF',
        className: 'w-[49%] pt-6',
      });

      if (deposit.unreceivability_date === null) {
        otherDates.push({
          title: t('deposits.date_complements'),
          value: complements ? dateToDDMMYYY(complements) : t('buttons.add'),
          className: 'w-[49%] pt-6',
          onClick: complements
            ? undefined
            : () => setModal('complements_sending_date'),
        });
      }
    }

    return [...depot_dates, ...otherDates];
  }, [deposit]);

  const emmyInfos = useMemo(() => {
    return [
      {
        title: t('deposits.emmy_ref'),
        value: deposit.emmy_reference || '-',
        icon: <InfoCircleIcon />,
        colorIcon: '#1730BF',
        className: 'w-full',
      },
      {
        title: t('partners.deposits.dd_classic'),
        value: deposit.num_dd_classique || '-',
        icon: <InfoCircleIcon />,
        colorIcon: '#ffffff',
        className: 'w-[49%] pt-4',
      },
      {
        title: t('partners.deposits.dd_precarity'),
        value: deposit.num_dd_precaire || '-',
        className: 'w-[49%] pt-4',
      },
    ];
  }, [deposit]);

  const modalTriggers: { label: string; name: DepositDateModalType }[] =
    useMemo(() => {
      const unlocked = deposit.unlocked_date;
      const relocked = deposit.relocked_date;

      const validation = deposit.validation_date;
      const unreceivability = deposit.unreceivability_date;

      const result: { label: string; name: DepositDateModalType }[] = [];

      if (!validation && unlocked && !relocked) {
        result.push({
          label: t('deposits.date_relocked'),
          name: 'relocked_date',
        });
      }

      if (!validation && !unlocked) {
        result.push({
          label: t('deposits.date_unlocked'),
          name: 'unlocked_date',
        });
      }

      if (!validation) {
        result.push({
          label: t('deposits.date_validation'),
          name: 'validation_date',
        });

        result.push({
          label: t('deposits.date_partial_validation'),
          name: 'partial_validation_date',
        });
      }

      if (!validation && !unreceivability) {
        result.push({
          label: t('deposits.date_unreceivability'),
          name: 'unreceivability_date',
        });
      }

      if (deposit.is_external) return [];
      return result;
    }, [deposit]);

  return (
    <>
      <DepositPageHeader data={deposit} showRepartition={false} page="Detail" />
      <div className="flex flex-wrap">
        <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
          <Card title={`${t('deposits.title_delivrance')}`}>
            <div>
              <div className="flex flex-wrap">
                {deliveryInfos.map((item) => (
                  <InfosWithIcon
                    key={v4()}
                    loading={false}
                    infos={[
                      {
                        title: item.title,
                        subtitle: item.value,
                      },
                    ]}
                    icon={item.icon}
                    addClass={`${item.className} pb-4 border border-solid border-transparent border-b-borderGrey`}
                    colorIcon={item.colorIcon}
                    dataTestId="infos_with_icon"
                    subtitleColor={item.onClick ? textGrey : 'black'}
                    onClick={item.onClick}
                  />
                ))}
              </div>
              {deposit.status !== 3 && (
                <div className="pt-6">
                  {deposit.unreceivability_date === null &&
                    modalTriggers.map((item) => (
                      <div
                        key={v4()}
                        className="flex justify-between flex-wrap items-center p-2 cursor-pointer rounded-default hover:bg-greyOpacity20"
                        onClick={() => setModal(item.name)}
                      >
                        <p>{item.label}</p>
                        <ColorCube
                          color={blueOpx}
                          numberOrIcon={<AddIcon />}
                          onClick={() => setModal(item.name)}
                          size="2rem"
                          opacity
                          addClass="rounded"
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </Card>
        </div>
        <div className="w-full md:w-[50%] mb-[1.5rem]">
          <Card title={`${t('deposits.title_references')}`}>
            <div className="flex flex-wrap pb-4 items-center">
              {emmyInfos.map((item) => (
                <InfosWithIcon
                  key={v4()}
                  loading={false}
                  infos={[
                    {
                      title: item.title,
                      subtitle: item.value,
                    },
                  ]}
                  icon={item.icon}
                  addClass={item.className}
                  colorIcon={item.colorIcon}
                  dataTestId="infos_with_icon"
                />
              ))}
            </div>
          </Card>
        </div>
      </div>

      {/* MODALS */}

      {modal !== '' && modal !== 'partial_validation_date' && (
        <ModalDateDeposit
          deposit={deposit}
          type={modal}
          callbackConfirm={(res) => {
            if (res) {
              setModal('');
              setDeposit(res);
            }
          }}
          callbackClose={() => setModal('')}
          setDeposit={setDeposit}
        />
      )}

      {modal === 'partial_validation_date' && (
        <ModalControl
          deposit={deposit}
          callbackConfirm={(res) => {
            if (res) {
              setModal('');
              setDeposit(res);
            }
          }}
          callbackClose={() => setModal('')}
        />
      )}
    </>
  );
}

export { TabDetailDeposit };
