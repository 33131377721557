/* eslint-disable react/no-unstable-nested-components */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '@context/globalContext';
import { SubHeader } from '@components/subHeader/SubHeader';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ListTable, IColumn, IRow } from '@components/ListTable';

import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getCertificationListColumns } from '@models/certifications/utils/certificationsHelper';
import { getCertificationsList } from '@models/certifications/apiRequests/certificationsRequests';
import { ICertificationType } from '@models/certifications/utils/certificationsTypes';
import { ROLES } from '@utils/roles';
import CertificationCreationModal from '@models/certifications/components/CertificationCreationModal';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';

interface ITabCertificationsProps {
  entityIdForRequests?: number;
}
function TabCertifications({ entityIdForRequests }: ITabCertificationsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roleUser, userView, globalEnum } = useContext(GlobalContext);
  const resultsPerPage = 5;

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [displayCreationModal, setDisplayCreationModal] = useState(false);

  const getDataCertificationList = async (page: number) => {
    setIsLoading(true);

    const resList = await getCertificationsList(
      entityIdForRequests || userView?.entity_id || 0,
      page,
      resultsPerPage
    );

    if (resList) {
      setData(resList.data);
      setPaginationData(resList.meta);
      if (resList.meta?.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          { data: resList.data, meta: resList.meta },
        ]);
      }
    }

    setIsLoading(false);
  };

  const columns = useMemo(
    () => getCertificationListColumns(t, globalEnum),
    [data, roleUser]
  );
  const columnsMemo = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <LoaderSkeleton height="1.5rem" />,
          }))
        : columns,
    [isLoading, columns]
  );
  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  useEffect(() => {
    if (userView) {
      getDataCertificationList(1);
    }
  }, [userView]);

  return (
    <div className="w-full items-center justify-center">
      {(roleUser === ROLES.PRODUCTION || roleUser === ROLES.ADMIN) && (
        <SubHeader
          leftPart={<div />}
          rightPart={
            <ButtonOpx
              onClick={() => setDisplayCreationModal(true)}
              type="primary"
              label={t('certifications.new_certification')}
              dataTestId="new_certification_button"
            />
          }
        />
      )}
      <div className="w-full items-center justify-center py-5">
        <ListTable
          columns={columnsMemo as IColumn[]}
          data={data}
          callBackOnRowClick={async (e: IRow) => {
            const rowData: ICertificationType =
              e.original as ICertificationType;
            navigate(PARTNERS_ROUTES_WITH_ID(rowData.id).CERTIFICATION_VIEW);
          }}
          loading={isLoading}
          rowsPerPage={resultsPerPage}
        />
        {paginationData && Number(paginationData.last_page) > 1 && (
          <ListPagination
            getData={(page) => {
              if (page > pagesDatas.length) {
                getDataCertificationList(page);
              } else {
                pageAlreadyLoad(page);
              }
            }}
            paginationData={paginationData}
          />
        )}
      </div>
      {displayCreationModal && (
        <CertificationCreationModal
          setDisplayCreationModal={setDisplayCreationModal}
          entityIdForRequests={entityIdForRequests}
        />
      )}
    </div>
  );
}

export { TabCertifications };

TabCertifications.defaultProps = {
  entityIdForRequests: undefined,
};
