/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState, useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { format, parse } from 'date-fns';

import { GlobalContext } from '@context/globalContext';

import { ListTable, IColumn, IRow } from '@components/ListTable';
import { Tag } from '@components/atomic/Tag';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getPartnerContracts } from '@models/partners/apiRequests/partnersFormRequest';

import { IContractType } from '@models/contracts/utils/contractTypes';
import { getSumVolumeOperationsContract } from '@models/contracts/utils/contractHelper';

import { dateToDDMMYYY } from '@utils/format';
import { iconBlue, red } from '@assets/color';
import { CONTRACTS_ROUTES_WITH_PARAMS } from '@utils/routesUrls';

function TabContracts() {
  const navigate = useNavigate();
  const { entite, id } = useParams();

  const { t } = useTranslation();
  const { partnersMenu, globalEnum } = useContext(GlobalContext);

  const [data, setData] = useState<IContractType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const getData = async (pageParam: number) => {
    setLoading(true);
    const iEntityType = partnersMenu.findIndex((menu) =>
      menu.link?.includes(String(entite || ''))
    );

    const resList = await getPartnerContracts({
      page: pageParam,
      perPage: 5,
      entityId: Number(id),
      entityType:
        iEntityType !== -1 ? Number(partnersMenu[iEntityType].entityType) : 0,
    });
    if (resList) {
      const tmpData = resList.data.map((contract: IContractType) => {
        const sumVolOps = contract.contractsTransfers
          ? getSumVolumeOperationsContract(contract.contractsTransfers)
          : { classique: 0, precaire: 0 };

        return {
          ...contract,
          sum_volume_ops_classique_kwhc: sumVolOps.classique,
          sum_volume_ops_precaire_kwhc: sumVolOps.precaire,
        };
      });

      setData(tmpData);

      setPaginationData(resList.meta);

      if (resList?.current_page > pagesDatas.length) {
        setPagesDatas([...pagesDatas, { data: resList.data, meta: resList }]);
      }
    }

    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const columnHelper = createColumnHelper<IContractType>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('reference', {
        header: () => t('contract.reference_opx'),
      }),
      columnHelper.accessor('contract_type', {
        header: () => t('contract.contract_type'),
        cell: (info) => {
          const contractTypes = globalEnum.contract_type;
          return contractTypes[info.getValue()] || '';
        },
      }),
      columnHelper.accessor('start_date', {
        header: () => t('contract.contract_start_date'),
        cell: (info) => dateToDDMMYYY(info.getValue()),
      }),
      columnHelper.accessor('end_date', {
        header: () => t('contract.contract_end_date'),
        cell: (info) => dateToDDMMYYY(info.getValue()),
      }),
      columnHelper.accessor('active', {
        header: () => t('contract.status'),
        cell: (info) => (
          <Tag
            color={info.getValue() ? iconBlue : red}
            label={
              info.getValue()
                ? t('partners.in_progress')
                : t('partners.expired')
            }
          />
        ),
      }),
      columnHelper.accessor('created_at', {
        header: () => t('global.creation'),
        cell: (info) => {
          const dateString = info.getValue();
          let formattedDate = null;

          if (dateString) {
            const dateParts = dateString.split(' ')[0];
            formattedDate = parse(dateParts, 'yyyy-MM-dd', new Date());
          }

          return (
            <span>
              {formattedDate ? format(formattedDate, 'dd/MM/yyyy') : '-'}
            </span>
          );
        },
      }),
    ],
    [data, globalEnum]
  );

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <div className="w-full items-center justify-center p-5">
      <ListTable
        loading={loading}
        columns={columns as IColumn[]}
        data={data}
        callBackOnRowClick={(e: IRow) => {
          const rowData = e.original as IContractType;
          const contractTypes = globalEnum.contract_type;
          const type = contractTypes[`${rowData.contract_type}`];

          navigate(
            CONTRACTS_ROUTES_WITH_PARAMS(type.toLowerCase(), rowData.id)
              .CONTRACT_VIEW
          );
        }}
      />

      {paginationData && Number(paginationData.last_page) > 1 && (
        <ListPagination
          getData={(page) => {
            if (page > pagesDatas.length) {
              getData(page);
            } else {
              pageAlreadyLoad(page);
            }
          }}
          paginationData={paginationData}
        />
      )}
    </div>
  );
}

export { TabContracts };
