import { useContext, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { Modal } from '@components/Modal';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { SearchBar } from '@components/SearchBar';

import {
  lotGetOperationToControl,
  lotValidateControl,
} from '@models/lots/apiRequests/lotRequests';

import { numberWithSeparator } from '@utils/format';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { ControlItem } from './ControlItem';

interface IModalControlProps {
  data: any;
  callbackConfirm: (res: any) => void;
  callbackClose: () => void;
}

function ModalControl(props: IModalControlProps) {
  const { data, callbackConfirm, callbackClose } = props;
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);

  const [validationType, setValidationType] = useState<string>();
  const [list, setList] = useState([]);
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();

  const [removeList, setRemoveList] = useState<any[]>([]);

  const getData = async (search?: string) => {
    const res = await lotGetOperationToControl(
      data.id,
      search || '',
      sortAndFilterData
    );
    setList(res);
  };

  const arrValidationTypes = useMemo(() => {
    const enumContractLot: any = {
      // exclude Corrigée in the list, but Corrigée will be used on display of operations
      1: globalEnum.contract_lot_operation_status['1'],
      2: globalEnum.contract_lot_operation_status['2'],
    };

    if (enumContractLot) {
      const result = Object.keys(enumContractLot).map((item) => {
        if (item === '2') setValidationType(enumContractLot[String(item)]);
        return {
          id: Number(item),
          label: enumContractLot[String(item)],
        };
      });
      return result;
    }
    return [];
  }, [globalEnum]);

  const nbSelectedOperations = useMemo(() => {
    if (validationType === globalEnum.contract_lot_operation_status['1']) {
      return list.length;
    }
    return removeList.length;
  }, [validationType, removeList, list]);

  const volumeTotal = useMemo(() => {
    if (validationType === globalEnum.contract_lot_operation_status['1']) {
      const sum = list.reduce((accumulator, object) => {
        return (
          accumulator +
          Number((object as any).worksite_operation.kwhc_classique || 0) +
          Number((object as any).worksite_operation.kwhc_precaire || 0)
        );
      }, 0);
      return sum / 1000;
    }

    const sum = removeList.reduce((accumulator, object) => {
      return accumulator + Number((object as any).mwhc);
    }, 0);

    return sum;
  }, [validationType, removeList, list]);

  useEffect(() => {
    getData();
  }, [data]);

  useEffect(() => {
    if (sortAndFilterData) getData();
  }, [sortAndFilterData]);

  const handleConfirm = async () => {
    const tmp = [...removeList].map((item: any) => {
      return {
        id: item.id,
        commentary: item.commentary,
      };
    });

    const res = await lotValidateControl(
      data.id,
      arrValidationTypes.filter((el: any) => el.label === validationType)[0].id,
      tmp
    );

    if (res && res.id) callbackConfirm(res);
  };

  return (
    <Modal
      title={t('contractMandant.control_delivery')}
      textBtnConfirm={`${t('buttons.confirm')}`}
      onConfirmClick={handleConfirm}
      btnCancel
      setIsModal={callbackClose}
      backgroundTransparent
    >
      <div className="mt-4">
        {arrValidationTypes && arrValidationTypes.length > 0 && (
          <InputSelect
            label=""
            dataArrayString={arrValidationTypes.map((item) => item.label)}
            onSelect={(value) => setValidationType(value as string)}
            placeholder={t('contractMandant.placholder_select_validation')}
            valueInput={validationType}
            error={!validationType || validationType === ''}
            showClearButton
          />
        )}

        {validationType &&
        validationType ===
          arrValidationTypes.filter((el) => el.id === 1)[0].label ? (
          <div />
        ) : (
          <div className="rounded-default p-[1rem] mb-[0.5rem] border borderGrey my-4">
            <p className="font-medium text-[1rem]">
              {t('contractMandant.operation_invalidated')}
            </p>
            <p>{t('contractMandant.select_invalidated')}</p>

            <div className="flex flex-wrap justify-between items-center my-[1.5rem]">
              <div className="rounded-default w-full md:w-[60%] mb-[1.5rem] md:mb-0">
                <SearchBar
                  placeholder={`${t('transfer.search_placeholder')}`}
                  onSearch={(value: string) => getData(value)}
                  searchOnEveryChange
                  width="100%"
                />
              </div>
              <div className="w-full md:w-[40%] flex md:justify-end">
                <SortAndFilter
                  onSort={(column, direction) =>
                    sortList(column, direction, setSortAndFilterData)
                  }
                  onFilter={(filters) =>
                    filterList(filters, setSortAndFilterData)
                  }
                  page="CONTRATS"
                />
              </div>
            </div>

            {list.length > 0 ? (
              <div className="h-[30rem] overflow-y-scroll">
                {list.map((item) => (
                  <div
                    className="rounded-default p-[1rem] mb-[0.5rem] border borderGrey my-4"
                    key={v4()}
                  >
                    <ControlItem
                      item={item}
                      removeList={removeList}
                      setRemoveList={setRemoveList}
                      enableComments
                      identifier="worksite_operation_id"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-textGrey">
                {t('contract.association.no_items')}
              </p>
            )}
          </div>
        )}

        <div className="flex flex-wrap justify-between items-center my-4">
          <p className="font-medium text-[0.875rem]">{`${nbSelectedOperations} ${t(
            'partners.deposits.operation'
          )}${nbSelectedOperations > 1 ? 's' : ''} ${t(
            'contractMandant.selected'
          )}${nbSelectedOperations > 1 ? 's' : ''}`}</p>

          <p className="font-medium text-[0.875rem]">{`${numberWithSeparator(
            String(Math.round(volumeTotal * 100) / 100)
          )} MWhc`}</p>
        </div>
      </div>
    </Modal>
  );
}

export { ModalControl };
