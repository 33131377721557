import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';

import { borderGrey } from '@assets/color';

import { numberWithSeparator } from '@utils/format';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';
import { BriefcaseIcon, VolumeGraphIcon } from '@assets/images/svgComponents';
import { convertKiloToGiga } from '@utils/functions';
import { IDepositType } from '@models/deposits/utils/depositsTypes';

interface IDepositPageHeaderProps {
  data: IDepositType;
  page: 'Suivi' | 'Detail';
  showRepartition?: boolean;
}

function DepositPageHeader(props: IDepositPageHeaderProps) {
  const { data, showRepartition, page } = props;
  const { t } = useTranslation();

  const elements = useMemo(() => {
    if (!data) return [];

    const arrInfos = [
      {
        title:
          page === 'Suivi'
            ? t('deposits.emmy_ref')
            : t('contract.tab_volume_detail.volume_total'),
        value:
          page === 'Suivi'
            ? `${data.emmy_reference || '-'}`
            : `${convertKiloToGiga(Number(data.volume.total)) || '-'} Gwhc`,
        icon: <BriefcaseIcon />,
      },
      {
        title: t('contract.tab_volume_detail.volume_classic'),
        value: `${convertKiloToGiga(data.volume.classique) || '-'} Gwhc`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('contract.tab_volume_detail.volume_precaire'),
        value: `${convertKiloToGiga(data.volume.precaire) || '-'} Gwhc`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('partners.deposits.total_prime'),
        value: `${
          numberWithSeparator(String(data.prime_total || '')) || '-'
        } €`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('convention.tab_info_general.rai'),
        value: data.rai.company_name || '-',
        icon: <BriefcaseIcon />,
      },
    ];

    return arrInfos.map((val) => {
      const newLocal = {
        infos: [
          {
            title: val.title,
            subtitle: val.value,
          },
        ],
        icon: val.icon,
        colorIcon: '#1730BF',
      };
      return newLocal;
    });
  }, [data, page]);

  return (
    <>
      <div className="w-full flex flex-wrap justify-between">
        {elements.map((element, idx) => (
          <div
            key={v4()}
            className={`w-full pb-6 md:w-[20%] ${
              idx < 4 ? 'md:pr-6' : 'md:pr-0'
            }`}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderColor: borderGrey,
              }}
              className="border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem] min-h-[7.2rem] flex items-center"
            >
              <InfosWithIcon
                icon={element.icon || <div />}
                colorIcon={element.colorIcon}
                infos={element.infos}
              />
            </div>
          </div>
        ))}
      </div>

      {showRepartition && (
        <div
          style={{
            backgroundColor: 'white',
            borderColor: borderGrey,
          }}
          className="my-[1rem] border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem]"
        >
          <MultipleProgressBar
            addClass="!h-[0.313rem]"
            showLegend
            data={[
              {
                ratio: data.volume.classique / 50000000,
                color: '#46BDE2',
                label: `${t(
                  'partners.deposits.volume_classic'
                )} ${convertKiloToGiga(data.volume.classique)} GWhc`,
              },
              {
                ratio: data.volume.precaire / 50000000,
                color: '#916BE2',
                label: `${t(
                  'partners.deposits.volume_precarity'
                )} ${convertKiloToGiga(data.volume.precaire)} GWhc`,
              },
            ]}
          />
          <p className="text-right font-medium">
            {`${t(
              'contract.tab_volume_detail.volume_total'
            )}: ${convertKiloToGiga(data.volume.total)} GWhc / 50 GWhc`}
          </p>
        </div>
      )}
    </>
  );
}

DepositPageHeader.defaultProps = {
  showRepartition: true,
};

export { DepositPageHeader };
