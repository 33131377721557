import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { GlobalContext } from '@context/globalContext';
import { WORK_SITE_DETAILS_TABS } from '@models/worksites/utils/tab';
import { Tag } from '@components/atomic/Tag';
import { textGrey } from '@assets/color';
import { useNavigate } from 'react-router-dom';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import TagIncentiveType from '@models/worksites/components/TagIncentiveType';
import { ADMIN_ROUTES, WORKSITES_ROUTES } from '@utils/routesUrls';
import { WorksiteDetailsOperations } from './worksiteDetails/WorksiteDetailsOperations';
import { getWorksiteDetails } from '../apiRequests/worksitesRequests';
import WorksiteDetailsInfos from './worksiteDetails/WorksiteDetailsInfos';
import { WorksiteDetailsDocuments } from './worksiteDetails/WorksiteDetailsDocuments';

interface IWorsiteDetailsProps {
  adminMode?: boolean;
}

function WorksiteDetails({ adminMode }: IWorsiteDetailsProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    updateTitleHeader,
    updateDisplayBackButtonHeader,
    updateAdditionnalInfosHeader,
    updateTagHeader,
    updateRouteFrom,
  } = useContext(HeaderContext);
  const { roleUser, updateRoute, route } = useContext(GlobalContext);
  const { updateNumberPageRequestsList } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );
  const {
    worksiteId,
    updateWorksiteDetails,
    updateLeftPartSubHeader,
    tabActive,
    updateTabActive,
    worksiteDetails,
  } = useContext(WorksitesContext);
  const { refreshRequestsAndChanges } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const routeFrom = useMemo(
    (): string =>
      adminMode ? ADMIN_ROUTES.ADMIN_WORKSITES : WORKSITES_ROUTES.WORKSITES,
    [adminMode]
  );

  const getWorksiteData = async () => {
    setIsLoading(true);
    const res = await getWorksiteDetails(
      updateWorksiteDetails,
      worksiteId,
      setIsLoading,
      updateTitleHeader
    );

    // display the list of worksites
    if (!res) {
      navigate(routeFrom);
    }
    setIsLoading(false);
    updateDisplayBackButtonHeader(true);
  };

  useEffect(() => {
    updateRouteFrom(routeFrom);
    return () => updateRouteFrom('');
  }, []);

  const renderElement = useMemo(() => {
    switch (tabActive) {
      case WORK_SITE_DETAILS_TABS(t).OP:
        return (
          <WorksiteDetailsOperations
            isLoading={isLoading}
            refresh={getWorksiteData}
          />
        );
      case WORK_SITE_DETAILS_TABS(t).DOCS:
        return (
          <WorksiteDetailsDocuments
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      case WORK_SITE_DETAILS_TABS(t).DETAILS:
        return (
          <WorksiteDetailsInfos
            isLoading={isLoading}
            refresh={getWorksiteData}
          />
        );
      default:
        return <div />;
    }
  }, [tabActive, isLoading]);

  useEffect(() => {
    if (worksiteId !== '') {
      getWorksiteData();
      updateNumberPageRequestsList(1);
      refreshRequestsAndChanges();
    }
    updateRoute({
      to: route.to,
      from: routeFrom,
    });
  }, [worksiteId]);

  useEffect(() => {
    if (worksiteDetails.address)
      updateAdditionnalInfosHeader([
        `${worksiteDetails.address.address} ${worksiteDetails.address.postal_code} ${worksiteDetails.address.city}`,
      ]);

    updateTagHeader(
      <div className="flex space-x-4">
        <Tag
          label={`#${worksiteDetails.reference}`}
          color={textGrey}
          dataTestId="worksite_ref"
        />
        <TagIncentiveType incentiveType={worksiteDetails.incentive_type} />
      </div>
    );
  }, [worksiteDetails]);

  useEffect(() => {
    const tab = Object.values(WORK_SITE_DETAILS_TABS(t));

    tab.splice(0, 1);
    tab.reverse();
    updateLeftPartSubHeader(
      <div className="flex justify-between items-center">
        <TabsLeftPart
          titlesList={Object.values(WORK_SITE_DETAILS_TABS(t))}
          onClick={(title: string) => updateTabActive(title)}
          activeButton={tabActive}
        />
      </div>
    );
  }, [WORK_SITE_DETAILS_TABS, roleUser, worksiteDetails.uuid, tabActive]);

  return <div className="w-full">{renderElement}</div>;
}

WorksiteDetails.defaultProps = {
  adminMode: false,
};

export { WorksiteDetails };
