/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';

import {
  urlPostUpsertLot,
  urlGetDeposits,
  urlGetDeliveries,
  urlGetAndDeleteLot,
  urlGetLotLinkedOperations,
  urlGetLotOperationsToLink,
  urlLotLinkOperation,
  urlLotUnlinkOperation,
  urlFinalizeLot,
  urlControlLot,
  urlLotGetListOperationsToControl,
  urlLotValidateControl,
} from '@api/apiUrls';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { Dispatch, SetStateAction } from 'react';

export const upsertLot = async (data: Record<string, number | null>) => {
  try {
    const response = await axiosClient.post(urlPostUpsertLot, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getDeposits = async (contractId: number) => {
  try {
    const response = await axiosClient.get(urlGetDeposits(contractId));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getDeliveries = async (contractId: number) => {
  try {
    const response = await axiosClient.get(urlGetDeliveries(contractId));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const deleteLot = async (lotId: number) => {
  try {
    const response = await axiosClient.delete(urlGetAndDeleteLot(lotId));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getLot = async (lotId: number) => {
  try {
    const response = await axiosClient.get(urlGetAndDeleteLot(lotId));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getLotLinkedOperations = async (
  id: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  const config = {
    keyword: search !== '' ? search : null,
    sort_and_filter: sortAndFilter,
  };
  try {
    const response = await axiosClient.post(
      urlGetLotLinkedOperations(id),
      config
    );
    setIsLoading((prev) => ({ ...prev, linked: false }));
    return response.data;
  } catch (error) {
    setIsLoading((prev) => ({ ...prev, linked: false }));
    displayError(error.response);
  }
};

export const getLotOperationsToLink = async (
  id: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  const config = {
    keyword: search !== '' ? search : null,
    sort_and_filter: sortAndFilter,
  };
  try {
    const response = await axiosClient.post(
      urlGetLotOperationsToLink(id),
      config
    );
    setIsLoading((prev) => ({ ...prev, toLink: false }));
    return response.data;
  } catch (error) {
    setIsLoading((prev) => ({ ...prev, toLink: false }));
    displayError(error.response);
  }
};
export const lotLinkOperation = async (
  contractId: number,
  lotId: number,
  operationId: number
) => {
  try {
    const response = await axiosClient.post(urlLotLinkOperation, {
      contract_id: contractId,
      contract_lot_id: lotId,
      worksite_operation_id: operationId,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const lotUnLinkOperation = async (
  lotId: number,
  operationId: number
) => {
  try {
    const response = await axiosClient.post(urlLotUnlinkOperation, {
      contract_lot_id: lotId,
      worksite_operation_id: operationId,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const lotFinalize = async (
  contractId: number,
  lotId: number,
  lotReference: string
) => {
  try {
    const response = await axiosClient.post(urlFinalizeLot, {
      contract_id: contractId,
      contract_lot_id: lotId,
      reference: lotReference,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const lotControl = async (lotId: number) => {
  try {
    const response = await axiosClient.put(urlControlLot(lotId));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const lotGetOperationToControl = async (
  lotId: number,
  keyword: string,
  sortAndFilter?: ISortAndFilterType
) => {
  try {
    const response = await axiosClient.post(
      urlLotGetListOperationsToControl(lotId),
      { keyword, sort_and_filter: sortAndFilter }
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const lotValidateControl = async (
  lotId: number,
  validation_status: number,
  worksite_operation: any
) => {
  try {
    const response = await axiosClient.post(urlLotValidateControl(lotId), {
      validation_status,
      worksite_operation,
    });
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};
