import { axiosClient } from '@api/apiClient';
import {
  urlGetWorksitePerMonthDatas,
  urlGetWorksiteStepsDatas,
} from '@api/apiUrls';
import { displayError } from '@utils/format';

export const getWorksiteStepsDatas = async () => {
  try {
    const response = await axiosClient.get(urlGetWorksiteStepsDatas);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
  return null;
};

export const getWorksitePerMonthDatas = async () => {
  try {
    const response = await axiosClient.get(urlGetWorksitePerMonthDatas);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
  return null;
};
