/* eslint-disable no-nested-ternary */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';

function ButtonsHeaderWorksiteCreation() {
  const { t } = useTranslation();
  const {
    beneficiary,
    updateIsModals,
    isModals,
    worksiteDatas,
    stepActiveWorksiteCreation,
  } = useContext(WorksiteCreationContext);
  return (
    <div className="md:flex md:space-x-[1rem] md:items-center md:mt-0 mt-[1rem]">
      <div className="md:mb-0 mb-[1rem]">
        <ButtonOpx
          label={t(
            !beneficiary ? 'buttons.bind_beneficiary' : 'buttons.delete'
          )}
          type="secondary"
          addClass="w-full"
          onClick={() =>
            beneficiary
              ? updateIsModals({
                  ...isModals,
                  bindBeneficiary: false,
                  formBeforeSimulation: false,
                  deleteWorksite: true,
                  saveAndQuit: false,
                })
              : updateIsModals({
                  ...isModals,
                  bindBeneficiary: true,
                  formBeforeSimulation: false,
                })
          }
          dataTestId="button_delete"
        />
      </div>
      <div>
        <ButtonOpx
          label={t(
            !beneficiary
              ? 'buttons.quit_and_delete'
              : (beneficiary && worksiteDatas.id === 0) ||
                (worksiteDatas.status === WORKSITE_STATUS.WORKSITE_CREATION &&
                  beneficiary)
              ? 'buttons.save_and_quit'
              : 'buttons.quit'
          )}
          type={
            stepActiveWorksiteCreation ===
            StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF
              ? 'tab'
              : 'primary'
          }
          addClass="w-full"
          onClick={() =>
            updateIsModals({
              createBeneficiary: false,
              formBeforeSimulation: false,
              bindBeneficiary: false,
              saveAndQuit: true,
              deleteWorksite: false,
            })
          }
          dataTestId="button_quit"
        />
      </div>
    </div>
  );
}

export { ButtonsHeaderWorksiteCreation };
