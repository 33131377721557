import { v4 } from 'uuid';

function LoaderCarouselDocument() {
  return (
    <div className="w-full flex items-center">
      <div className="flex space-x-[1rem] items-center mx-auto">
        {[...Array(4)].map(() => (
          <div
            className="skeleton-loader-anim h-[3.5rem] w-[3.5rem] border-[1px] border-borderGrey"
            key={v4()}
          />
        ))}
      </div>
    </div>
  );
}
export { LoaderCarouselDocument };
