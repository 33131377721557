import { InputText } from '@components/atomic/inputs/InputText';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useContext } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { IVolumeType } from '@models/contractCreation/utils/contractCreationTypes';
import { GlobalContext } from '@context/globalContext';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { numberRegex } from '@utils/regex';

function SaleVolumes() {
  const {
    updateVolumeMax,
    updateSalePrices,
    updatePenalties,
    volumeMax,
    salePrices,
    penalties,
    updateOrigination,
    origination,
  } = useContext(ContractCreationContext);

  const { globalEnum } = useContext(GlobalContext);

  const row = (type: 'precaire' | 'classique') => {
    const key = type === 'classique' ? 'classic' : 'precaire';

    const updateState = (
      setState: Dispatch<SetStateAction<IVolumeType>>,
      value: string
    ) => {
      setState((prevState) => ({ ...prevState, [key]: value }));
    };

    return (
      <div className="flex gap-3 w-full">
        <InputText
          id={`volume${type}`}
          name={`volume${type}`}
          placeholder={t('global.text_placeholder')}
          label={`${t(`contract.volume_${type}_kwhc`)} (MWhc)`}
          required
          onChange={(e) => updateState(updateVolumeMax, String(e))}
          valid={Number(volumeMax[key].replace(',', '')) > 0}
          error={!numberRegex.test(volumeMax[key].replace(',', '.'))}
          value={volumeMax[key] === '0' ? '' : volumeMax[key]}
        />
        <InputText
          id={`price${type}`}
          name={`price${type}`}
          placeholder={t('global.text_placeholder')}
          label={`${t(`contract.sale_price_${type}`)}`}
          required
          onChange={(e) => updateState(updateSalePrices, String(e))}
          valid={Number(salePrices[key].replace(',', '')) > 0}
          error={!numberRegex.test(salePrices[key].replace(',', '.'))}
          value={salePrices[key] === '0' ? '' : salePrices[key]}
        />
        <InputText
          id={`penalties${type}`}
          name={`penalties${type}`}
          placeholder={t('global.text_placeholder')}
          label={`${t(`contract.tab_info_general.amount_penalty_${type}`)}`}
          required
          onChange={(e) => updateState(updatePenalties, String(e))}
          valid={Number(penalties[key].replace(',', '')) > 0}
          error={!numberRegex.test(penalties[key].replace(',', '.'))}
          value={penalties[key] === '0' ? '' : penalties[key]}
        />
      </div>
    );
  };

  const getOriginationId = (value: string) => {
    const selected = Object.entries(globalEnum.origination).find(
      (o) => o[1] === value
    );

    if (selected) return Number(selected[0]);
    return 0;
  };

  return (
    <div className="w-full">
      <InputSelect
        placeholder={t('global.choose')}
        label={`${t('contract.origination')}`}
        dataArrayString={Object.values(globalEnum.origination)}
        required
        onSelect={(e) => updateOrigination(getOriginationId(e))}
        valid={origination > 0}
        defaultSelected={globalEnum.origination[origination]}
        valueInput={globalEnum.origination[origination]}
        dataTestIdSelect="select_origination"
        dataTestIdOptions="origination"
      />

      <p className="font-medium my-[1rem]">{t('contract.volumes_cee')}</p>
      <div className="flex flex-col gap-[1rem]">
        {row('classique')} {row('precaire')}
      </div>
    </div>
  );
}

export { SaleVolumes };
