import { Modal } from '@components/Modal';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IPartnerType } from '@models/partners/utils/types/partnersType';
import { getPartnersList } from '@models/partners/apiRequests/partnersRequest';
import { ENTITY_TYPES } from '@utils/roles';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { StepsEnumBeforeSimulation } from '@models/worksiteCreation/utils/enums';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { SelectOptions } from '@components/atomic/inputs/SelectOptions';
import { useTranslation } from 'react-i18next';

interface IStepStepChoiceInstaller {
  setStepFormBeforeSimulation: Dispatch<
    SetStateAction<StepsEnumBeforeSimulation>
  >;
}

function StepChoiceInstaller({
  setStepFormBeforeSimulation,
}: IStepStepChoiceInstaller) {
  const { t } = useTranslation();
  const [data, setData] = useState<IPartnerType[]>([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null | any> | undefined
  >();

  const [loading, setLoading] = useState<boolean>(false);

  const { updatePartner, partner } = useContext(WorksiteCreationContext);

  const getData = async (pageParam?: number) => {
    const page = pageParam || 1;

    if (
      !paginationData ||
      (paginationData && page <= paginationData.last_page)
    ) {
      setLoading(true);
      const entityType = ENTITY_TYPES.INSTALLATEUR;

      const resList = await getPartnersList({
        page,
        perPage: 10,
        entityType,
      });

      if (resList) {
        setData([...data, ...resList.data]);
        setPaginationData(resList.meta);
      }
      setLoading(false);
    }
  };

  const onSelectPartner = (partnerId: number) => {
    const selectedPartner = data.find((p) => p.id_partenaire === partnerId);

    if (selectedPartner) updatePartner(selectedPartner);
  };

  useEffect(() => {
    if (data.length === 1) updatePartner(data[0]);
  }, [data]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Modal
        title={t(
          'worksite_creation.form_modal_before_simulation.choice_installer'
        )}
        withArrowBack
        onBackClick={() =>
          setStepFormBeforeSimulation(
            StepsEnumBeforeSimulation.CHOICE_OPERATION_TYPE
          )
        }
        textBtnConfirm={t('buttons.confirm') as string}
        btnConfirmDisabled={!partner?.id_partenaire}
        onConfirmClick={() =>
          partner?.id_partenaire &&
          setStepFormBeforeSimulation(StepsEnumBeforeSimulation.CHOICE_CONTRACT)
        }
      >
        <div className="mt-5">
          <InputSelect
            placeholder={t(
              'worksite_creation.form_modal_before_simulation.choice_installer'
            )}
            valueInput={
              partner?.id_partenaire
                ? `${partner.company_name} - ${partner.siret}`
                : ''
            }
            onScrollToBottom={(page) => {
              getData(page);
            }}
          >
            <SelectOptions
              onClick={(installer) => onSelectPartner(Number(installer.value))}
              options={data.map((option) => ({
                label: `${option.company_name} - ${option.siret}`,
                value: String(option.id_partenaire),
              }))}
              dataTestId="installer"
              isLoading={loading}
            />
          </InputSelect>
        </div>
      </Modal>
    </div>
  );
}

export { StepChoiceInstaller };
