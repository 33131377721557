import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ISubcontractors,
  IWorksiteOperation,
  IWorksiteSuboperation,
} from '@models/worksiteCreation/utils/types/worksitesType';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { SelectSubcontractor } from './SelectSubcontractor';

interface IOneOperationSubcontractorProps {
  operation: IWorksiteOperation | IWorksiteSuboperation;
  listSubcontractors: ISubcontractors[];
  getList: (page?: number) => void;
}

function OneOperationSubcontractor({
  operation,
  listSubcontractors,
  getList,
}: IOneOperationSubcontractorProps) {
  const { t } = useTranslation();
  const {
    updateArraySubcontractorDataPost,
    arraySubcontractorDataPost,
    readOnly,
  } = useContext(WorksiteCreationContext);
  const [haveSubcontractor, setHaveSubcontractor] = useState(false);

  useEffect(() => {
    if (operation.subcontractor) {
      setHaveSubcontractor(true);
    }
  }, [operation]);

  const yes = t('global.yes');
  const no = t('global.no');

  const onChange = (e: string) => {
    if (e === yes + operation.id) {
      setHaveSubcontractor(true);
    } else {
      setHaveSubcontractor(false);
    }

    let newArray = arraySubcontractorDataPost
      ? [...arraySubcontractorDataPost]
      : [];

    if (!haveSubcontractor) {
      newArray.push({
        id: operation.id,
        toId: null,
      });
    } else {
      newArray = newArray.filter((obj) => obj.id !== operation.id);
    }

    updateArraySubcontractorDataPost(newArray);
  };

  return (
    <div className="mt-[1.5rem]">
      <p className="font-medium">{operation.operation.description}</p>
      <div className="flex items-center space-x-[.5rem] w-full mt-[1.5rem]">
        <TextWithRadio
          label={yes}
          selectValue={yes + operation.id}
          value={yes + operation.id}
          setSelectedValue={(e) => {
            onChange(e);
          }}
          isChecked={haveSubcontractor}
          addClass="w-full"
          dataTestId="input_radio_have_subcontractor"
          disabled={readOnly}
        />
        <TextWithRadio
          label={no}
          selectValue={no + operation.id}
          value={no + operation.id}
          setSelectedValue={(e) => onChange(e)}
          isChecked={!haveSubcontractor}
          addClass="w-full"
          dataTestId="input_radio_no_subcontractor"
          disabled={readOnly}
        />
      </div>
      {haveSubcontractor && (
        <SelectSubcontractor
          listSubcontractors={listSubcontractors}
          operation={operation}
          getList={getList}
          selectedSubcontractor={operation.subcontractor}
        />
      )}
    </div>
  );
}

export { OneOperationSubcontractor };
