/* eslint-disable @typescript-eslint/no-unused-vars */
import { TFunction } from 'i18next';
import { FieldValues } from 'react-hook-form';

import { SignataryIcon } from '@assets/images/svgComponents';

import { IAccountSignatory, IUserRight } from './userTypes';
import { DEFAULT_SIGNATORY } from './userConstants';

export const formatSignatoryRow = (
  signatory: IAccountSignatory,
  isEditable: boolean,
  t: TFunction,
  index: number
) => {
  return [
    {
      name: `firstname${index + 1}`,
      title: `${t('forms.firstname.placeholder')}`,
      value: signatory.firstname || '',
      isEditable,
      icon: <SignataryIcon />,
      showIconOnEdit: true,
      required: true,
    },
    {
      name: `lastname${index + 1}`,
      title: `${t('forms.lastname.placeholder')}`,
      value: signatory.lastname || '',
      isEditable,
      required: true,
    },
    {
      name: `function${index + 1}`,
      title: `${t('convention.tab_info_general.function')}`,
      value: signatory.function,
      isEditable,
      required: true,
    },
    {
      name: `email${index + 1}`,
      title: `${t('partners.email_address')}`,
      value: signatory.email,
      isEditable,
      type: 'email',
      required: true,
    },
    {
      name: `phone_number${index + 1}`,
      title: `${t('forms.phone.placeholder')}`,
      value: signatory.phone_number,
      isEditable,
      type: 'phone',
      required: true,
    },
    {
      name: `id${index + 1}`,
      value: signatory.id,
      type: 'hidden',
      isEditable,
    },
  ];
};

export const formatSignatoryParams = (formData: FieldValues) => {
  const formattedSignatories = [...Array(3)].map((_, i) => {
    return {
      id: formData[`id${i + 1}`],
      firstname: formData[`firstname${i + 1}`],
      lastname: formData[`lastname${i + 1}`],
      function: formData[`function${i + 1}`],
      email: formData[`email${i + 1}`],
      phone_number: formData[`phone_number${i + 1}`],
    };
  });

  return formattedSignatories.filter(
    (signatory: any) =>
      signatory.firstname &&
      signatory.lastname &&
      signatory.function &&
      signatory.email &&
      signatory.phone_number
  );
};
