import { TextError } from '@components/TextError';
import {
  generateSizingNote,
  sendSizingNote,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { ILinkedFile } from 'types/globalTypes';
import { useState, useEffect, useContext } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { getNextStep } from '@models/worksiteCreation/utils/functions';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { ChoiceGenerateSizingNote } from './ChoiceGenerateSizingNote';
import { InformationHousing } from './InformationHousing';
import { InformationMaterial } from './InformationMaterial';

function StepSizingNote() {
  const methods = useForm();
  const { handleSubmit } = methods;

  const [errorText, setErrorText] = useState<string>('');
  const [choice, setChoice] = useState<string>('1');

  const {
    updatePayloadSizingNote,
    payloadSizingNote,
    worksiteDatas,
    updateIsLoading,
    updateWorksiteDatas,
    sizingNote,
    updateSizingNote,
    updateDisabledNextButton,
    workflowSidebar,
    updateStepActiveWorksiteCreation,
    stepActiveWorksiteCreation,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const onSubmit = async () => {
    const nextStep =
      getNextStep(workflowSidebar, stepActiveWorksiteCreation)?.value ||
      StepsWorksiteCreationEnum.DOCUMENTS;

    if (readOnly) {
      updateStepActiveWorksiteCreation(nextStep);
    } else {
      updateIsLoading(true);
      if (sizingNote === '') {
        await generateSizingNote(
          worksiteDatas,
          updateIsLoading,
          payloadSizingNote,
          setErrorText,
          updateWorksiteDatas
        );
      } else {
        await sendSizingNote(
          worksiteDatas.id,
          updateIsLoading,
          setErrorText,
          updateWorksiteDatas
        );
      }

      updateStepActiveWorksiteCreation(nextStep);
    }
  };

  useEffect(() => {
    updatePayloadSizingNote({
      ...payloadSizingNote,
      generate: choice === '1',
    });
  }, [choice]);

  useEffect(() => {
    const sizeDocument: ILinkedFile | undefined =
      worksiteDatas.linkedFiles?.find((file) => file.file_type === 15);

    if (
      sizeDocument !== undefined &&
      sizeDocument !== null &&
      sizeDocument.file_url
    ) {
      updateSizingNote(sizeDocument.file_url);
    }
  }, [worksiteDatas]);

  useEffect(() => {
    const btnIsDisabled =
      payloadSizingNote.generate === true &&
      Object.values(payloadSizingNote).includes(null);

    updateDisabledNextButton(btnIsDisabled);
  }, [payloadSizingNote]);

  return (
    <FormProvider {...methods}>
      <form
        action="sendSizingNote"
        id="sendSizingNote"
        onSubmit={handleSubmit(onSubmit as (data: FieldValues) => void)}
      >
        <ChoiceGenerateSizingNote setChoice={setChoice} choice={choice} />
        {choice === '1' && (
          <div>
            <InformationHousing />
            <InformationMaterial />
          </div>
        )}
        <TextError errorMessage={errorText} />
      </form>
    </FormProvider>
  );
}

export { StepSizingNote };
