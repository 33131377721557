import { SearchBar } from '@components/SearchBar';
import {
  getGraph,
  getListOperations,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { FormProvider, useForm } from 'react-hook-form';
import { convertHexToRGBA } from '@utils/functions';
import { red } from '@assets/color';
import {
  incompatibleCategoryOperations,
  onCheckOperation,
} from '@models/worksiteCreation/utils/functions';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { GlobalContext } from '@context/globalContext';
import { OperationTypeEnum } from '@utils/enums';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { OneOperationCard } from './OneOperationCard';

function ChoiceOperations() {
  const listInnerRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const methods = useForm();
  const { handleSubmit } = methods;
  const {
    conventionActive,
    listOperationSelected,
    updateListOperationSelected,
    isLoading,
    updateIsLoading,
    updateStepActiveWorksiteCreation,
    updateGraphGeneral,
    updateGraphOperation,
    updateSimulatorData,
    listOperations,
    updateListOperations,
    worksiteOperationType,
    graphOperation,
    updateLastStep,
    updateDisabledNextButton,
    simulatorData,
    lastStep,
    readOnly,
  } = useContext(WorksiteCreationContext);
  const [arrayOperationsSlug, setArrayOperationsSlug] = useState<string[]>([
    '',
  ]);

  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [numberPageList, setNumberPageList] = useState<number>(1);
  const [listIsLoaded, setListIsLoaded] = useState<boolean>(false);
  const [totalOperations, setTotalOperations] = useState<number>(1);
  const [errorGraph, setErrorGraph] = useState<string>('');

  const { globalEnum } = useContext(GlobalContext);

  const isChecked = (operation: IOperation) =>
    listOperationSelected
      ? listOperationSelected?.some(
          (selectedOperation) => selectedOperation.code === operation.code
        )
      : false;

  const infiniteScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        (scrollTop + clientHeight === scrollHeight ||
          scrollTop + clientHeight === scrollHeight - 0.5 ||
          scrollTop + clientHeight === scrollHeight + 0.5) &&
        !listIsLoaded &&
        totalOperations > listOperations.length
      ) {
        setListIsLoaded(true);

        await getListOperations(
          conventionActive.id,
          updateListOperations,
          setIsLoadingList,
          numberPageList + 1,
          setTotalOperations,
          worksiteOperationType,
          '',
          setListIsLoaded,
          listOperations
        ).then(() => setNumberPageList(numberPageList + 1));
        listInnerRef.current.scrollTop = scrollTop - 2.5;
      }
    }
  };

  const onSearchOperation = (keyWord: string) => {
    setNumberPageList(1);
    getListOperations(
      conventionActive.id,
      updateListOperations,
      setIsLoadingList,
      1,
      setTotalOperations,
      worksiteOperationType,
      keyWord
    );
  };

  const noChange = useMemo(() => {
    const graphOps = graphOperation.map((g) => g.value.toUpperCase());

    if (graphOps.length !== arrayOperationsSlug.length) return false;

    let isNoChange = true;

    arrayOperationsSlug.forEach((op) => {
      if (!graphOps.includes(op)) isNoChange = false;
    });

    return isNoChange;
  }, [arrayOperationsSlug, graphOperation]);

  const onSubmit = async () => {
    updateIsLoading(true);
    setErrorGraph('');

    if (noChange) {
      updateStepActiveWorksiteCreation(
        StepsWorksiteCreationEnum.SIMULATION_LOGEMENT
      );
    } else {
      await getGraph(
        arrayOperationsSlug,
        updateGraphGeneral,
        updateGraphOperation,
        updateSimulatorData,
        worksiteOperationType,
        simulatorData,
        updateStepActiveWorksiteCreation,
        setErrorGraph
      );
      updateLastStep(StepsWorksiteCreationEnum.SIMULATION_LOGEMENT);
    }

    updateIsLoading(false);
  };

  const handleChangeB2BData = (label: string, key: string) => {
    const selectedValue = Object.entries(globalEnum[key]).find(
      (elt) => elt[1] === label
    );

    const newValue = selectedValue ? selectedValue[0] : '';

    if (key === 'sector') {
      updateListOperationSelected((prevState) => {
        if (prevState) {
          return prevState?.filter((elt) =>
            elt.code.toLowerCase().includes(newValue.toLowerCase())
          );
        }

        return prevState;
      });
    }

    updateSimulatorData((prevState) => ({
      ...prevState,
      [`worksite.${key}`]: newValue,
    }));
  };

  // Fonction pour déterminer si une opération est incompatible
  const isOperationIncompatible = (operation: IOperation) => {
    const isIncompatible = listOperationSelected
      ? listOperationSelected.some(
          (selectedOperation) =>
            selectedOperation.incompatibilities?.includes(operation.code) &&
            selectedOperation.code !== operation.code
        )
      : false;
    const isListOperationSelectedValid =
      !!listOperationSelected && listOperationSelected.length > 0;

    return isIncompatible && isListOperationSelectedValid && !!operation;
  };

  const setOperationListOnLoad = async () => {
    const listOperationsGetted = await getListOperations(
      conventionActive.id,
      updateListOperations,
      setIsLoadingList,
      numberPageList,
      setTotalOperations,
      worksiteOperationType,
      ''
    );
    if (
      listOperationsGetted &&
      lastStep !== StepsWorksiteCreationEnum.SIMULATION_CHOICEOPERATIONS
    ) {
      const operationSlugs = graphOperation.map((op) => op.value.toUpperCase());
      const graphOps = listOperationsGetted.filter((op: any) =>
        operationSlugs.includes(op.code)
      );

      if (JSON.stringify(graphOps) !== JSON.stringify(listOperationSelected)) {
        // Réinitialise les champs selectionnées par rapport au graph
        updateListOperationSelected(graphOps);
      }
    }
  };

  const filterOperationsBySector = (sector: string) => {
    return listOperations.filter((elt) => {
      return elt.code.toLowerCase().substring(0, 3) === sector;
    });
  };

  const sectorsFiltered = () => {
    if (!globalEnum.sector) return [];

    return Object.entries(globalEnum.sector)
      .map(([key, value]) => {
        const numberOfOperations = filterOperationsBySector(
          key.toLowerCase()
        ).length;
        // Exclure les éléments avec 0 opérations ou déjà sélectionnés
        if (numberOfOperations === 0) {
          return null;
        }
        return { key, value, numberOfOperations };
      })
      .filter(
        (
          elt
        ): elt is { key: string; value: string; numberOfOperations: number } =>
          elt !== null
      )
      .map(
        ({ value, numberOfOperations, key }) =>
          `${value} (${key}) - ${numberOfOperations}`
      );
  };

  const operationsFiltered = useMemo(() => {
    if (worksiteOperationType !== OperationTypeEnum.B2B) return listOperations;

    if (!simulatorData['worksite.sector']) return listOperations;
    return filterOperationsBySector(
      simulatorData['worksite.sector'].toLowerCase()
    );
  }, [listOperations, worksiteOperationType, simulatorData]);

  useEffect(() => {
    if (conventionActive) {
      setOperationListOnLoad();
    }
  }, [conventionActive]);

  useEffect(() => {
    const arraySlug: string[] = [];
    listOperationSelected?.map((operation) => arraySlug.push(operation.code));
    setArrayOperationsSlug(arraySlug);

    const isDisabled =
      !listOperationSelected || listOperationSelected.length < 1;

    const b2bCondition =
      worksiteOperationType === OperationTypeEnum.B2B
        ? !simulatorData['worksite.sector'] /* ||
          !simulatorData['worksite.building_use'] */
        : false;

    updateDisabledNextButton(isDisabled || b2bCondition);
  }, [simulatorData, listOperationSelected]);

  const getSectorBYOperation = (code: string) => {
    return Object.entries(globalEnum.sector).find(
      (elt) => elt[0] === code.substring(0, 3)
    );
  };

  useEffect(() => {
    if (arrayOperationsSlug.length > 0 && !simulatorData['worksite.sector']) {
      const sector = getSectorBYOperation(arrayOperationsSlug[0]);

      if (sector) {
        handleChangeB2BData(sector[1], 'sector');
      }
    }
  }, [arrayOperationsSlug]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        action="sendOperations"
        className="mt-[1rem] flex flex-col flex-grow overflow-y-hidden px-4"
        id="sendChoiceOperations"
      >
        {worksiteOperationType === OperationTypeEnum.B2B && (
          <>
            <p className="font-medium text-[1.125rem]">
              {t('worksites.sectors.choice')}
            </p>
            <div className="my-[1rem] w-full gap-4 grid grid-cols-3">
              {sectorsFiltered().map((sector) => {
                return (
                  <TextWithRadio
                    label={sector}
                    value={sector}
                    setSelectedValue={(e) => {
                      handleChangeB2BData(e.split(' (')[0], 'sector');
                    }}
                    withoutName
                    isChecked={sector.includes(
                      simulatorData['worksite.sector']
                    )}
                  />
                );
              })}
            </div>
          </>
        )}

        <SearchBar
          placeholder={t('search_bar.search_operation')}
          onSearch={(keyWord) => onSearchOperation(keyWord)}
          dataTestId="search_operation"
        />

        <div className="mt-[1rem] flex flex-col flex-grow h-[30rem]">
          {isLoadingList ? (
            <div className="flex flex-col space-y-[1rem]">
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
              <LoaderSkeleton height="4.625rem" />
            </div>
          ) : (
            <div
              onScroll={() => infiniteScroll()}
              ref={listInnerRef}
              className="w-full space-y-[1rem] overflow-y-auto flex flex-col flex-grow"
            >
              {operationsFiltered.map((operation) => {
                return (
                  <OneOperationCard
                    key={`${operation.description} ${operation.code}`}
                    nameOperation={operation.description}
                    code={operation.code}
                    isChecked={isChecked(operation)}
                    handleCheck={() =>
                      onCheckOperation(
                        operation,
                        listOperationSelected,
                        listOperations,
                        updateListOperationSelected
                      )
                    }
                    addClass={isLoading || readOnly ? 'cursor-not-allowed' : ''}
                    pictureOperationUrl=""
                    disabled={isLoading || readOnly}
                    incompatible={
                      incompatibleCategoryOperations(
                        operation,
                        listOperationSelected
                      ) || isOperationIncompatible(operation)
                    }
                  />
                );
              })}
            </div>
          )}
        </div>
        {errorGraph !== '' && (
          <p
            className="text-red p-[.5rem] rounded-default mt-[1rem]"
            style={{ backgroundColor: convertHexToRGBA(red, 0.1) }}
          >
            {errorGraph}
          </p>
        )}
      </form>
    </FormProvider>
  );
}

export { ChoiceOperations };
