import { useTranslation } from 'react-i18next';

import { Modal } from '@components/Modal';

import { upsertLot } from '@models/lots/apiRequests/lotRequests';
import { convertKiloToMega } from '@utils/functions';

interface IModalSendDeliveryProps {
  data: any;
  callbackConfirm: (res: any) => void;
  callbackClose: () => void;
}
function ModalSendDelivery(props: IModalSendDeliveryProps) {
  const { data, callbackConfirm, callbackClose } = props;
  const { t } = useTranslation();

  const handleConfirmFinalize = async () => {
    const res = await upsertLot({
      contract_id: data.contract_id,
      contract_lot_id: data.contract_lot_id,
      reference: data.reference,
    });
    callbackConfirm(res);
  };

  return (
    <Modal
      title={t('contractMandant.send_delivery_title')}
      textBtnConfirm={`${t('buttons.confirm')}`}
      onConfirmClick={handleConfirmFinalize}
      btnCancel
      setIsModal={callbackClose}
      backgroundTransparent
    >
      <div className="mt-4">
        <p>{data.message}</p>
        <p className="font-medium text-[1rem] my-3">
          {t('contractMandant.summary')}
        </p>
        <p>{`${data.nb_operations} ${t(
          'contract.tab_volume_detail.operation'
        )}${data.nb_operations > 1 ? 's' : ''}`}</p>
        <p>{`${t(
          'contract.tab_volume_detail.volume_classic'
        )} : ${convertKiloToMega(data.volume_kwhc.classique)} MWhc`}</p>
        <p>{`${t(
          'contract.tab_volume_detail.volume_precaire'
        )} : ${convertKiloToMega(data.volume_kwhc.precaire)} MWhc`}</p>
      </div>
    </Modal>
  );
}

export { ModalSendDelivery };
