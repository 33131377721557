import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/Modal';
import { sendCTAToBeRecontactedNotification } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';

interface IContractNotInList {
  setContractNotInList: Dispatch<SetStateAction<boolean>>;
}

function ContractNotInList({ setContractNotInList }: IContractNotInList) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRecontacted, setIsRecontacted] = useState<boolean>(false);

  const ctaToBeRecontacted = async () => {
    setIsLoading(true);
    try {
      const sent = await sendCTAToBeRecontactedNotification(t);

      if (sent) {
        setIsRecontacted(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsRecontacted(false);
    }
  };

  return (
    <div>
      {isRecontacted ? (
        <Modal
          title={t(
            'worksite_creation.before_simulation.choice_contract.contract_not_in_list'
          )}
          textBtnCancel={`${t(
            'worksite_creation.before_simulation.choice_contract.cta_to_be_recontacted.back_to_dashboard'
          )}`}
          closeModal
        >
          <div className="mt-[1.5rem] flex flex-col space-y-[1rem]">
            <div className="font-medium">
              {t(
                'worksite_creation.before_simulation.choice_contract.cta_to_be_recontacted.email_confirmation_description'
              )}
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          withArrowBack
          onBackClick={() => {
            setContractNotInList(false);
          }}
          title={t(
            'worksite_creation.before_simulation.choice_contract.contract_not_in_list'
          )}
          textBtnConfirm={
            t(
              'worksite_creation.before_simulation.choice_contract.cta_to_be_recontacted.button'
            ) as string
          }
          addClassBtn="mt-[1.5rem]"
          onConfirmClick={() => {
            ctaToBeRecontacted();
          }}
          closeModal
          isLoading={isLoading}
        >
          <div className="mt-[1.5rem] flex flex-col space-y-[1rem]">
            <div>
              <div className="font-medium">
                {t(
                  'worksite_creation.before_simulation.choice_contract.contract_not_in_list_subtitle'
                )}
              </div>
              <p className="text-sm mt-[.5rem]">
                {t(
                  'worksite_creation.before_simulation.choice_contract.contract_not_in_list_description'
                )}
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export { ContractNotInList };
