import { Modal } from '@components/Modal';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WORKSITES_ROUTES } from '@utils/routesUrls';
import { deleteWorksite } from '../apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '../utils/worksiteCreationContext';

interface DeleteWorksiteProps {
  isDeleteSimulation?: boolean;
  setIsDeleteSimulation?: Dispatch<SetStateAction<boolean>>;
}
function DeleteWorksite({
  isDeleteSimulation,
  setIsDeleteSimulation,
}: DeleteWorksiteProps) {
  const { t } = useTranslation();
  const router = useNavigate();

  const { worksiteDatas, updateIsModals, isModals } = useContext(
    WorksiteCreationContext
  );
  const [isModal, setIsModal] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isModal) {
      updateIsModals({
        ...isModals,
        deleteWorksite: false,
        saveAndQuit: false,
      });

      if (setIsDeleteSimulation) setIsDeleteSimulation(false);
    }
  }, [isModal]);

  const onDelete = () => {
    if (worksiteDatas.id > 0) {
      deleteWorksite(worksiteDatas.id, setIsLoading, updateIsModals, router);
    } else {
      updateIsModals({ ...isModals, deleteWorksite: false });
      router(WORKSITES_ROUTES.WORKSITES);
    }
  };

  return (
    <Modal
      backgroundTransparent
      title={
        isDeleteSimulation
          ? t('worksite_creation.titles.delete_simulation')
          : t('worksite_creation.titles.delete_worksite')
      }
      btnCancel
      setIsModal={setIsModal}
      textBtnCancel={`${t('buttons.back_to_simulation')}`}
      textBtnConfirm={`${t('buttons.delete')}`}
      onConfirmClick={() => onDelete()}
      isLoading={isLoading}
    >
      <p className="mb-[1.5rem] mt-[1rem]">
        {isDeleteSimulation ? (
          t('worksite_creation.subtitles.delete_simulation')
        ) : (
          <div>
            {worksiteDatas.id !== 0
              ? t('worksite_creation.subtitles.delete_worksite')
              : t('worksite_creation.subtitles.quit_and_delete')}
          </div>
        )}
      </p>
    </Modal>
  );
}

export { DeleteWorksite };

DeleteWorksite.defaultProps = {
  isDeleteSimulation: false,
  setIsDeleteSimulation: undefined,
};
