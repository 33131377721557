import { SidebarProgressCard } from '@models/worksiteCreation/components/sidebarProgress/SidebarProgressCard';
import {
  ContractTypes,
  CreationSteps,
} from '@models/contractCreation/utils/enums';
import { StatusStepEnum } from '@models/worksiteCreation/utils/enums';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { useTranslation } from 'react-i18next';

interface ContractCreationSidebarProps {
  setSteps: Dispatch<SetStateAction<{ value: number; label: string }[]>>;
  isNewTemplate?: boolean;
}
function ContractCreationSidebar({
  setSteps,
  isNewTemplate,
}: ContractCreationSidebarProps) {
  const { t } = useTranslation();
  const { activeStep, lastStep, changeStep, contractType, steps } = useContext(
    ContractCreationContext
  );

  const {
    GENERAL,
    PARTNER,
    REFERENTS,
    BUSINESS,
    OPERATIONS,
    PAYMENT,
    VOLUMES_CEE,
    BENEFICIARY,
    AMO,
  } = CreationSteps;

  useEffect(() => {
    let newSteps = [];
    const partnerLabel =
      contractType === ContractTypes.CONVENTION
        ? 'contract.installer_infos'
        : 'contract.information_representative';

    switch (contractType) {
      case ContractTypes.VENTE:
      case ContractTypes.DELEGATION:
        newSteps = [
          { value: GENERAL, label: `${t('partners.general_infos')}` },
          { value: PARTNER, label: `${t('contract.partner_infos')}` },
          { value: REFERENTS, label: `${t('contract.referents')}` },
        ];
        break;
      case ContractTypes.TRIPARTITE:
        newSteps = [
          { value: GENERAL, label: `${t('partners.general_infos')}` },
          { value: BENEFICIARY, label: `${t('contract.beneficiary_infos')}` },
          { value: AMO, label: `${t('contract.amo_infos')}` },
          { value: REFERENTS, label: `${t('contract.referents')}` },
          { value: OPERATIONS, label: `${t('contract.price_purchase')}` },
          { value: BUSINESS, label: `${t('contract.business.title')}` },
          { value: PAYMENT, label: `${t('contract.payment_terms')}` },
        ];
        break;
      default:
        newSteps = [
          { value: GENERAL, label: `${t('partners.general_infos')}` },
          { value: VOLUMES_CEE, label: `${t('contract.volumes_cee')}` },
          { value: PARTNER, label: `${t(partnerLabel)}` },
          { value: REFERENTS, label: `${t('contract.referents')}` },
          { value: OPERATIONS, label: `${t('contract.price_purchase')}` },
          { value: BUSINESS, label: `${t('contract.business.title')}` },
          { value: PAYMENT, label: `${t('contract.payment_terms')}` },
        ];

        if (isNewTemplate) {
          newSteps = newSteps.filter((step) => step.value !== PARTNER);
        }
    }

    setSteps(newSteps);
  }, [contractType, activeStep]);

  return (
    <div className="flex flex-col gap-3">
      <SidebarProgressCard
        title={t('contract.contract_creation')}
        status={
          activeStep < CreationSteps.DOCUMENTS
            ? StatusStepEnum.IN_PROGRESS
            : StatusStepEnum.COMPLETED
        }
        steps={steps}
        nameStepActive={steps.find((s) => s.value === activeStep)?.label}
        lastStep={steps.findIndex((elt) => elt.value === lastStep)}
        onClickStep={(step) =>
          activeStep !== CreationSteps.DOCUMENTS &&
          step <= lastStep &&
          changeStep(step)
        }
      />
      <SidebarProgressCard
        title={
          isNewTemplate ? t('contract.invitation') : t('contract.documents')
        }
        status={
          activeStep === CreationSteps.DOCUMENTS ||
          activeStep === CreationSteps.INVITATION
            ? StatusStepEnum.IN_PROGRESS
            : StatusStepEnum.NOT_STARTED
        }
        steps={[]}
        document
      />
    </div>
  );
}

export { ContractCreationSidebar };

ContractCreationSidebar.defaultProps = {
  isNewTemplate: false,
};
