import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';
import { orange, purple } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { useTranslation } from 'react-i18next';

interface TagIncentiveTypeProps {
  incentiveType: number;
}

function TagIncentiveType({ incentiveType }: TagIncentiveTypeProps) {
  const { t } = useTranslation();
  return (
    <Tag
      color={incentiveType === INCENTIVE_TYPE.DIRECT ? orange : purple}
      label={
        incentiveType === INCENTIVE_TYPE.DIRECT
          ? t('worksite_creation.form_modal_before_simulation.direct_incentive')
          : t(
              'worksite_creation.form_modal_before_simulation.indirect_incentive'
            )
      }
    />
  );
}

export default TagIncentiveType;
