import { DocumentViewer } from '@components/documents/DocumentViewer';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';

function SizingNoteViewer() {
  const { t } = useTranslation();
  const { isLoading, updateIsLoading, sizingNote } = useContext(
    WorksiteCreationContext
  );

  return (
    <>
      <div className="border-b p-[1.8rem] flex items-center w-full justify-between">
        <div className="font-medium text-[1.5rem]">Visualisation</div>
        <ButtonOpx
          label={t(
            'worksite_creation.create_worksite.sizing_note.send_beneficiary'
          )}
          type="secondary"
        />
      </div>
      <div className="p-[2rem]">
        <DocumentViewer
          setIsLoading={updateIsLoading}
          isLoading={isLoading}
          isSizingNote={sizingNote}
          addClassHeight="max-h-[63vh]"
        />
      </div>
    </>
  );
}

export { SizingNoteViewer };
