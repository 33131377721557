import { blueSecondary, green, textGrey, red } from '@assets/color';
import { COFRAC_STATUS } from '@models/cofrac/utils/cofracConstants';
import { t } from 'i18next';

export const getCofracTag = (status: number | null, isWorksite = false) => {
  const text = isWorksite
    ? 'worksites.operation.cofrac_status.'
    : 'cofrac.status';

  switch (status) {
    case COFRAC_STATUS.EN_CREATION:
      return { text: t('cofrac.status.creation'), color: textGrey };
    case COFRAC_STATUS.EN_COURS:
      return { text: t('cofrac.status.in_progress'), color: blueSecondary };
    case COFRAC_STATUS.SATISFAISANT:
      return { text: t(`${text}ok`), color: green };
    case COFRAC_STATUS.INSATISFAISANT:
      return { text: t(`${text}ko`), color: red };
    default:
      return { text: '', color: '' };
  }
};
