import { blueOpx } from '@assets/color';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BeneficiariesIcon } from '@assets/images/svgComponents';
import {
  labelIncomes,
  labelOwner,
} from '@models/worksiteCreation/utils/functions';

function HouseholdSummary() {
  const { t } = useTranslation();
  const { simulatorData } = useContext(WorksiteCreationContext);

  const generalPersons = Number(simulatorData['general.persons']);
  const generalIncome = simulatorData['general.income.precarityType'];
  const generalOwner = simulatorData['general.owner'];

  const dataInfosGeneral = [
    {
      title: t('worksite_creation.simulation.persons'),
      subtitle:
        `${generalPersons.toString()} ${t('global.people')}${
          generalPersons > 1 ? 's' : ''
        }` || '',
    },
    {
      title: t('worksite_creation.simulation.income'),
      subtitle: labelIncomes(t, generalIncome),
    },
    {
      title: t('worksite_creation.simulation.type_owner'),
      subtitle: labelOwner(t, generalOwner),
    },
  ];

  return (
    <div className="mb-[1rem] border border-borderGrey rounded-default">
      <div className="border-b border-b-borderGrey">
        <p className="p-[1.5rem] text-[1.25rem] font-medium">
          {t('worksites.household')}
        </p>
      </div>
      <div className="p-[1.5rem] grid grid-cols-2 gap-4">
        {dataInfosGeneral.map((info, index) => (
          <InfosWithIcon
            key={`${info.title}-${info.subtitle}`}
            icon={index === 0 ? <BeneficiariesIcon /> : undefined}
            spaceLeft={index !== 0}
            colorIcon={blueOpx}
            infos={[info]}
          />
        ))}
      </div>
    </div>
  );
}

export { HouseholdSummary };
