import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';

import { borderGrey, lightBlue, purple } from '@assets/color';

import { numberWithSeparator } from '@utils/format';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';
import { BriefcaseIcon, VolumeGraphIcon } from '@assets/images/svgComponents';
import { convertKiloToMega } from '@utils/functions';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

interface ILotPageHeaderProps {
  data: any;
  loading: boolean;
}

function LotPageHeader(props: ILotPageHeaderProps) {
  const { data, loading } = props;
  const { t } = useTranslation();

  const volumes = useMemo(() => {
    const arrVolumes = [
      {
        title: t('contract.tab_volume_detail.volume_classic'),
        value: `${data ? convertKiloToMega(data.volume.classique) : '-'} MWhc`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('contract.tab_volume_detail.volume_precaire'),
        value: `${data ? convertKiloToMega(data.volume.precaire) : '-'} MWhc`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('partners.deposits.total_prime'),
        value: `${
          data ? numberWithSeparator(String(data.prime_total || '')) : '-'
        } €`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('convention.tab_info_general.rai'),
        value: data ? data.rai : '-',
        icon: <BriefcaseIcon />,
      },
    ];

    return arrVolumes.map((vol) => {
      return {
        infos: [
          {
            title: vol.title,
            subtitle: vol.value,
          },
        ],
        icon: vol.icon,
        colorIcon: '#1730BF',
      };
    });
  }, [data]);

  return (
    <>
      <div className="w-full flex flex-wrap justify-between">
        {volumes.map((volume, idx) => (
          <div
            key={v4()}
            className={`w-full pb-6 md:w-[25%] ${
              idx < 3 ? 'md:pr-6' : 'md:pr-0'
            }`}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderColor: borderGrey,
              }}
              className="border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem]"
            >
              <InfosWithIcon
                icon={volume.icon || <div />}
                colorIcon={volume.colorIcon}
                infos={volume.infos}
                loading={!data}
              />
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          backgroundColor: 'white',
          borderColor: borderGrey,
        }}
        className="my-[1rem] border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem]"
      >
        <MultipleProgressBar
          addClass="!h-[0.313rem]"
          showLegend
          data={[
            {
              ratio: data
                ? (Number(data.volume.classique) * 100) /
                  Number(data.volume.total) /
                  100
                : 0,
              color: lightBlue,
              label: `${t(
                'partners.deposits.volume_classic'
              )} ${convertKiloToMega(data ? data.volume.classique : '')} MWhc`,
            },
            {
              ratio: data
                ? (Number(data.volume.precaire) * 100) /
                  Number(data.volume.total) /
                  100
                : 0,
              color: purple,
              label: `${t(
                'partners.deposits.volume_precarity'
              )} ${convertKiloToMega(data ? data.volume.precaire : '')} MWhc`,
            },
          ]}
          loading={loading}
        />

        {loading ? (
          <div className="flex justify-end">
            <LoaderSkeleton
              height="0.75rem"
              addClass="mt-2 w-[10rem] text-right"
            />
          </div>
        ) : (
          <p className="text-right font-medium">
            {`${t('contract.tab_volume_detail.volume_total')}: ${
              convertKiloToMega(data ? data.volume.total : '') || '-'
            } MWhc`}
          </p>
        )}
      </div>
    </>
  );
}

export { LotPageHeader };
