import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import { ColorCube } from '@components/atomic/ColorCube';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { convertVolumeFromMega } from '@utils/functions';
import { WORKSITES_ROUTES } from '@utils/routesUrls';

interface IStatusCardProps {
  step: number;
  numberOperations: number;
  amount: number;
  gisementMwhc: number;
}

function StatusCard({
  step,
  numberOperations,
  amount,
  gisementMwhc,
}: IStatusCardProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const { worksite_operation_status } = globalEnum;
  const worksiteStatusSplit = worksite_operation_status[step].split(' : ');
  const stepWithNumber = worksiteStatusSplit[0];
  const stepLabel = worksiteStatusSplit[1];

  return (
    <Link
      className="flex flex-1 border-r last:border-r-0 border-borderGrey"
      to={`${WORKSITES_ROUTES.WORKSITES}?step=${step}`}
    >
      <div className="animate-fade-in-down h-[13rem] flex w-full p-6 flex-col justify-between">
        <div className="flex justify-between items-start self-stretch">
          <div className="flex flex-col gap-1">
            <div className="text-xs text-[#1730BF] font-normal leading-2">
              {`${stepWithNumber}`}
            </div>
            <div className="text-sm font-medium leading-2 h-[3rem] pr-1">{`${stepLabel}`}</div>
          </div>
          <div className="rotate-180">
            <ColorCube
              size="1.5rem"
              numberOrIcon={<ChevronLeftIcon />}
              color={blueOpx}
              opacity
            />
          </div>
        </div>
        <div className="flex flex-col items-end gap-1 self-stretch">
          <div className="text-xs text-[#A5A5A5] font-normal leading-8 mb-2">{`${numberOperations} ${t(
            'auth.registration.subscription_overview.operation'
          )}${numberOperations > 1 ? 's' : ''}`}</div>
          <div className="text-[1.25rem] font-medium leading-6">{`${amount.toLocaleString(
            'fr-FR'
          )} €`}</div>
          <div className="text-[1.25rem] font-medium leading-6">
            {convertVolumeFromMega(gisementMwhc)}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default StatusCard;
