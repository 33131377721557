import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface ICardFormProps {
  title: string;
  idForm: string;
  children: JSX.Element;
  methods: UseFormReturn;
  onSubmit?: (formData: FieldValues) => Promise<void>;
  subtitle?: string | boolean;
  addClass?: string;
}

function CardForm({
  title,
  idForm,
  subtitle,
  children,
  addClass,
  onSubmit,
  methods,
}: ICardFormProps) {
  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmit as (data: FieldValues) => void)}
        action={idForm}
        className={['bg-white', addClass].join()}
        data-test-id="card_form"
      >
        <p className="pt-[1.5rem] font-medium text-[1.125rem]"> {title} </p>
        {subtitle && <p className="text-[.875rem] pt-[.5rem]">{subtitle}</p>}
        {children}
      </form>
    </FormProvider>
  );
}

export { CardForm };

CardForm.defaultProps = {
  subtitle: undefined,
  addClass: '',
  onSubmit: undefined,
};
