import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import {
  LogoMinimalistOpxIcon,
  LogoOpxIcon,
  ChevronLeftIcon,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { textGrey } from '@assets/color';
import { Link } from 'react-router-dom';
import { DIMENSIONS } from '@utils/utils';
import { DASHBOARD_ROUTES } from '@utils/routesUrls';

function HeaderSidebar() {
  const { sidebarMenuIsOpen, updateSidebarMenuIsOpen } =
    useContext(GlobalContext);

  return (
    <div className="border-b border-b-[#EEEEEE10] m-auto">
      {sidebarMenuIsOpen ? (
        <div
          className="flex justify-between items-center px-[1.5rem]"
          style={{ height: DIMENSIONS.header_height }}
        >
          <Link to={DASHBOARD_ROUTES.DASHBOARD}>
            <LogoOpxIcon width="6.625rem" height="2rem" />
          </Link>
          <ColorCube
            numberOrIcon={<ChevronLeftIcon />}
            size="1.5rem"
            color={textGrey}
            opacity
            onClick={() => updateSidebarMenuIsOpen(!sidebarMenuIsOpen)}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center py-[1.5rem]">
          <Link to={DASHBOARD_ROUTES.DASHBOARD}>
            <LogoMinimalistOpxIcon width="2rem" height="2rem" />
          </Link>
          <ColorCube
            numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
            size="1.5rem"
            color={textGrey}
            opacity
            onClick={() => updateSidebarMenuIsOpen(!sidebarMenuIsOpen)}
            addClass="mt-[1.5rem]"
          />
        </div>
      )}
    </div>
  );
}

export { HeaderSidebar };
