import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '@context/authContext';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { updateEntityInfos } from '@models/entities/apiRequests/entitiesRequests';
import { getUserInfo } from '@models/users/apiRequests/userRequests';
import { IAccountInfos } from '@models/users/utils/userTypes';
import { DEFAULT_ACCOUNT_INFOS } from '@models/users/utils/userConstants';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { yupResolver } from '@hookform/resolvers/yup';
import { getIbanSchema } from '@utils/validationSchemas';
import { useTranslation } from 'react-i18next';
import { Card } from '@components/Card';
import { toast } from 'react-toastify';

function PartnersPaymentInfo() {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  const [infos, setInfos] = useState<IAccountInfos>(DEFAULT_ACCOUNT_INFOS);
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm({
    resolver: yupResolver(getIbanSchema(t)),
  });
  const { handleSubmit } = methods;

  const onSubmit = async (formData: FieldValues) => {
    setLoading(true);
    const res = await updateEntityInfos(infos.informations_entite.id, formData);
    if (res) {
      toast.success(t('forms.iban.success'));
    }
    setLoading(false);
  };

  const getInfos = async () => {
    const resInfos = await getUserInfo(user?.id || 0);
    if (resInfos) {
      setInfos(resInfos);
    }
  };

  useEffect(() => {
    if (user) {
      getInfos();
    }
  }, [user]);

  return (
    <div className="w-full flex flex-col pt-6">
      <Card
        title={t('partners.iban')}
        subtitle={t('forms.iban.subtitle').toString()}
      >
        <FormProvider {...methods}>
          <form id="form-iban" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <InputText
                id="iban"
                name="iban"
                placeholder={t('forms.iban.placeholder')}
                required
                defaultValue={infos.informations_entite.iban_payment || ''}
                data-test-id="iban_input"
              />
            </div>
            <div className="mb-[1.5rem] mt-3">
              <ButtonOpx
                label={t('buttons.save')}
                isLoading={loading}
                disabled={loading}
                formId="form-iban"
                isSubmitBtn
                dataTestId="iban_button"
                addClass="w-full"
              />
            </div>
          </form>
        </FormProvider>
      </Card>
    </div>
  );
}

export { PartnersPaymentInfo };
