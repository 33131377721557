/* eslint-disable react/no-unstable-nested-components */
import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import React, { useEffect, useMemo, useState } from 'react';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { getListCustomFields } from '@models/settings/apiRequests/settingsRequests';
import { ICustomField } from '@models/settings/utils/settingsTypes';
import { Tag } from '@components/atomic/Tag';
import { green, orange } from '@assets/color';
import { renderCustomFieldTypeLabel } from '@models/settings/utils/utils';
import ModalAddOrEditCustomField from '@models/settings/components/ModalAddOrEditCustomField';
import { upperFirst } from 'lodash';
import OneCustomField from '@models/settings/components/OneCustomField';

function CustomFieldsTab() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [customFieldDetail, setCustomFieldDetail] = useState<ICustomField>();

  const columnHelper = createColumnHelper<ICustomField>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('internal_name', {
        header: () => t('settings.field_name'),
      }),
      columnHelper.accessor(
        (row) => {
          // Combinaison des références des contrats et des conventions en une seule chaîne
          const contractsReferences = row.contracts
            .map((contract) => contract.reference)
            .join(', ');
          const conventionsReferences = row.conventions
            .map((convention) => convention.reference)
            .join(', ');

          // Tu peux ajuster la manière de combiner ces chaînes selon tes besoins
          return `${contractsReferences} ${conventionsReferences}`;
        },
        {
          id: 'combinedContractsAndConventions', // Tu dois fournir un ID unique pour cette colonne
          header: () => t('settings.associated_contracts'),
          cell: (info) => info.getValue(), // getValue() retournera la valeur calculée par l'accessor
        }
      ),
      columnHelper.accessor('type', {
        header: () => t('settings.field_type'),
        cell: (info) => renderCustomFieldTypeLabel(info.row.original.type, t),
      }),
      columnHelper.accessor('is_public', {
        header: () => t('settings.visibility'),
        cell: (info) => {
          const label = info.row.original.is_public
            ? t('settings.all')
            : t('settings.internal');
          const tagColor = info.row.original.is_public ? green : orange;

          return <Tag color={tagColor} label={label} />;
        },
      }),
      columnHelper.accessor('is_required', {
        header: () => t('settings.mandatory'),
        cell: (info) => (
          <p>
            {upperFirst(
              info.row.original.is_required
                ? (t('global.yes') as string)
                : (t('global.no') as string)
            )}
          </p>
        ),
      }),
    ],
    [data]
  );

  const getData = async () => {
    await getListCustomFields(setData, setIsLoading);
  };

  useEffect(() => {
    getData();
  }, []);
  if (customFieldDetail) {
    return (
      <OneCustomField
        customFieldDetail={customFieldDetail}
        setCustomFieldDetail={setCustomFieldDetail}
        refreshlist={getData}
      />
    );
  }
  return (
    <>
      <Card
        title={t('settings.custom_fields')}
        actionButtons={
          <ButtonOpx
            onClick={() => setModalIsOpen(true)}
            label={t('settings.add_custom_field')}
            small
          />
        }
        addClass="w-full"
      >
        <ListTable
          loading={isLoading}
          columns={columns as IColumn[]}
          callBackOnRowClick={(e: IRow) => {
            const rowData = e.original as ICustomField;
            setCustomFieldDetail(rowData);
          }}
          data={data}
          addClass="!border-transparent"
        />
      </Card>
      {modalIsOpen && (
        <ModalAddOrEditCustomField
          setIsOpen={setModalIsOpen}
          refreshlist={getData}
        />
      )}
    </>
  );
}

export default CustomFieldsTab;
