import { FormAddress } from '@components/formAddress/FormAddress';
import { InputText } from '@components/atomic/inputs/InputText';
import { t } from 'i18next';
import { alphabeticRegex, numberRegex } from '@utils/regex';
import { ColorCube } from '@components/atomic/ColorCube';
import { AddIcon, TrashIcon } from '@assets/images/svgComponents';
import { blueOpx, red } from '@assets/color';
import { useContext, useMemo, useState } from 'react';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { initialWorksite } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';

interface OneAddressProps {
  action: 'add' | 'delete';
  index: number;
}

function OneAddress({ action, index }: OneAddressProps) {
  const { worksiteAddresses, updateWorksiteAddresses } = useContext(
    ContractCreationContext
  );

  const [address, setAddress] = useState<IBeneficiaryAddress>(
    initialWorksite.beneficiary.address
  );

  const [manualAddress, setManualAddress] = useState<boolean>(false);

  const btnDisabled = () => {
    if (action === 'delete') return false;
    return (
      !address ||
      address.city === '' ||
      address.address === '' ||
      address.postal_code === ''
    );
  };

  const handleOnClick = () => {
    if (!btnDisabled()) {
      if (action === 'add') {
        setAddress(initialWorksite.beneficiary.address);
        updateWorksiteAddresses((prevState) => [address, ...prevState]);
      } else {
        updateWorksiteAddresses((prevState) =>
          prevState.filter((a, idx) => idx !== index)
        );
      }
    }
  };

  const handleOnChange = (
    key: 'address' | 'city' | 'postal_code',
    value: string
  ) => {
    if (action === 'add') {
      setAddress((prevState) => ({ ...prevState, [key]: value }));
    } else {
      updateWorksiteAddresses((prevState) =>
        prevState.map((a, i) => (i === index ? { ...a, [key]: value } : a))
      );
    }
  };

  const inputValue = (key: 'address' | 'city' | 'postal_code') =>
    action === 'delete' ? worksiteAddresses[index][key] : address[key];

  const current = worksiteAddresses[index];

  const defaultAddress = useMemo(() => {
    if (current && action === 'delete')
      return `${current.address}, ${current.postal_code} ${current.city}`;
    return '';
  }, [current, address]);

  return (
    <div className="flex w-full gap-3">
      {!manualAddress && action === 'add' ? (
        <div className="w-full">
          <FormAddress
            setIsManual={setManualAddress}
            isManual={manualAddress}
            setAddressDatas={setAddress}
            addressDatas={address}
            validAddress={
              address.city !== '' &&
              address.postal_code !== '' &&
              address.address !== ''
            }
            valueInitialAddress={defaultAddress}
          />
        </div>
      ) : (
        <div className="flex gap-3 items-end w-full">
          <InputText
            id={`${action}Address${index}`}
            name={`${action}Address${index}`}
            placeholder={t('global.text_placeholder')}
            required={action === 'add'}
            label={`${t('contract.worksite_address')}`}
            onChange={(e) => handleOnChange('address', String(e))}
            value={inputValue('address')}
            addClass="w-full"
          />
          <InputText
            id={`${action}Zipcode${index}`}
            name={`${action}Zipcode${index}`}
            placeholder={t('global.text_placeholder')}
            label={`${t('forms.address.manual.zip')}`}
            required={action === 'add'}
            onChange={(e) =>
              (numberRegex.test(String(e)) || e === '') &&
              handleOnChange('postal_code', String(e))
            }
            value={inputValue('postal_code')}
            error={
              inputValue('postal_code').length !== 5 &&
              inputValue('postal_code') !== ''
            }
            addClass="w-full"
          />
          <InputText
            id={`${action}City${index}`}
            name={`${action}City${index}`}
            placeholder={t('global.text_placeholder')}
            label={`${t('forms.address.manual.city')}`}
            required={action === 'add'}
            onChange={(e) =>
              (alphabeticRegex.test(String(e)) || e === '') &&
              handleOnChange('city', String(e))
            }
            value={inputValue('city')}
            addClass="w-full"
          />
        </div>
      )}

      <ColorCube
        size="2.5rem"
        numberOrIcon={action === 'add' ? <AddIcon /> : <TrashIcon />}
        color={action === 'add' ? blueOpx : red}
        opacity={action === 'delete'}
        addClass={action === 'add' ? 'mt-1' : 'mt-[1.375rem]'}
        onClick={handleOnClick}
        dataTestId={`${action}_worksite_address`}
      />
    </div>
  );
}

export { OneAddress };
