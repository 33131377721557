import { URL_TYPES } from '@models/contracts/utils/contractConstants';

export const SUPPORT_URL = 'https://support.opx.co/';

// URL POUR LA SECTION D'AUTHENTIFICATION
export const AUTH_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_FROM_MODEL: '/register/from-model',
  INTERNAL_REGISTRATION: '/register/internal',
  REGISTRATION_ALMOST_DONE: '/register/almost-done',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SUBCONTRACTOR_OBJECTION: '/subcontractor-objection',
  PROTECTED_REDIRECT: '*',
};

// URL POUR LA SECTION DE DASHBOARD
export const DASHBOARD_ROUTES = {
  DASHBOARD: '/',
  DASHBOARD_DEV: '/dashboard-dev',
};

// URL POUR LA SECTION D'ADMINISTRATION
export const ADMIN_ROUTES = {
  ADMIN: '/admin',
  ADMIN_USERS: '/admin/users',
  ADMIN_WORKSITES: '/admin/worksites',
  ADMIN_WORKSITES_NEW: '/admin/worksites/new',
  ADMIN_CONTRACTS: '/admin/contracts',
};

export const ADMIN_ROUTES_WITH_ID = (id: string | number) => {
  return {
    ADMIN_USER_VIEW: `${ADMIN_ROUTES.ADMIN_USERS}/${id}`,
    ADMIN_WORKSITE_VIEW: `${ADMIN_ROUTES.ADMIN_WORKSITES}/${id}`,
    ADMIN_WORKSITE_NEW_ID: `${ADMIN_ROUTES.ADMIN_WORKSITES_NEW}/${id}`,
  };
};

// URL POUR LA SECTION DES PARTENAIRES
export const PARTNERS_ROUTES = {
  PARTNERS: '/partners',
  INSTALLERS: '/installers',
  CERTIFICATIONS: '/certifications',
  REQUESTS: '/requests',
  DESIGN: '/design',
  COFRAC: '/cofrac',
  PARTNERS_INSTALLERS: '/partners/installateurs',
  PARTNERS_DELEGATES: '/partners/delegataires',
  PARTNERS_REPRESENTATIVES: '/partners/mandataires',
  PARTNERS_OBLIGED: '/partners/obliges',
  PARTNERS_SUBCONTRACTORS: '/partners/sous-traitants',
  PARTNERS_AMO: '/partners/amo',
};

export const PARTNERS_ROUTES_WITH_ID = (id: string | number) => {
  return {
    PARTNER_VIEW: `${PARTNERS_ROUTES.PARTNERS}/${id}`,
    INSTALLER_VIEW: `${PARTNERS_ROUTES.INSTALLERS}/${id}`,
    CERTIFICATION_VIEW: `${PARTNERS_ROUTES.CERTIFICATIONS}/${id}`,
    COFRAC_VIEW: `${PARTNERS_ROUTES.COFRAC}/${id}`,
    PARTNER_SUBCONTRACTOR_VIEW: `${PARTNERS_ROUTES.PARTNERS}/${id}`,
  };
};

export const SUBCONTRACTOR_ROUTES_WITH_PARAMS = (
  installerId: number | string,
  id: number | string
) => {
  return {
    INSTALLER_SUBCONTRACTOR_VIEW: `${PARTNERS_ROUTES.PARTNERS}/${installerId}/${id}`,
  };
};

export const DOWNLOAD_ARCHIVE_ROUTE = '/download-archive';

export const DOWNLOAD_ARCHIVE_ROUTES_WITH_PARAMS = (
  worksiteId: string | number,
  fileName: string
) => `${DOWNLOAD_ARCHIVE_ROUTE}/${worksiteId}/${fileName}`;

// URL POUR LA SECTION DES UTILISATEURS
export const USERS_ROUTES = {
  PROFILE: '/profile',
};

// URL POUR LA SECTION DES CONTRATS
export const CONTRACTS_ROUTES = {
  CONTRACTS: '/contracts',
  CONVENTION: '/convention',
  CONTRACTS_CREATION: '/contracts/new',
  CONTRACTS_EDIT: '/contracts/edit',
  CONVENTION_MODEL: '/convention/model',
};

export const CONTRACTS_ROUTES_WITH_PARAMS = (
  type: string,
  id: string | number
) => {
  return {
    CONTRACT_VIEW: `${CONTRACTS_ROUTES.CONTRACTS}/${type}/${id}`,
    CONVENTION_VIEW: `${CONTRACTS_ROUTES.CONVENTION}/${type}/${id}`,
  };
};

export const CONTRACTS_ROUTES_WITH_ID = (id: string | number) => {
  return {
    CONTRACTS_EDIT: `${CONTRACTS_ROUTES.CONTRACTS_EDIT}/${id}`,
    CONTRACT_SALE_VIEW: CONTRACTS_ROUTES_WITH_PARAMS(URL_TYPES.SALE, id)
      .CONTRACT_VIEW,
    CONTRACT_REPRESENTATIVE_DEPOSITOR_VIEW: CONTRACTS_ROUTES_WITH_PARAMS(
      URL_TYPES.MANDANT,
      id
    ).CONTRACT_VIEW,
    CONTRACT_REPRESENTATIVE_NON_DEPOSITOR_VIEW: CONTRACTS_ROUTES_WITH_PARAMS(
      URL_TYPES.MANDANT_NON_DEPOSANT,
      id
    ).CONTRACT_VIEW,
    CONVENTION_MODEL: `${CONTRACTS_ROUTES.CONVENTION_MODEL}/${id}`,
    CONVENTION_INSTALLER_VIEW: CONTRACTS_ROUTES_WITH_PARAMS('installateur', id)
      .CONVENTION_VIEW,
    CONVENTION_BENEFICIARY_VIEW: CONTRACTS_ROUTES_WITH_PARAMS(
      'beneficiaire',
      id
    ).CONVENTION_VIEW,
    CONVENTION_MODEL_EDIT: `${CONTRACTS_ROUTES.CONVENTION_MODEL}/edit/${id}`,
  };
};

// URL POUR LA SECTION DES BENEFICIAIRES
export const BENEFICIARIES_ROUTES = {
  BENEFICIARIES: '/beneficiaries',
  BENEFICIARY: '/beneficiary',
};

export const BENEFICIARIES_ROUTES_WITH_ID = (id: number | string) => {
  return {
    BENEFICIARY_VIEW: `${BENEFICIARIES_ROUTES.BENEFICIARIES}/${id}`,
    BENEFICIARY_WORKSITE_VIEW: `${BENEFICIARIES_ROUTES.BENEFICIARY}/worksite/${id}`,
  };
};

// URL POUR LA SECTION DES DEPOTS
export const DEPOSITS_ROUTES = {
  DEPOSITS: '/deposits',
};

export const DEPOSITS_ROUTES_WITH_ID = (id: string | number) => {
  return {
    TRANSFER_VIEW: `/transfer/${id}`,
    LOT_VIEW: `/lot/${id}`,
    DEPOSIT_VIEW: `${DEPOSITS_ROUTES.DEPOSITS}/${id}`,
  };
};

// URL POUR LA SECTION DES CHANTIERS
export const WORKSITES_ROUTES = {
  WORKSITES: '/worksites',
  WORKSITES_NEW: '/worksites/new',
};

export const WORKSITES_ROUTES_WITH_ID = (id: string | number) => {
  return {
    WORKSITES_NEW_ID: `${WORKSITES_ROUTES.WORKSITES_NEW}/${id}`,
    WORKSITES_VIEW: `${WORKSITES_ROUTES.WORKSITES}/${id}`,
  };
};

// URL POUR LES PARAMETRES
export const SETTINGS_ROUTES = {
  SETTINGS: '/settings',
  SETTINGS_BRANDS: '/settings/brands',
};

export const SETTINGS_ROUTES_WITH_ID = (id: string | number) => {
  return {
    SETTINGS_BRANDS_VIEW: `${SETTINGS_ROUTES.SETTINGS_BRANDS}/${id}`,
  };
};
