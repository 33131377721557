import React, { Dispatch, SetStateAction, useContext } from 'react';
import {
  INCENTIVE_TYPE,
  StepsEnumBeforeSimulation,
} from '@models/worksiteCreation/utils/enums';
import { Modal } from '@components/Modal';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface IStepChoiceIncentiveType {
  setStepFormBeforeSimulation: Dispatch<
    SetStateAction<StepsEnumBeforeSimulation>
  >;
}

function StepChoiceIncentiveType({
  setStepFormBeforeSimulation,
}: IStepChoiceIncentiveType) {
  const { t } = useTranslation();
  const { updateWorksiteDatas } = useContext(WorksiteCreationContext);

  const incentiveChoices = [
    {
      title: t(
        'worksite_creation.form_modal_before_simulation.direct_incentive'
      ),
      description: t(
        'worksite_creation.form_modal_before_simulation.direct_incentive_description'
      ),
      incentiveType: INCENTIVE_TYPE.DIRECT,
    },
    {
      title: t(
        'worksite_creation.form_modal_before_simulation.indirect_incentive'
      ),
      description: t(
        'worksite_creation.form_modal_before_simulation.indirect_incentive_description'
      ),
      incentiveType: INCENTIVE_TYPE.INDIRECT,
    },
  ];

  const handleIncentiveSelection = (incentiveType: number) => {
    updateWorksiteDatas((prevState) => ({
      ...prevState,
      incentive_type: incentiveType,
    }));
    setStepFormBeforeSimulation(StepsEnumBeforeSimulation.BIND_BENEFICIARY);
  };

  return (
    <Modal
      title={t(
        'worksite_creation.form_modal_before_simulation.title_incentive_type'
      )}
      withArrowBack
      onBackClick={() =>
        setStepFormBeforeSimulation(StepsEnumBeforeSimulation.CHOICE_CONTRACT)
      }
    >
      <div className="flex flex-col space-y-[1rem] mt-[1.5rem]">
        {incentiveChoices.map((choice) => (
          <button
            key={`incentive_choice_${choice.incentiveType}`}
            onClick={() => handleIncentiveSelection(choice.incentiveType)}
            type="button"
            className="w-full bg-white border text-start border-borderGrey rounded-default p-[1.5rem] flex items-start justify-between hover:bg-backgroundBody hover:border-backgroundBody"
            data-test-id={`incentive-choice-${choice.incentiveType}`}
          >
            <div className="w-11/12">
              <p className="text-[1rem] font-medium">{choice.title}</p>
              <p className="text-[.875rem] mt-[.625rem]">
                {choice.description}
              </p>
            </div>
            <ColorCube
              opacity
              size="1.5rem"
              color={blueOpx}
              addClass="w-1/12"
              numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
            />
          </button>
        ))}
      </div>
    </Modal>
  );
}

export { StepChoiceIncentiveType };
