import { useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  getCofracInfos,
  getCofracLinkedOperations,
  getCofracOperationsToLink,
  linkCofracOperation,
  unlinkCofracOperation,
} from '@models/cofrac/apiRequests/cofracRequests';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { ICardOperationType } from '@models/contracts/utils/contractTypes';
import { OperationList } from '@models/contracts/components/OperationList';
import { useTranslation } from 'react-i18next';
import { CofracPageHeader } from '@models/cofrac/components/CofracPageHeader';
import { HeaderContext } from '@context/headerContext';
import { GlobalContext } from '@context/globalContext';
import { Tag } from '@components/atomic/Tag';
import { blueSecondary, green, red, textGrey } from '@assets/color';
import { CofracDate } from '@models/cofrac/components/CofracDate';
import { ConsultList } from '@models/lots/components/ConsultList';
import { CofracDetails } from '@models/cofrac/components/CofracDetails';
import {
  COFRAC_STATUS,
  DATE_MODAL,
} from '@models/cofrac/utils/cofracConstants';
import { CofracSatisfaction } from '@models/cofrac/components/CofracSatisfaction';
import { AddCofracOperations } from '@models/cofrac/components/AddCofracOperations';
import { LoaderDeposit } from '@components/loaders/LoaderDeposit';
import { IWorksiteOperation } from '@models/worksites/utils/worksitesTypes';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';

function CofracSheet(): JSX.Element {
  const [cofrac, setCofrac] = useState<ICofracType>();
  const [isLoading, setIsLoading] = useState({
    linked: false,
    toLink: false,
  });

  const [loadingCofrac, setLoadingCofrac] = useState<boolean>(false);

  const { t } = useTranslation();

  const [dataLinked, setDataLinked] = useState<ICardOperationType[]>([]);
  const [dataToLink, setDataToLink] = useState<ICardOperationType[]>([]);
  const [operationList, setOperationList] = useState<
    { id: number; worksite_operation: IWorksiteOperation }[]
  >([]);
  const [sortAndFilterLinked, setSortAndFilterLinked] =
    useState<ISortAndFilterType>();
  const [sortAndFilterToLink, setSortAndFilterToLink] =
    useState<ISortAndFilterType>();
  const [isInit, setIsInit] = useState(true);

  const [searchLinked, setSearchLinked] = useState<string | null>(null);
  const [searchToLink, setSearchToLink] = useState<string | null>(null);

  const [modal, setModal] = useState<string>('');

  const [tab, setTab] = useState<string>(`${t('cofrac.tab_suivi')}`);

  const [dateOk, setDateOk] = useState<{ ok: string; ko: string }>({
    ok: '',
    ko: '',
  });

  const {
    updateTitleHeader,
    updateTagHeader,
    updateDisplayBackButtonHeader,
    refreshHeader,
  } = useContext(HeaderContext);
  const { globalEnum } = useContext(GlobalContext);

  const { id } = useParams();

  const getData = async (
    sLinked?: string,
    sToLink?: string,
    refreshLinked?: boolean,
    refreshToLink?: boolean,
    loadCofrac = true
  ) => {
    setIsInit(false);
    setIsLoading({
      linked: refreshLinked || false,
      toLink: refreshToLink || false,
    });

    const fetchBoth = sLinked === undefined && sToLink === undefined;
    const fetchLinked = fetchBoth || sLinked !== undefined;
    const fetchToLink = fetchBoth || sToLink !== undefined;

    if (loadCofrac) await getCofracInfos(Number(id), setCofrac);

    if (refreshLinked || sLinked) {
      const resLinked = fetchLinked
        ? await getCofracLinkedOperations(
            Number(id),
            sLinked !== undefined ? sLinked : searchLinked,
            setIsLoading,
            sortAndFilterLinked
          )
        : await new Promise((myResolve) => {
            myResolve(dataLinked);
          });

      if (resLinked.data) setOperationList(resLinked.data);

      let res1 = [];
      if (!fetchLinked) {
        res1 = resLinked;
      } else if (resLinked) {
        res1 = resLinked.data.map((item: any) => {
          return {
            id: item.worksite_operation_id,
            code: item.worksite_operation.operation.code,
            description: item.worksite_operation.operation.description,
            precarity: item.worksite_operation.worksite.precarity,
            volume_classique: item.worksite_operation.kwhc_classique,
            volume_precaire: item.worksite_operation.kwhc_precaire,
            price: item.worksite_operation.cee_amount,
            price_precaire: item.worksite_operation.cee_precaire,
            price_classique: item.worksite_operation.cee_classique,
            beneficiary: item.worksite_operation.worksite.beneficiary,
            installer: item.worksite_operation.worksite.installer,
            line_id: item.id,
          };
        });
      }

      setDataLinked(res1);
    }

    if (!cofrac?.cofrac_date && (refreshToLink || sToLink)) {
      const resToLink = fetchToLink
        ? await getCofracOperationsToLink(
            Number(id),
            sToLink !== undefined ? sToLink : searchToLink,
            setIsLoading,
            sortAndFilterToLink
          )
        : await new Promise((myResolve) => {
            myResolve(dataToLink);
          });

      let res2 = [];
      if (!fetchToLink) {
        res2 = resToLink;
      } else if (resToLink) {
        res2 = resToLink.data.map((item: any) => {
          return {
            id: item.id,
            code: item.operation.code,
            description: item.operation.description,
            precarity: item.worksite.precarity,
            volume_classique: item.volume_classique_restant,
            volume_precaire: item.volume_precaire_restant,
            price: item.cee_amount,
            price_precaire: item.cee_precaire,
            price_classique: item.cee_classique,
            beneficiary: item.worksite.beneficiary,
            installer: item.worksite.installer,
            previous_used_status: item.previous_used_status,
          };
        });
      }

      setDataToLink(res2);
    }
  };

  useEffect(() => {
    getData(undefined, undefined, true, true);
    setLoadingCofrac(true);
  }, [id]);

  useEffect(() => {
    if (cofrac) {
      setLoadingCofrac(false);
      updateTitleHeader(cofrac.name);
      updateDisplayBackButtonHeader(true);
      const tagText = globalEnum.cofrac_lots_status[cofrac.status];
      let tagColor = textGrey;
      if (cofrac.status === COFRAC_STATUS.EN_CREATION) tagColor = blueSecondary;
      if (cofrac.status === COFRAC_STATUS.SATISFAISANT) tagColor = green;
      if (cofrac.status === COFRAC_STATUS.INSATISFAISANT) tagColor = red;

      const tag = (
        <div className="flex gap-5">
          <Tag label={tagText} color={tagColor} />
          <div>{cofrac.operation_type.description} </div>
          <Tag label={cofrac.operation_type.code} color={textGrey} />
        </div>
      );

      updateTagHeader(tag);
      setOperationList(cofrac.cofrac_lots_operations);
    } else {
      refreshHeader();
    }
  }, [cofrac]);

  useEffect(() => {
    if (sortAndFilterLinked)
      getData(undefined, undefined, true, undefined, false);
  }, [sortAndFilterLinked]);

  useEffect(() => {
    if (sortAndFilterToLink)
      getData(undefined, undefined, undefined, true, false);
  }, [sortAndFilterToLink]);

  const selectedModal = useMemo(() => {
    switch (modal) {
      case DATE_MODAL.KO:
      case DATE_MODAL.OK:
        return (
          <CofracSatisfaction
            modal={modal}
            setModal={setModal}
            data={cofrac}
            setData={setCofrac}
            setDateOk={setDateOk}
          />
        );
      case DATE_MODAL.OK_OPERATIONS:
      case DATE_MODAL.KO_OPERATIONS:
        return (
          <AddCofracOperations
            data={cofrac}
            setData={setCofrac}
            modal={modal}
            setModal={setModal}
            dateOk={dateOk}
          />
        );
      default:
        return (
          <CofracDate
            setModal={setModal}
            cofrac={cofrac}
            setCofrac={setCofrac}
          />
        );
    }
  }, [modal]);

  if (loadingCofrac)
    return (
      <div className="relative top-[14%]">
        <LoaderDeposit />;
      </div>
    );

  return (
    <div>
      <CofracPageHeader
        data={cofrac}
        setModal={setModal}
        setTab={setTab}
        tab={tab}
        loading={isLoading.linked && isLoading.toLink}
        exportCofracButtonEnabled={operationList?.length === 0}
      />
      {tab !== t('cofrac.tab_suivi') && cofrac?.cofrac_date && (
        <CofracDetails data={cofrac} setModal={setModal} />
      )}
      {tab === t('cofrac.tab_suivi') && cofrac?.cofrac_date && (
        <ConsultList
          data={operationList}
          model="cofrac"
          sortList={(c, d) => sortList(c, d, setSortAndFilterLinked)}
          onFilter={(filters) => filterList(filters, setSortAndFilterLinked)}
          loading={isLoading.linked}
        />
      )}
      {tab === t('cofrac.tab_suivi') && !cofrac?.cofrac_date && (
        <div className="w-full flex flex-wrap">
          <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
            <OperationList
              cardTitle={t('cofrac.section_to_link')}
              buttonList={['add']}
              onAdd={async (operationId) => {
                if (cofrac?.id)
                  await linkCofracOperation(cofrac.id, operationId);
              }}
              data={dataToLink}
              setSearch={(val: string) => {
                setSearchToLink(val);
                getData(undefined, val);
              }}
              refresh={() => getData(undefined, undefined, true, true, true)}
              isEdit
              priceLabel={`${t('contractMandant.amount_prime')}`}
              isLoading={isLoading.toLink}
              model="cofrac"
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterToLink)
              }
              onFilter={(filters) =>
                filterList(filters, setSortAndFilterToLink)
              }
              isInit={isInit}
            />
          </div>
          <div className="w-full md:w-[50%] mb-[1.5rem]">
            <OperationList
              cardTitle={t('cofrac.section_linked')}
              buttonList={['delete']}
              onDelete={async (_, lineId) => {
                await unlinkCofracOperation(lineId);
              }}
              data={dataLinked}
              setSearch={(val: string) => {
                setSearchLinked(val);
                getData(val, undefined);
              }}
              refresh={() => getData(undefined, undefined, true, true, true)}
              isEdit
              priceLabel={`${t('contractMandant.amount_prime')}`}
              placeholderEmpty={`${t('deposits.to_begin_deposit')}`}
              isLoading={isLoading.linked}
              model="cofrac"
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterLinked)
              }
              onFilter={(filters) =>
                filterList(filters, setSortAndFilterLinked)
              }
              isInit={isInit}
            />
          </div>
        </div>
      )}
      {modal !== '' && selectedModal}
    </div>
  );
}

export { CofracSheet };
