import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';

import {
  urlDownloadInstallerInvitationTemplate,
  urlPostSendInvitationLink,
} from '@api/apiUrls';
import { UploadXLSX } from '@components/atomic/UploadXLSX';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { axiosClient } from '@api/apiClient';
import { toast } from 'react-toastify';
import { IPartnersInvitedEmail } from '@models/partners/utils/types/partnersType';
import { sendValidatedFile } from '@models/partners/apiRequests/partnersRequest';

interface InstallerInvitationProps {
  setModal: Dispatch<SetStateAction<boolean>>;
}

function ModalInstallerInvitation({
  setModal,
}: InstallerInvitationProps): JSX.Element {
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState<File>();
  const [fileValid, setFileValid] = useState(false);
  const [sentOK, setSentOK] = useState(false);
  const [partnersEmail, setPartnersEmail] = useState<IPartnersInvitedEmail[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const onUpload = (file: File) => {
    setFileValid(false);
    setUploadFile(file);
  };

  const loadValidButton = () => {
    setFileValid(false);
  };

  const onClickValidateFile = async () => {
    if (uploadFile) {
      setLoading(true);
      const resValidatedFile = await sendValidatedFile(uploadFile);
      if (resValidatedFile) {
        setFileValid(true);
        setPartnersEmail(resValidatedFile);
      }
      setLoading(false);
    }
  };

  const onClickSendInvitation = async () => {
    setLoading(true);
    try {
      await axiosClient.post(urlPostSendInvitationLink, partnersEmail);
      setSentOK(true);
    } catch (error) {
      toast.error(error.response.data.errors);
    }
    setLoading(false);
  };

  return (
    <Modal
      title={
        sentOK
          ? t('partners.installer_invitation.modal.header_title_success')
          : t('partners.installer_invitation.modal.header_title_upload')
      }
      backgroundTransparent
      sidebarVisible
      setIsModal={setModal}
    >
      {sentOK ? (
        <div className="flex flex-col pt-[0.5rem]">
          <p>{t('partners.installer_invitation.modal.header_text_success')}</p>
          <div className="pt-[1.5rem] flex items-center justify-end space-x-[1rem]">
            <div>
              <ButtonOpx
                label={t('global.btn_close')}
                type="primary"
                onClick={() => setModal(false)}
                addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-2 flex flex-col gap-3">
          <p className="mt-0 mb-3">
            {t('partners.installer_invitation.modal.header_text_upload')}
          </p>
          <a
            href={`${process.env.PUBLIC_URL}${urlDownloadInstallerInvitationTemplate}`}
            target="_blank"
            rel="noreferrer"
            download
            className="mb-3 font-[600] cursor-pointer"
            style={{ color: 'blue' }}
          >
            {t('partners.installer_invitation.modal.link_download_template')}
          </a>
          <span className="font-300">
            {t('partners.installer_invitation.modal.input_file_title')}
          </span>

          {/* Input d'upload du document */}
          <UploadXLSX
            onUpload={onUpload}
            isUploadFile={!uploadFile}
            fileName={
              uploadFile
                ? uploadFile.name
                : t(
                    'partners.installer_invitation.modal.input_file_placeholder'
                  )
            }
            loadValidButton={loadValidButton}
          />
          <div className="pt-[1.5rem] flex items-center justify-end space-x-[1rem]">
            <div>
              <ButtonOpx
                label={`${t('buttons.cancel')}`}
                type="secondary"
                onClick={() => setModal(false)}
                addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
              />
            </div>
            {!fileValid ? (
              <div>
                <ButtonOpx
                  label={`${t('buttons.validate')}`}
                  type="primary"
                  disabled={!uploadFile}
                  onClick={onClickValidateFile}
                  addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
                  isLoading={loading}
                />
              </div>
            ) : (
              <div>
                <ButtonOpx
                  label={`${t('buttons.send')}`}
                  type="primary"
                  onClick={onClickSendInvitation}
                  addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
                  isLoading={loading}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

export { ModalInstallerInvitation };
