import { IWorksiteInstaller } from '@models/worksites/utils/worksitesTypes';
import { Card } from '@components/Card';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { blueOpx } from '@assets/color';
import { installerIcons } from '@models/beneficiaries/components/view/InfosIcons';
import { CheckIcon } from '@assets/images/svgComponents';
import { dateToDDMMYYY } from '@utils/format';
import { getCertificationsList } from '@models/certifications/apiRequests/certificationsRequests';
import { useEffect, useState } from 'react';
import { ICertificationType } from '@models/certifications/utils/certificationsTypes';
import { useTranslation } from 'react-i18next';

interface InstallerInfosProps {
  installer: IWorksiteInstaller;
  loggedIn: boolean;
}

function InstallerInfos({ installer, loggedIn }: InstallerInfosProps) {
  const { t } = useTranslation();
  const [certificationList, setCertificationList] = useState<
    ICertificationType[]
  >([]);
  const getDataCertificationList = async () => {
    const resList = await getCertificationsList(installer.id, 1, 10);

    if (resList) {
      setCertificationList(resList.data);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      getDataCertificationList();
    }
  }, []);
  return (
    <Card
      title={t('worksites.installer')}
      addClass="max-h-full flex flex-col"
      addClassBody="overflow-y-auto"
    >
      <div className="flex flex-col space-y-5">
        {installer.logo && (
          <img src={installer.logo} alt={installer.company_name} />
        )}
        <div className="grid gap-5">
          {installerIcons(installer)
            .filter((elt) => elt.subtitle)
            .map((icon) => (
              <InfosWithIcon
                key={icon.title}
                loading={false}
                infos={[
                  {
                    title: icon.title,
                    subtitle: icon.subtitle,
                  },
                ]}
                icon={icon.icon}
                colorIcon={blueOpx}
              />
            ))}
        </div>
        {certificationList.length > 0 && (
          <div>
            {certificationList.map((qualif, i) => (
              <div
                className={`${
                  i < certificationList.length - 1 ? 'py-5' : 'pt-5'
                } border-t border-t-borderGrey `}
                key={`qualif_${qualif.id}`}
              >
                <InfosWithIcon
                  loading={false}
                  infos={[
                    {
                      title: t('partners.rge_certificate'),
                      subtitle: qualif.name,
                    },
                  ]}
                  icon={<CheckIcon />}
                  colorIcon={blueOpx}
                  addClass="mb-5"
                />
                <InfosWithIcon
                  loading={false}
                  infos={[
                    {
                      title: t('partners.validation_date'),
                      subtitle: `${dateToDDMMYYY(
                        qualif.start_date
                      )} - ${dateToDDMMYYY(qualif.end_date)}`,
                    },
                  ]}
                  icon={<div />}
                  colorIcon="transparent"
                  addClass="mb-5"
                />
                <InfosWithIcon
                  loading={false}
                  infos={[
                    {
                      title: t('buttons.download_document'),
                      subtitle: qualif.linked_file?.file_name || '',
                    },
                  ]}
                  icon={<div />}
                  colorIcon="transparent"
                  onClick={() => {
                    const url = qualif.linked_file?.file_url;
                    if (url) {
                      window.open(url, '_blank', 'noopener,noreferrer');
                    }
                  }}
                  isLink
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
}

export { InstallerInfos };
