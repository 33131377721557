import { Dispatch, SetStateAction } from 'react';
import { axiosClient } from '@api/apiClient';
import {
  urlDeleteCustomField,
  urlGetCustomFields,
  urlPostCustomField,
  urlUpdateCustomField,
} from '@api/apiUrls';

export const getListCustomFields = async (
  setData: Dispatch<SetStateAction<any>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  const response = await axiosClient.post(urlGetCustomFields);
  setData(response.data);
  setIsLoading(false);
  return response.data;
};

export const addCustomField = async (
  data: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(urlPostCustomField, data);
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const updateCustomField = async (
  customFieldId: number,
  data: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(
      urlUpdateCustomField(customFieldId),
      data
    );
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const deleteCustomField = async (
  customFieldId: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    await axiosClient.post(urlDeleteCustomField(customFieldId));
    setIsLoading(false);
    return true;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};
