/* eslint-disable no-case-declarations */
import { FieldValues } from 'react-hook-form';
import { capitalizeWord, formatWord, toAPIDateStr } from '@utils/format';
import { isBeforeDate, isBeforeToday } from '@utils/functions';
import { blueSecondary, green, red, textGrey } from '@assets/color';
import { TFunction } from 'i18next';

export const validateDate = (
  type: string,
  valuesForm: FieldValues,
  deposit: any,
  t: TFunction
) => {
  if (
    valuesForm[type] === '' ||
    !valuesForm[type] ||
    valuesForm[type].length < 10
  ) {
    return { error: '', valid: true };
  }
  switch (type) {
    case 'deposit_date':
      if (!valuesForm.deposit_date) {
        return { error: '', valid: false };
      }
      return {
        error: t('deposits.errors.start_date'),
        valid: !isBeforeToday(valuesForm.deposit_date || ''),
      };
    default:
      if (isBeforeDate(valuesForm[type] || '', deposit.deposit_date || '')) {
        return { error: t('deposits.errors.deposit_date'), valid: false };
      }
      if (isBeforeToday(valuesForm[type] || '')) {
        return { error: t('deposits.errors.start_date'), valid: false };
      }
      return { error: t('deposits.errors.deposit_date'), valid: true };
  }
};

export const validateForm = (
  type: string,
  valuesForm: FieldValues,
  deposit: any,
  t: TFunction
) => {
  if (
    type.includes('date') &&
    valuesForm[type] &&
    valuesForm[type].length < 10
  ) {
    return false;
  }
  switch (type) {
    case 'deposit_date':
      if (!valuesForm.emmy_reference || valuesForm.emmy_reference === '') {
        return false;
      }
      return validateDate(type, valuesForm, deposit, t).valid;
    case 'partial_validation_date':
      return validateDate('validation_date', valuesForm, deposit, t).valid;
    case 'complements_sending_dat':
      return validateDate('complements_sending_date', valuesForm, deposit, t)
        .valid;
    default:
      return validateDate(type, valuesForm, deposit, t).valid;
  }
};

export const getReqBody = (
  type: string,
  deposit: any,
  valuesForm: FieldValues
) => {
  switch (type) {
    case 'deposit_date':
      return {
        id: deposit.id,
        action_date: 'deposit',
        emmy_reference: valuesForm.emmy_reference,
        deposit_date: toAPIDateStr(valuesForm.deposit_date),
      };
    case 'unlocked_date':
      return {
        id: deposit.id,
        action_date: 'unlocked',
        unlocked_date: toAPIDateStr(valuesForm.unlocked_date),
      };
    case 'relocked_date':
      return {
        id: deposit.id,
        action_date: 'relocked',
        relocked_date: toAPIDateStr(valuesForm.relocked_date),
      };
    case 'validation_date':
      return {
        id: deposit.id,
        action_date: 'validation',
        validation_date: toAPIDateStr(valuesForm.validation_date),
        num_dd_classique: valuesForm.num_dd_c || deposit.num_dd_classique,
        num_dd_precaire: valuesForm.num_dd_p || deposit.num_dd_precaire,
      };
    case 'unreceivability_date':
      return {
        id: deposit.id,
        action_date: 'unreceivability',
        unreceivability_date: toAPIDateStr(valuesForm.unreceivability_date),
      };
    case 'complements_sending_date':
      return {
        id: deposit.id,
        action_date: 'complements',
        complements_sending_date: toAPIDateStr(
          valuesForm.complements_sending_date
        ),
      };
    default:
      return null;
  }
};

export const getTagValue = (
  status: string | undefined,
  t: TFunction,
  isCofrac = false,
  isWorksite = false
) => {
  const list = isCofrac ? 'cofrac.operation_status.' : 'deposits.operation_';
  if (!status) return { tagText: '', tagColor: '' };
  const label = formatWord(status);

  switch (true) {
    case label.includes('invalid'):
      return { tagText: t(`${list}invalidated`), tagColor: red };
    case label.includes('corrigee'):
      return { tagText: t(`${list}edited`), tagColor: green };
    case label.includes('cours'):
      return { tagText: t(`${list}in_progress`), tagColor: blueSecondary };
    case label === 'satisfaisante':
      return { tagText: t(`${list}ok`), tagColor: green };
    case label === 'insatisfaisante':
      if (isWorksite) {
        return {
          tagText: capitalizeWord(t(`${list}insatisfaisante_singular`)),
          tagColor: red,
        };
      }
      return { tagText: t(`${list}ko`), tagColor: red };
    case label.includes('controlee'):
      return { tagText: t(`${list}uncontrolled`), tagColor: textGrey };
    case label.includes('verifiable'):
      return { tagText: t(`${list}uncontrolled`), tagColor: textGrey };
    default:
      return { tagText: '', tagColor: '' };
  }
};
