import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { OperationTypeEnum } from '@utils/enums';
import { ListOperationInfos } from './operations/ListOperationInfos';

function OperationSummary() {
  const { t } = useTranslation();

  const { arrayOperations, worksiteOperationType } = useContext(
    WorksiteCreationContext
  );

  const operations: IOperation[] = useMemo(() => {
    const arrayOps = arrayOperations as any[];

    if (
      worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION &&
      arrayOps &&
      arrayOps[0] &&
      arrayOps[0]['operation.suboperations']
    ) {
      return arrayOps[0]['operation.suboperations'] as IOperation[];
    }

    return arrayOperations as IOperation[];
  }, [arrayOperations, worksiteOperationType]);

  return (
    <div className="border border-borderGrey rounded-default mb-[1rem]">
      <div className="border-b border-b-borderGrey">
        <p className="p-[1.5rem] text-[1.25rem] font-medium">
          {t('worksites.operation.title') + (operations?.length > 1 ? 's' : '')}
        </p>
      </div>
      <div>
        <ListOperationInfos totalCost />
      </div>
    </div>
  );
}

export { OperationSummary };
