import { useState } from 'react';
import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import { FormProvider, useForm } from 'react-hook-form';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { InputText } from '@components/atomic/inputs/InputText';
import { CalendarIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';

function ControlsDesign() {
  const methods = useForm();
  const { handleSubmit } = methods;

  // TEST TextWithCheckboxOrToggle checkbox
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  // TEST TextWithCheckboxOrToggle toggle
  const [isToggle, setIsToggle] = useState(false);

  // TEST TextWithCheckboxOrToggle
  const handleCheck = (isChecked: boolean, label: string) => {
    if (isChecked) {
      setCheckedItems([...checkedItems, label]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== label));
    }
  };

  // TEST TextWithRadio
  const [selectedValue, setSelectedValue] = useState('');

  // TEST TextWithRadio + TextWithCheckboxOrToggle + InputTexts avec un icon
  const onSubmit = (d: any) => {
    console.log(checkedItems, selectedValue);
    console.log(d.prenom);
  };

  return (
    <div className="w-1/6 flex-col items-center mb-6">
      <p className="mb-4">CONTROLES</p>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} action="test/inputs">
          <TextWithCheckboxOrToggle
            label="Option 1"
            onCheck={(isChecked) => handleCheck(isChecked, 'Option 1')}
            checked={checkedItems.includes('Option 1')}
            type="checkbox"
          />
          <TextWithCheckboxOrToggle
            label="Option 2"
            onCheck={(isChecked) => handleCheck(isChecked, 'Option 2')}
            checked={checkedItems.includes('Option 2')}
            type="checkbox"
          />
          <TextWithCheckboxOrToggle
            label="Option 3"
            onCheck={(isChecked) => handleCheck(isChecked, 'Option 3')}
            checked={checkedItems.includes('Option 3')}
            type="checkbox"
          />
          <TextWithCheckboxOrToggle
            label="Toggle"
            onCheck={() => setIsToggle(!isToggle)}
            checked={isToggle}
            type="toggle"
          />
          <TextWithRadio
            label="Option 1"
            value="Option 1"
            setSelectedValue={setSelectedValue}
            isChecked={selectedValue === 'Option 1'}
            selectValue={selectedValue}
          />
          <TextWithRadio
            label="Option 2"
            value="Option 2"
            disabled
            setSelectedValue={setSelectedValue}
            isChecked={selectedValue === 'Option 2'}
            selectValue={selectedValue}
          />
          <TextWithRadio
            label="Option 3"
            value="Option 3"
            setSelectedValue={setSelectedValue}
            isChecked={selectedValue === 'Option 3'}
            selectValue={selectedValue}
          />

          <InputText
            id="prenom"
            name="prenom"
            label="Prénom"
            placeholder="Placeholder"
            required
            icon={<CalendarIcon fill={blueOpx} />}
          />

          <button
            type="submit"
            className="ml-5 mt-5 bg-green rounded-default text-white px-5 py-2"
          >
            Test envoi controles
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { ControlsDesign };
