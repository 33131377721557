/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError, toAPIDateStr } from '@utils/format';
import {
  urlDeleteContractBuyingPrices,
  urlGetLinkedFiles,
  urlGetTunnels,
  urlPostContractGenerateReference,
  urlPostContractPartenaires,
  urlPostListActorsSales,
  urlPostOperationsDdl,
  urlPutContractBuyingPrices,
  urlPutContractGeneralInfos,
  urlPutContractInfos,
  urlPutConventionStatus,
  urlPutContractStatus,
  urlGetConventionModelTunnel,
  urlPostGenerateModelReference,
} from '@api/apiUrls';
import { IBuyingPriceType, IInfosGeneralType } from '../utils/newContractTypes';

export const getTunnels = async () => {
  try {
    const response = await axiosClient.get(urlGetTunnels);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

// ne retourne que le tunnel du clic via bouton Nouveau template
export const getConventionModelTunnel = async () => {
  try {
    const response = await axiosClient.get(urlGetConventionModelTunnel);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getListPartners = async (
  categorie: string,
  contract_type: number,
  keyword: string | null
) => {
  try {
    const response = await axiosClient.post(urlPostContractPartenaires, {
      categorie,
      contract_type,
      keyword,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const saveGeneralInfosContract = async (data: IInfosGeneralType) => {
  try {
    const response = await axiosClient.put(urlPutContractGeneralInfos, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const generateReference = async (
  partnerId: number | null,
  fiche: string
) => {
  try {
    const response = await axiosClient.post(urlPostContractGenerateReference, {
      fiche,
      entity_to_id: partnerId,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const generateModelReference = async () => {
  try {
    const response = await axiosClient.post(urlPostGenerateModelReference);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const saveInfos = async (
  contractId: number,
  data: any,
  infosType: string
) => {
  try {
    const response = await axiosClient.put(
      urlPutContractInfos(contractId, infosType),
      data
    );
    return response.data.data;
  } catch (error) {
    displayError(error);
  }
};

export const storeBuyingPrices = async (
  contractId: number,
  data: IBuyingPriceType
) => {
  try {
    const response = await axiosClient.put(
      urlPutContractBuyingPrices(contractId),
      data
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const deleteBuyingPrices = async (buyingPriceId: number) => {
  try {
    const response = await axiosClient.delete(
      urlDeleteContractBuyingPrices(buyingPriceId)
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getOperationsDdl = async (
  contractId: number | null,
  contractType: number,
  beneficiaryType: number | null,
  keyword = '',
  startDate: string | null = null,
  endDate: string | null = null,
  partnerId: number | null = null,
  copyId: number | undefined = undefined
) => {
  try {
    const configs = {
      pagination: {
        page: 1,
        perPage: 300,
      },
      contractId,
      contractType,
      beneficiaryType,
      keyword,
      startDate: toAPIDateStr(startDate),
      endDate: toAPIDateStr(endDate),
      partnerId,
      copyId,
    };

    const response = await axiosClient.post(urlPostOperationsDdl, configs);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getListActorsSales = async (entityToId: number | null) => {
  try {
    const response = await axiosClient.post(urlPostListActorsSales, {
      entity_to_id: entityToId,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getListDocuments = async (contractId: number, type: string) => {
  try {
    const response = await axiosClient.get(urlGetLinkedFiles(contractId, type));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateContractStatus = async (
  id: number,
  isConvention: boolean
) => {
  const url = isConvention
    ? urlPutConventionStatus(id)
    : urlPutContractStatus(id);

  try {
    const response = await axiosClient.put(url);

    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};
