import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import {
  AddIcon,
  SignataryIcon,
  TrashIcon,
} from '@assets/images/svgComponents';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';

import { IInfosLine, IStatus } from 'types/globalTypes';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ColorCube } from '@components/atomic/ColorCube';
import { red } from '@assets/color';

interface IFormInfoUserProps {
  classRow: CallableFunction;
  renderInputInformation: (col: IInfosLine) => JSX.Element;
  isEditMode: boolean;
  data: {
    status: boolean | string | IStatus | undefined;
    rows: IInfosLine[][];
    additionalFunction?: Record<string, CallableFunction>;
  };
  setIsEditMode: CallableFunction;
  onClickAddSignatory?: (e: React.MouseEvent) => void;
}

function FormSignataire({
  classRow,
  renderInputInformation,
  isEditMode,
  data,
  setIsEditMode,
  onClickAddSignatory,
}: IFormInfoUserProps) {
  const { t } = useTranslation();

  return isEditMode ? (
    <div className="flex flex-col gap-4">
      {data.rows.map((row: IInfosLine[], iRow: number) => (
        <div
          key={v4()}
          className="border border-borderGrey p-4 rounded-default"
        >
          <div className="flex justify-between">
            <div>
              <h3 className="text-xl font-medium leading-8 mb-2">
                {t('contract.signatory')} {iRow + 1}
              </h3>
              <p className="text-sm leading-5 mb-4">
                {t('my_account.signatory_information_edit')}
              </p>
            </div>
            {iRow > 0 && (
              <ColorCube
                size="2.5rem"
                numberOrIcon={<TrashIcon />}
                color={red}
                opacity
                onClick={() => {
                  data?.additionalFunction?.deleteSignatory(
                    Number(row[row.length - 1].value)
                  );
                  setIsEditMode(false);
                }}
              />
            )}
          </div>

          <div className={`${classRow(iRow)}`}>
            {row.map((col: IInfosLine) => (
              <Fragment key={v4()}>{renderInputInformation(col)}</Fragment>
            ))}
          </div>
        </div>
      ))}
      <div className="flex justify-end">
        <ButtonOpx
          onClick={onClickAddSignatory}
          label={t('my_account.add_signatory')}
          type="primary"
          disabled={data.rows.length < 1 || data.rows.length === 3}
          icon={<AddIcon />}
          addClass="mt-4"
        />
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-6">
      {data.rows.map((row: IInfosLine[], iRow: number) => (
        <div
          key={v4()}
          className="border border-borderGrey p-4 rounded-default"
        >
          <h3 className="text-xl font-medium leading-8 mb-4">
            {t('contract.signatory')} {iRow + 1}
          </h3>
          <div className="grid gap-4 grid-cols-2">
            <InfosWithIcon
              icon={<SignataryIcon />}
              infos={[
                {
                  title: `${t('my_account.firstname_lastname')}`,
                  subtitle: `${row[0] ? row[0].value : ''} ${
                    row[1] ? row[1].value : ''
                  }`,
                },
              ]}
            />
            {row.slice(2, row.length).map((col: IInfosLine) => (
              <Fragment key={v4()}>{renderInputInformation(col)}</Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

FormSignataire.defaultProps = {
  onClickAddSignatory: undefined,
};

export { FormSignataire };
