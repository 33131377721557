import { SiretSection } from '@models/auth/components/registrationSteps/SiretSection';
import { AuthLayout } from '@pages/auth/AuthLayout';

interface IRegistrationProps {
  stepSiret?: boolean;
}

function Registration({ stepSiret }: IRegistrationProps) {
  return <AuthLayout>{stepSiret ? <SiretSection /> : <div />}</AuthLayout>;
}

export { Registration };

Registration.defaultProps = {
  stepSiret: true,
};
