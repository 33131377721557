import { textGrey, orange, red, borderGrey } from '@assets/color';
import { ListDotIcon } from '@assets/images/svgComponents';
import { Tag } from '@components/atomic/Tag';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IRequestTypes } from '@models/requests/types/requestTypes';
import { RequestAndActivityIntoModalOrCardContext } from '../../../../context/requestAndChangeContext';

interface OneCardRequestInCardProps {
  request: IRequestTypes;
  borderBottom?: boolean;
  allRequests?: boolean;
  onClick?: () => void;
  displayAddress?: boolean;
  animationDelay: number;
}

function OneCardRequestInCard({
  borderBottom,
  allRequests,
  onClick,
  animationDelay,
  request,
  displayAddress,
}: OneCardRequestInCardProps) {
  const [isVisible, setIsVisible] = useState(false);
  const slideInStyle = isVisible
    ? {
        transform: 'translateX(0)',
        opacity: 1,
        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
      }
    : { transform: 'translateX(100%)', opacity: 0 };

  const { t } = useTranslation();
  const { requestOrChangeEnumInModal } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );

  // const requestAutomatic = request.type === 1;

  // const tagEnum =
  //   globalEnum.demand_tag !== undefined
  //     ? globalEnum.demand_tag[`${request.tags}`]
  //     : null;
  const date = moment(request.created_at || '', 'YYYY-MM-DD HH:mm:ss');
  const now = moment();
  const diff = now.diff(date, 'days');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, animationDelay); // Délai avant de commencer l'animation
    return () => clearTimeout(timer);
  }, [animationDelay]);

  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        ...slideInStyle,
        borderBottom: borderBottom ? `1px solid ${borderGrey}` : '',
      }}
      className={`bg-white rounded-default w-full p-[1rem] pt-0  ${
        requestOrChangeEnumInModal !== null ? 'cursor-default' : ''
      }`}
      data-test-id="request_card_sidebar_right"
    >
      {allRequests && (
        <div className="flex items-center justify-between w-full">
          <Tag color={textGrey} label="Partnenaire" />
          <div className="flex space-x-[.5rem]">
            <Tag color={orange} label="Etape 2" />
            <Tag color={textGrey} label="Nouveau" />
          </div>
        </div>
      )}
      {requestOrChangeEnumInModal !== null && (
        <Tag
          color={textGrey}
          label={
            request.relation_type === 'worksite'
              ? t('requests_and_changes.worksite')
              : ''
          }
        />
      )}
      <div className="flex items-center justify-between mt-[1rem] w-full">
        <div className="flex space-x-[.5rem] ">
          <div className="mt-2">
            <ListDotIcon fill={red} width=".5rem" height=".5rem" />
          </div>
          <p className="font-medium text-[1rem] text-left">{request.subject}</p>
        </div>
        <Tag
          color={textGrey}
          label={
            diff === 0
              ? t('requests_and_changes.today')
              : `${t('requests_and_changes.there_are')} ${diff} ${t(
                  'requests_and_changes.day'
                )}${diff === 1 ? '' : 's'}`
          }
          addClass="whitespace-nowrap"
        />
      </div>
      <p className="text-textGrey text-[.875rem] mt-[.5rem] text-left">
        {displayAddress ? request.address : request.message || ''}
      </p>
      {/* <div className="flex items-center space-x-[.5rem] mt-[1rem] w-full">
        <Tag
            color={requestAutomatic ? blueSecondary : purple}
            label={
              requestAutomatic
                  ? t('requests_and_changes.request_automatic')
                  : t('requests_and_changes.request_personalized')
            }
        />
        {request.tags !== null && (
            <Tag color={red} label={`${request.nb} ${tagEnum}` || ''}/>
        )}
      </div> */}
    </button>
  );
}

export { OneCardRequestInCard };

OneCardRequestInCard.defaultProps = {
  borderBottom: false,
  allRequests: false,
  onClick: undefined,
  displayAddress: false,
};
