import React from 'react';
import { LoaderSkeleton } from './LoaderSkeleton';

function LoaderCreateWorksite() {
  return (
    <div className="bg-backgroundBody absolute min-h-screen top-0 md:h-full w-full">
      <div className="w-full  p-[2rem] md:flex justify-between items-center border-b border-b-borderGrey bg-white">
        <LoaderSkeleton height="1rem" addClass="!w-[20rem]" />
        <div className="flex space-x-[1rem]">
          <LoaderSkeleton height="3rem" addClass="!w-[10rem]" />
          <LoaderSkeleton height="3rem" addClass="!w-[15rem]" />
        </div>
      </div>
      <div className="h-[calc(100%_-_7rem)] p-[1.5rem] flex space-x-[1.5rem]">
        <div className="min-w-[18rem] h-full flex flex-col space-y-[.875rem]">
          <div className="bg-white border border-borderGrey p-[1.5rem] space-y-[1.5rem]">
            <LoaderSkeleton height="1rem" />
            <LoaderSkeleton height="1rem" />
            <LoaderSkeleton height="1rem" />
            <LoaderSkeleton height="1rem" />
          </div>
          <div className="bg-white border border-borderGrey p-[1.5rem] space-y-[1.5rem]">
            <LoaderSkeleton height="1rem" />
          </div>
          <div className="bg-white border border-borderGrey p-[1.5rem] space-y-[1.5rem]">
            <LoaderSkeleton height="1rem" />
          </div>
        </div>
        <div className="bg-white w-full h-full" />
      </div>
    </div>
  );
}

export { LoaderCreateWorksite };
