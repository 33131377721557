import { IWorksiteCardDatas } from '../utils/dashboardTypes';
import WorksiteQuantityCard from './WorksiteQuantityCard';

const fakeWorksiteCardDatas: IWorksiteCardDatas[] = [
  { worksiteType: 1, numberWorksite: 4 },
  {
    worksiteType: 2,
    numberWorksite: 7,
  },
  {
    worksiteType: 3,
    numberWorksite: 4,
  },
];

function WorksiteQuantityLine() {
  return (
    <div className="flex space-x-4 w-full items-start">
      {fakeWorksiteCardDatas.map((data) => (
        <WorksiteQuantityCard
          key={`worksitequantity-card-${data.worksiteType}`}
          worksiteType={data.worksiteType}
          numberWorksite={data.numberWorksite}
        />
      ))}
    </div>
  );
}

export default WorksiteQuantityLine;
