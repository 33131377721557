import { useEffect, useState } from 'react';
import { PaymentMethod, Subscription } from '@hyperline/react-components';
import { getBillingToken } from '@models/users/apiRequests/userRequests';
import { Loader } from '@components/atomic/Loader';
import { black, blueOpx, borderGrey } from '@assets/color';
import { PartnersPaymentInfo } from '@models/partners/components/PartnersPaymentInfo';

function SubscriptionAndPaymentTab() {
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>('notset'); // because there is an Hyperline error if length < 5
  const [mode, setMode] = useState<'production' | 'sandbox' | undefined>(
    'production'
  );

  const getToken = async () => {
    await getBillingToken(setToken, setMode);
    setLoading(false);
  };

  const appearancePaymentMethod = {
    variables: {
      colorPrimary: blueOpx,
    },
    rules: {
      input: {
        color: black,
        boxShadow: 'none',
        borderStyle: `1px solid ${borderGrey}`,
      },
    },
  };

  const appearanceSubscription = {
    variables: {
      colorText: black,
    },
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="flex bg-white justify-center border border-grey rounded-default min-h-[23rem]">
        {loading || token === 'notset' ? (
          <Loader />
        ) : (
          <div className="w-full flex text-neutral-400 text-sm justify-center p-6">
            {/* Première colonne : Bouton et détails */}
            <div className="w-1/2 flex justify-center border-r border-borderGrey pr-6">
              <Subscription
                options={{ token, mode, appearance: appearanceSubscription }}
              />
            </div>
            {/* Seconde colonne : Informations de paiement */}
            <div className="w-1/2 flex justify-center pl-6">
              <PaymentMethod
                options={{ token, mode, appearance: appearancePaymentMethod }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="w-1/2 flex text-neutral-400 text-sm justify-center">
        <PartnersPaymentInfo />
      </div>
    </div>
  );
}

export default SubscriptionAndPaymentTab;
