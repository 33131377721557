import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { saveAs } from 'file-saver';

import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';

import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { Tag } from '@components/atomic/Tag';
import { ButtonOpx } from '@components/atomic/ButtonOpx';

import { TabDepositFollowUp } from '@models/deposits/components/TabDepositFollowUp';
import { TabDetailDeposit } from '@models/deposits/components/TabDetailDeposit';

import {
  getDepot,
  exportCsv,
  downloadDocuments,
  deleteDeposit,
} from '@models/deposits/apiRequests/depositRequests';

import { blueSecondary, orange, textGrey } from '@assets/color';
import { TabDocuments } from '@models/deposits/components/TabDocuments';
import { IDepositType } from '@models/deposits/utils/depositsTypes';
import { formatToSingular } from '@utils/format';
import { initDocumentActive } from '@utils/initialState';
import { LoaderTabsLeftPart } from '@components/loaders/LoaderTabsLeftPart';
import { LoaderDeposit } from '@components/loaders/LoaderDeposit';
import { Modal } from '@components/Modal';
import { AuthContext } from '@context/authContext';
import { DEPOSITS_ROUTES } from '@utils/routesUrls';

function Deposit() {
  const { t } = useTranslation();
  const { id: depositId } = useParams();

  const {
    updateTitleHeader,
    updateDisplayBackButtonHeader,
    updateTagHeader,
    refreshHeader,
    updateRouteFrom,
  } = useContext(HeaderContext);
  const {
    updateRoute,
    globalEnum,
    updateDocumentActive,
    updateDisplayModalUpgradeSubscription,
  } = useContext(GlobalContext);

  const { user } = useContext(AuthContext);

  const [deposit, setDeposit] = useState<IDepositType>();

  const [selectedTab, setSelectedTab] = useState<string>(
    String(t('deposits.tab_suivi'))
  );
  const navigate = useNavigate();
  const [modalDateDepot, setModalDateDepot] = useState(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [emmyButtonEnabled, setEmmyButtonEnabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const getDataDeposit = async () => {
    setIsLoading(true);
    if (!deposit || !deposit.id) updateTitleHeader('loading');
    const res = await getDepot(Number(depositId));
    setDeposit(res);
    setIsLoading(false);
    return res;
  };

  const exportEmmy = async () => {
    try {
      setIsLoading(true);
      const response = await exportCsv(Number(depositId));

      if (response !== undefined) {
        const blob = new Blob([response], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'export_emmy');
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading emmy csv:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataDeposit();
    refreshHeader(true);
  }, [depositId]);

  useEffect(() => {
    updateDisplayBackButtonHeader(true);
    if (deposit && globalEnum && globalEnum.deposit_status) {
      updateTitleHeader(`${deposit.reference}`);

      const status = (globalEnum.deposit_status as any)[`${deposit.status}`];
      const tagText = formatToSingular(status);
      const tagColor =
        deposit.status > 1 && deposit.status < 5 ? blueSecondary : textGrey;

      updateTagHeader(
        <div className="flex">
          <Tag label={tagText} color={tagColor} addClass="mr-3" />
          {deposit.is_external && (
            <Tag
              label={t('deposits.external')}
              color={orange}
              addClass="border border-borderOrange mr-3"
            />
          )}
        </div>
      );

      updateRoute({
        to: `depots`,
        from: `depots`,
      });
    }
  }, [deposit, globalEnum]);

  useEffect(() => {
    updateDocumentActive(initDocumentActive);
    updateRouteFrom(DEPOSITS_ROUTES.DEPOSITS);
    return () => updateRouteFrom('');
  }, []);

  const handleDelete = async () => {
    if (deleteId) {
      setIsDeleting(true);

      const res = await deleteDeposit(Number(deleteId));
      if (res.result === 'success') {
        setDeleteId(undefined);
        navigate(DEPOSITS_ROUTES.DEPOSITS);
      }
      setIsDeleting(false);
    }
  };

  const noDeposit = !deposit || !deposit.id;
  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          noDeposit ? (
            <LoaderTabsLeftPart />
          ) : (
            <TabsLeftPart
              titlesList={
                deposit.status === 1
                  ? [t('deposits.tab_suivi'), t('contract.documents')]
                  : [
                      t('deposits.tab_suivi'),
                      t('contract.documents'),
                      t('deposits.tab_detail'),
                    ]
              }
              onClick={(title) => {
                if (title === t('deposits.tab_suivi')) getDataDeposit();
                setSelectedTab(title);
              }}
              activeButton={selectedTab}
            />
          )
        }
        rightPart={
          <div className="flex items-center gap-3">
            {deposit?.id && deposit.reference && deposit.status > 1 && (
              <ButtonOpx
                label={`${t('deposits.btn_download_docs')}`}
                onClick={() =>
                  downloadDocuments(deposit.id, deposit.reference, setIsLoading)
                }
                disabled={isLoading}
                isLoading={isLoading}
              />
            )}
            {!noDeposit && deposit.status === 1 ? (
              <>
                {deposit?.deposits_operations.length === 0 &&
                  !deposit.is_external && (
                    <ButtonOpx
                      label={`${t('deposits.btn_delete')}`}
                      onClick={() => setDeleteId(deposit?.id)}
                      type="secondary"
                    />
                  )}

                {deleteId && (
                  <Modal
                    isLoading={isDeleting}
                    title={t('deposits.title_delete')}
                    textBtnConfirm={`${t('buttons.confirm')}`}
                    onConfirmClick={handleDelete}
                    btnCancel
                    setIsModal={() => setDeleteId(undefined)}
                    backgroundTransparent
                  >
                    <p className="mt-4">{t('global.confirm_delete')}</p>
                  </Modal>
                )}
                {!deposit.is_external && (
                  <ButtonOpx
                    label={`${t('deposits.btn_enter_date')}`}
                    onClick={() => setModalDateDepot(true)}
                    type="secondary"
                  />
                )}
                <ButtonOpx
                  isLoading={isLoading}
                  disabled={!emmyButtonEnabled}
                  isSubmitBtn={isLoading}
                  label={`${t('deposits.btn_export_csv')}`}
                  onClick={() => {
                    if (user?.is_freemium) {
                      updateDisplayModalUpgradeSubscription(true);
                    } else {
                      exportEmmy();
                    }
                  }}
                />
              </>
            ) : (
              <div />
            )}
          </div>
        }
      />

      {!noDeposit ? (
        <>
          {selectedTab === t('deposits.tab_suivi') && (
            <TabDepositFollowUp
              deposit={deposit}
              setDeposit={setDeposit}
              getDataDeposit={getDataDeposit}
              modalDateDepot={modalDateDepot}
              setModalDateDepot={setModalDateDepot}
              setSelectedTab={setSelectedTab}
              setEmmyButtonEnabled={setEmmyButtonEnabled}
            />
          )}

          {selectedTab === t('deposits.tab_detail') && (
            <TabDetailDeposit deposit={deposit} setDeposit={setDeposit} />
          )}

          {selectedTab === t('contract.documents') && (
            <TabDocuments deposit={deposit} />
          )}
        </>
      ) : (
        <LoaderDeposit />
      )}
    </div>
  );
}

export { Deposit };
