import { ListRequestsInCard } from '@components/requestsAndChanges/card/requests/ListRequestsInCard';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';

interface RequestsDashboardProps {
  realData?: boolean;
}
function RequestsDashboard({ realData }: RequestsDashboardProps) {
  const { t } = useTranslation();
  const { refreshRequestsAndChanges } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );

  useEffect(() => {
    refreshRequestsAndChanges();
  }, []);

  return (
    <div className="w-full h-full bg-white border border-borderGrey rounded-default">
      <div className="flex px-4 py-6 text-xl font-medium leading-loose border-b border-borderGrey">
        {t('requests_and_changes.requests')}
      </div>
      <div className="h-[30rem]">
        <ListRequestsInCard isInTabFixed={false} realData={realData} />
      </div>
    </div>
  );
}

RequestsDashboard.defaultProps = { realData: false };

export default RequestsDashboard;
