import { v4 } from 'uuid';
import { LoaderKpiTab } from '../LoaderKpiTab';
import { LoaderWorksiteDetailsOperationCard } from './LoaderWorksiteDetailsOperationCard';

function LoaderWorksiteDetailsOperations() {
  return (
    <div className="w-full">
      <div className="w-full flex items-center space-x-[1.5rem]">
        {[...Array(4)].map(() => (
          <div key={v4()} className="w-full">
            <LoaderKpiTab />
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center space-y-[1rem] mt-[1.5rem] w-full">
        {[...Array(4)].map(() => (
          <div key={v4()} className="w-full">
            <LoaderWorksiteDetailsOperationCard />
          </div>
        ))}
      </div>
    </div>
  );
}
export { LoaderWorksiteDetailsOperations };
