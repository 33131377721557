/* eslint-disable consistent-return */
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from '@components/Modal';
import { SearchBar } from '@components/SearchBar';

import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';

import {
  listOperationsToControl,
  saveDateDeposit,
} from '@models/deposits/apiRequests/depositRequests';

import { renderTextField } from '@models/contractCreation/utils/newContractHelper';
import { ControlItem } from '@models/lots/components/ControlItem';
import { numberWithSeparator, toAPIDateStr } from '@utils/format';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { validateDate, validateForm } from '../utils/depositHelper';

interface IModalControlProps {
  deposit: any;
  callbackConfirm: (res: any) => void;
  callbackClose: () => void;
}

function ModalControl(props: IModalControlProps) {
  const { deposit, callbackConfirm, callbackClose } = props;
  const { t } = useTranslation();

  const methods = useForm();
  const { watch, setValue } = methods;
  const valuesForm = watch();

  const [list, setList] = useState([]);
  const [removeList, setRemoveList] = useState<any[]>([]);
  const [hasChange, setHasChange] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();

  const getData = async (search?: string) => {
    setLoading(true);
    const res = await listOperationsToControl(
      deposit.id,
      search || '',
      sortAndFilterData
    );
    setLoading(false);
    setList(res);
  };

  const nbSelectedOperations = useMemo(() => {
    return removeList.length;
  }, [removeList]);

  const volumeTotal = useMemo(() => {
    const sum = removeList.reduce((accumulator, object) => {
      return accumulator + Number((object as any).mwhc.replace(',', '.'));
    }, 0);

    return sum;
  }, [removeList, list]);

  useEffect(() => {
    getData();
  }, [deposit, sortAndFilterData]);

  const handleConfirm = async () => {
    const valid = validateForm(
      'partial_validation_date',
      valuesForm,
      deposit,
      t
    );
    if (!valid) return false;

    const tmp = [...removeList].map((item: any) => {
      return {
        id: item.id,
      };
    });

    const res = await saveDateDeposit({
      id: deposit.id,
      action_date: 'validation',
      validation_date: toAPIDateStr(valuesForm.validation_date),
      validation_type: 'partial',
      num_dd_classique: valuesForm.num_dd_classique || deposit.num_dd_classique,
      num_dd_precaire: valuesForm.num_dd_precaire || deposit.num_dd_precaire,
      deposits_operations: tmp,
    });

    if (res && res.id) callbackConfirm(res);
  };

  const loadingItem = (
    <div className="flex justify-between">
      <div className="flex flex-col gap-5 w-1/3">
        <LoaderSkeleton height="1rem" addClass="w-10/12" />
        <LoaderSkeleton height="1rem" addClass="w-full" />
      </div>
      <div className="flex flex-col gap-5 w-1/3 items-end">
        <LoaderSkeleton height="1rem" addClass="w-10/12" />
        <LoaderSkeleton height="1rem" addClass="w-full" />
      </div>
    </div>
  );

  return (
    <Modal
      title={t('deposits.date_partial_validation')}
      textBtnConfirm={`${t('buttons.confirm')}`}
      onConfirmClick={handleConfirm}
      btnCancel
      setIsModal={callbackClose}
      backgroundTransparent
      buttonsPosition="bottom"
      sidebarVisible
    >
      <FormProvider {...methods}>
        <form>
          <div className="mt-4">
            <div className="flex flex-wrap items-center">
              <DatePicker
                required
                label=""
                onChangeDate={(value: string) => {
                  if (value !== '') setHasChange(true);
                  setValue('validation_date', value);
                }}
                addClass="w-full md:w-[49.5%] mb-3"
                textError={`${t('deposits.errors.start_date')}`}
                error={
                  hasChange
                    ? valuesForm.validation_date === t('global.choose') ||
                      !validateDate('validation_date', valuesForm, deposit, t)
                        .valid
                    : false
                }
              />
            </div>
            <div className="flex flex-wrap items-center">
              {renderTextField({
                valuesForm,
                label: `${t('partners.deposits.dd_classic')}`,
                placeholder: deposit.num_dd_classique || '',
                name: 'num_dd_classique',
                className: 'w-full md:w-[49.5%] mb-3 md:mr-[0.5rem]',
                isValid: true,
                onChange: () => setHasChange(true),
              })}
              {renderTextField({
                valuesForm,
                label: `${t('partners.deposits.dd_precarity')}`,
                placeholder: deposit.num_dd_precaire || '',
                name: 'num_dd_precaire',
                className: 'w-full md:w-[49.5%] mb-3',
                isValid: true,
                onChange: () => setHasChange(true),
              })}
            </div>
            <div className="rounded-default p-[1rem] mb-[0.5rem] border borderGrey my-4">
              <p className="font-medium text-[1rem]">
                {t('contractMandant.operation_invalidated')}
              </p>
              <p>{t('deposits.select_invalidated')}</p>

              <div className="flex flex-wrap justify-between items-center my-[1.5rem]">
                <div className="rounded-default w-full md:w-[60%] mb-[1.5rem] md:mb-0">
                  <SearchBar
                    placeholder={`${t('transfer.search_placeholder')}`}
                    onSearch={(value: string) => getData(value)}
                    searchOnEveryChange
                    width="100%"
                  />
                </div>
                <div className="w-full md:w-[40%] flex md:justify-end">
                  <SortAndFilter
                    onSort={(column, direction) =>
                      sortList(column, direction, setSortAndFilterData)
                    }
                    onFilter={(filters) =>
                      filterList(filters, setSortAndFilterData)
                    }
                    page="DEPOTS_OPERATIONS"
                  />
                </div>
              </div>

              {list.length > 0 ? (
                <div className="h-[30rem] overflow-y-scroll">
                  {list.map((item) => (
                    <div
                      className="rounded-default p-[1rem] mb-[0.5rem] border borderGrey my-4"
                      key={v4()}
                    >
                      {loading ? (
                        loadingItem
                      ) : (
                        <ControlItem
                          item={item}
                          removeList={removeList}
                          setRemoveList={setRemoveList}
                          enableComments={false}
                          identifier="id"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {loading ? (
                    <div className="rounded-default p-[1rem] mb-[0.5rem] border borderGrey my-4">
                      {loadingItem}
                    </div>
                  ) : (
                    <p className="text-textGrey">
                      {t('contract.association.no_items')}
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-wrap justify-between items-center my-4">
              <p className="font-medium text-[0.875rem]">{`${nbSelectedOperations} ${t(
                'partners.deposits.operation'
              )}${nbSelectedOperations > 1 ? 's' : ''} ${t(
                'contractMandant.selected'
              )}${nbSelectedOperations > 1 ? 's' : ''}`}</p>

              <p className="font-medium text-[0.875rem]">{`${numberWithSeparator(
                String(volumeTotal)
              )} MWhc`}</p>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export { ModalControl };
