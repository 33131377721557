import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Modal } from '@components/Modal';
import { InputText } from '@components/atomic/inputs/InputText';
import { Loader } from '@components/atomic/Loader';
import { storeBrand } from '@models/materials/apiRequests/materialsRequests';

interface IModalAddBrandProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  refetch: CallableFunction;
}

interface IFormInputs {
  name: string;
}

function ModalAddBrand({ setShowModal, refetch }: IModalAddBrandProps) {
  const { t } = useTranslation();
  const methods = useForm<IFormInputs>();
  const { handleSubmit } = methods;
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (formData: FieldValues) => {
      setLoading(false);
      await storeBrand(formData.name, t);
      refetch();
      setShowModal(false);
    },
    [setShowModal, refetch]
  );

  return (
    <Modal
      title={t('brand.new')}
      setIsModal={setShowModal}
      backgroundTransparent
      btnCancelIsIcon
      lightHeader
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-2 my-8">
            <InputText
              label={t('brand.name') || ''}
              id="name"
              name="name"
              placeholder={t('brand.name')}
              addClass="w-full"
              required
            />
          </div>
          <button
            type="submit"
            className="rounded-default px-[1rem] py-[0.5rem] bg-blueOpx text-white flex items-center ml-auto"
            disabled={loading}
            data-test-id="add_user_button"
          >
            <span className="mr-2">{t('buttons.validate')}</span>
            {loading && <Loader />}
          </button>
        </form>
      </FormProvider>
    </Modal>
  );
}

export { ModalAddBrand };
