import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { ENTITY_TYPES, ROLES } from '@utils/roles';
import {
  BriefcaseIcon,
  EuroIcon,
  VolumeGraphIcon,
} from '@assets/images/svgComponents';
import { blueOpx, blueSecondary, green, purple } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { t } from 'i18next';
import {
  convertVolumeFromMega,
  numFormatSpace,
  roundFormat,
} from '@utils/functions';
import { IGlobalEnumType, IKPITab } from '../../../types/globalTypes';

export const getWorksiteKpiData = (
  roleUser: string,
  globalEnum: IGlobalEnumType,
  worksiteDetails: IWorksiteDetails,
  totalMpr: number,
  totalCeePrecarious: number,
  totalCeeClassic: number,
  totalCee: number,
  totalVolumeClassic: number,
  totalVolumePrecarious: number,
  onEdit: () => void
) => {
  const beneficiaryAmount = worksiteDetails.beneficiary_amount || 0;

  const beneficiaryAmountPercent = (beneficiaryAmount / totalCee) * 100;
  const installerAmountPercent = 100 - beneficiaryAmountPercent;

  const primeData: IKPITab[] = [
    {
      infos: [
        {
          title: t('worksites.total_cee'),
          subtitle:
            (totalCeePrecarious === 0 && totalCeeClassic === 0) ||
            totalCee > 0 ? (
              <div className="w-[4rem]">
                {totalCee.toLocaleString('fr-FR')} €
              </div>
            ) : (
              <div className="flex space-x-2">
                {totalCeeClassic > 0 && (
                  <div className="flex space-x-1 items-center">
                    <Tag color={purple} label="Cl" />
                    <span>{totalCeeClassic.toLocaleString('fr-FR')} €</span>
                  </div>
                )}
                {totalCeePrecarious > 0 && (
                  <div className="flex space-x-1 items-center">
                    <Tag color={purple} label="Pr" />
                    <span>{totalCeePrecarious.toLocaleString('fr-FR')} €</span>
                  </div>
                )}
              </div>
            ),
        },
      ],
      icon: <EuroIcon />,
      colorIcon:
        // eslint-disable-next-line no-nested-ternary
        totalCeeClassic > 0 && totalCeePrecarious > 0
          ? blueOpx
          : totalCeeClassic > 0
          ? blueSecondary
          : purple,
      dataTestId: 'worksite_total_cee',
    },
  ];

  if (
    worksiteDetails.beneficiary_amount &&
    worksiteDetails.beneficiary_amount > 0 //
  ) {
    primeData.push({
      infos: [
        {
          title:
            t('worksite_creation.simulation.summary.beneficiary_amount') || '',
          subtitle: (
            <div className="flex gap-2">
              <div>
                {worksiteDetails.beneficiary_amount.toLocaleString('fr-FR')} €
              </div>
              <Tag
                color={green}
                label={`${numFormatSpace(
                  roundFormat(beneficiaryAmountPercent)
                )} %`}
                addClass="border-round"
              />
            </div>
          ),
        },
      ],
      icon: <EuroIcon />,
      colorIcon: purple,
      onEdit,
      dataTestId: 'worksite_beneficiary_amount',
    });
    primeData.push({
      infos: [
        {
          title:
            t('worksite_creation.simulation.summary.installer_amount_short') ||
            '',
          subtitle: (
            <div className="flex gap-2">
              <div>
                {Number(
                  totalCee - worksiteDetails.beneficiary_amount
                ).toLocaleString('fr-FR')}{' '}
                €
              </div>
              <Tag
                color={green}
                label={`${numFormatSpace(
                  roundFormat(installerAmountPercent)
                )} %`}
              />
            </div>
          ),
        },
      ],
      icon: <EuroIcon />,
      colorIcon: purple,
      onEdit,
      dataTestId: 'worksite_installer_amount',
    });
  }

  const kpiTabData: IKPITab[] = [
    {
      infos: [
        {
          title:
            roleUser === ROLES.PRODUCTION
              ? globalEnum.entity_type[
                  worksiteDetails.convention.from_entity_type
                ]
              : globalEnum.entity_type[ENTITY_TYPES.INSTALLATEUR],
          subtitle:
            roleUser === ROLES.PRODUCTION
              ? worksiteDetails.convention.from?.company_name || ''
              : worksiteDetails.installer.company_name || '',
        },
      ],
      icon: <BriefcaseIcon />,
      colorIcon: blueOpx,
      dataTestId: 'worksite_role',
    },
    ...(totalMpr > 0 && !(totalCeePrecarious > 0 && totalCeeClassic > 0)
      ? [
          {
            infos: [
              {
                title: t('worksites.ma_prime_renov'),
                subtitle: `${totalMpr.toLocaleString('fr-FR')} €`,
              },
            ],
            icon: <EuroIcon />,
            colorIcon: blueOpx,
            dataTestId: 'worksite_total_mpr',
          },
        ]
      : []),
    ...primeData,
    {
      infos: [
        {
          title: t('worksites.volume'),
          subtitle: (
            <div className="flex space-x-2">
              {totalVolumeClassic > 0 && (
                <div className="flex space-x-1 items-center">
                  <Tag color={blueSecondary} label="Cl" />
                  <span>
                    {convertVolumeFromMega(totalVolumeClassic / 1000)}
                  </span>
                </div>
              )}
              {totalVolumePrecarious > 0 && (
                <div className="flex space-x-1 items-center">
                  <Tag color={purple} label="Pr" />
                  <span>
                    {convertVolumeFromMega(totalVolumePrecarious / 1000)}
                  </span>
                </div>
              )}
            </div>
          ),
        },
      ],
      icon: <VolumeGraphIcon />,
      colorIcon:
        // eslint-disable-next-line no-nested-ternary
        totalVolumePrecarious > 0 && totalVolumeClassic > 0
          ? blueOpx
          : totalVolumePrecarious > 0
          ? purple
          : blueSecondary,
      dataTestId: 'worksite_total_volume',
    },
  ];

  const kpiNumber = kpiTabData.filter((info) => info.infos.length > 0).length;

  if (kpiNumber > 5) {
    return [
      kpiTabData[3], // part benef
      kpiTabData[1], // mpr
      kpiTabData[5], // Volume
      kpiTabData[0], // mandataire
      kpiTabData[4], // rem installateur
      kpiTabData[2], // prime cee
    ];
  }

  if (kpiNumber > 4) {
    return [
      kpiTabData[2], // part benef
      kpiTabData[1], // prime cee
      kpiTabData[0], // mandataire
      kpiTabData[3], // rem installateur
      kpiTabData[4], // prime cee
    ];
  }
  return kpiTabData;
};
